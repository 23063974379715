import { Form } from 'components/Form/Form';
import { Page } from 'components/Page/Page';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver/useYupValidationResolver';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentPartner, useUpdateCurrentPartner } from 'services/repositories/partners/partners';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { AddressIdDomain, UpdatePartnerDTO } from 'support/types';
import * as Yup from 'yup';
import { SettingsSidebar } from '../SettingsSidebar/SettingsSidebar';
import { Section } from 'components/Display/Section/Section';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { countriesAsDropdown } from 'support/helpers/countries/countries';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';

type CompanyDataFormState = {
  companyName: string;
  streetAddress: string;
  city: string;
  postalCode: string;
  defaultBillFromAddressId: string;
  defaultBillFromAddressIdDomain: string;
  defaultTaxId: string;
  defaultVatId: string;
  organicControlPointNumber: string;
};

const defaultValues = {
  companyName: '',
  streetAddress: '',
  city: '',
  postalCode: '',
  defaultBillFromAddressId: '',
  defaultBillFromAddressIdDomain: '',
  defaultTaxId: '',
  defaultVatId: '',
  organicControlPointNumber: '',
};
export function CompanyData() {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  const { data: currentPartner } = useCurrentPartner();
  const { mutate, isLoading } = useUpdateCurrentPartner();

  const [countryCode, setCountryCode] = useState<string | undefined | null>(
    currentPartner?.settings?.billFromAddress?.countryCode,
  );
  const [addressIdDomain, setAddressIdDomain] = useState<AddressIdDomain | undefined>(
    currentPartner?.settings?.billFromAddressIdentifierDomain,
  );
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);

  const formSchema = Yup.object({
    companyName: Yup.string().required(t('settings:companyData.form.companyNameValidation') ?? ''),
    streetAddress: Yup.string(),
    city: Yup.string(),
    postalCode: Yup.string(),
    defaultBillFromAddressId: Yup.string(),
    defaultBillFromAddressIdDomain: Yup.string(),
    defaultTaxId: Yup.string(),
    defaultVatId: Yup.string(),
    organicControlPointNumber: Yup.string(),
  });

  const resolver = useYupValidationResolver(formSchema);
  const { register, handleSubmit, reset, setError } = useForm<CompanyDataFormState>({
    resolver,
    values: {
      ...defaultValues,
      companyName: currentPartner?.name ?? '',
      streetAddress: currentPartner?.settings?.billFromAddress?.street ?? '',
      city: currentPartner?.settings?.billFromAddress?.city ?? '',
      postalCode: currentPartner?.settings?.billFromAddress?.postalCode ?? '',
      defaultBillFromAddressId: currentPartner?.settings?.billFromAddressIdentifier ?? '',
      defaultBillFromAddressIdDomain: currentPartner?.settings?.billFromAddressIdentifierDomain ?? '',
      defaultTaxId: currentPartner?.settings?.billFromFederalTaxIdentifier ?? '',
      defaultVatId: currentPartner?.settings?.billFromVatIdentifier ?? '',
      organicControlPointNumber: currentPartner?.settings?.organicControlPointNumber ?? '',
    },
  });

  const onSubmit = (submission: CompanyDataFormState) => {
    if (!currentPartner) {
      return;
    }

    const billFromAddress = {
      ...currentPartner?.settings?.billFromAddress,
      name: submission.companyName,
      street: submission.streetAddress,
      city: submission.city,
      postalCode: submission.postalCode,
      countryCode,
    };

    let settings;
    if (currentPartner?.settings) {
      settings = {
        ...currentPartner?.settings,
        billFromAddressIdentifier: submission.defaultBillFromAddressId,
        billFromAddressIdentifierDomain: addressIdDomain,
        billFromFederalTaxIdentifier: submission.defaultTaxId,
        billFromVatIdentifier: submission.defaultVatId,
        organicControlPointNumber: submission.organicControlPointNumber,
        billFromAddress,
      };
    }

    const updatedPartner: UpdatePartnerDTO = {
      name: submission.companyName,
      settings,
    };

    const payload = {
      currentPartnerId: currentPartner.id,
      updatedPartner,
    };

    mutate(payload, {
      onSuccess: () => {
        addNotification(t('settings:companyData.onSuccess'));
      },
      onError: (err) => {
        if (err.message.includes('already belongs to another partner')) {
          addNotification(t('settings:companyData.identiferAlreadyInUse'), NotificationType.error);
        } else {
          processSubmissionErrors<CompanyDataFormState>({
            error: err as Error,
            setInputError: setError,
            setNonInputErrors,
            defaultData: defaultValues,
            t,
          });
        }
      },
    });
  };

  useEffect(() => {
    if (currentPartner?.settings?.billFromAddress) {
      const initialCountryCode = currentPartner.settings.billFromAddress.countryCode;
      const initialAddressIdDomain = currentPartner.settings.billFromAddressIdentifierDomain;

      setCountryCode(initialCountryCode);

      if (initialAddressIdDomain) {
        setAddressIdDomain(initialAddressIdDomain);
      }
    }
  }, [currentPartner]);

  if (!currentPartner) {
    return null;
  }

  return (
    <Page>
      <Page.Head title={t('common:mainMenu.settings')} />

      <Page.Section leftAside={<SettingsSidebar />}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          id="companyData"
          data-testid="companyData"
          className="w-full divide-y divide-gray-200 *:py-6 first:*:pt-0 last:*:pb-0"
        >
          <ValidationErrors errors={nonInputErrors} />
          <Section.Horizontal
            title={t('settings:sidebar.companyData')}
            description={t('settings:companyData.subTitle')}
          >
            <Input label={t('settings:companyData.form.companyName')} {...register('companyName')} required />

            <Input label={t('settings:companyData.form.streetAddress')} {...register('streetAddress')} />

            <Input label={t('settings:companyData.form.city')} {...register('city')} />

            <Input label={t('settings:companyData.form.postalCode')} {...register('postalCode')} />

            <Dropdown
              label={t('settings:companyData.form.country')}
              options={countriesAsDropdown()}
              value={countryCode}
              onChange={(value) => setCountryCode(value)}
              dropdownDisplayTestId="countryDropdown"
            />
          </Section.Horizontal>
          <Section.Horizontal
            title={t('settings:companyData.form.defaultSettings')}
            description={t('settings:companyData.form.defaultSettingsSubtitle')}
          >
            <Input
              label={t('settings:companyData.form.defaultBillFromAddressId')}
              description={t('settings:companyData.form.defaultBillFromAddressIdSubtitle')}
              {...register('defaultBillFromAddressId')}
            />

            <Dropdown
              label={t('settings:companyData.form.defaultBillFromAddressIdDomain')}
              description={t('settings:companyData.form.defaultBillFromAddressIdDomain')}
              name="addressIdDomain"
              value={addressIdDomain}
              options={[
                { value: 'GS1', label: 'GS1' },
                { value: 'DUNS', label: 'DUNS', testId: 'dunsOption' },
                { value: 'VAT_DE', label: 'VAT' },
                { value: 'FED_TAX', label: 'Federal Tax' },
              ]}
              onChange={(value?: AddressIdDomain) => value && setAddressIdDomain(value)}
              dropdownDisplayTestId="domainDropdown"
            />

            <Input
              label={t('settings:companyData.form.defaultTaxId')}
              description={t('settings:companyData.form.defaultTaxIdSubtitle')}
              {...register('defaultTaxId')}
            />

            <Input
              label={t('settings:companyData.form.defaultVatId')}
              description={t('settings:companyData.form.defaultVatIdSubtitle')}
              {...register('defaultVatId')}
            />

            <Input
              label={t('settings:companyData.form.organicControlPointNumber')}
              description={t('settings:companyData.form.organicControlPointNumberSubtitle')}
              {...register('organicControlPointNumber')}
            />
          </Section.Horizontal>
          <div>
            <Button
              className="mr-3"
              onClick={() => {
                reset();
                setCountryCode(currentPartner.settings?.billFromAddress?.countryCode);
                setAddressIdDomain(currentPartner.settings?.billFromAddressIdentifierDomain);
              }}
              variant="secondary"
              disabled={isLoading}
              analyticsId="company-data:update-cancel"
            >
              {t('settings:companyData.form.cancel')}
            </Button>
            <Button type="submit" form="companyData" loading={isLoading} analyticsId="company-data:update_save">
              {t('common:save')}
            </Button>
          </div>
        </Form>
      </Page.Section>
    </Page>
  );
}
