import { format, isPast } from 'date-fns';

const userLanguage = typeof window !== 'undefined' ? window.navigator.language : undefined;
const formatDateTime = (str: string, options: Intl.DateTimeFormatOptions, locale = userLanguage): string => {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    return '';
  }
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const formatDay = (str?: string, locale = userLanguage): string => {
  if (!str) return '';
  return formatDateTime(str, { year: 'numeric', month: '2-digit', day: '2-digit' }, locale);
};

export const formatDayAndTime = (str: string, locale = userLanguage): string => {
  return formatDateTime(
    str,
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    locale,
  );
};

export const formatTimestamp = (timestamp?: number) => {
  if (!timestamp) {
    return undefined;
  }

  const date = new Date(timestamp * 1000);
  if (!date || isNaN(date.getTime())) {
    return undefined;
  }

  return formatDay(formatDateToISO(date));
};

export const formatDateToISO = (date: Date): string => format(date, 'yyyy-MM-dd');

export const isDateInPast = (date: Date) => isPast(date);

//https://www.notion.so/procuros/Dates-Timestamps-Timezones-904a5168105c497ebef01d929b29309c?pvs=4
export const setToMidday = (date: string | number): Date => {
  const newDate = new Date(date);
  return new Date(newDate.setHours(12));
};

export const isValidDate = (dateString: string): boolean => {
  const dateObject = new Date(dateString);

  return !isNaN(dateObject.getTime()) && dateString === dateObject.toISOString().slice(0, 10);
};

const isDateInLastInterval = (interval: number, date: string) =>
  new Date().getTime() - new Date(date).getTime() < interval;
export const isDateInLastMinute = (date: string) => isDateInLastInterval(1000 * 60, date);

export const fromStringDateToProcurosTimestamp = (date: string): number => setToMidday(date).getTime() / 1000;
