import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Radio } from 'components/Form/Radio/Radio';
import { Section } from 'components/Display/Section/Section';
import { useCurrentPartner, useUpdateCurrentPartner } from 'services/repositories/partners/partners';
import { logError } from 'services/logging/logging';
import { PartnerDTO } from 'support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { useInfiniteRelationships } from 'services/repositories/relationships/relationships';
import { useIsMutating } from '@tanstack/react-query';
import { Translate } from 'components/Translate/Translate';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input/Input/Input';
import { Textarea } from 'components/Form/Input/Textarea/Textarea';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { nonNullable } from 'support/helpers/generic/generic';
import { Button } from 'components/Form/Button/Button';

const QuestionnaireWrapper = ({
  setIsLoading,
  onComplete,
}: {
  setIsLoading: (isLoading: boolean) => void;
  onComplete: () => void;
}) => {
  const { t } = useTranslation();
  const { data: relationships, isLoading: isLoadingRelationships } = useInfiniteRelationships({ variables: {} });
  const { data: currentPartner, isLoading: isLoadinCurrentPartner } = useCurrentPartner();
  const { mutate: updateCurrentPartner } = useUpdateCurrentPartner();
  const partnerRequestingIntegration = relationships?.pages[0]?.data?.filter(
    (relationship) =>
      relationship.sender_partner?.id === currentPartner?.id ||
      relationship.receiver_partner?.id === currentPartner?.id,
  );

  const partnerRequestingIntegrationNames = Array.from(
    new Set(
      (
        partnerRequestingIntegration?.map((relationship) => {
          return relationship?.sender_partner?.id === currentPartner?.id
            ? relationship?.receiver_partner?.name
            : relationship?.sender_partner?.name;
        }) || []
      ).filter(nonNullable),
    ),
  );

  const defaultValues = useMemo(
    () => generateDefaultValues(currentPartner, partnerRequestingIntegrationNames),
    [currentPartner, partnerRequestingIntegrationNames],
  );
  const [submissionErrors, setSubmissionErrors] = useState<Array<string>>([]);

  const formMethods = useForm<QuestionnaireForm>({
    values: defaultValues,
    resolver: yupResolver<QuestionnaireForm>(questionnaireSchema(t, partnerRequestingIntegrationNames)),
  });
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    setIsLoading(isLoadingRelationships || isLoadinCurrentPartner);
  }, [isLoadinCurrentPartner, isLoadingRelationships, setIsLoading]);

  const submit = (data: QuestionnaireForm) => {
    if (!currentPartner) {
      return logError(new Error('No current partner found'));
    }
    const updatedPartner = formatPartnerData(data, currentPartner, t);
    updateCurrentPartner(
      { currentPartnerId: currentPartner.id, updatedPartner },
      {
        onError: (error) => {
          processSubmissionErrors({
            error,
            t,
            setInputError: setError,
            setNonInputErrors: setSubmissionErrors,
            defaultData: defaultValues,
          });
        },
        onSuccess: onComplete,
      },
    );
  };

  const [otherERP] = useWatch({
    control,
    name: ['otherErp.checked'],
  });

  return (
    <div className="space-y-10">
      <ValidationErrors errors={submissionErrors} />
      <Form onSubmit={handleSubmit(submit)} className="space-y-10" id="onboarding-questionnaire">
        <Section.Vertical>
          <Input
            label={t('onboarding:steps.questionnaire.erpUsed.label')}
            description={t('onboarding:steps.questionnaire.erpUsed.description')}
            {...register('yourErp')}
            errors={errors.yourErp}
            required
          />
          <FormProvider {...formMethods}>
            {partnerRequestingIntegrationNames.map((partnerName) => {
              return <PartnerIntegrationQuestion partnerName={partnerName} key={partnerName} />;
            })}
          </FormProvider>
          <Radio.Group label={t('onboarding:steps.questionnaire.section.otherERP.title')}>
            <Radio.Item label={t('common:no')} value="false" {...register('otherErp.checked')} />
            <Radio.Item label={t('common:yes')} value="true" {...register('otherErp.checked')} />
          </Radio.Group>
          {otherERP === 'true' ? (
            <Textarea
              required
              label={t('common:pleaseSpecify')}
              {...register('otherErp.answer')}
              errors={errors.otherErp?.answer}
            />
          ) : null}
        </Section.Vertical>
      </Form>
    </div>
  );
};

type UpdatePartnerPayload = Parameters<ReturnType<typeof useUpdateCurrentPartner>['mutate']>['0']['updatedPartner'];

type QuestionnaireForm = {
  yourErp: string;
  otherErp: { checked: 'true' | 'false'; answer?: string };
  partnerIntegration: Record<string, { checked: 'true' | 'false'; answer?: string }>;
};

const formatPartnerData = (data: QuestionnaireForm, current: PartnerDTO, t: TFunction): UpdatePartnerPayload => {
  const { yourErp, otherErp, partnerIntegration } = data;

  return {
    ...current,
    onboarding_questionnaire: {
      ...Object.keys(partnerIntegration).reduce(
        (acc, partnerName) => {
          const value = partnerIntegration[partnerName];
          if (typeof value !== 'string') {
            acc[t('onboarding:steps.questionnaire.section.partnerIntegration.title', { partner: partnerName })] =
              value.checked === 'true' ? value.answer! : 'NO';
          }
          return acc;
        },
        {} as Record<string, string>,
      ),
      [t('onboarding:steps.questionnaire.section.erpUsed.title')]: yourErp,
      [t('onboarding:steps.questionnaire.section.otherERP.title')]:
        otherErp.checked === 'true' ? `YES | ${data.otherErp.answer}` : 'NO',
    },
  };
};

const BackCTA = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const isMutating = useIsMutating();
  return (
    <Button
      onClick={onClick}
      variant="secondary"
      disabled={Boolean(isMutating)}
      analyticsId="onboarding_questionnaire_back"
    >
      {t('common:back')}
    </Button>
  );
};

const NextCTA = () => {
  const { t } = useTranslation();
  const isMutating = useIsMutating();
  return (
    <Button
      type="submit"
      form="onboarding-questionnaire"
      loading={Boolean(isMutating)}
      analyticsId="onboarding_questionnaire_continue"
    >
      {t('onboarding:steps.questionnaire.cta.complete')}
    </Button>
  );
};

const Title = () => <Translate i18nKey="onboarding:steps.questionnaire.title" />;

export const Questionnaire = Object.assign(QuestionnaireWrapper, { BackCTA, NextCTA, Title });

const generateDefaultValues = (
  currentPartner: PartnerDTO | undefined,
  partnerNames: Array<string>,
): QuestionnaireForm => {
  const defaultPartner = {
    name: '',
    onboardingSteps: [],
  };

  return {
    ...defaultPartner,
    ...currentPartner,
    yourErp: '',
    otherErp: { checked: 'false', answer: '' },
    partnerIntegration: partnerNames.reduce(
      (acc, partnerName) => {
        acc[partnerName] = { checked: 'false', answer: '' };
        return acc;
      },
      {} as Record<string, { checked: 'true' | 'false'; answer?: string }>,
    ),
  };
};

const questionnaireSchema = (t: TFunction, partnerNames: Array<string>) =>
  Yup.object({
    yourErp: Yup.string().required(
      t('common:form.required', { field: t('onboarding:steps.questionnaire.erpUsed.label') }),
    ),
    otherErp: Yup.object({
      checked: Yup.string().oneOf(['true', 'false']).required(),
      answer: Yup.string().when('checked', {
        is: 'true',
        then: (schema) => schema.required(t('common:form.simpleRequired')),
      }),
    }).required(),
    partnerIntegration: Yup.object().shape<Record<string, any>>({
      ...partnerNames.reduce(
        (acc, partnerName) => {
          acc[partnerName] = Yup.object({
            checked: Yup.string().oneOf(['true', 'false']).required(),
            answer: Yup.string().when('checked', {
              is: 'true',
              then: (schema) => schema.required(t('common:form.simpleRequired')),
            }),
          }).required();
          return acc;
        },
        {} as Record<string, any>,
      ),
    }),
  });

const PartnerIntegrationQuestion = ({ partnerName }: { partnerName: string }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<QuestionnaireForm>();
  const partnerIntegration = useWatch({ name: `partnerIntegration.${partnerName}.checked` });

  return (
    <>
      <Radio.Group label={t('onboarding:steps.questionnaire.section.partnerIntegration.title', { partnerName })}>
        <Radio.Item label={t('common:no')} value="false" {...register(`partnerIntegration.${partnerName}.checked`)} />
        <Radio.Item label={t('common:yes')} value="true" {...register(`partnerIntegration.${partnerName}.checked`)} />
      </Radio.Group>
      {partnerIntegration === 'true' ? (
        <Textarea
          required
          label={t('common:pleaseSpecify')}
          {...register(`partnerIntegration.${partnerName}.answer`)}
          errors={errors.partnerIntegration?.[partnerName]?.answer}
        />
      ) : null}
    </>
  );
};
