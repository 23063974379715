import { ReactNode } from 'react';
import { useOnboarding } from 'services/onboarding/onboarding';
import { OnboardingStepper, Step } from 'components/OnboardingStepper/OnboardingStepper';
import { Account } from './components/Account/Account';
import { Questionnaire } from './components/Questionnaire/Questionnaire';
import { Welcome } from './components/Welcome/Welcome';
import { IOnboardingStepKeys, IOnboardingStepState } from 'support/types/dtos';

const Step1: Step = {
  label: Welcome.Title,
  component: Welcome,
  nextCta: Welcome.NextCTA,
};

const Step2: Step = {
  label: Account.Title,
  component: Account,
  nextCta: Account.NextCTA,
  backCta: Account.BackCTA,
};

const Step3: Step = {
  label: Questionnaire.Title,
  component: Questionnaire,
  nextCta: Questionnaire.NextCTA,
  backCta: Questionnaire.BackCTA,
};

export const Onboarding = ({ children }: { children: ReactNode }) => {
  const { steps, isFinished, isLoading } = useOnboarding();

  if (isLoading) {
    return null;
  }

  if (isFinished) {
    return <>{children}</>;
  }

  const accountSetupIsFinished = steps?.some((step) =>
    step.required?.some(
      (requiredStep) =>
        requiredStep.key === IOnboardingStepKeys.CREATE_COMPANY &&
        requiredStep.state === IOnboardingStepState.COMPLETED,
    ),
  );

  if (!accountSetupIsFinished) {
    return <OnboardingStepper steps={[Step1, Step2, Step3]} />;
  }

  return <>{children}</>;
};
