import { LoadingLogo } from 'components/Loading/LoadingLogo';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';

type InfiniteScrollProps = {
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
  noMoreItemsComponent?: ReactNode;
  noMoreItemsEnabled: boolean;
};

export function InfiniteScroll({
  hasMore,
  loading,
  loadMore,
  noMoreItemsComponent,
  noMoreItemsEnabled,
}: InfiniteScrollProps) {
  const { t } = useTranslation();
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: '0px 0px 0px 0px',
  });

  return (
    <div className="flex min-h-px w-full justify-center py-12" ref={sentryRef}>
      {loading ? (
        <span className="size-12 origin-center">
          <LoadingLogo />
        </span>
      ) : null}

      {!hasMore && !loading && noMoreItemsEnabled
        ? noMoreItemsComponent ?? (
            <span className="text-gray-500">{t('common:components.infiniteScroll.noMoreItems')}</span>
          )
        : null}
    </div>
  );
}
