// hooks/useRelationshipData.ts
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { useInfiniteRelationships, useRelationship } from 'services/repositories/relationships/relationships';
import { PartnerDTO, RelationshipV2, DocumentType } from 'support/types';

type UseRelationshipDataProps = {
  relationshipId: string | undefined;
  sourceMessageId: string | undefined;
  documentType: DocumentType | undefined;
};

type UseRelationshipDataResult = RelationshipV2 | undefined;

export const useRelationshipData = ({
  relationshipId,
  sourceMessageId,
  documentType,
}: UseRelationshipDataProps): UseRelationshipDataResult => {
  const { data: currentPartner } = useCurrentPartner();
  const { data: sourceMessage } = useMessageWithProcessSpecification({
    variables: { messageId: sourceMessageId },
    staleTime: Infinity,
    enabled: !!sourceMessageId,
  });

  const otherPartner: PartnerDTO | undefined =
    currentPartner && sourceMessage?.data && sourceMessage.data.sender_partner?.id === currentPartner?.id
      ? sourceMessage.data.receiver_partner || undefined
      : sourceMessage?.data.sender_partner || undefined;

  // Load relationship using partner id and message types
  const { data: relationships } = useInfiniteRelationships({
    variables: {
      query: {
        perPage: '100',
        filterValues: otherPartner?.id
          ? [
              {
                key: 'partner_id',
                value: otherPartner?.id,
              },
            ]
          : [],
      },
    },
    enabled: Boolean(otherPartner?.id),
  });

  // Load specific relationship using relationship id
  const { data: specificRelationship } = useRelationship({
    variables: {
      relationshipId: relationshipId,
    },
    enabled: !!relationshipId,
  });

  if (relationshipId) {
    return specificRelationship;
  }

  return relationships?.pages
    .flatMap((page) => page.data)
    .find((relationship) => {
      return relationship?.sender_partner?.id === currentPartner?.id && relationship.message_type === documentType;
    });
};
