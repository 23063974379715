import { DataTable, useGenerateColumns } from 'components/DataTable';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { usePinnedColumns } from '../helpers';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ui } from '@procuros/datachecks';
import { DisplayModes } from 'components/DataTable/Types';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';
import { memo, useMemo } from 'react';
import { useDatachecksStore } from 'stores/datachecks/datachecks';

const DATA_PREFIX = 'parties';

type DocumentPartiesProps = {
  config: ui.PartiesSection;
  mode: DisplayModes;
};

export const DocumentParties = memo(({ config, mode }: DocumentPartiesProps) => {
  const processSpecification = useDatachecksStore((state) => state.processSpecification);
  const { label: title, description, fields, options } = config;
  const labels = useMemo(() => {
    const partiesType = processSpecification?.fields?.filter((field: any) => {
      const containsTwoDots = (field.path.split('.') || []).length === 2;
      return containsTwoDots && field.path.includes('parties');
    });
    return (
      partiesType?.reduce((acc: any, field: any) => {
        acc[field.path.split('.')[1]] = field.name;
        return acc;
      }, {}) || {}
    );
  }, [processSpecification]);

  if (!fields?.length) return null;

  return (
    <WebEDISection header={{ title, spacing: 'medium', description }}>
      <div className="space-y-4">
        <DocumentSectionError path={DATA_PREFIX} />
        <DataTableProvider name={DATA_PREFIX}>
          <PartiesTable
            numberOfPinnedColumns={options?.pinned_column_count ?? 2}
            config={config}
            mode={mode}
            labels={labels}
          />
        </DataTableProvider>
      </div>
    </WebEDISection>
  );
});

DocumentParties.displayName = 'DocumentParties';

const PartiesTable = ({
  mode,
  config,
  numberOfPinnedColumns,
  labels,
}: {
  mode: DisplayModes;
  config: ui.PartiesSection;
  numberOfPinnedColumns: number | undefined;
  labels: Record<string, string>;
}) => {
  const { fields } = useDataTableContext();
  const updatedConfig = useMemo(() => {
    return config.fields.map((field, index) => {
      if (index === 0) {
        const extraFields = {
          translateValue: (value: string) => labels[value],
        };
        return {
          ...field,
          extraFields,
        };
      }
      return field;
    });
  }, [config, labels]);

  const columns = useGenerateColumns({ config: updatedConfig });
  const pinnedColumns = usePinnedColumns({ config: updatedConfig, numberOfPinnedColumns });

  return (
    <DataTable
      pinnedColumns={pinnedColumns}
      key={mode}
      meta={{ dataPrefix: DATA_PREFIX, mode }}
      columns={columns}
      data={fields}
      enableGlobalSearch={false}
    />
  );
};
