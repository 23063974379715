import { ArrowDownTrayIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Section } from 'components/Display/Section/Section';
import { ListTable } from 'components/ListTable/ListTable';
import { TableItem } from 'components/ListTable/types';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { downloadReceiverEnvelope } from 'services/repositories/transactions/transactions';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { nonNullable } from 'support/helpers/generic/generic';
import { ConnectionsRoutes, TransactionDTO } from 'support/types';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { useNavigate } from 'react-router-dom';
import { TestingFlowMessageStateDisplay } from 'components/TestingFlowMessageStateDisplay/TestingFlowMessageStateDisplay';

export const ReviewTestMessageInEmailConnector = ({
  transactions,
  pagination,
  isLoading,
  isOrder,
  relationshipId,
}: {
  transactions: Array<TransactionDTO> | undefined;
  pagination: Parameters<typeof ListTable>[0]['pagination'];
  isLoading: boolean;
  isOrder: boolean;
  relationshipId: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tableHeaders = [
    { label: t('connections:testMessagePendingReview.table.headers.documentId'), key: 'test-document' },
    isOrder
      ? undefined
      : { label: t('connections:testMessagePendingReview.table.headers.orderNumber'), key: 'order-number' },
    { label: t('connections:testMessagePendingReview.table.headers.status'), key: 'status' },
    { label: '', key: 'actions' },
    { label: '', key: 'details' },
  ].filter(nonNullable);

  const buildRow = (transaction: TransactionDTO): Array<TableItem> => {
    return [
      {
        key: 'test-document',
        element: <span className="block max-w-36 truncate font-normal">{transaction.externalMessageRef}</span>,
      },
      isOrder
        ? undefined
        : {
            key: 'order-number',
            element: (
              <span className="block max-w-36 truncate">
                {transaction.relatedOrderExternalMessageRef || EMPTY_FIELD}
              </span>
            ),
          },
      {
        key: 'status',
        element: <TestingFlowMessageStateDisplay state={transaction.internalState} receiver />,
      },
      {
        key: 'actions',
        className: 'text-end',
        element: transaction.receiverEnvelope?.fileName ? (
          <Button
            variant="secondary"
            size="small"
            LeftIcon={ArrowDownTrayIcon}
            onClick={() => downloadReceiverEnvelope(transaction.receiverEnvelopeId)}
            analyticsId="testing-flow:receiver_envelope_download"
          >
            {t('common:download')}
          </Button>
        ) : null,
      },
      {
        key: 'details',
        className: 'text-end',
        element:
          transaction.state === 'FAILED' ? (
            <Button
              variant="minimal"
              size="small"
              RightIcon={ChevronRightIcon}
              onClick={() =>
                navigate(
                  routeToPage(ConnectionsRoutes.testRelationshipMessageDetails, {
                    messageId: transaction.messageId,
                    relationshipId,
                  }),
                )
              }
              analyticsId="testing-flow:message_details_navigate"
            >
              {t('connections:testMessagePendingReview.table.actions.viewError')}
            </Button>
          ) : null,
      },
    ].filter(nonNullable);
  };
  return (
    <>
      <Section.Title variant="small">
        {t('connections:testMessagePendingReview.title', {
          documentsCount: transactions?.length,
          count: transactions?.length,
        })}
      </Section.Title>
      <Section.Subtitle>{t('connections:testMessagePendingReview.subtitle')}</Section.Subtitle>
      <ListTable<TransactionDTO>
        headers={tableHeaders}
        data={transactions}
        isLoading={isLoading}
        rowBuilder={buildRow}
        pagination={pagination}
      />
    </>
  );
};
