import { classNames } from 'support/helpers/generic/generic';

const sizeClasses: Record<SidebarBadgeProps['size'], string> = {
  small: 'absolute -right-0.5 -bottom-0.5 text-[10px] px-1.5 py-0.25',
  normal: 'px-2.5 py-0.5',
};

const colorClasses: Record<SidebarBadgeProps['color'], string> = {
  green: 'bg-procuros-green-500',
  red: 'bg-red-400',
};

type SidebarBadgeProps = {
  value: string | number;
  size: 'small' | 'normal';
  color: 'green' | 'red';
};

export const SidebarBadge = ({ value, size, color }: SidebarBadgeProps) => {
  return value ? (
    <div
      className={classNames(
        'flex flex-row rounded-full text-black text-xs font-semibold',
        sizeClasses[size],
        colorClasses[color],
      )}
    >
      <span>{value}</span>
    </div>
  ) : null;
};
