import { ErrorPage } from 'components/Page/ErrorPage';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { logError } from 'services/logging/logging';

export const MainErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  return <ErrorPage />;
};
