type ApplicationLogoSmallProps = {
  className?: string;
};

export function ApplicationLogoSmall({ ...props }: ApplicationLogoSmallProps) {
  return (
    <svg {...props} viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#F0F0F0"
        d="M40,63.2c10,0,15,11.1,25,11.1s15-11.1,25-11.1s14.9,11.1,25,11.1s15-11.1,25-11.1V41c-10,0-15,11.1-25,11.1
S99.9,41,90,41S75,52.1,65,52.1S50,41,40,41V63.2z"
      />
      <path
        fill="#F0F0F0"
        d="M40,96.6c10,0,15,11.1,25,11.1s15-11.1,25-11.1s14.9,11.1,25,11.1s15-11.1,25-11.1V74.3c-10,0-15,11.1-25,11.1
S99.9,74.3,90,74.3S75,85.4,65,85.4S50,74.3,40,74.3V96.6z"
      />
      <path
        fill="#F0F0F0"
        d="M40,129.9c10,0,15,11.1,25,11.1s15-11.1,25-11.1s14.9,11.1,25,11.1s15-11.1,25-11.1v-22.2
c-10,0-15,11.1-25,11.1s-15.1-11.1-25-11.1s-15,11.1-25,11.1s-15-11.1-25-11.1V129.9z"
      />
    </svg>
  );
}
