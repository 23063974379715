import {
  ArrowPathIcon,
  PaperAirplaneIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { InfiniteData } from '@tanstack/react-query';
import { Button } from 'components/Form/Button';
import { Page } from 'components/Page/Page';
import { TransactionStatusDisplay } from 'components/TransactionStatusDisplay/TransactionStatusDisplay';
import { DocumentEnvironmentBadge } from 'pages/Transactions/components/DocumentEnvironmentBadge/DocumentEnvironmentBadge';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RelationshipsResponseDTO, useInfiniteRelationships } from 'services/repositories/relationships/relationships';
import { BulkActionVariables, useRecoverTransactions } from 'services/repositories/transactions/transactions';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { DATA_CHECK_ERROR_CODE, EMPTY_FIELD } from 'support/helpers/const/const';
import { isMessageV2DTO } from 'support/helpers/transactions/transactions';
import {
  Connectors,
  DocumentType,
  MessageDTO,
  MessageV2DTO,
  PartnerDTO,
  RelationshipStatus,
  TransactionFlowState,
  TransactionInternalType,
  UserDTO,
  IssueResolverType,
  TransactionsRoutes,
} from 'support/types';
import { DisplayModes } from 'components/DataTable/Types';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useNavigate } from 'react-router-dom';
import { routeToPage, routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { PriceList } from 'components/WebEDI/PriceList/PriceList';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';
import { useWebEDIErrors } from 'services/webediErrors/webediErrors';
import { ActionButtons } from './ActionButtons/ActionButtons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../../../../components/Display/Tooltip/Tooltip';
import { formatDayAndTime } from '../../../../../support/helpers/dateTime/dateTime';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { DropTransactionModal } from 'pages/Transactions/modals/DropTransactionModal';
import { DeliverTransactionsModal } from 'pages/Transactions/modals/DeliverTransactionsModal';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';

type MessageHeaderProps = {
  message: MessageV2DTO;
  currentPartner: PartnerDTO | undefined;
  relatedTransactions: Array<MessageDTO | MessageV2DTO>;
  webediV2: boolean;
  mode: DisplayModes;
  issueResolverLabel?: string;
  isLoading?: boolean;
};

export function MessageHeader({
  message,
  relatedTransactions,
  currentPartner,
  webediV2,
  mode,
  issueResolverLabel,
  isLoading,
}: MessageHeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { track } = useAnalytics();

  const isPartnerReceiver = message.receiver_partner?.id === currentPartner?.id;

  const hasDatachecksError = message?.blocking_error?.code === DATA_CHECK_ERROR_CODE;
  const [issueResolver]: [IssueResolverType, string] = useTransactionActionRequired()(message?.issue_resolver_id);

  const [isDropTransactionModalOpen, setIsDropTransactionModalOpen] = useState(false);
  const [isManualDeliverTransactionModalOpen, setIsManualDeliverTransactionModalOpen] = useState(false);

  const { mutate, isLoading: isRecoveringTransaction } = useRecoverTransactions();
  const { numberOfErrors } = useWebEDIErrors();

  const retrigger = useCallback(async () => {
    if (!message) return;
    const payload: BulkActionVariables = {
      items: [{ transactionId: message.id }],
    };

    mutate(payload, {
      onSuccess: () => {
        track('transaction_recovered', {
          transaction_id: message.id,
          type: TransactionInternalType.message,
        });

        addNotification({
          title: t('webedi:notifications.recover.successful'),
          subtitle: t('webedi:notifications.recover.successful_description'),
        });
      },
      onError: () => {
        addNotification(t('webedi:notifications.recover.failed'), NotificationType.error);
      },
    });
  }, [message, mutate, t, track]);

  const otherPartnerId =
    currentPartner && message && message.sender_partner?.id === currentPartner?.id
      ? message.receiver_partner?.id
      : message.sender_partner?.id;

  const receiverPartnerID = message.receiver_partner?.id;

  const { data: relationships } = useInfiniteRelationships({
    variables: {
      query: {
        perPage: '100',
        filterValues: [
          {
            key: 'partner_id',
            value: otherPartnerId || '',
          },
        ],
      },
    },
    enabled: Boolean(otherPartnerId && currentPartner),
  });

  const enabledDocumentsTypes = useMemo(() => {
    return relationships && currentPartner && message.flow !== TransactionFlowState.test
      ? getRelationshipsAsWebEDISender(relationships, currentPartner.id).map(
          (relationship) => relationship.message_type,
        )
      : [];
  }, [currentPartner, message.flow, relationships]);

  const {
    customer_can_retry: canRetry,
    type: documentType,
    blocking_error: blockingError,
    external_message_ref: externalMessageRef,
  } = message;
  const canDropTransaction = blockingError ? message.customer_can_drop_message : null;

  const onEditCancel = useCallback(() => {
    navigate(routeToTransactionPage(TransactionInternalType.message, message.id));
  }, [message.id, navigate]);

  const { enabled: priceCatalogsEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS);

  function getLatestUpdateInfo(message: MessageV2DTO) {
    let updatedAt: string | undefined;
    let updatedBy: UserDTO | undefined;

    // Check if we have the latest_user_update and check if it's actually a user
    // by checking the isAdmin attribute presence, then cast it correctly (field is polymorphic)
    if (message.latest_user_update?.caused_by && 'isAdmin' in message.latest_user_update.caused_by) {
      updatedAt = message.latest_user_update.created_at;
      updatedBy = message.latest_user_update.caused_by as UserDTO;
    }

    if (!updatedBy || !updatedAt) {
      return null;
    }

    const description = updatedBy.isAdmin
      ? t('webedi:viewDocument.titles.latestUpdateInfo.procuros.description', {
          dateTime: formatDayAndTime(updatedAt),
        })
      : t('webedi:viewDocument.titles.latestUpdateInfo.partner.description', {
          userName: updatedBy.name,
          dateTime: formatDayAndTime(updatedAt),
        });

    const tooltipContent = updatedBy.isAdmin
      ? t('webedi:viewDocument.titles.latestUpdateInfo.procuros.tooltip')
      : t('webedi:viewDocument.titles.latestUpdateInfo.partner.tooltip', {
          userName: updatedBy.name,
          partnerName: updatedBy.partner?.name ?? '',
        });

    return (
      <div className="flex items-center gap-3">
        <Page.Description>{description}</Page.Description>
        {(updatedBy.isAdmin || updatedBy.partner) && (
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="mr-2 size-4 text-gray-400 hover:text-procuros-green-500" />
            </TooltipTrigger>
            <TooltipContent>{tooltipContent}</TooltipContent>
          </Tooltip>
        )}
      </div>
    );
  }

  const latestUpdateInfo = isMessageV2DTO(message) ? getLatestUpdateInfo(message) : null;

  const actions = useMemo(() => {
    if (webediV2) {
      let finalActions: Array<React.ReactNode> = [];
      if (canDropTransaction) {
        let action = (
          <Button
            LeftIcon={TrashIcon}
            analyticsId="transactions:discard_message"
            variant="secondary"
            onClick={() => setIsDropTransactionModalOpen(true)}
            key={`discard-message-${message.id}`}
          >
            {t('transactions:bulk_discard_modal.action')}
          </Button>
        );

        // show option to mark as manually imported if partner is receiver
        if (isPartnerReceiver && !hasDatachecksError && issueResolver === IssueResolverType.CURRENT_PARTNER) {
          action = (
            <MenuButton
              key={`close-issue-${message.id}`}
              text={t('transactions:view.discard_menu')}
              variant="secondary"
              analyticsId="transactions:close_issue"
              size="base"
            >
              <MenuButtonOption
                onClick={() => {
                  setIsManualDeliverTransactionModalOpen(true);
                }}
                className="flex-col border-b border-gray-100 last:border-b-0 "
                analyticsId="transactions:manually_deliver"
              >
                <div className="max-w-80 py-1 text-left">
                  <p className="mb-2 text-sm font-medium text-gray-900">
                    {t('transactions:bulk_discard_manual.title')}
                  </p>
                  <p className="text-sm text-gray-500">{t('transactions:bulk_discard_manual.description')}</p>
                </div>
              </MenuButtonOption>
              <MenuButtonOption
                onClick={() => {
                  setIsDropTransactionModalOpen(true);
                }}
                className="flex-col border-b border-gray-100 last:border-b-0 "
                analyticsId="transactions:drop"
              >
                <div className="max-w-80 py-1 text-left">
                  <p className="mb-2 text-sm font-medium text-gray-900">{t('transactions:bulk_discard.title')}</p>
                  <p className="text-sm text-gray-500">{t('transactions:bulk_discard.description')}</p>
                </div>
              </MenuButtonOption>
            </MenuButton>
          );
        }

        finalActions.push(action);
      }

      if (canRetry) {
        finalActions.push(
          <Button
            variant="secondary"
            onClick={retrigger}
            LeftIcon={ArrowPathIcon}
            loading={isRecoveringTransaction}
            key={`retry-${message.id}-${isRecoveringTransaction}`}
            analyticsId="webedi:document_retry"
          >
            {t('transactions:list.bulk_retrigger')}
          </Button>,
        );
      }

      if (blockingError && issueResolver === IssueResolverType.CURRENT_PARTNER) {
        finalActions.push(
          <Button
            variant="secondary"
            onClick={() => {
              navigate(routeToPage(TransactionsRoutes.edit, { id: message.id }));
            }}
            LeftIcon={PencilSquareIcon}
            analyticsId="webedi:document_edit"
          >
            {t('webedi:viewDocument.editCTA')}
          </Button>,
        );
      }

      if (finalActions.length === 0) {
        finalActions.push(
          <ActionButtons
            fromDocument={message}
            fromDocumentType={documentType}
            relatedTransactions={relatedTransactions}
            enabledDocumentTypes={enabledDocumentsTypes as Array<DocumentType>}
          />,
        );
      }

      if (mode === DisplayModes.edit) {
        finalActions = [];
        // add apply pricelist if invoice
        if (
          (documentType === DocumentType.invoice || documentType === DocumentType.creditNote) &&
          priceCatalogsEnabled
        ) {
          finalActions.push(<PriceList receiverPartnerId={receiverPartnerID} />);
        }
        finalActions.push([
          <Button
            variant="secondary"
            onClick={onEditCancel}
            key={`cancel-${message.id}`}
            analyticsId="webedi:edit_cancel"
          >
            {t('common:cancel')}
          </Button>,
          <Button
            variant="primary"
            type="submit"
            form="webedi-document"
            LeftIcon={PaperAirplaneIcon}
            loading={isLoading}
            key={`edit-${message.id}`}
            disabled={numberOfErrors > 0}
            analyticsId="webedi:edit_send"
          >
            {t('common:resend')}
          </Button>,
        ]);
      }

      return <div className="flex items-center space-x-2">{finalActions}</div>;
    }

    return (
      <ActionButtons
        fromDocument={message}
        fromDocumentType={documentType}
        relatedTransactions={relatedTransactions}
        enabledDocumentTypes={enabledDocumentsTypes as Array<DocumentType>}
      />
    );
  }, [
    webediV2,
    message,
    documentType,
    relatedTransactions,
    enabledDocumentsTypes,
    canDropTransaction,
    canRetry,
    retrigger,
    isRecoveringTransaction,
    t,
    isLoading,
    onEditCancel,
    mode,
    numberOfErrors,
  ]);

  return (
    <div className="-mt-2 flex w-full flex-row items-center justify-between">
      <div className="flex flex-col">
        <div className="flex items-center gap-3">
          <Page.Title>
            {documentType
              ? t('webedi:senderEnvelope.titles.knownDocumentType', {
                  documentType: t(`common:messageTypes.singular.${documentType}`),
                  externalReference: externalMessageRef || EMPTY_FIELD,
                })
              : externalMessageRef
                ? t('webedi:viewDocument.titles.unknownDocumentType.withExternalReference', {
                    externalReference: externalMessageRef,
                  })
                : t('webedi:viewDocument.titles.unknownDocumentType.withoutExternalReference')}
          </Page.Title>
          <TransactionStatusDisplay transaction={message} currentPartner={currentPartner} />
          {blockingError && (
            <Badge hasDot color={BadgeColor.red}>
              {t('webedi:viewDocument.titles.issueResolver', { resolver: issueResolverLabel })}
            </Badge>
          )}
          <DocumentEnvironmentBadge flow={message.flow} /> {/* New line for additional content */}
        </div>
        {latestUpdateInfo}
      </div>
      {actions}

      <DropTransactionModal
        transactionIds={[message.id]}
        open={isDropTransactionModalOpen}
        onComplete={() => {
          setIsDropTransactionModalOpen(false);
        }}
      />

      <DeliverTransactionsModal
        transactionIds={[message.id]}
        open={isManualDeliverTransactionModalOpen}
        onComplete={() => {
          setIsManualDeliverTransactionModalOpen(false);
        }}
      />
    </div>
  );
}

const getRelationshipsAsWebEDISender = (
  relationships: InfiniteData<RelationshipsResponseDTO>,
  currentPartnerId: string,
) => {
  return (
    relationships?.pages.flatMap(
      (page) =>
        page.data?.filter(
          (relationship) =>
            relationship.sender_partner?.id === currentPartnerId &&
            [RelationshipStatus.ACTIVE, RelationshipStatus.LIVE].includes(RelationshipStatus[relationship.status]) &&
            Connectors.WEB_EDI === relationship.sender_integration?.production?.connector,
        ) || [],
    ) || []
  );
};
