import { useCallback, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { produce } from 'immer';
import set from 'lodash/set';
import { ColumnDef, ColumnMeta } from '@tanstack/react-table';
import { ColumnTypesInternal } from '../Types';
import get from 'lodash/get';

export type Props<TData, TValue> = {
  columns: Array<ColumnDef<TData, TValue>>;
  rowSelection: Record<string, unknown>;
  tableData: Array<TData>;
  setTableData: Dispatch<SetStateAction<Array<TData>>>;
};

export const useBulkActions = <TData, TValue>({
  columns,
  rowSelection,
  tableData,
  setTableData,
}: Props<TData, TValue>) => {
  const [isBulkEditModalOpen, setBulkEditModalOpen] = useState(false);
  const handleBulkEditModalClose = useCallback(() => {
    setBulkEditModalOpen(false);
  }, []);

  const handleBulkEditButtonClick = useCallback(() => {
    setBulkEditModalOpen(true);
  }, []);

  const bulkColumns = useMemo(() => {
    return columns
      .map((column) => {
        return { ...column.meta, id: column.id };
      })
      .filter(
        (columnMeta) =>
          columnMeta && !columnMeta.readOnly && columnMeta.type && !(columnMeta.type in ColumnTypesInternal),
      );
  }, [columns]) as Array<ColumnMeta<TData, TValue> & { id: string }>;

  const handleBulkEdit = useCallback(
    (column?: string, value?: string | number) => {
      const selection = Object.keys(rowSelection).map((item) => parseInt(item, 10));

      const nextState = produce(tableData, (nextData) => {
        selection.forEach((index: number) => {
          const columnId = getColumnId(column as string, nextData[index]);
          set(nextData[index] as NonNullable<unknown>, columnId as string, value);
        });
      }) as Array<TData>;

      // set table state
      setTableData(nextState);
    },
    [rowSelection, tableData, setTableData],
  );

  return { isBulkEditModalOpen, handleBulkEditModalClose, bulkColumns, handleBulkEdit, handleBulkEditButtonClick };
};

const getColumnId = (columnId: string, rowData: any) => {
  // Handle modification groups - we only have an amount column in the table and we need to update different fields according to the type
  if (columnId?.includes('.amount') && columnId?.includes('modification_groups')) {
    const isRelative = get(rowData, columnId.replace('.amount', '.type')) === 'RELATIVE';
    if (isRelative) {
      return columnId.replace('.amount', '.percentage');
    }
  }
  return columnId;
};
