import { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, DialogBackdrop, Field, Label } from '@headlessui/react';
import { Button } from '../../../Form/Button';
import { Dropdown } from '../../../Form/Dropdown';
import { RowData } from '@tanstack/table-core';
import { ColumnMeta } from '@tanstack/react-table';
import { getInputComponent } from './helpers';
import { ui } from '@procuros/datachecks';
import { useTranslation } from 'react-i18next';
import { useEnumerableOptions } from 'components/WebEDI/helpers';

export type Props = {
  isOpen: boolean;
  closeSelf: () => void;
  onApply: (columnId?: string, value?: string | number) => void;
  inputsConfig?: Array<ColumnMeta<RowData, unknown> & { id: string }>;
};

export const BulkEditDialog: FC<Props> = ({ isOpen, closeSelf, inputsConfig = [], onApply }) => {
  const { t } = useTranslation();
  const inputs = useMemo(() => {
    return inputsConfig.map(({ label, id }) => ({ label: label || id, value: id }));
  }, [inputsConfig]);

  const [selectedColumn, setSelectedColumn] = useState(inputs[0]?.value);
  const [bulkValue, setBulkValue] = useState<string | number>('');

  const handleColumnSelect = useCallback((selected: string) => {
    setSelectedColumn(selected);
    setBulkValue('');
  }, []);

  const handleApply = useCallback(() => {
    onApply(selectedColumn, bulkValue);
    closeSelf();
  }, [bulkValue, closeSelf, onApply, selectedColumn]);

  useEffect(() => {
    setSelectedColumn(inputs[0]?.value);
    setBulkValue('');
  }, [inputs, isOpen]);

  const handleBulkValueChange = useCallback((value: SetStateAction<string | number>) => {
    setBulkValue(value);
  }, []);

  return (
    <Dialog open={isOpen} onClose={() => closeSelf()} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 transition duration-300 ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center">
        <DialogPanel
          transition
          className="flex w-[420px] flex-col gap-4 rounded-md border border-gray-300 bg-white p-6 shadow-lg duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <DialogTitle className="text-base font-bold">{t('common:dataTable.bulkEdit.title')}</DialogTitle>
          <BulkEditForm
            key={selectedColumn}
            handleApply={handleApply}
            handleBulkValueChange={handleBulkValueChange}
            handleColumnSelect={handleColumnSelect}
            selectedColumn={selectedColumn}
            bulkValue={bulkValue}
            inputsConfig={inputsConfig}
            inputs={inputs}
            closeSelf={closeSelf}
          />
        </DialogPanel>
      </div>
    </Dialog>
  );
};

type BulkEditFormProps = {
  handleColumnSelect: (selected: string) => void;
  selectedColumn: string;
  inputsConfig: Array<ColumnMeta<RowData, unknown> & { id: string }>;
  inputs: Array<{ label: string; value: string }>;
  bulkValue: string | number;
  handleBulkValueChange: (value: SetStateAction<string | number>) => void;
  handleApply: () => void;
  closeSelf: () => void;
};

/**
 * We just need this since useEnumerableOptions uses useWatch and useWatch doesn't accept a dynamic field name
 * useWatch is need to check for dynamic dropdown options. For example transport_unit on line_items
 */
const BulkEditForm = ({
  handleColumnSelect,
  selectedColumn,
  inputsConfig,
  inputs,
  bulkValue,
  handleBulkValueChange,
  handleApply,
  closeSelf,
}: BulkEditFormProps) => {
  const { t } = useTranslation();
  const config = inputsConfig.find(({ id }) => selectedColumn === id)?.optionalConfig;
  const options = useEnumerableOptions(config);

  const InputComponent = useMemo(() => {
    const selectedInputType = inputsConfig.find(({ id }) => selectedColumn === id)?.type as keyof typeof ui.FieldTypes;
    return getInputComponent(selectedInputType);
  }, [inputsConfig, selectedColumn]);

  return (
    <>
      <Field>
        <Label className="mb-1 block truncate text-sm text-gray-500">
          {t('common:dataTable.bulkEdit.column.label')}
        </Label>
        <Dropdown onChange={handleColumnSelect} value={selectedColumn} options={inputs} />
      </Field>
      <Field>
        <Label className="mb-1 block truncate text-sm text-gray-500">
          {t('common:dataTable.bulkEdit.value.label')}
        </Label>
        <InputComponent
          value={bulkValue}
          placeholder="Enter new value"
          options={options || []}
          onChange={handleBulkValueChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleApply();
            }
          }}
        />
      </Field>
      <div className="flex gap-4">
        <Button variant="secondary" onClick={() => closeSelf()} analyticsId="table_bulk_edit_cancel">
          {t('common:dataTable.bulkEdit.cancel')}
        </Button>
        <Button onClick={handleApply} analyticsId="table_bulk_edit_confirm">
          {t('common:dataTable.bulkEdit.confirm')}
        </Button>
      </div>
    </>
  );
};
