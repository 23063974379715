import { TFunction } from 'i18next';

import {
  ChartDetails,
  DateRange,
  StatChangeType,
  TransactionData,
  TransactionGraphData,
  TransactionSeries,
  TransactionVolumeStats,
  VolumeStats,
} from './types';
import { DocumentType } from 'support/types';

export const convertToSeries = (
  chartDetails: ChartDetails,
  chartDateParam: number,
  allDates: number[],
): TransactionSeries => {
  if (!chartDetails.data.length) {
    return { name: chartDetails.name, data: [] };
  }
  const seriesData: number[][] = [];

  allDates.forEach((date) => {
    if (date > chartDateParam) {
      seriesData.push([date, 0]);
    }
  });

  chartDetails.data.forEach((dataPoint: TransactionData) => {
    const dateTime = new Date(dataPoint.date).getTime();

    if (dateTime > chartDateParam) {
      const dateIndex = seriesData.findIndex((seriesPoint: number[]) => seriesPoint[0] === dateTime);
      if (dateIndex >= 0) {
        seriesData[dateIndex] = [dateTime, dataPoint.count];
      } else {
        seriesData.push([dateTime, dataPoint.count]);
      }
    }
  });

  return {
    name: chartDetails.name,
    data: seriesData.sort((a: number[], b: number[]) => (a[0] < b[0] ? -1 : 1)),
  };
};

export const getAllDates = (dateRangeOptions: DateRange[]) => {
  if (!dateRangeOptions.length) {
    return [];
  }

  const dates: number[] = [];
  let startDate = new Date(Date.now()).setUTCHours(0, 0, 0, 0);
  dates.push(startDate);

  while (startDate > dateRangeOptions[dateRangeOptions.length - 1].value) {
    startDate -= 1000 * 60 * 60 * 24;
    dates.push(startDate);
  }

  return dates;
};

export const sumTransactionCount = (
  transactionsData: TransactionData[] | undefined,
  volumeDateRange: number,
  previousVolumeDateRange?: number,
) => {
  if (!transactionsData) {
    return 0;
  }

  let total = 0;

  transactionsData.forEach((dataPoint: TransactionData) => {
    const date = new Date(dataPoint.date).getTime();
    const count = dataPoint.count;

    if (previousVolumeDateRange) {
      if (date < volumeDateRange && date > previousVolumeDateRange) {
        total += count;
      }
    } else if (date > volumeDateRange) {
      total += count;
    }
  });

  return total;
};

const sumAllTransactionsTypes = (
  transactions: TransactionGraphData | undefined,
  volumeDateRange: number,
  previousVolumeDateRange?: number,
) => {
  return (
    sumTransactionCount(transactions?.orders, volumeDateRange, previousVolumeDateRange) +
    sumTransactionCount(transactions?.shippingNotices, volumeDateRange, previousVolumeDateRange) +
    sumTransactionCount(transactions?.invoices, volumeDateRange, previousVolumeDateRange)
  );
};

export const changePercentage = (original: number, current: number) => {
  if ((!original && !current) || original === current) {
    return 0;
  }
  if (!original && current) {
    return 100;
  }
  return Math.round(((current - original) / original) * 100);
};

export const changeType = (stat: TransactionVolumeStats) => {
  if (!stat.change) {
    return StatChangeType.level;
  } else if (stat.change > 0) {
    return StatChangeType.increase;
  } else {
    return StatChangeType.decrease;
  }
};

export const getVolumeStats = (
  transactions: TransactionGraphData | undefined,
  volumeDateRange: number,
  previousVolumeDateRange: number,
  t: TFunction,
): VolumeStats => {
  const volumeStats: VolumeStats = {
    allTransactions: {
      name: t('dashboard:transactions.allTransactions'),
      count: sumAllTransactionsTypes(transactions, volumeDateRange),
      previousCount: sumAllTransactionsTypes(transactions, volumeDateRange, volumeDateRange - previousVolumeDateRange),
      type: undefined,
    },
    orders: {
      type: DocumentType.order,
      name: t('dashboard:transactions.orders'),
      count: sumTransactionCount(transactions?.orders, volumeDateRange),
      previousCount: sumTransactionCount(
        transactions?.orders,
        volumeDateRange,
        volumeDateRange - previousVolumeDateRange,
      ),
    },
    shippingNotices: {
      type: DocumentType.shippingNotice,
      name: t('dashboard:transactions.shippingNotices'),
      count: sumTransactionCount(transactions?.shippingNotices, volumeDateRange),
      previousCount: sumTransactionCount(
        transactions?.shippingNotices,
        volumeDateRange,
        volumeDateRange - previousVolumeDateRange,
      ),
    },
    invoices: {
      type: DocumentType.invoice,
      name: t('dashboard:transactions.invoices'),
      count: sumTransactionCount(transactions?.invoices, volumeDateRange),
      previousCount: sumTransactionCount(
        transactions?.invoices,
        volumeDateRange,
        volumeDateRange - previousVolumeDateRange,
      ),
    },
  };

  Object.keys(volumeStats).forEach((type) => {
    const stats = volumeStats[type as keyof VolumeStats];

    stats.change = changePercentage(stats.previousCount, stats.count);
    stats.changeType = changeType(stats);
  });

  return volumeStats;
};

export const TRANSACTION_TYPE_OPTIONS: { label: string; value: DocumentType }[] = [
  {
    label: 'transactions:list.filter.messageTypes.ORDER.label',
    value: DocumentType.order,
  },
  {
    label: 'transactions:list.filter.messageTypes.SHIPPING_NOTICE.label',
    value: DocumentType.shippingNotice,
  },
  {
    label: 'transactions:list.filter.messageTypes.INVOICE.label',
    value: DocumentType.invoice,
  },
];
