import { Divider } from 'components/Divider/Divider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { usePasswordSignIn } from 'services/repositories/user/user';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { AuthRoutes, DashboardRoutes } from 'support/types';
import { AuthBodySection } from '../../components/AuthBodySection/AuthBodySection';
import { Credentials } from '../types';
import { Input } from 'components/Form/Input';
import { Button } from 'components/Form/Button';

export const PasswordLoginForm = ({
  toggleLoginMethod,
  defaultData,
}: {
  toggleLoginMethod: () => void;
  defaultData: Credentials;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl: string = location.state?.from || DashboardRoutes.root;
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const { mutate: passwordSigin, isLoading } = usePasswordSignIn();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useFormContext<Credentials>();

  const onPasswordSignin = async (credentials: Credentials) => {
    setNonInputErrors([]);

    passwordSigin(credentials, {
      onSuccess: () => {
        navigate(redirectUrl, { replace: true });
      },

      onError: (err) => {
        processSubmissionErrors({ error: err, setInputError: setError, setNonInputErrors, defaultData, t });
      },
    });
  };

  return (
    <AuthBodySection
      onSubmit={handleSubmit(onPasswordSignin)}
      nonInputErrors={nonInputErrors}
      heading={t('auth:heading.password')}
    >
      <Input
        {...register('email', { required: true })}
        placeholder={t('auth:email')}
        autoComplete="username"
        label={t('auth:emailAddress')}
        type="email"
        errors={errors.email}
        required
        readOnly={isLoading}
      />

      <Input
        {...register('password', { required: true })}
        placeholder={t('auth:password')}
        autoComplete="password"
        type="password"
        label={t('auth:password')}
        errors={errors.password}
        required
        readOnly={isLoading}
      />

      <Button type="submit" fullWidth loading={isLoading} analyticsId="login:login">
        {t('auth:signIn')}
      </Button>

      <div className="flex w-full flex-col gap-8 pt-6">
        <Divider>
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-sm text-gray-500">
            {t('auth:otherOptions.heading')}
          </div>
        </Divider>

        <div className="flex w-full flex-col gap-4">
          <Button
            type="button"
            variant="text"
            className="!text-gray-900 underline"
            fullWidth
            analyticsId="login:toggle-login-method"
            onClick={toggleLoginMethod}
          >
            {t('auth:change-method.loginPasswordless')}
          </Button>

          <Link to={AuthRoutes.forgotPassword} className="w-full">
            <Button
              type="button"
              variant="text"
              className="!text-gray-900 underline"
              fullWidth
              analyticsId="login:forgot-password"
            >
              {t('auth:forgotPassword')}
            </Button>
          </Link>
        </div>
      </div>
    </AuthBodySection>
  );
};
