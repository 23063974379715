import { usePriceCatalogSummary } from 'components/WebEDI/hooks/usePriceCatalogSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from 'support/helpers/dateTime/dateTime';
import { DocumentSummaryContent } from './DocumentSummaryContent';

export const PriceCatalogSummary = () => {
  const { t } = useTranslation();
  const priceCatalogSummary = usePriceCatalogSummary();
  return (
    <DocumentSummaryContent
      items={[
        {
          label: t('webedi:documentSummary.priceCatalog.lineCount.label'),
          value: Math.round(priceCatalogSummary.lineCount),
        },
        {
          label: t('webedi:documentSummary.priceCatalog.creationDate.label'),
          value: formatTimestamp(priceCatalogSummary.creationDate) ?? '—',
        },
      ]}
    />
  );
};
