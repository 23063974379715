import { ArrowDownTrayIcon, ArrowPathIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Section } from 'components/Display/Section/Section';
import {
  SlideOver,
  SlideOverContentSkeleton,
  SlideOverSubtitleSkeleton,
  SlideOverTitleSkeleton,
} from 'components/Display/SlideOver/SlideOver';
import { Divider } from 'components/Divider/Divider';
import { Button } from 'components/Form/Button/Button';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { TestingFlowMessageStateDisplay } from 'components/TestingFlowMessageStateDisplay/TestingFlowMessageStateDisplay';
import { TransactionBlockingError } from 'components/TransactionBlockingError/TransactionBlockingError';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMessage } from 'services/repositories/messages/messages';
import { downloadReceiverEnvelope, useRecoverTransaction } from 'services/repositories/transactions/transactions';
import { useHistoryStore } from 'stores/history/history';
import { DEFAULT_REFETCH_INTERVAL, EMPTY_FIELD } from 'support/helpers/const/const';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionsRoutes, DocumentType, TransactionState } from 'support/types';

type TestMessageDetailsProps = {
  open: boolean;
};

export const TestMessageDetails: FC<TestMessageDetailsProps> = ({ open }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistoryStore();
  const { relationshipId, messageId } = useParams<{ relationshipId: string; messageId: string }>();
  const { data: message, isLoading: isLoadingMessage } = useMessage({
    variables: { messageId },
    enabled: Boolean(messageId),
    keepPreviousData: !open,
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
    staleTime: 0,
  });

  const { mutate, isLoading: isRecoveringMessage } = useRecoverTransaction();

  const onBack = () => {
    relationshipId && navigate(routeToPage(ConnectionsRoutes.testRelationship, { relationshipId }));
  };
  const onClose = () => navigate(routeToPage(ConnectionsRoutes.root));

  const messageData = message?.data;
  const failedContent =
    messageData?.state === TransactionState.failed ? (
      <>
        <div className="flex w-full items-end justify-between">
          <div className="space-y-2">
            <Section.Title variant="small">{t('connections:testMessageDetails.failedStatus.title')}</Section.Title>
            <Section.Subtitle>{t('connections:testMessageDetails.failedStatus.subtitle')}</Section.Subtitle>
          </div>
          {messageData.customer_can_retry ? (
            <Button
              variant="secondary"
              size="small"
              LeftIcon={ArrowPathIcon}
              loading={isRecoveringMessage}
              onClick={() => messageId && mutate({ id: messageId })}
              analyticsId="testing-flow:message-recover"
            >
              {t('connections:testMessageDetails.failedStatus.action')}
            </Button>
          ) : null}
        </div>
        <TransactionBlockingError transaction={messageData} />
        <Divider />
      </>
    ) : null;

  return (
    <SlideOver
      size="large"
      open={open}
      onBack={history.length <= 1 ? undefined : onBack}
      setOpen={onClose}
      title={isLoadingMessage ? <SlideOverTitleSkeleton /> : messageData?.sender_partner?.name}
      subtitle={
        isLoadingMessage ? (
          <SlideOverSubtitleSkeleton />
        ) : (
          `${t('connections:connectionsTest.drawer.subtitle', {
            documentType: t(`common:messageTypes.plural.${messageData?.type}`),
          })} - ${messageData?.external_message_ref}`
        )
      }
      headerImage={
        <PartnerLogo imageUrl={messageData?.sender_partner?.logoUrl} size="large" isLoading={isLoadingMessage} />
      }
    >
      {isLoadingMessage || !messageData ? (
        <SlideOverContentSkeleton />
      ) : (
        <div className="space-y-6">
          {failedContent}
          <div className="space-y-3">
            <Section.Item direction="horizontal" title={t('connections:testMessageDetails.documentId')}>
              {messageData.external_message_ref}
            </Section.Item>
            {messageData.type !== DocumentType.order ? (
              <Section.Item direction="horizontal" title={t('connections:testMessageDetails.orderNumber')}>
                {messageData.related_order?.external_message_ref || EMPTY_FIELD}
              </Section.Item>
            ) : null}
            <Section.Item direction="horizontal" title={t('connections:testMessageDetails.documentStatus')}>
              <div className="flex gap-3">
                <TestingFlowMessageStateDisplay
                  state={messageData.receiver_envelope?.state || messageData.internal_state}
                  receiver
                />
                {messageData.receiver_envelope?.externalDocumentUrl ? (
                  <Link to={messageData.receiver_envelope?.externalDocumentUrl} target="_blank">
                    <Button
                      size="extra-small"
                      variant="secondary"
                      RightIcon={ArrowTopRightOnSquareIcon}
                      analyticsId="testing-flow:document-in-external-system-navigate"
                    >
                      {t('connections:testMessageDetails.viewDocument', {
                        connector: t(`integrations:connector.${messageData.receiver_integration?.connector}.name`),
                      })}
                    </Button>
                  </Link>
                ) : null}
              </div>
            </Section.Item>
            <Section.Item direction="horizontal" title={t('connections:testMessageDetails.deliveryTime')}>
              {messageData.receiver_envelope?.deliveredAt
                ? formatDayAndTime(messageData?.receiver_envelope?.deliveredAt)
                : EMPTY_FIELD}
            </Section.Item>
            <Section.Item direction="horizontal" title={t('connections:testMessageDetails.integrationName')}>
              {messageData.receiver_integration?.name || EMPTY_FIELD}
            </Section.Item>
            {messageData.receiver_envelope?.fileName ? (
              <Section.Item direction="horizontal" title={t('connections:testMessageDetails.fileName')}>
                <span>{messageData.receiver_envelope?.fileName}</span>
                <Button
                  size="extra-small"
                  variant="secondary"
                  iconOnly
                  LeftIcon={ArrowDownTrayIcon}
                  onClick={() =>
                    messageData.receiver_envelope && downloadReceiverEnvelope(messageData.receiver_envelope.id)
                  }
                  analyticsId="testing-flow:document-download"
                />
              </Section.Item>
            ) : null}
            <Section.Item direction="horizontal" title={t('connections:testMessageDetails.receiverTechnicalId')}>
              {messageData.receiver_envelope?.technicalReferenceId || EMPTY_FIELD}
            </Section.Item>
          </div>
        </div>
      )}
    </SlideOver>
  );
};
