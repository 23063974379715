import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from 'components/Display/Alert/Alert';
import { Section } from 'components/Display/Section/Section';
import { Button } from 'components/Form/Button/Button';
import { Pagination } from 'components/ListTable/Pagination/Pagination';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationType, addNotification } from 'stores/notifications/notifications';
import {
  ConnectionsRoutes,
  DocumentType,
  InternalTransactionState,
  PartnerV2,
  TransactionDTO,
  TransactionInternalType,
  TransactionState,
  TransactionsRoutes,
} from 'support/types';
import * as Yup from 'yup';
import { Form } from 'components/Form/Form';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { classNames } from 'support/helpers/generic/generic';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Checkbox } from 'components/Form/Checkbox/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { useRelationshipTestDocuments, useSendTestDocuments } from 'services/repositories/relationships/relationships';
import { TestingFlowMessageStateDisplay } from 'components/TestingFlowMessageStateDisplay/TestingFlowMessageStateDisplay';
import { DEFAULT_REFETCH_INTERVAL, EMPTY_FIELD } from 'support/helpers/const/const';
import { Translate } from 'components/Translate/Translate';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { TFunction } from 'i18next';

const RefetchIndicator = ({
  t,
  isRefetching,
  lastUpdatedAt,
}: {
  t: TFunction;
  isRefetching: boolean;
  lastUpdatedAt: number;
}) => {
  const [secondsToRefetch, setSecondsToRefetch] = useState(DEFAULT_REFETCH_INTERVAL / 1000);

  // Calculate the time left until the next refetch
  // by subtracting the current time from the last updated time
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (lastUpdatedAt) {
        const now = Date.now();
        const nextRefetch = lastUpdatedAt + DEFAULT_REFETCH_INTERVAL;
        const timeLeft = Math.max(nextRefetch - now, 0);

        setSecondsToRefetch(Math.ceil(timeLeft / 1000));
      }
    }, 1000); // Update the countdown every second

    return () => {
      clearInterval(intervalId);
      setSecondsToRefetch(DEFAULT_REFETCH_INTERVAL / 1000);
    };
  }, [lastUpdatedAt]);

  return (
    <div className="mt-2 flex items-center gap-3 rounded-lg bg-gray-50 px-6 py-3 text-sm text-gray-500">
      {isRefetching && <LoadingSpinner sizeClassName="size-5" />}
      {isRefetching
        ? t('connections:connectionTest.testDocumentsSection.refetchIndicator.refetching')
        : t('connections:connectionTest.testDocumentsSection.refetchIndicator.refetchPending', { secondsToRefetch })}
    </div>
  );
};

export const TestDocumentsSection = ({
  relationshipId,
  documentType,
  onSuccess,
}: {
  relationshipId: string;
  documentType: DocumentType | undefined;
  receiverPartner: PartnerV2 | undefined;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentCursor, setCurrentCursor] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ selectedDocumentsId: Array<string> }>({
    defaultValues: { selectedDocumentsId: [] },
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading: isSendingTestDocuments } = useSendTestDocuments();

  const {
    data: transactionsData,
    isFetching: transactionsDataRefetching,
    dataUpdatedAt: transactionsDataUpdatedAt,
  } = useRelationshipTestDocuments({
    variables: {
      relationshipId,
      query: {
        perPage: '5',
        cursor: currentCursor,
      },
    },
    keepPreviousData: !relationshipId,
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
  });

  const onSubmit = ({ selectedDocumentsId }: { selectedDocumentsId: Array<string> }) => {
    mutate(
      { relationshipId, messages: selectedDocumentsId },
      {
        onSuccess: () => {
          onSuccess();
          addNotification({
            title: t('connections:connectionTest.testDocumentsSection.notification.success.title', {
              count: selectedDocumentsId.length,
            }),
            subtitle: t('connections:connectionTest.testDocumentsSection.notification.success.subtitle', {
              count: selectedDocumentsId.length,
            }),
          });
        },
        onError: () => {
          addNotification(t('common:error.generic'), NotificationType.error);
        },
      },
    );
  };

  const tableHeaders: Array<TableHeader> = [
    { label: '', key: 'selection', className: '!pr-0' },
    { label: t('connections:connectionTest.testDocumentsSection.documentId') },
    { label: t('connections:connectionTest.testDocumentsSection.date') },
    { label: t('connections:connectionTest.testDocumentsSection.status') },
    { label: '', key: 'actions' },
  ];

  const buildRow = (transaction: TransactionDTO) => {
    const disabled = transaction.internalState !== InternalTransactionState.pendingRelease;
    const textClasses = classNames('text-sm max-w-36 truncate', disabled ? 'text-gray-400' : 'text-gray-700');

    return [
      {
        element: <Checkbox label="" {...register('selectedDocumentsId')} value={transaction.id} disabled={disabled} />,
        key: `selection-${transaction.id}`,
        className: '!pr-0',
      },
      {
        element: (
          <Link
            target="_blank"
            to={routeToPage(
              transaction.internalType === TransactionInternalType.message
                ? TransactionsRoutes.viewMessage
                : TransactionsRoutes.viewSenderEnvelope,
              { id: transaction.id },
            )}
            className={classNames(textClasses, 'underline block')}
          >
            {transaction.externalMessageRef}
          </Link>
        ),
        key: `documentId-${transaction.id}`,
      },
      {
        element: <div className={textClasses}>{formatDayAndTime(transaction.createdAt) || EMPTY_FIELD}</div>,
        key: `createdAt-${transaction.id}`,
      },
      {
        element: <TestingFlowMessageStateDisplay state={transaction.internalState} receiver={false} />,
        key: `status-${transaction.id}`,
      },
      {
        element:
          transaction.state === TransactionState.failed ? (
            <Button
              variant="minimal"
              size="extra-small"
              RightIcon={ChevronRightIcon}
              onClick={() => {
                navigate(
                  routeToPage(ConnectionsRoutes.testRelationshipTransactionError, {
                    transactionId: transaction.id,
                    relationshipId,
                  }),
                );
              }}
              analyticsId="testing-flow:error-view"
            >
              {t('connections:connectionTest.testDocumentsSection.viewError')}
            </Button>
          ) : null,
        key: `actions-${transaction.id}`,
      },
    ];
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <Section.Title>
          {t('connections:connectionTest.testDocumentsSection.title', {
            documentType: t(`common:messageTypes.plural.${documentType}`).toLowerCase(),
          })}
        </Section.Title>
        <Section.Subtitle>
          <Translate i18nKey="connections:connectionTest.testDocumentsSection.description" />
        </Section.Subtitle>
        <RefetchIndicator isRefetching={transactionsDataRefetching} lastUpdatedAt={transactionsDataUpdatedAt} t={t} />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <ListTable<TransactionDTO>
          headers={tableHeaders}
          data={transactionsData?.items}
          isLoading={false}
          rowBuilder={buildRow}
          emptyState={
            <ListTable.EmptyState cols={tableHeaders.length} background="gray">
              {
                <div className="flex flex-col items-center gap-3">
                  <Translate i18nKey="connections:connectionTest.testDocumentsSection.forceRefetchLoading" />
                </div>
              }
            </ListTable.EmptyState>
          }
        />

        {!!Object.values(errors).length && (
          <Alert severity="error">
            {transactionsData?.count
              ? t('connections:connectionTest.testDocumentsSection.error.notSelected')
              : t('connections:connectionTest.testDocumentsSection.error.emptyList')}
          </Alert>
        )}

        <div className="flex w-full items-center justify-between gap-2">
          <Button
            type="submit"
            loading={isSendingTestDocuments}
            onClick={handleSubmit(onSubmit)}
            analyticsId="testing-flow:test-document-send"
          >
            {t('connections:connectionTest.sendTestDocument')}
          </Button>
          <Pagination
            hasPrevious={Boolean(transactionsData?.previousCursor)}
            hasNext={Boolean(transactionsData?.hasMore)}
            onPrevious={() => setCurrentCursor(transactionsData?.previousCursor)}
            onNext={() => setCurrentCursor(transactionsData?.nextCursor)}
          />
        </div>
      </Form>
    </div>
  );
};

const schema = Yup.object().shape({
  selectedDocumentsId: Yup.array().of(Yup.string().required()).min(1).required(),
});
