import { TrashIcon } from '@heroicons/react/24/outline';
import { ConfirmDeletePopup } from 'components/Display/ConfirmDeletePopup/ConfirmDeletePopup';
import { Button } from 'components/Form/Button/Button';
import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListPricingConfigsResponse,
  useDeletePricingConfig,
} from 'services/repositories/pricingConfigs/pricingConfigs';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToPricingConfigEditPage } from 'support/helpers/navigation/navigation';
import { PricingConfigDTO } from 'support/types';

type PricingConfigListProps = {
  pricingConfigsData: ListPricingConfigsResponse['items'] | undefined;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  onNextPage: () => void;
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
  clearAllFilters: () => void;
  hasFilters: boolean;
};

export function PricingConfigList({
  pricingConfigsData,
  isLoading,
  isFetchingNextPage,
  onNextPage,
  hasNextPage,
  hasPreviousPage,
  clearAllFilters,
  hasFilters,
}: PricingConfigListProps) {
  const { mutate } = useDeletePricingConfig();
  const { t } = useTranslation();
  const [pricingConfigToDelete, setPricingConfigToDelete] = useState<PricingConfigDTO | undefined>(undefined);

  const deleteConfig = async (pricingConfig: PricingConfigDTO | undefined) => {
    if (!pricingConfig) return;
    mutate({ partnerId: pricingConfig.partner.id, pricingConfigId: pricingConfig.id });
    setPricingConfigToDelete(undefined);
  };

  const tableHeaders: Array<TableHeader> = [
    { label: t('priceList:list.tableHeaders.name'), className: 'w-2/5' },
    { label: t('priceList:list.tableHeaders.tradePartner') },
    { label: t('priceList:list.tableHeaders.date') },
    { label: '', invisible: true },
  ];

  const buildRow = (pricingConfig: PricingConfigDTO) => {
    return [
      {
        element: <div className="max-w-xs truncate">{pricingConfig.name}</div>,
        key: `name ${pricingConfig.id ?? ''}`,
      },
      {
        element: <div className="max-w-xs truncate">{pricingConfig.partner.name}</div>,
        key: 'trade-partner',
      },
      {
        element: pricingConfig.createdAt ? (
          <span className="tabular-nums">{formatDayAndTime(pricingConfig.createdAt)}</span>
        ) : (
          EMPTY_FIELD
        ),
        key: 'created-at',
      },
      {
        element: (
          <div className="flex justify-end space-x-2 text-right">
            <Button
              variant="minimal"
              size="extra-small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPricingConfigToDelete(pricingConfig);
              }}
              analyticsId="pricing-configs:delete"
            >
              <TrashIcon className="h-4 w-6" />
            </Button>
          </div>
        ),
        key: 'actions',
        className: 'px-0',
      },
    ];
  };
  return (
    <>
      <ListTable<PricingConfigDTO>
        headers={tableHeaders}
        data={pricingConfigsData}
        rowBuilder={buildRow}
        rowClickPath={(pricingConfig) =>
          routeToPricingConfigEditPage({ id: pricingConfig.id, partnerId: pricingConfig.partner.id })
        }
        isLoading={isLoading}
        pagination={
          <InfiniteScroll
            hasMore={Boolean(hasNextPage)}
            loadMore={onNextPage}
            loading={isFetchingNextPage}
            noMoreItemsEnabled={Boolean(hasPreviousPage)}
          />
        }
        emptyState={
          <ListTable.EmptyState
            background="gray"
            cols={tableHeaders.length}
            cta={
              hasFilters
                ? {
                    children: t('common:table.withFilters.emptyState.cta'),
                    onClick: clearAllFilters,
                    analyticsId: 'pricing-configs:clear-filters',
                  }
                : undefined
            }
          >
            {hasFilters ? t('common:table.withFilters.emptyState.title') : t('priceList:list.emptyState')}
          </ListTable.EmptyState>
        }
      />
      <ConfirmDeletePopup
        title={t('priceList:list.deletePopup.title')}
        description={t('priceList:list.deletePopup.description', { name: pricingConfigToDelete?.name })}
        open={Boolean(pricingConfigToDelete)}
        onClose={() => setPricingConfigToDelete(undefined)}
        onDelete={() => deleteConfig(pricingConfigToDelete)}
      />
    </>
  );
}
