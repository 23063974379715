import { useCallback, useRef } from 'react';

// Define a type for the debounced function that includes the cancel method
type DebouncedFunction<T extends (...args: Array<any>) => Promise<any>> = {
  (...args: Parameters<T>): Promise<ReturnType<T>>;
  cancel: () => void;
};

export const CANCELLED_ERROR = 'Debounced function cancelled';
function useDebouncePromise<T extends (...args: Array<any>) => Promise<any>>(
  func: T,
  wait: number,
): DebouncedFunction<T> {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const pendingPromise = useRef<Promise<ReturnType<T>> | null>(null);
  const pendingResolve = useRef<((value: ReturnType<T>) => void) | null>(null);
  const pendingReject = useRef<((reason?: any) => void) | null>(null);

  const cancel = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (pendingReject.current) {
      pendingReject.current(new Error(CANCELLED_ERROR));
    }
  }, []);

  const debouncedFunction = useCallback(
    (...args: Parameters<T>): Promise<ReturnType<T>> => {
      cancel();

      return new Promise((resolve, reject) => {
        pendingResolve.current = resolve;
        pendingReject.current = reject;

        timeout.current = setTimeout(() => {
          func(...args)
            .then((result) => {
              if (pendingResolve.current) {
                pendingResolve.current(result);
              }
            })
            .catch((error) => {
              if (pendingReject.current) {
                pendingReject.current(error);
              }
            })
            .finally(() => {
              pendingPromise.current = null;
              pendingResolve.current = null;
              pendingReject.current = null;
            });
        }, wait);
      });
    },
    [func, wait, cancel],
  );

  return Object.assign(debouncedFunction, { cancel }) as DebouncedFunction<T>;
}

export default useDebouncePromise;
