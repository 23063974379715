import { RowData, CellContext } from '@tanstack/react-table';
import { cellMapping } from 'components/DataTable/cellMapping';
import { ColumnTypesInternal, DisplayModes } from 'components/DataTable/Types';
import { useMemo } from 'react';
import { EMPTY_FUNCTION } from 'support/helpers/const/const';
import { getFieldId, isRelativeModification } from '../cellHelpers';

export const BodyCell = <TData = RowData,>(props: CellContext<TData, unknown>) => {
  const displayMode = props.table.options.meta?.mode;
  const [ViewComponent, EditComponent] = useMemo(
    () => (cellMapping as any)[props.column.columnDef.meta!.type],
    [props.column.columnDef.meta],
  );
  const globalFilter = props.table.getState().globalFilter;
  const isHighlighted = useMemo(() => {
    const globalFilterFn = props.table.getGlobalFilterFn();
    const isHighlightedDisabled = [
      (displayMode === DisplayModes.create || displayMode === DisplayModes.edit) && 'select',
      (displayMode === DisplayModes.create || displayMode === DisplayModes.edit) && 'date',
      ColumnTypesInternal.procuros_delete,
      ColumnTypesInternal.procuros_select,
    ].includes(props.column.columnDef.meta?.type as string);

    return (
      !isHighlightedDisabled &&
      globalFilter &&
      globalFilterFn?.(props.row, props.column.id, globalFilter, EMPTY_FUNCTION)
    );
  }, [displayMode, globalFilter, props.column.columnDef.meta?.type, props.column.id, props.row, props.table]);

  const Cell = displayMode === DisplayModes.view ? ViewComponent : EditComponent;
  const {
    cell: { id },
    table,
    column: { columnDef },
    row,
  } = props;

  let fieldId = useMemo(
    () => getFieldId(columnDef, table.options.meta?.dataPrefix, id),
    [columnDef, id, table.options.meta?.dataPrefix],
  );

  const isLineItems = table.options.meta?.dataPrefix.includes('line_items');
  const isRelativeModificationRow = isRelativeModification(
    fieldId,
    row.original,
    DisplayModes.create,
    Boolean(isLineItems),
  );

  if (isRelativeModificationRow && fieldId.endsWith('.amount')) {
    fieldId = fieldId.replace('.amount', '.percentage');
  }

  return <Cell {...props} isHighlighted={isHighlighted} fieldId={fieldId} key={fieldId} mode={displayMode} />;
};
