import { forwardRef, useState } from 'react';
import { Input } from '../Input/Input/Input';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

type InlineInputProps = Parameters<typeof Input>[0] & {
  onSave: () => Promise<void> | void;
};

const InlineInputWithRef = ({ onSave, ...otherProps }: InlineInputProps, ref: React.Ref<HTMLInputElement>) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const save = async () => {
    try {
      setLoading(true);
      await onSave();
      setEditing(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const content = editing ? (
    <Input {...otherProps} wrapperClassName="w-full" ref={ref} />
  ) : (
    otherProps.value ?? otherProps.defaultValue
  );
  const button = editing ? (
    <Button
      className="self-start"
      size="extra-small"
      disabled={Boolean(otherProps.errors)}
      onClick={save}
      loading={loading}
      analyticsId="inline_input_save"
    >
      {t('common:save')}
    </Button>
  ) : (
    <Button
      className="self-start"
      variant="secondary"
      size="extra-small"
      onClick={() => setEditing(true)}
      LeftIcon={PencilSquareIcon}
      analyticsId="inline_input_edit"
    >
      {t('common:edit')}
    </Button>
  );
  return (
    <div className="flex w-full items-center gap-3">
      {content}
      {button}
    </div>
  );
};

export const InlineInput = forwardRef(InlineInputWithRef);
