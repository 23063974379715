import { useTranslation } from 'react-i18next';

import { Alert } from 'components/Display/Alert/Alert';
import { checkSenderEnvelopeDropped } from 'support/helpers/transactions/transactions';
import { SenderEnvelopeDTO } from 'support/types';
import { AlertSection } from 'components/Display/Alert/AlertSection';

type DroppedSenderEnvelopeAlertProps = {
  senderEnvelope?: SenderEnvelopeDTO | null;
};

export function DroppedSenderEnvelopeAlert({ senderEnvelope }: DroppedSenderEnvelopeAlertProps) {
  const { t } = useTranslation();
  const isSenderEnvelopeDropped = checkSenderEnvelopeDropped(senderEnvelope);
  if (!isSenderEnvelopeDropped) {
    return null;
  }

  return (
    <Alert withoutIcon fullWidth severity="neutral">
      <AlertSection severity="neutral" title={t('transactions:show.sections.errors.alert.messageDropped')} />
    </Alert>
  );
}
