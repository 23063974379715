import { TFunction } from 'i18next';
import { getDropdownOptionsValues } from 'support/helpers/generic/generic';
import { AS2ConfigurationDTO, EDIFACTSyntaxIdentifier, EDIFACTSyntaxVersion } from 'support/types/dtos';
import * as Yup from 'yup';

type EDIFACTConfigurationDTO = Pick<
  AS2ConfigurationDTO,
  'edifact_identity' | 'edifact_identity_qualifier' | 'edifact_syntax_identifier' | 'edifact_syntax_version'
>;

export const edifactDefaultConfiguration: EDIFACTConfigurationDTO = {
  edifact_identity: '',
  edifact_identity_qualifier: '14',
  edifact_syntax_identifier: 'UNOC',
  edifact_syntax_version: 3,
};

/*
  t('integrations:new.as2.form.field.edifactIdentityQualifier.options.1')
  t('integrations:new.as2.form.field.edifactIdentityQualifier.options.7')
  t('integrations:new.as2.form.field.edifactIdentityQualifier.options.9')
  t('integrations:new.as2.form.field.edifactIdentityQualifier.options.14')
  t('integrations:new.as2.form.field.edifactIdentityQualifier.options.zz')
  t('integrations:new.as2.form.field.edifactIdentityQualifier.options.zzz')
*/
export const edifactIdentityQualifierOptions = [
  {
    label: 'integrations:new.as2.form.field.edifactIdentityQualifier.options.1',
    value: '1',
  },
  {
    label: 'integrations:new.as2.form.field.edifactIdentityQualifier.options.7',
    value: '7',
  },
  {
    label: 'integrations:new.as2.form.field.edifactIdentityQualifier.options.9',
    value: '9',
  },
  {
    label: 'integrations:new.as2.form.field.edifactIdentityQualifier.options.14',
    value: '14',
  },
  {
    label: 'integrations:new.as2.form.field.edifactIdentityQualifier.options.zz',
    value: 'ZZ',
  },
  {
    label: 'integrations:new.as2.form.field.edifactIdentityQualifier.options.zzz',
    value: 'ZZZ',
  },
];

export const edifactSyntaxIdentifierOptions: { value: EDIFACTSyntaxIdentifier; label: string }[] = [
  { value: 'UNOC', label: 'UNOC' },
  { value: 'UNOD', label: 'UNOD' },
  { value: 'UNOY', label: 'UNOY' },
  { value: 'UNOB', label: 'UNOB' },
  { value: 'UNOA', label: 'UNOA' },
];

export const edifactSyntaxVersionOptions: { value: EDIFACTSyntaxVersion; label: string }[] = [
  { value: 3, label: '3' },
  { value: 4, label: '4' },
];

export const EDIFACTSchema = (t: TFunction) =>
  Yup.object({
    edifact_identity: Yup.string().required(
      t('common:form.required', { field: t('integrations:new.as2.form.field.edifactIdentity.label') }),
    ),
    edifact_identity_qualifier: Yup.string()
      .oneOf(getDropdownOptionsValues(edifactIdentityQualifierOptions))
      .required(),
    edifact_syntax_identifier: Yup.string().oneOf(getDropdownOptionsValues(edifactSyntaxIdentifierOptions)).required(),
    edifact_syntax_version: Yup.number().oneOf(getDropdownOptionsValues(edifactSyntaxVersionOptions)).required(),
  });
