import { CheckIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Translate } from '../Translate/Translate';
import { useTranslation } from 'react-i18next';
import InfoPopup from '../Display/InfoPopup/InfoPopup';

export const IntegrationSetupCompleteModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  return (
    <InfoPopup
      setOpen={(open: boolean) => {
        setOpen(open);
        if (!open) {
          onClose();
        }
      }}
      open={open}
      iconWrapperClassName="bg-procuros-green-100"
      icon={<CheckIcon className="h-6 w-6 text-procuros-green-600" />}
      title={t('onboarding:integration_steps.completed_modal.title')}
      description={<Translate i18nKey={'onboarding:integration_steps.completed_modal.description'} />}
    />
  );
};
