export const AnimatedApplicationLogo = () => {
  return (
    <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="animate-slide-left-infinite">
        <path
          d="M66 0C63.8275 0 62.1803 1.21486 60.529 2.43277C58.8693 3.65682 57.2055 4.88395 55 4.88395C52.8 4.88395 51.15 3.66296 49.5 2.44198C47.85 1.22099 46.2 0 44 0C41.8 0 40.15 1.22099 38.5 2.44198C36.85 3.66296 35.2 4.88395 33 4.88395C30.7945 4.88395 29.1307 3.65682 27.471 2.43277C25.8197 1.21486 24.1725 0 22 0C19.8275 0 18.1803 1.21486 16.529 2.43277C14.8693 3.65682 13.2055 4.88395 11 4.88395C8.8 4.88395 7.15 3.66296 5.5 2.44198C3.85 1.22099 2.2 0 0 0V9.7679C2.2 9.7679 3.85 10.9889 5.5 12.2099C7.15 13.4309 8.8 14.6519 11 14.6519C13.2 14.6519 14.85 13.4309 16.5 12.2099C18.15 10.9889 19.8 9.7679 22 9.7679C24.1945 9.7679 25.8308 10.9828 27.4712 12.2008C29.1198 13.4248 30.7725 14.6519 33 14.6519C35.2275 14.6519 36.8802 13.4248 38.5288 12.2008C40.1692 10.9828 41.8055 9.7679 44 9.7679C46.2 9.7679 47.85 10.9889 49.5 12.2099C51.15 13.4309 52.8 14.6519 55 14.6519C57.2 14.6519 58.85 13.4309 60.5 12.2099C62.15 10.9889 63.8 9.7679 66 9.7679V0Z"
          fill="currentColor"
        />
        <path
          d="M66 14.6515C63.8275 14.6515 62.1803 15.8664 60.529 17.0843C58.8693 18.3083 57.2055 19.5354 55 19.5354C52.8 19.5354 51.15 18.3145 49.5 17.0935C47.85 15.8725 46.2 14.6515 44 14.6515C41.8 14.6515 40.15 15.8725 38.5 17.0935C36.85 18.3145 35.2 19.5354 33 19.5354C30.7945 19.5354 29.1307 18.3083 27.471 17.0843C25.8197 15.8664 24.1725 14.6515 22 14.6515C19.8275 14.6515 18.1803 15.8664 16.529 17.0843C14.8693 18.3083 13.2055 19.5354 11 19.5354C8.8 19.5354 7.15 18.3145 5.5 17.0935C3.85 15.8725 2.2 14.6515 0 14.6515V24.4634C2.2 24.4634 3.85 25.6844 5.5 26.9054C7.15 28.1264 8.8 29.3473 11 29.3473C13.2 29.3473 14.85 28.1264 16.5 26.9054C18.15 25.6844 19.8 24.4634 22 24.4634C24.1945 24.4634 25.8308 25.6783 27.4712 26.8963C29.1198 28.1203 30.7725 29.3473 33 29.3473C35.2275 29.3473 36.8802 28.1203 38.5288 26.8963C40.1692 25.6783 41.8055 24.4634 44 24.4634C46.2 24.4634 47.85 25.6844 49.5 26.9054C51.15 28.1264 52.8 29.3473 55 29.3473C57.2 29.3473 58.85 28.1264 60.5 26.9054C62.15 25.6844 63.8 24.4634 66 24.4634V14.6515Z"
          fill="currentColor"
        />
        <path
          d="M66 29.3481C63.8275 29.3481 62.1803 30.563 60.529 31.7809C58.8693 33.005 57.2055 34.2321 55 34.2321C52.8 34.2321 51.15 33.0111 49.5 31.7901C47.85 30.5691 46.2 29.3481 44 29.3481C41.8 29.3481 40.15 30.5691 38.5 31.7901C36.85 33.0111 35.2 34.2321 33 34.2321C30.7945 34.2321 29.1307 33.005 27.471 31.7809C25.8197 30.563 24.1725 29.3481 22 29.3481C19.8275 29.3481 18.1803 30.563 16.529 31.7809C14.8693 33.005 13.2055 34.2321 11 34.2321C8.8 34.2321 7.15 33.0111 5.5 31.7901C3.85 30.5691 2.2 29.3481 0 29.3481V39.116C2.2 39.116 3.85 40.337 5.5 41.558C7.15 42.779 8.8 44 11 44C13.2 44 14.85 42.779 16.5 41.558C18.15 40.337 19.8 39.116 22 39.116C24.1945 39.116 25.8308 40.331 27.4712 41.5489C29.1198 42.7729 30.7725 44 33 44C35.2275 44 36.8802 42.7729 38.5288 41.5489C40.1692 40.331 41.8055 39.116 44 39.116C46.2 39.116 47.85 40.337 49.5 41.558C51.15 42.779 52.8 44 55 44C57.2 44 58.85 42.779 60.5 41.558C62.15 40.337 63.8 39.116 66 39.116V29.3481Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
