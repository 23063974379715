import { LoadingLogo } from './LoadingLogo';

export function LoadingPage() {
  return (
    <div className="flex size-full flex-col items-center justify-center">
      <div className="size-20">
        <LoadingLogo />
      </div>
    </div>
  );
}
