import { useTranslation } from 'react-i18next';
import { ModificationDTO, ModificationGroupDTO } from 'support/types';
import { ModificationRows } from './ModificationRows';

type ModificationLevelsProps = {
  modificationGroups?: ModificationGroupDTO[];
  removeModification: (levelToModify: number, modificationPosition: number) => void;
  updateLevelModification: (levelToModify: number, modificationIndex: number, modification: ModificationDTO) => void;
  disabled?: boolean;
};

export function ModificationLevels({
  modificationGroups,
  removeModification,
  updateLevelModification,
  disabled,
}: ModificationLevelsProps) {
  const { t } = useTranslation();
  return (
    <>
      {modificationGroups?.map((modificationGroup, index) => {
        return (
          <div className="pt-1" key={index}>
            <table className="w-11/12 table-fixed text-gray-500">
              <thead className="sr-only text-sm text-gray-400 text-left sm:not-sr-only">
                <tr>
                  <th
                    scope="col"
                    className="hidden sm:w-full pr-2 py-1 font-normal sm:table-cell after:content-['*'] after:ml-0.5 after:text-gray-400"
                  >
                    {t('webedi:modifications.type')}
                  </th>
                  <th
                    scope="col"
                    className="sm:w-full pr-2 py-1 font-normal after:content-['*'] after:ml-0.5 after:text-gray-400"
                  >
                    {t('webedi:modifications.reason')}
                  </th>
                  <th
                    scope="col"
                    className="sm:w-full pr-2 py-1 font-normal after:content-['*'] after:ml-0.5 after:text-gray-400"
                  >
                    {t('webedi:modifications.amount')}
                  </th>
                  <th scope="col" className="hidden sm:w-full pr-2 py-1 font-normal sm:table-cell after:content-['*']">
                    {t('webedi:modifications.taxPercentForCharges')}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-sm">
                <ModificationRows
                  modifications={modificationGroup.modifications}
                  calculationLevel={modificationGroup.level}
                  removeModification={removeModification}
                  updateLevelModification={updateLevelModification}
                  disabled={disabled}
                />
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}
