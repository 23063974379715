import { Divider } from 'components/Divider/Divider';
import {
  MessagePopover,
  MessagePopoverBody,
  MessagePopoverFooter,
} from 'components/WebEDI/MessagePopover/MessagePopover';
import { CSSProperties, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'support/helpers/currency/currency';

type Modification = {
  type?: 'RELATIVE' | 'ABSOLUTE';
  description?: string;
  reason_code?: 'SHIPPING' | 'PACKAGING' | 'HANDLING' | 'DISCOUNT';
  percentage?: number;
  amount?: number;
  tax?: {
    total_tax_amount?: number;
    items?: Array<{
      taxable_amount?: number;
      tax_amount?: number;
      tax_percentage?: number;
      tax_type?: 'VAT' | 'EXEMPT' | 'ZERO_RATED';
    }>;
  };
};
type ModificationGroup = {
  basis?: number;
  comments?: string;
  charges?: Array<Modification>;
  allowances?: Array<Modification>;
};

export const ModificationGroupsModal = forwardRef<
  HTMLDivElement,
  {
    modificationGroups: Array<ModificationGroup>;
    documentCurrency: string;
    totalAllowances: number;
    totalCharges: number;
    style?: CSSProperties;
  }
>(({ modificationGroups, documentCurrency, totalAllowances, totalCharges, style }, ref) => {
  return (
    <MessagePopover ref={ref} style={style}>
      <MessagePopoverBody>
        <ModificationGroupsModalTitle />
        <div className="space-y-2.5">
          {modificationGroups.map((modificationGroup, index) => (
            <ModificationGroupsLevel
              key={index}
              modificationGroup={modificationGroup}
              index={index}
              currency={documentCurrency}
            />
          ))}
        </div>
      </MessagePopoverBody>
      <ModificationGroupsModalFooter
        totalAllowances={totalAllowances}
        totalCharges={totalCharges}
        currency={documentCurrency}
      />
    </MessagePopover>
  );
});

ModificationGroupsModal.displayName = 'ModificationGroupsModal';

const ModificationGroupsModalTitle = () => {
  const { t } = useTranslation();
  return (
    <h1 className="text-sm font-bold leading-tight text-gray-900">
      {t('webedi:line_items.modification_groups.modal.title')}
    </h1>
  );
};

const ModificationGroupsModalFooter = ({
  totalAllowances,
  totalCharges,
  currency,
}: {
  totalAllowances: number;
  totalCharges: number;
  currency: string;
}) => {
  const { t } = useTranslation();
  const total = totalCharges - totalAllowances;
  return (
    <MessagePopoverFooter>
      <div className="flex w-full items-center justify-between p-2">
        <div className="text-xs text-gray-500">{t('webedi:line_items.modification_groups.modal.footer')}</div>
        <div className="text-xs font-semibold text-gray-900">
          {formatCurrency(total, currency, { signDisplay: 'exceptZero' })}
        </div>
      </div>
    </MessagePopoverFooter>
  );
};

const ModificationGroupsLevel = ({
  modificationGroup,
  index,
  currency,
}: {
  modificationGroup: ModificationGroup;
  index: number;
  currency: string;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Divider>
        <div className="-ml-8 bg-white py-3.5 pr-2 text-xs font-semibold uppercase tracking-tight text-gray-400">
          {t('webedi:line_items.modification_groups.modal.level_title', { level: index + 1 })}
        </div>
      </Divider>
      {modificationGroup.charges
        ?.filter((modification) => modification.amount || modification.percentage)
        .map((modification, index) => (
          <Modification key={index} modification={modification} type="charge" currency={currency} />
        ))}
      {modificationGroup.allowances
        ?.filter((modification) => modification.amount || modification.percentage)
        .map((modification, index) => (
          <Modification key={index} modification={modification} type="allowance" currency={currency} />
        ))}
    </div>
  );
};

const Modification = ({
  modification,
  type,
  currency,
}: {
  modification: Modification;
  type: 'charge' | 'allowance';
  currency: string;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid w-full grid-cols-3 py-3">
        <div className="text-xs text-gray-500">
          {t(`webedi:line_items.modification_groups.modal.modification.${type}`)}
        </div>
        <div className="text-xs text-gray-500">
          {t(
            `webedi:line_items.modification_groups.modal.modification.type.${modification.percentage ? 'RELATIVE' : 'ABSOLUTE'}`,
          )}
        </div>
        <div className="text-end text-xs text-gray-900">
          {type === 'charge' ? '+' : '-'}
          {modification.percentage
            ? `${modification.percentage}%`
            : `${formatCurrency(modification.amount || 0, currency)}`}
        </div>
      </div>
    </div>
  );
};

/**
 * i18n
 * t('webedi:line_items.modification_groups.modal.modification.charge')
 * t('webedi:line_items.modification_groups.modal.modification.allowance')
 * t('webedi:line_items.modification_groups.modal.modification.type.RELATIVE')
 * t('webedi:line_items.modification_groups.modal.modification.type.ABSOLUTE')

 */
