import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFailedTransactionsCount } from 'services/repositories/stats/stats';
import { Alert } from 'components/Display/Alert/Alert';

export function PendingTransactions() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: failedTransactionCountData } = useFailedTransactionsCount();

  if (!failedTransactionCountData?.actionRequiredTransactions) {
    return null;
  }

  return (
    <div className="mb-6" data-testid="pending-transactions-banner">
      <Alert
        severity="error"
        withoutIcon
        title={t('transactions:list.failedTransactionsActionRequired.title', {
          count: failedTransactionCountData?.failedTransactions,
          required: failedTransactionCountData?.actionRequiredTransactions,
        })}
        actions={{
          primary: {
            variant: 'secondary',
            children: t('transactions:list.failedTransactions.view'),
            onClick: () => navigate('/transactions/blocked'),
            analyticsId: 'transactions:failed_transactions_navigate',
          },
        }}
      >
        {t('transactions:list.failedTransactions.subtitle')}
      </Alert>
    </div>
  );
}
