import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { forwardRef, ReactNode } from 'react';

import { classNames } from 'support/helpers/generic/generic';
import { Severity } from './types';

const classes = {
  error: {
    bg: 'bg-error-50',
    iconColor: 'text-error-400',
    text: 'text-error-700',
  },
  warning: {
    bg: 'bg-warning-50',
    iconColor: 'text-warning-500',
    text: 'text-warning-700',
  },
  info: {
    bg: 'bg-info-50',
    iconColor: 'text-info-500',
    text: 'text-info-700',
  },
  success: {
    bg: 'bg-success-50',
    iconColor: 'text-success-500',
    text: 'text-success-700',
  },
  neutral: {
    bg: 'bg-neutral-50',
    iconColor: 'text-neutral-500',
    text: 'text-neutral-700',
  },
};

type Props = {
  fullWidth?: boolean;
  title?: string | null;
  children?: ReactNode;
  severity: Severity;
  withoutIcon?: boolean;
  loading?: boolean;
  testId?: string;
  className?: string;
  actions?: {
    primary: Omit<Parameters<typeof Button>[0], 'className'>;
    secondary?: Omit<Parameters<typeof Button>[0], 'size' | 'variant' | 'className'>;
  };
};

export const Alert = forwardRef<HTMLDivElement, Props>(
  ({ title, children, className, severity, withoutIcon, loading, testId, actions, fullWidth }, ref) => {
    let Icon = null;

    if (!withoutIcon) {
      switch (severity) {
        case 'error':
          Icon = ExclamationCircleIcon;
          break;
        case 'warning':
          Icon = ExclamationTriangleIcon;
          break;
        case 'info':
          Icon = InformationCircleIcon;
          break;
        case 'success':
          Icon = CheckCircleIcon;
          break;
      }
    }

    return (
      <div
        data-testid={testId}
        className={classNames('w-full', classes[severity].bg, fullWidth ? 'px-8 py-4' : 'rounded-md p-4', className)}
        ref={ref}
      >
        <div className="flex gap-3">
          {Icon || loading ? (
            <div className={classNames('shrink-0', { 'pt-0.5': Boolean(title) })}>
              {loading ? (
                <LoadingSpinner variant={severity} sizeClassName="size-6" />
              ) : (
                Icon && (
                  <Icon className={classNames('h-5 w-5 stroke-2', classes[severity].iconColor)} aria-hidden="true" />
                )
              )}
            </div>
          ) : null}
          <div
            className={classNames(
              'w-full flex gap-2',
              actions?.secondary ? 'flex-col' : 'items-center justify-between',
            )}
          >
            <div className="w-full space-y-2">
              {!!title && <h3 className={classNames('text-base font-bold', classes[severity].text)}>{title}</h3>}
              {!!children && <div className="flex flex-col gap-2 text-sm text-gray-900">{children}</div>}
            </div>
            {actions ? (
              <div className="flex justify-end gap-2">
                {actions.secondary && <Button {...actions.secondary} variant="secondary" size="small" />}
                {actions.primary && (
                  <Button size="small" variant={actions.secondary ? 'primary' : 'secondary'} {...actions.primary} />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

Alert.displayName = 'Alert';
