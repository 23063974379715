import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useTranslation } from 'react-i18next';

export const DirectionBadge = ({ direction }: { direction: 'INBOUND' | 'OUTBOUND' }) => {
  const { t } = useTranslation();

  const icon =
    direction === 'INBOUND' ? (
      <ArrowDownTrayIcon data-testid="arrow-down-tray-icon" />
    ) : (
      <ArrowUpTrayIcon data-testid="arrow-up-tray-icon" />
    );

  /*
    t('common:direction.INBOUND')
    t('common:direction.OUTBOUND')
  */
  return (
    <Badge color={BadgeColor.gray} icon={icon}>
      {t(`common:direction.${direction}`)}
    </Badge>
  );
};
