import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useTranslation } from 'react-i18next';

export const ActionRequiredBadge = () => {
  const { t } = useTranslation();
  return (
    <Badge color={BadgeColor.blue} type="indicator">
      {t('connections:table.fields.actionRequired')}
    </Badge>
  );
};
