import { useTranslation } from 'react-i18next';
import { Alert } from 'components/Display/Alert/Alert';
import { MessageDTO, MessageV2DTO } from 'support/types';
import { ErrorContext } from '../../types';
import { isMessageV2DTO, checkMessageDropped } from 'support/helpers/transactions/transactions';
import { AlertSection } from 'components/Display/Alert';

type DroppedMessageAlertProps = {
  message?: MessageDTO | MessageV2DTO;
  webediV2Enabled?: boolean;
};

export function DroppedMessageAlert({ message, webediV2Enabled }: DroppedMessageAlertProps) {
  const { t } = useTranslation();
  const isMessageDropped =
    (message && (isMessageV2DTO(message) ? message?.dropped_error_reasons : !message?.droppedErrorReasons)) ||
    checkMessageDropped(message);

  if (!message || !isMessageDropped) {
    return null;
  }

  const droppedMessageReasons = isMessageV2DTO(message) ? message.dropped_error_reasons : message.droppedErrorReasons;

  if (!droppedMessageReasons || (isMessageV2DTO(message) && !droppedMessageReasons.length)) {
    return null;
  }
  if (webediV2Enabled) {
    return (
      <Alert severity="neutral" withoutIcon fullWidth>
        {droppedMessageReasons?.length ? (
          <AlertSection severity="neutral" title={t('transactions:show.sections.errors.alert.messageDropped')}>
            {droppedMessageReasons?.map((context: ErrorContext) => {
              return context.reason;
            })}
          </AlertSection>
        ) : null}
      </Alert>
    );
  }

  return (
    <div data-testid="DroppedMessageAlert">
      <Alert severity="warning" title={t('transactions:show.sections.errors.alert.messageDropped')}>
        {droppedMessageReasons.length ? (
          <div>
            {t('transactions:show.sections.errors.alert.reason')}
            {droppedMessageReasons.map((context: ErrorContext) => {
              return context.reason;
            })}
          </div>
        ) : null}
      </Alert>
    </div>
  );
}
