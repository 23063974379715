import { PlusIcon } from '@heroicons/react/24/outline';
import { SlideOver, SlideOverTitleSkeleton } from 'components/Display/SlideOver/SlideOver';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConnectionsRoutes, DocumentType, RelationshipV2 } from 'support/types';
import { RelationshipBadge } from '../../../RelationshipBadge/RelationshipBadge';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useInfiniteConnections, useRelationship } from 'services/repositories/relationships/relationships';
import { ConnectionsFilterKey, getDefaultFilterConfig } from 'pages/Connections/tableConfig';
import { useQueries } from '@tanstack/react-query';
import { EMPTY_FIELD, ORDER_OF_DOCUMENTS } from 'support/helpers/const/const';
import { useTruthyCachedValue } from 'support/helpers/hooks/useTruthyCachedValue';
import { queryClient } from 'services/http/http';
import { getFilterConfig, getFilterValues } from 'hooks/useFilters';
import { formatBasedOnSortArray } from 'support/helpers/arrays/arrays';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { ResendInvitationEmailAlert } from './components/ResendInvitationEmailAlert/ResendInvitationEmailAlert';
import { ActionRequiredBadge } from 'components/ActionRequiredBadge/ActionRequiredBadge';
import { ListTable } from 'components/ListTable/ListTable';
import {
  NewTradeRequest,
  convertToPartnerV2ToRequiredPartnerDTO,
} from 'pages/TradePartners/NewTradeRequest/NewTradeRequest';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToPage } from 'support/helpers/navigation/navigation';

const usePartnerWithRelationships = (partnerId: string | undefined, enabled: boolean) => {
  const defaultConfig = useMemo(() => getDefaultFilterConfig(), []);
  const [searchParams] = useSearchParams();
  const { data: partners, isLoading } = useInfiniteConnections({
    variables: {
      query: {
        filterValues: partnerId ? [{ key: ConnectionsFilterKey.partnerId, value: partnerId }] : undefined,
        perPage: '1',
      },
    },
    enabled,
    initialData: queryClient.getQueryData(
      useInfiniteConnections.getKey({
        query: { filterValues: getFilterValues(getFilterConfig(defaultConfig, searchParams)), perPage: null },
      }),
    ),
  });

  const partner = partners?.pages.flatMap((page) => page.data).find((partner) => partner.id === partnerId);
  return { partner, isLoading };
};

export const PartnerConnectionDetails = ({
  onClose,
  open,
  partnerId,
  children,
}: {
  onClose: () => void;
  open: boolean;
  partnerId: string | undefined;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openNewTradeRequest, setOpenNewTradeRequest] = useState(false);
  const { data: currentPartner } = useCurrentPartner();

  const partnerIdInView = useTruthyCachedValue(open, partnerId);
  const { partner, isLoading: relationshipsLoading } = usePartnerWithRelationships(
    partnerIdInView,
    Boolean(open && partnerIdInView),
  );

  const relationshipsData: Array<RelationshipV2> = [
    ...(partner?.receiver_relationships || []),
    ...(partner?.sender_relationships || []),
  ].sort(
    formatBasedOnSortArray(ORDER_OF_DOCUMENTS, (array, value) => array.indexOf(value.message_type as DocumentType)),
  );
  usePrefetchRelationships(relationshipsData);

  const isWaitingForPartnerOnboarding = relationshipsData.some(
    (relationship) => relationship.status === 'PARTNER_ONBOARDING_PENDING',
  );

  const tableHeaders = [
    { key: 'documentType', label: t('connections:partnerConnectionDetails.table.header.documentType') },
    { key: 'status', label: t('connections:partnerConnectionDetails.table.header.status') },
    { key: 'lastUpdated', label: t('connections:partnerConnectionDetails.table.header.lastUpdated') },
    { key: 'actionRequired', label: '' },
  ];

  const buildRow = (relationship: RelationshipV2) => {
    return [
      {
        key: `documentType-${relationship.id}`,
        element: (
          <span className="block max-w-xs truncate">
            {t(`common:messageTypes.plural.${relationship.message_type}`)}
          </span>
        ),
      },
      {
        key: `status-${relationship.id}`,
        element: <RelationshipBadge relationship={relationship} currentPartnerId={currentPartner?.id || ''} />,
      },
      {
        key: `lastUpdated-${relationship.id}`,
        element: relationship.updated_at ? (
          <span className="tabular-nums">
            {formatDayAndTime(
              relationship.timeline_events?.length
                ? relationship.timeline_events[0].created_at
                : relationship.updated_at,
            )}
          </span>
        ) : (
          EMPTY_FIELD
        ),
      },
      {
        key: `actionRequired-${relationship.id}`,
        element: relationship.action_required && <ActionRequiredBadge />,
      },
    ];
  };
  return (
    <SlideOver
      size="large"
      open={open}
      setOpen={onClose}
      title={partner?.name || <SlideOverTitleSkeleton />}
      headerImage={<PartnerLogo imageUrl={partner?.logo_url} size="large" isLoading={!partner} />}
      actionConfig={
        partner
          ? {
              children: t('connections:partnerConnectionDetails.newTradeRequest'),
              onClick: () => setOpenNewTradeRequest(true),
              LeftIcon: PlusIcon,
              analyticsId: 'connections:new_trade_request',
            }
          : undefined
      }
    >
      {partner && isWaitingForPartnerOnboarding && <ResendInvitationEmailAlert partner={partner} />}

      <ListTable<RelationshipV2>
        headers={tableHeaders}
        data={relationshipsData}
        isLoading={relationshipsLoading}
        rowBuilder={buildRow}
        onRowClick={(relationship) =>
          navigate(
            routeToPage(ConnectionsRoutes.relationshipDetails, {
              relationshipId: relationship.id,
            }),
          )
        }
      />
      {partner ? (
        <NewTradeRequest
          onBack={() => {
            setOpenNewTradeRequest(false);
          }}
          showPopup={openNewTradeRequest}
          onClose={() => {
            onClose();
            setOpenNewTradeRequest(false);
          }}
          partner={convertToPartnerV2ToRequiredPartnerDTO(partner)}
          connected
        />
      ) : null}
      {children}
    </SlideOver>
  );
};

const usePrefetchRelationships = (relationships: Array<RelationshipV2>) => {
  useQueries({
    queries:
      relationships.map((relationship) => useRelationship.getFetchOptions({ relationshipId: relationship.id })) || [],
  });
};
