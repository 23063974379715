import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  history: Array<string>;
  wentBackInHistory: boolean;
  previousPath: string | undefined;
};

type Actions = {
  push: (pathname: string) => void;
  pop: () => void;
};

const initialState: State = {
  wentBackInHistory: false,
  history: [],
  previousPath: undefined,
};

export const useHistoryStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,

    push: (pathname: string) => {
      set((state) => {
        state.previousPath = state.history.at(-1);
        state.wentBackInHistory = false;
        state.history.push(pathname);
      });
    },

    pop: () => {
      set((state) => {
        state.wentBackInHistory = true;
        state.history.pop();
        state.previousPath = state.history.length > 1 ? state.history.at(-2) : undefined;
      });
    },
  })),
);

export const pushToHistory = (pathname: Parameters<Actions['push']>['0']) => {
  return useHistoryStore.getState().push(pathname);
};

export const popFromHistory = () => {
  return useHistoryStore.getState().pop();
};

export const getPreviousPath = () => {
  return useHistoryStore.getState().previousPath;
};
