export const getScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
  let currentElement: HTMLElement | null = element;

  while (currentElement !== null) {
    const style = window.getComputedStyle(currentElement);
    const overflowY = style.getPropertyValue('overflow-y');

    if (overflowY === 'auto' || overflowY === 'scroll') {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
};
