import { useEffect, useMemo, useState } from 'react';
import { FieldError, FieldErrors, useFormState } from 'react-hook-form';
import { WebEDIError, useWebEDIErrorsStore } from 'stores/webediErrors/webediErrors';
import { scrollToWebEDIError } from './helpers';
import isEqual from 'lodash/isEqual';
import { DisplayModes } from 'components/DataTable/Types';
import { track } from 'support/helpers/analytics/analytics';
export type { WebEDIError } from 'stores/webediErrors/webediErrors';

type UseWebEDIErrorsReturnType = {
  errors: Array<WebEDIError>;
  currentError: WebEDIError | undefined;
  nextError: WebEDIError | undefined;
  previousError: WebEDIError | undefined;
  numberOfErrors: number;
  moveToPreviousWebEDIError: () => void;
  moveToNextWebEDIError: () => void;
  clearErrors: () => void;
  setCurrentError: (error: WebEDIError) => void;
  focusOnCurrentError: () => void;
  setErrors: (errors: Record<string, WebEDIError>, uiConfig: any) => void;
};

export const useWebEDIErrors = (): UseWebEDIErrorsReturnType => {
  const { errors, currentErrorIndex, clearErrors, numberOfErrors, setErrors, nextErrorIndex, previousErrorIndex } =
    useWebEDIErrorsStore();

  return {
    currentError: errors[currentErrorIndex],
    nextError: errors[nextErrorIndex],
    previousError: errors[previousErrorIndex],
    numberOfErrors,
    moveToPreviousWebEDIError,
    moveToNextWebEDIError,
    clearErrors,
    setCurrentError,
    focusOnCurrentError,
    setErrors,
    errors,
  };
};

export const useWebEDIFieldError = (fieldId: string) => {
  const { errorsMap } = useWebEDIErrorsStore();
  const error = useMemo(() => {
    return errorsMap[fieldId];
  }, [errorsMap, fieldId]);

  return useMemo(
    () => ({
      error,
    }),
    [error],
  );
};

/**
 *
 * const template = "Hello {{name}}, welcome to {{company}} and {{location}}!";
 * const vars = {
 *   name: "John",
 *   company: "Acme Corp"
 * };
 * console.log(interpolateString(template, vars));
 * // Output: "Hello John, welcome to Acme Corp and {{location}}!"
 */
const interpolateString = (str: string, variables: Record<string, string>) => {
  return str.replace(/\{\{(.*?)\}\}/g, (full, match) => (variables[match] !== undefined ? variables[match] : full));
};

const interpolateErrorVariables = (
  error: Pick<WebEDIError, 'title' | 'description'>,
  variables: Record<string, string>,
) => {
  const { title, description } = error;
  return {
    title: interpolateString(title, variables),
    description: interpolateString(description, variables),
  };
};
export const getAllWebEDIErrors = (errors: FieldErrors, variables: Record<string, string>) => {
  const allErrors: Record<string, WebEDIError> = {};
  function getAllErrors(obj: Record<string, unknown>, parentKey = ''): void {
    if (typeof obj !== 'object' || obj === null) return;
    for (const key of Object.keys(obj)) {
      if (key === 'ref') {
        continue;
      }
      const fullPath = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key]) {
        getAllErrors(obj[key] as any, fullPath);
      } else {
        if (key === 'message') {
          const error = parseErrorMessage(obj.message);
          const { title, description } = interpolateErrorVariables(error, variables);
          allErrors[parentKey] = {
            id: parentKey,
            title,
            description,
            type: (obj as FieldError).type,
          };
        }
      }
    }
  }

  getAllErrors(errors);
  return allErrors;
};

/*Summary: This code defines utility functions and hooks for handling web EDI errors in a React application.
 * Function: parseErrorMessage
 * Input: errorMessage (unknown) - The error message to parse and extract the title and description from.
 * Should follow the pattern [TITLE=...] [DESCRIPTION=...]
 * Output: { title: string, description: string }
 */

const parseErrorMessage = (errorMessage: unknown) => {
  if (typeof errorMessage !== 'string') return { title: '', description: '' };
  // Define regular expressions for TITLE and DESCRIPTION
  const titleRegex = /\[TITLE\s*=\s*([^\]]+)\]/;
  const descriptionRegex = /\[DESCRIPTION\s*=\s*([^\]]+)\]/;

  // Extract the values using the regular expressions
  const titleMatch = errorMessage.match(titleRegex);
  const descriptionMatch = errorMessage.match(descriptionRegex);

  // Return the values or null if not found
  const title = titleMatch ? titleMatch[1].trim() : errorMessage;
  const description = descriptionMatch ? descriptionMatch[1].trim() : errorMessage;

  return { title, description };
};

const focusOnCurrentError = () => {
  const { currentErrorIndex, errors } = useWebEDIErrorsStore.getState();

  scrollToWebEDIError(errors[currentErrorIndex] || errors[0]);
};

const moveToNextWebEDIError = () => {
  const { setCurrentError, currentErrorIndex, errors, nextErrorIndex } = useWebEDIErrorsStore.getState();

  scrollToWebEDIError(errors[nextErrorIndex]);
  setCurrentError(currentErrorIndex + 1);
};

const moveToPreviousWebEDIError = () => {
  const { setCurrentError, currentErrorIndex, errors, previousErrorIndex } = useWebEDIErrorsStore.getState();
  scrollToWebEDIError(errors[previousErrorIndex]);
  setCurrentError(currentErrorIndex - 1);
};

const setCurrentError = (error: WebEDIError) => {
  const { setCurrentError, errors } = useWebEDIErrorsStore.getState();
  const errorIndex = errors.findIndex((e) => e.id === error.id);
  setCurrentError(errorIndex);
};

export const useDocumentErrors = (uiConfig: any, displayMode: DisplayModes, variables: Record<string, string>) => {
  const webEDIErrors = useGetAllWebEDIErrors(variables);
  const { setErrors } = useWebEDIErrors();

  useEffect(() => {
    if (displayMode === DisplayModes.view) {
      Object.values(webEDIErrors).forEach(trackPossiblemItemModificationGroupsError);
    }
  }, [displayMode, webEDIErrors]);

  useEffect(() => {
    if (!webEDIErrors) return;
    setErrors(webEDIErrors, uiConfig);
  }, [setErrors, uiConfig, webEDIErrors]);
};

const useGetAllWebEDIErrors = (variables: Record<string, string>) => {
  const formState = useFormState();
  const [webEDIErrors, setWebEDIErrors] = useState<Record<string, WebEDIError>>({});

  useEffect(() => {
    const newWebEDIErrors = getAllWebEDIErrors(formState.errors, variables);
    if (!isEqual(newWebEDIErrors, webEDIErrors)) {
      setWebEDIErrors(newWebEDIErrors);
    }
  }, [formState, variables, webEDIErrors]);

  return webEDIErrors;
};

const trackPossiblemItemModificationGroupsError = (error: WebEDIError) => {
  if (error.id.includes('modification_groups') && error.id.includes('line_items')) {
    track('[WEBEDI] - line_item_modification_group_error', { error, location: window.location.href });
  }
};
