import { useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { fromStringDateToProcurosTimestamp } from 'support/helpers/dateTime/dateTime';
import { Input } from '../Input';
import { forwardRef } from 'react';

type TimestampDateInputProps = {
  name: string;
};

export const TimestampDateInput = forwardRef<HTMLInputElement, Parameters<typeof Input>[0] & TimestampDateInputProps>(
  (props, ref) => {
    const { name, value } = props;
    const { setValue, register } = useDataTableContext();
    const registerReturn = register(name, { valueAsNumber: true });

    return (
      <div className="w-full">
        <input type="number" className="hidden" {...registerReturn} ref={ref}></input>
        <Input
          {...props}
          id={undefined}
          name={undefined}
          type="date"
          value={value ?? ''}
          onChange={(e) => {
            setValue(name, fromStringDateToProcurosTimestamp(e.target.value));
          }}
        />
      </div>
    );
  },
);

TimestampDateInput.displayName = 'TimestampDateInput';
