import { fetchData } from 'services/http/http';
import { NotFoundError } from 'support/helpers/errors/errors';
import { sleep } from 'support/helpers/generic/generic';
import { MessageDTO, TransactionInternalType } from 'support/types';

export const pollForMessage = async (senderEnvelopeId: string, attempts = 0): Promise<MessageDTO | null> => {
  try {
    const { data } = await fetchData<MessageDTO>(
      `/v1/transactions/${TransactionInternalType.senderEnvelope}/${senderEnvelopeId}/message`,
    );

    if (!data?.receiver && attempts < 10) {
      throw new NotFoundError('Receiver not yet available');
    }

    return data;
  } catch (err) {
    if (err instanceof NotFoundError) {
      if (attempts > 10) {
        return null;
      }

      await sleep(1000);
      return pollForMessage(senderEnvelopeId, attempts + 1);
    }
    throw err;
  }
};

export const getMessageOrEnvelope = async (transactionId: string, waitForMessage: boolean) => {
  const createdSenderEnvelopeId = transactionId;

  if (!waitForMessage) {
    return {
      transactionId: createdSenderEnvelopeId,
      transactionType: TransactionInternalType.senderEnvelope,
    };
  }

  const createdMessage = await pollForMessage(createdSenderEnvelopeId);

  return createdMessage
    ? {
        transactionId: createdMessage.id,
        transactionType: TransactionInternalType.message,
      }
    : {
        transactionId: createdSenderEnvelopeId,
        transactionType: TransactionInternalType.senderEnvelope,
      };
};
