import { useInfiniteRelationships } from 'services/repositories/relationships/relationships';
import { CustomerStatus, DocumentType, RelationshipV2 } from 'support/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { Translate } from 'components/Translate/Translate';
import { Button } from 'components/Form/Button/Button';
import { getTradePartner } from 'support/helpers/relationships/relationships';
import { joinArrayWithSeparators, classNames } from 'support/helpers/generic/generic';

const WelcomeWrapper = ({ setIsLoading }: { setIsLoading: (isLoading: boolean) => void }) => {
  const { t } = useTranslation();
  const { data: currentPartner, isLoading: isLoadingCurrentPartner } = useCurrentPartner();
  const { data: connections, isLoading: isLoadingConnections } = useInfiniteRelationships({
    variables: {
      query: {
        filterValues: [{ key: 'customer_status', value: CustomerStatus.inTesting }],
        cursor: null,
        perPage: null,
      },
    },
  });

  useEffect(() => {
    setIsLoading(isLoadingCurrentPartner || isLoadingConnections);
  }, [isLoadingCurrentPartner, isLoadingConnections, setIsLoading]);

  const requestedConnections = useMemo(
    () =>
      currentPartner
        ? getFormattedRelationships(connections?.pages[0]?.data || [], currentPartner.id)
        : { partners: [], documentTypes: [], logos: [] },
    [connections, currentPartner],
  );

  return (
    <div className="flex flex-col gap-10">
      <div className="flex items-center gap-6">
        <PartnersLogos logos={requestedConnections.logos} />
        <p>
          <Translate
            i18nKey="onboarding:steps.welcome.content.description.partnersRequested"
            values={{
              partnerName: joinArrayWithSeparators(requestedConnections.partners, ', ', ` ${t('common:and')} `),
            }}
          />
        </p>
      </div>
      <div>
        <p>{t('onboarding:steps.welcome.content.description.connectionsRequested')}</p>
        <br />
        <ul className="list-inside list-disc">
          {requestedConnections.documentTypes.map((connectionType) => (
            <li key={connectionType}>{t(`common:messageTypes.plural.${connectionType}`)}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const imagesSizes = ['w-24 h-24', 'w-16 h-16', 'w-14 h-14'];
const imagesPositions = [
  ['top-0 left-0'],
  ['top-0 left-0', 'top-8 left-8'],
  ['top-0 left-0', 'top-4 left-10', 'top-10 left-4'],
];
const PartnersLogos = ({ logos }: { logos: Array<string> }) => {
  const images = logos.slice(0, 3);
  if (!images.length) return null;

  return (
    <div className={classNames('relative w-24 h-24 flex-shrink-0')}>
      {images.map((logo, index) => (
        <img
          src={logo}
          key={logo}
          alt={''}
          className={classNames(
            'object-contain absolute top-0 left-0 border border-gray-200 rounded bg-white',
            imagesSizes[images.length - 1],
            imagesPositions[images.length - 1][index],
          )}
        />
      ))}
    </div>
  );
};
const getFormattedRelationships = (relationships: Array<RelationshipV2>, currentPartnerId: string) => {
  const uniqueConnectionTypes = new Set<DocumentType>();
  const uniquePartnersName = new Set<string>();
  const uniquePartnersLogo = new Set<string>();

  relationships.forEach((relationship) => {
    const partner = getTradePartner(relationship, currentPartnerId);

    uniqueConnectionTypes.add(relationship.message_type as DocumentType);
    partner && uniquePartnersName.add(partner.name);
    partner?.logo_url && uniquePartnersLogo.add(partner.logo_url);
  });

  return {
    documentTypes: Array.from(uniqueConnectionTypes),
    partners: Array.from(uniquePartnersName),
    logos: Array.from(uniquePartnersLogo),
  };
};

const NextCTA = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} className="shrink-0" variant="primary" analyticsId="onboarding_welcome_continue">
      {t('common:continue')}
    </Button>
  );
};

const Title = () => <Translate i18nKey="onboarding:steps.welcome.title" />;
export const Welcome = Object.assign(WelcomeWrapper, { NextCTA, Title });
