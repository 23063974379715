import { TabsList } from 'components/Navigation/Tabs/TabsList';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFailedTransactionsCount } from 'services/repositories/stats/stats';
import { TransactionsRoutes } from 'support/types';

type Props = {
  selectedIndex: number;
};

export function TransactionsTabs({ selectedIndex }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: failedTransactionCountData } = useFailedTransactionsCount();

  const tabs = useMemo(() => {
    return [
      {
        label: t('transactions:list.tabs.allTransactions'),
        route: TransactionsRoutes.root,
      },
      {
        label: t('transactions:list.tabs.blockedTransactions'),
        route: TransactionsRoutes.blocked,
        badge:
          failedTransactionCountData &&
          failedTransactionCountData.failedTransactions &&
          failedTransactionCountData.failedTransactions > 0
            ? failedTransactionCountData.failedTransactions
            : undefined,
      },
      {
        label: t('transactions:list.tabs.expiredTransactions'),
        route: TransactionsRoutes.expired,
      },
    ];
  }, [failedTransactionCountData, t]);

  const onTabChange = useCallback(
    (tabIndex: number) => {
      const tab = tabs[tabIndex];
      navigate(tab.route);
    },
    [navigate, tabs],
  );

  return <TabsList onChange={(index: number) => onTabChange(index)} tabs={tabs} selected={selectedIndex} />;
}
