import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { logError } from 'services/logging/logging';
import { IntegrationDTO, PartnerV2, RelationshipDocumentStatus, RelationshipStatus } from 'support/types';

export const RelationshipStateBadge = ({
  status,
  requester,
  sender,
  className,
  children,
  otherPartner,
  senderIntegration,
  receiverIntegration,
}: {
  status: RelationshipDocumentStatus;
  requester: boolean;
  sender: boolean;
  className?: string;
  children?: ReactNode;
  otherPartner?: PartnerV2;
  senderIntegration?: IntegrationDTO | undefined;
  receiverIntegration?: IntegrationDTO | undefined;
}) => {
  const { t } = useTranslation();
  const label = stateToLabel(t, sender, requester, status, otherPartner, senderIntegration, receiverIntegration);

  return (
    <Badge color={stateToColor(sender, status)} hasDot tooltip={children ? label : undefined} className={className}>
      {children ?? label}
    </Badge>
  );
};

const InviteeRequesterStates = [
  RelationshipStatus.PARTNER_APPROVAL_PENDING,
  RelationshipStatus.PARTNER_ONBOARDING_PENDING,
  RelationshipStatus.PARTNER_PROCESS_PENDING,
  RelationshipStatus.ISSUE_RESOLVER_PENDING,
];

const SenderReceiverStates = [
  RelationshipStatus.READY_FOR_TESTING,
  RelationshipStatus.HAS_TEST_MESSAGES,
  RelationshipStatus.IN_REVIEW,
];

const EqualStates = [
  RelationshipStatus.REJECTED,
  RelationshipStatus.BLOCKED,
  RelationshipStatus.LIVE,
  RelationshipStatus.DEACTIVATED,
  RelationshipStatus.ACTIVE,
  RelationshipStatus.GO_LIVE_DATE_SCHEDULED,
  RelationshipStatus.GO_LIVE_DATE_PENDING,
  RelationshipStatus.LIVE_ORDER_NEEDED,
  RelationshipStatus.PARTNER_INTEGRATION_PENDING,
];

/*
 *i18n
 * t('connections:relationshipsBadges.PARTNER_APPROVAL_PENDING.invitee')
 * t('connections:relationshipsBadges.PARTNER_APPROVAL_PENDING.requester')
 * t('connections:relationshipsBadges.PARTNER_ONBOARDING_PENDING.invitee')
 * t('connections:relationshipsBadges.PARTNER_ONBOARDING_PENDING.requester')
 * t('connections:relationshipsBadges.PARTNER_PROCESS_PENDING.invitee')
 * t('connections:relationshipsBadges.PARTNER_PROCESS_PENDING.requester')
 * t('connections:relationshipsBadges.ISSUE_RESOLVER_PENDING.invitee')
 * t('connections:relationshipsBadges.ISSUE_RESOLVER_PENDING.requester')
 * t('connections:relationshipsBadges.REJECTED')
 * t('connections:relationshipsBadges.REJECTED')
 *
 * t('connections:relationshipsBadges.READY_FOR_TESTING.sender')
 * t('connections:relationshipsBadges.READY_FOR_TESTING.receiver')
 * t('connections:relationshipsBadges.HAS_TEST_MESSAGES.sender')
 * t('connections:relationshipsBadges.HAS_TEST_MESSAGES.receiver')
 * t('connections:relationshipsBadges.IN_REVIEW.sender')
 * t('connections:relationshipsBadges.IN_REVIEW.receiver')
 *
 * t('connections:relationshipsBadges.BLOCKED')
 * t('connections:relationshipsBadges.LIVE')
 * t('connections:relationshipsBadges.DEACTIVATED')
 * t('connections:relationshipsBadges.ACTIVE')
 * t('connections:relationshipsBadges.GO_LIVE_DATE_SCHEDULED')
 * t('connections:relationshipsBadges.GO_LIVE_DATE_PENDING')
 * t('connections:relationshipsBadges.LIVE_ORDER_NEEDED')
 *
 * t('connections:relationshipsBadges.PARTNER_INTEGRATION_PENDING.missingIntegration')
 * t('connections:relationshipsBadges.PARTNER_INTEGRATION_PENDING.missingPartnerIntegration')
 *
 */
const stateToLabel = (
  t: TFunction,
  sender: boolean,
  requester: boolean,
  state: RelationshipDocumentStatus,
  otherPartner: PartnerV2 | undefined,
  senderIntegration: IntegrationDTO | undefined,
  receiverIntegration: IntegrationDTO | undefined,
): string | undefined | ReactNode => {
  if (state === RelationshipStatus.PARTNER_INTEGRATION_PENDING) {
    return <PartnerIntegrationPending integration={sender ? senderIntegration : receiverIntegration} />;
  }

  if (SenderReceiverStates.includes(RelationshipStatus[state])) {
    return t(`connections:relationshipsBadges.${state}.${sender ? 'sender' : 'receiver'}`);
  } else if (InviteeRequesterStates.includes(RelationshipStatus[state])) {
    return t(`connections:relationshipsBadges.${state}.${requester ? 'requester' : 'invitee'}`);
  } else if (EqualStates.includes(RelationshipStatus[state])) {
    return t(`connections:relationshipsBadges.${state}`, { partnerName: otherPartner?.name });
  }

  logError(`Unknown state translation: ${RelationshipStatus[state]}`);
  return undefined;
};

const stateToColor = (sender: boolean, state: RelationshipDocumentStatus): BadgeColor | undefined => {
  if (SenderReceiverStates.includes(RelationshipStatus[state])) {
    switch (state) {
      case RelationshipStatus.READY_FOR_TESTING:
      case RelationshipStatus.HAS_TEST_MESSAGES:
        return sender ? BadgeColor.blue : BadgeColor.gray;
      case RelationshipStatus.IN_REVIEW:
        return sender ? BadgeColor.gray : BadgeColor.blue;
    }
  } else if (InviteeRequesterStates.includes(RelationshipStatus[state])) {
    return BadgeColor.gray;
  }

  switch (state) {
    case RelationshipStatus.ACTIVE:
      return BadgeColor.blue;
    case RelationshipStatus.LIVE:
      return BadgeColor.green;
    case RelationshipStatus.DEACTIVATED:
    case RelationshipStatus.BLOCKED:
      return BadgeColor.gray;
  }

  if (
    [
      RelationshipStatus.GO_LIVE_DATE_SCHEDULED,
      RelationshipStatus.GO_LIVE_DATE_PENDING,
      RelationshipStatus.LIVE_ORDER_NEEDED,
      RelationshipStatus.PARTNER_INTEGRATION_PENDING,
    ].includes(RelationshipStatus[state])
  ) {
    return BadgeColor.gray;
  }

  logError(`Unknown state color badge: ${state}`);

  return undefined;
};

const PartnerIntegrationPending = ({ integration }: { integration: IntegrationDTO | undefined }) => {
  const { t } = useTranslation();
  if (integration?.state !== 'READY') {
    return t('connections:relationshipsBadges.PARTNER_INTEGRATION_PENDING.missingIntegration');
  }
  return t('connections:relationshipsBadges.PARTNER_INTEGRATION_PENDING.missingPartnerIntegration');
};
