import { WebEDIError } from './webediErrors';

type ConfigField = {
  type: string;
  path: string;
  fields?: Array<ConfigField>;
};

type ConfigSection = {
  type: string;
  path: string;
  fields: Array<ConfigField>;
};

type ConfigItem = {
  type: string;
  fields?: Array<ConfigField>;
  sections?: Array<ConfigSection>;
};

/*
 * Let's be thankful about Claude.ai that fully generated this function for us.
 */
export const sortArrayBasedOnConfig = (
  errors: Array<WebEDIError>,
  configuration: Array<ConfigItem>,
): Array<WebEDIError> => {
  const getConfigIndex = (segment: string): number => {
    return configuration.findIndex((config) => config.type === segment);
  };

  const getFieldWeight = (configItem: ConfigItem | undefined, path: Array<string>): number => {
    if (!configItem) return Infinity;
    const fields = configItem.fields || [];
    let currentFields = fields;
    let weight = 0;
    let depth = 0;

    for (const segment of path) {
      if (!isNaN(Number(segment))) continue; // Skip numeric indices

      const fieldIndex = currentFields.findIndex((field) => 'path' in field && field.path === segment);
      if (fieldIndex === -1) return Infinity;

      weight = weight * 1000 + fieldIndex;
      const field = currentFields[fieldIndex];
      if ('fields' in field && field.fields) {
        currentFields = field.fields;
        depth++;
      } else {
        break;
      }
    }

    return weight * Math.pow(1000, 10 - depth); // Adjust weight based on depth
  };

  const comparePaths = (aPath: Array<string>, bPath: Array<string>): number => {
    const aConfigIndex = getConfigIndex(aPath[0]);
    const bConfigIndex = getConfigIndex(bPath[0]);

    if (aConfigIndex !== bConfigIndex) {
      return aConfigIndex - bConfigIndex;
    }

    if (aConfigIndex === -1) {
      // Neither path was found in the configuration, compare them lexicographically
      return aPath.join('.').localeCompare(bPath.join('.'));
    }

    const configItem = configuration[aConfigIndex];

    for (let i = 1; i < Math.min(aPath.length, bPath.length); i++) {
      if (aPath[i] !== bPath[i]) {
        // If both segments are numeric, compare them
        if (!isNaN(Number(aPath[i])) && !isNaN(Number(bPath[i]))) {
          return Number(aPath[i]) - Number(bPath[i]);
        }

        // If we've reached a non-numeric difference, compare weights
        const aWeight = getFieldWeight(configItem, aPath.slice(1));
        const bWeight = getFieldWeight(configItem, bPath.slice(1));
        if (aWeight !== bWeight) {
          return aWeight - bWeight;
        }
      }
    }

    return aPath.length - bPath.length;
  };

  return [...errors].sort((a, b) => {
    const aPath = a.id.split('.');
    const bPath = b.id.split('.');
    return comparePaths(aPath, bPath);
  });
};

export const getFutureIndexOfCurrentError = (
  currentErrorIndex: number,
  errorsMap: Record<string, WebEDIError>,
  errorsArray: Array<WebEDIError>,
) => {
  let futureCurrentErrorIndex = currentErrorIndex;
  if (futureCurrentErrorIndex !== -1) {
    const currentError = errorsArray[currentErrorIndex];
    const newCurrentError = errorsMap[currentError.id];
    if (newCurrentError) {
      futureCurrentErrorIndex = errorsArray.findIndex((error) => error.id === newCurrentError.id);
    }
  } else if (errorsArray.length > 0) {
    futureCurrentErrorIndex = 0;
  }

  return futureCurrentErrorIndex;
};
