import { Cell, flexRender } from '@tanstack/react-table';
import { getPinningStyles } from '../layoutHelpers/getPinningStyles';
import classNames from 'classnames';

export type Props<TData, TValue> = {
  cell: Cell<TData, TValue>;
  isLastRow: boolean;
  betweenCellBorders: boolean;
  isFirstColumn: boolean;
  isLastColumn: boolean;
  isAction: boolean;
  className?: string;
  isEven: boolean;
  style?: React.CSSProperties;
};

export const Td = <TData, TValue>({
  cell,
  isLastRow,
  betweenCellBorders,
  className,
  isFirstColumn,
  isLastColumn,
  isAction,
  isEven,
  style,
}: Props<TData, TValue>) => {
  return (
    <td
      key={cell.id}
      data-cellid={cell.id}
      className={classNames(
        'whitespace-nowrap font-normal text-gray-700 border-b border-b-gray-200 border-r border-r-gray-200 last:border-r-0 p-0',
        {
          'border-r-transparent': !betweenCellBorders && !isLastColumn,
          'rounded-bl-md': isFirstColumn && isLastRow,
          'rounded-br-md': isLastColumn && isLastRow,
          'focus-within:border-l': isFirstColumn,
          'focus-within:border-r': isLastColumn,
          'border-b-transparent focus-within:border-b': isLastRow,
          'bg-gray-50': !isEven,
          'bg-white': isEven,
          'w-[40px] max-w-[40px]': isAction,
        },
        className,
      )}
      style={{
        ...style,
        ...getPinningStyles(cell.column),
      }}
    >
      <div
        className={classNames('flex', {
          'justify-end': cell.column.columnDef.meta?.style?.textAlign === 'right',
          'text-right': cell.column.columnDef.meta?.style?.textAlign === 'right',
          'justify-start': cell.column.columnDef.meta?.style?.textAlign !== 'right',
          'text-left': cell.column.columnDef.meta?.style?.textAlign !== 'right',
        })}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </td>
  );
};
