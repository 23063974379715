import React, { cloneElement, ReactNode } from 'react';
import { classNames } from 'support/helpers/generic/generic';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip';

export enum BadgeColor {
  gray = 'gray',
  red = 'red',
  yellow = 'yellow',
  green = 'green',
  blue = 'blue',
  indigo = 'indigo',
  purple = 'purple',
  pink = 'pink',
}

type BadgeProps = {
  icon?: React.ReactNode;
  type?: 'basic' | 'indicator' | 'filters';
  color?: BadgeColor;
  hasDot?: boolean;
  onRemove?: () => void;
  children: React.ReactNode;
  className?: string;
  tooltip?: string | ReactNode;
};

const classes = {
  color: {
    gray: 'bg-gray-50 border-gray-200 text-gray-800',
    red: 'bg-red-50 border-red-100 text-red-800',
    yellow: 'bg-yellow-50 border-yellow-100 text-yellow-800',
    green: 'bg-green-50 border-green-100 text-green-800',
    blue: 'bg-blue-50 border-blue-100 text-blue-800',
    indigo: 'bg-indigo-50 border-indigo-100 text-indigo-800',
    purple: 'bg-purple-50 border-purple-100 text-purple-800',
    pink: 'bg-pink-50 border-pink-100 text-pink-800',
  },
  dotColor: {
    gray: 'text-gray-400',
    red: 'text-red-400',
    yellow: 'text-yellow-400',
    green: 'text-green-400',
    blue: 'text-blue-400',
    indigo: 'text-indigo-400',
    purple: 'text-purple-400',
    pink: 'text-pink-400',
  },
  dotShadowColor: {
    gray: 'bg-gray-50',
    red: 'bg-red-50',
    yellow: 'bg-yellow-50',
    green: 'bg-green-50',
    blue: 'bg-blue-50',
    indigo: 'bg-indigo-50',
    purple: 'bg-purple-50',
    pink: 'bg-pink-50',
  },
  type: {
    basic: 'border',
    indicator: '!bg-transparent',
    filters: 'border bg-gray-50 border-gray-200 text-gray-800',
  },
};

export function Badge({
  icon,
  type = 'basic',
  color = BadgeColor.gray,
  hasDot = type === 'indicator',
  onRemove,
  className,
  children,
  tooltip,
}: BadgeProps) {
  return (
    <Tooltip>
      <TooltipTrigger>
        <span
          className={classNames(
            'inline-flex items-center font-medium py-0.5 height-min-content rounded-full text-xs',
            type !== 'filters' ? classes.color[color] : '',
            classes.type[type],
            onRemove ? 'pl-2 pr-0.5' : 'px-2',
            className ?? '',
          )}
        >
          <div
            className={classNames(
              'relative',
              hasDot ? ' mr-1.5 w-2 h-2' : '',
              type === 'indicator' ? classNames(classes.dotShadowColor[color], 'rounded-full w-4 h-4 mr-1.5') : '',
            )}
          >
            {hasDot && (
              <svg
                data-testid="badge-dot"
                className={classNames(
                  'w-2 h-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                  classes.dotColor[color],
                )}
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx={4} cy={4} r={3} />
              </svg>
            )}
          </div>
          {icon && cloneElement(icon as React.ReactElement, { className: 'w-4 h-4 mr-1.5' })}
          <span className="truncate leading-4">{children}</span>
          {!!onRemove && (
            <button
              type="button"
              onClick={onRemove}
              className={classNames(
                'flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center focus-visible:outline-none focus-visible:text-white text-gray-600 hover:text-gray-500 focus-visible:bg-gray-500',
              )}
            >
              <span className="sr-only">Remove</span>
              <svg className="size-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
              </svg>
            </button>
          )}
        </span>
      </TooltipTrigger>
      {tooltip && <TooltipContent>{tooltip}</TooltipContent>}
    </Tooltip>
  );
}
