import {
  useCreateIntegrationProcess,
  useUpdateIntegrationProcess,
} from 'services/repositories/integrationProcesses/integrationProcesses';
import { EdifactFormState } from './ProcessTypes/Edifact/EdifactForm/EdifactForm';
import {
  DocumentType,
  IntegrationProcessConfigurationSteps,
  IntegrationProcessDTO,
  IntegrationProcessRoutes,
  ProcessDirection,
} from 'support/types';
import { generateDefaultValues, integrationProcessSchema } from '../IntegrationProcessConfigurationStep/helpers';
import { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { NewEditIntegrationProcessForm } from './components/NewEditIntegrationProcessForm/NewEditIntegrationProcessForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EdifactSideInformation } from './ProcessTypes/Edifact/EdifactSideInformation/EdifactSideInformation';
import { Form } from 'components/Form/Form';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { routeToPage } from 'support/helpers/navigation/navigation';

type IntegrationProcessForm = {
  messageType: DocumentType;
  direction: ProcessDirection;
  configuration: EdifactFormState;
};

type IntegrationProcessConfigurationStepProps = {
  integrationId: string;
  process: Partial<IntegrationProcessDTO> | undefined;
  onSuccess: (processId: string) => void;
};
export const IntegrationProcessConfigurationStep = ({
  integrationId,
  process,
  onSuccess,
}: IntegrationProcessConfigurationStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: createProcess, isLoading: isCreatingProcess } = useCreateIntegrationProcess();
  const { mutateAsync: updateProcess, isLoading: isUpdatingProcess } = useUpdateIntegrationProcess();
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const defaultValues = useMemo(() => generateDefaultValues(process), [process]);
  const formMethods = useForm<IntegrationProcessForm>({
    resolver: yupResolver(integrationProcessSchema(t)),
    values: defaultValues,
  });
  const {
    formState: { isDirty },
    setError,
    handleSubmit,
  } = formMethods;

  const submit = async (data: IntegrationProcessForm) => {
    setSubmitErrors([]);
    if (!integrationId) return;
    let processIdToUpdate = process?.id;
    try {
      if (!processIdToUpdate) {
        const process = await createProcess({ integrationId, data });
        processIdToUpdate = process.id;
        navigate(
          routeToPage(IntegrationProcessRoutes.edit, {
            id: integrationId,
            processId: processIdToUpdate,
            step: IntegrationProcessConfigurationSteps.CONFIGURATION,
          }),
        );
      }

      if (!processIdToUpdate) return;
      if (!isDirty && process?.hasValidConfiguration) {
        return onSuccess(processIdToUpdate);
      }
      await updateProcess({ integrationId, processId: processIdToUpdate, data });
      onSuccess(processIdToUpdate);
    } catch (error) {
      processSubmissionErrors({
        error: error as Error,
        setNonInputErrors: setSubmitErrors,
        t,
        setInputError: setError,
        defaultData: defaultValues,
      });
    }
  };

  return (
    <>
      <ValidationErrors errors={submitErrors} />
      <div className="grid grid-cols-2 gap-12">
        <Form className="flex flex-col gap-6" onSubmit={handleSubmit(submit)}>
          <FormProvider {...formMethods}>
            <NewEditIntegrationProcessForm
              isLoading={isCreatingProcess || isUpdatingProcess}
              EditControls={Boolean(process && Object.values(process).filter(Boolean).length > 0)}
            />
          </FormProvider>
        </Form>
        <EdifactSideInformation />
      </div>
    </>
  );
};
