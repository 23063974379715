import { ColumnDef } from '@tanstack/react-table';

export const BATCHES_SUFFIX = 'batches';

const lastBatchGroupId = (columns: Array<ColumnDef<Record<string, string>>>) => {
  const lastBatchGroup =
    columns
      .map((col) => {
        const accessorKey = 'accessorKey' in col ? col.accessorKey : '';
        return accessorKey.includes(BATCHES_SUFFIX) ? accessorKey.split('.')[1] : undefined;
      })
      .reduce((unique, item) => {
        if (item) {
          return unique.add(item);
        }
        return unique;
      }, new Set()).size - 1;

  const lastBatchGroupLastColumn = columns.findIndex((col) => {
    const accessorKey = 'accessorKey' in col ? col.accessorKey : '';
    return accessorKey.includes(`batches.${lastBatchGroup}`);
  });

  // + 1 to get the second column so we are more centered in the batch group
  const column = columns[lastBatchGroupLastColumn + 1] || columns[lastBatchGroupLastColumn];
  return column && 'accessorKey' in column ? column.accessorKey : '';
};

export const scrollToBatch = (tableId: string, columns: Array<ColumnDef<Record<string, string>>>) => {
  const columnId = lastBatchGroupId(columns);
  const table = document.getElementById(tableId);
  if (!table) {
    return; // Return if the table is not found
  }

  const columnElement = document.getElementById(`${tableId}.0.${columnId}`);
  if (!columnElement) {
    return; // Return if the column element is not found
  }

  const containerRect = table.getBoundingClientRect();
  const elementRect = columnElement.getBoundingClientRect();

  // Calculate the x position of the element relative to the table
  const xPosition = elementRect.left - containerRect.left;

  // Get the current scroll position of the table
  const currentScrollLeft = table.scrollLeft;

  // Calculate the new scroll position by adding the element's x position to the current scroll position
  const newScrollLeft = currentScrollLeft + xPosition;

  // Scroll the table to the new scroll position, preserving the current vertical scroll position
  table?.scrollTo({
    left: newScrollLeft,
    behavior: 'smooth',
  });
};
