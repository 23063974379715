import get from 'lodash/get';
import { useEffect, useRef } from 'react';
import { useDatachecksStore } from 'stores/datachecks/datachecks';

export const useInitialValue = (fieldId: string) => {
  const initialValue = useRef();
  const initialData = useDatachecksStore((state) => state.initialFlattenData);

  useEffect(() => {
    initialValue.current = get(initialData, fieldId);
  }, [fieldId, initialData]);

  return initialValue.current;
};
