import { Table } from 'components/Display/Table/Table';
import { ComponentType } from 'react';
export enum PropertyFilterType {
  dropdown = 'dropdown',
  search = 'search',
  date = 'date',
  hidden = 'hidden',
  checkbox = 'checkbox',
}

export type SearchPropertyFilter = PropertyFilter & {
  type: PropertyFilterType.search;
  placeholder: (count: number) => string | string;
};

export type PropertyFilter = {
  key: string;
  label?: string;
  type: PropertyFilterType;
  value: string;
  dropDownOptions?: {
    dropdownValues: Array<{ label: string; value: string }>;
    dropdownDefaultLabel?: string;
    defaultToFirstValue?: boolean;
  };
  placeholder?: string;
  endDate?: string;
  eventDetails?: {
    event: string;
    properties?: Record<string, any>;
  };
};

export type FilterValue = {
  key: string;
  value: string;
};

export type Filter = {
  cursor?: string | null;
  perPage?: string | null;
  filterValues?: Array<FilterValue>;
  include?: string;
};

export type TableHeader = {
  key?: string;
  label: string;
  invisible?: boolean;
  className?: string;
  tooltip?: string;
};

export type TableItem = {
  element: React.ReactNode;
  key: string;
  className?: string;
  options?: Pick<Parameters<typeof Table.Cell>[0], 'withHighlight'>;
};

export type PaginationSelectorType = {
  display: ComponentType;
  pageKey: string;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
};

export enum PageSelectorKey {
  ellipsisStart = 'ellipsisStart',
  ellipsisEnd = 'ellipsisEnd',
  forwardArrow = 'forwardArrow',
  backwardArrow = 'backwardArrow',
}
