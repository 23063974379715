import { Row } from '@tanstack/react-table';
import { DataRenderModes } from '../Types';

export type Props<TData> = {
  rows: Array<Row<TData>>;
  mode: keyof typeof DataRenderModes;
};

/**
 * React hook. Decides which data render mode to use in the table based on `dataRenderMode` prop and rows amount.
 */
export const useDataRenderMode = <TData>({
  rows,
  mode,
}: Props<TData>): DataRenderModes.plain | DataRenderModes.virtualized | DataRenderModes.scrollable => {
  if (mode !== DataRenderModes.auto) {
    return mode as DataRenderModes.plain | DataRenderModes.virtualized | DataRenderModes.scrollable;
  }

  if (rows.length <= 50) {
    return DataRenderModes.plain;
  }

  if (rows.length > 50 && rows.length <= 100) {
    return DataRenderModes.scrollable;
  }

  return DataRenderModes.virtualized;
};
