import { DocumentType } from 'support/types';

export const DOCUMENTATION_LINK = 'https://help.procuros.io';
export const SUPPORT_EMAIL = 'help@procuros.io';
export const EMPTY_FIELD = '—';
export const ORDER_OF_DOCUMENTS: Array<DocumentType> = [
  DocumentType.order,
  DocumentType.orderResponse,
  DocumentType.dispatchInstruction,
  DocumentType.dispatchResponse,
  DocumentType.shippingNotice,
  DocumentType.invoice,
  DocumentType.creditNote,
  DocumentType.productCatalog,
  DocumentType.unknown,
];

export const DEFAULT_REFETCH_INTERVAL = 10000; //10 seconds
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const EMPTY_FUNCTION = () => {};
export const DATA_CHECK_ERROR_CODE = 1724421292;
