import { EnvelopeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button';
import { useTranslation } from 'react-i18next';
import { AuthBodySection } from '../AuthBodySection/AuthBodySection';
import { Translate } from 'components/Translate/Translate';

export const PasswordlessSuccess = ({
  toggleLoginMethod,
  usedEmail,
  onBack,
}: {
  toggleLoginMethod: (() => void) | undefined;
  usedEmail: string;
  onBack: undefined | (() => void);
}) => {
  const { t } = useTranslation();
  return (
    <AuthBodySection nonInputErrors={[]} heading={t('auth:heading.passwordlessSuccess')} onSubmit={undefined}>
      <div className="flex flex-col items-center gap-6">
        <div className="w-min rounded-full bg-procuros-grey p-4 text-slate-900">
          <EnvelopeIcon className="size-8" />
        </div>

        <div className="text-center text-sm text-gray-900">
          <Translate
            i18nKey="auth:body.passwordlessSuccess"
            components={{
              changeEmail: onBack ? <ChangeEmailComponent email={usedEmail} onBack={onBack} /> : <></>,
            }}
          />
        </div>
        {toggleLoginMethod ? (
          <Button
            type="button"
            variant="text"
            className="mt-2 !text-gray-900 underline"
            fullWidth
            analyticsId="login:toggle-login-method"
            onClick={toggleLoginMethod}
          >
            {t('auth:change-method.loginWithPassword')}
          </Button>
        ) : null}
      </div>
    </AuthBodySection>
  );
};

const ChangeEmailComponent = ({ email, onBack }: { email: string; onBack: () => void }) => {
  return (
    <div className="flex items-center justify-center py-2">
      <span className="text-sm font-semibold text-gray-900">{email}</span>
      <Button
        size="extra-small"
        onClick={onBack}
        iconOnly
        LeftIcon={PencilSquareIcon}
        variant="minimal"
        analyticsId="login:change-passwordless-email"
      />
    </div>
  );
};
