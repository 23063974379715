import { Filter, FilterValue } from 'components/ListTable/types';

export const buildFilterUrl = (query?: Filter): string => {
  if (!query) {
    return '';
  }

  const { filterValues, cursor, perPage, include } = query;

  const urlParams =
    filterValues
      ?.filter((filter: FilterValue) => filter.value.length)
      .map((filter: FilterValue) => `filter[${filter.key}]=${filter.value}`) || [];

  if (cursor !== null && cursor !== undefined) {
    urlParams.push(`cursor=${cursor}`);
  }

  if (include && include !== '') {
    urlParams.push(`include=${include}`);
  }

  if (perPage !== null && perPage !== undefined) {
    urlParams.push(`perPage=${perPage}`);
  }
  return urlParams.length ? `?${urlParams.join('&')}` : '';
};

export const withParameter = (parameters: Array<string>): string => {
  return parameters.length ? `?with[]=${parameters.join(',')}` : '';
};
