import { DocumentType } from 'support/types';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { useTranslation } from 'react-i18next';
import { getDocumentSummary } from './helpers';

export const DocumentSummary = ({ documentType }: { documentType: DocumentType | undefined }) => {
  const { t } = useTranslation();
  if (!documentType) {
    return null;
  }

  const summary = getDocumentSummary(documentType);

  return (
    <WebEDISection
      header={{
        title: t('webedi:documentSummary.title', {
          documentType: t(`common:messageTypes.singular.${documentType}`),
        }),
        spacing: 'medium',
      }}
    >
      {summary}
    </WebEDISection>
  );
};
