import { QueryClient } from '@tanstack/react-query';
import { useMessage, useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { useTransactionMessage, useTransactions } from 'services/repositories/transactions/transactions';

const resetMultipleQueries = (
  queryClient: QueryClient,
  keys: Array<Parameters<QueryClient['invalidateQueries']>[0]>,
) => {
  return Promise.all(keys.map((key) => queryClient.resetQueries(key)));
};

export const resetMessageQueries = (queryClient: QueryClient, messageId: string) => {
  const useTransactionMessageKey = useTransactionMessage.getKey({ messageId });
  const useMessageKey = useMessage.getKey({ messageId });
  const useMessageWithProcessSpecifications = useMessageWithProcessSpecification.getKey({ messageId });
  return resetMultipleQueries(queryClient, [
    useTransactionMessageKey,
    useMessageKey,
    useTransactions.getKey(),
    useMessageWithProcessSpecifications,
  ]);
};
