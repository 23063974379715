import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Section } from 'components/Display/Section/Section';
import { Button } from 'components/Form/Button/Button';
import { Translate } from 'components/Translate/Translate';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToNewIntegrationPage } from 'support/helpers/navigation/navigation';
import { Connectors, IntegrationConfigurationSteps } from 'support/types';

export const WebEDIInitialInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="space-y-14">
      <Section.Vertical
        header={<Section.Title>{t('integrations:new.webedi.INITIAL_INFORMATION.sections.0.title')}</Section.Title>}
      >
        <div className="space-y-4">
          <Translate i18nKey="integrations:new.webedi.INITIAL_INFORMATION.sections.0.description" />
        </div>
      </Section.Vertical>

      <Section.Vertical
        header={<Section.Title>{t('integrations:new.webedi.INITIAL_INFORMATION.sections.1.title')}</Section.Title>}
      >
        <div className="space-y-4">
          <Translate
            i18nKey="integrations:new.webedi.INITIAL_INFORMATION.sections.1.description"
            components={{ li: <ListItemWithIcon /> }}
          />
        </div>
      </Section.Vertical>

      <Section.Vertical
        header={<Section.Title>{t('integrations:new.webedi.INITIAL_INFORMATION.sections.2.title')}</Section.Title>}
      >
        <div className="space-y-4">
          <Translate
            i18nKey="integrations:new.webedi.INITIAL_INFORMATION.sections.2.description"
            components={{ li: <ListItemWithIcon /> }}
          />
        </div>
      </Section.Vertical>
      <Button
        type="button"
        onClick={() =>
          navigate(routeToNewIntegrationPage(Connectors.WEB_EDI, IntegrationConfigurationSteps.CONFIGURATION))
        }
        analyticsId="integrations:new-webedi-integration"
      >
        {t('integrations:new.webedi.INITIAL_INFORMATION.cta')}
      </Button>
    </div>
  );
};

const ListItemWithIcon = ({ children }: { children?: ReactNode }) => (
  <li className="flex items-center gap-3">
    <CheckCircleIcon className="size-6 text-procuros-green-400" aria-hidden="true" />
    {children}
  </li>
);
