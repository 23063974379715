import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';
import { AuthRoutes } from 'support/types';
import { useNotificationStore } from 'stores/notifications/notifications';
import { useRequestPasswordReset } from 'services/repositories/user/user';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Button } from 'components/Form/Button/Button';
import { processSubmissionErrors } from 'support/helpers/errors/errors';

type ForgotPasswordSubmission = {
  email: string;
};

export function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [forgotPasswordError, setForgotPasswordError] = useState<Array<string>>([]);
  const { addNotification } = useNotificationStore();

  const { mutate, isLoading } = useRequestPasswordReset();

  const { register, handleSubmit } = useForm<ForgotPasswordSubmission>();

  const onSubmit = async (submission: ForgotPasswordSubmission) => {
    mutate(submission, {
      onSuccess: () => {
        navigate(AuthRoutes.login);
        addNotification(t('auth:resetEmail'));
      },
      onError: (error) => {
        processSubmissionErrors({
          error,
          setInputError: undefined,
          setNonInputErrors: setForgotPasswordError,
          defaultData: undefined,
          t,
        });
      },
    });
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ApplicationLogo variant="dark" className="mx-auto h-11 w-auto" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10"
          data-testid="forgotPasswordForm"
        >
          <p className="mb-4 text-sm text-gray-600">{t('auth:forgotPasswordText')}</p>

          <ValidationErrors errors={forgotPasswordError} />

          <Input
            {...register('email', { required: true })}
            placeholder={t('auth:email')}
            autoComplete="username"
            label={t('auth:emailAddress')}
            type="email"
            required
          />

          <Button type="submit" fullWidth loading={isLoading} analyticsId="password-reset:email-send">
            {t('auth:sendEmail')}
          </Button>
        </Form>
      </div>
    </div>
  );
}
