import { useEffect, useState } from 'react';

export const useTruthyCachedValue = (updateState: boolean, value: string | undefined) => {
  const [state, setState] = useState<string | undefined>(value);
  useEffect(() => {
    if (updateState && value) {
      setState(value);
    }
  }, [updateState, value]);
  return state;
};
