import { Page } from 'components/Page/Page';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewTradePartner } from './NewTradePartner/NewTradePartner';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { UserPlusIcon, UsersIcon } from '@heroicons/react/24/outline';
import { NewBulkTradePartner } from './NewBulkTradePartner/NewBulkTradePartner';
import { getTraderPartnersFilterConfig } from './filtersConfig';
import { useNewFilters } from 'hooks/useFilters';
import { PartnerList } from './PartnerList/PartnerList';
import { useInfinitePartners } from 'services/repositories/partners/partners';
import { useDebounce } from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import { Filters } from 'components/ListTable/Filters/Filters';

export function TradePartners() {
  const { t } = useTranslation();
  const [showTradePartner, setShowTradePartner] = useState<boolean>(false);
  const [showBulkTradePartner, setShowBulkTradePartner] = useState<boolean>(false);
  const filtersConfig = useMemo(() => getTraderPartnersFilterConfig(), []);
  const { filters, filterValues, perPage, updateFilters, clearAllFilters } = useNewFilters(
    filtersConfig,
    'tradePartners',
  );
  const hasFilters = filterValues && filterValues.length > 0;
  const debouncedFilterValues = useDebounce(filterValues, 150);
  const differentFilters = !isEqual(debouncedFilterValues, filterValues);

  const {
    data: partnersData,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    hasPreviousPage,
  } = useInfinitePartners({
    variables: {
      query: {
        filterValues: debouncedFilterValues,
        perPage,
      },
    },
  });

  return (
    <Page>
      <Page.Head
        title={t('common:mainMenu.tradePartners')}
        description={t('partners:description')}
        rightContent={
          <MenuButton text={t('partners:list.inviteTradePartner')} analyticsId="partners:invite">
            <MenuButtonOption
              onClick={() => setShowTradePartner(true)}
              Icon={UserPlusIcon}
              analyticsId="partners:invite_single"
            >
              {t('partners:list.inviteSingleTradePartner')}
            </MenuButtonOption>
            <MenuButtonOption
              onClick={() => setShowBulkTradePartner(true)}
              Icon={UsersIcon}
              analyticsId="partners:invite_bulk"
            >
              {t('partners:list.inviteBulkTradePartner')}
            </MenuButtonOption>
          </MenuButton>
        }
      >
        <Filters
          propertyFilters={filters}
          onFiltersChange={updateFilters}
          filterValues={filterValues}
          totalElements={partnersData?.pages?.[0].totalCount}
          clearAllFilters={clearAllFilters}
        />
      </Page.Head>

      <Page.Section>
        <PartnerList
          data={partnersData}
          isLoading={isLoading || (differentFilters && !partnersData?.pages[0]?.items.length)}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasPreviousPage={hasPreviousPage}
          clearAllFilters={clearAllFilters}
          hasFilters={hasFilters}
        />
      </Page.Section>
      <NewTradePartner showPopup={showTradePartner} setShowPopup={setShowTradePartner} />
      <NewBulkTradePartner showPopup={showBulkTradePartner} setShowPopup={setShowBulkTradePartner} />
    </Page>
  );
}
