import { SlideOver, SlideOverSubtitleSkeleton, SlideOverTitleSkeleton } from 'components/Display/SlideOver/SlideOver';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useRelationship } from 'services/repositories/relationships/relationships';
import { getTradePartner } from 'support/helpers/relationships/relationships';
import { ConnectionsTestWebEdi } from './components/ConnectionTestWebEDI/ConnectionsTestWebEDI';
import { ConnectionsTestNonWebEDI } from './components/ConnectionTestNonWebEDI/ConnectionsTestNonWebEDI';
import { ConnectionsRoutes, Connectors, RelationshipStatus, RelationshipV2 } from 'support/types';
import { ReactNode, useEffect } from 'react';
import { isReceiver } from '../RelationshipDetails/components/RelationshipDetailsHeader/components/RelationshipDetailsBanner/helpers';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { useHistoryStore } from 'stores/history/history';
import { TestMessagePendingReview } from './components/TestMessagePendingReview/TestMessagePendingReview';

export const ConnectionsTestSlideOver = ({ open, children }: { open: boolean; children?: ReactNode }) => {
  const { history } = useHistoryStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { relationshipId } = useParams<{ relationshipId: string }>() as { relationshipId: string };

  const { data: currentPartner, isLoading: isCurrentPartnerLoading } = useCurrentPartner();
  const { data: relationship, isLoading: isLoadingRelationship } = useRelationship({
    variables: {
      relationshipId,
    },
    keepPreviousData: true,
    enabled: Boolean(relationshipId),
  });

  const isLoading = isCurrentPartnerLoading || isLoadingRelationship;
  const otherPartner = relationship && currentPartner && getTradePartner(relationship, currentPartner.id);

  let slideOverContent: ReactNode = null;

  if (relationship) {
    switch (relationship.status) {
      case RelationshipStatus.READY_FOR_TESTING:
      case RelationshipStatus.HAS_TEST_MESSAGES:
        slideOverContent =
          relationship.sender_partner.id === currentPartner?.id ? (
            <TestMessageRequested relationship={relationship} currentPartnerId={currentPartner?.id} />
          ) : null;
        break;
      case RelationshipStatus.IN_REVIEW:
        slideOverContent =
          relationship.receiver_partner.id === currentPartner?.id ? (
            <TestMessagePendingReview relationship={relationship} currentPartnerId={currentPartner?.id} />
          ) : null;
        break;
    }
  }

  useEffect(() => {
    //In case the view has nothing to show, we redirect to the relationship details page
    if (!slideOverContent && !isLoading && relationshipId) {
      navigate(routeToPage(ConnectionsRoutes.relationshipDetails, { relationshipId }), { replace: true });
    }
  }, [isLoading, navigate, relationshipId, slideOverContent]);

  return (
    <SlideOver
      size="large"
      open={open}
      onBack={
        history.length <= 1
          ? undefined
          : () => navigate(routeToPage(ConnectionsRoutes.relationshipDetails, { relationshipId }))
      }
      setOpen={() => navigate(routeToPage(ConnectionsRoutes.root))}
      title={isLoading ? <SlideOverTitleSkeleton /> : otherPartner?.name}
      subtitle={
        isLoading ? (
          <SlideOverSubtitleSkeleton />
        ) : (
          t('connections:connectionsTest.drawer.subtitle', {
            documentType: t(`common:messageTypes.plural.${relationship?.message_type}`),
          })
        )
      }
      headerImage={<PartnerLogo imageUrl={otherPartner?.logo_url} size="large" isLoading={isLoading} />}
    >
      {slideOverContent}
      {!isLoading && children}
    </SlideOver>
  );
};

const TestMessageRequested = ({
  relationship,
  currentPartnerId,
}: {
  relationship: RelationshipV2;
  currentPartnerId: string | undefined;
}) => {
  if (!currentPartnerId) return null;
  if (isReceiver({ relationship, currentPartnerId })) {
    return null;
  }

  const senderConnector =
    relationship?.sender_integration?.testing?.connector || relationship?.sender_integration?.production?.connector;

  return senderConnector === Connectors.WEB_EDI ? (
    relationship ? (
      <ConnectionsTestWebEdi relationship={relationship} />
    ) : null
  ) : (
    <ConnectionsTestNonWebEDI relationship={relationship} />
  );
};
