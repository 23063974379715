import { createMutation, createQuery } from 'react-query-kit';
import { createData, downloadFile, fetchData, patchData, queryClient, updateData } from 'services/http/http';
import portalSchema from 'support/types/schema-portal';
import { useCurrentPartner } from '../partners/partners';

type GetIntegrationProcess =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}']['get']['responses']['200']['content']['application/json'];
type GetIntegrationProcessTest =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/process-tests']['get']['responses']['200']['content']['application/json'];
type CreateIntegrationProcessTestPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/process-tests']['post']['requestBody']['content']['application/json'];
type CreateIntegrationProcessTestResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/process-tests']['post']['responses']['201']['content']['application/json'];
type CreateIntegrationProcessDeployResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/deploy']['post']['responses']['200']['content']['application/json'];
type CreateIntegrationProcessPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes']['post']['requestBody']['content']['application/json'];
type CreateIntegrationProcessResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes']['post']['responses']['201']['content']['application/json'];
type UpdateIntegrationProcessPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}']['put']['requestBody']['content']['application/json'];
type UpdateIntegrationProcessResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}']['put']['responses']['200']['content']['application/json'];
type CreateProcessTransformationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/transformations']['post']['responses']['201']['content']['application/json'];
type CreateProcessTransformationPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/transformations']['post']['requestBody']['content']['application/json'];
type UpdateProcessTransformationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/transformations/{transformationId}']['patch']['responses']['200']['content']['application/json'];
type UpdateProcessTransformationPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/transformations/{transformationId}']['patch']['requestBody']['content']['application/json'];
type CreateProcessSchemaResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/schemas']['post']['responses']['201']['content']['application/json'];
type CreateProcessSchemaPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/schemas']['post']['requestBody']['content']['application/json'];
type UpdateProcessSchemaResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/schemas/{schemaId}']['patch']['responses']['200']['content']['application/json'];
type UpdateProcessSchemaPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/schemas/{schemaId}']['patch']['requestBody']['content']['application/json'];
type PushProcessConfigurationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/configuration']['put']['responses']['204']['content']['application/json'];
type PushProcessConfigurationPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/configuration']['put']['requestBody']['content']['application/json'];

export const usePushProcessConfiguration = createMutation<
  PushProcessConfigurationResponse,
  { integrationId: string | undefined; processId: string | undefined; data: PushProcessConfigurationPayload },
  Error
>({
  mutationFn: async ({ integrationId, processId, data }) => {
    const response = await updateData<PushProcessConfigurationResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/configuration`,
      data,
    );
    return response.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useIntegrationProcess = createQuery<
  GetIntegrationProcess,
  { integrationId: string | undefined; processId: string | undefined },
  Error
>({
  primaryKey: '/integrations/processes',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<GetIntegrationProcess>(
      `/v1/integrations/${variables.integrationId}/processes/${variables.processId}`,
    );
    return data;
  },
  enabled: (_, variables) => !!variables.integrationId && !!variables.processId,
});

export const useCreateIntegrationProcess = createMutation<
  CreateIntegrationProcessResponse,
  { integrationId: string | undefined; data: CreateIntegrationProcessPayload },
  Error
>({
  mutationFn: async ({ integrationId, data }) => {
    const response = await createData<CreateIntegrationProcessResponse>(
      '/v1/integrations/' + integrationId + '/processes',
      data,
    );
    queryClient.setQueryData(
      useIntegrationProcess.getKey({
        integrationId: integrationId,
        processId: response.data.id,
      }),
      response.data,
    );
    return response.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useCreateProcessSchema = createMutation<
  CreateProcessSchemaResponse['data'],
  { integrationId: string | undefined; processId: string | undefined; data: CreateProcessSchemaPayload },
  Error
>({
  mutationFn: async ({ integrationId, processId, data }) => {
    const response = await createData<CreateProcessSchemaResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/schemas`,
      data,
    );
    return response.data.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useUpdateProcessSchema = createMutation<
  UpdateProcessSchemaResponse['data'],
  {
    integrationId: string | undefined;
    processId: string | undefined;
    schemaId: string | undefined;
    data: UpdateProcessSchemaPayload;
  },
  Error
>({
  mutationFn: async ({ integrationId, processId, schemaId, data }) => {
    const response = await patchData<UpdateProcessSchemaResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/schemas/${schemaId}`,
      data,
    );
    return response.data.data;
  },
  // onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useCreateProcessTransformation = createMutation<
  CreateProcessTransformationResponse['data'],
  { integrationId: string | undefined; processId: string | undefined; data: CreateProcessTransformationPayload },
  Error
>({
  mutationFn: async ({ integrationId, processId, data }) => {
    const response = await createData<CreateProcessTransformationResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/transformations`,
      data,
    );
    return response.data.data;
  },
  // onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useUpdateProcessTransformation = createMutation<
  UpdateProcessTransformationResponse['data'],
  {
    integrationId: string | undefined;
    processId: string | undefined;
    transformationId: string | undefined;
    data: UpdateProcessTransformationPayload;
  },
  Error
>({
  mutationFn: async ({ integrationId, processId, transformationId, data }) => {
    const response = await patchData<UpdateProcessTransformationResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/transformations/${transformationId}`,
      data,
    );
    return response.data.data;
  },
  // onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useUpdateIntegrationProcess = createMutation<
  UpdateIntegrationProcessResponse,
  { integrationId: string; processId: string; data: UpdateIntegrationProcessPayload },
  Error
>({
  mutationFn: async ({ integrationId, processId, data }) => {
    const response = await updateData<UpdateIntegrationProcessResponse>(
      '/v1/integrations/' + integrationId + '/processes/' + processId,
      data,
    );
    return response.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useIntegrationProcess.getKey()),
});

export const useIntegrationProcessDeploy = createMutation<
  CreateIntegrationProcessDeployResponse,
  { integrationId: string; processId: string },
  Error
>({
  mutationFn: async ({ integrationId, processId }) => {
    const response = await createData<CreateIntegrationProcessDeployResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/deploy`,
    );
    return response.data;
  },
  onSuccess: () =>
    Promise.all([
      queryClient.invalidateQueries(useCurrentPartner.getKey()),
      queryClient.invalidateQueries(useIntegrationProcess.getKey()),
    ]),
});

export const useIntegrationProcessTests = createQuery<
  GetIntegrationProcessTest,
  { integrationId: string | undefined; processId: string | undefined },
  Error
>({
  primaryKey: '/integrations/processes/tests',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<GetIntegrationProcessTest>(
      `/v1/integrations/${variables.integrationId}/processes/${variables.processId}/process-tests`,
    );
    return data;
  },
  enabled: (_, variables) => !!variables.integrationId && !!variables.processId,
});

export const useCreateIntegrationProcessTest = createMutation<
  CreateIntegrationProcessTestResponse,
  { integrationId: string; processId: string; data: CreateIntegrationProcessTestPayload },
  Error
>({
  mutationFn: async (variables) => {
    const response = await createData<CreateIntegrationProcessTestResponse>(
      `/v1/integrations/${variables.integrationId}/processes/${variables.processId}/process-tests`,
      variables.data,
    );
    return response.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useIntegrationProcessTests.getKey()),
});

export const downloadIntegrationProcessExample = ({
  processId,
  integrationId,
  type,
  filename,
}: {
  processId: string;
  integrationId: string;
  type: string;
  filename: string;
}) => {
  return downloadFile(
    `/v1/integrations/${integrationId}/processes/${processId}/download-process-tests-fixtures?type=${type}`,
    { filename },
  );
};
