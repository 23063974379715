import { ReactElement, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const DEFAULT_COMPONENTS = {
  ol: <ol className="space-y-2 first:*:mt-2" />,
  ul: <ul className="space-y-2 first:*:mt-2" />,
  li: <li className="ms-4 list-disc" />,
  b: <span className="font-bold" />,
  a: <a target="_blank" className="underline hover:text-procuros-green-400" />,
  br: <br />,
  div: <div />,
  code: <code className="rounded-md border border-gray-200 bg-white px-1 py-0.5 text-xs text-procuros-green-700" />,
};

export const Translate = ({
  i18nKey,
  components,
  values,
}: {
  i18nKey: string;
  components?: Record<string, ReactElement>;
  values?: Record<string, string | number | undefined | ReactNode>;
}) => {
  const { t } = useTranslation();

  return <Trans t={t} i18nKey={i18nKey} components={{ ...DEFAULT_COMPONENTS, ...components }} values={values} />;
};
