import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';

export const Bullets = ({ numberOfSteps, activeStep }: { numberOfSteps: number; activeStep: number }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between gap-4">
      <span className="text-sm font-medium text-gray-500">
        {t('common:bullets.step', { activeStep: activeStep + 1, numberOfSteps })}
      </span>
      <div className="flex items-center justify-between gap-1">
        {Array.from({ length: numberOfSteps }, (_, i) => (
          <Bullet key={i} isActive={i === activeStep} isCompleted={i < activeStep} />
        ))}
      </div>
    </div>
  );
};

const Bullet = ({ isActive, isCompleted }: { isActive: boolean; isCompleted: boolean }) => {
  const internalClasses = classNames(
    'h-[10px] w-[10px] rounded-full',
    isCompleted ? 'bg-procuros-green-400' : 'bg-gray-200',
    isActive ? 'bg-procuros-green-400 ' : 'bg-gray-200',
  );

  const externalClasses = classNames(
    'h-5 w-5 rounded-full flex items-center justify-center',
    isActive ? 'bg-procuros-green-200' : undefined,
  );

  return (
    <div
      className={externalClasses}
      data-testid={isActive ? 'active-bullet' : isCompleted ? 'completed-bullet' : 'inactive-bullet'}
    >
      <div className={internalClasses} />
    </div>
  );
};
