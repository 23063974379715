import { Page } from 'components/Page/Page';
import { useNewFilters } from 'hooks/useFilters';
import { t } from 'i18next';
import { useInfinitePricingConfigs } from 'services/repositories/pricingConfigs/pricingConfigs';
import { routeToPricingConfigEditPage } from 'support/helpers/navigation/navigation';
import { PricingConfigList } from './PricingConfigList/PricingConfigList';
import { PricingConfigsFilterKey, getDefaultFilterConfig } from './tableConfig';
import { useConnectedPartners } from 'services/repositories/partners/partners';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { ArrowUpTrayIcon, PlusIcon } from '@heroicons/react/24/outline';
import { UploadPricingConfig } from '../../components/UploadPricingConfig/UploadPricingConfig';
import { useState, useMemo } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import { addPartnersToFiltersConfig } from 'support/helpers/filters/filters';
import { Filters } from 'components/ListTable/Filters/Filters';

export function PricingConfigsPage() {
  const { data: partners } = useConnectedPartners();
  const [uploadPopupOpen, setUploadPopupOpen] = useState(false);
  const filterConfig = useMemo(
    () => addPartnersToFiltersConfig(getDefaultFilterConfig(), PricingConfigsFilterKey.partnerId, partners),
    [partners],
  );
  const { filterValues, filters, updateFilters, clearAllFilters, perPage } = useNewFilters(
    filterConfig,
    'pricingConfigs',
  );
  const hasFilters = filterValues && filterValues.length > 0;
  const debouncedFilterValues = useDebounce(filterValues, 150);
  const differentFilters = !isEqual(debouncedFilterValues, filterValues);

  const {
    data: pricingConfigsData,
    isLoading: pricingConfigsLoading,
    isFetchingNextPage,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
  } = useInfinitePricingConfigs({
    variables: {
      query: { perPage, filterValues: debouncedFilterValues },
    },
  });

  return (
    <Page>
      <Page.Head
        title={t('common:mainMenu.priceLists')}
        description={t('priceList:description')}
        rightContent={
          <MenuButton text={t('priceList:list.new')} analyticsId="pricing-configs:new">
            <MenuButtonOption to={routeToPricingConfigEditPage()} Icon={PlusIcon} analyticsId="price-configs:edit">
              {t('priceList:list.create')}
            </MenuButtonOption>
            <MenuButtonOption
              onClick={() => setUploadPopupOpen(true)}
              Icon={ArrowUpTrayIcon}
              analyticsId="price-configs:upload"
            >
              {t('priceList:list.upload')}
            </MenuButtonOption>
          </MenuButton>
        }
      >
        <Filters
          propertyFilters={filters}
          onFiltersChange={updateFilters}
          filterValues={filterValues}
          clearAllFilters={clearAllFilters}
        />
      </Page.Head>

      <Page.Section>
        <PricingConfigList
          pricingConfigsData={pricingConfigsData?.pages.flatMap((page) => page.items)}
          isLoading={pricingConfigsLoading || (differentFilters && !pricingConfigsData?.pages[0]?.items.length)}
          isFetchingNextPage={isFetchingNextPage}
          onNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          clearAllFilters={clearAllFilters}
          hasFilters={hasFilters}
        />
        <UploadPricingConfig open={uploadPopupOpen} setOpen={setUploadPopupOpen} />
      </Page.Section>
    </Page>
  );
}
