import { ReactNode, useState } from 'react';

export const Image = ({
  fallback,
  ...props
}: { fallback?: ReactNode } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const [imageWithError, setImageWithError] = useState(false);

  if (!fallback) {
    return <img alt="" {...props} />;
  }

  return imageWithError ? fallback : <img alt="" {...props} onError={() => setImageWithError(true)} />;
};
