import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Page } from 'components/Page/Page';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver/useYupValidationResolver';
import { AllowedLanguages } from 'i18n';
import { useForm, useWatch } from 'react-hook-form';
import { useCurrentUser, useUpdateUser } from 'services/repositories/user/user';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { Form } from 'components/Form/Form';
import { SettingsSidebar } from '../SettingsSidebar/SettingsSidebar';
import { Section } from 'components/Display/Section/Section';
import { Input } from 'components/Form/Input/Input/Input';
import { ToggleSwitch } from 'components/Form/ToggleSwitch/ToggleSwitch';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { mapFormErrorsToString } from 'support/helpers/errors/errors';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';

type FormState = {
  email: string;
  name: string;
  language: AllowedLanguages;
  notifications: {
    errors: boolean;
    successfulReceivedMessages: boolean;
    successfulSendMessages: boolean;
  };
};

export function ProfileAndPreferences() {
  const { t } = useTranslation();
  const { data: currentUser, isLoading } = useCurrentUser();
  const { mutate, isLoading: isMutating } = useUpdateUser();

  const formSchema = Yup.object({
    email: Yup.string().required(t('settings:profileAndPreferences.form.validation.email') ?? ''),
    name: Yup.string().required(t('settings:profileAndPreferences.form.validation.name') ?? ''),
    language: Yup.string()
      .oneOf(['en', 'de'])
      .required(t('settings:profileAndPreferences.form.validation.language') ?? ''),
  });

  const resolver = useYupValidationResolver(formSchema);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm<FormState>({
    resolver,
    values: {
      email: currentUser?.email ?? '',
      name: currentUser?.name ?? '',
      language: (currentUser?.language ?? 'en') as AllowedLanguages,
      notifications: {
        errors: currentUser?.notifications.errors ?? true,
        successfulReceivedMessages: currentUser?.notifications.successfulReceivedMessages ?? true,
        successfulSendMessages: currentUser?.notifications.successfulSendMessages ?? true,
      },
    },
  });
  const language = useWatch({ name: 'language', control });

  const onSubmit = (data: FormState) => {
    mutate(data, {
      onSuccess: () => {
        addNotification(t('auth:notifications.updateUser.success'));
      },
      onError: () => {
        addNotification(t('auth:notifications.updateUser.error'), NotificationType.error);
      },
    });
  };

  return (
    <Page isLoading={isLoading}>
      <Page.Head title={t('common:mainMenu.settings')} />

      <Page.Section leftAside={<SettingsSidebar />}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          id="profileAndPreferences"
          className="w-full divide-y divide-gray-200 *:py-6 first:*:pt-0 last:*:pb-0"
        >
          <ValidationErrors errors={mapFormErrorsToString(errors)} />

          <Section.Horizontal
            title={t('settings:sidebar.profileAndPreferences')}
            description={t('settings:profileAndPreferences.intro')}
          >
            <div className="flex flex-col justify-center">
              <div className="text-xs text-gray-500">{t('settings:profileAndPreferences.form.fields.email.label')}</div>
              <span className="text-sm font-medium text-gray-700">{currentUser?.email}</span>
            </div>

            <Input
              label={t('settings:profileAndPreferences.form.fields.name.label')}
              {...register('name')}
              placeholder={t('settings:profileAndPreferences.form.fields.name.placeholder')}
              required
            />
            <Dropdown<AllowedLanguages>
              label={t('settings:profileAndPreferences.form.fields.language.label')}
              value={language || (currentUser?.language as AllowedLanguages) || 'en'}
              options={[
                { value: 'en', label: 'English' },
                { value: 'de', label: 'Deutsch' },
              ]}
              onChange={(value) => setValue('language', value)}
            />
          </Section.Horizontal>

          <Section.Horizontal
            title={t('settings:profileAndPreferences.notifications.title')}
            description={t('settings:profileAndPreferences.notifications.description')}
          >
            <div className="flex items-center justify-between gap-4 pt-4">
              <div>
                <p className="text-sm font-semibold">
                  {t('settings:profileAndPreferences.notifications.fields.errors.title')}
                </p>

                <p className="mt-2 text-sm font-light text-gray-600">
                  {t('settings:profileAndPreferences.notifications.fields.errors.description')}
                </p>
              </div>

              <ToggleSwitch disabled {...register('notifications.errors')} control={control} defaultValue={true} />
            </div>

            <div className="flex items-center justify-between gap-4 border-t border-gray-200 pt-4">
              <div>
                <p className="text-sm font-semibold">
                  {t('settings:profileAndPreferences.notifications.fields.successfulReceivedMessages.title')}
                </p>

                <p className="mt-2 text-sm font-light text-gray-600">
                  {t('settings:profileAndPreferences.notifications.fields.successfulReceivedMessages.description')}
                </p>
              </div>

              <ToggleSwitch
                {...register('notifications.successfulReceivedMessages')}
                control={control}
                defaultValue={false}
              />
            </div>

            <div className="flex items-center justify-between gap-4 border-t border-gray-200 pt-4">
              <div>
                <p className="text-sm font-semibold">
                  {t('settings:profileAndPreferences.notifications.fields.successfulSendMessages.title')}
                </p>

                <p className="mt-2 text-sm font-light text-gray-600">
                  {t('settings:profileAndPreferences.notifications.fields.successfulSendMessages.description')}
                </p>
              </div>

              <ToggleSwitch
                {...register('notifications.successfulSendMessages')}
                control={control}
                defaultValue={true}
              />
            </div>
          </Section.Horizontal>

          <div>
            <Button
              onClick={() => reset()}
              disabled={isMutating}
              variant="secondary"
              className="mr-3"
              analyticsId="profile:update_cancel"
            >
              {t('common:cancel')}
            </Button>

            <Button type="submit" loading={isMutating} form="profileAndPreferences" analyticsId="profile:update_save">
              {t('common:save')}
            </Button>
          </div>
        </Form>
      </Page.Section>
    </Page>
  );
}
