import { useCallback, useState } from 'react';

/**
 * Utility hook. Responsible for react-table global filter state
 */
export const useGlobalFilter = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const handleResetFilter = useCallback(() => {
    setGlobalFilter('');
  }, []);

  return { globalFilter, setGlobalFilter, handleResetFilter };
};
