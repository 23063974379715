import { classNames } from 'support/helpers/generic/generic';

type SizeClassName = `size-${number}`;

type LoadingSpinnerProps = {
  sizeClassName?: SizeClassName;
  variant?: 'neutral' | 'warning' | 'info' | 'success' | 'error';
};

const colorClasses = {
  neutral: 'text-neutral-500',
  warning: 'text-warning-700',
  info: 'text-info-700',
  success: 'text-success-700',
  error: 'text-error-700',
};

export const LoadingSpinner = ({ sizeClassName, variant = 'neutral' }: LoadingSpinnerProps) => {
  return (
    <svg
      className={classNames(sizeClassName, 'animate-spin', colorClasses[variant])}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  );
};
