import { useTranslation } from 'react-i18next';
import { DashboardCard } from '../components/DashboardCard/DashboardCard';
import { DashboardSection } from '../components/DashboardSection';
import { routeToTradePartnersPage } from 'support/helpers/navigation/navigation';
import { RelationshipPartnerStatus } from 'support/types/dtos';

type TradePartnerStatsDisplayProps = {
  numberOfReadyPartners: number;
  numberOfTestingPartners: number;
};

export function TradePartnerStatsDisplay({
  numberOfReadyPartners,
  numberOfTestingPartners,
}: TradePartnerStatsDisplayProps) {
  const { t } = useTranslation();

  return (
    <DashboardSection title={t('dashboard:tradePartners.title')}>
      <div className="grid grid-cols-2">
        <DashboardCard
          to={routeToTradePartnersPage({ status: RelationshipPartnerStatus.connected })}
          title={t('dashboard:tradePartners.active')}
          value={numberOfReadyPartners}
          testId="active-trade-partners"
        />
        <DashboardCard
          title={t('dashboard:tradePartners.inTesting')}
          value={numberOfTestingPartners}
          to={routeToTradePartnersPage({ status: RelationshipPartnerStatus.pending })}
          testId="in-testing-trade-partners"
        />
      </div>
    </DashboardSection>
  );
}
