import { Dialog } from '@headlessui/react';
import { Button } from 'components/Form/Button/Button';
import { classNames } from 'support/helpers/generic/generic';
import { BasePopup } from '../BasePopup/BasePopup';
import { useTranslation } from 'react-i18next';

type InfoPopupProps = {
  setOpen: (open: boolean) => void;
  open: boolean;
  icon?: React.ReactNode;
  iconWrapperClassName?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
};
export default function InfoPopup({ setOpen, open, icon, iconWrapperClassName, title, description }: InfoPopupProps) {
  const { t } = useTranslation();
  return (
    <BasePopup setOpen={setOpen} open={open} className="sm:w-full sm:max-w-sm">
      <div className="p-6">
        {icon ? (
          <div
            className={classNames(
              'mx-auto flex h-12 w-12 items-center justify-center rounded-full',
              iconWrapperClassName,
            )}
          >
            {icon}
          </div>
        ) : null}
        <div className={classNames('text-center', icon ? 'mt-3 sm:mt-5' : undefined)}>
          {title ? (
            <Dialog.Title as="h3" className="text-base font-semibold text-gray-900">
              {title}
            </Dialog.Title>
          ) : null}
          <div className={title ? 'mt-2' : undefined}>
            <p className="text-sm text-gray-500">{description}</p>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <Button fullWidth type="button" onClick={() => setOpen(false)} analyticsId="info_popup_close">
            {t('common:close')}
          </Button>
        </div>
      </div>
    </BasePopup>
  );
}
