import React from 'react';
import { Control, useController } from 'react-hook-form';
import { classNames } from 'support/helpers/generic/generic';

type ToggleSwitchProps = {
  label?: string;
  name: string;
  defaultValue?: boolean;
  control: Control<any, any>;
  disabled?: boolean;
};

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, name, control, defaultValue, disabled = false }) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const styles = classNames(
    "w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-procuros-green-400",
    disabled ? 'opacity-50 cursor-not-allowed' : '',
  );

  return (
    <div className="flex items-center justify-between">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          id={name}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
          disabled={disabled}
          className="sr-only peer"
        />

        <div className={styles}>{label}</div>
      </label>
    </div>
  );
};
