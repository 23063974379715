const clear = () => {
  try {
    sessionStorage.clear();
  } catch (e) {
    //do nothing. We are in a browser environment and sessionStorage is not always available
  }
};

export const SessionStorageService = {
  clear,
};
