import { CollapsibleSidebar } from 'components/CollapsibleSidebar/CollapsibleSidebar';
import { MessageV2DTO, PartnerDTO, SenderEnvelopeDTO } from 'support/types';
import { RelatedMessagesV2 } from '../RelatedMessagesV2/RelatedMessagesV2';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/Display/Tooltip/Tooltip';
import { t } from 'i18next';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { SidebarKeys } from 'support/types/localStorage';
import { TradePartnerNameAndLogo } from 'components/TradePartnerNameAndLogo';

export const WebEDISidebar = ({
  document,
  currentPartner,
  relatedTransactions,
  children,
  isSenderEnvelope,
}: {
  document: MessageV2DTO | SenderEnvelopeDTO | undefined;
  relatedTransactions: Array<MessageV2DTO | SenderEnvelopeDTO> | undefined;
  currentPartner: PartnerDTO | undefined;
  children?: React.ReactNode;
  isSenderEnvelope?: boolean;
} & (
  | { isSenderEnvelope: true; document: SenderEnvelopeDTO | undefined }
  | { isSenderEnvelope?: false; document: MessageV2DTO | undefined }
)) => {
  const senderPartner = isSenderEnvelope ? currentPartner : document?.sender_partner;
  const receiverPartner = isSenderEnvelope ? null : document?.receiver_partner;
  const otherPartner = currentPartner && senderPartner?.id === currentPartner?.id ? receiverPartner : senderPartner;

  return (
    <CollapsibleSidebar bottomSlot={children} sidebarKey={SidebarKeys.webedi}>
      {(collapsed) => {
        return (
          <div className="space-y-6">
            <TradePartnerSidebar collapsed={collapsed} partner={otherPartner} />
            <RelatedMessagesV2
              collapsed={collapsed}
              messageDetails={{
                id: document?.id,
                sender: senderPartner,
                receiver: receiverPartner,
              }}
              currentPartner={currentPartner}
              relatedMessages={relatedTransactions}
            />
          </div>
        );
      }}
    </CollapsibleSidebar>
  );
};

const TradePartnerSidebar = ({
  partner,
  collapsed,
}: {
  partner: PartnerDTO | undefined | null;
  collapsed: boolean;
}) => {
  return (
    <WebEDISection collapsed={collapsed} header={{ spacing: 'small', title: t('webedi:sidebar.tradePartner.title') }}>
      <Tooltip placement="left" enabled={collapsed}>
        <TooltipTrigger>
          <TradePartnerNameAndLogo partner={partner} showPartnerName={!collapsed} />
        </TooltipTrigger>
        <TooltipContent>{partner?.name || t('common:tradePartner.unknownName')}</TooltipContent>
      </Tooltip>
    </WebEDISection>
  );
};
