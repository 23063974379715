import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { IdentifierDomainOptions } from 'support/types';
import { PartnerIdentifierSchema } from '../PartnerIdentifiers/PartnerIdentifiers';
import { AllowedLanguages } from 'i18n';

type ContactPerson = {
  language: AllowedLanguages;
  name: string;
  email: string;
  phone?: string | undefined;
};

type BaseNewTradePartnerForm = {
  name: string;
  message?: string | undefined;
  partyIdentifierDomain: IdentifierDomainOptions;
  partyIdentifier: string;
  includeAsCCRecipient: boolean;
};
type NewTradePartnerFormWithPartnerId = {
  partnerId: string;
  contactPersons?: Array<Partial<ContactPerson>> | undefined;
} & BaseNewTradePartnerForm;
type NewTradePartnerFormWithoutPartnerId = {
  partnerId?: undefined | null;
  contactPersons: Array<ContactPerson>;
} & BaseNewTradePartnerForm;

export type NewTradePartnerForm = NewTradePartnerFormWithPartnerId | NewTradePartnerFormWithoutPartnerId;

export const defaultData: NewTradePartnerForm = {
  name: '',
  message: '',
  partnerId: undefined,
  partyIdentifierDomain: IdentifierDomainOptions.GS1,
  partyIdentifier: '',
  contactPersons: [
    {
      language: 'de',
      name: '',
      email: '',
      phone: '',
    },
  ],
  includeAsCCRecipient: true,
};

const contactPersonSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(t('common:form.required', { field: t('partners:request.contactPerson') })),
    email: Yup.string().required(t('common:form.required', { field: t('partners:request.email') })),
    phone: Yup.string(),
    language: Yup.string().oneOf(['de', 'en']).required(),
  });

const contactPersonOptionalSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string(),
  phone: Yup.string(),
  language: Yup.string().oneOf(['de', 'en']).optional(),
});

export const NewTradePartnerFormSchema = (t: TFunction) =>
  Yup.lazy((value) => {
    if (value.partnerId) {
      return Yup.object()
        .shape({
          partnerId: Yup.string().required(),
          name: Yup.string().required(t('common:form.required', { field: t('partners:request.tradePartnerName') })),
          message: Yup.string(),
          contactPersons: Yup.array().of(contactPersonOptionalSchema),
          includeAsCCRecipient: Yup.boolean().required(),
        })
        .concat(PartnerIdentifierSchema(t, false));
    } else {
      return Yup.object()
        .shape({
          partnerId: Yup.string().optional().nullable(),
          name: Yup.string().required(t('common:form.required', { field: t('partners:request.tradePartnerName') })),
          message: Yup.string(),
          contactPersons: Yup.array().of(contactPersonSchema(t)).required(),
          includeAsCCRecipient: Yup.boolean().required(),
        })
        .concat(PartnerIdentifierSchema(t, false));
    }
  });

export const isFormWithPartnerId = (form: NewTradePartnerForm): form is NewTradePartnerFormWithPartnerId => {
  return Boolean(form.partnerId);
};
