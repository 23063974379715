import { Stepper } from 'components/Stepper/Stepper';
import { ComponentType, createElement, useCallback, useState } from 'react';
import { Background } from './components/Background/Background';
import { DashboardRoutes } from 'support/types';
import { useNavigate } from 'react-router-dom';

type StepComponent =
  | ComponentType<{
      setIsLoading: (isLoading: boolean) => void;
      onComplete?: () => void;
    }>
  | ComponentType<{ setIsLoading: (isLoading: boolean) => void; onComplete: () => void }>;

type StepCta = ComponentType<{ onClick?: () => void }> | ComponentType<{ onClick: () => void }>;
export interface Step {
  label: () => JSX.Element;
  component: StepComponent;
  nextCta?: StepCta;
  backCta?: StepCta;
}

export const OnboardingStepper = ({ steps }: { steps: Array<Step> }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const increaseStep = useCallback(() => {
    setIsLoading(true);
    if (activeStep === steps.length - 1) {
      navigate(DashboardRoutes.root);
      return;
    }
    setActiveStep((activeStep) => activeStep + 1);
  }, [activeStep, navigate, steps.length]);

  const decreaseStep = useCallback(() => {
    setIsLoading(true);
    setActiveStep((activeStep) => activeStep - 1);
  }, [setActiveStep]);

  const currentStep = steps[activeStep];
  const ChildrenComponent = currentStep.component;
  const NextCta = currentStep.nextCta;
  const BackCta = currentStep.backCta;

  return (
    <div className="flex h-screen">
      <div className="fixed left-0 right-0 top-0 bottom-0 flex items-end justify-center text-gray-100 bg-gray-50">
        <Background />
      </div>
      <div className="relative my-auto w-full flex justify-center py-12">
        <Stepper
          isLoading={isLoading}
          numberOfSteps={3}
          activeStep={activeStep}
          stepTitle={createElement(steps[activeStep].label)}
          nextCta={NextCta && <NextCta onClick={increaseStep} />}
          backCta={BackCta && <BackCta onClick={decreaseStep} />}
        >
          <ChildrenComponent setIsLoading={setIsLoading} onComplete={increaseStep} />
        </Stepper>
      </div>
    </div>
  );
};
