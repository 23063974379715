import { Page } from 'components/Page/Page';
import { useTranslation } from 'react-i18next';
import { IssueResolverType, SenderEnvelopeDTO, TransactionInternalType, TransactionState } from 'support/types';
import { Button } from 'components/Form/Button/Button';
import { useRecoverTransaction } from 'services/repositories/transactions/transactions';
import { NotificationType, addNotification } from 'stores/notifications/notifications';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { TransactionStatusDisplay } from 'components/TransactionStatusDisplay/TransactionStatusDisplay';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { DropTransaction } from '../../DropTransaction/DropTransaction';

export const SenderEnvelopeHeader = ({ senderEnvelope }: { senderEnvelope: SenderEnvelopeDTO }) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { mutate, isLoading: isRecoveringTransaction } = useRecoverTransaction();
  const [_, issueResolverLabel]: [IssueResolverType, string] = useTransactionActionRequired()(
    senderEnvelope?.issueResolverId,
  );

  const send = async () => {
    const payload = {
      id: senderEnvelope.id,
      type: TransactionInternalType.senderEnvelope,
    };

    mutate(payload, {
      onSuccess: () => {
        track('transaction_recovered', {
          transaction_id: senderEnvelope.id,
          type: TransactionInternalType.senderEnvelope,
        });

        addNotification({
          title: t('webedi:notifications.recover.successful'),
          subtitle: t('webedi:notifications.recover.successful_description'),
        });
      },
      onError: () => {
        addNotification(t('webedi:notifications.recover.failed'), NotificationType.error);
      },
    });
  };

  const title = senderEnvelope.documentType
    ? t('webedi:senderEnvelope.titles.knownDocumentType', {
        documentType: t(`common:messageTypes.singular.${senderEnvelope.documentType}`),
        externalReference: senderEnvelope.externalMessageRef || EMPTY_FIELD,
      })
    : senderEnvelope.externalMessageRef
      ? t('webedi:senderEnvelope.titles.unknownDocumentType.withExternalReference', {
          externalReference: senderEnvelope.externalMessageRef,
        })
      : t('webedi:senderEnvelope.titles.unknownDocumentType.withoutExternalReference');

  return (
    <Page.Head
      title={
        <>
          {title}
          <TransactionStatusDisplay state={senderEnvelope.state} />
          {senderEnvelope.state === TransactionState.failed && (
            <Badge hasDot color={BadgeColor.red}>
              {t('webedi:viewDocument.titles.issueResolver', { resolver: issueResolverLabel })}
            </Badge>
          )}
        </>
      }
      rightContent={
        <>
          <DropTransaction
            transactionId={senderEnvelope.id}
            canDropTransaction={senderEnvelope.canDropTransaction}
            internalType={TransactionInternalType.senderEnvelope}
          />
          {senderEnvelope.customerCanRetry ? (
            <Button
              variant="secondary-danger"
              onClick={send}
              LeftIcon={ArrowPathIcon}
              loading={isRecoveringTransaction}
              analyticsId="webedi:sender_envelope_retry"
            >
              {t('transactions:list.bulk_retrigger')}
            </Button>
          ) : null}
        </>
      }
    />
  );
};
