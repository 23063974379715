import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useFloating, offset, autoPlacement, autoUpdate } from '@floating-ui/react';
import { MessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { WebEDIError } from 'services/webediErrors/webediErrors';
import { Dropdown } from '../Dropdown';
import { useEnumerableOptions } from 'components/WebEDI/helpers';
import { ui } from '@procuros/datachecks';
import { useDatachecksStore } from 'stores/datachecks/datachecks';

type MessageDropdownProps = Omit<Parameters<typeof Dropdown>[0], 'errors' | 'hasError' | 'options' | 'label'> & {
  error: WebEDIError | undefined;
  totalNumberOfErrors: number;
  field: ui.EnumerableField;
  onShowFieldErrorModal?: (showFieldErrorModal: boolean) => void;
};
export const MessageDropdown = forwardRef<HTMLInputElement, MessageDropdownProps>(
  ({ error, totalNumberOfErrors, onBlur, field, onShowFieldErrorModal, ...otherProps }, ref) => {
    const options = useEnumerableOptions(field.type_config);
    const isValidating = useDatachecksStore((state) => state.isValidating);
    const [focused, setFocused] = useState(false);
    const { refs, floatingStyles } = useFloating({
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
      middleware: [offset(8), autoPlacement()],
    });

    const onDropdownFocus = useCallback(() => {
      setFocused(true);
    }, []);

    const onDropdownBlur = useCallback(() => {
      onBlur?.();
      setFocused(false);
    }, [onBlur]);

    useEffect(() => {
      if (error && focused) {
        onShowFieldErrorModal?.(true);
      }
    }, [error, focused, onShowFieldErrorModal]);

    return (
      <div ref={refs.setReference}>
        <Dropdown
          {...otherProps}
          options={options}
          label={field.label}
          ref={ref}
          description={undefined}
          errors={undefined}
          hasErrors={Boolean(error)}
          onFocus={onDropdownFocus}
          onBlur={onDropdownBlur}
          dropdownDisplayTestId={'message-dropdown'}
          disabled={otherProps.disabled}
        />

        {focused && (
          <MessageFieldErrorModal
            viewMode={false}
            error={error}
            ref={refs.setFloating}
            style={floatingStyles}
            numberOfErrors={totalNumberOfErrors}
            isValidating={isValidating}
          />
        )}
      </div>
    );
  },
);

MessageDropdown.displayName = 'MessageDropdown';
