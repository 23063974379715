import { PlusIcon } from '@heroicons/react/20/solid';
import { Card } from 'components/Display/Card/Card';
import { useTranslation } from 'react-i18next';
import { PaymentTermDTO } from 'support/types';
import { PaymentTermRow } from './PaymentTermRow';
import { classNames, deepCopy } from 'support/helpers/generic/generic';
import { Input } from 'components/Form/Input/Input/Input';
import { Button } from 'components/Form/Button/Button';

type PaymentTermsProps = {
  paymentTerms: Array<PaymentTermDTO>;
  onChange: (paymentTerms: Array<PaymentTermDTO>) => void;
  disabled?: boolean;
};

export function PaymentTerms({ paymentTerms, onChange, disabled }: PaymentTermsProps) {
  const { t } = useTranslation();

  const [firstPaymentTerm, ...extraPaymentTerms] = paymentTerms.length
    ? paymentTerms
    : [
        {
          percentage: 0,
          payInNumberOfDays: 0,
        },
      ];

  const numberOfExtraTerms = extraPaymentTerms.length;
  const maxExtraTerms = 2;
  const canAddExtraTerms = numberOfExtraTerms < maxExtraTerms;

  const addPaymentTerm = () => {
    if (!canAddExtraTerms) {
      return;
    }

    onChange([
      firstPaymentTerm,
      ...extraPaymentTerms,
      {
        percentage: 0,
        payInNumberOfDays: 0,
      },
    ]);
  };

  const deletePaymentTerm = (index: number) => {
    const newPaymentTerms: Array<PaymentTermDTO> = deepCopy(paymentTerms);

    newPaymentTerms.splice(index, 1);

    if (newPaymentTerms.length === 1 && !newPaymentTerms[0].payInNumberOfDays) {
      newPaymentTerms.splice(0, newPaymentTerms.length);
    }

    onChange(newPaymentTerms);
  };

  const updateExtraPaymentTerm = (index: number, updatedPaymentTerm: PaymentTermDTO) => {
    const newPaymentTerms = deepCopy<typeof paymentTerms>(paymentTerms).map((paymentTerm, i) => {
      if (i === index) {
        return updatedPaymentTerm;
      }
      return paymentTerm;
    });

    onChange(newPaymentTerms);
  };

  return (
    <Card className="mt-5">
      <Card.Body className="border-t-0">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">{t('webedi:paymentTerms.title')}</h3>
          <p className="mt-1 text-sm text-gray-500">{t('webedi:paymentTerms.subTitle')}</p>
        </div>

        <div className="mb-3 flex flex-row items-center pt-3">
          <div>{t('webedi:paymentTerms.netPaymentTarget')}</div>
          <div className="mx-1 w-10">
            <Input
              name="Default Term"
              className="p-1 text-center"
              type="number"
              min={0}
              value={firstPaymentTerm.payInNumberOfDays ?? ''}
              onChange={(e) => {
                const newValue = parseInt(e.target.value);
                if (isNaN(newValue)) {
                  return;
                }

                onChange([
                  {
                    ...firstPaymentTerm,
                    payInNumberOfDays: newValue,
                  },
                  ...extraPaymentTerms,
                ]);
              }}
              disabled={disabled}
            />
          </div>
          <div>{t('webedi:paymentTerms.days')}</div>
        </div>

        {extraPaymentTerms.length ? <div className="my-3 border-b-2 border-gray-200"></div> : null}

        {extraPaymentTerms.map((paymentTerm, index) => (
          <PaymentTermRow
            key={`PaymentTerm-${index + 1}`}
            paymentTerm={paymentTerm}
            index={index + 1}
            onChange={updateExtraPaymentTerm}
            deletePaymentTerm={deletePaymentTerm}
            disabled={disabled}
          />
        ))}

        <Button
          variant="secondary"
          className={classNames('px-2 py-2 mt-1', !canAddExtraTerms ? 'cursor-default opacity-25' : '')}
          onClick={addPaymentTerm}
          disabled={disabled}
          LeftIcon={PlusIcon}
          analyticsId="pricing-configs:payment-terms-add"
        >
          {t('webedi:paymentTerms.add')}
        </Button>
      </Card.Body>
    </Card>
  );
}
