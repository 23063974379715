import { Table } from 'components/Display/Table/Table';
import { TableHeader, TableItem } from './types';
import { ReactNode, useState, useCallback, ComponentType, useMemo } from 'react';
import { Button } from 'components/Form/Button';
import { classNames } from 'support/helpers/generic/generic';
import { SelectionCell } from 'components/DataTable/cells/SelectionCell';
import { useTranslation } from 'react-i18next';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';

type DropdownActionProps<T> = {
  type: 'dropdown';
  label: string;
  options: Array<{ title: string; description?: string; onClick: (items: Array<T>) => void }>;
  analyticsId: string;
};

type ButtonActionProps<T> = {
  type: 'button';
  label: string;
  onClick: (items: Array<T>) => void;
  analyticsId: string;
  icon: ComponentType<any>;
};

type ListTableProps<T> = {
  headers: Array<TableHeader>;
  data?: Array<T>;
  isLoading: boolean;
  rowBuilder: (data: T, index: number) => Array<TableItem>;
  onRowClick?: (data: T) => void;
  rowClickPath?: (data: T) => string | undefined;
  emptyState?: ReactNode;
  pagination?: ReactNode;
  bulkActions?: Array<ButtonActionProps<T> | DropdownActionProps<T>>;
};

function ListTableWrapper<T>({
  headers,
  data,
  isLoading,
  rowBuilder,
  onRowClick,
  rowClickPath,
  emptyState,
  pagination,
  bulkActions,
}: ListTableProps<T>) {
  const { t } = useTranslation();
  const showEmptyState = !data?.length && !isLoading;
  const [selectedItems, setSelectedItems] = useState<Array<T>>([]);
  const toggleAllItems = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelectedItems(data || []);
      } else {
        setSelectedItems([]);
      }
    },
    [data],
  );

  const toggleItem = useCallback((item: T) => {
    setSelectedItems((prev) => (prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]));
  }, []);

  const clearAllSection = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const filteredSelectedItems = useMemo(() => {
    return selectedItems.filter((item) => data?.includes(item));
  }, [selectedItems, data]);

  const renderDynamicActions = useCallback(() => {
    return bulkActions?.map((action) => {
      switch (action.type) {
        case 'button':
          return <ButtonAction key={action.analyticsId} action={action} selectedItems={filteredSelectedItems} />;
        case 'dropdown':
          return <DropdownAction key={action.analyticsId} action={action} selectedItems={filteredSelectedItems} />;
      }
    });
  }, [selectedItems, bulkActions]);

  return (
    <div className="space-y-3">
      {bulkActions && filteredSelectedItems.length > 0 && (
        <div className="sticky top-0 z-10 inline-flex items-center rounded-md border border-gray-200 bg-white px-4 py-2 shadow">
          <p className="mr-6 text-sm text-gray-500">
            {t('transactions:list.bulk_selected', { count: filteredSelectedItems.length })}
          </p>
          {renderDynamicActions()}
          <Button onClick={clearAllSection} variant="secondary" analyticsId={'list_table:bulk_clear'}>
            {t('transactions:list.bulk_clear')}
          </Button>
        </div>
      )}
      <Table>
        {!showEmptyState ? (
          <Table.Head>
            <Table.Row>
              {bulkActions ? (
                <Table.HeadCell className="w-10">
                  <SelectionCell
                    checked={data && filteredSelectedItems.length === data.length}
                    onChange={toggleAllItems}
                    indeterminate={
                      filteredSelectedItems.length > 0 && filteredSelectedItems.length < (data?.length || 0)
                    }
                  />
                </Table.HeadCell>
              ) : null}
              {headers.map((tableHeader: TableHeader) => (
                <Table.HeadCell
                  invisible={tableHeader.invisible}
                  key={tableHeader.label || tableHeader.key}
                  className={tableHeader.className}
                  tooltip={tableHeader.tooltip}
                >
                  {tableHeader.label}
                </Table.HeadCell>
              ))}
            </Table.Row>
          </Table.Head>
        ) : null}
        <Table.Body isLoading={isLoading}>
          {data?.map((item: T, index: number) => {
            const rowData = rowBuilder(item, index);
            return (
              <Table.Row
                key={rowData[0].key}
                onClick={
                  onRowClick
                    ? () => {
                        onRowClick?.(item);
                      }
                    : undefined
                }
                hover={Boolean(rowClickPath?.(item)) || Boolean(onRowClick)}
              >
                {bulkActions ? (
                  <Table.Cell>
                    <SelectionCell checked={filteredSelectedItems.includes(item)} onChange={() => toggleItem(item)} />
                  </Table.Cell>
                ) : null}
                {rowData.map((cell: TableItem) => {
                  return (
                    <Table.Cell key={cell.key} to={rowClickPath?.(item)} className={cell.className} {...cell.options}>
                      {cell.element}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}

          {showEmptyState ? emptyState : null}
        </Table.Body>
      </Table>
      {pagination}
    </div>
  );
}

function ButtonAction(props: { action: ButtonActionProps<any>; selectedItems: Array<any> }) {
  return (
    <Button
      analyticsId={props.action.analyticsId}
      className="mr-2"
      variant="secondary"
      RightIcon={props.action.icon}
      onClick={() => props.action.onClick(props.selectedItems)}
    >
      {props.action.label}
    </Button>
  );
}

export function DropdownAction(props: { action: Omit<DropdownActionProps<any>, 'type'>; selectedItems: Array<any> }) {
  const action = useCallback(
    (action: DropdownActionProps<any>['options'][number]) => {
      action.onClick(props.selectedItems);
    },
    [props.selectedItems],
  );

  return (
    <MenuButton
      text={props.action.label}
      variant="secondary"
      analyticsId={props.action.analyticsId}
      size="base"
      className="mr-2"
    >
      {props.action.options.map((option) => {
        return (
          <MenuButtonOption
            key={props.action.analyticsId}
            onClick={() => {
              action(option);
            }}
            className="flex-col border-b border-gray-100 last:border-b-0 "
            analyticsId={props.action.analyticsId}
          >
            <div className="max-w-80 py-1 text-left">
              <p className="mb-2 text-sm font-medium text-gray-900">{option.title}</p>
              <p className="text-sm text-gray-500">{option.description}</p>
            </div>
          </MenuButtonOption>
        );
      })}
    </MenuButton>
  );
}

type EmptyStateProps = {
  children: ReactNode;
  cols: number;
  background?: 'white' | 'gray';
  cta?: Omit<Parameters<typeof Button>[0], 'variant' | 'className'>;
};

const EmptyState = ({ children, cols, cta, background = 'white' }: EmptyStateProps) => {
  return (
    <Table.Row withBottomBorder={background === 'white'}>
      <Table.Cell colSpan={cols} className="!p-0">
        <div
          className={classNames(
            'text-sm !text-gray-500 text-center py-6 font-normal',
            background === 'gray' ? 'bg-gray-50' : undefined,
          )}
        >
          <div>{children}</div>
          {cta ? <Button {...cta} variant="secondary" className="mt-6" /> : null}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const ListTable = Object.assign(ListTableWrapper, { EmptyState });
