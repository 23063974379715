import { useTranslation } from 'react-i18next';
import { MessageDTO, MessageV2DTO, PartnerDTO, SenderEnvelopeDTO } from 'support/types';
import { GetCurrentPartnerResponse } from 'services/repositories/partners/partners';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { RelatedMessage } from './RelatedMessage/RelatedMessage';
import { isMessageV2DTO } from 'support/helpers/transactions/transactions';

type RelatedMessagesProps = {
  messageDetails?: {
    id?: string;
    sender?: PartnerDTO | null;
    receiver?: PartnerDTO | null;
  };
  currentPartner?: GetCurrentPartnerResponse;
  relatedMessages?: Array<MessageDTO | MessageV2DTO | SenderEnvelopeDTO>;
  collapsed?: boolean;
};

export function RelatedMessagesV2({
  messageDetails,
  relatedMessages,
  currentPartner,
  collapsed,
}: RelatedMessagesProps) {
  const { t } = useTranslation();

  if (!messageDetails || !relatedMessages || !currentPartner) {
    return null;
  }

  return (
    <WebEDISection
      collapsed={collapsed}
      header={{ spacing: 'small', title: t('transactions:show.sidebar.relatedMessages.label') }}
    >
      <div className="flow-root">
        <ul>
          {relatedMessages.map((relatedMessage, index) => (
            <RelatedMessage
              key={relatedMessage.id}
              collapsed={collapsed}
              relatedMessage={{
                id: relatedMessage.id,
                type: 'type' in relatedMessage ? relatedMessage.type : relatedMessage.documentType,
                externalMessageRef: isMessageV2DTO(relatedMessage)
                  ? relatedMessage.external_message_ref
                  : relatedMessage.externalMessageRef,
                createdAt: isMessageV2DTO(relatedMessage) ? relatedMessage.created_at : relatedMessage.createdAt,
                state: isMessageV2DTO(relatedMessage) ? relatedMessage.state : relatedMessage.state,
              }}
              messageId={messageDetails.id ?? ''}
              displayBorder={index !== relatedMessages.length - 1}
            />
          ))}
        </ul>
      </div>
    </WebEDISection>
  );
}
