import { PropertyFilter, SearchPropertyFilter, PropertyFilterType } from 'components/ListTable/types';
import { t } from 'i18next';

export enum PricingConfigsFilterKey {
  name = 'name',
  partnerId = 'partner_id',
}

export const getDefaultFilterConfig = (): Array<PropertyFilter | SearchPropertyFilter> => [
  {
    key: PricingConfigsFilterKey.partnerId,
    type: PropertyFilterType.dropdown,
    label: t('priceList:filters.partnerId.label'),
    value: '',
    dropDownOptions: {
      dropdownValues: [],
    },
  },
  {
    key: PricingConfigsFilterKey.name,
    type: PropertyFilterType.search,
    value: '',
    placeholder: t('priceList:filters.searchByName.placeholder'),
  } as SearchPropertyFilter,
];
