import { useTranslation } from 'react-i18next';
import { AuthBodySection } from '../components/AuthBodySection/AuthBodySection';
import { AuthPage } from '../components/AuthPage/AuthPage';
import { useState } from 'react';
import { usePasswordlessSignIn } from 'services/repositories/user/user';
import { useForm } from 'react-hook-form';
import { Button } from 'components/Form/Button';
import { Input } from 'components/Form/Input';
import { PasswordlessSuccess } from '../components/PasswordlessSuccess/PasswordlessSuccess';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { Translate } from 'components/Translate/Translate';

export const ExpiredLink = () => {
  return (
    <AuthPage>
      <ExpiredMagicLinkForm />
    </AuthPage>
  );
};

type ExpiredLinkFormState = {
  email: string;
  previous_url: string;
};

const defaultData: ExpiredLinkFormState = {
  email: '',
  previous_url: '',
};

const ExpiredMagicLinkForm = () => {
  const { t } = useTranslation();

  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const { mutate: passwordlessSignIn, isLoading, isSuccess, reset } = usePasswordlessSignIn();
  const {
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ExpiredLinkFormState>({ defaultValues: { ...defaultData, previous_url: window.location.href } });

  const onPasswordlessSignIn = async (data: ExpiredLinkFormState) => {
    setNonInputErrors([]);
    passwordlessSignIn(data, {
      onError: (err) => {
        processSubmissionErrors({ error: err, setInputError: setError, setNonInputErrors, defaultData, t });
        throw err;
      },
    });
  };

  if (isSuccess) {
    return <PasswordlessSuccess usedEmail={watch('email')} onBack={() => reset()} toggleLoginMethod={undefined} />;
  }

  return (
    <AuthBodySection
      nonInputErrors={nonInputErrors}
      heading={t('auth:heading.passwordlessExpired')}
      onSubmit={handleSubmit(onPasswordlessSignIn)}
    >
      <p className="text-sm text-gray-900">
        <Translate i18nKey="auth:body.passwordlessExpired" />
      </p>

      <Input
        {...register('email', { required: true })}
        placeholder={t('auth:email')}
        autoComplete="username"
        label={t('auth:emailAddress')}
        type="email"
        errors={errors.email}
        required
      />

      <Button type="submit" fullWidth loading={isLoading} analyticsId="login:login-passwordless">
        {t('auth:signInPasswordless')}
      </Button>
    </AuthBodySection>
  );
};
