import * as React from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';

type TooltipOptions = {
  placement?: Placement;
  spacing?: number;
};

function useTooltip({ placement = 'top', spacing = 5 }: TooltipOptions = {}) {
  const [open, setOpen] = React.useState(false);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(spacing),
      flip({
        fallbackAxisSideDirection: 'start',
        padding: spacing,
      }),
      shift({ padding: spacing }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: true,
  });
  const focus = useFocus(context, {
    enabled: true,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export function Tooltip({
  children,
  enabled = true,
  ...options
}: { children: React.ReactNode; enabled?: boolean } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={{ ...tooltip, open: enabled ? tooltip.open : false }}>
      {children}
    </TooltipContext.Provider>
  );
}

type ElementRef = HTMLElement;

// Define the props interface
type TooltipTriggerProps = {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>> & {
    ref?: React.RefObject<ElementRef>;
  };
} & Omit<React.HTMLProps<ElementRef>, 'children'>;

export const TooltipTrigger = React.forwardRef<HTMLElement, TooltipTriggerProps>(function TooltipTrigger(
  { children, ...props },
  propRef,
) {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (!React.isValidElement(children)) {
    return null;
  }

  return React.cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed',
    }),
  );
});

export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  function TooltipContent(props, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;

    return (
      <FloatingPortal>
        <div
          ref={ref}
          className="absolute z-50 mb-3 max-w-60  rounded bg-black px-2 py-1  text-xs text-white"
          style={context.floatingStyles}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  },
);
