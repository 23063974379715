import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useInfiniteRelationships } from 'services/repositories/relationships/relationships';
import { useIntegrationProcesses } from 'services/repositories/integrations/integrations';
import {
  CustomerStatus,
  IntegrationProcessConfigurationSteps,
  IntegrationProcessDTO,
  IntegrationProcessRoutes,
  ProcessDirection,
  RelationshipV2,
  UserDTO,
} from 'support/types';
import { BadgeColor } from 'components/Display/Badge/Badge';
import { routeToPage } from 'support/helpers/navigation/navigation';

export enum IntegrationProcessStatus {
  IN_TESTING = 'IN_TESTING',
  READY = 'READY',
  REQUESTED = 'REQUESTED',
  INACTIVE = 'INACTIVE',
}

export type IntegrationProcess = {
  id: string;
  messageType: string;
  standard: string;
  direction: ProcessDirection;
  status: IntegrationProcessStatus;
  type: 'SOURCE' | 'DESTINATION';
};

type UseIntegrationWithProcessesDataArgs = {
  integrationId: string | undefined;
};

type IntegrationWithProcessesData = {
  data: IntegrationProcess[];
  isLoading: boolean;
};

export const useIntegrationWithProcessesData = ({
  integrationId,
}: UseIntegrationWithProcessesDataArgs): IntegrationWithProcessesData => {
  const { data: currentPartner } = useCurrentPartner();
  const {
    data: processes,
    isLoading: isLoadingProcesses,
    isFetched: areProcessesFetched,
  } = useIntegrationProcesses({
    variables: { id: integrationId },
  });
  const {
    data: connections,
    isLoading: isLoadingConnections,
    isFetched: areRelationshipsFetched,
  } = useInfiniteRelationships({
    variables: {
      query: {
        filterValues: [{ key: 'customer_status', value: CustomerStatus.inTesting }],
      },
    },
  });

  const processItems = processes?.map(formatProcess);
  const connectionItems = connections?.pages[0]?.data
    .map(formatRelationship(currentPartner?.id))
    .filter(filterDuplicatedConnectionProcesses)
    .filter(filterConnectionsInProcesses(processItems));

  const data =
    areProcessesFetched && areRelationshipsFetched ? [...(connectionItems ?? []), ...(processItems ?? [])] : [];

  return {
    data,
    isLoading: isLoadingProcesses || isLoadingConnections,
  };
};

const EMPTY_STANDARD = '-';
const formatProcess = (process: IntegrationProcessDTO): IntegrationProcess => {
  return {
    id: process.id,
    messageType: process.messageType,
    standard: (process.configuration?.standard as string) || EMPTY_STANDARD,
    direction: ProcessDirection[process.direction],
    status: IntegrationProcessStatus[process.status],
    type: process.type,
  };
};

const formatRelationship =
  (currentPartnerId: string | undefined) =>
  (relationship: RelationshipV2): IntegrationProcess => {
    return {
      id: relationship.id,
      messageType: relationship.message_type,
      standard: EMPTY_STANDARD,
      direction:
        relationship.sender_partner?.id === currentPartnerId ? ProcessDirection.OUTBOUND : ProcessDirection.INBOUND,
      status: IntegrationProcessStatus.REQUESTED,
      type: relationship.sender_partner?.id === currentPartnerId ? 'SOURCE' : 'DESTINATION',
    };
  };

const filterDuplicatedConnectionProcesses = (
  process: IntegrationProcess,
  index: number,
  array: IntegrationProcess[],
) => {
  const duplicateIndex = array.findIndex(
    (item) => item.messageType === process.messageType && item.direction === process.direction,
  );
  return duplicateIndex === index;
};
const filterConnectionsInProcesses = (formatedProcesses: IntegrationProcess[] | undefined) => {
  return (connection: IntegrationProcess) => {
    return !formatedProcesses?.some((process) => {
      return process.messageType === connection.messageType && process.direction === connection.direction;
    });
  };
};
export const getProcessStatusBadgeColor = (status: IntegrationProcessStatus): BadgeColor => {
  switch (status) {
    case IntegrationProcessStatus.IN_TESTING:
      return BadgeColor.yellow;
    case IntegrationProcessStatus.READY:
      return BadgeColor.green;
    default:
      return BadgeColor.gray;
  }
};

/*
  t('integrations:processes.status.inTesting')
  t('integrations:processes.status.ready')
  t('integrations:processes.status.requested')
*/
export const getProcessStatusBadgeText = (status: IntegrationProcessStatus): string => {
  switch (status) {
    case IntegrationProcessStatus.IN_TESTING:
      return 'integrations:processes.status.inTesting';
    case IntegrationProcessStatus.READY:
      return 'integrations:processes.status.ready';
    case IntegrationProcessStatus.REQUESTED:
      return 'integrations:processes.status.requested';
    default:
      return '';
  }
};

export const generateTableRowPath = (integrationId: string, currentUser?: UserDTO) => (process: IntegrationProcess) => {
  switch (process.status) {
    case IntegrationProcessStatus.IN_TESTING:
      return routeToPage(IntegrationProcessRoutes.edit, {
        id: integrationId,
        processId: process.id,
        step: IntegrationProcessConfigurationSteps.CONFIGURATION,
      });
    case IntegrationProcessStatus.REQUESTED:
      return routeToPage(
        IntegrationProcessRoutes.new,
        {
          id: integrationId,
        },
        {},
        { messageType: process.messageType, direction: process.direction },
      );
    case IntegrationProcessStatus.READY:
      return currentUser?.isAdmin
        ? routeToPage(IntegrationProcessRoutes.transformation, { id: integrationId, processId: process.id })
        : undefined;
    default:
      return undefined;
  }
};
