import Papa from 'papaparse';
import { LoaderResult } from '../../types';
import jschardet from 'jschardet';

export const CSVLoader = (file: File): Promise<LoaderResult> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const encoding = await getCSVFileEncoding(file);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding,
        error: reject,
        complete: (parseResult) => {
          if (parseResult.errors.length > 0) {
            return reject(parseResult.errors);
          }
          return resolve({ data: parseResult.data });
        },
      });
    } catch (error) {
      reject(error);
    }
  });
};

const getCSVFileEncoding = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      if (!e.target?.result) return reject('Could not read file content and get encoding.');
      // Create a binary string from the ArrayBuffer
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      let binaryString = '';
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < data.length; i++) {
        binaryString += String.fromCharCode(data[i]);
      }
      resolve(jschardet.detect(binaryString).encoding);
    };
    reader.readAsArrayBuffer(file);
  });
};
