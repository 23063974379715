import { DataTable, useGenerateColumns } from 'components/DataTable';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { usePinnedColumns } from '../helpers';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ui } from '@procuros/datachecks';
import { useTranslation } from 'react-i18next';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import { DisplayModes } from 'components/DataTable/Types';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/Form/Button';
import { memo } from 'react';

export const DocumentTransportUnits = memo(({ config, mode }: DocumentTransportUnitsProps) => {
  const { label: title, description, options, fields } = config;
  if (!fields?.length) return null;
  return (
    <WebEDISection header={{ title, spacing: 'medium', description }}>
      <div className="space-y-4">
        <DocumentSectionError path={DATA_PREFIX} />
        <DataTableProvider name={DATA_PREFIX}>
          <TransportUnitsTable numberOfPinnedColumns={options?.pinned_column_count} config={fields} mode={mode} />
        </DataTableProvider>
      </div>
    </WebEDISection>
  );
});

DocumentTransportUnits.displayName = 'DocumentTransportUnits';

const TransportUnitsTable = ({
  mode,
  config,
  numberOfPinnedColumns,
}: {
  mode: DisplayModes;
  config: ui.TransportUnitsSection['fields'];
  numberOfPinnedColumns: number | undefined;
}) => {
  const { t } = useTranslation();
  const { fields, append } = useDataTableContext();
  const columns = useGenerateColumns({
    config,
    ...TABLE_STATIC_CONFIG[mode],
    nestedLevelField: 'unit_identifier',
  });
  const pinnedColumns = usePinnedColumns({ config, numberOfPinnedColumns, ...TABLE_STATIC_CONFIG[mode] });
  const emptyValue = useEmptyStructure(fields);

  return (
    <DataTable
      pinnedColumns={pinnedColumns}
      id="transport_units"
      key={mode}
      meta={{ dataPrefix: DATA_PREFIX, mode }}
      columns={columns}
      data={fields}
      enableGlobalSearch={false}
      emptyState={
        mode === DisplayModes.create || mode === DisplayModes.edit
          ? t('webedi:transport_units.empty_state.create.text')
          : t('webedi:transport_units.empty_state.view.text')
      }
      footerMenu={
        mode === DisplayModes.create || mode === DisplayModes.edit ? (
          <Button
            variant="secondary"
            size="extra-small"
            LeftIcon={PlusIcon}
            onClick={() => {
              const table = document.getElementById('transport_units');
              append(emptyValue);
              setTimeout(() => {
                table?.scrollTo({ top: table.scrollHeight });
              }, 250);
            }}
            analyticsId="webedi:transport_units_add"
          >
            {t('webedi:transport_units.add_button.text')}
          </Button>
        ) : null
      }
    />
  );
};

const DATA_PREFIX = 'transport_units';
const TABLE_STATIC_CONFIG = {
  view: {
    withIndexColumn: true,
  },
  create: {
    withIndexColumn: true,
    selectableRows: true,
    deletableRows: true,
  },
  edit: {
    withIndexColumn: true,
    selectableRows: true,
    deletableRows: true,
  },
};

type DocumentTransportUnitsProps = {
  config: ui.TransportUnitsSection;
  mode: DisplayModes;
};
