import { t } from 'i18next';
import { PartnerFilterKey } from './types';
import { RelationshipPartnerStatus } from 'support/types/dtos';
import { PropertyFilter, SearchPropertyFilter, PropertyFilterType } from 'components/ListTable/types';

export const getTraderPartnersFilterConfig = (): Array<PropertyFilter | SearchPropertyFilter> => [
  {
    key: PartnerFilterKey.name,
    type: PropertyFilterType.search,
    value: '',
    placeholder: (count: number) => t('partners:search.searchByName', { count, amount: count }),
  } as SearchPropertyFilter,
  {
    key: PartnerFilterKey.status,
    type: PropertyFilterType.dropdown,
    label: t('partners:search.status.label'),
    value: '',
    dropDownOptions: {
      dropdownValues: [
        { label: t('partners:list.myPartners.partnerStatus.connected'), value: RelationshipPartnerStatus.connected },
        {
          label: t('partners:list.myPartners.partnerStatus.not_connected'),
          value: RelationshipPartnerStatus.notConnected,
        },
        { label: t('partners:list.myPartners.partnerStatus.pending'), value: RelationshipPartnerStatus.pending },
      ],
    },
  },
  {
    key: PartnerFilterKey.industry,
    type: PropertyFilterType.dropdown,
    label: t('partners:search.industry.label'),
    value: '',
    dropDownOptions: {
      dropdownValues: [
        { label: t('partners:search.industry.PHARMA'), value: 'PHARMA' },
        { label: t('partners:search.industry.LOGISTICS'), value: 'LOGISTICS' },
        { label: t('partners:search.industry.INDUSTRIAL'), value: 'INDUSTRIAL' },
        { label: t('partners:search.industry.GROCERY'), value: 'GROCERY' },
        { label: t('partners:search.industry.HOUSEHOLD_SUPPLIES'), value: 'HOUSEHOLD_SUPPLIES' },
        { label: t('partners:search.industry.BEAUTY_HEALTH'), value: 'BEAUTY_HEALTH' },
        { label: t('partners:search.industry.FASHION'), value: 'FASHION' },
        { label: t('partners:search.industry.HARDWARE'), value: 'HARDWARE' },
        { label: t('partners:search.industry.ELECTRONICS'), value: 'ELECTRONICS' },
        { label: t('partners:search.industry.FURNITURE'), value: 'FURNITURE' },
        { label: t('partners:search.industry.AGNOSTIC_MARKETPLACE'), value: 'AGNOSTIC_MARKETPLACE' },
      ],
    },
  },
];
