import { ChevronRightIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { Alert } from 'components/Display/Alert/Alert';
import { Section } from 'components/Display/Section/Section';
import { ListTable } from 'components/ListTable/ListTable';
import { Button } from 'components/Form/Button/Button';
import { TableItem } from 'components/ListTable/types';
import { TestingFlowMessageStateDisplay } from 'components/TestingFlowMessageStateDisplay/TestingFlowMessageStateDisplay';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { useReceiveTestMessage } from 'services/repositories/relationships/relationships';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { nonNullable } from 'support/helpers/generic/generic';
import { routeToPage } from 'support/helpers/navigation/navigation';
import {
  TransactionDTO,
  TransactionInternalType,
  TransactionsRoutes,
  ConnectionsRoutes,
  ReceiverEnvelopeState,
  InternalTransactionState,
} from 'support/types';

export const ReviewTestMessage = ({
  relationshipId,
  transactions,
  pagination,
  isLoading,
  isOrder,
}: {
  relationshipId: string;
  transactions: Array<TransactionDTO> | undefined;
  isLoading: boolean;
  pagination: Parameters<typeof ListTable>[0]['pagination'];
  isOrder: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tableHeaders = useMemo(
    () =>
      [
        { label: t('connections:testMessagePendingReview.table.headers.documentId'), key: 'test-document' },
        isOrder
          ? undefined
          : { label: t('connections:testMessagePendingReview.table.headers.orderNumber'), key: 'order-number' },
        { label: t('connections:testMessagePendingReview.table.headers.status'), key: 'status' },
        { label: t('connections:testMessagePendingReview.table.headers.deliveryTime'), key: 'time-of-delivery' },
        { label: '', key: 'actions' },
      ].filter(nonNullable),
    [isOrder, t],
  );

  const buildRow = (transaction: TransactionDTO): Array<TableItem> => {
    return [
      {
        key: 'test-document',
        element: (
          <Link
            target="_blank"
            to={routeToPage(
              transaction.internalType === TransactionInternalType.message
                ? TransactionsRoutes.viewMessage
                : TransactionsRoutes.viewSenderEnvelope,
              { id: transaction.id },
            )}
            className="block max-w-36 truncate font-normal underline"
          >
            {transaction.externalMessageRef}
          </Link>
        ),
      },
      isOrder
        ? undefined
        : {
            key: 'order-number',
            element: (
              <span className="block max-w-36 truncate">
                {transaction.relatedOrderExternalMessageRef || EMPTY_FIELD}
              </span>
            ),
          },
      {
        key: 'status',
        element: <TestingFlowMessageStateDisplay state={transaction.internalState} receiver />,
      },
      {
        key: 'time-of-delivery',
        element: transaction.receiverEnvelope?.deliveredAt
          ? formatDayAndTime(transaction.receiverEnvelope.deliveredAt)
          : EMPTY_FIELD,
      },
      {
        key: 'actions',
        className: 'text-end',
        element: (
          <Button
            variant="minimal"
            size="small"
            RightIcon={ChevronRightIcon}
            onClick={() =>
              navigate(
                routeToPage(ConnectionsRoutes.testRelationshipMessageDetails, {
                  messageId: transaction.messageId,
                  relationshipId,
                }),
              )
            }
            disabled={['UNKOWN', 'DROPPED'].includes(transaction.state)}
            analyticsId="testing-flow:review-test-message-details"
          >
            {transaction.state === 'FAILED'
              ? t('connections:testMessagePendingReview.table.actions.viewError')
              : t('connections:testMessagePendingReview.table.actions.viewDetails')}
          </Button>
        ),
      },
    ].filter(nonNullable);
  };

  const hasReadyForDeliveryMessages = transactions?.some(
    (transaction) => transaction.internalState === InternalTransactionState.deliverySimulated,
  );

  const hasInDeliveryMessages = transactions?.some((transaction) =>
    [
      InternalTransactionState.initial,
      InternalTransactionState.denormalized,
      InternalTransactionState.decanonicalized,
      InternalTransactionState.deliveryPending,
      InternalTransactionState.pendingAutoRecovery,
    ].includes(transaction.internalState as ReceiverEnvelopeState),
  );

  const hasPendingDeliveryMessages = transactions?.some(
    (transaction) => transaction.internalState === InternalTransactionState.pendingPickup,
  );

  return (
    <div className="space-y-4">
      <Section.Title variant="small">
        {t('connections:testMessagePendingReview.title', {
          documentsCount: transactions?.length,
          count: transactions?.length,
        })}
      </Section.Title>
      <Section.Subtitle>{t('connections:testMessagePendingReview.subtitle')}</Section.Subtitle>

      <ListTable<TransactionDTO>
        headers={tableHeaders}
        data={transactions}
        isLoading={isLoading}
        rowBuilder={buildRow}
        pagination={pagination}
      />
      {hasReadyForDeliveryMessages && (
        <ReadyToDeliveryAlert transactions={transactions} relationshipId={relationshipId} />
      )}
      {hasInDeliveryMessages && <TransmittingMessagesAlert />}
      {hasPendingDeliveryMessages && <PendingPickupMessagesAlert />}
    </div>
  );
};

const ReadyToDeliveryAlert = ({
  transactions,
  relationshipId,
}: {
  transactions: Array<TransactionDTO> | undefined;
  relationshipId: string;
}) => {
  const { t } = useTranslation();
  const { mutate, isLoading: isDeliveringTestMessage } = useReceiveTestMessage();
  const deliveryTestFiles = () => {
    mutate({ id: relationshipId });
  };
  return (
    <Alert
      severity="info"
      withoutIcon
      title={t('connections:testMessagePendingReview.readyForDelivery.alert.title')}
      actions={{
        primary: {
          children: t('connections:testMessagePendingReview.readyForDelivery.alert.action', {
            count: transactions?.length,
          }),
          RightIcon: PaperAirplaneIcon,
          onClick: deliveryTestFiles,
          variant: 'secondary',
          loading: isDeliveringTestMessage,
          analyticsId: 'testing-flow:deliver-test-files',
        },
      }}
    >
      <span>{t('connections:testMessagePendingReview.readyForDelivery.alert.description')}</span>
    </Alert>
  );
};

const TransmittingMessagesAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert
      severity="warning"
      loading={true}
      withoutIcon
      title={t('connections:testMessagePendingReview.transmitting.alert.title')}
    >
      <span>{t('connections:testMessagePendingReview.transmitting.alert.description')}</span>
    </Alert>
  );
};

const PendingPickupMessagesAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert severity="warning" withoutIcon title={t('connections:testMessagePendingReview.pendingPickup.alert.title')}>
      <span>{t('connections:testMessagePendingReview.pendingPickup.alert.description')}</span>
    </Alert>
  );
};
