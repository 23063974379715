import { Button } from 'components/Form/Button/Button';
import { ReactElement, ReactNode, cloneElement, isValidElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const ConnectorSelectorItem = ({
  name,
  description,
  path,
  icon,
  badge,
  onClick,
}: {
  name: string;
  description?: string;
  path?: string;
  icon?: ReactNode;
  badge?: ReactNode;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();

  const onClickCallback = useCallback(() => {
    if (path) {
      navigate(path);
    } else {
      onClick?.();
    }
  }, [path, navigate, onClick]);

  return (
    <Button
      variant="secondary"
      size="extra-large"
      onClick={onClickCallback}
      fullWidth
      className="flex !min-w-full flex-col !items-start gap-6 !p-6 !text-start"
      analyticsId="integrations:connector-select"
    >
      {icon || badge ? (
        <div className="flex w-full flex-wrap items-center justify-between gap-4">
          {icon && isValidElement(icon)
            ? cloneElement(icon as ReactElement, {
                ...icon.props,
                className: icon.props.className + ' shrink-0 w-10 h-10 rounded-lg border border-transparent',
              })
            : null}
          {badge}
        </div>
      ) : null}
      <div className="flex flex-col gap-2">
        <span className="text-xl font-bold text-gray-700">{name}</span>
        {description && <span className="text-sm text-gray-500">{description}</span>}
      </div>
    </Button>
  );
};
