import { RelationshipV2 } from 'support/types';
import { useMemo } from 'react';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { Timeline } from 'components/Timeline/Timeline';
import { useTranslation } from 'react-i18next';
import { RelationshipTimelineActivity } from './RelationshipTimelineActivity/RelationshipTimelineActivity';
import { RelationshipTimelineActivityExtra } from './RelationshipTimelineActivityExtra/RelationshipTimelineActivityExtra';
import { nonNullable } from 'support/helpers/generic/generic';

type RelationshipTimelineProps = {
  relationship: RelationshipV2;
};
export const RelationshipTimeline = ({ relationship }: RelationshipTimelineProps) => {
  const { t } = useTranslation();
  const activities = useMemo(() => generateActivities(relationship), [relationship]);
  if (activities.length === 0) return null;
  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-sm font-bold text-gray-900">{t('connections:relationshipDetails.timeline.title')}</h3>
      <Timeline activities={activities} />
    </div>
  );
};

const generateActivities = (
  relationship: RelationshipTimelineProps['relationship'],
): Parameters<typeof Timeline>[0]['activities'] => {
  const activities = [...(relationship.timeline_events || [])]
    ?.filter((activitiy) => !['PARTNER_APPROVAL_PENDING'].includes(activitiy.type))
    .sort(sortDateReverse)
    .map((activity) => {
      if (activity.type === 'RELATIONSHIP_IS_READY') {
        const isFuture = relationship.go_live_date ? new Date(relationship.go_live_date) > new Date() : false;
        if (isFuture) {
          return null;
        }
      }
      return {
        id: `${activity.type} + ${activity.created_at}`,
        date: formatDayAndTime(activity.created_at),
        content: <RelationshipTimelineActivity activity={activity} relationship={relationship} />,
        extra: <RelationshipTimelineActivityExtra activity={activity} />,
      };
    })
    .filter(nonNullable);
  return activities || [];
};

const sortDateReverse = (
  { created_at: dateA }: { created_at: string },
  { created_at: dateB }: { created_at: string },
) => {
  const castedDateA = new Date(dateA);
  const castedDateB = new Date(dateB);

  return castedDateB.getTime() - castedDateA.getTime();
};
