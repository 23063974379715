import { Filter } from 'components/ListTable/types';
import { createInfiniteQuery, createMutation, createQuery } from 'react-query-kit';
import { buildFilterUrl, withParameter } from 'support/helpers/urls/urls';
import { IntegrationDTO, PaginationDTO } from 'support/types';
import portalSchema from 'support/types/schema-portal';

import { createData, fetchData, queryClient, updateData } from '../../http/http';
import { useCurrentPartner } from '../partners/partners';

type IntegrationsResponseDTO = PaginationDTO<IntegrationDTO>;

type GetIntegrationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}']['get']['responses']['200']['content']['application/json'];

type CreateIntegrationPayload =
  portalSchema.paths['/v1/integrations']['post']['requestBody']['content']['application/json'];

type CreateIntegrationResponse =
  portalSchema.paths['/v1/integrations']['post']['responses']['201']['content']['application/json'];

type UpdateIntegrationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}']['put']['responses']['200']['content']['application/json'];

type UpdateIntegrationPayload =
  portalSchema.paths['/v1/integrations/{integrationId}']['put']['requestBody']['content']['application/json'];

type CreateIntegrationRequestPayload =
  portalSchema.paths['/v1/integrations/request-new']['post']['requestBody']['content']['application/json'];

type CreateIntegrationRequestResponse =
  portalSchema.paths['/v1/integrations/request-new']['post']['responses']['201']['content']['application/json'];

type GetIntegrationConnectivityTestsResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/connectivity-test']['get']['responses']['200']['content']['application/json'];

type CreateIntegrationConnectivityTestResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/connectivity-test']['post']['responses']['201']['content']['application/json'];

type CreateIntegrationConnectivityTestPayload =
  portalSchema.paths['/v1/integrations/{integrationId}/connectivity-test']['post']['requestBody']['content']['application/json'];

type CreateIntegrationDeployResponse =
  portalSchema.paths['/v1/integrations/{id}/deploy']['post']['responses']['200']['content']['application/json'];

type GetIntegrationProcessesResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes']['get']['responses']['200']['content']['application/json'];

export const useInfiniteIntegrations = createInfiniteQuery<IntegrationsResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/integrations',
  queryFn: async ({ queryKey: [, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ ...variables.query, cursor: pageParam });
    const { data } = await fetchData<IntegrationsResponseDTO>('/v1/integrations' + urlParams);

    return data;
  },
  getNextPageParam: (lastPage) => lastPage.nextCursor,
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.previousCursor,
});

export const useCreateIntegrationRequest = createMutation<
  CreateIntegrationRequestResponse,
  CreateIntegrationRequestPayload,
  Error
>({
  mutationFn: async (data) => {
    const response = await createData<CreateIntegrationRequestResponse>('/v1/integrations/request-new', data);
    return response.data;
  },
  onSuccess: async () => queryClient.invalidateQueries(useInfiniteIntegrations.getKey()),
});

export const useIntegration = createQuery<GetIntegrationResponse, { id?: string; withConfiguration?: boolean }, Error>({
  primaryKey: '/integrations',
  queryFn: async ({ queryKey: [, variables] }) => {
    const withParameters = [];
    if (variables.withConfiguration) {
      withParameters.push('configuration');
    }

    const { data } = await fetchData<IntegrationDTO>(
      '/v1/integrations/' + variables.id + withParameter(withParameters),
    );
    return data;
  },
  enabled: (data, variables) => !!variables.id,
});

export const useCreateIntegration = createMutation<CreateIntegrationResponse, CreateIntegrationPayload, Error>({
  mutationFn: async (data) => {
    const response = await createData<CreateIntegrationResponse>('/v1/integrations', data);

    queryClient.setQueryData(useIntegration.getKey({ id: response.data.id, withConfiguration: true }), response.data);
    return response.data;
  },
  onSuccess: async () =>
    Promise.all([
      queryClient.invalidateQueries(useCurrentPartner.getKey()),
      queryClient.invalidateQueries(useInfiniteIntegrations.getKey()),
    ]),
});

type UpdateIntegrationVariables = {
  id: string;
  data: UpdateIntegrationPayload;
};

export const useUpdateIntegration = createMutation<UpdateIntegrationResponse, UpdateIntegrationVariables, Error>({
  mutationFn: async ({ id, data }) => {
    const response = await updateData<UpdateIntegrationResponse>(`/v1/integrations/${id}`, data);

    queryClient.setQueryData(useIntegration.getKey({ id: response.data.id, withConfiguration: true }), response.data);
    return response.data;
  },
  onSuccess: () =>
    Promise.all([
      queryClient.invalidateQueries(useCurrentPartner.getKey()),
      queryClient.invalidateQueries(useInfiniteIntegrations.getKey()),
    ]),
});

export const useIntegrationConnectivityTests = createQuery<
  GetIntegrationConnectivityTestsResponse,
  { id?: string },
  Error
>({
  primaryKey: '/integrations/connectivity-test',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<GetIntegrationConnectivityTestsResponse>(
      '/v1/integrations/' + variables.id + '/connectivity-test',
    );
    return data;
  },
});

export const useCreateIntegrationConnectivityTest = createMutation<
  CreateIntegrationConnectivityTestResponse,
  { id: string; data: CreateIntegrationConnectivityTestPayload },
  Error
>({
  mutationFn: async ({ id, data }) => {
    const response = await createData<CreateIntegrationConnectivityTestResponse>(
      `/v1/integrations/${id}/connectivity-test`,
      data,
    );
    return response.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useIntegrationConnectivityTests.getKey()),
});

export const useIntegrationDeploy = createMutation<CreateIntegrationDeployResponse, { id: string }, Error>({
  mutationFn: async ({ id }) => {
    const response = await createData<CreateIntegrationDeployResponse>(`/v1/integrations/${id}/deploy`);
    return response.data;
  },
  onSuccess: () =>
    Promise.all([
      queryClient.invalidateQueries(useCurrentPartner.getKey()),
      queryClient.invalidateQueries(useInfiniteIntegrations.getKey()),
    ]),
});

export const useIntegrationProcesses = createQuery<GetIntegrationProcessesResponse, { id?: string }, Error>({
  primaryKey: '/integrations/processes',
  queryFn: async ({ queryKey: [, variables] }) => {
    const { data } = await fetchData<GetIntegrationProcessesResponse>(
      '/v1/integrations/' + variables.id + '/processes',
    );
    return data;
  },
  enabled: (data, variables) => !!variables.id,
});
