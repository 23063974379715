import { LoaderResult } from '../../types';
import exceljs from 'exceljs';

export const XSLXLoader = (file: any): Promise<LoaderResult> => {
  return new Promise((resolve, reject) => {
    try {
      if (file) {
        const reader = new FileReader();

        reader.onload = function (e: ProgressEvent<FileReader>) {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          processData(data)
            .then((resultData) => {
              resolve({ data: resultData });
            })
            .catch((error) => {
              reject(error);
            });
        };

        reader.readAsArrayBuffer(file);
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};

const processData = async (data: Uint8Array) => {
  const workbook = new exceljs.Workbook();

  await workbook.xlsx.load(data);
  const excelInJSON: Record<string, any[]> = {};
  workbook.eachSheet((sheet) => {
    const sheetHeaders: Array<string | undefined> = sheet.getRow(1).values as Array<string | undefined>;
    excelInJSON[sheet.name] = [];

    sheet.eachRow((row, rowNumber) => {
      //rowNumbers start at 1
      if (rowNumber > 1) {
        const rowValues = row.values as string[];
        const rowData: Record<string, string> = rowValues.reduce(
          (acc, value, index) => {
            const header = sheetHeaders[index];
            if (header !== null && header !== undefined) {
              acc[header] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );
        excelInJSON[sheet.name].push(rowData);
      }
    });
  });

  return excelInJSON;
};
