import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardRoutes } from 'support/types';
import { isPotentiallyAuthenticated } from 'services/auth/auth';
import { Credentials } from './types';
import { PasswordlessLoginForm } from './PasswordessLoginForm/PasswordlessLoginForm';
import { PasswordLoginForm } from './PasswordLoginForm/PasswordLoginForm';
import { AuthPage } from '../components/AuthPage/AuthPage';

const defaultData: Credentials = {
  email: '',
  password: '',
};

enum LoginFormMethods {
  password = 'password',
  passwordless = 'passwordless',
}

export function Login() {
  const [loginMethod, setLoginMethod] = useState<LoginFormMethods>(LoginFormMethods.passwordless);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl: string = location.state?.from || DashboardRoutes.root;

  useEffect(() => {
    // If the user is already logged in, redirect to the dashboard
    if (isPotentiallyAuthenticated()) {
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate, redirectUrl]);

  const formMethods = useForm<Credentials>({
    defaultValues: defaultData,
  });

  return (
    <AuthPage>
      <FormProvider {...formMethods}>
        {loginMethod === LoginFormMethods.passwordless ? (
          <PasswordlessLoginForm
            toggleLoginMethod={() => setLoginMethod(LoginFormMethods.password)}
            defaultData={defaultData}
          />
        ) : (
          <PasswordLoginForm
            toggleLoginMethod={() => {
              setLoginMethod(LoginFormMethods.passwordless);
              formMethods.resetField('password');
            }}
            defaultData={defaultData}
          />
        )}
      </FormProvider>
    </AuthPage>
  );
}
