import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { Page } from 'components/Page/Page';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { DisplayModes } from 'components/DataTable/Types';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'components/Form/Form';
import { ui } from '@procuros/datachecks';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import {
  DocumentType,
  IssueResolverType,
  MessageState,
  MessageV2DTO,
  PartnerDTO,
  ProcessSpecificationDTO,
  TransactionInternalType,
} from 'support/types';
import { DocumentSections } from 'components/WebEDI/DocumentSections/DocumentSections';
import { DATA_CHECK_ERROR_CODE, EMPTY_FUNCTION } from 'support/helpers/const/const';
import { useFlattenData } from 'hooks/useFlattenData';
import { datachecksResolver } from 'support/helpers/resolvers/datachecksResolver';
import { cleanStore, setIsValidating, setProcessSpecification, useDatachecksStore } from 'stores/datachecks/datachecks';
import { terminateDataChecksWorker } from 'services/datachecks/datachecks';
import { UnkownTypePlaceholder } from '../TransactionViewPage/MessageViewPage/UnkownTypePlaceholder';
import { MessageHeader } from '../TransactionViewPage/MessageViewPage/MessageHeader/MessageHeader';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';
import { useDatachecksData } from '../hooks/useDatachecksData';
import { Placeholder } from 'components/Placeholder/Placeholder';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { useEditMessage } from '../hooks/useEditMessage';
import { CreateInvoiceAndCreditNoteSummary } from 'components/WebEDI/CreateInvoiceAndCreditNoteSummary/CreateInvoiceAndCreditNoteSummary';
import { WebEDIMessageErrorBanner } from 'components/WebEDI/WebEDIMessageErrorBanner/WebEDIMessageErrorBanner';
import { CreateShippingNoticeSummary } from 'components/WebEDI/CreateShippingNoticeSummary/CreateShippingNoticeSummary';

const mode = DisplayModes.edit;
export function EditDocument() {
  const { id } = useParams<{ id: string }>();
  const { data: currentPartner } = useCurrentPartner();
  const { data: message, isError } = useMessageWithProcessSpecification({
    variables: {
      messageId: id,
    },
  });

  useLayoutEffect(() => {
    cleanStore();
    return () => {
      cleanStore();
      terminateDataChecksWorker();
    };
  }, []);

  const processSpec: ProcessSpecificationDTO | undefined = useMemo(() => {
    return message
      ? {
          fields: message.fields,
          data: message.data?.canonical,
          process: message.process,
        }
      : undefined;
  }, [message]);

  const { data, uiConfig, isLoading: isLoadingDataChecks } = useFlattenData(processSpec);

  if (!id) return;

  if (isError) {
    return <UnkownTypePlaceholder messageId={id} />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).procuros_debug) {
    console.log({ flattenData: data, canonical: processSpec?.data });
  }

  return (
    <EditDocumentPageUI
      isLoading={isLoadingDataChecks}
      message={message?.data}
      data={data}
      relatedTransactions={message?.related_messages || []}
      currentPartner={currentPartner}
      uiConfig={uiConfig}
      processSpec={processSpec}
    />
  );
}

type EditDocumentPageUIProps = {
  isLoading: boolean;
  message: MessageV2DTO | undefined;
  data: any;
  relatedTransactions: Array<MessageV2DTO>;
  currentPartner: PartnerDTO | undefined;
  uiConfig: Array<ui.Section>;
  processSpec: ProcessSpecificationDTO | undefined;
};

export const EditDocumentPageUI = ({
  isLoading,
  message,
  data,
  uiConfig,
  relatedTransactions,
  currentPartner,
  processSpec,
}: EditDocumentPageUIProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setCanonical = useDatachecksStore((state) => state.setCanonical);
  const [issueResolver, issueResolverLabel]: [IssueResolverType, string] = useTransactionActionRequired()(
    message?.issue_resolver_id,
  );
  const hasDatachecksError = message?.blocking_error?.code === DATA_CHECK_ERROR_CODE;

  const resolver = useMemo(
    () => (message?.internal_state === MessageState.routingFailed ? undefined : datachecksResolver(processSpec)),
    [processSpec, message],
  );

  const formMethods = useForm<typeof data>({
    values: data,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver,
  });

  const {
    handleSubmit,
    formState: { isSubmitted },
    watch,
    reset,
    trigger,
    getValues,
  } = formMethods;

  useEffect(() => {
    setProcessSpecification(processSpec);
  }, [processSpec]);

  useDatachecksData({
    targetRelationshipId: message?.relationship_id,
    watch,
    reset,
    isSubmitted,
    trigger,
    documentType: message?.type as DocumentType,
    processSpecification: processSpec,
  });

  useEffect(() => {
    if (message?.canonical) {
      setCanonical(message?.canonical);
    }
  }, [message, setCanonical]);

  useEffect(() => {
    if (hasDatachecksError && data) {
      setIsValidating(true);
      handleSubmit(EMPTY_FUNCTION)();
    }
  }, [data, handleSubmit, hasDatachecksError]);

  const { editMessage, isUpdating: isEditingMessage } = useEditMessage({
    message: message as MessageV2DTO,
    processSpecification: processSpec,
  });
  const onSubmit = useCallback(() => {
    editMessage(getValues());
  }, [editMessage, getValues]);

  const bottomBanner = useMemo(() => {
    if (!message?.type) {
      return null;
    }

    switch (message?.type as DocumentType) {
      case DocumentType.invoice:
      case DocumentType.creditNote:
        return (
          <CreateInvoiceAndCreditNoteSummary
            documentType={message?.type as DocumentType.creditNote | DocumentType.invoice}
          />
        );
      case DocumentType.shippingNotice:
        return <CreateShippingNoticeSummary documentType={message?.type as DocumentType.shippingNotice} />;
      default:
        return null;
    }
  }, [message?.type]);

  return (
    <Page isLoading={isLoading}>
      <FormProvider {...formMethods}>
        <Form noValidate={true} id="webedi-document" onSubmit={handleSubmit(onSubmit)}>
          <Page.Head>
            {!!message && (
              <MessageHeader
                mode={issueResolver !== IssueResolverType.CURRENT_PARTNER ? DisplayModes.view : mode}
                message={message}
                relatedTransactions={relatedTransactions}
                currentPartner={currentPartner}
                webediV2
                issueResolverLabel={issueResolverLabel}
                isLoading={isEditingMessage}
              />
            )}
          </Page.Head>
          <Page.Section
            topBanner={
              <>
                {message?.blocking_error ? (
                  <WebEDIMessageErrorBanner
                    messageId={message.id}
                    mode={mode}
                    blockingError={message.blocking_error}
                    issueResolver={issueResolver}
                  />
                ) : null}
              </>
            }
            bottomBanner={bottomBanner}
          >
            {issueResolver !== IssueResolverType.CURRENT_PARTNER ? (
              <Placeholder
                title={t('webedi:editDocument.noActionRequired.title')}
                description={t('webedi:editDocument.noActionRequired.description')}
                icon={<LockClosedIcon />}
                ctaText={t('webedi:editDocument.noActionRequired.ctaText')}
                ctaOnClick={() => {
                  navigate(routeToTransactionPage(TransactionInternalType.message, message?.id as string));
                }}
              />
            ) : uiConfig ? (
              <DocumentSections
                mode={mode}
                uiConfig={uiConfig}
                documentType={message?.type as DocumentType}
                variables={message?.receiver_partner ? { receiverPartnerName: message.receiver_partner.name } : {}}
              />
            ) : null}
          </Page.Section>
        </Form>
      </FormProvider>
    </Page>
  );
};
