import { CreatePartnerRequest, useCreatePartner } from 'services/repositories/partners/partners';
import { CreateTradeRequestRequest, useCreateTradeRequest } from 'services/repositories/relationships/relationships';
import { DocumentType, PartnerDTO, PartnerDocumentTypes } from 'support/types';

export type DocumentTypeSetting = {
  documentType: DocumentType;
  outgoing: boolean | null;
  incoming: boolean | null;
};

export const defaultDocumentSettings: DocumentTypeSetting[] = [
  { documentType: DocumentType.order, incoming: false, outgoing: false },
  { documentType: DocumentType.orderResponse, incoming: false, outgoing: false },
  { documentType: DocumentType.shippingNotice, incoming: false, outgoing: false },
  { documentType: DocumentType.invoice, incoming: false, outgoing: false },
  { documentType: DocumentType.creditNote, incoming: false, outgoing: false },
  { documentType: DocumentType.dispatchInstruction, incoming: false, outgoing: false },
  { documentType: DocumentType.dispatchResponse, incoming: false, outgoing: false },
  { documentType: DocumentType.productCatalog, incoming: false, outgoing: false },
];

export const mapPartnerDocumentTypes = (partnerDocuments?: PartnerDocumentTypes): DocumentTypeSetting[] => {
  const mappedDocumentTypes: DocumentTypeSetting[] = [];

  defaultDocumentSettings.forEach((defaultDocumentSetting) => {
    const incomingConnected = partnerDocuments?.sends.some(
      (partnerDocument) => partnerDocument.type === defaultDocumentSetting.documentType && partnerDocument.connected,
    );

    const outgoingConnected = partnerDocuments?.receives.some(
      (partnerDocument) => partnerDocument.type === defaultDocumentSetting.documentType && partnerDocument.connected,
    );

    const mappedDocument: DocumentTypeSetting = {
      documentType: defaultDocumentSetting.documentType,
      incoming: incomingConnected ? null : false,
      outgoing: outgoingConnected ? null : false,
    };

    mappedDocumentTypes.push(mappedDocument);
  });

  return mappedDocumentTypes;
};

export const createTradePartnerRequests = async ({
  createPartnerMutation,
  createTradeRequestMutation,
  currentPartner,
  submission: { tradePartners, requestedDocumentTypes, additionalInformation, message, includeAsCCRecipient },
}: {
  createPartnerMutation: ReturnType<typeof useCreatePartner>;
  createTradeRequestMutation: ReturnType<typeof useCreateTradeRequest>;
  currentPartner: PartnerDTO;
  submission: {
    message?: string;
    additionalInformation?: string;
    tradePartners: Required<CreatePartnerRequest>['partners'] &
      Array<{
        partyIdentifierDomain: Required<CreateTradeRequestRequest>['tradeRequests'][number]['partyIdentifierDomain'];
        partyIdentifier: Required<CreateTradeRequestRequest>['tradeRequests'][number]['partyIdentifier'];
      }>;
    requestedDocumentTypes: DocumentTypeSetting[];
    includeAsCCRecipient?: boolean;
  };
}) => {
  const newTradePartners = await createPartnerMutation.mutateAsync({
    partners: tradePartners.map((tradePartner) => ({
      name: tradePartner.name,
      contactPersons: [
        {
          language: tradePartner.contactPersons[0].language,
          name: tradePartner.contactPersons[0].name,
          email: tradePartner.contactPersons[0].email,
          phone: tradePartner.contactPersons[0].phone,
        },
      ],
    })),
  });

  const tradeRequests = newTradePartners.map((newTradePartner, tradePartnerIndex) => {
    const relationships = createDocumentTypesPayload(
      currentPartner.id,
      newTradePartner.partnerId,
      requestedDocumentTypes,
    );

    return {
      partyIdentifierDomain: tradePartners[tradePartnerIndex].partyIdentifierDomain,
      partyIdentifier: tradePartners[tradePartnerIndex].partyIdentifier,
      partnerId: newTradePartner.partnerId,
      additionalInformation,
      relationships,
      message,
      includeAsCCRecipient,
    };
  });

  await createTradeRequestMutation.mutateAsync({
    tradeRequests,
  });
};

export const createDocumentTypesPayload = (
  currentPartner: PartnerDTO['id'],
  tradePartner: PartnerDTO['id'],
  documentTypesSettings: DocumentTypeSetting[],
) => {
  const relationships = documentTypesSettings.reduce<
    Required<CreateTradeRequestRequest>['tradeRequests'][number]['relationships']
  >((carry, setting) => {
    const newRelationships = [];

    if (setting.outgoing) {
      newRelationships.push({
        documentType: setting.documentType,
        sender: {
          partnerId: currentPartner,
        },
        receiver: {
          partnerId: tradePartner,
        },
      });
    }

    if (setting.incoming) {
      newRelationships.push({
        documentType: setting.documentType,
        sender: {
          partnerId: tradePartner,
        },
        receiver: {
          partnerId: currentPartner,
        },
      });
    }

    return [...carry, ...newRelationships];
  }, []);

  return relationships;
};
