import { useShippingNoticeSummary } from 'components/WebEDI/hooks/useShippingNoticeSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from 'support/helpers/dateTime/dateTime';
import { DocumentSummaryContent } from './DocumentSummaryContent';

export const ShippingNoticeSummary = () => {
  const { t } = useTranslation();
  const shippingNoticeSummary = useShippingNoticeSummary();
  return (
    <DocumentSummaryContent
      items={[
        {
          label: t('webedi:documentSummary.shippingNotice.totalQuantity.label'),
          value: Math.round(shippingNoticeSummary.totalQuantity),
        },
        {
          label: t('webedi:documentSummary.shippingNotice.transportUnitCount.label'),
          value: Math.round(shippingNoticeSummary.transportUnitCount),
        },
        {
          label: t('webedi:documentSummary.shippingNotice.deliveryDate.label'),
          value: formatTimestamp(shippingNoticeSummary.deliveryDate) ?? '—',
        },
      ]}
    />
  );
};
