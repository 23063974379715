import { ReactNode } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  popup: ReactNode;
};

type Actions = {
  addGlobalPopup: (popup: ReactNode) => void;
  removeGlobalPopup: () => void;
};

const initialState: State = {
  popup: null,
};

export const useGlobalPopupStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,

    addGlobalPopup: (popup: ReactNode) => {
      set((state) => {
        state.popup = popup;
      });
    },

    removeGlobalPopup: () => {
      set((state) => {
        state.popup = null;
      });
    },
  })),
);

export const addGlobalPopup = (popup: Parameters<Actions['addGlobalPopup']>['0']) => {
  return useGlobalPopupStore.getState().addGlobalPopup(popup);
};

export const removeGlobalPopup = () => {
  return useGlobalPopupStore.getState().removeGlobalPopup();
};
