import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

type InvoiceAndCreditNoteSummaryValues = {
  subtotal: number;
  charges: number;
  allowances: number;
  taxes: number;
  due: number;
};

// We are calculating a more user-friendly summary for the invoice here:
// Basically we are reporting charges and allowances separately from the subtotal
// regardless of whether they are on line- or document level.
// Typically the subtotal would already have charges and allowances from the line-level included.
const calculateInvoiceAndCreditNoteSummary = (
  initialSummary: InvoiceAndCreditNoteSummaryValues,
  lineItems?: Array<any>,
): InvoiceAndCreditNoteSummaryValues =>
  lineItems?.reduce(
    (acc, item) => ({
      ...acc,
      subtotal: acc.subtotal + item.line_gross_amount,
      charges: acc.charges + item.line_charge_amount,
      allowances: acc.allowances + item.line_allowance_amount,
    }),
    initialSummary,
  ) ?? initialSummary;

export const useInvoiceAndCreditNoteSummary = (): InvoiceAndCreditNoteSummaryValues => {
  const [lineItems, due, taxes, charges, allowances] = useWatch({
    name: [
      'line_items',
      'info.0.due_amount',
      'tax.total_tax_amount',
      'info.0.charge_amount',
      'info.0.allowance_amount',
    ],
  });
  return useMemo(
    () =>
      calculateInvoiceAndCreditNoteSummary(
        {
          subtotal: 0,
          charges: charges ?? 0,
          allowances: allowances ?? 0,
          taxes: taxes ?? 0,
          due: due ?? 0,
        },
        lineItems,
      ),
    [lineItems, charges, allowances, due, taxes],
  );
};
