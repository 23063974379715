import { AllowedLanguages, setLanguage } from 'i18n';
import { queryClient } from 'services/http/http';
import { createMutation, createQuery } from 'react-query-kit';
import { UserDTO } from 'support/types';
import portalSchema from 'support/types/schema-portal';
import { createData, fetchData, updateData } from '../../http/http';
import { setPotentiallyAuthenticated } from 'services/auth/auth';
import { SessionStorageService } from 'services/sessionStorage/sessionStorage';

type RegisterRequest = portalSchema.paths['/v1/auth/register']['post']['requestBody']['content']['application/json'];

type RegisterResponse =
  portalSchema.paths['/v1/auth/register']['post']['responses']['200']['content']['application/json'];

type ForgotPasswordResponse =
  portalSchema.paths['/v1/auth/password-reset-request']['post']['responses']['200']['content']['application/json'];

type ResetPasswordRequest =
  portalSchema.paths['/v1/auth/reset-password']['post']['requestBody']['content']['application/json'];

type ResetPasswordResponse =
  portalSchema.paths['/v1/auth/reset-password']['post']['responses']['200']['content']['application/json'];

type SignOutResponse = portalSchema.paths['/v1/auth/logout']['post']['responses']['200']['content']['application/json'];

export const useRegister = createMutation<void, RegisterRequest, Error>({
  mutationFn: async (variables) => {
    await createData<RegisterResponse>('/v1/auth/register', variables);
  },
});

type SignInRequest = portalSchema.paths['/v1/auth/login']['post']['requestBody']['content']['application/json'];
type SignInResponse = portalSchema.paths['/v1/auth/login']['post']['responses']['200']['content']['application/json'];
export const usePasswordSignIn = createMutation<SignInResponse, SignInRequest, Error>({
  mutationFn: async (variables) => {
    const { data } = await createData<SignInResponse>('/v1/auth/login', variables);
    return data;
  },
  onSuccess: (data) => {
    setPotentiallyAuthenticated(true);
    queryClient.setQueryData(useCurrentUser.getKey(), data.user);
    return setLanguage(data.user.language as AllowedLanguages);
  },
});

type PasswordlessSignInRequest = Omit<
  portalSchema.paths['/v1/auth/login-passwordless']['post']['requestBody']['content']['application/json'],
  'password'
>;
type PasswordlessSignInRequestResponse =
  portalSchema.paths['/v1/auth/login-passwordless']['post']['responses']['200']['content']['application/json'];
export const usePasswordlessSignIn = createMutation<
  PasswordlessSignInRequestResponse,
  PasswordlessSignInRequest,
  Error
>({
  mutationFn: async (variables) => {
    const { data } = await createData<PasswordlessSignInRequestResponse>('/v1/auth/login-passwordless', variables);
    return data;
  },
});

export const useSignOut = createMutation<void, null, Error>({
  mutationFn: async () => {
    await createData<SignOutResponse>('/v1/auth/logout');
  },
  onSuccess: () => {
    setPotentiallyAuthenticated(false);
    queryClient.clear();
    SessionStorageService.clear();
    return queryClient.invalidateQueries(useCurrentUser.getKey());
  },
});

export const useCurrentUser = createQuery<UserDTO, any, Error>({
  primaryKey: '/currentUser',
  queryFn: async () => {
    const { data } = await fetchData<UserDTO>('/v1/user/current');

    return data;
  },
  onSuccess: (data) => {
    setPotentiallyAuthenticated(true);
    return setLanguage(data.language as AllowedLanguages);
  },
  staleTime: Infinity,
});

export const useRequestPasswordReset = createMutation<ForgotPasswordResponse, { email: string }, Error>({
  mutationFn: async (variables) => {
    const { data } = await createData<ForgotPasswordResponse>('/v1/auth/password-reset-request', {
      email: variables.email,
    });
    return data;
  },
});

export const useSetNewPassword = createMutation<ResetPasswordResponse, ResetPasswordRequest, Error>({
  mutationFn: async (variables) => {
    const { data } = await createData<ResetPasswordResponse>('/v1/auth/reset-password', variables);
    return data;
  },
});

type UpdateUserVariables = portalSchema.components['schemas']['PutUser'];

export const useUpdateUser = createMutation<UserDTO, UpdateUserVariables, Error>({
  mutationFn: async (variables) => {
    const { data } = await updateData<UserDTO>('/v1/user', variables);
    return data;
  },
  onSuccess: (data, { partner_id }) => {
    if (partner_id) {
      queryClient.clear();
      return queryClient.invalidateQueries(useCurrentUser.getKey());
    }
    queryClient.setQueryData(useCurrentUser.getKey(), data);
    return setLanguage(data.language as AllowedLanguages);
  },
});
