import { DetailedHTMLProps, FormHTMLAttributes } from 'react';

export const Form = (
  props: Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onKeyDown'>,
) => {
  return (
    <form
      {...props}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isTextArea(e.target as HTMLElement)) {
          // Keeps form from submiting on hitting enter
          e.preventDefault();
        }
      }}
    />
  );
};

const isTextArea = (element: HTMLElement) => element.tagName === 'TEXTAREA';
