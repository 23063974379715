import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { ENV } from 'services/environment/environment';
import { UserKeys } from 'support/types/localStorage';

const defaultNS = 'common';

export type AllowedLanguages = 'en' | 'de';
const DEFAULT_LANGUAGE: AllowedLanguages = 'en';
const AllowedLanguagesArray: Array<AllowedLanguages> = ['en', 'de'];
const localStorageLanguage = localStorage.getItem(UserKeys.i18n);

export let currentLanguage: AllowedLanguages =
  localStorageLanguage && AllowedLanguagesArray.includes(localStorageLanguage as AllowedLanguages)
    ? (localStorageLanguage as AllowedLanguages)
    : DEFAULT_LANGUAGE;

export const setLanguage = async (language: AllowedLanguages): Promise<void> => {
  currentLanguage = language;
  localStorage.setItem(UserKeys.i18n, language);
  await i18n.changeLanguage(language);
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: [
      defaultNS,
      'auth',
      'connections',
      'dashboard',
      'inbox',
      'integrations',
      'orders',
      'partners',
      'priceList',
      'priceCatalogs',
      'settings',
      'transactions',
      'webedi',
      'onboarding',
    ],
    defaultNS,
    fallbackLng: 'en',
    lng: currentLanguage,
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: false,
    returnNull: false,
    returnDetails: false,
    backend: {
      queryStringParams: { v: ENV.SENTRY_RELEASE },
    },
  });
