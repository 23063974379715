import { EMPTY_FIELD } from 'support/helpers/const/const';
import { SummaryAccordionItem } from '../types';

type SummaryAccordionBodyProps = {
  items: Array<SummaryAccordionItem>;
};
export const SummaryAccordionBody = ({ items }: SummaryAccordionBodyProps) => {
  return (
    <div className="flex w-full flex-col px-8">
      {items.map((item) => {
        return (
          <div key={item.label} className="flex items-center justify-between border-b border-gray-200 py-4">
            <span className="text-sm text-gray-700">{item.label}</span>
            <span className="text-sm font-semibold text-gray-700">{item.value || EMPTY_FIELD}</span>
          </div>
        );
      })}
    </div>
  );
};
