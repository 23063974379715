import { ModificationDTO } from 'support/types';

export enum ModificationCalculationType {
  relative = 'RELATIVE',
  absolute = 'ABSOLUTE',
}

export const getModificationCalculationType = (modification: ModificationDTO): ModificationCalculationType =>
  typeof modification.amount === 'undefined'
    ? ModificationCalculationType.relative
    : ModificationCalculationType.absolute;
