import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button';
import { useTranslation } from 'react-i18next';

type SelectionWidgetProps = {
  rowSelection: any;
  handleBulkEditButtonClick: () => void;
  clearSelection: () => void;
};
export const SelectionWidget = ({ rowSelection, handleBulkEditButtonClick, clearSelection }: SelectionWidgetProps) => {
  const { t } = useTranslation();
  return (
    <div className="absolute bottom-full left-0 pb-2">
      <div className="box-border flex items-center rounded-md border border-gray-200 bg-white px-4 py-1.5 shadow-sm">
        <span className="truncate text-sm text-gray-500">
          {t('common:components.dataTable.selection.selectedItems', {
            count: Object.keys(rowSelection).length,
            selectedElements: Object.keys(rowSelection).length,
          })}
        </span>
        <Button
          className="ml-6"
          variant="secondary"
          LeftIcon={PencilSquareIcon}
          onClick={handleBulkEditButtonClick}
          analyticsId="table_bulk_edit_open"
        >
          {t('common:components.dataTable.selection.edit')}
        </Button>
        <Button className="ml-2" variant="secondary" onClick={clearSelection} analyticsId="table_bulk_edit_clear">
          {t('common:components.dataTable.selection.clear')}
        </Button>
      </div>
    </div>
  );
};
