import { useNavigate, useParams } from 'react-router-dom';
import { useCreateIntegrationRequest, useIntegration } from 'services/repositories/integrations/integrations';
import { useEffect, useMemo } from 'react';
import {
  Connectors,
  ConnectorsType,
  IntegrationConfigurationSteps,
  IntegrationDTO,
  IntegrationsRoutes,
} from 'support/types';
import { useTranslation } from 'react-i18next';
import { ContactSoon } from 'components/ContactSoon/ContactSoon';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectorSteps } from './components/ConnectorSteps/ConnectorSteps';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';

const connectorsAvailable = [Connectors.WEB_EDI];

export const NewEditIntegration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type, id, step } = useParams<{ type: Connectors; id: string; step?: IntegrationConfigurationSteps }>();
  const { data: loadedIntegration, isInitialLoading: isLoadingIntegration } = useIntegration({
    variables: { id, withConfiguration: true },
  });
  const { mutate: sendIntegrationRequest } = useCreateIntegrationRequest();
  const { enabled: isAs2ConnectorEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.AS2_SETUP);

  if (isAs2ConnectorEnabled) {
    connectorsAvailable.push(Connectors.AS2);
  }

  const integration = useMemo(() => (id ? loadedIntegration : undefined), [id, loadedIntegration]);
  useEffect(() => {
    if (isUnavailableToEdit(integration)) {
      navigate(IntegrationsRoutes.root, { replace: true });
    }
    if (isUnavailableToTest(integration) && step === IntegrationConfigurationSteps.TEST) {
      navigate(
        routeToPage(IntegrationsRoutes.edit, {
          id: integration.id,
          step: IntegrationConfigurationSteps.CONFIGURATION,
        }),
        { replace: true },
      );
    }
  }, [integration, navigate, step]);

  const connector = useMemo(() => (integration ? integration.connector : type), [integration, type]);
  const isDisabledConnector = useMemo(
    () => connector && !connectorsAvailable.some((c) => c === connector),
    [connector],
  );

  useEffect(() => {
    if (!integration && connector && isDisabledConnector && connector !== Connectors.NONE) {
      sendIntegrationRequest({ connector: connector as ConnectorsType });
    }
  }, [connector, sendIntegrationRequest, isDisabledConnector, integration]);

  if (isUnavailableToEdit(integration)) {
    return null;
  }

  if (isDisabledConnector) {
    return (
      <ContactSoon
        title={t('integrations:contactSoon.title')}
        description={t('integrations:contactSoon.description')}
        ctaOptions={{
          ctaAction: () => navigate(IntegrationsRoutes.root),
          ctaText: t('integrations:contactSoon.button'),
        }}
      />
    );
  }
  return (
    <ConnectorSteps
      connector={connector as Connectors}
      integration={integration}
      stepKey={step}
      isLoadingIntegration={isLoadingIntegration}
    />
  );
};

const isUnavailableToEdit = (integration?: IntegrationDTO) => integration?.connectionEstablishedAt;

const isUnavailableToTest = (integration?: IntegrationDTO): integration is IntegrationDTO =>
  Boolean(integration && !integration?.hasValidConfiguration);
