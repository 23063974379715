import { PricingConfigDTO } from 'support/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  uploadedPriceConfig: PricingConfigDTO | undefined;
};

type Actions = {
  setUploadedPriceConfig: (priceConfig: State['uploadedPriceConfig']) => void;
  removeUploadedPriceConfig: () => void;
};

export const initialState: State = {
  uploadedPriceConfig: undefined,
};

export const usePriceConfigStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,
    setUploadedPriceConfig: (priceConfig) => {
      set((state) => {
        state.uploadedPriceConfig = priceConfig;
      });
    },

    removeUploadedPriceConfig: () => {
      set((state) => {
        state.uploadedPriceConfig = undefined;
      });
    },
  })),
);

export const setUploadedPriceConfig = (priceConfig: Parameters<Actions['setUploadedPriceConfig']>[0]) => {
  return usePriceConfigStore.getState().setUploadedPriceConfig(priceConfig);
};

export const removeUploadedPriceConfig = () => {
  return usePriceConfigStore.getState().removeUploadedPriceConfig();
};
