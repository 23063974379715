import { Button } from 'components/Form/Button/Button';
import { ReactElement, cloneElement } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type PlaceholderProps = {
  title: string;
  description?: string;
  ctaText?: string;
  ctaOnClick?: () => void;
  ctaIcon?: React.ComponentType<any>;
  icon: ReactElement;
};

export const Placeholder = ({ title, description, ctaText, ctaOnClick, ctaIcon, icon }: PlaceholderProps) => {
  return (
    <div className="mt-14">
      <div className="mx-auto flex w-[600px] flex-col items-center justify-center gap-6 px-8 text-center">
        <PlaceholderIcon icon={icon} />
        <h3 className={classNames('text-lg text-gray-900 font-bold')}>{title}</h3>
        {description ? <p className="text-sm text-gray-500">{description}</p> : null}
        <PlaceholderCTA
          ctaText={ctaText}
          ctaOnClick={ctaOnClick}
          className={description ? undefined : 'mt-10'}
          ctaIcon={ctaIcon}
        />
      </div>
    </div>
  );
};

const PlaceholderIcon = ({ icon }: { icon: PlaceholderProps['icon'] }) => {
  return (
    <div className="rounded-full bg-procuros-grey p-5 text-slate-900">
      {cloneElement(icon, { className: 'h-16 w-16' })}
    </div>
  );
};

const PlaceholderCTA = ({
  ctaText,
  ctaOnClick,
  className,
  ctaIcon,
}: {
  ctaText: PlaceholderProps['ctaText'];
  ctaOnClick: PlaceholderProps['ctaOnClick'];
  className?: string;
  ctaIcon?: React.ComponentType<any>;
}) => {
  return ctaText ? (
    <Button
      onClick={ctaOnClick}
      className={className}
      LeftIcon={ctaIcon}
      variant="secondary"
      analyticsId="placeholder_cta"
    >
      {ctaText}
    </Button>
  ) : null;
};
