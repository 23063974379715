import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useCreateTradeRequest } from 'services/repositories/relationships/relationships';
import { addNotification } from 'stores/notifications/notifications';
import { IdentifierDomainOptions, PartnerDTO, PartnerV2 } from 'support/types';
import { DocumentTypeSetting, createDocumentTypesPayload, mapPartnerDocumentTypes } from '../helpers';
import { SlideOver } from 'components/Display/SlideOver/SlideOver';
import { TradeRequestMessage } from '../TradeRequestMessage/TradeRequestMessage';
import { Form } from 'components/Form/Form';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import {
  PartnerIdentifierSchema,
  PartnerIdentifierType,
  PartnerIdentifiers,
} from '../PartnerIdentifiers/PartnerIdentifiers';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CheckboxInABox } from 'components/Form/Checkbox/Checkbox';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Button } from 'components/Form/Button/Button';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { classNames } from 'support/helpers/generic/generic';
import { DocumentSettingsCheckboxes } from '../DocumentSettingsCheckboxes/DocumentSettingsCheckboxes';

type NewTradeRequestProps = {
  showPopup: boolean;
  onClose: () => void;
  partner: Pick<PartnerDTO, 'id' | 'documentTypes' | 'logoUrl' | 'name'>;
  connected: boolean;
  onBack?: () => void;
};

type NewTradeRequestForm = { message: string; includeAsCCRecipient: boolean } & PartnerIdentifierType;

const defaultData = (connected: boolean): NewTradeRequestForm => ({
  message: '',
  includeAsCCRecipient: true,
  partyIdentifierDomain: connected ? undefined : IdentifierDomainOptions.GS1,
});

export function NewTradeRequest({
  showPopup,
  onClose: setShowPopup,
  partner,
  connected,
  onBack,
}: NewTradeRequestProps) {
  const { t } = useTranslation();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const formMethods = useForm<NewTradeRequestForm>({
    defaultValues: defaultData(connected),
    resolver: yupResolver(
      Yup.object()
        .shape({
          message: Yup.string().default(''),
          includeAsCCRecipient: Yup.boolean().required(),
        })
        .concat(PartnerIdentifierSchema(t, connected)),
    ),
  });

  const { register, handleSubmit, reset, setError } = formMethods;

  /*
  We have an error here when partner is not yet loaded, so we need to add a check for that
  */
  const [documentTypeSettings, setDocumentTypeSettings] = useState<Array<DocumentTypeSetting>>(
    mapPartnerDocumentTypes(partner.documentTypes),
  );

  const { data: currentPartner } = useCurrentPartner();
  const { mutate, isLoading } = useCreateTradeRequest();

  const onSubmit = async (submission: NewTradeRequestForm) => {
    setNonInputErrors([]);

    if (!currentPartner) {
      return;
    }

    const relationships = createDocumentTypesPayload(
      currentPartner.id,
      partner.id,
      documentTypeSettings.filter((setting) => setting.outgoing || setting.incoming),
    );

    if (!relationships.length) {
      setNonInputErrors([t('partners:request.noDocumentTypesSelected')]);
      return;
    }
    mutate(
      {
        tradeRequests: [
          {
            partnerId: partner.id,
            message: submission.message,
            partyIdentifier: submission.partyIdentifier,
            partyIdentifierDomain: submission.partyIdentifierDomain,
            relationships,
            includeAsCCRecipient: submission.includeAsCCRecipient,
          },
        ],
      },
      {
        onSuccess: () => {
          onClose();
          addNotification(t('partners:request.newTradeRequest.success'));
        },
        onError: (err) => {
          processSubmissionErrors({
            error: err,
            setInputError: setError,
            setNonInputErrors,
            defaultData: defaultData(connected),
            t,
          });
        },
      },
    );
  };

  const onClose = () => {
    reset();
    setShowPopup();
    setNonInputErrors([]);
    setDocumentTypeSettings(mapPartnerDocumentTypes(partner.documentTypes));
  };

  const PrimaryButton = () => (
    <Button loading={isLoading} type="submit" form="newTradeRequestForm" analyticsId="partners:new_trade_request_send">
      <div data-testid="action-button">{t('partners:request.newTradeRequest.primaryButton')}</div>
    </Button>
  );
  const CancelButton = () => (
    <Button
      disabled={isLoading}
      onClick={onBack ?? onClose}
      variant="secondary"
      analyticsId="partners:new_trade_request_cancel"
    >
      <div data-testid="cancel-button">{t('common:cancel')}</div>
    </Button>
  );

  if (!partner) {
    return null;
  }

  return (
    <SlideOver
      size="large"
      onBack={onBack}
      footer={
        <>
          <CancelButton />
          <PrimaryButton />
        </>
      }
      headerImage={<PartnerLogo imageUrl={partner.logoUrl} size="large" />}
      title={partner.name}
      subtitle={
        connected ? t('partners:request.updateTradeRequest.heading') : t('partners:request.newTradeRequest.heading')
      }
      open={showPopup}
      setOpen={onClose}
    >
      <div className={classNames(nonInputErrors.length ? 'mb-6' : undefined)}>
        <ValidationErrors errors={nonInputErrors} />
      </div>

      <FormProvider {...formMethods}>
        <Form
          id="newTradeRequestForm"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 divide-y divide-gray-200 [&>*:first-child]:pt-0 [&>*]:pt-6"
        >
          {connected ? null : <PartnerIdentifiers />}
          <DocumentSettingsCheckboxes
            documentTypeSettings={documentTypeSettings}
            setDocumentTypeSettings={setDocumentTypeSettings}
          />

          <div className="space-y-6">
            <TradeRequestMessage />

            <CheckboxInABox
              label={t('partners:request.includeAsCCRecipient.label')}
              description={t('partners:request.includeAsCCRecipient.description')}
              {...register('includeAsCCRecipient')}
            />
          </div>
        </Form>
      </FormProvider>
    </SlideOver>
  );
}

export const convertToPartnerV2ToRequiredPartnerDTO = (
  partner: PartnerV2,
): Pick<PartnerDTO, 'id' | 'name' | 'logoUrl' | 'documentTypes'> => {
  return {
    id: partner.id,
    name: partner.name,
    logoUrl: partner.logo_url || undefined,
    documentTypes: {
      receives:
        partner.receiver_relationships?.map((relationship) => ({
          type: relationship.message_type,
          connected: true,
        })) || [],
      sends:
        partner.sender_relationships?.map((relationship) => ({
          type: relationship.message_type,
          connected: true,
        })) || [],
    },
  };
};
