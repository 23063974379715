import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { t } from 'i18next';
import {
  MessageV2DTO,
  PartnerDTO,
  ReceiverEnvelopeState,
  TransactionDTO,
  TransactionState,
  ValueOf,
} from 'support/types';
import { isMessageV2DTO } from '../../support/helpers/transactions/transactions';

const getColourAndText = (
  state: ValueOf<TransactionState> | ReceiverEnvelopeState.manuallyDelivered,
): { colour: BadgeColor; text: string; tooltip: string } => {
  switch (state) {
    case TransactionState.dropped:
      return {
        colour: BadgeColor.gray,
        text: t('transactions:show.sections.states.simple.dropped'),
        tooltip: t('transactions:show.sections.states.simple.droppedTooltip'),
      };
    case TransactionState.failed:
      return {
        colour: BadgeColor.red,
        text: t('transactions:show.sections.states.simple.failed'),
        tooltip: t('transactions:show.sections.states.simple.failedTooltip'),
      };
    case TransactionState.success:
      return {
        colour: BadgeColor.green,
        text: t('transactions:show.sections.states.simple.delivered'),
        tooltip: t('transactions:show.sections.states.simple.deliveredTooltip'),
      };
    case ReceiverEnvelopeState.manuallyDelivered:
      return {
        colour: BadgeColor.green,
        text: t('transactions:show.sections.states.simple.manuallyDelivered'),
        tooltip: t('transactions:show.sections.states.simple.manuallyDeliveredTooltip'),
      };
    case TransactionState.pending:
      return {
        colour: BadgeColor.yellow,
        text: t('transactions:show.sections.states.simple.inProgress'),
        tooltip: t('transactions:show.sections.states.simple.inProgressTooltip'),
      };
    case TransactionState.unknown:
    default:
      return {
        colour: BadgeColor.yellow,
        text: t('transactions:show.sections.states.simple.unknown'),
        tooltip: '',
      };
  }
};

const deriveState = (
  state: ValueOf<TransactionState>,
  transaction: TransactionDTO | MessageV2DTO,
  currentPartner: PartnerDTO,
) => {
  const receiverPartnerId = isMessageV2DTO(transaction) ? transaction.receiver_partner?.id : transaction.receiver?.id;
  const receiverEnvelopeState = isMessageV2DTO(transaction)
    ? transaction.receiver_envelope?.state
    : transaction.internalState;

  if (currentPartner.id !== receiverPartnerId || state !== TransactionState.success) {
    return state;
  }

  return receiverEnvelopeState === ReceiverEnvelopeState.manuallyDelivered
    ? ReceiverEnvelopeState.manuallyDelivered
    : state;
};

type TransactionStatusDisplayProps = {
  state?: ValueOf<TransactionState> | null | undefined;
  transaction?: MessageV2DTO | TransactionDTO | null;
  currentPartner?: PartnerDTO | null;
};

export function TransactionStatusDisplay({ state, transaction, currentPartner }: TransactionStatusDisplayProps) {
  state = state ?? transaction?.state;

  if (!state) {
    return null;
  }

  const derivedState = transaction && currentPartner ? deriveState(state, transaction, currentPartner) : state;

  const { colour, text, tooltip } = getColourAndText(derivedState);

  return (
    <Badge hasDot color={colour} tooltip={tooltip}>
      {text}
    </Badge>
  );
}
