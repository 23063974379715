import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { readCookie } from 'support/helpers/cookies/cookies';
import { setPotentiallyAuthenticated, useIsPotentiallyAuthenticated } from 'services/auth/auth';
import { AuthRoutes } from 'support/types';

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsPotentiallyAuthenticated();

  useEffect(() => {
    // Check if the user has an XSRF token and set the authentication state accordingly
    const xsrfToken = readCookie('XSRF-TOKEN');
    if (xsrfToken && !isAuthenticated) {
      setPotentiallyAuthenticated(true);
      return;
    }

    if (!isAuthenticated) {
      navigate(AuthRoutes.login, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <>{children}</> : null;
};
