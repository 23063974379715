import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { ReactNode } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type PopupWidth = 'fit-content' | 'sm' | 'md' | 'lg' | 'xl';

const sizeClasses = {
  'fit-content': undefined,
  sm: 'sm:w-full sm:max-w-md',
  md: 'sm:w-full sm:max-w-lg',
  lg: 'sm:w-full sm:max-w-2xl',
  xl: 'sm:w-full sm:max-w-5xl',
};

export const BasePopupV2 = ({
  children,
  open,
  setOpen,
  initialFocus,
  testId,
  width = 'md',
  className,
}: {
  testId?: string;
  initialFocus?: React.RefObject<HTMLElement>;
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  width?: PopupWidth;
  className?: string;
}) => {
  return (
    <Dialog as="div" transition className="relative z-50" open={open} onClose={setOpen} initialFocus={initialFocus}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 duration-300 ease-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex h-full max-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            data-testid={testId}
            transition
            className={classNames(
              'relative overflow-y-visible bg-white text-left shadow-xl sm:my-8 rounded-lg duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
              sizeClasses[width],
              className,
            )}
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
