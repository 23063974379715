import { ExclamationCircleIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { classNames } from 'support/helpers/generic/generic';

export const ErrorIcon = ({ className }: { className?: string }) => {
  return <ExclamationCircleIcon className={classNames('shrink-0 size-5 text-red-700', className)} />;
};

export const LockerIcon = () => {
  return (
    <span className="-m-1 flex size-6 shrink-0 items-center justify-center rounded border border-gray-200 bg-white text-gray-400">
      <LockClosedIcon className="size-4 " />
    </span>
  );
};
