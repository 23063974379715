import { useTranslation } from 'react-i18next';
import { Page } from 'components/Page/Page';
import { useNewFilters } from 'hooks/useFilters';
import { useInfiniteConnections } from 'services/repositories/relationships/relationships';
import { ConnectionsRoutes, PartnerV2 } from 'support/types';
import { ConnectionsFilterKey, getDefaultFilterConfig } from './tableConfig';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { DocumentTypeBadges } from './components/DocumentTypeBadges/DocumentTypeBadges';
import { ConnectionsSlideOvers } from './components/ConnectionsSlideOvers/ConnectionsSlideOvers';
import { useDebounce } from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { EllipsisVerticalIcon, PlusIcon, InboxIcon, ListBulletIcon } from '@heroicons/react/20/solid';
import { ActionRequiredBadge } from 'components/ActionRequiredBadge/ActionRequiredBadge';
import { Filters } from 'components/ListTable/Filters/Filters';
import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader, TableItem } from 'components/ListTable/types';
import {
  NewTradeRequest,
  convertToPartnerV2ToRequiredPartnerDTO,
} from 'pages/TradePartners/NewTradeRequest/NewTradeRequest';
import { routeToPage, routeToTransactionsPage, routeToPricingConfigPage } from 'support/helpers/navigation/navigation';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';
import { TradePartnerNameAndLogo, TradePartnerNameAndLogoClamping } from 'components/TradePartnerNameAndLogo';
import { Alert } from 'components/Display/Alert/Alert';
import { useConnectionsStats } from 'services/repositories/stats/stats';

const ActionsRequiredBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: connectionsCountData } = useConnectionsStats({
    variables: {
      query: {
        filterValues: [
          {
            key: ConnectionsFilterKey.actionRequired,
            value: 'true',
          },
          {
            key: ConnectionsFilterKey.deactivated_partners,
            value: 'false',
          },
        ],
      },
    },
    refetchOnMount: 'always', // Force refetching the stats every time the component mounts (e.g. when navigating back to the page)
  });

  const actionRequiredRelationshipsCount = connectionsCountData?.connectionsCount ?? 0;
  if (!actionRequiredRelationshipsCount) {
    return null;
  }

  return (
    <Alert
      className="mb-4"
      severity="info"
      withoutIcon
      title={t('connections:list.alert.hasActionRequiredRelationships.title', {
        count: actionRequiredRelationshipsCount,
      })}
      actions={{
        primary: {
          variant: 'secondary',
          children: t('connections:list.alert.hasActionRequiredRelationships.action'),
          onClick: () =>
            navigate(
              routeToPage(ConnectionsRoutes.root, undefined, {
                [ConnectionsFilterKey.actionRequired]: 'true',
                [ConnectionsFilterKey.deactivated_partners]: 'false',
              }),
            ),
          analyticsId: 'connections:action_required_banner_cta',
        },
      }}
    >
      {t('connections:list.alert.hasActionRequiredRelationships.description')}
    </Alert>
  );
};

export function Connections() {
  const { t } = useTranslation();
  const { enabled: priceCatalogsEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS);
  const navigate = useNavigate();
  const { partnerId, relationshipId } = useParams();
  const defaultConfig = useMemo(() => getDefaultFilterConfig(), []);
  const { filterValues, filters, perPage, updateFilters, clearAllFilters } = useNewFilters(
    defaultConfig,
    'connections',
  );
  const hasFilters = filterValues && filterValues.length > 0;
  const debouncedFilterValues = useDebounce(filterValues, 150);
  const differentFilters = !isEqual(debouncedFilterValues, filterValues);

  const {
    data: relationshipsData,
    isLoading: relationshipsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasPreviousPage,
    hasNextPage,
  } = useInfiniteConnections({
    variables: {
      query: {
        filterValues: debouncedFilterValues,
        perPage,
      },
    },
  });

  const { data: currentPartner, isLoading: currentPartnerLoading } = useCurrentPartner();

  const tableHeaders: Array<TableHeader> = [
    { label: t('connections:table.fields.tradePartner'), key: 'trade-partner' },
    { label: t('connections:table.fields.documentTypes'), key: 'document-types' },
    { label: '', key: 'action-required' },
    { label: '', key: 'actions' },
  ];

  const buildRow = (partner: PartnerV2): Array<TableItem> => {
    const actionRequired =
      partner.receiver_relationships?.some((relationship) => relationship.action_required) ||
      partner.sender_relationships?.some((relationship) => relationship.action_required);

    return [
      {
        element: <TradePartnerNameAndLogo partner={partner} clamping={TradePartnerNameAndLogoClamping.HORIZONTAL} />,
        key: `tp-name-${partner.id}`,
      },
      {
        element: <DocumentTypeBadges currentPartnerId={currentPartner?.id || ''} otherPartner={partner} />,
        key: `documentType-${partner.id}`,
      },
      {
        key: `actionRequired-${partner.id}`,
        element: actionRequired ? <ActionRequiredBadge /> : null,
      },
      {
        element: <PartnerActions partner={partner} priceCatalogsEnabled={priceCatalogsEnabled} />,
        className: 'text-end',
        key: `actions-${partner.id}`,
      },
    ];
  };

  const data = relationshipsData?.pages?.flatMap((page) => page.data);

  const emptyData = data?.length === 0;

  return (
    <Page>
      <Page.Head title={t('common:mainMenu.connections')} description={t('connections:description')}>
        <Filters
          propertyFilters={filters}
          onFiltersChange={updateFilters}
          filterValues={filterValues}
          clearAllFilters={clearAllFilters}
        />
      </Page.Head>

      <Page.Section>
        <ActionsRequiredBanner />
        <ListTable<PartnerV2>
          headers={tableHeaders}
          data={data}
          isLoading={relationshipsLoading || currentPartnerLoading || (differentFilters && emptyData)}
          rowBuilder={buildRow}
          onRowClick={(partner) => navigate(routeToPage(ConnectionsRoutes.partnerDetails, { partnerId: partner.id }))}
          pagination={
            <InfiniteScroll
              hasMore={Boolean(hasNextPage)}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
              noMoreItemsEnabled={Boolean(hasPreviousPage)}
            />
          }
          emptyState={
            <ListTable.EmptyState
              background="gray"
              cols={tableHeaders.length}
              cta={
                hasFilters
                  ? {
                      children: t('common:table.withFilters.emptyState.cta'),
                      onClick: clearAllFilters,
                      analyticsId: 'connections:empty_state_cta',
                    }
                  : undefined
              }
            >
              {hasFilters ? t('common:table.withFilters.emptyState.title') : t('connections:list.emptyState')}
            </ListTable.EmptyState>
          }
        />
      </Page.Section>

      <ConnectionsSlideOvers
        partnerId={partnerId}
        relationshipId={relationshipId}
        currentPartnerId={currentPartner?.id}
      />
    </Page>
  );
}

const PartnerActions = ({ partner, priceCatalogsEnabled }: { partner: PartnerV2; priceCatalogsEnabled: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  return (
    <div
      className="flex w-full justify-end"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <MenuButton
        iconOnly
        Icon={EllipsisVerticalIcon}
        variant="secondary"
        analyticsId="connections:partner_secondary_options"
      >
        <MenuButtonOption
          Icon={PlusIcon}
          onClick={() => {
            setShowPopup(true);
          }}
          analyticsId="connections:partner_secondary_options_add_trade_request"
        >
          {t('partners:actions.item.addDocumentTypes')}
        </MenuButtonOption>
        <MenuButtonOption
          newGroup
          Icon={InboxIcon}
          onClick={() => {
            navigate(routeToTransactionsPage({ partner_id: partner.id }));
          }}
          analyticsId="connections:partner_secondary_options_transactions_navigate"
        >
          {t('partners:actions.item.transactions')}
        </MenuButtonOption>
        {!priceCatalogsEnabled && (
          <MenuButtonOption
            Icon={ListBulletIcon}
            onClick={() => {
              navigate(routeToPricingConfigPage({ partner_id: partner.id }));
            }}
            analyticsId="connections:partner_secondary_options_price_lists_navigate"
          >
            {t('partners:actions.item.priceLists')}
          </MenuButtonOption>
        )}
      </MenuButton>

      <NewTradeRequest
        showPopup={showPopup}
        onClose={() => setShowPopup(false)}
        partner={convertToPartnerV2ToRequiredPartnerDTO(partner)}
        connected
      />
    </div>
  );
};
