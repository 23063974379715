import { ReactNode, FC } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type WebEDIHeaderConfig = {
  title: string;
  description?: string;
  spacing: 'small' | 'medium';
  action?: ReactNode;
};

type WebEDISectionProps = {
  children: ReactNode;
  header: WebEDIHeaderConfig;
  collapsed?: boolean;
};
export const WebEDISection: FC<WebEDISectionProps> = ({ children, header, collapsed }) => {
  return (
    <section aria-label={header.title || header.description} className="relative">
      <div
        className={classNames(
          collapsed ? 'hidden' : undefined,
          header.spacing === 'small' ? 'mb-4' : 'mb-6',
          'text-xs flex flex-col gap-2',
        )}
      >
        <div className="relative flex items-center justify-between">
          {header.action ? (
            <div aria-hidden="true" className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
          ) : null}
          {header.title ? (
            <h1 className="relative bg-white pr-3 font-medium uppercase tracking-wider text-gray-600">
              {header.title}
            </h1>
          ) : null}
          {header.action ? <div className="relative bg-white">{header.action}</div> : null}
        </div>
        {header.description ? <p className="text-sm text-gray-500">{header.description}</p> : null}
      </div>
      <div>{children}</div>
    </section>
  );
};
