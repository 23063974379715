import { DisplayModes } from 'components/DataTable/Types';
import { MessageErrorBanner } from 'pages/Transactions/MessageErrorBanner/MessageErrorBanner';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { logError } from 'services/logging/logging';
import { SenderEnvelopeDTO } from 'support/types';

type SenderEnvelopeErrorBannerProps = {
  senderEnvelope: Pick<SenderEnvelopeDTO, 'id' | 'blockingError'>;
};
export const SenderEnvelopeErrorBanner: FC<SenderEnvelopeErrorBannerProps> = ({ senderEnvelope }) => {
  const { t } = useTranslation();
  const blockingError = senderEnvelope.blockingError;
  useEffect(() => {
    if (!blockingError || !blockingError.title) {
      logError(`SenderEnvelopeErrorBanner: Blocking error found. Sender envelope: ${senderEnvelope.id}`);
    }
  }, [blockingError, senderEnvelope]);

  if (!blockingError) {
    return null;
  }

  return (
    <MessageErrorBanner
      mode={DisplayModes.view}
      blockingError={{
        ...blockingError,
        title: blockingError.title || t('transactions:show.senderEnvelope.defaultError.title'),
        description: blockingError.description || t('transactions:show.senderEnvelope.defaultError.description'),
      }}
    />
  );
};
