type InputLabelProps = {
  label?: string;
  required?: boolean;
  htmlFor?: string;
};

export const InputLabel = ({ label, required, htmlFor }: InputLabelProps) => {
  if (!label) {
    return null;
  }

  return (
    <label className="block truncate text-sm text-gray-500" htmlFor={htmlFor}>
      {label}
      {Boolean(required) && <span className="text-procuros-green-600"> *</span>}
    </label>
  );
};
