import { ArrowPathIcon, ChevronLeftIcon, InboxIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateIntegrationConnectivityTest,
  useIntegrationConnectivityTests,
  useIntegrationDeploy,
} from 'services/repositories/integrations/integrations';
import { IntegrationConnectivityTestDTO, IntegrationDTO, ProcessDirection } from 'support/types/dtos';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { useNavigate } from 'react-router-dom';
import { IntegrationConfigurationSteps, IntegrationsRoutes } from 'support/types';
import { IntegrationTestStepDetailsPopup } from './components/IntegrationTestStepDetailsPopup/IntegrationTestStepDetailsPopup';
import { IntegrationTestStepSection } from './components/IntegrationTestStepSection/IntegrationTestStepSection';
import { isConnectivityTestComplete } from './helpers';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { routeToPage } from 'support/helpers/navigation/navigation';

type IntegrationTestStepProps = {
  rightColumn: React.ReactNode;
  integration: Pick<IntegrationDTO, 'id'>;
};

const REFETCH_INTERVAL = 10000; //10 Seconds
export const IntegrationTestStep = ({ rightColumn, integration }: IntegrationTestStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  const [itemDetails, setItemDetails] = useState<IntegrationConnectivityTestDTO>();
  const [itemDetailsPopupOpen, setItemDetailsPopupOpen] = useState<boolean>(false);
  const [deployErrors, setDeployErrors] = useState<Array<string>>([]);

  const {
    data: connectivityTests,
    isInitialLoading,
    isFetching: isFetchingConnectivityTests,
    refetch,
  } = useIntegrationConnectivityTests({
    variables: { id: integration.id },
    refetchInterval: REFETCH_INTERVAL,
  });

  const { mutate: triggerTest, isLoading: isTesting } = useCreateIntegrationConnectivityTest();
  const { mutate: mutateIntegrationDeploy, isLoading: isDeploying } = useIntegrationDeploy({
    onSuccess: () => {
      addNotification(t('integrations:new.test.deploy.success'), NotificationType.success);
      navigate(routeToPage(IntegrationsRoutes.view, { id: integration.id }));
    },
    onError: (e) => {
      processSubmissionErrors<undefined>({
        error: e,
        setNonInputErrors: setDeployErrors,
        t,
        setInputError: undefined,
        defaultData: undefined,
      });
    },
  });

  const deployIntegration = async ({ id }: { id: string }) => {
    setDeployErrors([]);
    const hasCompletedTest =
      isConnectivityTestComplete(connectivityTests?.items, ProcessDirection.INBOUND) &&
      isConnectivityTestComplete(connectivityTests?.items, ProcessDirection.OUTBOUND);

    if (hasCompletedTest) {
      return await mutateIntegrationDeploy({ id });
    }
    setDeployErrors([t('integrations:new.test.deploy.error.description')]);
  };

  const refreshTests = async () => {
    try {
      await refetch({ throwOnError: true });
      addNotification(t('integrations:new.test.refresh.success'));
    } catch (e) {
      addNotification(t('integrations:new.test.refresh.error'), NotificationType.error);
    }
  };

  const actions = {
    INBOUND: (
      <>
        <Button
          size="extra-small"
          variant="secondary"
          LeftIcon={InboxIcon}
          onClick={() => triggerTest({ id: integration.id, data: { type: ProcessDirection.INBOUND } })}
          loading={isTesting}
          analyticsId="integrations:inbound_test_trigger"
        >
          {t('integrations:new.test.actions.receiveTestFile')}
        </Button>
        <Button
          size="extra-small"
          variant="secondary"
          LeftIcon={ArrowPathIcon}
          onClick={refreshTests}
          loading={isFetchingConnectivityTests}
          analyticsId="integrations:inbound_test_refresh"
        >
          {t('common:refresh')}
        </Button>
      </>
    ),
    OUTBOUND: (
      <Button
        size="extra-small"
        variant="secondary"
        LeftIcon={ArrowPathIcon}
        onClick={refreshTests}
        loading={isFetchingConnectivityTests}
        analyticsId="integrations:outbound_test_refresh"
      >
        {t('common:refresh')}
      </Button>
    ),
  };

  const onShowItemDetails = (item: IntegrationConnectivityTestDTO) => {
    setItemDetails(item);
    setItemDetailsPopupOpen(true);
  };

  const onBack = () =>
    navigate(
      routeToPage(IntegrationsRoutes.edit, {
        id: integration.id,
        step: IntegrationConfigurationSteps.CONFIGURATION,
      }),
    );

  return (
    <>
      <Button onClick={onBack} LeftIcon={ChevronLeftIcon} analyticsId="integrations:test_back">
        {t('integrations:new.test.actions.back')}
      </Button>
      <ValidationErrors errors={deployErrors} />
      <div className="space-y-8 divide-y divide-gray-200 *:pt-8 first:*:pt-0">
        <IntegrationTestStepSection
          type={ProcessDirection.INBOUND}
          data={connectivityTests}
          isLoading={isInitialLoading}
          actions={actions.INBOUND}
          onShowItemDetails={onShowItemDetails}
        />

        <IntegrationTestStepSection
          type={ProcessDirection.OUTBOUND}
          data={connectivityTests}
          isLoading={isInitialLoading}
          actions={actions.OUTBOUND}
          onShowItemDetails={onShowItemDetails}
        />
        {rightColumn}
        <div>
          <Button
            onClick={() => deployIntegration({ id: integration.id })}
            loading={isDeploying}
            disabled={isInitialLoading}
            analyticsId="integrations:deploy"
          >
            {t('integrations:new.test.actions.deploy')}
          </Button>
        </div>
      </div>
      <IntegrationTestStepDetailsPopup
        open={itemDetailsPopupOpen}
        item={itemDetails}
        onClose={() => setItemDetailsPopupOpen(false)}
      />
    </>
  );
};
