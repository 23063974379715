import { useNavigate } from 'react-router-dom';
import { Page } from 'components/Page/Page';
import { PricingConfigForm } from './PricingConfigForm/PricingConfigForm';
import { usePriceConfigsPartners } from 'hooks/usePriceConfigsPartners/usePriceConfigsPartners';
import { removeUploadedPriceConfig, usePriceConfigStore } from 'stores/priceConfigs/priceConfigs';

export function NewPricingConfigsPage() {
  const navigate = useNavigate();
  const { uploadedPriceConfig } = usePriceConfigStore();
  const onCancelOrSuccess = () => {
    removeUploadedPriceConfig();
    navigate(-1);
  };
  const { data: partnersWithInvoices, isLoading: connectedPartnersLoading } = usePriceConfigsPartners();

  return (
    <Page isLoading={connectedPartnersLoading}>
      <PricingConfigForm
        connectedPartners={partnersWithInvoices}
        pricingConfig={uploadedPriceConfig}
        onCancel={onCancelOrSuccess}
        onSuccess={onCancelOrSuccess}
      />
    </Page>
  );
}
