import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Popup } from 'components/Display/Popup/Popup';
import { Radio } from 'components/Form/Radio/Radio';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateUUID } from 'support/helpers/generic/generic';
import { TemplateDTO } from 'support/types';
import { LocalSchemaDTO } from 'stores/transformations/processTransformation';
import { useEffect } from 'react';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Input } from 'components/Form/Input/Input/Input';

type CreateSchemaModalProps = {
  existingSchemas: Array<LocalSchemaDTO>;
  schemaTemplates: Array<TemplateDTO>;
  open: boolean;
  type: LocalSchemaDTO['type'];
  onSchemaCreate: (schema: LocalSchemaDTO) => void;
  onClose: () => void;
};

enum SchemaSourceType {
  EXISTING = 'EXISTING',
  TEMPLATE = 'TEMPLATE',
  NEW = 'NEW',
}

type CreateSchemaForm = {
  sourceType: SchemaSourceType;
  sourceId?: string;
  newSchemaName?: string;
};

const generateDefaultValues = (existingSchemas: Array<LocalSchemaDTO>, schemaTemplates: Array<TemplateDTO>) => {
  const defaultValues: CreateSchemaForm = {
    sourceType: SchemaSourceType.NEW,
    sourceId: '',
  };

  if (existingSchemas.length > 0) {
    defaultValues.sourceType = SchemaSourceType.EXISTING;
    defaultValues.sourceId = existingSchemas[existingSchemas.length - 1].id;
    defaultValues.newSchemaName = `${existingSchemas[0].name} (copy)`;
    return defaultValues;
  }

  if (schemaTemplates.length > 0) {
    defaultValues.sourceType = SchemaSourceType.TEMPLATE;
    defaultValues.sourceId = schemaTemplates[schemaTemplates.length - 1].id;
    defaultValues.newSchemaName = `${schemaTemplates[0].name} (copy)`;
    return defaultValues;
  }

  return defaultValues;
};

export const CreateSchemaModal = ({
  open,
  existingSchemas,
  schemaTemplates,
  type,
  onClose,
  onSchemaCreate,
}: CreateSchemaModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateSchemaForm>({
    defaultValues: generateDefaultValues(existingSchemas, schemaTemplates),
    resolver: yupResolver<CreateSchemaForm>(newSchemaSchema),
  });

  const [sourceType, sourceId] = useWatch({
    control,
    name: ['sourceType', 'sourceId'],
  });

  // Prefill new schema name when source is selected
  useEffect(() => {
    if (sourceType === SchemaSourceType.EXISTING) {
      const lastExistingSchema = existingSchemas[existingSchemas.length - 1];
      if (!lastExistingSchema) {
        return;
      }
      if (!sourceId) {
        setValue('sourceId', lastExistingSchema.id);
        setValue('newSchemaName', `${lastExistingSchema.name} (copy)`);
        return;
      }

      const existingSchema = existingSchemas.find((schema) => schema.id === sourceId);
      if (existingSchema) {
        setValue('newSchemaName', `${existingSchema.name} (copy)`);
      }
    }

    if (sourceType === SchemaSourceType.TEMPLATE) {
      const lastSchemaTemplate = schemaTemplates[schemaTemplates.length - 1];
      if (!lastSchemaTemplate) {
        return;
      }
      if (!sourceId) {
        setValue('sourceId', lastSchemaTemplate.id);
        setValue('newSchemaName', `${lastSchemaTemplate.name} (copy)`);
        return;
      }

      const templateSchema = schemaTemplates.find((schema) => schema.id === sourceId);
      if (templateSchema) {
        setValue('newSchemaName', `${templateSchema.name} (copy)`);
      }
    }
  }, [sourceType, sourceId, existingSchemas, schemaTemplates, setValue]);

  const PrimaryButton = () => (
    <Button onClick={handleSubmit(submit)} analyticsId="transformation-ui:new-schema-create">
      {t('common:create')}
    </Button>
  );
  const CancelButton = () => (
    <Button variant="secondary" onClick={onClose} analyticsId="transformation-ui:new-schema-cancel">
      {t('common:cancel')}
    </Button>
  );

  const submit = (data: CreateSchemaForm) => {
    const schema: LocalSchemaDTO = {
      name: data.newSchemaName || 'New schema',
      schema: '{"type": "object"}',
      id: `pending-schema-${generateUUID()}`,
      is_draft: true,
      type,
      activated_at: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_dirty: false,
    };

    if (data.sourceType === SchemaSourceType.EXISTING && data.sourceId) {
      const existingSchema = existingSchemas.find((schema) => schema.id === data.sourceId);
      if (existingSchema) {
        schema.schema = existingSchema.schema;
      }
    }

    if (data.sourceType === SchemaSourceType.TEMPLATE && data.sourceId) {
      const templateSchema = schemaTemplates.find((schema) => schema.id === data.sourceId);
      if (templateSchema) {
        schema.schema = templateSchema.content;
      }
    }

    onSchemaCreate(schema);
    onClose();
  };

  return (
    <Popup
      PrimaryButton={PrimaryButton}
      CancelButton={CancelButton}
      title="Create new schema"
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
        <Radio.Group>
          <Radio.Item label="From existing schema" {...register('sourceType')} value={SchemaSourceType.EXISTING} />
          <Radio.Item label="From template" {...register('sourceType')} value={SchemaSourceType.TEMPLATE} />
          <Radio.Item label="New" value={SchemaSourceType.NEW} {...register('sourceType')} />
        </Radio.Group>
        {sourceType === SchemaSourceType.EXISTING && (
          <Controller
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                label="Select existing schemas"
                options={existingSchemas.map((schema) => ({
                  label: schema.name,
                  value: schema.id,
                }))}
                errors={fieldState.error}
              />
            )}
            control={control}
            name="sourceId"
          />
        )}
        {sourceType === SchemaSourceType.TEMPLATE && (
          <Controller
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                label="Select template"
                options={schemaTemplates.map((schema) => ({
                  label: schema.name,
                  value: schema.id,
                }))}
                errors={fieldState.error}
              />
            )}
            control={control}
            name="sourceId"
          />
        )}
        <Input label="New schema name" {...register('newSchemaName')} errors={errors.newSchemaName} />
      </form>
    </Popup>
  );
};

const newSchemaSchema = Yup.object({
  sourceType: Yup.mixed<SchemaSourceType>().oneOf(Object.values(SchemaSourceType)).required(),
  sourceId: Yup.string().when('source', {
    is: (value: string) => value === SchemaSourceType.EXISTING || value === SchemaSourceType.TEMPLATE,
    then: (schema) => schema.required(),
  }),
  newSchemaName: Yup.string().required(),
});
