import { Button } from 'components/Form/Button/Button';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Page } from 'components/Page/Page';
import { useEffect, useMemo, useState } from 'react';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, useUpdateUser } from 'services/repositories/user/user';
import { addNotification } from 'stores/notifications/notifications';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import * as Yup from 'yup';
import { SettingsSidebar } from '../SettingsSidebar/SettingsSidebar';
import { Section } from 'components/Display/Section/Section';
import { Form } from 'components/Form/Form';

const defaultValues = {
  current_password: '',
  password: '',
  password_confirmation: '',
};

type FormState = typeof defaultValues;

export const Password = () => {
  const { t } = useTranslation();
  const { isLoading, data: currentUser } = useCurrentUser();
  const { mutate, isLoading: isMutating } = useUpdateUser();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const formSchema = useMemo(
    () =>
      Yup.object({
        current_password: Yup.string().required(
          t('common:form.required', {
            field: t('settings:password.form.fields.currentPassword.label'),
          }) ?? '',
        ),
        password: Yup.string().required(
          t('common:form.required', {
            field: t('settings:password.form.fields.newPassword.label'),
          }) ?? '',
        ),
        password_confirmation: Yup.string()
          .required(
            t('common:form.required', {
              field: t('settings:password.form.fields.confirmPassword.label'),
            }) ?? '',
          )
          .test('passwords-match', t('settings:password.form.fields.validation.confirmPassword'), function (value) {
            return this.parent.password === value;
          }),
      }),
    [t],
  );

  const resolver = useYupValidationResolver(formSchema);
  const {
    register,
    setError,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    resolver,
    defaultValues,
  });

  useEffect(() => {
    setNonInputErrors([]);
  }, [errors]);

  const onSubmit = (data: FormState) => {
    setNonInputErrors([]);

    const submissionData = {
      ...currentUser!,
      current_password: data.current_password,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    mutate(submissionData, {
      onSuccess: () => {
        addNotification(t('auth:notifications.password.success'));
        reset();
      },
      onError: (err) => {
        processSubmissionErrors({
          error: err,
          setInputError: setError,
          setNonInputErrors,
          defaultData: defaultValues,
          t,
        });
      },
    });
  };

  return (
    <Page isLoading={isLoading}>
      <Page.Head title={t('common:mainMenu.settings')} />

      <Page.Section leftAside={<SettingsSidebar />}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          id="newPassword"
          className="w-full divide-y divide-gray-200 *:py-6 first:*:pt-0 last:*:pb-0"
        >
          <ValidationErrors errors={nonInputErrors} />

          <Section.Horizontal title={t('settings:sidebar.password')} description={t('settings:password.intro')}>
            <Input
              {...register('current_password')}
              label={t('settings:password.form.fields.currentPassword.label')}
              errors={errors.current_password}
              required
              type="password"
              autoComplete="current-password"
            />

            <Input
              {...register('password')}
              label={t('settings:password.form.fields.newPassword.label')}
              errors={errors.password}
              hasError={Boolean(errors.password_confirmation || errors.password)}
              type="password"
              required
              autoComplete="new-password"
            />

            <Input
              {...register('password_confirmation')}
              label={t('settings:password.form.fields.confirmPassword.label')}
              errors={errors.password_confirmation}
              type="password"
              required
              autoComplete="new-password"
            />
          </Section.Horizontal>
          <div>
            <Button
              onClick={() => reset()}
              disabled={isMutating}
              variant="secondary"
              className="mr-3"
              analyticsId="account:new_password_cancel"
            >
              {t('common:cancel')}
            </Button>

            <Button type="submit" loading={isMutating} form="newPassword" analyticsId="account:new_password_save">
              {t('common:save')}
            </Button>
          </div>
        </Form>
      </Page.Section>
    </Page>
  );
};
