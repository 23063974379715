import { Button } from 'components/Form/Button/Button';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { addHostToUrl, downloadFile } from 'services/http/http';
import { useTranslation } from 'react-i18next';
import { AS2ConfigurationDTO, IntegrationDTO } from 'support/types/dtos';
import { Section } from 'components/Display/Section/Section';
import { Translate } from 'components/Translate/Translate';
import { ENV } from 'services/environment/environment';

const downloadAS2Certificates = () => {
  downloadFile(addHostToUrl('/assets/procuros-as2-certificate.zip'));
};

const generateAS2ServerUrl = (integration: Pick<IntegrationDTO, 'handle'>) => {
  return `${ENV.AS2_BASE_URL}${integration.handle}`;
};

type AS2ServerInformationProps = {
  integration: Partial<Pick<IntegrationDTO, 'handle' | 'configuration'>> | undefined;
};

export const AS2ServerInformation = ({ integration }: AS2ServerInformationProps) => {
  const { t } = useTranslation();
  const configuration = integration?.configuration as Partial<AS2ConfigurationDTO> | undefined;

  return (
    <>
      <Section.Horizontal
        title={t('integrations:new.as2.side.section.as2Server.title')}
        description={t('integrations:new.as2.side.section.as2Server.description')}
      >
        <Section.Item title={t('integrations:new.as2.side.signature.title')}>
          {t('integrations:new.as2.side.signature.content', {
            algorithm: t(
              `integrations:new.as2.form.field.signingAlgorithm.options.${configuration?.algorithm?.signing?.toLowerCase()}`,
            ),
          })}
        </Section.Item>
        <Section.Item title={t('integrations:new.as2.side.encryption.title')}>
          {t('integrations:new.as2.side.encryption.content')}
        </Section.Item>
        <Section.Item title={t('integrations:new.as2.side.mdn.title')}>
          {configuration?.settings?.receive.mdn.type === 'ASYNCHRONOUS'
            ? t('integrations:new.as2.side.mdn.content.async')
            : t('integrations:new.as2.side.mdn.content.sync')}
        </Section.Item>
        {configuration?.settings?.receive.mdn.type === 'ASYNCHRONOUS' ? (
          <Section.Item title={t('integrations:new.as2.side.mdnUrl.title')}>{ENV.AS2_MDN_RECEIVE_URL}</Section.Item>
        ) : null}

        <Section.Item title={t('integrations:new.as2.side.as2Id.title')}>{ENV.AS2_PROCUROS_ID}</Section.Item>
        <Section.Item title={t('integrations:new.as2.side.serverUrl.title')}>
          {integration?.handle
            ? generateAS2ServerUrl(integration as Pick<IntegrationDTO, 'handle'>)
            : t('integrations:new.as2.side.serverUrl.content')}
        </Section.Item>
      </Section.Horizontal>
      <Section.Horizontal
        title={t('integrations:new.as2.side.section.as2Certificate.title')}
        description={t('integrations:new.as2.side.section.as2Certificate.description')}
      >
        <Section.Item title={t('integrations:new.as2.side.fingerprint.title')}>
          {t('integrations:new.as2.side.fingerprint.content')}
        </Section.Item>
        <Section.Item>
          <Button
            variant="secondary"
            size="extra-small"
            LeftIcon={ArrowDownTrayIcon}
            onClick={downloadAS2Certificates}
            analyticsId="integrations:certificate-download"
          >
            {t('integrations:new.as2.side.downloadCertificate.content')}
          </Button>
        </Section.Item>
      </Section.Horizontal>
      <Section.Horizontal
        title={t('integrations:new.as2.side.section.ipsAndFirewalls.title')}
        description={t('integrations:new.as2.side.section.ipsAndFirewalls.description')}
      >
        <Section.Item>
          <Translate i18nKey={'integrations:new.as2.side.ipsAndFirewalls.content'} />
        </Section.Item>
      </Section.Horizontal>
    </>
  );
};
