export enum AuthRoutes {
  login = '/login',
  register = '/register',
  logout = '/logout',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password/:token',
  createAccount = '/create-account/:token',
  expiredLink = '/magic-link-expired',
}

export enum DashboardRoutes {
  root = '/',
  legacyRoot = '/dashboard',
}

export enum InboxRoutes {
  root = '/inbox',
}

export enum NetworkRoutes {
  root = '/network',
  deprecatedRoot = '/trade-partners',
}

export enum PartnerSearchRoutes {
  root = '/partner-search',
}

export enum PricingConfigRoutes {
  root = '/price-lists',
  view = '/price-lists/:partnerId/:id',
  new = '/price-lists/new',
}

export enum PriceCatalogRoutes {
  root = '/price-catalogs',
  relationshipDetails = '/price-catalogs/:relationshipId',
}

export enum TransactionsRoutes {
  root = '/transactions',
  errors = '/transactions?filter[transaction_state]=FAILED&filter[transaction_flow]=LIVE',
  viewMessage = '/transactions/message/:id',
  viewSenderEnvelope = '/transactions/sender-envelope/:id',
  createStandalone = '/transactions/relationship/:relationshipId/create/:messageType',
  create = '/transactions/message/:messageId/create/:messageType',
  edit = '/transactions/message/:id/edit',
  blocked = '/transactions/blocked',
  expired = '/transactions/expired',
}

export enum ConnectionsRoutes {
  root = '/connections',
  partnerDetails = '/connections/partner/:partnerId',
  relationshipDetails = '/connections/relationship/:relationshipId',
  testRelationship = '/connections/relationship/:relationshipId/test',
  testRelationshipMessageDetails = '/connections/relationship/:relationshipId/test/message/:messageId',
  testRelationshipTransactionError = '/connections/relationship/:relationshipId/test/transaction/:transactionId/error',
}

export enum SettingsRoutes {
  root = '/settings',
  companyData = '/settings/company-data',
  teamMembers = '/settings/team-members',
  profileAndPreferences = '/settings/profile-preferences',
  password = '/settings/password',
}

export enum IntegrationsRoutes {
  root = '/integrations',
  new = '/integrations/new',
  create = '/integrations/new/:type/:step?',
  view = '/integrations/:id',
  edit = '/integrations/:id/edit/:step',
}
export enum IntegrationConfigurationSteps {
  INITIAL_INFORMATION = 'INITIAL_INFORMATION',
  CONFIGURATION = 'CONFIGURATION',
  TEST = 'TEST',
  TOUR = 'TOUR',
}

export enum IntegrationProcessRoutes {
  root = IntegrationsRoutes.view + '/processes',
  new = IntegrationsRoutes.view + '/processes/new',
  edit = IntegrationsRoutes.view + '/processes/:processId/edit/:step',
  transformation = '/internal' + IntegrationsRoutes.view + '/processes/:processId/transformation',
}
export enum IntegrationProcessConfigurationSteps {
  CONFIGURATION = 'CONFIGURATION',
  TEST = 'TEST',
}
