import classNames from 'classnames';
import { Severity } from './types';
import { ReactNode } from 'react';

export const AlertSection = ({
  title,
  children,
  severity,
}: {
  title: string | null;
  children?: ReactNode;
  severity: Severity;
}) => {
  return (
    <div>
      {title && <h4 className={classNames('font-semibold', titleClasses[severity])}>{title}</h4>}
      {children ? <p className="text-gray-900">{children}</p> : null}
    </div>
  );
};

const titleClasses: Record<Severity, string> = {
  error: 'text-red-700',
  warning: 'text-warning-700',
  info: 'text-info-700',
  success: 'text-success-700',
  neutral: 'text-neutral-700',
};
