import { BadgeColor, Badge } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { IntegrationProcessTestDTO } from 'support/types';

export const IntegrationProcessTestList = ({
  data,
  isLoading,
  onShowItemDetails,
}: {
  data: Array<IntegrationProcessTestDTO> | undefined;
  isLoading: boolean;
  onShowItemDetails: (item: IntegrationProcessTestDTO) => void;
}) => {
  const { t } = useTranslation();
  const tableHeaders: Array<TableHeader> = [
    { label: t('integrations:processes.new.test.table.header.date'), key: 'date' },
    { label: t('integrations:processes.new.test.table.header.status'), key: 'status' },
    { label: '', key: 'actions' },
  ];

  const rowBuilder = useCallback(
    (item: IntegrationProcessTestDTO) => [
      {
        element: <div className="text-sm text-gray-500">{formatDayAndTime(item.createdAt)}</div>,
        key: `date-${item.id}`,
      },
      {
        /*
          t('integrations:processTest.status.SUCCESSFUL')
          t('integrations:processTest.status.IN_PROGRESS')
          t('integrations:processTest.status.FAILED')
          t('integrations:processTest.status.INVALID')
          */
        element: (
          <div>
            <Badge color={getBadgeColor(item.status)}>{t(`integrations:processTest.status.${item.status}`)}</Badge>
          </div>
        ),
        key: `status-${item.id}`,
      },
      {
        element: (
          <div className="flex justify-end">
            {['FAILED', 'INVALID'].includes(item.status) ? (
              <Button
                size="extra-small"
                variant="minimal-emphasized"
                onClick={() => onShowItemDetails(item)}
                analyticsId="integrations:process-test-details"
              >
                {t('integrations:processes.new.test.actions.details')}
              </Button>
            ) : null}
          </div>
        ),
        key: `actions-${item.id}`,
      },
    ],
    [onShowItemDetails, t],
  );

  return (
    <ListTable<IntegrationProcessTestDTO>
      isLoading={isLoading}
      data={convertArrayToPaginatedDTO(data)}
      headers={tableHeaders}
      rowBuilder={rowBuilder}
      emptyState={
        <ListTable.EmptyState cols={tableHeaders.length - 1}>
          {t('integrations:processes.new.test.edifact.list.emptyState')}
        </ListTable.EmptyState>
      }
    />
  );
};

const convertArrayToPaginatedDTO = (
  data: Array<IntegrationProcessTestDTO> | undefined,
): Array<IntegrationProcessTestDTO> => {
  return (
    data?.sort(
      ({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
        new Date(createdAtB).getTime() - new Date(createdAtA).getTime(),
    ) || []
  );
};

const getBadgeColor = (status: IntegrationProcessTestDTO['status']): BadgeColor => {
  switch (status) {
    case 'SUCCESSFUL':
      return BadgeColor.green;
    case 'IN_PROGRESS':
      return BadgeColor.yellow;
    case 'FAILED':
    case 'INVALID':
      return BadgeColor.red;
    default:
      return BadgeColor.gray;
  }
};
