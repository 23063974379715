import type { HeaderContext } from '@tanstack/react-table';
import type { ReactNode } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { InformationCircleIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { HeaderMenu } from '../components/HeaderMenu';
import { DisplayModes } from '../Types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export type Props<TData, TValue> = HeaderContext<TData, TValue> & {
  children: ReactNode;
  required?: boolean;
  tooltip?: ReactNode;
  mode?: keyof typeof DisplayModes;
  selectableRows?: boolean;
  actions?: Parameters<typeof HeaderMenu>[0]['actions'];
};

export const HeaderCell = <TData, TValue>({
  column: {
    columnDef: { meta },
  },
  children,
  required,
  tooltip,
  mode = DisplayModes.view,
  selectableRows,
  actions,
}: Props<TData, TValue>) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex items-center', { 'justify-end': meta?.optionalConfig?.textAlign === 'right' })}>
      <div className="flex px-3 py-1.5">
        {children}
        {Boolean(required) && mode !== DisplayModes.view && <span className="ml-1 text-procuros-green-500">*</span>}
        {Boolean(tooltip) && (
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon className="ml-1 size-4 text-gray-400 hover:text-procuros-green-500" />
            </TooltipTrigger>
            <TooltipContent>{tooltip}</TooltipContent>
          </Tooltip>
        )}
      </div>

      {(mode === DisplayModes.create || mode === DisplayModes.edit) && (
        <div className="ml-auto flex">
          {meta?.readOnly ? (
            <Tooltip>
              <TooltipTrigger>
                <LockClosedIcon className="mr-2 size-4 text-gray-400 hover:text-procuros-green-500" />
              </TooltipTrigger>
              <TooltipContent>{t('webedi:header_cell.read_only.tooltip')}</TooltipContent>
            </Tooltip>
          ) : (
            selectableRows && <HeaderMenu actions={actions} />
          )}
        </div>
      )}
    </div>
  );
};
