import {
  Dialog as HeadlessUIDialog,
  DialogProps as HeadlessUIDialogProps,
  DialogPanel,
  DialogTitle,
  Description,
} from '@headlessui/react';
import { ElementType, forwardRef, useEffect, useId } from 'react';
import { useDialogStore } from 'stores/dialog/dialog';

const ProcurosDialog = forwardRef<any, any>(
  (props: Omit<HeadlessUIDialogProps<ElementType>, 'static' | 'unmount' | 'open'>, ref) => {
    const { addDialog, removeDialog, dialogs } = useDialogStore();
    const id = useId();

    useEffect(() => {
      addDialog(id);
      return () => {
        removeDialog(id);
      };
    }, [addDialog, removeDialog, id]);

    const onClose = (value: boolean) => {
      if (dialogs.at(-1) === id) {
        removeDialog(id);
        props.onClose(value);
      }
    };
    return <HeadlessUIDialog {...props} ref={ref} onClose={onClose} />;
  },
);

ProcurosDialog.displayName = 'ProcurosDialog';

export const Dialog = Object.assign(ProcurosDialog, {
  Panel: DialogPanel,
  Title: DialogTitle,
  Description: Description,
});
