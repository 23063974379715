import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Section } from 'components/Display/Section/Section';
import { Button } from 'components/Form/Button/Button';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationConnectivityTestDTO, PaginationDTO, ProcessDirection } from 'support/types/dtos';
import { getConnectivitiTestBadgeColor } from './helpers';
import { isConnectivityTestComplete } from '../../helpers';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';

type IntegrationTestStepSectionProps = {
  data: PaginationDTO<IntegrationConnectivityTestDTO> | undefined;
  isLoading: boolean;
  type: ProcessDirection;
  actions: ReactNode;
  onShowItemDetails: (item: IntegrationConnectivityTestDTO) => void;
};

export const IntegrationTestStepSection = ({
  data,
  isLoading,
  type,
  actions,
  onShowItemDetails,
}: IntegrationTestStepSectionProps) => {
  const { t } = useTranslation();
  const tableHeaders: Array<TableHeader> = [
    { label: t('integrations:new.test.table.header.date'), key: 'date' },
    { label: t('integrations:new.test.table.header.status'), key: 'status' },
    { label: '', key: 'actions' },
  ];

  const rowBuilder = useCallback(
    (item: IntegrationConnectivityTestDTO) => [
      {
        element: <div className="text-sm text-gray-500">{formatDayAndTime(item.createdAt)}</div>,
        key: `date-${item.id}`,
      },
      {
        /*
        t('integrations:connectivityTest.status.SUCCESSFUL')
        t('integrations:connectivityTest.status.IN_PROGRESS')
        t('integrations:connectivityTest.status.FAILED')
        */
        element: (
          <div>
            <Badge color={getConnectivitiTestBadgeColor(item.status)}>
              {t(`integrations:connectivityTest.status.${item.status}`)}
            </Badge>
          </div>
        ),
        key: `status-${item.id}`,
      },
      {
        element: (
          <div className="flex justify-end">
            {['FAILED'].includes(item.status) ? (
              <Button
                size="extra-small"
                variant="minimal-emphasized"
                onClick={() => onShowItemDetails(item)}
                analyticsId="integration:test-show-details"
              >
                {t('integrations:new.test.actions.details')}
              </Button>
            ) : null}
          </div>
        ),
        key: `actions-${item.id}`,
      },
    ],
    [t, onShowItemDetails],
  );

  /*
    t('integrations:new.test.outbound.title')
    t('integrations:new.test.outbound.description')
    t('integrations:new.test.outbound.emptyState.title')
    t('integrations:new.test.inbound.title')
    t('integrations:new.test.inbound.description')
    t('integrations:new.test.inbound.emptyState.title')
  */

  const typeForTranslations = type.toLowerCase();
  return (
    <Section.Horizontal
      title={t(`integrations:new.test.${typeForTranslations}.title`)}
      description={t(`integrations:new.test.${typeForTranslations}.description`)}
    >
      <div className="flex items-center justify-between">
        <IntegrationTestStepBadge completed={isConnectivityTestComplete(data?.items ?? [], type)} />
        <div className="flex gap-2">{actions}</div>
      </div>
      <ListTable<IntegrationConnectivityTestDTO>
        isLoading={isLoading}
        data={getConnectivityTestsByType(data, type)}
        headers={tableHeaders}
        rowBuilder={rowBuilder}
        emptyState={
          <ListTable.EmptyState cols={tableHeaders.length - 1}>
            {t(`integrations:new.test.${typeForTranslations}.emptyState.title`)}
          </ListTable.EmptyState>
        }
      />
    </Section.Horizontal>
  );
};

const IntegrationTestStepBadge = ({ completed }: { completed: boolean }) => {
  const { t } = useTranslation();

  const badgeProps = {
    true: {
      color: BadgeColor.green,
      children: t('integrations:new.test.header.status.complete'),
    },
    false: {
      color: BadgeColor.red,
      children: t('integrations:new.test.header.status.incomplete'),
    },
  };

  return <Badge {...badgeProps[String(completed) as keyof typeof badgeProps]} type="indicator" />;
};

const getConnectivityTestsByType = (
  data: PaginationDTO<IntegrationConnectivityTestDTO> | undefined,
  type: ProcessDirection,
) => {
  return data
    ? data.items
        .filter((item) => item.type === type)
        ?.sort(
          ({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
            new Date(createdAtB).getTime() - new Date(createdAtA).getTime(),
        )
    : undefined;
};
