import { ArrowRightEndOnRectangleIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/16/solid';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useTranslation } from 'react-i18next';

export const TypeBadge = ({ type }: { type: 'SOURCE' | 'DESTINATION' }) => {
  const { t } = useTranslation();

  const icon =
    type === 'SOURCE' ? (
      <ArrowRightStartOnRectangleIcon data-testid="arrow-down-tray-icon" />
    ) : (
      <ArrowRightEndOnRectangleIcon data-testid="arrow-up-tray-icon" />
    );

  /*
    t('integrations:processes.type.SOURCE')
    t('integrations:processes.type.DESTINATION')
  */
  return (
    <Badge color={BadgeColor.gray} icon={icon}>
      {t(`integrations:processes.type.${type}`)}
    </Badge>
  );
};
