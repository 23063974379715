import { Section } from 'components/Display/Section/Section';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { FileInput } from 'components/Form/FileInput/FileInput';
import { Base64Loader } from 'components/Form/FileInput/Loaders/Base64Loader/Base64Loader';
import { Input } from 'components/Form/Input/Input/Input';
import { Controller, FieldError, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { fileFormatOptions, mdnOptions, messageEncryptionOptions, messageSigningOptions } from './helpers';
import { AS2ConfigurationDTO, IntegrationDTO } from 'support/types';
import { EDIFACTConfiguration } from '../../FileFormats/EDIFACT/EDIFACTForm';
import { AS2ServerInformation } from './AS2ServerInformation';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';

type AS2ConfigurationFormType = { configuration: Partial<AS2ConfigurationDTO>; handle: IntegrationDTO['handle'] };

export const AS2ConfigurationForm = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<AS2ConfigurationFormType>();
  const [configuration, handle] = useWatch<AS2ConfigurationFormType, ['configuration', 'handle']>({
    name: ['configuration', 'handle'],
  });

  const certificate = configuration?.certificate;
  const mdnType = configuration?.settings?.receive?.mdn?.type;
  const fileFormat = configuration?.file_format;

  const certificateExtraInformation = [
    certificate?.fingerprint &&
      t('integrations:new.as2.form.field.certificate.fingerprint', {
        fingerprint: certificate.fingerprint,
      }),
    certificate?.expiry_date &&
      t('integrations:new.as2.form.field.certificate.expiryDate', {
        expiryDate: formatDayAndTime(certificate.expiry_date),
      }),
  ].filter(Boolean) as Array<string>;

  const onFileInputError = () => {
    setValue('configuration.certificate.contents', '');
    addNotification(
      {
        title: t('integrations:new.as2.form.field.certificate.notification.invalid.title'),
        subtitle: t('integrations:new.as2.form.field.certificate.notification.invalid.subtitle'),
      },
      NotificationType.error,
    );
  };

  return (
    <>
      <Section.Horizontal
        title={t('integrations:new.as2.form.title')}
        description={t('integrations:new.as2.form.description')}
      >
        <Input
          {...register('configuration.as2_identity')}
          label={t('integrations:new.as2.form.field.as2Identity.label')}
          errors={errors.configuration?.as2_identity}
        />
        <Input
          {...register('configuration.as2_url')}
          label={t('integrations:new.as2.form.field.as2Url.label')}
          errors={errors.configuration?.as2_url}
        />

        <Controller
          render={({ field }) => (
            <Dropdown
              {...field}
              label={t('integrations:new.as2.form.field.mdnType.label')}
              options={mdnOptions}
              errors={errors.configuration?.settings?.receive?.mdn?.type as FieldError}
            />
          )}
          name="configuration.settings.receive.mdn.type"
        />
        {mdnType === 'ASYNCHRONOUS' ? (
          <Input
            {...register('configuration.settings.receive.mdn.asynchronous_mdn_url')}
            label={t('integrations:new.as2.form.field.asynchronousMdnUrl.label')}
            errors={errors.configuration?.settings?.receive?.mdn?.asynchronous_mdn_url}
          />
        ) : null}

        <Controller
          render={({ field }) => (
            <Dropdown
              {...field}
              label={t('integrations:new.as2.form.field.signingAlgorithm.label')}
              options={messageSigningOptions}
              errors={errors.configuration?.algorithm?.signing}
            />
          )}
          name="configuration.algorithm.signing"
        />

        <Controller
          render={({ field }) => (
            <Dropdown
              {...field}
              label={t('integrations:new.as2.form.field.encryptionAlgorithm.label')}
              options={messageEncryptionOptions}
              errors={errors.configuration?.algorithm?.encryption}
            />
          )}
          name="configuration.algorithm.encryption"
        />

        <Controller
          render={({ field }) => (
            <Dropdown
              {...field}
              label={t('integrations:new.as2.form.field.fileFormat.label')}
              options={fileFormatOptions}
              errors={errors.configuration?.file_format}
            />
          )}
          name="configuration.file_format"
        />

        {fileFormat === 'EDIFACT' && <EDIFACTConfiguration />}

        <FileInput
          label={t('integrations:new.as2.form.field.certificate.label')}
          description={t('integrations:new.as2.form.field.certificate.description')}
          onLoad={(data, filename) =>
            setValue(
              'configuration.certificate',
              { contents: data[0], name: filename },
              { shouldValidate: true, shouldDirty: true },
            )
          }
          onError={onFileInputError}
          onClear={() => setValue('configuration.certificate.contents', '')}
          loader={Base64Loader}
          errors={errors.configuration?.certificate?.contents}
          defaultFilename={certificate?.name ?? undefined}
          fileExtraInformation={certificateExtraInformation}
          accept={{
            'application/x-x509-ca-cert': ['.crt', '.der'],
            'application/pkix-cert': ['.cer'],
            'application/x-pem-file': ['.pem'],
          }}
        />
      </Section.Horizontal>
      <AS2ServerInformation integration={{ configuration, handle }} />
    </>
  );
};
