import { useOrderSummary } from 'components/WebEDI/hooks/useOrderSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from 'support/helpers/dateTime/dateTime';
import { DocumentSummaryContent } from './DocumentSummaryContent';

export const OrderSummary = () => {
  const { t } = useTranslation();
  const orderSummary = useOrderSummary();
  return (
    <DocumentSummaryContent
      items={[
        {
          label: t('webedi:documentSummary.order.totalQuantity.label'),
          value: Math.round(orderSummary.totalQuantity),
        },
        {
          label: t('webedi:documentSummary.order.lineCount.label'),
          value: Math.round(orderSummary.lineCount),
        },
        {
          label: t('webedi:documentSummary.order.deliveryDate.label'),
          value: formatTimestamp(orderSummary.deliveryDate) ?? '—',
        },
      ]}
    />
  );
};
