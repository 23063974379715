import { BadgeColor } from 'components/Display/Badge/Badge';
import { IntegrationConnectivityTestDTO } from 'support/types/dtos';

export const getConnectivitiTestBadgeColor = (status: IntegrationConnectivityTestDTO['status']): BadgeColor => {
  switch (status) {
    case 'SUCCESSFUL':
      return BadgeColor.green;
    case 'IN_PROGRESS':
      return BadgeColor.yellow;
    case 'FAILED':
      return BadgeColor.red;
  }
};
