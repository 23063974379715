import { ApexOptions } from 'apexcharts';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { TransactionsRoutes } from 'support/types';
import { convertToSeries, getAllDates } from '../helpers';
import { DateRange, TransactionGraphData, TransactionSeries } from '../types';
import { Link } from 'react-router-dom';
import { Button } from 'components/Form/Button/Button';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const chartOptions: ApexOptions = {
  chart: {
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
  },
  colors: ['#34D399', '#A78BFA', '#60A5FA'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
    width: 2,
  },
  fill: {
    opacity: 0.2,
    type: 'solid',
    colors: ['#34D399', '#A78BFA', '#60A5FA'],
  },
  legend: {
    show: false,
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    show: false,
  },
};

type TransactionGraphProps = {
  dateRangeOptions: Array<DateRange>;
  transactions: TransactionGraphData | undefined;
  chartDateRange: number;
  loading: boolean;
};

type DefaultSeriesProps = {
  transactions?: TransactionGraphData;
  dateRange: number;
  dates: Array<number>;
  t: TFunction;
};

const buildSeries = ({ transactions, dateRange, dates, t }: DefaultSeriesProps) => {
  const { orders = [], shippingNotices = [], invoices = [] } = transactions ?? {};

  return [
    convertToSeries({ data: orders, name: t('dashboard:orders') }, dateRange, dates),
    convertToSeries({ data: shippingNotices, name: t('dashboard:shippingNotices') }, dateRange, dates),
    convertToSeries({ data: invoices, name: t('dashboard:transactions.invoices') }, dateRange, dates),
  ];
};
const useKeepZoom = (chartOptions: ApexOptions, chartDateRange: TransactionGraphProps['chartDateRange']) => {
  const [lastZoom, setLastZoom] = useState<Array<number>>([]);
  const updatedChartOptions = useMemo(() => {
    return {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        events: {
          beforeResetZoom: function () {
            setLastZoom([]);
          },
          zoomed: function (_: any, value: any) {
            setLastZoom([value.xaxis.min, value.xaxis.max]);
          },
        },
      },
      xaxis: {
        ...chartOptions.xaxis,
        min: lastZoom ? lastZoom[0] : undefined,
        max: lastZoom ? lastZoom[1] : undefined,
      },
    };
  }, [lastZoom]);

  useEffect(() => {
    setLastZoom([]);
  }, [chartDateRange]);

  return updatedChartOptions;
};
export function TransactionGraph({ transactions, dateRangeOptions, chartDateRange }: TransactionGraphProps) {
  const { t } = useTranslation();
  const updatedChartOptions = useKeepZoom(chartOptions, chartDateRange);
  const allDates = useMemo(() => getAllDates(dateRangeOptions), [dateRangeOptions]);
  const [series, setSeries] = useState<Array<TransactionSeries>>(
    buildSeries({ transactions, dateRange: chartDateRange, dates: allDates, t }),
  );

  useEffect(() => {
    const series = buildSeries({ transactions, dateRange: chartDateRange, dates: allDates, t });
    setSeries(series);
  }, [transactions, t, chartDateRange, allDates]);

  return (
    <div className="h-full">
      <h3 className="text-lg font-medium leading-6 text-gray-900">{t('dashboard:transactions.title')}</h3>
      <div className="h-full min-h-[350px]">
        <Chart options={updatedChartOptions} series={series} type="area" height="100%" />
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Badge color={BadgeColor.green} type="indicator">
              {t('dashboard:transactions.orders')}
            </Badge>

            <Badge color={BadgeColor.blue} type="indicator">
              {t('dashboard:transactions.invoices')}
            </Badge>

            <Badge color={BadgeColor.indigo} type="indicator">
              {t('dashboard:transactions.shippingNotices')}
            </Badge>
          </div>
          <Link to={TransactionsRoutes.root}>
            <Button
              variant="minimal-emphasized"
              size="extra-small"
              RightIcon={MagnifyingGlassIcon}
              analyticsId="dashboard:transactions_chart_view_all"
            >
              {t('common:viewAll')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
