import { Radio } from 'components/Form/Radio/Radio';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePartnersSuggestion } from 'services/repositories/partners/partners';
import { PartnerFilterKey } from '../types';
import { TradePartnerNameAndLogo } from 'components/TradePartnerNameAndLogo';

export const TradePartnerSuggestion = ({ tradePartnerName }: { tradePartnerName: string | undefined }) => {
  const { t } = useTranslation();
  const debouncedTradePartnerName = useDebounce(tradePartnerName, 300);
  const validTradePartnerName = debouncedTradePartnerName && debouncedTradePartnerName.length >= 2;

  const { data, isFetching } = usePartnersSuggestion({
    variables: {
      query: {
        filterValues: debouncedTradePartnerName
          ? [{ key: PartnerFilterKey.name, value: debouncedTradePartnerName }]
          : [],
      },
    },
    keepPreviousData: true,
    enabled: Boolean(validTradePartnerName),
  });

  const { register, watch, setValue } = useFormContext();
  useEffect(() => {
    const selectedPartner = watch('partnerId');
    if (selectedPartner && !data?.items?.some(({ id }) => id === selectedPartner)) {
      setValue('partnerId', '');
    }
  }, [data, setValue, watch]);

  if (!data?.count || !validTradePartnerName) {
    return null;
  }

  return (
    <div className="bg-gray-50 p-4">
      <div className="text-base font-semibold text-gray-700">{t('partners:request.suggestions.title')}</div>
      <div className="text-sm font-normal text-gray-500">{t('partners:request.suggestions.description')}</div>
      {isFetching ? (
        <Radio.Group variant="list">
          <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
          <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
          <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
        </Radio.Group>
      ) : (
        <Radio.Group variant="list">
          {data?.items.map((partner) => (
            <Radio.Item
              variant="list"
              key={partner.id}
              label={<TradePartnerNameAndLogo partner={partner} />}
              value={partner.id}
              {...register('partnerId')}
            />
          ))}
          <Radio.Item
            variant="list"
            label={t('partners:request.suggestions.identifier.none.label')}
            value=""
            {...register('partnerId')}
          />
        </Radio.Group>
      )}
    </div>
  );
};
