import { Alert } from 'components/Display/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionsRoutes } from 'support/types';
import { isReceiver } from '../../helpers';
import { BannersProps } from '../../types';

export const TestMessagePendingReviewBanner = ({ relationship, currentPartnerId }: BannersProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (isReceiver({ relationship, currentPartnerId })) {
    return (
      <Alert
        severity="info"
        withoutIcon
        title={t('connections:relationshipDetails.header.alert.inReview.receiver.title')}
        actions={{
          primary: {
            variant: 'primary',
            children: t('connections:relationshipDetails.header.alert.inReview.receiver.action'),
            onClick: () =>
              navigate(routeToPage(ConnectionsRoutes.testRelationship, { relationshipId: relationship.id })),
            analyticsId: 'testing-flow:review_test_documents_navigate',
          },
        }}
      >
        {t('connections:relationshipDetails.header.alert.inReview.receiver.description')}
      </Alert>
    );
  }

  return (
    <Alert severity="neutral">{t('connections:relationshipDetails.header.alert.inReview.sender.description')}</Alert>
  );
};
