import MonacoEditor, { monaco } from 'react-monaco-editor';
import { registerJsonata } from 'support/helpers/transformation/jsonataMode';
import { UiMode } from './ProcessTransformation';
import { CreateTransformationModal } from './CreateTransformationModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IntegrationProcessDTO, TemplateDTO } from 'support/types';
import {
  LocalTransformationDTO,
  PayloadObject,
  RelationshipObject,
  TestScenarioEnum,
  TestScenarioObject,
} from 'stores/transformations/processTransformation';
import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { TransformationResult } from 'support/helpers/transformation/transformer';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { classNames, generateUUID } from 'support/helpers/generic/generic';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Button } from 'components/Form/Button/Button';
import { Input } from 'components/Form/Input/Input/Input';

type TransformationExpressionEditorProps = {
  className?: string;
  processType?: IntegrationProcessDTO['type'];
  uiMode: UiMode;
  hidden?: boolean;
  needsRetesting: boolean;
  setUiMode: (mode: UiMode) => void;
  existingTransformations: Array<LocalTransformationDTO>;
  transformationTemplates: Array<TemplateDTO>;
  selectedTransformation?: LocalTransformationDTO;
  activeTransformation?: LocalTransformationDTO;
  selectedPayload?: PayloadObject;
  existingPayloads: Array<PayloadObject>;
  onPayloadSelect: (payloadId: string) => void;
  selectedRelationship?: RelationshipObject;
  existingRelationships: Array<RelationshipObject>;
  onRelationshipSelect: (relationshipId: string) => void;
  onTransformationSelect: (transformationId: string) => void;
  onTransformationChange: (transformationId: string, transformation: LocalTransformationDTO) => void;
  onTransformationCreate: (transformation: LocalTransformationDTO) => void;
  testScenarios: Array<TestScenarioObject>;
  selectedTestScenario?: TestScenarioObject;
  onTestScenarioSelect: (testScenarioId: string) => void;
  specificEntityId: string | null;
  onSpecificEntityIdChange: (entityId: string) => void;
  testsExecuting: boolean;
  onTestsExecute: () => void;
  onOpenDeployModal: () => void;
};

const tableHeaders: Array<TableHeader> = [{ label: 'Payload' }, { label: 'Date' }, { label: 'Test result' }];

const buildRow = (payload: PayloadObject) => {
  const hasDiff = payload.outputDiff && (payload.outputDiff.additions > 0 || payload.outputDiff.deletions > 0);
  return [
    {
      element: <div className="max-w-xs truncate text-sm text-gray-900">{payload.name}</div>,
      key: `payload-name-${payload.id}`,
    },
    {
      element: <div className="text-sm text-gray-900">{formatDayAndTime(payload.date)}</div>,
      key: `payload-date-${payload.id}`,
    },
    {
      element: (
        <div className="space-x-1">
          <Badge color={payload.inputSchemaValidationResult?.valid ? BadgeColor.green : BadgeColor.red}>In</Badge>
          <Badge color={payload.transformationResult?.success ? BadgeColor.green : BadgeColor.red}>T</Badge>
          <Badge color={payload.outputSchemaValidationResult?.valid ? BadgeColor.green : BadgeColor.red}>Out</Badge>
          {hasDiff ? (
            <Badge color={BadgeColor.gray}>
              <span className="text-green-500">+{payload.outputDiff?.additions || 0}</span>
              <span className="ml-1 text-red-500">-{payload.outputDiff?.deletions || 0}</span>
            </Badge>
          ) : (
            <Badge color={BadgeColor.green}>–</Badge>
          )}
        </div>
      ),
      key: `payload-test-result-${payload.id}`,
    },
  ];
};

export const TransformationExpressionEditor = ({
  className,
  processType,
  uiMode,
  hidden,
  needsRetesting,
  setUiMode,
  existingTransformations,
  transformationTemplates,
  selectedTransformation,
  activeTransformation,
  existingPayloads,
  selectedPayload,
  onPayloadSelect,
  existingRelationships,
  selectedRelationship,
  onRelationshipSelect,
  onTransformationSelect,
  onTransformationChange,
  onTransformationCreate,
  testScenarios,
  selectedTestScenario,
  onTestScenarioSelect,
  specificEntityId,
  onSpecificEntityIdChange,
  testsExecuting,
  onTestsExecute,
  onOpenDeployModal,
}: TransformationExpressionEditorProps) => {
  const [createTransformationModalOpen, setCreateTransformationModalOpen] = useState<boolean>(false);
  // const [currentSchemaErrorIndex, setCurrentSchemaErrorIndex] = useState<number>();
  const transformationEditorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  const readyToExecuteTests =
    selectedTestScenario &&
    (selectedTestScenario.id === TestScenarioEnum.RECENT_100 ||
      (selectedTestScenario.id === TestScenarioEnum.PER_PARTNER_10 && selectedRelationship) ||
      (selectedTestScenario.id === TestScenarioEnum.SPECIFIC_ENTITY_ID && specificEntityId));

  const markErrors = useCallback(
    (transformationResult: TransformationResult) => {
      const model = transformationEditorRef.current?.getModel();
      if (!transformationEditorRef.current || !model || !transformationResult) {
        return;
      }

      // console.log(transformAjvErrorsToMonacoMarkers(model, validationResult.errors));
      // console.log('lineCount', model.getLineCount());
      const monacoErrors: Array<monaco.editor.IMarkerData> = (transformationResult.errors ?? [])
        .filter((error) => !!error.position)
        .map((error) => {
          const modelPosition = model.getPositionAt(error.position ?? 0);
          return {
            startLineNumber: modelPosition.lineNumber,
            startColumn: modelPosition.column,
            endLineNumber: modelPosition.lineNumber,
            endColumn:
              model.getLineLength(modelPosition.lineNumber) < modelPosition.column + 1
                ? modelPosition.column
                : modelPosition.column + 1,
            severity: monaco.MarkerSeverity.Error,
            message: error.message,
          };
        });

      // console.log('transformation errors', monacoErrors);
      transformationResult.success
        ? monaco.editor.setModelMarkers(model, 'owner', [])
        : monaco.editor.setModelMarkers(model, 'owner', monacoErrors);

      // setCurrentSchemaErrorIndex(undefined);
    },
    [transformationEditorRef],
  );

  const copySelectedTransformation = (): void => {
    if (!selectedTransformation) {
      return;
    }
    // Check if transformation name ends with "vx" and if so, remove the "x" and increment the "v"
    const matches = selectedTransformation.name.match(/v(\d+)$/);
    const newTransformationName = matches
      ? selectedTransformation.name.replace(/v(\d+)$/, `v${parseInt(matches[1]) + 1}`)
      : `${selectedTransformation.name} v2`;

    const newTransformation: LocalTransformationDTO = {
      name: newTransformationName,
      expression: selectedTransformation.expression,
      id: `pending-transformation-${generateUUID()}`,
      is_draft: true,
      type: 'JSONATA',
      group: 'DEFAULT',
      activated_at: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_dirty: false,
    };

    onTransformationCreate(newTransformation);
  };

  useEffect(() => {
    if (!selectedPayload?.transformationResult) {
      return;
    }

    markErrors(selectedPayload.transformationResult);
  }, [selectedPayload?.transformationResult, markErrors]);

  return (
    <div className={classNames('flex-1 min-h-0 flex flex-col divide-y', hidden ? 'hidden' : undefined, className)}>
      <div className="flex justify-between px-3 py-4">
        <div className="flex space-x-3 text-sm">
          <dl>
            <dt className="font-medium text-gray-700">Type</dt>
            <dd>JSONata</dd>
          </dl>
          <dl>
            <dt className="font-medium text-gray-700">{uiMode === 'BUILD' ? 'Active' : 'Selected'} Transformation</dt>
            <dd>{uiMode === 'BUILD' ? activeTransformation?.name ?? '–' : selectedTransformation?.name ?? '–'}</dd>
          </dl>
        </div>
        <div className="flex items-end space-x-2 text-sm">
          <SegmentedControl
            items={[{ label: 'BUILD' }, { label: 'TEST' }]}
            selectedIndex={uiMode === 'BUILD' ? 0 : 1}
            onChange={(index) => setUiMode(index === 0 ? 'BUILD' : 'TEST')}
          />
        </div>
      </div>
      {uiMode === 'BUILD' ? (
        <div className="flex space-x-3 px-3 py-4 text-sm">
          <div>
            <Dropdown
              label="Selected transformation"
              value={selectedTransformation?.id ?? 'choose...'}
              options={existingTransformations.map((transformation) => ({
                label: transformation.name,
                value: transformation.id,
              }))}
              onChange={onTransformationSelect}
            />
          </div>
          <div className="flex items-end space-x-2 text-sm">
            {!!selectedTransformation && (
              <Button
                variant="secondary"
                onClick={copySelectedTransformation}
                analyticsId="transformation-ui:transformation-copy"
              >
                Copy
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={() => setCreateTransformationModalOpen(true)}
              analyticsId="transformation-ui:transformation-create"
            >
              New transformation
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex justify-between space-x-3 px-3 py-4 text-sm">
          <div className="flex items-end">
            <Dropdown
              className="mr-3"
              label="Select test scenario"
              value={selectedTestScenario?.id ?? 'choose...'}
              options={testScenarios.map((testScenario) => ({
                label: testScenario.label,
                value: testScenario.id,
              }))}
              onChange={onTestScenarioSelect}
            />
            {selectedTestScenario?.id === TestScenarioEnum.PER_PARTNER_10 && (
              <Dropdown
                label="Selected Partner"
                value={selectedRelationship?.id ?? 'choose...'}
                options={existingRelationships.map((relationship) => ({
                  label: relationship.name,
                  value: relationship.id,
                }))}
                onChange={onRelationshipSelect}
              />
            )}
            {selectedTestScenario?.id === TestScenarioEnum.SPECIFIC_ENTITY_ID && (
              <Input
                placeholder={processType === 'SOURCE' ? 'Sender envelope ID' : 'Message ID'}
                value={specificEntityId}
                onChange={(e) => onSpecificEntityIdChange(e.target.value)}
              />
            )}
          </div>
          <div className="flex items-end">
            <Button disabled={needsRetesting} onClick={onOpenDeployModal} analyticsId="transformation-ui:deploy">
              Deploy...
            </Button>
          </div>
        </div>
      )}

      {selectedTransformation ? (
        <>
          <div className={classNames('flex-1 min-h-0', uiMode === 'BUILD' ? '' : 'hidden')}>
            <MonacoEditor
              width="100%"
              height="100%"
              language="jsonata"
              theme="jsonataTheme"
              value={selectedTransformation.expression}
              options={{
                readOnly: selectedTransformation.is_draft === false,
                automaticLayout: true,
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
              }}
              editorWillMount={registerJsonata}
              editorDidMount={(editor) => (transformationEditorRef.current = editor)}
              onChange={(value) =>
                onTransformationChange(selectedTransformation.id, { ...selectedTransformation, expression: value })
              }
            />
          </div>
          <div
            className={classNames(
              'flex-1 overflow-y-auto',
              needsRetesting ? 'flex justify-center items-center' : '',
              uiMode === 'TEST' ? '' : 'hidden',
            )}
          >
            {needsRetesting ? (
              <Button
                loading={testsExecuting}
                disabled={!readyToExecuteTests}
                variant="secondary"
                onClick={onTestsExecute}
                analyticsId="transformation-ui:execute-tests"
              >
                Execute tests
              </Button>
            ) : (
              <ListTable<PayloadObject>
                headers={tableHeaders}
                data={existingPayloads}
                isLoading={testsExecuting}
                onRowClick={(payload) => onPayloadSelect(payload.id)}
                rowBuilder={buildRow}
              />
            )}
          </div>
        </>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          <Button
            variant="secondary"
            onClick={() => setCreateTransformationModalOpen(true)}
            analyticsId="transformation-ui:new-transformation"
          >
            Create new transformation
          </Button>
        </div>
      )}
      <CreateTransformationModal
        open={createTransformationModalOpen}
        onClose={() => setCreateTransformationModalOpen(false)}
        existingTransformations={existingTransformations}
        transformationTemplates={transformationTemplates}
        onTransformationCreate={onTransformationCreate}
      />
    </div>
  );
};
