import { CellContext } from '@tanstack/react-table';
import { CellProps } from './types';
import { DropdownCell } from './DropdownCell';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type Props<TData, TValue> = CellContext<TData, TValue> & CellProps;

export const BooleanCell = <TData = unknown, TValue = string>(props: Props<TData, TValue>) => {
  const { t } = useTranslation();
  const booleanCellOptions = useMemo(
    () => [
      { label: t('common:yes'), value: true },
      { label: t('common:no'), value: false },
    ],
    [t],
  );
  return <DropdownCell {...props} options={booleanCellOptions} />;
};
