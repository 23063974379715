import React, { FC } from 'react';

import { classNames } from 'support/helpers/generic/generic';

type CardVariants = 'default' | 'well' | 'well-darker';

// no-unused-vars: key is being used
const cardClasses: { [key in CardVariants]: string } = {
  default: 'bg-white shadow',
  well: 'bg-gray-50',
  'well-darker': 'bg-gray-200',
};

type CardProps = {
  variant?: CardVariants;
} & JSX.IntrinsicElements['div'];

const CardWrapper: FC<CardProps> = ({ children, className, variant = 'default', ...otherProps }) => {
  return (
    <div className={classNames('rounded-lg', cardClasses[variant], className ?? '')} {...otherProps}>
      {children}
    </div>
  );
};

type BodyType = { gray?: boolean } & JSX.IntrinsicElements['div'];
const Body: FC<BodyType> = ({ gray, children, className, ...otherProps }) => (
  <div
    className={classNames('px-4 py-5 sm:p-6 border-t border-gray-200', gray ? 'bg-gray-50' : '', className ?? '')}
    {...otherProps}
  >
    {children}
  </div>
);

type HeaderType = {
  right?: React.ReactNode;
} & JSX.IntrinsicElements['div'];

const Header: FC<HeaderType> = ({ children, className, right, ...otherProps }) => (
  <div className={classNames('px-4 py-5 sm:px-6', className ?? '')} {...otherProps}>
    <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
      <div className="ml-4 mt-2 flex items-center">{children}</div>
      {!!right && <div className="ml-4 mt-2 shrink-0">{right}</div>}
    </div>
  </div>
);

type FooterType = { gray?: boolean } & JSX.IntrinsicElements['div'];
const Footer: FC<FooterType> = ({ gray, children, className, ...otherProps }) => (
  <div
    className={classNames(
      'px-4 py-4 sm:px-6 rounded-b-lg',
      gray ? 'bg-gray-50' : 'border-t border-gray-200',
      className ?? '',
    )}
    {...otherProps}
  >
    {children}
  </div>
);

export const Card = Object.assign(CardWrapper, { Header, Body, Footer });
