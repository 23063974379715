import { BadgeColor } from 'components/Display/Badge/Badge';
import { IntegrationDTO } from 'support/types';

export const getIntegrationStatusBadgeColor = (state?: IntegrationDTO['state']): BadgeColor => {
  switch (state) {
    case 'IN_TESTING':
      return BadgeColor.yellow;
    case 'READY':
      return BadgeColor.green;
    default:
      return BadgeColor.gray;
  }
};
