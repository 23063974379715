import { Checkbox } from 'components/Form/Checkbox/Checkbox';

interface CheckboxFilterProps {
  label?: string;
  currentValue: string;
  filterKey: string;
  onChange: (filterKey: string, value: string) => void;
}

export const CheckboxFilter = ({ label, currentValue, filterKey, onChange }: CheckboxFilterProps) => {
  return (
    <div className="flex items-center rounded-md border-gray-300 bg-white border shadow-sm px-3 py-2">
      <Checkbox
        label={label}
        checked={currentValue === 'true'}
        onChange={(event) => onChange(filterKey, event.target.checked.toString())}
      />
    </div>
  );
};
