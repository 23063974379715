import { useFloating, useInteractions, useClick, offset, flip, shift, FloatingPortal } from '@floating-ui/react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Button, ButtonType } from 'components/Form/Button/Button';
import {
  Children,
  ComponentType,
  FC,
  Fragment,
  ReactNode,
  cloneElement,
  isValidElement,
  useState,
  MouseEvent,
} from 'react';
import { Link } from 'react-router-dom';
import { AnalyticsProps, buildAnalyticsAttributes } from 'support/helpers/analytics/analytics';
import { classNames } from 'support/helpers/generic/generic';

type MenuButtonProps = {
  className?: string;
  children: ReactNode;
  text?: ReactNode;
  Icon?: ButtonType['LeftIcon'];
  iconOnly?: ButtonType['iconOnly'];
  variant?: ButtonType['variant'];
  testId?: string;
  size?: ButtonType['size'];
} & AnalyticsProps;

export const MenuButton: FC<MenuButtonProps> = ({
  size = 'small',
  className,
  children,
  text,
  variant,
  testId,
  Icon,
  iconOnly,
  analyticsId,
  analyticsProperties,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [offset(), flip(), shift()],
  });
  const click = useClick(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => {
        return (
          <>
            <div>
              <Menu.Button as={Fragment} ref={refs.setReference} {...getReferenceProps()}>
                <Button
                  RightIcon={iconOnly ? undefined : ChevronDownIcon}
                  variant={variant}
                  testId={testId}
                  LeftIcon={Icon}
                  iconOnly={iconOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={className}
                  size={size}
                  analyticsId={analyticsId}
                  analyticsProperties={analyticsProperties}
                >
                  {text}
                </Button>
              </Menu.Button>
            </div>
            <FloatingPortal>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute right-0 z-30 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus-visible:outline-none"
                  ref={refs.setFloating}
                  style={floatingStyles}
                  {...getFloatingProps()}
                >
                  {Children.map(
                    children,
                    (child) =>
                      isValidElement(child) &&
                      cloneElement(child, {
                        ...child.props,
                        size,
                        className: classNames(
                          child.props.className,
                          'overflow-hidden first:rounded-t-md last:rounded-b-md',
                        ),
                      }),
                  )}
                </Menu.Items>
              </Transition>
            </FloatingPortal>
          </>
        );
      }}
    </Menu>
  );
};

const SIZE_CLASSNAMES: Record<Required<ButtonType>['size'], string> = {
  'extra-small': 'py-1',
  small: 'py-2',
  base: 'py-3',
  large: 'py-3',
  'extra-large': 'py-4',
};
type MenuButtonOptionProps = {
  to?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  Icon?: ComponentType<any>;
  testId?: string;
  className?: string;
  size?: ButtonType['size'];
  newGroup?: boolean;
} & AnalyticsProps;
const defaultContainerClasses =
  'flex items-center text-sm text-gray-700 relative pl-4 pr-4 hover:bg-gray-200 w-max min-w-full gap-3';

export const MenuButtonOption: FC<MenuButtonOptionProps> = ({
  onClick,
  size = 'base',
  to,
  children,
  Icon,
  testId,
  className,
  newGroup,
  analyticsId,
  analyticsProperties,
}) => {
  const content = (
    <>
      {Icon && <Icon className="-ml-1 mr-2 size-5" />}
      {children}
    </>
  );

  const containerClasses = classNames(defaultContainerClasses, SIZE_CLASSNAMES[size]);
  return (
    <Menu.Item as="div" className={classNames(className, newGroup ? 'border-t border-gray-100' : undefined)}>
      {to ? (
        <Link to={to} className="text-sm">
          <div data-testid={testId} className={containerClasses}>
            {content}
          </div>
        </Link>
      ) : null}
      {onClick ? (
        <button
          {...buildAnalyticsAttributes({ analyticsId, analyticsProperties })}
          type="button"
          data-testid={testId}
          onClick={onClick}
          className={containerClasses}
        >
          {content}
        </button>
      ) : null}
    </Menu.Item>
  );
};
