import { classNames } from 'support/helpers/generic/generic';

type ApplicationLogoProps = {
  className?: string;
  withText?: boolean;
  variant?: 'light' | 'dark';
};

export function ApplicationLogo({ withText = true, variant = 'light', ...props }: ApplicationLogoProps) {
  const viewBox = withText ? `0 0 1000 180` : '40 40 100 105';
  return (
    <svg
      {...props}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(variant === 'light' ? 'text-procuros-grey' : 'text-black', props.className ?? '')}
    >
      <path
        fill="currentColor"
        d="M40,63.2c10,0,15,11.1,25,11.1s15-11.1,25-11.1s14.9,11.1,25,11.1s15-11.1,25-11.1V41c-10,0-15,11.1-25,11.1
	S99.9,41,90,41S75,52.1,65,52.1S50,41,40,41V63.2z"
      />
      <path
        fill="currentColor"
        d="M40,96.6c10,0,15,11.1,25,11.1s15-11.1,25-11.1s14.9,11.1,25,11.1s15-11.1,25-11.1V74.3c-10,0-15,11.1-25,11.1
	S99.9,74.3,90,74.3S75,85.4,65,85.4S50,74.3,40,74.3V96.6z"
      />
      <path
        fill="currentColor"
        d="M40,129.9c10,0,15,11.1,25,11.1s15-11.1,25-11.1s14.9,11.1,25,11.1s15-11.1,25-11.1v-22.2
	c-10,0-15,11.1-25,11.1s-15.1-11.1-25-11.1s-15,11.1-25,11.1s-15-11.1-25-11.1V129.9z"
      />
      {withText ? (
        <path
          fill="currentColor"
          d="M924.7,142.6c25.7,0,40.3-15,40.3-33.3c0-21.7-13-28.2-38.3-31c-12.5-1.7-15.5-4.2-15.5-9.8
	c0-5,3.9-8.5,11.6-8.5s11.9,3.6,12.9,10.8h26.6c-1.8-21.1-15.4-31.2-39.4-31.2C898.8,39.5,884,52.7,884,70.7
	c0,20.1,9.5,28.5,37.8,31.9c12,1.8,15.8,3.5,15.8,9.7c0,5.9-4.6,9.9-12.9,9.9c-11.9,0-15.1-5.9-15.8-13h-27.3
	C882.5,130.9,896.8,142.6,924.7,142.6 M824.3,142.6c32.3,0,53.4-21,53.4-51.8v-1.1c0-31-22.5-50.2-53-50.2
	c-29.9,0-52.9,20.4-52.9,50.9v1.1C771.9,121.6,792,142.6,824.3,142.6 M824.9,120.1c-13.8,0-23.1-10.5-23.1-28.7v-1.1
	c0-18.2,8.8-28.4,22.9-28.4s23.1,10.5,23.1,28.2v1.1C847.9,109.2,839,120.1,824.9,120.1 M711.9,86.5v-24h9.4
	c9.2,0,14.3,3.2,14.3,11.3v0.6c0,8.1-4.8,12.2-14.4,12.2L711.9,86.5z M683.4,140.9h28.5v-35.8h6.7l20.3,35.8h30.2l-23.9-41.4
	c10.5-4,18-12,18-26.3v-0.6c0-21.1-14.5-31.8-40.7-31.8h-39.1V140.9z M622.5,142.6c27.5,0,44.6-13.6,44.6-42.7v-59h-29v57.7
	c0,15-3.8,21.4-15.7,21.4c-12.2,0-15.7-6.8-15.7-21V40.9h-28.6v59.4C578.1,128.4,594.2,142.6,622.5,142.6 M522,142.6
	c25.9,0,44.6-13.4,46.6-39.3H540c-1.4,11-8.5,16.4-18.6,16.4c-13.7,0-21.4-10.2-21.4-28.2v-1.1c0-18.2,8.1-28.2,21-28.2
	c10.1,0,15.7,5.6,16.8,15.8h29.5c-2.4-26.4-20.4-38.5-46.4-38.5c-29.2,0-51,20.3-51,51v1.1C469.8,122.2,487,142.6,522,142.6
	 M409.9,142.6c32.3,0,53.4-21,53.4-51.8v-1.1c0-31-22.5-50.2-53-50.2c-29.9,0-52.9,20.4-52.9,50.9v1.1
	C357.4,121.6,377.6,142.6,409.9,142.6 M410.4,120.1c-13.8,0-23.1-10.5-23.1-28.7v-1.1c0-18.2,8.8-28.4,22.9-28.4
	s23.1,10.5,23.1,28.2v1.1C433.4,109.2,424.6,120.1,410.4,120.1 M297.4,86.5v-24h9.4c9.2,0,14.3,3.2,14.3,11.3v0.6
	c0,8.1-4.8,12.2-14.4,12.2L297.4,86.5z M268.9,140.9h28.5v-35.8h6.7l20.3,35.8h30.2l-23.9-41.4c10.5-4,18-12,18-26.3v-0.6
	c0-21.1-14.5-31.8-40.7-31.8h-39.1V140.9z M208.5,87.6V62.5h8.4c8.9,0,14,3.5,14,12V75c0,8.2-4.9,12.6-14.1,12.6L208.5,87.6z
	 M180,140.9h28.5v-33.7h10.8c24.8,0,38.9-11.3,38.9-32.3v-0.6c0-21.3-12.7-33.4-40.1-33.4H180V140.9z"
        />
      ) : null}
    </svg>
  );
}
