import { useCallback, ChangeEvent, KeyboardEventHandler } from 'react';
import { Input } from 'components/Form/Input';
import { Dropdown } from 'components/Form/Dropdown';
import { ui } from '@procuros/datachecks';
import { fromStringDateToProcurosTimestamp } from 'support/helpers/dateTime/dateTime';

type InputProps = {
  onChange: (value: string | number) => void;
  onKeyDown?: KeyboardEventHandler;
  value: string | number;
  options?: Array<{ label: string; value: string }>;
  placeholder: string;
};

type InputType = 'number' | 'date' | 'text';

//TODO: Extract this. We should have multiple inputs for different types, it will make it easier to use on automatic generated forms
const createInputComponent = (type: InputType) => {
  const InputComponent: React.FC<InputProps> = ({ onChange, ...props }) => {
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (type === 'date') {
          return onChange(fromStringDateToProcurosTimestamp(event.target.value));
        }

        if (type === 'number') {
          return onChange(parseFloat(event.target.value));
        }
        onChange(event.target.value);
      },
      [onChange],
    );

    return <Input {...props} value={undefined} onChange={handleChange} type={type} />;
  };

  InputComponent.displayName = `InputComponent_${type}`;
  return InputComponent;
};

const NumberInput = createInputComponent('number');
const DateInput = createInputComponent('date');
const TextInput = createInputComponent('text');

const inputMapping: Record<keyof typeof ui.FieldTypes, React.ComponentType<InputProps> | undefined> = {
  [ui.FieldTypes.select]: Dropdown,
  [ui.FieldTypes.number]: NumberInput,
  [ui.FieldTypes.monetary]: NumberInput,
  [ui.FieldTypes.date]: DateInput,
  [ui.FieldTypes.text]: TextInput,
  [ui.FieldTypes.url]: undefined,
  [ui.FieldTypes.boolean]: undefined,
  [ui.FieldTypes.date_time]: undefined,
  [ui.FieldTypes.multi_select]: undefined,
  [ui.FieldTypes.multi_column]: undefined,
};

export const getInputComponent = (type: keyof typeof ui.FieldTypes) => {
  return inputMapping[type] || TextInput;
};
