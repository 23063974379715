import { Button } from 'components/Form/Button/Button';
import { ApplicationLogoSmall } from 'components/Logo/ApplicationLogoSmall/ApplicationLogoSmall';
import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-gray-100">
      <ApplicationLogoSmall className="size-40 rounded-full bg-black shadow-2xl" />
      <div className="mt-5 text-4xl">{t('common:error.generic')}</div>
      <Button className="mt-5" onClick={() => window.location.reload()} analyticsId="error_page_reload">
        {t('common:error.reload')}
      </Button>
    </div>
  );
}
