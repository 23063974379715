import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePasswordlessSignIn } from 'services/repositories/user/user';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { AuthBodySection } from '../../components/AuthBodySection/AuthBodySection';
import { Credentials } from '../types';
import { Button } from 'components/Form/Button';
import { Input } from 'components/Form/Input';
import { PasswordlessSuccess } from 'pages/Auth/components/PasswordlessSuccess/PasswordlessSuccess';

export const PasswordlessLoginForm = ({
  toggleLoginMethod,
  defaultData,
}: {
  toggleLoginMethod: () => void;
  defaultData: Credentials;
}) => {
  const { t } = useTranslation();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const { mutate: passwordlessSignIn, isLoading, isSuccess, reset } = usePasswordlessSignIn();
  const {
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext<Credentials>();

  const onPasswordlessSignIn = async (credentials: Omit<Credentials, 'password'>) => {
    setNonInputErrors([]);

    passwordlessSignIn(credentials, {
      onError: (err) => {
        processSubmissionErrors({ error: err, setInputError: setError, setNonInputErrors, defaultData, t });
        throw err;
      },
    });
  };

  if (isSuccess) {
    return (
      <PasswordlessSuccess usedEmail={watch('email')} onBack={() => reset()} toggleLoginMethod={toggleLoginMethod} />
    );
  }
  return (
    <AuthBodySection
      nonInputErrors={nonInputErrors}
      heading={t('auth:heading.passwordless')}
      onSubmit={handleSubmit(onPasswordlessSignIn)}
    >
      <Input
        {...register('email', { required: true })}
        placeholder={t('auth:email')}
        autoComplete="username"
        label={t('auth:emailAddress')}
        type="email"
        errors={errors.email}
        required
        readOnly={isLoading}
      />

      <Button type="submit" fullWidth loading={isLoading} analyticsId="login:login-passwordless">
        {t('auth:signInPasswordless')}
      </Button>
      <div className="mt-2 flex w-full flex-col gap-6">
        <Button
          type="button"
          variant="text"
          className="!text-gray-900 underline"
          fullWidth
          analyticsId="login:toggle-login-method"
          onClick={toggleLoginMethod}
        >
          {t('auth:change-method.loginWithPassword')}
        </Button>
      </div>
    </AuthBodySection>
  );
};
