export type DataType = string | number | boolean | { [x: string]: DataType | Array<DataType> };

/** internal columns which aren't used in a canonical document */
export enum ColumnTypesInternal {
  procuros_select = 'procuros_select',
  procuros_delete = 'procuros_delete',
  procuros_added = 'procuros_added',
  procuros_index = 'procuros_index',
}

/** Supported view modes */
export enum DisplayModes {
  view = 'view',
  edit = 'edit',
  create = 'create',
}

/** Supported data modes */
export enum DataRenderModes {
  auto = 'auto',
  plain = 'plain',
  scrollable = 'scrollable',
  virtualized = 'virtualized',
}
