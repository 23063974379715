import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'support/helpers/generic/generic';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

type TablePropsType = {
  children: React.ReactNode;
};

const TableWrapper = ({ children }: TablePropsType) => {
  return (
    <div className="-mx-6 overflow-x-auto px-6">
      <div
        className={classNames(
          'align-middle inline-block min-w-full  rounded-md  overflow-hidden border-gray-200 border',
        )}
      >
        <table className={classNames('min-w-full  border-collapse')}>{children}</table>
      </div>
    </div>
  );
};

const Head = ({ children }: { children: React.ReactNode }) => <thead>{children}</thead>;

const HeadCell = ({
  invisible = false,
  alignRight = false,
  className = '',
  children,
  tooltip,
  ...otherProps
}: {
  invisible?: boolean;
  alignRight?: boolean;
  className?: string;
  children: React.ReactNode;
  tooltip?: string;
} & JSX.IntrinsicElements['th']) =>
  invisible ? (
    <th scope="col" className={classNames('relative px-3 py-4', className)} {...otherProps}>
      <span className="sr-only">{children}</span>
    </th>
  ) : (
    <th
      scope="col"
      className={classNames(
        'px-3 py-4 text-left text-xs font-normal text-gray-500 border-gray-200 border-b',
        alignRight ? 'text-right' : '',
        className,
      )}
      {...otherProps}
    >
      <div className="flex">
        {children}

        {Boolean(tooltip) && (
          <div className="ml-1 cursor-context-menu">
            <Tooltip>
              <TooltipTrigger>
                <InformationCircleIcon className="mr-2 size-4 text-gray-400 hover:text-procuros-green-500" />
              </TooltipTrigger>
              <TooltipContent>{tooltip}</TooltipContent>
            </Tooltip>
          </div>
        )}
      </div>
    </th>
  );

const BodyLoadingGlimmer = () => {
  return (
    <tbody data-testid="table-loading-state">
      <tr>
        <td colSpan={99}>
          <div className="m-4 flex w-full flex-wrap">
            <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
            <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-100 leading-relaxed"></div>
            <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-300 leading-relaxed"></div>
            <div className="mb-3 block h-5 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

const Body = ({ children, isLoading = false }: { children: React.ReactNode; isLoading?: boolean }) => {
  return isLoading ? <BodyLoadingGlimmer /> : <tbody>{children}</tbody>;
};

const Footer = ({ children }: { children: React.ReactNode }) => <tfoot>{children}</tfoot>;

const Row = ({
  children,
  hover,
  onClick,
  className,
  withBottomBorder = true,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  hover?: boolean;
  className?: string;
  withBottomBorder?: boolean;
}) => (
  <tr
    className={classNames(
      'odd:bg-white even:bg-slate-50 last:border-b-0 text-sm text-gray-700',
      withBottomBorder ? 'border-b border-gray-200' : undefined,
      hover ? 'group' : undefined,
      className,
    )}
    onClick={onClick}
  >
    {children}
  </tr>
);

const Cell: FC<{ className?: string; to?: string; withHighlight?: boolean } & JSX.IntrinsicElements['td']> = ({
  children,
  className,
  to,
  withHighlight,
  ...otherProps
}) => (
  <td
    {...otherProps}
    className={classNames(
      'whitespace-nowrap text-gray-500 text-sm ',
      to ? 'p-0' : 'px-3 py-4',
      withHighlight === undefined
        ? undefined
        : withHighlight
          ? 'text-gray-900 font-medium'
          : '!text-gray-500 !font-normal',
      className ?? '',
      'group-hover:bg-procuros-green-50 group-hover:text-procuros-green-600 group-hover:cursor-pointer',
    )}
  >
    {to ? (
      <Link to={to} className={classNames('w-full block px-3 py-4', className ?? '')}>
        {children}
      </Link>
    ) : (
      children
    )}
  </td>
);

export const Table = Object.assign(TableWrapper, { Head, HeadCell, Body, Footer, Row, Cell });
