import { Alert } from 'components/Display/Alert/Alert';
import { useEffect, useState } from 'react';
import { MessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { autoPlacement, autoUpdate, offset, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { WebEDIError } from 'stores/webediErrors/webediErrors';
import { Translate } from 'components/Translate/Translate';
import { logError } from 'services/logging/logging';
import { GenericError } from 'support/helpers/errors/errors';

type MessageNonFieldErrorBannerProps = {
  error: WebEDIError;
  totalNumberOfErrors: number;
  setCurrentError: (error: WebEDIError) => void;
};

export const MessageNonFieldErrorBanner = ({
  error,
  totalNumberOfErrors,
  setCurrentError,
}: MessageNonFieldErrorBannerProps) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: showErrorModal,
    onOpenChange: setShowErrorModal,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      autoPlacement({ allowedPlacements: ['bottom-start', 'bottom', 'bottom-end', 'top-start', 'top', 'top-end'] }),
    ],
  });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    if (totalNumberOfErrors === 0 && error) {
      logError(new GenericError('MessageNonFieldErrorBanner: No errors found when rendering the banner', error));
    }
  }, [error, totalNumberOfErrors]);

  useEffect(() => {
    if (error && showErrorModal) {
      setCurrentError(error);
    }
  }, [error, setCurrentError, showErrorModal]);

  if (totalNumberOfErrors === 0) {
    return null;
  }

  return (
    <>
      <Alert
        severity="error"
        actions={{
          primary: {
            variant: 'minimal-danger',
            children: <Translate i18nKey="webedi:MessageNonFieldErrorBanner.cta" />,
            onFocus: () => setShowErrorModal(true),
            onBlur: () => setShowErrorModal(false),
            size: 'extra-small',
            ref: refs.setReference,
            ...getReferenceProps(),
            'data-field': error.id,
          } as any,
        }}
      >
        {error.title}
      </Alert>

      {showErrorModal ? (
        <div {...getFloatingProps()}>
          <MessageFieldErrorModal
            viewMode={false}
            error={error}
            numberOfErrors={totalNumberOfErrors}
            ref={refs.setFloating}
            style={floatingStyles}
          />
        </div>
      ) : null}
    </>
  );
};
