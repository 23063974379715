import {
  SlideOver,
  SlideOverContentSkeleton,
  SlideOverSubtitleSkeleton,
  SlideOverTitleSkeleton,
} from 'components/Display/SlideOver/SlideOver';
import { useDeactivateRelationship, useRelationship } from 'services/repositories/relationships/relationships';
import { RelationshipTimeline } from './components/RelationshipTimeline/RelationshipTimeline';
import { RelationshipDetailsHeader } from './components/RelationshipDetailsHeader/RelationshipDetailsHeader';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useTranslation } from 'react-i18next';
import { getTradePartner } from 'support/helpers/relationships/relationships';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ConfirmDeletePopup } from 'components/Display/ConfirmDeletePopup/ConfirmDeletePopup';
import { ReactNode, useState } from 'react';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionsRoutes, RelationshipV2 } from 'support/types';
import { useNavigate } from 'react-router-dom';
import { Translate } from 'components/Translate/Translate';

const BEFORE_LIVE_RELATIONSHIP_STATUS: Array<RelationshipV2['status']> = [
  'PARTNER_APPROVAL_PENDING',
  'PARTNER_INTEGRATION_PENDING',
  'PARTNER_ONBOARDING_PENDING',
  'PARTNER_PROCESS_PENDING',
  'ISSUE_RESOLVER_PENDING',
  'LIVE_ORDER_NEEDED',
  'READY_FOR_TESTING',
  'HAS_TEST_MESSAGES',
  'IN_REVIEW',
];

export const RelationshipsDetails = ({
  onClose,
  onBack,
  open,
  relationshipId,
  children,
}: {
  onClose: () => void;
  onBack: undefined | (() => void);
  open: boolean;
  relationshipId: string | undefined;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentPartner } = useCurrentPartner();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { data: relationship } = useRelationship({
    variables: { relationshipId },
    keepPreviousData: true,
    staleTime: 0,
    enabled: Boolean(relationshipId),
  });
  const { mutate, isLoading: isDeactivingRelationship } = useDeactivateRelationship();

  const isLoading = !relationship || !currentPartner;
  const otherPartner = relationship && currentPartner && getTradePartner(relationship, currentPartner.id);
  const userCanCancelRequest = relationship && BEFORE_LIVE_RELATIONSHIP_STATUS.includes(relationship.status);
  const isRelationshipDeactivated = relationship?.status === 'DEACTIVATED';

  return (
    <SlideOver
      size="large"
      open={open}
      setOpen={onClose}
      onBack={onBack}
      title={isLoading ? <SlideOverTitleSkeleton /> : otherPartner?.name}
      subtitle={
        isLoading ? <SlideOverSubtitleSkeleton /> : t(`common:messageTypes.plural.${relationship?.message_type}`)
      }
      headerImage={<PartnerLogo imageUrl={otherPartner?.logo_url} size="large" isLoading={isLoading} />}
      actionConfig={
        isLoading || isRelationshipDeactivated
          ? undefined
          : {
              children: userCanCancelRequest
                ? t('connections:cancelRequest.label')
                : t('connections:deactivateRequest.label'),
              variant: 'secondary-danger',
              LeftIcon: TrashIcon,
              onClick: () => setShowConfirmDelete(true),
              analyticsId: 'connections:deactivate_request',
            }
      }
    >
      {isLoading || !relationship ? (
        <SlideOverContentSkeleton />
      ) : (
        <>
          <div className="border-b border-gray-200 pb-6">
            <RelationshipDetailsHeader relationship={relationship} currentPartnerId={currentPartner.id} />
          </div>
          <div className="pt-6">
            <RelationshipTimeline relationship={relationship} />
          </div>
        </>
      )}
      {otherPartner && relationship && (
        <ConfirmDeletePopup
          open={showConfirmDelete}
          isDeleting={isDeactivingRelationship}
          onClose={() => setShowConfirmDelete(false)}
          onDelete={() =>
            mutate(
              {
                relationshipId: relationship.id,
                partnerId: otherPartner.id,
              },
              {
                onSuccess: () => {
                  setShowConfirmDelete(false);
                  navigate(routeToPage(ConnectionsRoutes.partnerDetails, { partnerId: otherPartner.id }));
                },
              },
            )
          }
          title={
            userCanCancelRequest
              ? t('connections:cancelRequest.confirm.title')
              : t('connections:deactivateRequest.confirm.title')
          }
          description={
            userCanCancelRequest ? (
              <Translate i18nKey="connections:cancelRequest.confirm.description" />
            ) : (
              <Translate i18nKey="connections:deactivateRequest.confirm.description" />
            )
          }
          cancelText={
            userCanCancelRequest
              ? t('connections:cancelRequest.confirm.cancelButton')
              : t('connections:deactivateRequest.confirm.cancelButton')
          }
          deleteText={
            userCanCancelRequest
              ? t('connections:cancelRequest.confirm.deleteButton')
              : t('connections:deactivateRequest.confirm.deleteButton')
          }
        />
      )}
      {!isLoading && children}
    </SlideOver>
  );
};
