import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Section } from 'components/Display/Section/Section';
import { Button } from 'components/Form/Button/Button';
import { Translate } from 'components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { addHostToUrl, downloadFile } from 'services/http/http';
import { SUPPORT_EMAIL } from 'support/helpers/const/const';
import { DocumentType, EdifactStandard, IntegrationProcessDTO } from 'support/types';

export const EDIFACTIntegrationProcessTestSideInformation = ({ process }: { process: IntegrationProcessDTO }) => {
  const { t } = useTranslation();
  const eficaftStandard = process.configuration?.standard as EdifactStandard | undefined;
  const documentType = process.messageType as DocumentType;

  return (
    <div className="space-y-16">
      {eficaftStandard ? (
        <Section.Vertical
          header={
            <Section.Title>
              {t('integrations:processes.new.test.edifact.aside.sections.guidelines.title')}
            </Section.Title>
          }
        >
          <Button
            size="extra-small"
            variant="secondary"
            LeftIcon={ArrowDownTrayIcon}
            onClick={() => downloadFile(getGuidelinesUrl(documentType, eficaftStandard))}
            analyticsId="integrations:process-test-download-guidelines"
          >
            {t('integrations:processes.new.test.edifact.aside.actions.downloadGuidelines')}
          </Button>
        </Section.Vertical>
      ) : null}
      <Section.Vertical
        header={
          <Section.Title>{t('integrations:processes.new.test.edifact.aside.sections.scenarios.title')}</Section.Title>
        }
      >
        <div className="text-sm font-medium text-gray-700">
          <Translate i18nKey={'integrations:processes.new.test.edifact.aside.sections.scenarios.content'} />
        </div>
      </Section.Vertical>
      <Section.Vertical
        header={
          <Section.Title>
            {t('integrations:processes.new.test.edifact.aside.sections.additionalInformation.title')}
          </Section.Title>
        }
      >
        <div className="text-sm font-medium text-gray-700">
          <Translate
            i18nKey={'integrations:processes.new.test.edifact.aside.sections.additionalInformation.content'}
            values={{ supportEmail: SUPPORT_EMAIL }}
          />
        </div>
      </Section.Vertical>
    </div>
  );
};

const getGuidelinesUrl = (documentType: DocumentType, standard: EdifactStandard): string => {
  switch (documentType) {
    case DocumentType.invoice:
      return addHostToUrl(`/assets/guidelines/EDIFACT-INVOIC-${standard}.pdf`);
    case DocumentType.order:
      return addHostToUrl(`/assets/guidelines/EDIFACT-ORDERS-${standard}.pdf`);
    case DocumentType.shippingNotice:
      return addHostToUrl(`/assets/guidelines/EDIFACT-DESDAV-${standard}.pdf`);
    default:
      return '';
  }
};
