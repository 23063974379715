import { ReactNode, useEffect, useRef } from 'react';

type ListboxHandlingOpenStateProps = {
  children: ReactNode;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};
export const ListboxHandlingOpenState = ({ children, open, onOpen, onClose }: ListboxHandlingOpenStateProps) => {
  const openStateRef = useRef(false);

  useEffect(() => {
    if (open !== openStateRef.current) {
      openStateRef.current = open;
      if (open) {
        onOpen?.();
      } else {
        onClose?.();
      }
    }
  }, [open, onOpen, onClose]);

  return children;
};
