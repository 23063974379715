import { Textarea } from 'components/Form/Input/Textarea/Textarea';
import { Button } from 'components/Form/Button/Button';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTradeRequestLastMessage } from 'services/repositories/partners/partners';

export const TradeRequestMessage = () => {
  const { t } = useTranslation();
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext<{ message: string }>();
  const { data: lastMessage } = useTradeRequestLastMessage();

  return (
    <div>
      <div className="flex items-center justify-between gap-4">
        <div>
          <div className="text-sm font-medium text-gray-700">{t('partners:request.message')}</div>
          <div className="pb-3 text-xs font-normal text-gray-500">{t('partners:request.messageHint')}</div>
        </div>
        <div>
          {lastMessage ? (
            <Button
              variant="secondary"
              size="extra-small"
              onClick={() => setValue('message', lastMessage)}
              analyticsId="partners:new_trade_request_use_message"
            >
              {t('partners:request.useLastMessage')}
            </Button>
          ) : null}
        </div>
      </div>
      <Textarea {...register('message')} errors={errors.message} />
    </div>
  );
};
