import { useTranslation } from 'react-i18next';
import { TransactionsRoutes } from 'support/types';
import { DashboardCard } from '../components/DashboardCard/DashboardCard';
import { DashboardSection } from '../components/DashboardSection';

export function FailedTransactions({ failedTransactionsCount }: { failedTransactionsCount: number }) {
  const { t } = useTranslation();
  return (
    <DashboardSection title={t('dashboard:errors.title')}>
      <DashboardCard
        to={TransactionsRoutes.blocked}
        testId="failedTransactions"
        title={t('dashboard:tradePartners.failedTransactions')}
        value={failedTransactionsCount}
        status="error"
      ></DashboardCard>
    </DashboardSection>
  );
}
