import { ui } from '@procuros/datachecks';
import { DisplayModes } from 'components/DataTable/Types';
import { useMemo } from 'react';
import { ModificationGroupsModalTrigger } from '../components/ModificationGroupsModalTrigger';
import { DocumentType } from 'support/types';
import { useRemovePercentageColumn } from 'components/WebEDI/hooks/useRemovePercentageColumn';

const PARTIAL_QUANTITY_FIELD_PATH = 'quantity';
const useStatusAndDeleteConfig = (config: ui.LineItemsSection['fields'], documentType: DocumentType) => {
  const quantityField = useMemo(() => {
    if (documentType === DocumentType.creditNote || documentType === DocumentType.productCatalog) {
      return undefined;
    }
    const quantityFields = config.filter(
      ({ path, is_readonly }: any) => path?.includes(PARTIAL_QUANTITY_FIELD_PATH) && !is_readonly,
    );
    return quantityFields[0]?.path;
  }, [config, documentType]);

  const updatedConfig = useMemo(() => {
    return config.map((field, index) => {
      if (index === 0) {
        const extraFields = {
          isStatusColumn: true,
          quantityField,
        };
        return {
          ...field,
          extraFields,
        };
      }
      return field;
    });
  }, [config, quantityField]);

  return { config: updatedConfig, deleteQuantityField: quantityField };
};

const useAddModificationGroupsModal = (config: ui.LineItemsSection['fields'], mode: DisplayModes) => {
  return useMemo(() => {
    return mode === DisplayModes.create || mode === DisplayModes.edit
      ? config
      : config.map((field) => {
          if (field.jsonata_expression) {
            return {
              ...field,
              extraFields: {
                rightAddon: <ModificationGroupsModalTrigger />,
              },
            };
          }
          return field;
        });
  }, [config, mode]);
};

const useAddTransportUnits = (config: ui.LineItemsSection['fields'], documentType: DocumentType) => {
  return useMemo(() => {
    if (documentType !== DocumentType.shippingNotice) {
      return config;
    }

    const transportUnitField = {
      path: '_internal.parent_transport_unit',
      type: ui.FieldTypes.select,
      modality: 'MANDATORY' as const,
      label: 'Transport unit',
      sort_order: -Infinity,
      type_config: {
        options_lookup_field: 'transport_units.unit_identifier',
      },
      extraFields: {
        disabledIfNested: true,
      },
      _internal: {
        level: 0,
        parent_line_item: '',
        parent_transport_unit: '',
      },
    };

    const updatedConfig = [...config];

    //Setting it right after unit of measure
    const unitIndex = updatedConfig.findIndex((field) => field.path === 'item.unit_of_measure');
    if (unitIndex !== -1) {
      updatedConfig.splice(unitIndex + 1, 0, transportUnitField as any);
    }

    return updatedConfig;
  }, [config, documentType]);
};

const useNameWidth = (config: ui.LineItemsSection['fields']) => {
  return useMemo(() => {
    return config.map((field) => {
      if (field.path === 'item.name') {
        return {
          ...field,
          extraFields: {
            ...(field as { extraFields?: Record<string, any> }).extraFields,
            width: '200px',
          },
        };
      }
      return field;
    });
  }, [config]);
};
export const useDocumentLineItemsConfig = (
  config: ui.LineItemsSection['fields'],
  mode: DisplayModes,
  documentType: DocumentType,
) => {
  const { config: updatedConfig, deleteQuantityField } = useStatusAndDeleteConfig(config, documentType);
  const configWithModificationGroupsModal = useAddModificationGroupsModal(updatedConfig, mode);
  const configWithTransportUnits = useAddTransportUnits(configWithModificationGroupsModal, documentType);
  const configWithoutModificationsPercentage = useRemovePercentageColumn(configWithTransportUnits);
  const configWithNameWidth = useNameWidth(configWithoutModificationsPercentage);

  return {
    config: configWithNameWidth,
    deleteQuantityField,
  };
};
