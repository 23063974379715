import { ReactNode } from 'react';

type DashboardSectionProps = {
  title: string;
  children: ReactNode;
  actions?: ReactNode;
};
export const DashboardSection = ({ title, children, actions }: DashboardSectionProps) => {
  return (
    <div className="pb-8">
      <div className="flex flex-wrap justify-between border-b border-gray-200 pb-4">
        <h3 className="text-lg font-bold text-gray-900">{title}</h3>
        {actions}
      </div>
      <div className="pt-5">{children}</div>
    </div>
  );
};
