import { ArrowDownTrayIcon, InboxIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import {
  downloadIntegrationProcessExample,
  useCreateIntegrationProcessTest,
  useIntegrationProcessTests,
} from 'services/repositories/integrationProcesses/integrationProcesses';
import { IntegrationProcessTestDTO, DocumentType } from 'support/types';
import { Button } from 'components/Form/Button/Button';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { IntegrationProcessTestList } from '../../../../components/IntegrationProcessTestList/IntegrationProcessTestList';

const AsyncInboundTest = ({
  documentType,
  processId,
  integrationId,
}: {
  documentType: DocumentType;
  processId: string;
  integrationId: string;
}) => {
  const { t } = useTranslation();

  return (
    <Button
      LeftIcon={ArrowDownTrayIcon}
      size="extra-small"
      variant="secondary"
      onClick={() => {
        downloadIntegrationProcessExample({
          integrationId,
          processId,
          type: 'INBOUND_ASYNCHRONOUS',
          filename: t(`common:messageTypes.plural.${documentType}`) + '.edi',
        });
      }}
      analyticsId="integrations:process-test-download-example"
    >
      {t('integrations:processes.new.test.edifact.INBOUND.async.actions.downloadExample', {
        documentType: t(`common:messageTypes.plural.${documentType}`),
      })}
    </Button>
  );
};

const SyncInboundTest = ({
  integrationId,
  processId,
  onShowItemDetails,
}: {
  integrationId: string;
  processId: string;
  onShowItemDetails: (item: IntegrationProcessTestDTO) => void;
}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  const { mutateAsync, isLoading: isCreatingTests } = useCreateIntegrationProcessTest();
  const {
    data,
    isInitialLoading,
    isFetching: isFetchingIntegrationProcessTests,
    refetch,
  } = useIntegrationProcessTests({
    variables: { integrationId, processId },
    refetchInterval: 10000,
  });

  const triggerTest = async () => {
    if (!integrationId || !processId) return;
    await mutateAsync({ integrationId, processId, data: { type: 'INBOUND_SYNCHRONOUS' } });
  };

  const refreshTests = async () => {
    try {
      await refetch({ throwOnError: true });
      addNotification(t('integrations:processes.new.test.refresh.success'));
    } catch (e) {
      addNotification(t('integrations:processes.new.test.refresh.error'), NotificationType.error);
    }
  };

  return (
    <div className="space-y-6">
      <p className="text-sm font-medium text-gray-700">
        {t('integrations:processes.new.test.edifact.INBOUND.sync.description')}
      </p>
      <div className="flex gap-2">
        <Button
          LeftIcon={InboxIcon}
          size="extra-small"
          variant="secondary"
          onClick={triggerTest}
          loading={isCreatingTests}
          analyticsId="integrations:process-test-trigger"
        >
          {t('integrations:processes.new.test.edifact.INBOUND.sync.action.triggerTest')}
        </Button>
        <Button
          size="extra-small"
          variant="secondary"
          LeftIcon={ArrowPathIcon}
          onClick={refreshTests}
          loading={isFetchingIntegrationProcessTests}
          analyticsId="integrations:process-test-refresh"
        >
          {t('common:refresh')}
        </Button>
      </div>
      <IntegrationProcessTestList isLoading={isInitialLoading} data={data} onShowItemDetails={onShowItemDetails} />
    </div>
  );
};

export const EDIFACTInboundTests = {
  Async: AsyncInboundTest,
  Sync: SyncInboundTest,
};
