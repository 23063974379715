import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

type ShippingNoticeSummaryValues = {
  totalQuantity: number;
  transportUnitCount: number;
  lineItemCount: number;
  deliveryDate?: number;
};

const calculateLineItemsQuantity = (lineItems: Array<any> | undefined): number => {
  return (
    lineItems?.reduce((acc, item) => {
      if (item.line_items?.length) {
        return acc + calculateLineItemsQuantity(item.line_items);
      }

      return acc + (item.shipped_quantity ?? 0);
    }, 0) || 0
  );
};

export const useShippingNoticeSummary = (): ShippingNoticeSummaryValues => {
  const [lineItems, transportUnits, expectedDeliveryDate] = useWatch({
    name: ['line_items', 'transport_units', 'info.0.expected_delivery_date'],
  });

  return useMemo(
    () => ({
      totalQuantity: calculateLineItemsQuantity(lineItems),
      lineItemCount: lineItems?.length ?? 0,
      transportUnitCount: transportUnits?.length ?? 0,
      deliveryDate: expectedDeliveryDate,
    }),
    [lineItems, transportUnits, expectedDeliveryDate],
  );
};
