import { createBrowserRouter, Navigate } from 'react-router-dom';
import { NotFoundPage } from 'components/Page/NotFoundPage';
import Root from './Root';
import {
  AuthRoutes,
  ConnectionsRoutes,
  DashboardRoutes,
  InboxRoutes,
  IntegrationProcessRoutes,
  IntegrationsRoutes,
  PricingConfigRoutes,
  SettingsRoutes,
  NetworkRoutes,
  TransactionsRoutes,
  PriceCatalogRoutes,
} from 'support/types';
import { Register } from 'pages/Auth/Register/Register';
import { Login } from 'pages/Auth/Login/Login';
import { ForgotPassword } from 'pages/Auth/ForgotPassword/ForgotPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
import { CreateAccount } from 'pages/Auth/CreateAccount/CreateAccount';
import { CompanyData } from 'pages/Settings/CompanyData/CompanyData';
import { Integrations } from 'pages/Integrations/Integrations';
import { ProfileAndPreferences } from 'pages/Settings/ProfileAndPreferences/ProfileAndPreferences';
import { CreateDocument } from 'pages/Transactions/CreateDocument/CreateDocument';
import { MessageViewPage } from 'pages/Transactions/TransactionViewPage/MessageViewPage/MessageViewPage';
import { Password } from 'pages/Settings/Password/Password';
import { EditPricingConfigsPage } from 'pages/PriceConfigs/EditPricingConfigs';
import { PricingConfigsPage } from 'pages/PriceConfigs/PricingConfigsPage';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import { Inbox } from 'pages/Inbox/Inbox';
import { NewPricingConfigsPage } from 'pages/PriceConfigs/NewPricingConfigs';
import { ConnectorSelector } from 'pages/Integrations/ConnectorSelector/ConnectorSelector';
import { FEATURE_FLAGS } from 'support/helpers/featureFlags/featureFlags';
import { NewEditIntegration } from 'pages/Integrations/NewEditIntegration/NewEditIntegration';
import { ViewIntegration } from 'pages/Integrations/ViewIntegration/ViewIntegration';
import { NewEditIntegrationProcess } from 'pages/IntegrationProcesses/NewEditIntegrationProcess/NewEditIntegrationProcess';
import { PartnerFeatureFlagGuard } from 'components/PartnerFeatureFlagGuard/PartnerFeatureFlagGuard';
import { Onboarding } from 'components/Onboarding/Onboarding';
import { MainErrorBoundary } from 'components/MainErrorBoundary/MainErrorBoundary';
import { ProcessTransformation } from 'pages/IntegrationProcesses/ProcessTransformation/ProcessTransformation';
import { Connections } from 'pages/Connections/Connections';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { TeamMembers } from 'pages/Settings/TeamMembers/TeamMembers';
import { TradePartners } from 'pages/TradePartners/TradePartners';
import { Transactions } from 'pages/Transactions/Transactions';
import { SenderEnvelopeViewPage } from 'pages/Transactions/TransactionViewPage/SenderEnvelopeView/SenderEnvelopeViewPage';
import { PriceCatalogsPage } from 'pages/PriceCatalogs/PriceCatalogsPage';
import { BlockedTransactions, ExpiredTransactions } from 'pages/Transactions/BlockedTransactions/BlockedTransactions';
import { EditDocument } from 'pages/Transactions/EditDocument/EditDocument';
import { ExpiredLink } from 'pages/Auth/ExpiredLink/ExpiredLink';

export const router = createBrowserRouter(
  [
    {
      element: <Root />,
      path: DashboardRoutes.root,
      errorElement: <MainErrorBoundary />,
      children: [
        {
          path: '*',
          element: <NotFoundPage />,
        },
        {
          path: DashboardRoutes.legacyRoot,
          element: <Navigate to={DashboardRoutes.root} replace />,
        },
        {
          path: SettingsRoutes.root,
          element: <Navigate to={SettingsRoutes.companyData} replace />,
        },
        {
          path: AuthRoutes.login,
          element: <Login />,
        },
        {
          path: AuthRoutes.register,
          element: <Register />,
        },
        {
          path: AuthRoutes.forgotPassword,
          element: <ForgotPassword />,
        },
        {
          path: AuthRoutes.resetPassword,
          element: <ResetPassword />,
        },
        {
          path: AuthRoutes.createAccount,
          element: <CreateAccount />,
        },
        {
          path: AuthRoutes.expiredLink,
          element: <ExpiredLink />,
        },
        {
          index: true,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Dashboard />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: InboxRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Inbox />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: NetworkRoutes.deprecatedRoot,
          element: <Navigate to={NetworkRoutes.root} replace />,
        },
        {
          path: NetworkRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <TradePartners />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <PartnerFeatureFlagGuard
                  to={DashboardRoutes.root}
                  featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
                >
                  <PriceCatalogsPage />
                </PartnerFeatureFlagGuard>
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.relationshipDetails,
          element: (
            <PrivateRoute>
              <Onboarding>
                <PartnerFeatureFlagGuard
                  to={DashboardRoutes.root}
                  featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
                >
                  <PriceCatalogsPage />
                </PartnerFeatureFlagGuard>
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: PricingConfigRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <PricingConfigsPage />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: PricingConfigRoutes.view,
          element: (
            <PrivateRoute>
              <Onboarding>
                <EditPricingConfigsPage />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: PricingConfigRoutes.new,
          element: (
            <PrivateRoute>
              <Onboarding>
                <NewPricingConfigsPage />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Transactions />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.blocked,
          element: (
            <PrivateRoute>
              <Onboarding>
                <BlockedTransactions />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.expired,
          element: (
            <PrivateRoute>
              <Onboarding>
                <ExpiredTransactions />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.viewMessage,
          element: (
            <PrivateRoute>
              <Onboarding>
                <MessageViewPage />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.viewSenderEnvelope,
          element: (
            <PrivateRoute>
              <Onboarding>
                <SenderEnvelopeViewPage />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.createStandalone,
          element: (
            <PrivateRoute>
              <Onboarding>
                <CreateDocument />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.create,
          element: (
            <PrivateRoute>
              <Onboarding>
                <CreateDocument />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.edit,
          element: (
            <PrivateRoute>
              <Onboarding>
                <EditDocument />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.testRelationshipMessageDetails,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Connections />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.testRelationshipTransactionError,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Connections />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.testRelationship,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Connections />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.partnerDetails,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Connections />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.relationshipDetails,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Connections />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Connections />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationsRoutes.root,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Integrations />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationsRoutes.new,
          element: (
            <PrivateRoute>
              <Onboarding>
                <ConnectorSelector />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationsRoutes.create,
          element: (
            <PrivateRoute>
              <Onboarding>
                <NewEditIntegration />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationsRoutes.edit,
          element: (
            <PrivateRoute>
              <Onboarding>
                <PartnerFeatureFlagGuard to={IntegrationsRoutes.root} featureFlags={[FEATURE_FLAGS.AS2_SETUP]}>
                  <NewEditIntegration />
                </PartnerFeatureFlagGuard>
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationsRoutes.view,
          element: (
            <PrivateRoute>
              <Onboarding>
                <PartnerFeatureFlagGuard to={IntegrationsRoutes.root} featureFlags={[FEATURE_FLAGS.AS2_SETUP]}>
                  <ViewIntegration />
                </PartnerFeatureFlagGuard>
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationProcessRoutes.new,
          element: (
            <PrivateRoute>
              <Onboarding>
                <NewEditIntegrationProcess />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: IntegrationProcessRoutes.edit,
          element: (
            <PrivateRoute>
              <Onboarding>
                <NewEditIntegrationProcess />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.companyData,
          element: (
            <PrivateRoute>
              <Onboarding>
                <CompanyData />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.teamMembers,
          element: (
            <PrivateRoute>
              <Onboarding>
                <TeamMembers />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.profileAndPreferences,
          element: (
            <PrivateRoute>
              <Onboarding>
                <ProfileAndPreferences />
              </Onboarding>
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.password,
          element: (
            <PrivateRoute>
              <Onboarding>
                <Password />
              </Onboarding>
            </PrivateRoute>
          ),
        },

        {
          path: IntegrationProcessRoutes.transformation,
          element: (
            <PrivateRoute>
              <ProcessTransformation />
            </PrivateRoute>
          ),
        },
      ],
    },
  ],
  {
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true,
    },
  },
);
