import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { isActionColumn } from './useIsActionColumn';

export const useColumnWidth = <TData, TValue>(columns: Array<ColumnDef<TData, TValue>>, equalWidthColumns: boolean) => {
  const numberOfActionColumns = useMemo(() => columns.filter((column) => isActionColumn(column)).length, [columns]);
  const totalActionColumnsWidth = useMemo(() => 40 * numberOfActionColumns, [numberOfActionColumns]);
  // Calculate the width for the non-action columns
  const columnsWidth = useMemo(
    () =>
      equalWidthColumns
        ? `calc((100% - ${totalActionColumnsWidth}px) / ${columns.length - numberOfActionColumns})`
        : 'auto',
    [equalWidthColumns, totalActionColumnsWidth, columns.length, numberOfActionColumns],
  );

  return columnsWidth;
};
