import { FC, ChangeEvent, useState, useEffect, startTransition } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Input as TextInput } from '../../Form/Input';
import { useDebounceCallback } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

export type Props = {
  filter?: string;
  onFilterChange: (filter: string) => void;
};

export const Search: FC<Props> = ({ filter = '', onFilterChange }) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState(filter);
  const debouncedOnFilterChange = useDebounceCallback(onFilterChange, 200);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilterValue(newValue);
    startTransition(() => {
      debouncedOnFilterChange(newValue);
    });
  };

  /**
   * Reset the filter whe the filter value changes.
   * We need to do this because we are debouncing the calls directly from this component instead of the parent
   */
  useEffect(() => {
    if (filter === '') {
      setFilterValue(filter);
    }
  }, [filter]);

  return (
    <div className="flex gap-3">
      <TextInput
        placeholder={t('common:dataTable.search.placeholder')}
        leftIcon={<MagnifyingGlassIcon className="size-4 text-gray-400" />}
        type="text"
        value={filterValue}
        onChange={handleChange}
        className="w-[220px]"
      />
    </div>
  );
};
