import { WebEDISection } from '../WebEDISection/WebEDISection';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ui } from '@procuros/datachecks';
import { useTranslation } from 'react-i18next';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import { DisplayModes } from 'components/DataTable/Types';
import { Divider } from 'components/Divider/Divider';
import { Button } from 'components/Form/Button';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { memo, ReactNode } from 'react';
import { DocumentModificationGroup } from './DocumentModificationGroup';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';

export const DocumentModificationGroups = memo(({ config, mode }: DocumentModificationGroupsProps) => {
  return (
    <DataTableProvider name={DATA_PREFIX}>
      <ModificationGroupsSections config={config} mode={mode} />
    </DataTableProvider>
  );
});

DocumentModificationGroups.displayName = 'DocumentModificationGroups';

const ModificationGroupsSections = ({
  config,
  mode,
}: {
  config: ui.GlobalModificationsSection;
  mode: DisplayModes;
}) => {
  const { t } = useTranslation();
  const { label: title, description, sections } = config;
  const { fields, append, remove } = useDataTableContext();
  const emptyField = useEmptyStructure(fields);
  if (!sections?.length) return null;
  return (
    <WebEDISectionWrapper mode={mode} fields={fields} description={description} title={title}>
      <div className="space-y-6">
        <div className="space-y-4">
          <DocumentSectionError path={DATA_PREFIX} />
          {fields.map((_, modificationLevelIndex) => (
            <WebEDISection
              key={modificationLevelIndex}
              header={{
                title:
                  mode === DisplayModes.view
                    ? fields.length > 1
                      ? t('webedi:global_modifications.level_title.view', {
                          title,
                          level: modificationLevelIndex + 1,
                        })
                      : title
                    : t('webedi:global_modifications.level_title.create', { level: modificationLevelIndex + 1 }),
                spacing: 'small',
                action: mode !== DisplayModes.view && (
                  <Button
                    variant="minimal"
                    size="small"
                    iconOnly
                    LeftIcon={XMarkIcon}
                    onClick={() => remove(modificationLevelIndex)}
                    analyticsId="webedi:modification_group_remove"
                  >
                    {t('webedi:global_modifications.level_delete.label', { level: modificationLevelIndex + 1 })}
                  </Button>
                ),
              }}
            >
              <div className="space-y-6">
                <DocumentSectionError path={`${DATA_PREFIX}.${modificationLevelIndex}`} />
                {sections.map((section) => {
                  const dataPrefix = `${DATA_PREFIX}.${modificationLevelIndex}.${section.path}`;
                  return (
                    <div className="space-y-4" key={`${section.path}${fields.length}`}>
                      <DocumentSectionError path={dataPrefix} />
                      <DataTableProvider name={dataPrefix}>
                        <DocumentModificationGroup mode={mode} config={section} dataPrefix={dataPrefix} />
                      </DataTableProvider>
                    </div>
                  );
                })}
              </div>
            </WebEDISection>
          ))}
        </div>
        {!fields.length ? <EmptyState mode={mode} /> : null}
        {mode !== DisplayModes.view && (
          <Divider>
            <Button
              variant="minimal"
              size="extra-small"
              LeftIcon={PlusIcon}
              onClick={() => append(emptyField)}
              analyticsId="webedi:modification_group_add"
            >
              {t('webedi:global_modifications.add_button.text')}
            </Button>
          </Divider>
        )}
      </div>
    </WebEDISectionWrapper>
  );
};

const DATA_PREFIX = 'modification_groups';
type DocumentModificationGroupsProps = {
  config: ui.GlobalModificationsSection;
  mode: DisplayModes;
};

const EmptyState = ({ mode }: { mode: DisplayModes }) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col items-center gap-3 rounded-md bg-gray-50 p-4 text-sm text-gray-500">
      <span>{t(`webedi:global_modifications.empty_state.${mode}.text`)}</span>
    </div>
  );
};

/**
 * i18n
 * t('webedi:global_modifications.empty_state.view.text')
 * t('webedi:global_modifications.empty_state.create.text')
 * t('webedi:global_modifications.empty_state.edit.text')
 */

const WebEDISectionWrapper = ({
  children,
  mode,
  fields,
  description,
  title,
}: {
  children: ReactNode;
  mode: DisplayModes;
  fields: Array<unknown> | undefined;
  description: string | undefined;
  title: string;
}) => {
  return mode === DisplayModes.view && fields?.length ? (
    <>{children}</>
  ) : (
    <WebEDISection header={{ title, spacing: 'medium', description }}>{children}</WebEDISection>
  );
};
