import {
  readEdifact,
  readTradacoms,
  writeEdifact,
  writeTradacoms,
  readXml,
  writeXml,
  writeCsv,
  readCsv,
  readEdifactV2,
  writeEdifactV2,
  readPhbest,
  writePhbest,
  readFixed,
  writeFixed,
} from '@procuros/parser';
import { DataType } from 'pages/IntegrationProcesses/ProcessTransformation/helpers';
import { parseJson } from 'support/helpers/generic/generic';

const getDenormalizeOptions = (
  context: Record<string, unknown> | undefined,
  rawType: DataType,
): Record<string, unknown> | undefined => {
  const denormalizeOptions = context?.denormalize as Record<string, unknown>;

  const dataTypeDenormalizeOptions = denormalizeOptions?.[rawType] as Record<string, unknown> | undefined;

  return dataTypeDenormalizeOptions ?? {};
};

export type NormalizationError = {
  message: string;
  line?: number;
  column?: number;
};

export type NormalizationResult = {
  success: boolean;
  normalizedPayload?: unknown;
  errorMessage?: string;
  errors?: Array<NormalizationError>;
};

export const normalizeRawPayload = ({
  rawPayload,
  normalizerConfig,
  rawType,
}: {
  rawPayload: string;
  rawType: DataType;
  normalizerConfig?: Record<string, unknown>;
}): NormalizationResult => {
  if (rawType === 'JSON') {
    const jsonParseResponse = parseJson(rawPayload);

    if (!jsonParseResponse.success) {
      return {
        success: false,
        errorMessage: 'Unknown error normalizing JSON',
        errors: [],
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: jsonParseResponse.result,
    };
  }

  if (rawType === 'EDIFACT') {
    const edifactReaderResult = readEdifact(rawPayload);

    if (!edifactReaderResult.success) {
      return {
        success: false,
        errorMessage: edifactReaderResult.error_message || 'Unknown error normalizing Edifact',
        errors: edifactReaderResult.errors || [],
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: edifactReaderResult.result,
    };
  }

  if (rawType === 'EDIFACT_V2') {
    const edifactReaderResult = readEdifactV2(rawPayload);

    if (!edifactReaderResult.success) {
      return {
        success: false,
        errorMessage: edifactReaderResult.error_message || 'Unknown error normalizing Edifact',
        errors: edifactReaderResult.errors || [],
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: edifactReaderResult.result,
    };
  }

  if (rawType === 'TRADACOMS') {
    const tradacomsReaderResult = readTradacoms(rawPayload);

    if (!tradacomsReaderResult.success) {
      return {
        success: false,
        errorMessage: tradacomsReaderResult.error_message || 'Unknown error normalizing Tradacoms',
        errors: tradacomsReaderResult.errors || [],
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: tradacomsReaderResult.result,
    };
  }

  if (rawType === 'FIXED') {
    const fixedReaderResult = readFixed(rawPayload, normalizerConfig as any);

    if (!fixedReaderResult.success) {
      return {
        success: false,
        errorMessage: fixedReaderResult.error_message || 'Unknown error normalizing Fixed-Width',
        errors: fixedReaderResult.errors || [],
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: fixedReaderResult.result,
    };
  }

  if (rawType === 'PHBEST') {
    const phbestReaderResult = readPhbest(rawPayload);

    if (!phbestReaderResult.success) {
      return {
        success: false,
        errorMessage: phbestReaderResult.error_message || 'Unknown error normalizing Phbest',
        errors: phbestReaderResult.errors || [],
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: phbestReaderResult.result,
    };
  }

  if (rawType === 'XML') {
    const xmlReaderResult = readXml(rawPayload);

    if (!xmlReaderResult.success) {
      return {
        success: false,
        errorMessage: xmlReaderResult.error_message || 'Unknown error normalizing Xml',
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: xmlReaderResult.result,
    };
  }

  if (rawType === 'CSV') {
    const csvReaderResult = readCsv(rawPayload, normalizerConfig as any);

    if (!csvReaderResult.success) {
      return {
        success: false,
        errorMessage: csvReaderResult.error_message || 'Unknown error normalizing Csv',
        normalizedPayload: {},
      };
    }

    return {
      success: true,
      normalizedPayload: csvReaderResult.result,
    };
  }

  return { success: false, errorMessage: `Data type ${rawType} cannot be normalized` };
};

export type DenormalizationResult = {
  success: boolean;
  rawPayload?: string;
  outputCharset?: string;
  errorMessage?: string;
};

export const denormalizeNormalizedPayload = ({
  normalizedPayload,
  rawType,
  normalizerConfig,
  context,
}: {
  normalizedPayload: Record<string, unknown>;
  rawType: DataType;
  normalizerConfig?: Record<string, unknown>;
  context?: Record<string, unknown>;
}): DenormalizationResult => {
  if (rawType === 'JSON') {
    return {
      success: true,
      rawPayload: JSON.stringify(normalizedPayload, null, 2),
    };
  }

  if (rawType === 'EDIFACT') {
    const edifactWriterResult = writeEdifact(normalizedPayload, {
      multiline: true,
      forceUNASegment: true,
      ...getDenormalizeOptions(context, rawType),
    });

    if (!edifactWriterResult.success) {
      return {
        success: false,
        errorMessage: edifactWriterResult.error_message || 'Unknown error denormalizing Edifact',
      };
    }

    return {
      success: true,
      rawPayload: edifactWriterResult.result?.output,
      outputCharset: edifactWriterResult.result?.charset,
    };
  }

  if (rawType === 'EDIFACT_V2') {
    const edifactWriterResult = writeEdifactV2(normalizedPayload, {
      multiline: true,
      forceUNASegment: true,
      ...getDenormalizeOptions(context, rawType),
    });

    if (!edifactWriterResult.success) {
      return {
        success: false,
        errorMessage: edifactWriterResult.error_message || 'Unknown error denormalizing Edifact',
      };
    }

    return {
      success: true,
      rawPayload: edifactWriterResult.result?.output,
      outputCharset: edifactWriterResult.result?.charset,
    };
  }

  if (rawType === 'TRADACOMS') {
    const tradacomsWriterResult = writeTradacoms(normalizedPayload);

    if (!tradacomsWriterResult.success) {
      return {
        success: false,
        errorMessage: tradacomsWriterResult.error_message || 'Unknown error denormalizing Tradacoms',
      };
    }

    return {
      success: true,
      rawPayload: tradacomsWriterResult.result,
    };
  }

  if (rawType === 'FIXED') {
    const fixedWriterResult = writeFixed(normalizedPayload as any, normalizerConfig as any);

    if (!fixedWriterResult.success) {
      return {
        success: false,
        errorMessage: fixedWriterResult.error_message || 'Unknown error denormalizing Fixed-Width',
      };
    }

    return {
      success: true,
      rawPayload: fixedWriterResult.result,
    };
  }

  if (rawType === 'PHBEST') {
    const phbestWriterResult = writePhbest(normalizedPayload);

    if (!phbestWriterResult.success) {
      return {
        success: false,
        errorMessage: phbestWriterResult.error_message || 'Unknown error denormalizing Phbest',
      };
    }

    return {
      success: true,
      rawPayload: phbestWriterResult.result,
    };
  }

  if (rawType === 'XML') {
    const xmlWriterResult = writeXml(normalizedPayload, { format: true });

    if (!xmlWriterResult.success) {
      return {
        success: false,
        errorMessage: xmlWriterResult.error_message || 'Unknown error denormalizing Xml',
      };
    }

    return {
      success: true,
      rawPayload: xmlWriterResult.result,
    };
  }

  if (rawType === 'CSV') {
    const csvWriterResult = writeCsv(normalizedPayload as any, normalizerConfig as any);

    if (!csvWriterResult.success) {
      return {
        success: false,
        errorMessage: csvWriterResult.error_message || 'Unknown error denormalizing Csv',
      };
    }

    return {
      success: true,
      rawPayload: csvWriterResult.result,
    };
  }

  return { success: false, errorMessage: `Data type ${rawType} cannot be denormalized` };
};
