import { PropertyFilter, PropertyFilterType, SearchPropertyFilter } from 'components/ListTable/types';
import { TransactionFlowState, TransactionState } from 'support/types';
import { t } from 'i18next';
import { TransactionFilterKey } from './types';
import { buildDocumentTypeFilter } from 'components/ListTable/Filters/helpers';

const SearchFilter = () =>
  ({
    key: TransactionFilterKey.transactionId,
    type: PropertyFilterType.search,
    placeholder: t('transactions:list.filter.searchByExternalReference.placeholder'),
    label: '',
    value: '',
  }) as SearchPropertyFilter;

const PartnerFilter = () => ({
  key: TransactionFilterKey.partnerId,
  type: PropertyFilterType.dropdown,
  label: t('transactions:list.filter.partnerOptions.label'),
  value: '',
  dropDownOptions: {
    dropdownValues: [],
  },
  eventDetails: {
    event: 'filter_changed',
    properties: {
      filter_type: 'partner',
    },
  },
});

const StateFilter = () => ({
  key: TransactionFilterKey.transactionState,
  type: PropertyFilterType.dropdown,
  label: t('transactions:list.filter.messageStateOptions.label'),
  value: '',
  dropDownOptions: {
    dropdownValues: [
      {
        label: t('transactions:list.filter.messageStateOptions.options.inProgress'),
        value: TransactionState.pending,
      },
      { label: t('transactions:list.filter.messageStateOptions.options.delivered'), value: TransactionState.success },
      { label: t('transactions:list.filter.messageStateOptions.options.dropped'), value: TransactionState.dropped },
    ],
  },
  eventDetails: {
    event: 'filter_changed',
    properties: {
      filter_type: 'transaction_state',
    },
  },
});

const FlowFilter = () => ({
  key: TransactionFilterKey.transactionFlow,
  type: PropertyFilterType.dropdown,
  value: '',
  label: t('transactions:list.filter.messageFlowOptions.label'),
  dropDownOptions: {
    dropdownValues: [
      { label: t('transactions:list.filter.messageFlowOptions.options.live'), value: TransactionFlowState.live },
      { label: t('transactions:list.filter.messageFlowOptions.options.test'), value: TransactionFlowState.test },
    ],
  },
  eventDetails: {
    event: 'filter_changed',
    properties: {
      filter_type: 'transaction_flow',
    },
  },
});

const DateFilter = () => ({
  key: TransactionFilterKey.transactionCreatedBetween,
  type: PropertyFilterType.date,
  label: t('transactions:list.filter.date.label'),
  placeholder: t('transactions:list.filter.date.label'),
  value: '',
  eventDetails: {
    event: 'filter_changed',
    properties: {
      filter_type: 'transaction_date',
    },
  },
});

const ActionRequiredFilter = () => ({
  key: TransactionFilterKey.actionRequired,
  type: PropertyFilterType.dropdown,
  label: t('connections:list.filter.actionRequired.label'),
  value: '',
  dropDownOptions: {
    dropdownValues: [
      { label: t('connections:list.filter.actionRequired.required.label'), value: 'true' },
      { label: t('connections:list.filter.actionRequired.notRequired.label'), value: 'false' },
    ],
  },
});

export const getDefaultFilterConfig = (): Array<PropertyFilter | SearchPropertyFilter> => [
  SearchFilter(),
  buildDocumentTypeFilter(TransactionFilterKey.transactionType),
  PartnerFilter(),
  StateFilter(),
  FlowFilter(),
  DateFilter(),
];

export const getBlockedTransactionsFilterConfig = (): Array<PropertyFilter | SearchPropertyFilter> => [
  SearchFilter(),
  buildDocumentTypeFilter(TransactionFilterKey.transactionType),
  PartnerFilter(),
  StateFilter(),
  ActionRequiredFilter(),
  DateFilter(),
];
