import { generateUUID } from 'support/helpers/generic/generic';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export enum NotificationType {
  success = 'success',
  error = 'error',
}

export type Notification = {
  id: string;
  title: string;
  subtitle?: string;
  type: NotificationType;
};

export type State = {
  notifications: Notification[];
};

export type Actions = {
  addNotification: (text: { title: string; subtitle?: string } | string, type?: NotificationType) => void;
  removeNotification: (id: string) => void;
};

export const initialState: State = {
  notifications: [],
};

export const useNotificationStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,

    addNotification: (
      text: Parameters<Actions['addNotification']>['0'],
      type: NotificationType = NotificationType.success,
    ) => {
      set((state) => {
        state.notifications.push({
          id: generateUUID(),
          title: typeof text === 'string' ? text : text.title,
          subtitle: typeof text === 'string' ? undefined : text.subtitle,
          type,
        });
      });
    },

    removeNotification: (id: string) => {
      set((state) => {
        state.notifications = state.notifications.filter((notification) => notification.id !== id);
      });
    },
  })),
);

export const addNotification = (
  text: Parameters<Actions['addNotification']>['0'],
  type: NotificationType = NotificationType.success,
) => {
  return useNotificationStore.getState().addNotification(text, type);
};
export const removeNotification = (id: string) => {
  return useNotificationStore.getState().removeNotification(id);
};
