import { PartyDTO } from 'support/types/dtos';

export enum TransactionFilterKey {
  transactionId = 'transaction_id',
  transactionType = 'transaction_type',
  transactionFlow = 'transaction_flow',
  partnerId = 'partner_id',
  transactionState = 'transaction_state',
  transactionCreatedBetween = 'transaction_created_between',
  actionRequired = 'action_required',
  onlyExpired = 'only_expired',
}

export type ErrorContext = {
  reason: string;
};

export type ContentAddress = {
  title: string;
  party?: PartyDTO;
};
