import { useRef, useState, useEffect } from 'react';
import { useResizeObserver } from 'usehooks-ts';
import { useMutationObserver } from './useMutationObserver';

type IsTruncatedReturnType = React.RefObject<HTMLDivElement>;

export default function useIsTruncated(): [IsTruncatedReturnType, boolean] {
  const ref = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const { width } = useResizeObserver({
    ref,
    box: 'border-box',
  });

  useMutationObserver(ref, () => {
    const el = ref.current;
    if (el) {
      setIsTruncated(el.scrollWidth > el.clientWidth);
    }
  });

  useEffect(() => {
    const el = ref.current;
    if (el) {
      setIsTruncated(el.scrollWidth > el.clientWidth);
    }
  }, [width]);

  return [ref, isTruncated];
}
