import { AllowedLanguages } from 'i18n';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResendEmailFormType } from '../../types';
import { useIsMutating } from '@tanstack/react-query';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Input } from 'components/Form/Input/Input/Input';

type DifferentEmailFormProps = {
  onCancel: () => void;
  onSubmit: (args: { email: string; name: string; language: AllowedLanguages }) => void;
};
export const DifferentEmailForm = ({ onCancel, onSubmit }: DifferentEmailFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ResendEmailFormType>();

  const isMutating = useIsMutating();
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-5 grid w-full grid-cols-2 gap-2">
      <Input
        label={t('connections:resendInvitationEmailAlert.contactName.label')}
        {...register('name')}
        required
        errors={errors.name}
      />
      <Input
        label={t('connections:resendInvitationEmailAlert.email.label')}
        type="email"
        {...register('email')}
        required
        errors={errors.email}
      />
      <Controller
        render={({ field, fieldState }) => (
          <Dropdown<AllowedLanguages>
            {...field}
            required
            wrapperClassName="col-span-full"
            label={t('connections:resendInvitationEmailAlert.language.label')}
            options={[
              { value: 'de', label: t('common:language.de') },
              { value: 'en', label: t('common:language.en') },
            ]}
            errors={fieldState.error}
          />
        )}
        name="language"
      />
      <div className="col-span-full mt-2 flex gap-2">
        <Button
          variant="secondary"
          onClick={onCancel}
          disabled={Boolean(isMutating)}
          analyticsId="testing-flow:invite_email_change_cancel"
        >
          {t('common:cancel')}
        </Button>

        <Button
          variant="primary"
          type="submit"
          loading={Boolean(isMutating)}
          analyticsId="testing-flow:invite_email_change_confirm"
        >
          {t('connections:resendInvitationEmailAlert.submitDifferentEmailCta')}
        </Button>
      </div>
    </form>
  );
};
