import { LoadingPage } from 'components/Loading/LoadingPage';
import { classNames } from 'support/helpers/generic/generic';
import { Sidebar } from './Sidebar/Sidebar';
import { useScrollRestoration } from 'use-scroll-restoration';
import { useLocation } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';
import { ReactNode, useEffect, useRef } from 'react';

type PageProps = {
  children: ReactNode;
  isLoading?: boolean;
  grayBackground?: boolean;
  sidebar?: ReactNode;
  isInternal?: boolean;
  isScrollable?: boolean;
};

function PageWrapper({
  children,
  isLoading,
  grayBackground,
  sidebar,
  isInternal = false,
  isScrollable = true,
}: PageProps) {
  /*
   * We need the useScrollRestoration here since the ScrollRestoration from react-router does not allow to select the container
   * Our scrollable container is not the window but the div with the class overflow-scroll
   */
  const key = `scroll-position-${useLocation().key}`;
  const { ref } = useScrollRestoration(key, {
    debounceTime: 200,
    persist: 'localStorage',
  });

  return (
    <div className="h-screen w-screen">
      {isInternal ? (
        <div className="fixed inset-x-0 top-0 z-20 flex h-4 justify-center bg-procuros-green-500">
          <span className="text-xs font-bold uppercase tracking-wide text-white">Internal</span>
        </div>
      ) : null}
      <div className={classNames('w-full h-full flex', isInternal ? 'pt-4' : undefined)}>
        <div className="sticky top-0 z-10 flex h-full">{sidebar ?? <Sidebar />}</div>
        <div
          ref={ref}
          className={classNames(
            isScrollable ? 'overflow-y-auto' : undefined,
            'h-full w-full overflow-x-auto',
            grayBackground ? 'bg-gray-100' : 'bg-white',
          )}
        >
          {isLoading ? (
            <div className="mx-auto h-screen">
              <LoadingPage />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
}

const Title = ({ children }: { children: ReactNode }) => (
  <h3 className="flex items-center gap-2 text-lg font-bold leading-10 text-gray-900">{children}</h3>
);
const Description = ({ children }: { children: ReactNode }) => (
  <p className="text-sm font-normal text-gray-500">{children}</p>
);

const Head = ({
  children,
  rightContent,
  title,
  description,
}: {
  rightContent?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  title?: ReactNode;
  description?: string;
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { height = 0 } = useResizeObserver({
    ref: headerRef,
    box: 'border-box',
  });

  useEffect(() => {
    document.documentElement.style.setProperty('--header-height', `${height}px`);
  }, [height]);

  const content = (
    <div className="relative flex w-full shrink-0 justify-between gap-2">
      {title ? (
        <div className="w-full">
          <Title>{title}</Title>
          {description ? <Description>{description}</Description> : null}
        </div>
      ) : null}
      {rightContent}
    </div>
  );

  return (
    <div
      ref={headerRef}
      className={'sticky top-0 z-20 mx-auto overflow-x-auto border-b border-gray-200 bg-white p-4 sm:px-6 md:px-8'}
    >
      <div className="flex">{content}</div>
      {children ? <div className="mt-2 flex w-full justify-between gap-2">{children}</div> : null}
    </div>
  );
};

const Section = ({
  children,
  leftAside,
  rightAside,
  topBanner,
  bottomBanner,
  testId,
}: {
  children: ReactNode;
  leftAside?: ReactNode;
  rightAside?: ReactNode;
  topBanner?: ReactNode;
  bottomBanner?: ReactNode;
  testId?: string;
}) => {
  return (
    <section className="overflow-x-auto" data-testid={testId}>
      <div className="flex w-full">
        {leftAside ? <div className="h-full">{leftAside}</div> : null}
        <div className="relative w-full overflow-y-auto" style={{ height: `var(--content-height)` }}>
          {topBanner ? <div className="sticky inset-x-0 top-0 z-20">{topBanner}</div> : null}
          <div className="px-4 py-6 sm:px-6 md:px-8">{children}</div>
          {bottomBanner ? <div className="sticky inset-x-0 bottom-0 z-20">{bottomBanner}</div> : null}
        </div>
        {rightAside ? <div className="h-full">{rightAside}</div> : null}
      </div>
    </section>
  );
};

export const Page = Object.assign(PageWrapper, { Head, Section, Title, Description });
