import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Form/Button/Button';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver/useYupValidationResolver';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useCreatePricingConfig, useUpdatePricingConfig } from 'services/repositories/pricingConfigs/pricingConfigs';
import {
  ModificationDTO,
  ModificationGroupDTO,
  ModificationReasonCode,
  ModificationType,
  PartnerDTO,
  PaymentTermDTO,
  PricingConfigDTO,
  PricingConfigModificationDTO,
  PricingConfigPayloadLineItemDTO,
} from 'support/types';
import { PricingConfigLineItems } from '../PricingConfigLineItems/PricingConfigLineItems';

import { addNotification } from 'stores/notifications/notifications';
import { usePartner } from 'services/repositories/partners/partners';
import { Page } from 'components/Page/Page';
import { validateModifications } from './helpers';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { ModificationValueForm } from '../InvoiceModifications/ModificationRows';
import { InvoiceModifications } from '../InvoiceModifications/InvoiceModifications';
import { PaymentTerms } from '../PaymentTerms/PaymentTerms';
import {
  ModificationCalculationType,
  getModificationCalculationType,
} from '../invoiceCalculations/invoiceCalculations';

type PricingConfigFormProps = {
  connectedPartners: Array<PartnerDTO>;
  pricingConfig: PricingConfigDTO | undefined;
  onCancel: () => void;
  onSuccess: (pricingConfig: PricingConfigDTO) => void;
};

type FormState = {
  name: string;
  vatId?: string;
  partnerId: string;
  lineItems: Array<PricingConfigPayloadLineItemDTO>;
};

const isInUpdateMode = (pricingConfig: PricingConfigDTO | undefined): pricingConfig is PricingConfigDTO => {
  return Boolean(pricingConfig?.id);
};

export function PricingConfigForm({ onCancel, onSuccess, pricingConfig, connectedPartners }: PricingConfigFormProps) {
  const createMutation = useCreatePricingConfig();
  const updateMutation = useUpdatePricingConfig();
  const inUpdateMode = isInUpdateMode(pricingConfig);
  const isMutating = createMutation.isLoading || updateMutation.isLoading;

  const { t } = useTranslation();
  const [invoiceModification, setInvoiceModification] = useState<
    ModificationGroupDTO & { modifications: Array<ModificationValueForm> }
  >(mapToInvoiceModificationGroup(pricingConfig?.payload?.modifications ?? []));

  const [paymentTerms, setPaymentTerms] = useState<Array<PaymentTermDTO>>(
    pricingConfig?.payload.paymentTerms ? pricingConfig.payload.paymentTerms : [],
  );

  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);

  const formSchema = Yup.object({
    name: Yup.string().required(t('priceList:form.validation.name') ?? ''),
    partnerId: Yup.string().required(t('priceList:form.validation.partnerId') ?? ''),
    lineItems: Yup.array()
      .of(
        Yup.object().shape({
          gtin: Yup.string().nullable(),
          name: Yup.string().nullable(),
          unitPrice: Yup.number().min(0).nullable(),
          salesTax: Yup.number().min(0).nullable(),
        }),
      )
      .required(t('priceList:form.validation.lineItems') ?? ''),
  });

  const resolver = useYupValidationResolver(formSchema);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormState>({
    resolver,
    defaultValues: {
      name: pricingConfig?.name ?? '',
      vatId: pricingConfig?.payload.vatId ?? '',
      partnerId: pricingConfig?.partner.id ?? '',
      lineItems: pricingConfig?.payload.lineItems ?? [],
    },
  });

  const [partnerId, lineItems] = useWatch({ name: ['partnerId', 'lineItems'], control });
  const { data: partner } = usePartner({ variables: { id: partnerId } });

  const onSubmit = (data: FormState) => {
    const errors = validateModifications(invoiceModification.modifications);
    if (errors) {
      setNonInputErrors(errors.map((errorField) => t('common:form.required', { field: t(errorField) })));
      return;
    }

    setNonInputErrors([]);
    const body = {
      name: data.name,
      payload: {
        vatId: data.vatId,
        lineItems: data.lineItems,
        modifications: mapToPricingConfigModifications([invoiceModification]),
        paymentTerms,
      },
    };

    if (inUpdateMode) {
      updateMutation.mutate(
        { partnerId: data.partnerId, pricingConfigId: pricingConfig.id, data: body },
        {
          onSuccess: () => {
            addNotification(t('priceList:form.notifications.update.success'));
            onSuccess(pricingConfig);
          },
          onError: (error) => {
            processSubmissionErrors({
              error,
              setInputError: undefined,
              setNonInputErrors: setNonInputErrors,
              defaultData: undefined,
              t,
            });
          },
        },
      );
    } else {
      createMutation.mutate(
        { partnerId: data.partnerId, data: body },
        {
          onSuccess: (pricingConfig) => {
            addNotification(t('priceList:form.notifications.create.success'));
            onSuccess(pricingConfig);
          },
          onError: (errors) => {
            processSubmissionErrors({
              error: errors,
              setInputError: undefined,
              setNonInputErrors: setNonInputErrors,
              defaultData: undefined,
              t,
            });
          },
        },
      );
    }
  };

  const CancelConfirmButtons = () => (
    <>
      <Button
        variant="secondary"
        disabled={isMutating}
        className="mr-2"
        onClick={onCancel}
        analyticsId="pricing-configs:new-config-cancel"
      >
        {t('common:cancel')}
      </Button>
      <Button
        type="submit"
        loading={isMutating}
        form="pricing-config-form"
        analyticsId="pricing_configs:new-config-create"
      >
        {t('common:save')}
      </Button>
    </>
  );

  return (
    <div className="pb-24">
      <Page.Head>
        <div className="flex w-full flex-row items-center justify-between">
          <div>
            <Page.Title>{inUpdateMode ? t('priceList:form.title.update') : t('priceList:form.title.new')}</Page.Title>
            <p className="mt-2 text-sm font-normal text-gray-500">{t('priceList:form.intro')}</p>
          </div>

          <div>
            <CancelConfirmButtons />
          </div>
        </div>
      </Page.Head>

      <Page.Section>
        <ValidationErrors errors={nonInputErrors} />
        <Form id="pricing-config-form" onSubmit={handleSubmit(onSubmit)} className="pt-4">
          <div className="flex flex-col gap-y-5">
            <div className="grid grid-cols-2 gap-x-10">
              <Input
                {...register('name')}
                placeholder={t('priceList:form.fields.name.placeholder')}
                label={t('priceList:form.fields.name.label')}
                errors={errors.name}
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-x-10">
              <Dropdown
                disabled={Boolean(inUpdateMode)}
                label={t('priceList:form.fields.partnerId.label')}
                required
                value={partnerId}
                options={connectedPartners.map((partner) => ({
                  value: partner.id ?? '',
                  label: partner.name ?? '',
                }))}
                onChange={(id) => setValue('partnerId', id, { shouldValidate: true, shouldDirty: true })}
                errors={errors.partnerId}
              />
              <Input
                {...register('vatId')}
                placeholder={t('priceList:form.fields.vatId.placeholder')}
                label={t('priceList:form.fields.vatId.label')}
                errors={errors.vatId}
                required={false}
              />
            </div>
          </div>
        </Form>

        <div className="my-3">
          <div>
            <InvoiceModifications
              modificationGroups={[invoiceModification]}
              onChange={(modificationGroups) => setInvoiceModification(modificationGroups[0])}
            />
          </div>
        </div>

        <div className="mb-3">
          <PaymentTerms onChange={setPaymentTerms} paymentTerms={paymentTerms} />
        </div>

        <div className="mt-5">
          <PricingConfigLineItems
            errors={errors}
            lineItems={lineItems}
            currency={partner?.settings?.currency}
            onChange={(lineItems) => setValue('lineItems', lineItems, { shouldValidate: true, shouldDirty: true })}
          />
        </div>
        <div className="mt-6 flex w-full flex-row items-center justify-start">
          <CancelConfirmButtons />
        </div>
      </Page.Section>
    </div>
  );
}

const mapToInvoiceModificationGroup = (modifications: Array<PricingConfigModificationDTO>): ModificationGroupDTO => ({
  level: 1,
  basis: 0,
  modifications: modifications.map(
    (modification): ModificationDTO => ({
      type: modification.type as ModificationType,
      reasonCode: modification.code as ModificationReasonCode,
      ...(modification.isPercentage
        ? { percentage: modification.amount, calculationType: ModificationCalculationType.relative }
        : { amount: modification.amount, calculationType: ModificationCalculationType.absolute }),
      tax: modification.taxPercent ? { percentage: modification.taxPercent, amount: 0 } : undefined,
    }),
  ),
});

const mapToPricingConfigModifications = (
  modificationGroups: Array<ModificationGroupDTO>,
): Array<PricingConfigModificationDTO> => {
  const levelOneModificationGroup = modificationGroups.find((modificationGroup) => modificationGroup.level === 1);
  return (
    levelOneModificationGroup?.modifications.map(
      (modification): PricingConfigModificationDTO => ({
        type: modification.type,
        code: modification.reasonCode ?? '',
        level: 1,
        amount: modification.amount ?? modification.percentage ?? 0,
        isPercentage: getModificationCalculationType(modification) === ModificationCalculationType.relative,
        taxPercent: modification.tax?.percentage,
      }),
    ) ?? []
  );
};
