import { useReadLocalStorage } from 'usehooks-ts';
import { UserKeys } from 'support/types/localStorage';

export const useIsPotentiallyAuthenticated = () => useReadLocalStorage(UserKeys.potentiallyAuthenticatedSession);
export const isPotentiallyAuthenticated = () => !!localStorage.getItem(UserKeys.potentiallyAuthenticatedSession);

export const setPotentiallyAuthenticated = (isAuthenticated: boolean) => {
  if (!isAuthenticated) {
    localStorage.removeItem(UserKeys.potentiallyAuthenticatedSession);
    window.dispatchEvent(new Event('storage'));
    return;
  }
  window.dispatchEvent(new Event('storage'));
  localStorage.setItem(UserKeys.potentiallyAuthenticatedSession, 'true');
};
