import { TrashIcon } from '@heroicons/react/20/solid';
import { Input } from 'components/Form/Input/Input/Input';
import { useTranslation } from 'react-i18next';
import { PaymentTermDTO } from 'support/types';

type PaymentTermRowProps = {
  index: number;
  paymentTerm: PaymentTermDTO;
  onChange: (index: number, paymentTerm: PaymentTermDTO) => void;
  deletePaymentTerm: (index: number) => void;
  disabled?: boolean;
};

export function PaymentTermRow({ index, paymentTerm, onChange, deletePaymentTerm, disabled }: PaymentTermRowProps) {
  const { payInNumberOfDays, percentage } = { ...paymentTerm };
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <div className="mb-2 flex flex-row items-center">
        <div className="mr-1 w-14">
          <Input
            name="Percentage"
            className="p-1 text-right"
            type="number"
            min={0}
            value={percentage ?? ''}
            rightAddon="%"
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);

              if (isNaN(newValue)) {
                return;
              }

              onChange(index, { ...paymentTerm, percentage: newValue });
            }}
            disabled={disabled}
          />
        </div>
        <div>{t('webedi:paymentTerms.tradePartnerWithin')}</div>
        <div className="mx-1 w-10">
          <Input
            name="Days"
            className="p-1 text-center"
            type="number"
            min={0}
            value={payInNumberOfDays ?? ''}
            onChange={(e) => {
              const newValue = parseInt(e.target.value);

              if (isNaN(newValue)) {
                return;
              }

              onChange(index, { ...paymentTerm, payInNumberOfDays: newValue });
            }}
            disabled={disabled}
          />
        </div>
        <div>{t('webedi:paymentTerms.days')}.</div>
      </div>
      <div className="ml-2 cursor-pointer text-procuros-green-500" onClick={() => deletePaymentTerm(index)}>
        <TrashIcon className="size-6 text-gray-400" />
      </div>
    </div>
  );
}
