import { Tab, TabList, TabGroup } from '@headlessui/react';
import { classNames } from 'support/helpers/generic/generic';

type TabsListProps = {
  tabs: Array<{ label: string; badge?: string | number; route?: string }>;
  onChange: (index: number) => void;
  selected: number;
};

export function TabsList({ tabs, onChange, selected }: TabsListProps) {
  return (
    <div className="mb-4">
      <TabGroup onChange={onChange} defaultIndex={0} selectedIndex={selected}>
        <TabList>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.label}
                    className={({ selected }) =>
                      classNames(
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none',
                        selected
                          ? 'border-procuros-green-500 text-procuros-green-500 '
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 ',
                      )
                    }
                  >
                    {tab.label}

                    {tab.badge && (
                      <span className="ml-2 rounded-full bg-gray-100 px-2 py-1 font-medium text-gray-800">
                        {tab.badge}
                      </span>
                    )}
                  </Tab>
                ))}
              </nav>
            </div>
          </div>
        </TabList>
      </TabGroup>
    </div>
  );
}
