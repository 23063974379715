import { formatBasedOnSortArray } from 'support/helpers/arrays/arrays';
import { ORDER_OF_DOCUMENTS } from 'support/helpers/const/const';
import { PartnerV2, DocumentType, RelationshipV2 } from 'support/types';
import { RelationshipBadge } from '../RelationshipBadge/RelationshipBadge';
import { useTranslation } from 'react-i18next';

export const DocumentTypeBadges = ({
  currentPartnerId,
  otherPartner,
}: {
  currentPartnerId: string;
  otherPartner: PartnerV2;
}) => {
  const { t } = useTranslation();
  const allRelationships: RelationshipV2[] = [
    ...(otherPartner?.receiver_relationships || []),
    ...(otherPartner?.sender_relationships || []),
  ].sort(
    formatBasedOnSortArray(ORDER_OF_DOCUMENTS, (array, value) => array.indexOf(value.message_type as DocumentType)),
  );
  return (
    <div className="text-sm text-gray-900 flex flex-wrap gap-1">
      {allRelationships?.map((relationship) => (
        <RelationshipBadge key={relationship.id} relationship={relationship} currentPartnerId={currentPartnerId}>
          {t(`common:messageTypes.plural.${relationship.message_type}`)}
        </RelationshipBadge>
      ))}
    </div>
  );
};
