import { VirtualItem } from '@tanstack/react-virtual';
import { DataRenderModes } from '../Types';
import { isActionColumn } from '../hooks/useIsActionColumn';
import { Td } from './Td';
import { ColumnDef, Row } from '@tanstack/react-table';

type VirtualizationConfig = {
  before: number;
  after: number;
  virtualRows: Array<VirtualItem<Element>>;
};
type TableBodyProps<TData, TValue> = {
  dataRenderMode: DataRenderModes;
  virtualizationConfig: VirtualizationConfig;
  rows: Array<Row<TData>>;
  columns: Array<ColumnDef<TData, TValue>>;
  showCellBorders: boolean;
  columnsWidth: string;
};

export function TableBody<TData, TValue>({
  columnsWidth,
  showCellBorders,
  dataRenderMode,
  virtualizationConfig,
  rows,
  columns,
}: TableBodyProps<TData, TValue>) {
  const { before, after, virtualRows } = virtualizationConfig;
  const colSpan = columns.length;
  return (
    <tbody>
      {dataRenderMode === DataRenderModes.virtualized ? (
        <>
          {before > 0 && (
            <tr>
              <td colSpan={colSpan} style={{ height: before }} />
            </tr>
          )}
          {virtualRows.map((virtualRow, index) => {
            const row = rows[virtualRow.index];
            return (
              <tr
                key={row.id}
                style={{
                  height: `${virtualRow.size}px`,
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const isAction = isActionColumn(cell.column.columnDef);
                  const fixedWidth = cell.column.columnDef.meta?.style?.width;
                  return (
                    <Td
                      isAction={isAction}
                      key={cell.id}
                      cell={cell as any}
                      isEven={virtualRow.index % 2 === 0}
                      isFirstColumn={!index}
                      isLastColumn={index === row.getVisibleCells().length - 1}
                      isLastRow={virtualRow.index === rows.length - 1}
                      betweenCellBorders={showCellBorders}
                      style={!isAction ? { width: columnsWidth, maxWidth: fixedWidth } : undefined}
                    />
                  );
                })}
              </tr>
            );
          })}
          {after > 0 && (
            <tr>
              <td colSpan={colSpan} style={{ height: after }} />
            </tr>
          )}
        </>
      ) : (
        rows.map((row, rowIndex) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
              const isAction = isActionColumn(cell.column.columnDef);
              const fixedWidth = cell.column.columnDef.meta?.style?.width;
              return (
                <Td
                  isAction={isAction}
                  key={cell.id}
                  cell={cell as any}
                  isEven={rowIndex % 2 === 0}
                  isFirstColumn={!index}
                  isLastColumn={index === row.getVisibleCells().length - 1}
                  isLastRow={rowIndex === rows.length - 1}
                  betweenCellBorders={showCellBorders}
                  style={!isAction ? { width: columnsWidth, maxWidth: fixedWidth } : undefined}
                />
              );
            })}
          </tr>
        ))
      )}
    </tbody>
  );
}
