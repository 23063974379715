import { useAnalytics, TrackFunction } from 'support/helpers/analytics/analytics';
import { FilterValue, PropertyFilter, PropertyFilterType, SearchPropertyFilter } from '../types';
import { DateFilter } from './DateFilter';
import { useMemo } from 'react';
import { FilterBadges } from './components/FilterBadges/FilterBadges';
import { DropdownFilter } from './DropdownFilter';
import { SearchFilter } from './SearchFilter';
import { useTranslation } from 'react-i18next';
import { CheckboxFilter } from './CheckboxFilter';

type FiltersProps = {
  propertyFilters: Array<PropertyFilter>;
  onFiltersChange: (filters: Record<string, string>) => void;
  filterValues: Array<FilterValue> | undefined;
  totalElements?: number | null;
  clearAllFilters: () => void;
};

const FilterCreator = ({
  track,
  filter,
  onChange,
}: {
  track: TrackFunction;
  filter: PropertyFilter;
  onChange: (filterKey: string, newValue: string) => void;
}) => {
  switch (filter.type) {
    case PropertyFilterType.dropdown:
      if (!filter.dropDownOptions) {
        return null;
      }

      return (
        <DropdownFilter
          className="min-w-44"
          label={filter.label}
          currentValue={filter.value}
          dropdownValues={filter.dropDownOptions.dropdownValues}
          filterKey={filter.key}
          onChange={(_, newValue) => {
            if (filter.eventDetails) {
              track(filter.eventDetails.event, filter.eventDetails.properties);
            }

            onChange(filter.key, newValue);
          }}
          hideValueFromButton
          defaultToFirstValue={filter.dropDownOptions.defaultToFirstValue}
          displayValueLabel={filter.dropDownOptions.dropdownDefaultLabel}
        />
      );

    case PropertyFilterType.date: {
      let startDate = filter.value;
      let endDate = startDate;

      if (filter.value?.indexOf(',') > -1) {
        startDate = filter.value.split(',')[0];
        endDate = filter.value.split(',')[1];
      }

      return (
        <DateFilter
          placeholder={filter.placeholder}
          startDate={startDate}
          endDate={endDate}
          filterKey={filter.key}
          onChange={(_, startDate, endDate) => {
            if (filter.eventDetails) {
              track(filter.eventDetails.event, filter.eventDetails.properties);
            }
            onChange(filter.key, `${startDate},${endDate}`);
          }}
        />
      );
    }

    case PropertyFilterType.checkbox:
      return (
        <CheckboxFilter
          label={filter.label}
          currentValue={filter.value}
          filterKey={filter.key}
          onChange={(_, newValue) => {
            if (filter.eventDetails) {
              track(filter.eventDetails.event, filter.eventDetails.properties);
            }
            onChange(filter.key, newValue);
          }}
        />
      );
    case PropertyFilterType.search:
    default:
      return null;
  }
};

const generateDefaultValues = (propertyFilters: Array<PropertyFilter>, key?: string) => {
  return propertyFilters.map((filter) => {
    if (!key || key === filter.key) {
      if (filter.type === PropertyFilterType.dropdown && filter.dropDownOptions?.defaultToFirstValue) {
        return { ...filter, value: filter.dropDownOptions.dropdownValues[0].value };
      }
      if (filter.type === PropertyFilterType.search || filter.type === PropertyFilterType.hidden) {
        return filter;
      }
      return { ...filter, value: '' };
    }
    return filter;
  });
};

const formatValues = (filterValues: Array<FilterValue | PropertyFilter> | undefined) => {
  if (!filterValues) return {};
  return filterValues?.reduce((acc, filter) => {
    return { ...acc, [filter.key]: filter.value };
  }, {});
};

export function Filters({
  propertyFilters,
  filterValues,
  onFiltersChange,
  clearAllFilters,
  totalElements,
}: FiltersProps) {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const formattedFilters = useMemo(() => formatValues(filterValues), [filterValues]);
  const search: SearchPropertyFilter | undefined = propertyFilters.find(isSearchFilterGuard);

  const badgesFilters: Array<FilterValue> = useMemo(() => {
    return Object.entries(formattedFilters)
      .map((filter) => {
        const propertyField = propertyFilters.find((propertyFilter) => propertyFilter.key === filter[0]);
        if (!propertyField) return undefined;
        if (
          [PropertyFilterType.search, PropertyFilterType.hidden, PropertyFilterType.checkbox].includes(
            propertyField.type,
          )
        ) {
          return undefined;
        }

        return {
          key: filter[0],
          value:
            propertyField.dropDownOptions?.dropdownValues.find((dropdownValue) => dropdownValue.value === filter[1])
              ?.label || filter[1],
        };
      })
      .filter(Boolean) as Array<FilterValue>;
  }, [formattedFilters, propertyFilters]);

  if (!propertyFilters.length) return null;

  return (
    <div className="grid gap-2">
      <div className="flex w-full flex-wrap gap-4">
        {!!search && (
          <SearchFilter
            placeholder={
              typeof totalElements === 'number'
                ? search.placeholder(totalElements)
                : typeof search.placeholder === 'string'
                  ? search.placeholder
                  : t('common:search.placeholder')
            }
            currentValue={filterValues?.find((filter) => filter.key === search.key)?.value || ''}
            filterKey={search.key}
            onChange={(filterKey, newValue) => onFiltersChange({ ...formattedFilters, [filterKey]: newValue })}
          />
        )}

        <div className="flex flex-wrap gap-4">
          {propertyFilters
            .filter(({ type }) => type !== PropertyFilterType.search)
            .map((filter: PropertyFilter) => (
              <FilterCreator
                track={track}
                filter={filter}
                key={filter.key}
                onChange={(filterKey, newValue) => onFiltersChange({ ...formattedFilters, [filterKey]: newValue })}
              />
            ))}
        </div>
      </div>

      <FilterBadges
        reset={clearAllFilters}
        filters={badgesFilters}
        filtersConfig={propertyFilters}
        remove={(key) => {
          onFiltersChange(formatValues(generateDefaultValues(propertyFilters, key)));
        }}
      />
    </div>
  );
}

const isSearchFilterGuard = (filter: PropertyFilter): filter is SearchPropertyFilter => {
  return filter.type === PropertyFilterType.search;
};
