import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDropdownOptionsValues } from 'support/helpers/generic/generic';
import { EdifactStandard } from 'support/types';
import * as Yup from 'yup';

export const edifactStandardOptions = [
  { value: EdifactStandard.D01B, label: 'D01B' },
  { value: EdifactStandard.D96A, label: 'D96A' },
];

export const edifactConfigurationSchema = Yup.object({
  standard: Yup.string().oneOf(getDropdownOptionsValues(edifactStandardOptions)).required(),
});

export const defaultData = {
  standard: edifactStandardOptions[0].value,
};

export type EdifactFormState = {
  standard: EdifactStandard;
};

export const EdifactForm = () => {
  const { t } = useTranslation();

  return (
    <Controller
      render={({ field, fieldState }) => (
        <Dropdown
          {...field}
          label={t('integrations:processes.new.edifact.form.standard.label')}
          options={edifactStandardOptions}
          errors={fieldState.error}
        />
      )}
      name="configuration.standard"
    />
  );
};
