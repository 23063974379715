import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Popup } from 'components/Display/Popup/Popup';
import { Radio } from 'components/Form/Radio/Radio';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateUUID } from 'support/helpers/generic/generic';
import { useEffect, useMemo } from 'react';
import { TemplateDTO } from 'support/types';
import { LocalTransformationDTO } from 'stores/transformations/processTransformation';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Input } from 'components/Form/Input/Input/Input';

type CreateTransformationModalProps = {
  existingTransformations: Array<LocalTransformationDTO>;
  transformationTemplates: Array<TemplateDTO>;
  open: boolean;
  onTransformationCreate: (transformation: LocalTransformationDTO) => void;
  onClose: () => void;
};

type CreateTransformationForm = {
  sourceType: 'EXISTING' | 'TEMPLATE' | 'NEW';
  sourceId?: string;
  newTransformationName?: string;
};

const generateDefaultValues = (
  existingTransformations: Array<LocalTransformationDTO>,
  transformationTemplates: Array<TemplateDTO>,
) => {
  const defaultValues: CreateTransformationForm = {
    sourceType: 'NEW',
    sourceId: '',
  };

  if (existingTransformations.length > 0) {
    defaultValues.sourceType = 'EXISTING';
    defaultValues.sourceId = existingTransformations[0].id;
    defaultValues.newTransformationName = `${existingTransformations[0].name} (copy)`;
    return defaultValues;
  }

  if (transformationTemplates.length > 0) {
    defaultValues.sourceType = 'TEMPLATE';
    defaultValues.sourceId = transformationTemplates[0].id;
    defaultValues.newTransformationName = `${transformationTemplates[0].name} (copy)`;
    return defaultValues;
  }

  return defaultValues;
};

export const CreateTransformationModal = ({
  open,
  existingTransformations,
  transformationTemplates,
  onClose,
  onTransformationCreate,
}: CreateTransformationModalProps) => {
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => generateDefaultValues(existingTransformations, transformationTemplates),
    [existingTransformations, transformationTemplates],
  );
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateTransformationForm>({
    defaultValues,
    resolver: yupResolver<CreateTransformationForm>(newTransformationSchema),
  });

  const [sourceType, sourceId] = useWatch({
    control,
    name: ['sourceType', 'sourceId'],
  });

  // Prefill new schema name when source is selected
  useEffect(() => {
    if (sourceType === 'EXISTING') {
      const lastExistingTransformation = existingTransformations[existingTransformations.length - 1];
      if (!lastExistingTransformation) {
        return;
      }
      if (!sourceId) {
        setValue('sourceId', lastExistingTransformation.id);
        setValue('newTransformationName', `${lastExistingTransformation.name} (copy)`);
        return;
      }

      const existingTransformation = existingTransformations.find((transformation) => transformation.id === sourceId);
      if (existingTransformation) {
        setValue('newTransformationName', `${existingTransformation.name} (copy)`);
      }
    }

    if (sourceType === 'TEMPLATE') {
      const lastTransformationTemplate = transformationTemplates[transformationTemplates.length - 1];
      if (!lastTransformationTemplate) {
        return;
      }
      if (!sourceId) {
        setValue('sourceId', lastTransformationTemplate.id);
        setValue('newTransformationName', `${lastTransformationTemplate.name} (copy)`);
        return;
      }

      const templateTransformation = transformationTemplates.find((transformation) => transformation.id === sourceId);
      if (templateTransformation) {
        setValue('newTransformationName', `${templateTransformation.name} (copy)`);
      }
    }
  }, [sourceType, sourceId, existingTransformations, transformationTemplates, setValue]);

  const PrimaryButton = () => (
    <Button onClick={handleSubmit(submit)} analyticsId="transformation-ui:new-transformation-create">
      {t('common:create')}
    </Button>
  );
  const CancelButton = () => (
    <Button variant="secondary" onClick={onClose} analyticsId="transformation-ui:new-transformation-cancel">
      {t('common:cancel')}
    </Button>
  );

  const submit = (data: CreateTransformationForm) => {
    const transformation: LocalTransformationDTO = {
      name: data.newTransformationName || 'New transformation',
      expression: '{}',
      id: `pending-transformation-${generateUUID()}`,
      is_draft: true,
      type: 'JSONATA',
      group: 'DEFAULT',
      activated_at: null,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      is_dirty: false,
    };

    if (data.sourceType === 'EXISTING' && data.sourceId) {
      const existingTransformation = existingTransformations.find(
        (transformation) => transformation.id === data.sourceId,
      );
      if (existingTransformation) {
        transformation.expression = existingTransformation.expression;
      }
    }

    if (data.sourceType === 'TEMPLATE' && data.sourceId) {
      const templateTransformation = transformationTemplates.find(
        (transformation) => transformation.id === data.sourceId,
      );
      if (templateTransformation) {
        transformation.expression = templateTransformation.content;
      }
    }

    onTransformationCreate(transformation);
    onClose();
  };

  return (
    <Popup
      PrimaryButton={PrimaryButton}
      CancelButton={CancelButton}
      title="Create new transformation"
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
        <Radio.Group>
          <Radio.Item label="From existing transformation" value="EXISTING" {...register('sourceType')} />
          <Radio.Item label="From template" value="TEMPLATE" {...register('sourceType')} />
          <Radio.Item label="New" value="NEW" {...register('sourceType')} />
        </Radio.Group>
        {sourceType === 'EXISTING' && (
          <Controller
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                label="Select existing transformations"
                options={existingTransformations.map((transformation) => ({
                  label: transformation.name,
                  value: transformation.id,
                }))}
                errors={fieldState.error}
              />
            )}
            control={control}
            name="sourceId"
          />
        )}
        {sourceType === 'TEMPLATE' && (
          <Controller
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                label="Select template"
                options={transformationTemplates.map((transformation) => ({
                  label: transformation.name,
                  value: transformation.id,
                }))}
                errors={fieldState.error}
              />
            )}
            control={control}
            name="sourceId"
          />
        )}

        <Input
          label="New transformation name"
          {...register('newTransformationName')}
          errors={errors.newTransformationName}
        />
      </form>
    </Popup>
  );
};

const newTransformationSchema = Yup.object({
  sourceType: Yup.string().oneOf(['EXISTING', 'TEMPLATE', 'NEW']).required(),
  sourceId: Yup.string().when('source', {
    is: (value: string) => value === 'EXISTING' || value === 'TEMPLATE',
    then: (schema) => schema.required(),
  }),
  newTransformationName: Yup.string().required(),
});
