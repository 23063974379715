import { Button } from 'components/Form/Button/Button';
import { ListTable } from 'components/ListTable/ListTable';
import { Pagination } from 'components/ListTable/Pagination/Pagination';
import { TableHeader, TableItem } from 'components/ListTable/types';
import { TransactionFilterKey } from 'pages/Transactions/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTransactions } from 'services/repositories/transactions/transactions';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToCreatePage } from 'support/helpers/navigation/navigation';
import { DocumentType, RelationshipV2, TransactionDTO, TransactionFlowState } from 'support/types';

export const ConnectionsTestWebEdi = ({ relationship }: { relationship: RelationshipV2 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentCursor, setCurrentCursor] = useState<string | undefined>();
  const { data: transactionsData, isFetching } = useTransactions({
    variables: {
      query: {
        filterValues: transactionsFilters(relationship.receiver_partner.id),
        perPage: '5',
        cursor: currentCursor,
      },
    },
    enabled: Boolean(relationship),
  });
  const tableHeaders: Array<TableHeader> = [
    { label: t('connections:connectionTest.webediDrawer.selectedDocumentSection.tableHeader.number'), key: 'number' },
    { label: t('connections:connectionTest.webediDrawer.selectedDocumentSection.tableHeader.date'), key: 'date' },
    { label: '', key: 'actions' },
  ];

  const buildRow = (order: TransactionDTO): Array<TableItem> => {
    return [
      {
        element: <span className="block max-w-xs truncate">{order.externalMessageRef}</span>,
        key: `number-${order.id}`,
      },
      {
        element: <span className="tabular-nums">{formatDayAndTime(order.createdAt)}</span>,
        key: `date-${order.id}`,
      },
      {
        element: (
          <Button
            variant="secondary"
            size="extra-small"
            onClick={() => navigate(routeToCreatePage(order.id, relationship?.message_type as DocumentType))}
            analyticsId="testing-flow:test-document-create-navigate"
          >
            {t('connections:connectionTest.webediDrawer.selectDocumentSection.send')}
          </Button>
        ),
        className: 'text-end',
        key: `actions-${order.id}`,
      },
    ];
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <p className="text-base font-bold text-gray-900">
          {t('connections:connectionTest.webediDrawer.selectDocumentSection.title')}
        </p>
        <p className="text-sm text-gray-500">
          {t('connections:connectionTest.webediDrawer.selectDocumentSection.description')}.
        </p>
      </div>
      <ListTable<TransactionDTO>
        data={transactionsData?.items}
        isLoading={isFetching}
        headers={tableHeaders}
        rowBuilder={buildRow}
        pagination={
          <Pagination
            hasPrevious={Boolean(transactionsData?.previousCursor)}
            hasNext={Boolean(transactionsData?.hasMore)}
            onPrevious={() => setCurrentCursor(transactionsData?.previousCursor)}
            onNext={() => setCurrentCursor(transactionsData?.nextCursor)}
          />
        }
      />
    </div>
  );
};

const transactionsFilters = (partnerId: string) => [
  { key: TransactionFilterKey.transactionFlow, value: TransactionFlowState.live },
  { key: TransactionFilterKey.partnerId, value: partnerId },
  { key: TransactionFilterKey.transactionType, value: DocumentType.order },
];
