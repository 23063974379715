import { Section } from 'components/Display/Section/Section';
import { Input } from 'components/Form/Input/Input/Input';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WebEDIConfiguration } from './helpers';

export const WebEDIConfigurationForm = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<{ configuration: WebEDIConfiguration }>();

  return (
    <>
      <Section.Horizontal
        title={t('integrations:new.webedi.form.section.email.title')}
        description={t('integrations:new.webedi.form.section.email.description')}
      >
        <Input
          {...register('configuration.email')}
          required
          label={t('integrations:new.webedi.form.field.email.label')}
          errors={errors.configuration?.email}
        />
      </Section.Horizontal>

      <Section.Horizontal title={t('integrations:new.webedi.form.section.companyDetaisl.title')}>
        <Input
          {...register('configuration.vatId')}
          required
          label={t('integrations:new.webedi.form.field.vatId.label')}
          errors={errors.configuration?.vatId}
        />

        <Input
          {...register('configuration.billFromAddressIdentifier')}
          description={t('integrations:new.webedi.form.field.billFromAddressId.description')}
          label={t('integrations:new.webedi.form.field.billFromAddressId.label')}
          errors={errors.configuration?.billFromAddressIdentifier}
        />
        <Input
          {...register('configuration.taxId')}
          label={t('integrations:new.webedi.form.field.taxId.label')}
          description={t('integrations:new.webedi.form.field.taxId.description')}
        />
      </Section.Horizontal>
    </>
  );
};
