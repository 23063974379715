import { useCallback, useEffect, useRef, useState } from 'react';
import MonacoEditor, { MonacoDiffEditor, monaco } from 'react-monaco-editor';
import { UiMode } from './ProcessTransformation';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { ValidationResult } from 'support/helpers/schemas/schemaValidator';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { CreateSchemaModal } from './CreateSchemaModal';
import { IntegrationProcessDTO, TemplateDTO } from 'support/types';
import { LocalSchemaDTO, PayloadObject } from 'stores/transformations/processTransformation';
import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import { DataType } from './helpers';
import { classNames } from 'support/helpers/generic/generic';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';

type EditorType = 'SCHEMA' | 'PAYLOAD' | 'CHANGES';
type DisplayType = 'JSON' | 'XML' | 'RAW';

type TransformationOutputEditorProps = {
  className?: string;
  processType?: IntegrationProcessDTO['type'];
  uiMode: UiMode;
  outputDataType: DataType;
  hidden?: boolean;
  selectedPayload?: PayloadObject;
  existingSchemas: Array<LocalSchemaDTO>;
  schemaTemplates: Array<TemplateDTO>;
  activeSchema?: LocalSchemaDTO;
  selectedSchema?: LocalSchemaDTO;
  onSchemaSelect: (schemaId: string) => void;
  onSchemaChange: (schemaId: string, schema: LocalSchemaDTO) => void;
  onSchemaCreate: (schema: LocalSchemaDTO) => void;
};

type EditorConfig = {
  payloadDisplayTypeOptions: Array<DisplayType>;
  changesDisplayTypeOptions: Array<DisplayType>;
  initialDisplayType: DisplayType;
  hasRawForm: boolean;
};

const editorConfigs: { [k in DataType]: EditorConfig } = {
  CANONICAL: {
    payloadDisplayTypeOptions: ['JSON'],
    changesDisplayTypeOptions: ['JSON'],
    initialDisplayType: 'JSON',
    hasRawForm: false,
  },
  JSON: {
    payloadDisplayTypeOptions: ['JSON'],
    changesDisplayTypeOptions: ['JSON'],
    initialDisplayType: 'JSON',
    hasRawForm: false,
  },
  TRADACOMS: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: false,
  },
  FIXED: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: false,
  },
  PHBEST: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: false,
  },
  EDIFACT: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: false,
  },
  EDIFACT_V2: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: false,
  },
  XML: {
    payloadDisplayTypeOptions: ['XML', 'JSON'],
    changesDisplayTypeOptions: ['XML', 'JSON'],
    initialDisplayType: 'XML',
    hasRawForm: false,
  },
  CSV: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: false,
  },
  NONE: {
    payloadDisplayTypeOptions: ['RAW', 'JSON'],
    changesDisplayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
};

export const TransformationOutputEditor = ({
  className,
  processType,
  uiMode,
  outputDataType,
  hidden,
  selectedPayload,
  existingSchemas,
  schemaTemplates,
  activeSchema,
  selectedSchema,
  onSchemaSelect,
  onSchemaChange,
  onSchemaCreate,
}: TransformationOutputEditorProps) => {
  const [currentSchemaErrorIndex, setCurrentSchemaErrorIndex] = useState<number>();
  const [displayType, setDisplayType] = useState<DisplayType>(editorConfigs[outputDataType].initialDisplayType);
  const [editorType, setEditorType] = useState<EditorType>('PAYLOAD');
  const [createSchemaModalOpen, setCreateSchemaModalOpen] = useState<boolean>(false);
  const dataEditorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  useEffect(() => {
    setEditorType('PAYLOAD');
    setDisplayType('JSON');
  }, [uiMode]);

  const selectNextSchemaError = useCallback(() => {
    if (!selectedPayload?.outputSchemaValidationResult?.errors) {
      return;
    }
    if (typeof currentSchemaErrorIndex === 'undefined') {
      setCurrentSchemaErrorIndex(0);
      return;
    }
    if (currentSchemaErrorIndex >= selectedPayload.outputSchemaValidationResult.errors.length - 1) {
      setCurrentSchemaErrorIndex(0);
      return;
    }
    setCurrentSchemaErrorIndex(currentSchemaErrorIndex + 1);
  }, [selectedPayload?.outputSchemaValidationResult?.errors, currentSchemaErrorIndex]);

  const selectPreviousSchemaError = useCallback(() => {
    if (!selectedPayload?.outputSchemaValidationResult?.errors) {
      return;
    }
    if (typeof currentSchemaErrorIndex === 'undefined') {
      setCurrentSchemaErrorIndex(selectedPayload.outputSchemaValidationResult.errors.length - 1);
      return;
    }
    if (currentSchemaErrorIndex <= 0) {
      setCurrentSchemaErrorIndex(selectedPayload.outputSchemaValidationResult.errors.length - 1);
      return;
    }
    setCurrentSchemaErrorIndex(currentSchemaErrorIndex - 1);
  }, [selectedPayload?.outputSchemaValidationResult?.errors, currentSchemaErrorIndex]);

  const markErrors = useCallback(
    (schemaValidationResult: ValidationResult) => {
      const model = dataEditorRef.current?.getModel();
      if (!dataEditorRef.current || !model || !schemaValidationResult) {
        return;
      }

      // console.log('lineCount', model.getLineCount());
      // console.log('output errors', schemaValidationResult.errors);
      schemaValidationResult.valid
        ? monaco.editor.setModelMarkers(model, 'owner', [])
        : monaco.editor.setModelMarkers(model, 'owner', schemaValidationResult.errors);

      setCurrentSchemaErrorIndex(undefined);
    },
    [dataEditorRef],
  );

  useEffect(() => {
    if (!selectedPayload?.outputSchemaValidationResult) {
      return;
    }

    markErrors(selectedPayload.outputSchemaValidationResult);
  }, [selectedPayload?.outputSchemaValidationResult, markErrors]);

  const selectedSchemaUpdated = useCallback(
    (schema: string) => {
      if (!selectedSchema) {
        return;
      }
      onSchemaChange(selectedSchema.id, { ...selectedSchema, schema });
    },
    [onSchemaChange, selectedSchema],
  );

  // reveal current schema error in editor on currentSchemaErrorIndex change
  useEffect(() => {
    if (
      typeof currentSchemaErrorIndex === 'undefined' ||
      !selectedPayload?.outputSchemaValidationResult ||
      !dataEditorRef.current
    ) {
      return;
    }
    const error = selectedPayload?.outputSchemaValidationResult.errors[currentSchemaErrorIndex];
    const model = dataEditorRef.current.getModel();
    if (!model || !error) {
      return;
    }

    // console.log('marking error', error);
    const errorRange = new monaco.Range(error.startLineNumber, error.startColumn, error.endLineNumber, error.endColumn);
    dataEditorRef.current.revealRangeInCenter(errorRange);
    dataEditorRef.current.setSelection(errorRange);
  }, [currentSchemaErrorIndex, selectedPayload?.outputSchemaValidationResult]);

  return (
    <div className={classNames('flex-1 flex flex-col divide-y', hidden ? 'hidden' : undefined, className)}>
      <div className="flex items-center justify-between px-3 py-4">
        <div className="flex space-x-3 text-sm">
          <dl>
            <dt className="font-medium text-gray-700">Type</dt>
            <dd>{outputDataType}</dd>
          </dl>
          <dl>
            <dt className="font-medium text-gray-700">{uiMode === 'BUILD' ? 'Active' : 'Selected'} Schema</dt>
            <dd>{uiMode === 'BUILD' ? activeSchema?.name ?? '–' : selectedSchema?.name ?? '–'}</dd>
          </dl>
        </div>

        <Badge type="basic" color={uiMode === 'BUILD' ? BadgeColor.blue : BadgeColor.yellow}>
          {uiMode === 'BUILD' ? 'BUILD' : 'TEST'}
        </Badge>
      </div>
      <div className="flex justify-between space-x-3 px-3 py-4 text-sm">
        {editorType === 'PAYLOAD' && (
          <div className="flex items-end space-x-2 text-sm">
            <SegmentedControl
              label="Display"
              items={editorConfigs[outputDataType].payloadDisplayTypeOptions.map((displayTypeOption) => ({
                label: displayTypeOption,
                LeftIcon:
                  displayTypeOption === 'JSON' && selectedPayload?.outputSchemaValidationResult?.valid === false
                    ? ExclamationTriangleIcon
                    : undefined,
              }))}
              selectedIndex={editorConfigs[outputDataType].payloadDisplayTypeOptions.indexOf(displayType)}
              onChange={(index) => setDisplayType(editorConfigs[outputDataType].payloadDisplayTypeOptions[index])}
            />
          </div>
        )}
        {editorType === 'CHANGES' && (
          <div className="flex items-end space-x-2 text-sm">
            <SegmentedControl
              label="Display"
              items={editorConfigs[outputDataType].changesDisplayTypeOptions.map((displayTypeOption) => ({
                label: displayTypeOption,
                LeftIcon:
                  displayTypeOption === 'JSON' &&
                  selectedPayload?.outputDiff &&
                  (selectedPayload?.outputDiff.additions > 0 || selectedPayload?.outputDiff.deletions > 0)
                    ? ExclamationTriangleIcon
                    : undefined,
              }))}
              selectedIndex={editorConfigs[outputDataType].changesDisplayTypeOptions.indexOf(displayType)}
              onChange={(index) => setDisplayType(editorConfigs[outputDataType].changesDisplayTypeOptions[index])}
            />
          </div>
        )}
        {editorType === 'SCHEMA' ? (
          <div className="flex items-end space-x-2 text-sm">
            <Dropdown
              label="Selected schema"
              value={selectedSchema?.id ?? 'choose...'}
              options={existingSchemas.map((schema) => ({
                label: schema.name,
                value: schema.id,
              }))}
              onChange={onSchemaSelect}
            />
            <Button
              variant="secondary"
              onClick={() => setCreateSchemaModalOpen(true)}
              analyticsId="transformation-ui:new-output-schema"
            >
              New schema
            </Button>
          </div>
        ) : (
          <div />
        )}
        {uiMode === 'BUILD' ? (
          <div className="flex items-end space-x-2 text-sm">
            <SegmentedControl
              label="Editor"
              items={[{ label: 'PAYLOAD' }, { label: 'CHANGES' }, { label: 'SCHEMA' }]}
              selectedIndex={editorType === 'PAYLOAD' ? 0 : editorType === 'CHANGES' ? 1 : 2}
              onChange={(index) => setEditorType(index === 0 ? 'PAYLOAD' : index === 1 ? 'CHANGES' : 'SCHEMA')}
            />
          </div>
        ) : (
          <div className="flex items-end space-x-2 text-sm">
            <SegmentedControl
              label="Editor"
              items={[{ label: 'PAYLOAD' }, { label: 'CHANGES' }]}
              selectedIndex={editorType === 'PAYLOAD' ? 0 : 1}
              onChange={(index) => setEditorType(index === 0 ? 'PAYLOAD' : 'CHANGES')}
            />
          </div>
        )}
      </div>
      {selectedPayload && (
        <>
          <div
            className={classNames('flex-1 min-h-0', editorType === 'PAYLOAD' && displayType === 'JSON' ? '' : 'hidden')}
          >
            <MonacoEditor
              width="100%"
              height="100%"
              language="json"
              value={JSON.stringify(selectedPayload.transformationResult?.output ?? '', null, 2)}
              options={{
                // readOnly: true,
                automaticLayout: true,
                bracketPairColorization: {
                  enabled: true,
                },
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
                stickyScroll: {
                  enabled: true,
                },
              }}
              editorDidMount={(editor) => (dataEditorRef.current = editor)}
            />
          </div>
          <div
            className={classNames(
              'flex-1 min-h-0',
              editorType === 'PAYLOAD' && ['RAW', 'XML'].includes(displayType) ? '' : 'hidden',
            )}
          >
            <MonacoEditor
              width="100%"
              height="100%"
              language={displayType === 'XML' ? 'xml' : 'plain'}
              value={selectedPayload.rawOutputData ?? ''}
              options={{
                maxTokenizationLineLength: 100000,
                readOnly: true,
                automaticLayout: true,
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
              }}
            />
          </div>
          <div
            className={classNames('flex-1 min-h-0', editorType === 'CHANGES' && displayType === 'JSON' ? '' : 'hidden')}
          >
            <MonacoDiffEditor
              width="100%"
              height="100%"
              language="json"
              value={JSON.stringify(selectedPayload.transformationResult?.output ?? '', null, 2)}
              original={JSON.stringify(selectedPayload.originalTransformationResult?.output ?? '', null, 2)}
              options={{
                renderSideBySide: false,
                readOnly: true,
                automaticLayout: true,
                bracketPairColorization: {
                  enabled: true,
                },
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
                stickyScroll: {
                  enabled: true,
                },
              }}
            />
          </div>
          <div
            className={classNames('flex-1 min-h-0', editorType === 'CHANGES' && displayType === 'RAW' ? '' : 'hidden')}
          >
            <MonacoDiffEditor
              width="100%"
              height="100%"
              language="plaintext"
              value={selectedPayload.rawOutputData ?? ''}
              original={selectedPayload.originalRawOutputData ?? ''}
              options={{
                renderSideBySide: false,
                readOnly: false,
                automaticLayout: true,
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
              }}
            />
          </div>
        </>
      )}
      {editorType === 'PAYLOAD' && !selectedPayload && (
        <div className="flex flex-1 items-center justify-center">
          <div className="text-gray-500">No payload selected</div>
        </div>
      )}
      {uiMode === 'TEST' && editorType === 'CHANGES' && !selectedPayload && (
        <div className="flex flex-1 items-center justify-center">
          <div className="text-gray-500">No payload selected</div>
        </div>
      )}
      {selectedSchema && (
        <div className={classNames('flex-1 min-h-0', uiMode === 'BUILD' && editorType === 'SCHEMA' ? '' : 'hidden')}>
          <MonacoEditor
            width="100%"
            height="100%"
            language="json"
            value={selectedSchema.schema}
            options={{
              readOnly: selectedSchema.is_draft === false,
              automaticLayout: true,
              bracketPairColorization: {
                enabled: true,
              },
              codeLens: false,
              scrollBeyondLastLine: false,
              minimap: {
                enabled: false,
              },
              stickyScroll: {
                enabled: true,
              },
            }}
            onChange={selectedSchemaUpdated}
          />
          {/* <SchemaEditor
            schema={selectedSchema.schema}
            readOnly={selectedSchema.is_draft === false}
            onChange={selectedSchemaUpdated}
          /> */}
        </div>
      )}
      {uiMode === 'BUILD' && editorType === 'SCHEMA' && !selectedSchema && (
        <div className="flex flex-1 items-center justify-center">
          <Button
            variant="secondary"
            onClick={() => setCreateSchemaModalOpen(true)}
            analyticsId="transformation-ui:new-output-schema"
          >
            Create new schema
          </Button>
        </div>
      )}

      <div className="flex items-center px-3 py-4">
        {selectedPayload?.outputSchemaValidationResult ? (
          <>
            {selectedPayload.outputSchemaValidationResult.valid ? (
              <Badge color={BadgeColor.green} className="mr-3">
                No Errors
              </Badge>
            ) : (
              <Badge color={BadgeColor.red} className="mr-3">
                {selectedPayload.outputSchemaValidationResult.errors.length} Errors
              </Badge>
            )}
            {selectedPayload.outputSchemaValidationResult.valid === false && (
              <div className="flex space-x-1">
                <Button
                  iconOnly
                  LeftIcon={ArrowUpIcon}
                  variant="secondary"
                  size="small"
                  onClick={selectPreviousSchemaError}
                  analyticsId="transformation-ui:select_previous_output_schema_error"
                />
                <Button
                  iconOnly
                  LeftIcon={ArrowDownIcon}
                  variant="secondary"
                  size="small"
                  onClick={selectNextSchemaError}
                  analyticsId="transformation-ui:select_next_output_schema_error"
                />
              </div>
            )}
          </>
        ) : (
          <Badge color={BadgeColor.gray} className="mr-3">
            Not yet validated
          </Badge>
        )}
      </div>

      {!!processType && (
        <CreateSchemaModal
          type={processType === 'SOURCE' ? 'PROVIDED' : 'DESTINATION'}
          open={createSchemaModalOpen}
          onClose={() => setCreateSchemaModalOpen(false)}
          existingSchemas={existingSchemas}
          schemaTemplates={schemaTemplates}
          onSchemaCreate={onSchemaCreate}
        />
      )}
    </div>
  );
};
