import { Bullets } from 'components/Bullets/Bullets';
import { LoadingLogo } from 'components/Loading/LoadingLogo';
import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';
import { ReactNode } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type StepperProps = {
  isLoading: boolean;
  children: ReactNode;
  stepTitle?: ReactNode;
  numberOfSteps: number;
  activeStep: number;
  backCta?: ReactNode;
  nextCta?: ReactNode;
};

export const Stepper = ({
  isLoading,
  stepTitle,
  numberOfSteps,
  children,
  activeStep,
  backCta,
  nextCta,
}: StepperProps) => {
  return (
    <div className="w-full max-w-3xl space-y-10 bg-white px-24 py-16 shadow-lg">
      {stepTitle ? <Header stepTitle={stepTitle} numberOfSteps={numberOfSteps} activeStep={activeStep} /> : null}
      <div className="relative space-y-10">
        <div className={isLoading ? 'opacity-0' : undefined}>{children}</div>

        {isLoading && (
          <div className="absolute inset-0 !mt-0 flex items-center justify-center">
            <div className="size-20">
              <LoadingLogo />
            </div>
          </div>
        )}

        {(backCta || nextCta) && (
          <div className={classNames('flex gap-4 border-t border-gray-200 pt-10', isLoading ? 'opacity-0' : undefined)}>
            {backCta}
            {nextCta}
          </div>
        )}
      </div>
    </div>
  );
};

type HeaderProps = Pick<StepperProps, 'stepTitle' | 'numberOfSteps' | 'activeStep'>;
const Header = ({ stepTitle, numberOfSteps, activeStep }: HeaderProps) => {
  return (
    <div className="space-y-10 border-b border-gray-200 pb-6">
      <div className="flex items-center justify-between">
        <ApplicationLogo withText={false} className="size-9" variant="dark" />
        <Bullets numberOfSteps={numberOfSteps} activeStep={activeStep} />
      </div>
      <h1 className="text-2xl font-extrabold text-gray-900">{stepTitle}</h1>
    </div>
  );
};
