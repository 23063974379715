import { IntegrationDTO, IntegrationProcessDTO } from 'support/types';

export type DataType =
  | 'CANONICAL'
  | 'TRADACOMS'
  | 'EDIFACT'
  | 'EDIFACT_V2'
  | 'FIXED'
  | 'PHBEST'
  | 'XML'
  | 'CSV'
  | 'JSON'
  | 'NONE';
import { executeTransformation, executeTransformationOnBackend } from 'support/helpers/transformation/transformer';
import { validateSchema, wrapSchema } from 'support/helpers/schemas/schemaValidator';
import isEqual from 'lodash/isEqual';
import { denormalizeNormalizedPayload, normalizeRawPayload } from 'services/normalization/normalization';
import diffMatchPatch from 'diff-match-patch';
import {
  LocalSchemaDTO,
  LocalTransformationDTO,
  OutputDiff,
  PayloadObject,
} from 'stores/transformations/processTransformation';
import { parseJson } from 'support/helpers/generic/generic';
const dmp = new diffMatchPatch();

export const formatRawPayload = (rawPayload: string): string => {
  if (!rawPayload.length) {
    return rawPayload;
  }

  // Split lines on single-line edifact
  if (!rawPayload.includes('\n') && (rawPayload.indexOf('UNB') === 0 || rawPayload.indexOf('UNA') === 0)) {
    return rawPayload.split(/(?<![^\?](?:\?\?)*\?)'/g).join("'\n");
  }

  // Split lines on single-line tradacoms
  if (!rawPayload.includes('\n') && rawPayload.indexOf('STX') === 0) {
    return rawPayload.split(/(?<![^\?](?:\?\?)*\?)'/g).join("'\n");
  }

  return rawPayload;
};

export const getDataType = (integration: IntegrationDTO, process: IntegrationProcessDTO): DataType => {
  const normalizer = process.configuration?.normalizer as DataType | 'PASS_THROUGH';
  if (normalizer && normalizer !== 'PASS_THROUGH' && normalizer !== 'NONE') {
    return normalizer;
  }

  const translator =
    integration.translator === 'TRANSFORMATION_PROXY' ? integration.fallbackTranslator ?? '' : integration.translator;
  switch (translator.toLowerCase()) {
    case 'edifact':
      return 'EDIFACT';
    case 'tradacoms':
      return 'TRADACOMS';
    case 'phbest':
      return 'PHBEST';
    case 'xml':
      return 'XML';
    case 'api_v2':
      return 'JSON';
    default:
      return 'NONE';
  }
};

const calculateDiff = (oldText: string, newText: string): OutputDiff => {
  console.time('diff');
  const diff = dmp.diff_main(oldText, newText);
  console.timeEnd('diff');

  const result = diff.reduce<OutputDiff>(
    (acc, [operation]) => {
      if (operation === 1) {
        return {
          additions: acc.additions + 1,
          deletions: acc.deletions,
        };
      }
      if (operation === -1) {
        return {
          additions: acc.additions,
          deletions: acc.deletions + 1,
        };
      }
      return acc;
    },
    { additions: 0, deletions: 0 },
  );

  return result;
};

export const processPayloadObject = async (config: {
  payload: PayloadObject;
  normalizerConfig?: Record<string, unknown>;
  integrationId?: string;
  processId?: string;
  inputSchema?: LocalSchemaDTO;
  outputSchema?: LocalSchemaDTO;
  selectedTransformation?: LocalTransformationDTO;
  activeTransformation?: LocalTransformationDTO;
  onBackend?: boolean;
}) => {
  const {
    payload,
    normalizerConfig,
    integrationId,
    processId,
    inputSchema,
    outputSchema,
    selectedTransformation,
    activeTransformation,
    onBackend,
  } = config;

  const processedPayload = {
    ...payload,
  };
  let hasChanged = false;

  let transformationInputJson = processedPayload?.transformationInput
    ? parseJson(processedPayload?.transformationInput)
    : undefined;
  const inputSchemaJson = inputSchema?.schema ? parseJson(inputSchema.schema) : undefined;
  const outputSchemaJson = outputSchema?.schema ? parseJson(outputSchema.schema) : undefined;

  // Step 0: Normalize raw input data for source transformations if only raw is available
  if (
    processedPayload.rawInputData &&
    !processedPayload.transformationInput &&
    processedPayload.inputType !== 'CANONICAL' // Only for source transformations
  ) {
    console.log('normalizing input data on payload id', processedPayload.id);
    const newInputNormalizationResult = normalizeRawPayload({
      rawPayload: processedPayload.rawInputData,
      rawType: processedPayload.inputType,
      normalizerConfig,
    });
    transformationInputJson = {
      result: {
        payloads: [
          newInputNormalizationResult.success
            ? newInputNormalizationResult.normalizedPayload
            : {
                errorMessage: newInputNormalizationResult.errorMessage,
                errors: newInputNormalizationResult.errors,
              },
        ],
        context: {},
      },
      success: newInputNormalizationResult.success,
    };
    processedPayload.transformationInput = JSON.stringify(transformationInputJson.result, null, 2);
    hasChanged = true;
  }

  // Step 1: Validate input schema
  if (transformationInputJson?.success && inputSchemaJson?.success) {
    console.log('validating input schema on payload id', processedPayload.id);
    const wrappedSchema = wrapSchema(inputSchemaJson.result);
    const inputSchemaValidationResult = validateSchema(transformationInputJson.result, wrappedSchema, 'input');
    if (!isEqual(inputSchemaValidationResult, processedPayload.inputSchemaValidationResult)) {
      processedPayload.inputSchemaValidationResult = inputSchemaValidationResult;
      hasChanged = true;
    }
  }

  // Step 2: Execute transformation of new/selected expression
  if (selectedTransformation?.expression && transformationInputJson?.success) {
    console.log('executing selected transformation on payload id', processedPayload.id);
    // console.time('first_execution');
    const transformationResult =
      onBackend && integrationId && processId
        ? await executeTransformationOnBackend({
            integrationId,
            processId,
            transformationId: selectedTransformation.id,
            input: transformationInputJson.result,
          })
        : await executeTransformation(transformationInputJson.result, selectedTransformation.expression);
    // console.timeEnd('first_execution');
    if (!isEqual(transformationResult, processedPayload.transformationResult)) {
      processedPayload.transformationResult = transformationResult;
      hasChanged = true;
    }
  }

  // Step 3: Execute transformation of active expression
  if (activeTransformation?.expression && transformationInputJson?.success) {
    console.log('executing active transformation on payload id', processedPayload.id);
    // console.time('second_execution');
    const originalTransformationResult =
      onBackend && integrationId && processId
        ? await executeTransformationOnBackend({
            integrationId,
            processId,
            transformationId: activeTransformation.id,
            input: transformationInputJson.result,
          })
        : await executeTransformation(transformationInputJson.result, activeTransformation.expression);
    // console.timeEnd('second_execution');
    if (!isEqual(originalTransformationResult, processedPayload.originalTransformationResult)) {
      processedPayload.originalTransformationResult = originalTransformationResult;
      hasChanged = true;
    }
  }

  // Step 4: Validate output schema
  if (processedPayload.transformationResult?.success && outputSchemaJson?.success) {
    console.log('validating output schema on payload id', processedPayload.id);
    const wrappedSchema = wrapSchema(outputSchemaJson.result);
    const outputSchemaValidationResult = validateSchema(
      processedPayload.transformationResult.output,
      wrappedSchema,
      'output',
    );
    if (!isEqual(outputSchemaValidationResult, processedPayload.outputSchemaValidationResult)) {
      processedPayload.outputSchemaValidationResult = outputSchemaValidationResult;
      hasChanged = true;
    }
  }

  // Step 5: Denormalize output data (only on destination transformations)
  if (
    processedPayload.outputType !== 'CANONICAL' &&
    processedPayload.transformationResult?.success &&
    processedPayload.transformationResult.output.payloads?.length
  ) {
    if (processedPayload.transformationResult.output.payloads?.length > 1) {
      console.error('Cannot denormalize multiple payloads');
    }

    const newOutputDenormalizationResult = denormalizeNormalizedPayload({
      normalizedPayload: processedPayload.transformationResult.output.payloads[0],
      rawType: processedPayload.outputType,
      context: processedPayload.transformationResult.output.context,
      normalizerConfig,
    });

    console.log('denormalizing output data on payload id', processedPayload.id);
    const newOriginalRawOutputData = newOutputDenormalizationResult.success
      ? formatRawPayload(newOutputDenormalizationResult.rawPayload ?? '')
      : newOutputDenormalizationResult.errorMessage;

    if (newOriginalRawOutputData !== processedPayload.rawOutputData) {
      processedPayload.rawOutputData = newOriginalRawOutputData;
      hasChanged = true;
    }
  }

  // Step 6: Denormalize original output data (only on destination transformations)
  if (
    processedPayload.outputType !== 'CANONICAL' &&
    processedPayload.originalTransformationResult?.success &&
    processedPayload.originalTransformationResult.output.payloads?.length
  ) {
    if (processedPayload.originalTransformationResult.output.payloads?.length > 1) {
      console.error('Cannot denormalize multiple payloads');
    }

    const newOriginalOutputDenormalizationResult = denormalizeNormalizedPayload({
      normalizedPayload: processedPayload.originalTransformationResult.output.payloads[0],
      rawType: processedPayload.outputType,
      context: processedPayload.originalTransformationResult.output.context,
      normalizerConfig,
    });

    console.log('denormalizing original output data on payload id', processedPayload.id);
    const newOriginalRawOutputData = newOriginalOutputDenormalizationResult.success
      ? formatRawPayload(newOriginalOutputDenormalizationResult.rawPayload ?? '')
      : newOriginalOutputDenormalizationResult.errorMessage;

    if (newOriginalRawOutputData !== processedPayload.originalRawOutputData) {
      processedPayload.originalRawOutputData = newOriginalRawOutputData;
      hasChanged = true;
    }
  }

  // Step 7: Normalize original output data if only raw is available (happens only when no active transformation is present on DESTINATION processes)
  if (
    processedPayload.originalRawOutputData &&
    !processedPayload.originalTransformationResult &&
    processedPayload.outputType !== 'CANONICAL'
  ) {
    console.log('normalizing original output data on payload id', processedPayload.id);

    const newOriginalOutputNormalizationResult = normalizeRawPayload({
      rawPayload: processedPayload.originalRawOutputData,
      rawType: processedPayload.outputType,
      normalizerConfig,
    });

    processedPayload.originalTransformationResult = {
      output: {
        payloads: [
          newOriginalOutputNormalizationResult.success
            ? newOriginalOutputNormalizationResult.normalizedPayload
            : {
                errorMessage: newOriginalOutputNormalizationResult.errorMessage,
                errors: newOriginalOutputNormalizationResult.errors,
              },
        ],
        context: {},
      },
      success: newOriginalOutputNormalizationResult.success,
    };
    hasChanged = true;
  }

  // Step 8: Check if output has changed
  if (processedPayload.originalTransformationResult && processedPayload.transformationResult && onBackend) {
    console.log('processing difference on payload id', processedPayload.id);
    const compareRaw = processedPayload.originalRawOutputData && processedPayload.rawOutputData;
    const outputDiff = calculateDiff(
      JSON.stringify(
        compareRaw
          ? processedPayload.originalRawOutputData ?? ''
          : processedPayload.originalTransformationResult.output ?? '',
        null,
        2,
      ),
      JSON.stringify(
        compareRaw ? processedPayload.rawOutputData ?? '' : processedPayload.transformationResult.output ?? '',
        null,
        2,
      ),
    );
    if (!isEqual(outputDiff, processedPayload.outputDiff)) {
      processedPayload.outputDiff = outputDiff;
      hasChanged = true;
    }
    console.log('calculated diff');
  }

  return hasChanged ? processedPayload : payload;
};
