import { Popup } from 'components/Display/Popup/Popup';
import { Form } from 'components/Form/Form';
import { Page } from 'components/Page/Page';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentPartner, useInviteTeamMember, useTeamMembers } from 'services/repositories/partners/partners';
import { useNotificationStore } from 'stores/notifications/notifications';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { UserDTO } from 'support/types';
import { SettingsSidebar } from '../SettingsSidebar/SettingsSidebar';
import { Button } from 'components/Form/Button/Button';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Input } from 'components/Form/Input/Input/Input';

type InviteTeamMemberFormState = {
  name: string;
  email: string;
};

const defaultValues = {
  name: '',
  email: '',
};
export function TeamMembers() {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<InviteTeamMemberFormState>({ defaultValues });
  const { addNotification } = useNotificationStore();
  const { data: currentPartner } = useCurrentPartner();

  const { data: teamMembers, isLoading: teamMembersLoading } = useTeamMembers({
    variables: {
      partnerId: currentPartner?.id,
    },
  });

  const { mutate, isLoading: invitationProcessing } = useInviteTeamMember();

  const onSubmit = (newMember: InviteTeamMemberFormState) => {
    if (currentPartner?.id) {
      mutate(
        { partnerId: currentPartner?.id, newMember },
        {
          onSuccess: () => {
            onClose();
            addNotification(t('settings:teamMembers.popup.success'));
          },
          onError: (err) => {
            processSubmissionErrors<InviteTeamMemberFormState>({
              error: err as Error,
              setInputError: setError,
              setNonInputErrors,
              defaultData: defaultValues,
              t,
            });
          },
        },
      );
    }
  };

  const onClose = () => {
    reset();
    setShowPopup(false);
  };

  const PrimaryButton = () => (
    <Button loading={invitationProcessing} form="newMemberForm" type="submit" analyticsId="team:member_invite_send">
      <div data-testid="action-button">{t('settings:teamMembers.invite')}</div>
    </Button>
  );

  const CancelButton = ({ cancelButtonRef }: { cancelButtonRef: React.RefObject<HTMLButtonElement> }) => (
    <Button
      onClick={onClose}
      disabled={invitationProcessing}
      variant="secondary"
      ref={cancelButtonRef}
      analyticsId="team:member_invite_cancel"
    >
      <div data-testid="cancel-button">{t('common:cancel')}</div>
    </Button>
  );

  return (
    <>
      <Page isLoading={teamMembersLoading}>
        <Page.Head title={t('common:mainMenu.settings')} />

        <Page.Section leftAside={<SettingsSidebar />}>
          <div className="w-full divide-y divide-gray-200 *:py-6 first:*:pt-0 last:*:pb-0">
            <div className="flex w-full justify-between gap-2">
              <div>
                <h2 className="text-base font-bold text-gray-900">{t('settings:sidebar.teamMembers')}</h2>
                <p className="text-sm text-gray-500">{t('settings:teamMembers.subTitle')}</p>
              </div>
              <div>
                <Button onClick={() => setShowPopup(true)} analyticsId="team:member_invite">
                  {t('settings:teamMembers.addMember')}
                </Button>
              </div>
            </div>
            <div className="text-sm">
              <div className="grid grid-cols-2 py-3">
                <div className="font-semibold">{t('settings:teamMembers.name')}</div>
                <div className="font-semibold">{t('settings:teamMembers.email')}</div>
              </div>

              <div>
                {teamMembers?.map((teamMember: UserDTO) => {
                  return (
                    <div key={teamMember.id} className="grid grid-cols-2 border-t-2 border-gray-100 py-4">
                      <div className="font-medium text-gray-900">{teamMember.name}</div>
                      <div className="text-gray-500">{teamMember.email}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Page.Section>
      </Page>

      <Popup
        CancelButton={CancelButton}
        PrimaryButton={PrimaryButton}
        title={t('settings:teamMembers.popup.title')}
        subtitle={t('settings:teamMembers.popup.subtitle')}
        open={showPopup}
        onClose={onClose}
      >
        <Form data-testid="newMemberForm" id="newMemberForm" onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <ValidationErrors errors={nonInputErrors} />
          <Input
            label={t('settings:teamMembers.popup.name')}
            className="mb-3"
            errors={errors.name}
            {...register('name', { required: true })}
          />
          <Input
            label={t('settings:teamMembers.popup.email')}
            type="email"
            errors={errors.email}
            {...register('email', { required: true })}
          />
        </Form>
      </Popup>
    </>
  );
}
