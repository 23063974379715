import React, { ReactNode, forwardRef, useId, useEffect, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { classNames } from 'support/helpers/generic/generic';
import { useMergeRefs } from 'support/helpers/hooks/useMergedRefs';

type CheckboxType = {
  label?: string | ReactNode;
  description?: ReactNode;
  id?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  indeterminate?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

function CheckboxComponent(
  { id, label, description, className, disabled, error, indeterminate, ...otherProps }: CheckboxType,
  forwardedRef: React.Ref<HTMLInputElement>,
) {
  const generatedId = useId();
  const internalRef = useRef<HTMLInputElement>(null);
  const mergedRef = useMergeRefs([internalRef, forwardedRef]);

  useEffect(() => {
    if (internalRef.current) {
      internalRef.current.indeterminate = Boolean(indeterminate);
    }
  }, [indeterminate]);

  return (
    <div className={classNames('relative flex items-start', disabled ? 'opacity-40' : undefined)}>
      <div className="flex h-5 items-center">
        <input
          ref={mergedRef}
          type="checkbox"
          id={id || generatedId}
          aria-describedby={`${label}-description`}
          className={classNames(
            className ?? '',
            'h-4 w-4 border-gray-300 rounded focus:ring-0 focus:ring-offset-0',
            disabled
              ? 'text-gray-500'
              : 'focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-procuros-green-500 text-procuros-green-500',
            error ? 'border-red-300 ' : undefined,
          )}
          disabled={disabled}
          {...otherProps}
        />
      </div>

      {label || description ? (
        <div className="ml-3 text-sm">
          {label ? (
            <label htmlFor={id || generatedId} className="font-medium text-gray-700">
              {label}
            </label>
          ) : null}

          {!!description && (
            <p id={`${label}-description`} className="text-gray-500">
              {description}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
}

export const Checkbox = forwardRef(CheckboxComponent);

export const CheckboxInABox = forwardRef<
  HTMLInputElement | null,
  CheckboxType & { description: ReactNode; errors?: FieldError }
>(({ errors, description, ...props }, ref) => {
  return (
    <div>
      <div className={classNames('p-4 rounded-lg flex', errors || props.error ? 'bg-red-50' : 'bg-gray-50')}>
        <Checkbox {...props} description={props.label ? description : undefined} error={Boolean(errors)} ref={ref} />
        {description && !props.label ? <p className="ml-3 text-sm text-gray-700">{description}</p> : null}
      </div>
      {!!errors && <p className="mt-2 text-sm text-red-600">{errors.message}</p>}
    </div>
  );
});

CheckboxInABox.displayName = 'CheckboxInABox';
