import { forwardRef, useEffect, useState } from 'react';
import { WebEDIError } from 'services/webediErrors/webediErrors';
import { WithoutErrorsContent } from './components/WithoutErrorsContent';
import { WithErrorsContent } from './components/WithErrorsContent';
import { MessagePopover } from '../MessagePopover/MessagePopover';

type MessageFieldErrorModalProps = {
  style?: React.CSSProperties;
  error: WebEDIError | undefined;
  numberOfErrors: number;
  isValidating?: boolean;
  viewMode: boolean;
};

export const MessageFieldErrorModal = forwardRef<HTMLDivElement, MessageFieldErrorModalProps>(
  ({ style, error, numberOfErrors, isValidating, viewMode }, ref) => {
    const [errorState, setErrorState] = useState<MessageFieldErrorModalProps['error']>(error);

    useEffect(() => {
      if (error) {
        setErrorState(error);
      }
    }, [error]);

    if (!errorState) {
      return null;
    }

    const isErrorFixed = errorState && !error;
    const hasErrors = numberOfErrors > 0;

    return (
      <MessagePopover ref={ref} style={style}>
        {hasErrors ? (
          <WithErrorsContent
            isErrorFixed={isErrorFixed}
            error={errorState}
            numberOfErrors={numberOfErrors}
            isValidating={isValidating}
            viewMode={viewMode}
          />
        ) : (
          <WithoutErrorsContent />
        )}
      </MessagePopover>
    );
  },
);

MessageFieldErrorModal.displayName = 'DocumentFieldErrorModal';
