import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Alert } from 'components/Display/Alert/Alert';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { TransactionsRoutes } from 'support/types';
import { MessageV2DTO, RelationshipV2 } from 'support/types/dtos';

export const RelationshipTimelineActivityExtra = ({
  activity,
}: {
  activity: Required<RelationshipV2>['timeline_events'][number];
}) => {
  switch (activity.type) {
    case 'RELATIONSHIP_TEST_MESSAGE_REJECTED':
      return (
        <Alert severity="neutral" withoutIcon>
          {typeof activity.meta?.reason === 'string' ? activity.meta.reason : EMPTY_FIELD}
        </Alert>
      );

    case 'RELATIONSHIP_TEST_MESSAGE_PENDING_REVIEW': {
      if (!activity.meta?.messages) {
        return null;
      }
      const messages = activity.meta.messages as Array<MessageV2DTO>;
      return (
        <div className="flex gap-2">
          {messages.map((message) => (
            <a
              href={routeToPage(TransactionsRoutes.viewMessage, { id: message.id! })}
              target="_blank"
              rel="noreferrer"
              key={message.id}
              className="flex items-center rounded-md bg-gray-50 px-3 py-2 text-xs underline decoration-gray-300"
            >
              {message.external_message_ref}
              <ArrowTopRightOnSquareIcon className="ml-2.5 inline size-4 text-gray-400" />
            </a>
          ))}
        </div>
      );
    }

    default:
      return null;
  }
};
