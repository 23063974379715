import { useNotificationStore } from 'stores/notifications/notifications';
import { Notification } from '../Notification/Notification';

export function NotificationList() {
  const { notifications } = useNotificationStore();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {notifications.map((notification, index) => (
        <Notification
          index={index}
          key={notification.id}
          id={notification.id}
          headline={notification.title}
          description={notification.subtitle}
          type={notification.type}
        />
      ))}
    </div>
  );
}
