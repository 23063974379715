import { parseISO } from 'date-fns';
import { DataType, formatRawPayload, getDataType } from 'pages/IntegrationProcesses/ProcessTransformation/helpers';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { parseJson } from 'support/helpers/generic/generic';
import { ValidationResult } from 'support/helpers/schemas/schemaValidator';
import { TransformationResult } from 'support/helpers/transformation/transformer';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  IntegrationDTO,
  IntegrationProcessDTO,
  MessageV2DTO,
  RelationshipV2,
  SchemaDTO,
  SenderEnvelopeV2DTO,
  TemplateDTO,
  TransformationDTO,
} from '../../support/types';
import isEqual from 'lodash/isEqual';

export type OutputDiff = {
  additions: number;
  deletions: number;
};

export type PayloadObject = {
  id: string;
  name: string;
  date: string;
  inputType: DataType;
  outputType: DataType;
  transformationInput?: string;
  rawInputData?: string;
  transformationResult?: TransformationResult;
  rawOutputData?: string;
  originalTransformationResult?: TransformationResult;
  originalRawOutputData?: string;
  inputEditable: boolean;
  inputSchemaValidationResult?: ValidationResult;
  outputSchemaValidationResult?: ValidationResult;
  outputDiff?: OutputDiff;
};

export type RelationshipObject = {
  id: string;
  name: string;
};

export enum TestScenarioEnum {
  SPECIFIC_ENTITY_ID = 'specific_entity_id',
  RECENT_100 = 'recent_100',
  PER_PARTNER_10 = 'per_partner_10',
}

export type TestScenarioObject = {
  id: string;
  label: string;
};

export type LocalSchemaDTO = SchemaDTO & {
  is_dirty: boolean;
};

export type LocalTransformationDTO = TransformationDTO & {
  is_dirty: boolean;
};

type Actions = {
  mountTemplates: (props: {
    processType: IntegrationProcessDTO['type'];
    messageType: IntegrationProcessDTO['messageType'];
    inputDataType: DataType;
    outputDataType: DataType;
    templates: Array<TemplateDTO>;
  }) => void;
  mountProcess: (process: IntegrationProcessDTO) => void;
  mountRelationships: (relationships: Array<RelationshipV2>) => void;
  updateInputSchema: (schemaId: string, schema: Partial<LocalSchemaDTO>, dirty?: boolean) => void;
  updateOutputSchema: (schemaId: string, schema: Partial<LocalSchemaDTO>, dirty?: boolean) => void;
  updateTransformation: (
    transformationId: string,
    transformation: Partial<LocalTransformationDTO>,
    dirty?: boolean,
  ) => void;
  addInputSchema: (schema: LocalSchemaDTO) => void;
  addOutputSchema: (schema: LocalSchemaDTO) => void;
  addTransformation: (transformation: LocalTransformationDTO) => void;
  selectInputSchema: (schemaId: string) => void;
  selectOutputSchema: (schemaId: string) => void;
  selectTransformation: (transformationId: string) => void;
  selectRelationship: (relationshipId: string) => void;
  mountSenderEnvelopes: (props: {
    sourceIntegration: IntegrationDTO;
    sourceProcess: IntegrationProcessDTO;
    senderEnvelopes: Array<SenderEnvelopeV2DTO>;
    autoSelectFirstPayload?: boolean;
  }) => void;
  mountMessages: (props: {
    destinationIntegration: IntegrationDTO;
    destinationProcess: IntegrationProcessDTO;
    messages: Array<MessageV2DTO>;
    autoSelectFirstPayload?: boolean;
  }) => void;
  selectPayload: (payloadId: string) => void;
  addPayload: (payload: PayloadObject) => void;
  updatePayload: (payloadId: string, payload: Partial<PayloadObject>) => void;
  replacePayloads: (payloads: Array<PayloadObject>) => void;
  selectTestScenario: (scenarioId: string) => void;
};

type State = {
  inputSchemaTemplates: Array<TemplateDTO>;
  outputSchemaTemplates: Array<TemplateDTO>;
  transformationTemplates: Array<TemplateDTO>;
  existingInputSchemas: Array<LocalSchemaDTO>;
  existingOutputSchemas: Array<LocalSchemaDTO>;
  existingTransformations: Array<LocalTransformationDTO>;
  selectedInputSchema?: LocalSchemaDTO;
  selectedOutputSchema?: LocalSchemaDTO;
  selectedTransformation?: LocalTransformationDTO;
  activeInputSchema?: LocalSchemaDTO;
  activeOutputSchema?: LocalSchemaDTO;
  activeTransformation?: LocalTransformationDTO;
  existingPayloads: Array<PayloadObject>;
  selectedPayload?: PayloadObject;
  normalizerConfig?: Record<string, unknown>;
  existingRelationships: Array<RelationshipObject>;
  selectedRelationship?: RelationshipObject;
  testScenarios: Array<TestScenarioObject>;
  selectedTestScenario?: TestScenarioObject;
  needsRetesting: boolean;
};

const initialState: State = {
  inputSchemaTemplates: [],
  outputSchemaTemplates: [],
  transformationTemplates: [],
  existingInputSchemas: [],
  existingOutputSchemas: [],
  existingTransformations: [],
  existingPayloads: [],
  existingRelationships: [],
  testScenarios: [
    {
      label: '100 recent payloads',
      id: TestScenarioEnum.RECENT_100,
    },
    {
      label: '10 payloads per partner',
      id: TestScenarioEnum.PER_PARTNER_10,
    },
    {
      label: 'Specific entity ID',
      id: TestScenarioEnum.SPECIFIC_ENTITY_ID,
    },
  ],
  selectedTestScenario: {
    label: '100 recent payloads',
    id: TestScenarioEnum.RECENT_100,
  },
  needsRetesting: true,
};

const sortEntitiesByUpdatedAt = <T extends { updated_at: string }>(a: T, b: T): number => {
  return parseISO(b.updated_at).valueOf() - parseISO(a.updated_at).valueOf();
};

const buildSenderEnvelopeName = (senderEnvelope: SenderEnvelopeV2DTO): string => {
  if (senderEnvelope.sender_document_reference_number) {
    return senderEnvelope.sender_document_reference_number;
  }

  if (senderEnvelope.sender_technical_reference_id) {
    return senderEnvelope.sender_technical_reference_id;
  }

  if (senderEnvelope.file_name) {
    return senderEnvelope.file_name;
  }

  return senderEnvelope.id;
};

const formatRelationship = (relationship: RelationshipV2): RelationshipObject => {
  return {
    id: relationship.id,
    name: `${relationship.sender_partner?.name ?? '?'} -> ${relationship.receiver_partner?.name ?? '?'}`,
  };
};

const senderEnvelopeToPayloadObject = (
  sourceIntegration: IntegrationDTO,
  sourceProcess: IntegrationProcessDTO,
  senderEnvelope: SenderEnvelopeV2DTO,
): PayloadObject => {
  // TODO: Currently, we cannot trust the sender_payload_normalized to
  // actually contain json
  let normalizedPayloads: string | undefined;
  if (senderEnvelope.sender_payload_normalized) {
    const normalizedPayloadsJson = parseJson(senderEnvelope.sender_payload_normalized);
    if (normalizedPayloadsJson.success) {
      normalizedPayloads = normalizedPayloadsJson.result;
    }
  }

  return {
    id: senderEnvelope.id,
    inputType: getDataType(sourceIntegration, sourceProcess),
    outputType: 'CANONICAL',
    name: buildSenderEnvelopeName(senderEnvelope),
    date: senderEnvelope.created_at,
    rawInputData: senderEnvelope.sender_payload_raw ? formatRawPayload(senderEnvelope.sender_payload_raw) : undefined,
    transformationInput: normalizedPayloads
      ? JSON.stringify(
          {
            payloads: [normalizedPayloads],
            context: {},
          },
          null,
          2,
        )
      : undefined,
    originalTransformationResult: senderEnvelope.messages_deprecated?.length
      ? {
          output: {
            payloads: senderEnvelope.messages_deprecated
              .filter((message) => !!message.original_canonical)
              .map((message) => message.original_canonical as Record<string, unknown>),
            context: {},
          },
          success: true,
        }
      : undefined,
    inputEditable: false,
  };
};

const buildMessageName = (message: MessageV2DTO): string => {
  const suffix = ` - ${formatDayAndTime(message.created_at)}`;

  if (message.external_message_ref) {
    return `${message.external_message_ref}${suffix}`;
  }

  return `${message.id}${suffix}`;
};

const messagesToPayloadObject = (
  destinationIntegration: IntegrationDTO,
  destinationProcess: IntegrationProcessDTO,
  messages: Array<MessageV2DTO>,
): PayloadObject => {
  // TODO: Currently, we cannot trust the receiver_payload_normalized to
  // actually contain json
  let normalizedPayloads: string | undefined;
  if (messages[0].receiver_envelope_deprecated?.receiver_payload_normalized) {
    const normalizedPayloadsJson = parseJson(messages[0].receiver_envelope_deprecated?.receiver_payload_normalized);
    if (normalizedPayloadsJson.success) {
      normalizedPayloads = normalizedPayloadsJson.result;
    }
  }

  return {
    id: messages[0].id,
    inputType: 'CANONICAL',
    outputType: getDataType(destinationIntegration, destinationProcess),
    name: buildMessageName(messages[0]),
    date: messages[0].created_at,
    transformationInput: JSON.stringify(
      {
        payloads: messages
          .filter((message) => !!message.canonical)
          .map((message) => message.canonical as Record<string, unknown>),
        context: {
          transformation: {
            sender_edi_identifier: messages[0].edi_sender_identifier,
          },
        },
      },
      null,
      2,
    ),
    rawOutputData: messages[0].receiver_envelope_deprecated?.receiver_payload_raw
      ? formatRawPayload(messages[0].receiver_envelope_deprecated?.receiver_payload_raw)
      : undefined,
    originalRawOutputData: messages[0].receiver_envelope_deprecated?.receiver_payload_raw
      ? formatRawPayload(messages[0].receiver_envelope_deprecated?.receiver_payload_raw)
      : undefined,
    transformationResult: normalizedPayloads
      ? {
          output: JSON.stringify(
            {
              payloads: [normalizedPayloads],
              context: {},
            },
            null,
            2,
          ),
          success: true,
        }
      : undefined,
    originalTransformationResult: normalizedPayloads
      ? {
          output: {
            payloads: [normalizedPayloads],
            context: {},
          },
          success: true,
        }
      : undefined,
    inputEditable: false,
  };
};

export const useProcessTransformationStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,

    selectTestScenario: (scenarioId) => {
      console.log('selectTestScenario');
      set((state) => {
        state.selectedTestScenario = state.testScenarios.find((scenario) => scenario.id === scenarioId);

        state.needsRetesting = true;
        state.selectedRelationship = undefined;
      });
    },

    mountTemplates: ({ processType, messageType, inputDataType, outputDataType, templates }) => {
      console.log('mountTemplates');
      set((state) => {
        if (processType === 'SOURCE') {
          state.inputSchemaTemplates = templates.filter(
            (template) =>
              template.category === 'SCHEMA' &&
              template.type === 'SOURCE' &&
              template.content_type === inputDataType &&
              template.message_type === messageType,
          );
          state.outputSchemaTemplates = templates.filter(
            (template) =>
              template.category === 'SCHEMA' &&
              template.type === 'PROVIDED' &&
              template.content_type === outputDataType &&
              template.message_type === messageType,
          );
          state.transformationTemplates = templates.filter(
            (template) =>
              template.category === 'TRANSFORMATION' &&
              template.type === 'SOURCE' &&
              template.content_type === inputDataType &&
              template.message_type === messageType,
          );
        }

        if (processType === 'DESTINATION') {
          state.inputSchemaTemplates = templates.filter(
            (template) =>
              template.category === 'SCHEMA' &&
              template.type === 'EXPECTED' &&
              template.content_type === inputDataType &&
              template.message_type === messageType,
          );
          state.outputSchemaTemplates = templates.filter(
            (template) =>
              template.category === 'SCHEMA' &&
              template.type === 'DESTINATION' &&
              template.content_type === outputDataType &&
              template.message_type === messageType,
          );
          state.transformationTemplates = templates.filter(
            (template) =>
              template.category === 'TRANSFORMATION' &&
              template.type === 'DESTINATION' &&
              template.content_type === outputDataType &&
              template.message_type === messageType,
          );
        }

        state.needsRetesting = true;
      });
    },

    mountProcess: (process) => {
      console.log('mountProcess');
      set((state) => {
        if (process.type === 'SOURCE') {
          state.existingInputSchemas =
            process.schemas
              ?.filter((schema) => schema.type === 'SOURCE')
              .map((schema) => ({
                ...schema,
                is_dirty: false,
              })) || [];
          state.existingOutputSchemas =
            process.schemas
              ?.filter((schema) => schema.type === 'PROVIDED')
              .map((schema) => ({
                ...schema,
                is_dirty: false,
              })) || [];
        }

        if (process.type === 'DESTINATION') {
          state.existingInputSchemas =
            process.schemas
              ?.filter((schema) => schema.type === 'EXPECTED')
              .map((schema) => ({
                ...schema,
                is_dirty: false,
              })) || [];
          state.existingOutputSchemas =
            process.schemas
              ?.filter((schema) => schema.type === 'DESTINATION')
              .map((schema) => ({
                ...schema,
                is_dirty: false,
              })) || [];
        }

        state.activeInputSchema = state.existingInputSchemas.find((schema) => schema.activated_at !== null);
        state.selectedInputSchema =
          state.activeInputSchema ??
          (state.existingInputSchemas.length
            ? state.existingInputSchemas.slice().sort(sortEntitiesByUpdatedAt)[0]
            : undefined);

        state.activeOutputSchema = state.existingOutputSchemas.find((schema) => schema.activated_at !== null);
        state.selectedOutputSchema =
          state.activeOutputSchema ??
          (state.existingOutputSchemas.length
            ? state.existingOutputSchemas.slice().sort(sortEntitiesByUpdatedAt)[0]
            : undefined);

        state.existingTransformations =
          process.transformations
            ?.filter((transformation) => transformation.group === 'DEFAULT')
            .map((transformation) => ({
              ...transformation,
              is_dirty: false,
            })) || [];
        state.activeTransformation = state.existingTransformations.find(
          (transformation) => transformation.activated_at !== null,
        );
        state.selectedTransformation =
          state.activeTransformation ??
          (state.existingTransformations.length
            ? state.existingTransformations.slice().sort(sortEntitiesByUpdatedAt)[0]
            : undefined);

        state.normalizerConfig = process.configuration?.normalizer_config as Record<string, unknown> | undefined;

        state.needsRetesting = true;
      });
    },

    mountRelationships: (relationships) => {
      console.log('mountRelationships');
      set((state) => {
        state.existingRelationships = relationships.map(formatRelationship);

        if (state.selectedRelationship) {
          state.selectedRelationship = state.existingRelationships.find(
            (relationship) => relationship.id === state.selectedRelationship?.id,
          );
        }

        state.needsRetesting = true;
      });
    },

    selectRelationship: (relationshipId) => {
      console.log('selectRelationship');
      set((state) => {
        state.selectedRelationship = state.existingRelationships.find(
          (relationship) => relationship.id === relationshipId,
        );

        state.needsRetesting = true;
      });
    },

    selectInputSchema: (schemaId) => {
      console.log('selectInputSchema');
      set((state) => {
        state.selectedInputSchema = state.existingInputSchemas.find((schema) => schema.id === schemaId);
        state.needsRetesting = true;
      });
    },

    selectOutputSchema: (schemaId) => {
      console.log('selectOutputSchema');
      set((state) => {
        state.selectedOutputSchema = state.existingOutputSchemas.find((schema) => schema.id === schemaId);
        state.needsRetesting = true;
      });
    },

    selectTransformation: (transformationId) => {
      console.log('selectTransformation');
      set((state) => {
        state.selectedTransformation = state.existingTransformations.find(
          (transformation) => transformation.id === transformationId,
        );
        state.needsRetesting = true;
      });
    },

    updateInputSchema: (schemaId, schema, dirty = true) => {
      console.log('updateInputSchema');
      set((state) => {
        state.existingInputSchemas = state.existingInputSchemas.map((existingSchema) =>
          existingSchema.id === schemaId
            ? {
                ...existingSchema,
                ...schema,
                is_dirty: dirty ? true : schema.is_dirty ?? existingSchema.is_dirty,
              }
            : existingSchema,
        );

        if (state.selectedInputSchema?.id === schemaId) {
          state.selectedInputSchema = state.existingInputSchemas.find((schema) => schema.id === schemaId);
          state.needsRetesting = true;
        }
      });
    },

    updateOutputSchema: (schemaId, schema, dirty = true) => {
      console.log('updateOutputSchema');
      set((state) => {
        state.existingOutputSchemas = state.existingOutputSchemas.map((existingSchema) =>
          existingSchema.id === schemaId
            ? {
                ...existingSchema,
                ...schema,
                is_dirty: dirty ? true : schema.is_dirty ?? existingSchema.is_dirty,
              }
            : existingSchema,
        );

        if (state.selectedOutputSchema?.id === schemaId) {
          state.selectedOutputSchema = state.existingOutputSchemas.find((schema) => schema.id === schemaId);
          state.needsRetesting = true;
        }
      });
    },

    updateTransformation: (transformationId, transformation, dirty = true) => {
      //   console.log('updateTransformation', transformation, dirty);
      set((state) => {
        state.existingTransformations = state.existingTransformations.map((existingTransformation) =>
          existingTransformation.id === transformationId
            ? {
                ...existingTransformation,
                ...transformation,
                is_dirty: dirty ? true : transformation.is_dirty ?? existingTransformation.is_dirty,
              }
            : existingTransformation,
        );

        if (state.selectedTransformation?.id === transformationId) {
          state.selectedTransformation = state.existingTransformations.find(
            (transformation) => transformation.id === transformationId,
          );
          state.needsRetesting = true;
        }
      });
    },

    addInputSchema: (schema) => {
      console.log('addInputSchema');
      set((state) => {
        const pristineSchema = {
          ...schema,
          is_dirty: false,
        };
        state.existingInputSchemas.push(pristineSchema);
        state.selectedInputSchema = pristineSchema;
        state.needsRetesting = true;
      });
    },

    addOutputSchema: (schema) => {
      console.log('addOutputSchema');
      const pristineSchema = {
        ...schema,
        is_dirty: false,
      };
      set((state) => {
        state.existingOutputSchemas.push(pristineSchema);
        state.selectedOutputSchema = pristineSchema;
        state.needsRetesting = true;
      });
    },

    addTransformation: (transformation) => {
      console.log('addTransformation');
      const pristineTransformation = {
        ...transformation,
        is_dirty: false,
      };
      set((state) => {
        state.existingTransformations.push(pristineTransformation);
        state.selectedTransformation = pristineTransformation;
        state.needsRetesting = true;
      });
    },

    mountSenderEnvelopes: ({ sourceIntegration, sourceProcess, senderEnvelopes, autoSelectFirstPayload }) => {
      console.log('mountSenderEnvelopes');
      set((state) => {
        state.existingPayloads = senderEnvelopes.map((senderEnvelope) =>
          senderEnvelopeToPayloadObject(sourceIntegration, sourceProcess, senderEnvelope),
        );

        if (autoSelectFirstPayload && state.existingPayloads.length) {
          state.selectedPayload = state.existingPayloads[0];
        }

        state.needsRetesting = true;
      });
    },

    mountMessages: ({ destinationIntegration, destinationProcess, messages, autoSelectFirstPayload }) => {
      console.log('mountMessages');
      set((state) => {
        state.existingPayloads = Object.entries(
          messages.reduce(
            (carry, message) =>
              message.receiver_envelope_deprecated?.id
                ? {
                    ...carry,
                    [message.receiver_envelope_deprecated.id]: [
                      ...(carry[message.receiver_envelope_deprecated.id] ?? []),
                      message,
                    ],
                  }
                : {
                    ...carry,
                    [message.id]: [message],
                  },
            {} as Record<string, Array<MessageV2DTO>>,
          ),
        ).map(([_, messages]) => messagesToPayloadObject(destinationIntegration, destinationProcess, messages));

        if (autoSelectFirstPayload && state.existingPayloads.length) {
          state.selectedPayload = state.existingPayloads[0];
        }

        state.needsRetesting = true;
      });
    },

    selectPayload: (payloadId) => {
      console.log('selectPayload');
      set((state) => {
        if (state.selectedPayload?.id === payloadId) {
          return;
        }
        state.selectedPayload = state.existingPayloads.find((payload) => payload.id === payloadId);
      });
    },

    addPayload: (payload) => {
      console.log('addPayload');
      set((state) => {
        state.existingPayloads.push(payload);
        state.selectedPayload = payload;
        state.needsRetesting = true;
      });
    },

    updatePayload: (payloadId, payload) => {
      console.log('updatePayload');
      set((state) => {
        let payloadWasUpdated = false;
        state.existingPayloads = state.existingPayloads.map((existingPayload) => {
          if (existingPayload.id !== payloadId) {
            return existingPayload;
          }
          const updatedPayload = {
            ...existingPayload,
            ...payload,
          };

          if (isEqual(updatedPayload, existingPayload)) {
            return existingPayload;
          }
          payloadWasUpdated = true;
          return updatedPayload;
        });

        if (payloadWasUpdated && state.selectedPayload?.id === payloadId) {
          state.selectedPayload = state.existingPayloads.find((payload) => payload.id === payloadId);
        }

        state.needsRetesting = payloadWasUpdated;
      });
    },

    replacePayloads: (payloads) => {
      console.log('replacePayloads');
      set((state) => {
        state.existingPayloads = payloads;

        if (state.selectedPayload) {
          state.selectedPayload = payloads.find((payload) => payload.id === state.selectedPayload?.id);
        }

        state.needsRetesting = false;
      });
    },
  })),
);
