import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { MessageNonFieldErrorBanner } from '../MessageNonFieldErrorBanner/MessageNonFieldErrorBanner';

export const DocumentSectionError = ({ path }: { path: string }) => {
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { error } = useWebEDIFieldError(path);

  if (!error) {
    return null;
  }

  return (
    <MessageNonFieldErrorBanner error={error} totalNumberOfErrors={numberOfErrors} setCurrentError={setCurrentError} />
  );
};
