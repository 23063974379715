import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { Section } from 'components/Display/Section/Section';
import { FileUploader } from 'components/FileUploader/FileUploader';
import { Base64Loader } from 'components/Form/FileInput/Loaders/Base64Loader/Base64Loader';
import { useTranslation } from 'react-i18next';
import {
  useCreateIntegrationProcessTest,
  useIntegrationProcessTests,
} from 'services/repositories/integrationProcesses/integrationProcesses';
import { useNotificationStore, NotificationType } from 'stores/notifications/notifications';
import { IntegrationProcessTestDTO } from 'support/types';
import { Button } from 'components/Form/Button/Button';
import { IntegrationProcessTestList } from '../../../../components/IntegrationProcessTestList/IntegrationProcessTestList';
import { useIntegration } from 'services/repositories/integrations/integrations';
import { ENV } from 'services/environment/environment';

const AsyncOutboundTest = ({
  integrationId,
  processId,
  onShowItemDetails,
}: {
  integrationId: string;
  processId: string;
  onShowItemDetails: (item: IntegrationProcessTestDTO) => void;
}) => {
  const { t } = useTranslation();
  const {
    data,
    isInitialLoading,
    refetch,
    isFetching: isLoadingIntegrationProcessTests,
  } = useIntegrationProcessTests({
    variables: { integrationId, processId },
    refetchInterval: 10000,
  });
  const { data: integration } = useIntegration({ variables: { id: integrationId } });
  const { addNotification } = useNotificationStore();
  const refreshTests = async () => {
    try {
      await refetch({ throwOnError: true });
      addNotification(t('integrations:processes.new.test.refresh.success'));
    } catch (e) {
      addNotification(t('integrations:processes.new.test.refresh.error'), NotificationType.error);
    }
  };

  return (
    <div className="space-y-6">
      <Section.Item title={t('integrations:processes.new.test.edifact.OUTBOUND.id.title')}>
        {ENV.AS2_PROCUROS_ID}
      </Section.Item>
      <Section.Item title={t('integrations:processes.new.test.edifact.OUTBOUND.url.title')}>
        {ENV.AS2_BASE_URL}
        {integration?.handle}
      </Section.Item>
      <Button
        size="extra-small"
        variant="secondary"
        LeftIcon={ArrowPathIcon}
        onClick={refreshTests}
        loading={isLoadingIntegrationProcessTests}
        analyticsId="integrations:process-test-refresh"
      >
        {t('common:refresh')}
      </Button>
      <IntegrationProcessTestList data={data} isLoading={isInitialLoading} onShowItemDetails={onShowItemDetails} />
    </div>
  );
};

const SyncOutboundTest = ({
  integrationId,
  processId,
  onShowItemDetails,
}: {
  integrationId: string;
  processId: string;
  onShowItemDetails: (item: IntegrationProcessTestDTO) => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading: isCreatingTests } = useCreateIntegrationProcessTest();
  const {
    data,
    isInitialLoading,
    refetch,
    isFetching: isLoadingIntegrationProcessTests,
  } = useIntegrationProcessTests({
    variables: { integrationId, processId },
    refetchInterval: 10000,
  });
  const { addNotification } = useNotificationStore();

  const triggerTest = async (data: Array<string>) => {
    if (!integrationId || !processId) return;
    await mutateAsync({
      integrationId,
      processId,
      data: { type: 'OUTBOUND_SYNCHRONOUS', fixtures: { payload: data[0] } },
    });
  };

  const refreshTests = async () => {
    try {
      await refetch({ throwOnError: true });
      addNotification(t('integrations:processes.new.test.refresh.success'));
    } catch (e) {
      addNotification(t('integrations:processes.new.test.refresh.error'), NotificationType.error);
    }
  };

  return (
    <div className="space-y-6">
      <FileUploader
        isUploading={isCreatingTests}
        label={t('integrations:processes.new.test.edifact.OUTBOUND.sync.actions.uploadFile')}
        onLoad={triggerTest}
        onError={() =>
          addNotification(t('common:components.fileInput.notifications.invalid-file'), NotificationType.error)
        }
        loader={Base64Loader}
        accept={{
          'text/plain': ['.edi', '.x12', '.edifact', '.txt'],
        }}
      />
      <Button
        size="extra-small"
        variant="secondary"
        LeftIcon={ArrowPathIcon}
        onClick={refreshTests}
        loading={isLoadingIntegrationProcessTests}
        analyticsId="integrations:process-test-refresh"
      >
        {t('common:refresh')}
      </Button>
      <IntegrationProcessTestList data={data} isLoading={isInitialLoading} onShowItemDetails={onShowItemDetails} />
    </div>
  );
};

export const EDIFACTOutboundTests = {
  Async: AsyncOutboundTest,
  Sync: SyncOutboundTest,
};
