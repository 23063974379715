import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode, useCallback } from 'react';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { Popup } from '../Popup/Popup';

type ConfirmDeletePopupProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  description: ReactNode;
  onDelete: () => void;
  deleteText?: string;
  cancelText?: string;
  isDeleting?: boolean;
};

export const ConfirmDeletePopup: FC<ConfirmDeletePopupProps> = ({
  open,
  onClose,
  onDelete,
  title,
  description,
  cancelText,
  deleteText,
  isDeleting,
}) => {
  const { t } = useTranslation();
  const CancelButton = useCallback(
    () => (
      <Button variant="secondary" onClick={onClose} analyticsId="confirm_delete_popup_cancel">
        {cancelText ?? t('common:cancel')}
      </Button>
    ),
    [onClose, cancelText, t],
  );

  const PrimaryButton = useCallback(
    () => (
      <Button
        onClick={() => onDelete()}
        variant="secondary-danger"
        disabled={isDeleting}
        analyticsId="confirm_delete_popup_confirm"
      >
        {deleteText ?? t('common:delete')}
      </Button>
    ),
    [deleteText, t, onDelete, isDeleting],
  );
  return (
    <Popup
      CancelButton={CancelButton}
      PrimaryButton={PrimaryButton}
      open={open}
      onClose={onClose}
      title={title}
      subtitle={description}
      icon={
        <div className="flex !h-10 !w-10 shrink-0 grow-0 items-center justify-center rounded-full bg-red-100">
          <ExclamationTriangleIcon className="size-6 text-red-600" aria-hidden="true" />
        </div>
      }
    />
  );
};
