import { CalendarIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Section } from 'components/Display/Section/Section';
import { Input } from 'components/Form/Input/Input/Input';
import { Textarea } from 'components/Form/Input/Textarea/Textarea';
import { Radio } from 'components/Form/Radio/Radio';
import { useRef, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, Form } from 'react-router-dom';
import { useReviewTestMessage } from 'services/repositories/relationships/relationships';
import { addNotification } from 'stores/notifications/notifications';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionsRoutes } from 'support/types';
import { Button } from 'components/Form/Button/Button';
import * as yup from 'yup';

type ReviewActionForm = {
  reviewAction: 'RELATIONSHIP_TEST_MESSAGE_APPROVED' | 'RELATIONSHIP_TEST_MESSAGE_REJECTED';
  goLiveImmediatly: string;
  goLiveDate?: Date | undefined;
  shouldImmediatelyActivateTheConnection?: boolean | undefined;
  rejectionReason?: string | undefined;
};

export const ReviewAction = ({ relationshipId }: { relationshipId: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isLoading: isSendingReview } = useReviewTestMessage();
  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const sendReviewAction = (data: ReviewActionForm) => {
    const action = data.reviewAction;
    let submission: Parameters<typeof mutate>[0];
    if (action === 'RELATIONSHIP_TEST_MESSAGE_APPROVED') {
      const goLiveDate =
        data.goLiveImmediatly === 'now' || !data.goLiveDate
          ? new Date().toISOString()
          : new Date(data.goLiveDate).toISOString();
      submission = {
        state: action,
        go_live_date: goLiveDate,
        activate_connection_immediately: data.goLiveImmediatly === 'now' || !data.goLiveDate,
        relationshipId,
      };
    } else {
      submission = { state: action, relationshipId, reason: data.rejectionReason };
    }

    mutate(submission, {
      onSuccess: (_, variables) => {
        if (variables.state === 'RELATIONSHIP_TEST_MESSAGE_APPROVED') {
          addNotification(t('connections:testMessagePendingReview.notifications.successfulApproval'));
        }
        navigate(routeToPage(ConnectionsRoutes.relationshipDetails, { relationshipId }));
      },
    });
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ReviewActionForm>({
    defaultValues: {
      goLiveImmediatly: 'now',
    },
    resolver: yupResolver(
      yup.object().shape({
        reviewAction: yup
          .string()
          .oneOf(['RELATIONSHIP_TEST_MESSAGE_APPROVED', 'RELATIONSHIP_TEST_MESSAGE_REJECTED'])
          .required(),
        goLiveImmediatly: yup.string().required(),
        goLiveDate: yup
          .date()
          .optional()
          .transform((v) => (!isNaN(v) && v instanceof Date ? v : undefined))
          .when(['reviewAction', 'goLiveImmediatly'], {
            is: (reviewAction: string, goLiveImmediatly: string) =>
              reviewAction && 'TEST_MESSAGE_APPROVED' && goLiveImmediatly === 'date',
            then: (schema) => schema.min(new Date()).required(),
          }),
        rejectionReason: yup.string().when('reviewAction', {
          is: 'TEST_MESSAGE_REJECTED',
          then: (schema) => schema.required(),
        }),
      }),
    ),
  });
  const [reviewAction, goLiveImmediatly] = useWatch({ name: ['reviewAction', 'goLiveImmediatly'], control });

  useEffect(() => {
    if (goLiveImmediatly === 'date') {
      dateInputRef.current?.showPicker?.();
    }
  }, [goLiveImmediatly]);

  const { ref: goLiveDateRef, ...goLiveRegister } = register('goLiveDate');
  return (
    <Form onSubmit={handleSubmit(sendReviewAction)} className="space-y-6">
      <div>
        <Section.Title variant="small">
          {t('connections:testMessagePendingReview.reviewAction.title')}{' '}
          <span className="text-procuros-green-500"> *</span>
        </Section.Title>
        <Radio.Group variant="list">
          <Radio.Item
            {...register('reviewAction')}
            value="RELATIONSHIP_TEST_MESSAGE_APPROVED"
            hasErrors={Boolean(errors.reviewAction)}
            label={t('connections:testMessagePendingReview.reviewAction.approve.label')}
            description={t('connections:testMessagePendingReview.reviewAction.approve.description')}
            variant="list"
          />
          <Radio.Item
            {...register('reviewAction')}
            hasErrors={Boolean(errors.reviewAction)}
            value="RELATIONSHIP_TEST_MESSAGE_REJECTED"
            label={t('connections:testMessagePendingReview.reviewAction.reject.label')}
            description={t('connections:testMessagePendingReview.reviewAction.reject.description')}
            variant="list"
          />
        </Radio.Group>
      </div>
      {reviewAction === 'RELATIONSHIP_TEST_MESSAGE_APPROVED' ? (
        <div>
          <Section.Title variant="small">
            {t('connections:testMessagePendingReview.reviewAction.approve.dateSelection.label')}{' '}
            <span className="text-procuros-green-500"> *</span>
          </Section.Title>
          <Radio.Group variant="list">
            <Radio.Item
              {...register('goLiveImmediatly')}
              value="now"
              label={t('connections:testMessagePendingReview.reviewAction.approve.dateSelection.now.label')}
              description={t('connections:testMessagePendingReview.reviewAction.approve.dateSelection.now.description')}
              variant="list"
              hasErrors={Boolean(errors.goLiveImmediatly)}
            ></Radio.Item>
            <Radio.Item
              hasErrors={Boolean(errors.goLiveImmediatly)}
              {...register('goLiveImmediatly')}
              value="date"
              label={t('connections:testMessagePendingReview.reviewAction.approve.dateSelection.specificDate.label')}
              variant="list"
            >
              <Input
                {...goLiveRegister}
                ref={(e) => {
                  goLiveDateRef(e);
                  dateInputRef.current = e;
                }}
                type="datetime-local"
                onClick={() =>
                  setValue('goLiveImmediatly', 'date', { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                }
                min={new Date().toISOString()}
                hasError={Boolean(errors.goLiveDate)}
                leftIcon={<CalendarIcon className="size-5 text-gray-500" />}
              />
            </Radio.Item>
          </Radio.Group>
        </div>
      ) : null}

      {reviewAction === 'RELATIONSHIP_TEST_MESSAGE_REJECTED' ? (
        <Textarea
          label={t('connections:testMessagePendingReview.reviewAction.reject.rejectReason.label')}
          required
          {...register('rejectionReason')}
          hasErrors={Boolean(errors.rejectionReason)}
        />
      ) : null}
      <Button
        type="submit"
        loading={isSendingReview}
        analyticsId="testing-flow:test-document-review-submit"
        analyticsProperties={{ review_action: reviewAction }}
      >
        {t('connections:testMessagePendingReview.reviewAction.submit')}
      </Button>
    </Form>
  );
};
