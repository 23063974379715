import { useNavigate } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { ConnectionsRoutes, Connectors, DocumentType, RelationshipV2 } from 'support/types';
import { TestDocumentsSection } from './components/TestDocumentsSection/TestDocumentsSection';
import { getTradePartner } from 'support/helpers/relationships/relationships';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionTestGuidelines } from './components/ConnectionTestGuidelines/ConnectionTestGuidelines';
import { useIntegration } from 'services/repositories/integrations/integrations';

export const ConnectionsTestNonWebEDI = ({ relationship }: { relationship: RelationshipV2 }) => {
  const navigate = useNavigate();
  const { data: currentPartner } = useCurrentPartner();
  const senderIntegration = relationship.sender_integration?.testing || relationship.sender_integration?.production;
  const { data: integration } = useIntegration({
    variables: { id: senderIntegration?.id, withConfiguration: true },
    enabled: Boolean(senderIntegration),
  });

  if (!relationship || !senderIntegration || !integration) return null;
  const otherPartner = currentPartner && getTradePartner(relationship, currentPartner?.id);

  return (
    <div className="space-y-8 divide-y divide-gray-200 first:-mt-8 *:first:pt-8">
      <ConnectionTestGuidelines
        connectorConfig={integration?.connectorConfig}
        connector={integration.connector as Connectors}
        relationship={relationship}
      />
      <TestDocumentsSection
        documentType={relationship.message_type as DocumentType | undefined}
        receiverPartner={otherPartner}
        onSuccess={() =>
          navigate(routeToPage(ConnectionsRoutes.relationshipDetails, { relationshipId: relationship.id }))
        }
        relationshipId={relationship.id}
      />
    </div>
  );
};
