import { createMutation, createQuery } from 'react-query-kit';
import { fetchData } from 'services/http/http';
import portalSchema from 'support/types/schema-portal';

export type GetMessageResponse =
  portalSchema.paths['/v1/messages/{messageId}']['get']['responses']['200']['content']['application/json'];

export const useMessage = createQuery<GetMessageResponse, { messageId: string | undefined }, Error>({
  primaryKey: '/messages/:messageId',
  queryFn: async ({ queryKey: [, { messageId }] }) => {
    const { data } = await fetchData<GetMessageResponse>(`/v1/messages/${messageId}`);
    return data;
  },
  enabled: (_, { messageId }) => !!messageId,
});

export type GetMessageWithProcessSpecificationResponse =
  portalSchema.paths['/v2/messages/{messageId}']['get']['responses']['200']['content']['application/json'];
export const useMessageWithProcessSpecification = createQuery<
  GetMessageWithProcessSpecificationResponse,
  { messageId: string | undefined },
  Error
>({
  primaryKey: '/v2/messages/:messageId',
  queryFn: async ({ queryKey: [, { messageId }] }) => {
    const { data } = await fetchData<GetMessageWithProcessSpecificationResponse>(`/v2/messages/${messageId}`);
    return data;
  },
  enabled: (_, { messageId }) => !!messageId,
});

export type UpdateMessageRequest =
  portalSchema.paths['/v1/messages/{messageId}']['put']['requestBody']['content']['application/json'];
export type UpdateMessageResponse =
  portalSchema.paths['/v1/messages/{messageId}']['put']['responses']['200']['content']['application/json'] & {
    aaa: number;
  };
export const useUpdateMessage = createMutation<
  UpdateMessageResponse,
  { messageId: string; data: UpdateMessageRequest },
  Error
>({
  mutationFn: async ({ messageId, data }) => {
    const { data: responseData } = await fetchData<UpdateMessageResponse>(`/v1/messages/${messageId}`, {
      method: 'put',
      data,
    });
    return responseData;
  },
});
