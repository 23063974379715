import { ReactNode } from 'react';
import { classNames } from 'support/helpers/generic/generic';

const HorizontalSection = ({
  title,
  description,
  action,
  children,
}: {
  title: string;
  description?: string;
  action?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <section className="grid grid-cols-[320px_1fr] gap-12">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h3 className="text-base font-bold text-black">{title}</h3>
          {description && <p className="text-sm text-gray-500">{description}</p>}
        </div>
        {action}
      </div>
      <div className="flex w-full flex-col gap-6">{children}</div>
    </section>
  );
};

const VerticalSection = ({
  header,
  children,
  cols = 1,
  noGap,
}: {
  header?: ReactNode;
  children: ReactNode;
  cols?: number;
  noGap?: boolean;
}) => {
  return (
    <section>
      {header && <div className="pt-6">{header}</div>}
      <div
        className={classNames(
          `grid gap-6`,
          cols > 1 ? `grid-cols-${cols}` : undefined,
          header ? 'border-t border-gray-200 mt-6 pt-4' : undefined,
          noGap ? '!pt-0' : undefined,
        )}
      >
        {children}
      </div>
    </section>
  );
};

const Title = ({ children, variant = 'normal' }: { children: ReactNode; variant?: 'normal' | 'small' }) => {
  return <h4 className={variant === 'small' ? 'font-bold' : 'text-lg font-bold text-gray-900'}>{children}</h4>;
};

const Subtitle = ({ children }: { children: ReactNode }) => {
  return <h5 className="text-sm text-gray-500">{children}</h5>;
};

const Item = ({
  title,
  children,
  direction = 'vertical',
}: {
  title?: ReactNode;
  children: ReactNode;
  direction?: 'vertical' | 'horizontal';
}) => {
  return (
    <div className={direction === 'horizontal' ? 'grid w-full grid-cols-2 items-center' : undefined}>
      {title ? <span className="text-xs text-gray-500">{title}</span> : null}
      <div className="text-sm text-gray-700">{children}</div>
    </div>
  );
};

const EmtpyState = ({ children }: { children: ReactNode }) => {
  return <div className="text-sm font-normal text-gray-700">{children}</div>;
};

export const Section = Object.assign(
  {},
  {
    Horizontal: HorizontalSection,
    Vertical: VerticalSection,
    Title,
    Subtitle,
    Item,
    EmtpyState,
  },
);
