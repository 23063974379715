import { FilterFn } from '@tanstack/react-table';
import { Ranking, rankItem } from '@tanstack/match-sorter-utils';

/**
 * Default fuzzy filter implementation
 * @see https://tanstack.com/table/latest/docs/framework/react/examples/filters-fuzzy
 */
export const createFuzzyFilter =
  <TData = unknown>(threshold: Ranking): FilterFn<TData> =>
  (row, columnId, filterValue) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), filterValue, { threshold });
    // Return if the item should be filtered in/out
    return itemRank.passed;
  };
