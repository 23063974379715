import { LoaderResult } from '../../types';

export const Base64Loader = async (file: File): Promise<LoaderResult> => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      const data = [btoa(reader.result as string)];
      resolve({ data });
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};
