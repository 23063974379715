import { createQuery } from 'react-query-kit';

import portalSchema from 'support/types/schema-portal';
import { fetchData } from '../../http/http';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import { Filter } from 'components/ListTable/types';
import { TemplateDTO } from 'support/types';

type ListTemplatesResponse =
  portalSchema.paths['/v1/templates']['get']['responses']['200']['content']['application/json'];

export const useTemplates = createQuery<Array<TemplateDTO>, { query?: Filter }, Error>({
  primaryKey: '/templates',
  queryFn: async ({ queryKey: [_, variables] }) => {
    let hasMorePages = true;
    let nextPageCursor = null;
    let currentPage = 1;
    let data: Array<TemplateDTO> = [];

    // 50 pages limit because I'm just super scared to accidentally DDOS ourselves
    while (hasMorePages && currentPage <= 50) {
      const urlParams = buildFilterUrl({ filterValues: [], ...variables.query, cursor: nextPageCursor });
      const { data: responseData } = await fetchData<ListTemplatesResponse>('/v1/templates' + urlParams);

      hasMorePages = !!responseData.meta.next_cursor;
      nextPageCursor = responseData.meta.next_cursor;
      currentPage++;

      data = [...data, ...responseData.data];
    }

    return data;
  },
});
