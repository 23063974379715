import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardRoutes } from 'support/types';
import { isPotentiallyAuthenticated } from 'services/auth/auth';

export function AuthPage({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl: string = location.state?.from || DashboardRoutes.root;

  useEffect(() => {
    // If the user is already logged in, redirect to the dashboard
    if (isPotentiallyAuthenticated()) {
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate, redirectUrl]);

  return <div className="flex min-h-screen flex-col bg-gray-50 py-12 pt-[112px] sm:px-6 lg:px-8">{children}</div>;
}
