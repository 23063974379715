import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';
import { ReactNode, FormEventHandler } from 'react';

export const AuthBodySection = ({
  children,
  heading,
  nonInputErrors,
  onSubmit,
}: {
  children: ReactNode;
  heading: string;
  nonInputErrors: Array<string>;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
}) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <ApplicationLogo variant="dark" className="mx-auto h-11 w-auto" />

      <h2 className="mt-24 text-center text-3xl font-extrabold text-gray-900">{heading}</h2>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {onSubmit ? (
          <form onSubmit={onSubmit} className="space-y-6 bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <ValidationErrors errors={nonInputErrors} />
            {children}
          </form>
        ) : (
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">{children}</div>
        )}
      </div>
    </div>
  );
};
