import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Image } from 'components/Image/Image';
import { ApplicationLogoSmall } from 'components/Logo/ApplicationLogoSmall/ApplicationLogoSmall';
import { classNames } from 'support/helpers/generic/generic';

const sizeClasses = {
  wrapper: {
    base: 'w-10 h-10',
    large: 'w-12 h-12',
  },
  image: {
    base: 'w-7 h-7',
    large: 'w-9 h-9',
  },
};

type SIZE_VARIANTS = 'base' | 'large';

export const PartnerLogo = ({
  imageUrl,
  size = 'base',
  isLoading = false,
  knownPartner = true,
}: {
  imageUrl: string | undefined | null;
  isLoading?: boolean;
  size?: SIZE_VARIANTS;
  knownPartner?: boolean;
}) => {
  if (isLoading) {
    return <PartnerLogoSkeleton className={sizeClasses.image[size]} />;
  }
  return (
    <div
      className={classNames(
        'flex-shrink-0 border border-gray-200 bg-white rounded flex items-center justify-center',
        sizeClasses.wrapper[size],
      )}
    >
      {knownPartner ? (
        imageUrl ? (
          <Image
            className={classNames('object-contain', sizeClasses.image[size])}
            src={imageUrl}
            alt=""
            fallback={<ApplicationLogoSmall className={sizeClasses.image[size]} />}
          />
        ) : (
          <ApplicationLogoSmall className={sizeClasses.image[size]} />
        )
      ) : (
        <UnkownPartnerLogo className={sizeClasses.image[size]} />
      )}
    </div>
  );
};

const PartnerLogoSkeleton = ({ className }: { className: string }) => {
  return <div className={classNames('leading-relaxed animate-pulse bg-gray-200 rounded shrink-0', className)} />;
};

const UnkownPartnerLogo = ({ className }: { className: string }) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center text-gray-400 bg-gray-50 rounded shrink-0 w-full h-full',
        className,
      )}
    >
      <QuestionMarkCircleIcon className={className} />
    </div>
  );
};
