import cloneDeep from 'lodash/cloneDeep';
import { ENV } from 'services/environment/environment';
import { formatCurrency } from '../currency/currency';
import { formatDateToISO } from '../dateTime/dateTime';

export { default as classNames } from 'classnames';

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const deepCopy = <T>(object: object | Array<object>): T => cloneDeep(object) as T;

export const capitaliseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const currentEnvironmentIndicatorColor = {
  local: '#8B5CF6',
  dev: '#06B6D4',
  staging: '#F59E0B',
  production: '#000000',
}[ENV.APP_ENV];

export const isProduction = () => {
  return ['production', 'staging'].includes(ENV.APP_ENV);
};

export const isLocal = () => {
  return ENV.APP_ENV === 'local';
};

export const humanizeTitle = (title = ''): string => {
  return title
    .toLowerCase()
    .split('_')
    .map((x: string) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
};

export const getDropdownOptionsValues = <T>(options: Array<{ label: string; value: T }>) => {
  return options.reduce((acc, option) => {
    acc.push(option.value);
    return acc;
  }, [] as Array<T>);
};

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

export const formatValue = (value: string | number, formatTo: string, options?: { currency: string | undefined }) => {
  switch (formatTo) {
    case 'currency':
      return formatCurrency(parseFloat(value as string), options?.currency || 'EUR');
    case 'date':
      return formatDateToISO(new Date((typeof value === 'number' ? value : parseInt(value)) * 1000));
    default:
      return value;
  }
};

export const joinArrayWithSeparators = (array: Array<string>, middleJoiner: string, lastJoiner?: string): string => {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else {
    const mainJoiner = lastJoiner ? lastJoiner : middleJoiner;
    const result = array.slice(0, -1).join(middleJoiner) + mainJoiner + array.slice(-1);
    return result;
  }
};

type JsonParseResult = { success: boolean; result: any };

export const parseJson = (rawData: string): JsonParseResult => {
  try {
    const jsonData = JSON.parse(rawData);
    return { success: true, result: jsonData };
  } catch (e: any) {
    return { success: false, result: e.message };
  }
};

export const withoutNulls = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(withoutNulls);
  }

  if (typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([, value]) => typeof value !== 'undefined' && value !== null)
        .map(([key, value]) => [key, withoutNulls(value)]),
    );
  }

  return obj;
};

const isObject = (obj: any): obj is Record<string, any> =>
  obj !== null && typeof obj === 'object' && !Array.isArray(obj);

const setValueAtPath = (obj: Record<string, any>, path: string, value: any) => {
  const keys = path.split('.');
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    let key = keys[i];

    // Check if the key indicates an array
    const arrayMatch = key.match(/(.*)\[\]$/);
    if (arrayMatch) {
      key = arrayMatch[1];
      if (!Array.isArray(current[key])) {
        current[key] = [{}]; // Initialize as an array with one object if not already an array
      }
      current = current[key][0]; // Always use the first element of the array
    } else {
      if (!isObject(current[key])) {
        current[key] = {};
      }
      current = current[key];
    }
  }

  const finalKey = keys[keys.length - 1];
  const finalArrayMatch = finalKey.match(/(.*)\[\]$/);
  if (finalArrayMatch) {
    const arrayKey = finalArrayMatch[1];
    if (!Array.isArray(current[arrayKey])) {
      current[arrayKey] = [{}];
    }
    current[arrayKey][0] = value;
  } else {
    current[finalKey] = value;
  }
};

export const unflattenObject = (flatObject: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const [key, value] of Object.entries(flatObject)) {
    setValueAtPath(result, key, value);
  }

  return result;
};
