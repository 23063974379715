import { ConnectionsRoutes, PartnerDTO } from 'support/types';
import { ListTable } from 'components/ListTable/ListTable';
import { PartnersResponseDTO } from 'services/repositories/partners/partners';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Form/Button/Button';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { PlusIcon, InboxIcon, LinkIcon, TagIcon } from '@heroicons/react/24/outline';
import { NewTradeRequest } from '../NewTradeRequest/NewTradeRequest';
import { useState } from 'react';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { InfiniteData } from '@tanstack/react-query';
import { ConnectionsFilterKey } from 'pages/Connections/tableConfig';
import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { routeToPage, routeToPricingConfigPage, routeToTransactionsPage } from 'support/helpers/navigation/navigation';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';
import { TradePartnerNameAndLogo } from 'components/TradePartnerNameAndLogo';

const BADGE_COLOR = {
  connected: BadgeColor.green,
  pending: BadgeColor.yellow,
  not_connected: BadgeColor.gray,
};
export function PartnerList({
  data,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  hasPreviousPage,
  clearAllFilters,
  hasFilters,
}: {
  data: InfiniteData<PartnersResponseDTO> | undefined;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasPreviousPage: boolean | undefined;
  clearAllFilters: () => void;
  hasFilters: boolean;
}) {
  const { enabled: priceCatalogsEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS);
  const { t } = useTranslation();
  const tableHeaders = [
    { label: t('partners:list.myPartners.headers.name'), key: 'name' },
    { label: t('partners:list.myPartners.headers.status'), key: 'status' },
    { label: t('partners:list.myPartners.headers.industry'), key: 'industry' },
    { label: '', key: 'actions' },
  ];

  const buildRow = (partner: PartnerDTO) => {
    return [
      {
        element: <TradePartnerNameAndLogo partner={partner} />,
        key: `name ${partner.id}`,
      },
      {
        element: (
          <Badge color={BADGE_COLOR[partner.partnerStatus]} type="indicator" className="shrink-0">
            {t('partners:list.myPartners.partnerStatus.' + partner.partnerStatus)}
          </Badge>
        ),
        key: `partner status ${partner.id}`,
      },
      {
        element: partner.industry ? t(`partners:search.industry.${partner.industry}`) : EMPTY_FIELD,
        key: `industry ${partner.id}`,
      },
      {
        element: <PartnerActions partner={partner} priceCatalogsEnabled={priceCatalogsEnabled} />,

        key: `actions ${partner.id}`,
      },
    ];
  };

  const listTableData = data?.pages.flatMap(({ items }) => items);
  return (
    <ListTable<PartnerDTO>
      headers={tableHeaders}
      data={listTableData}
      isLoading={isLoading}
      rowBuilder={buildRow}
      pagination={
        <InfiniteScroll
          hasMore={Boolean(hasNextPage)}
          loadMore={fetchNextPage}
          loading={isFetchingNextPage}
          noMoreItemsEnabled={Boolean(hasPreviousPage)}
        />
      }
      emptyState={
        <ListTable.EmptyState
          background="gray"
          cols={tableHeaders.length}
          cta={
            hasFilters
              ? {
                  children: t('common:table.withFilters.emptyState.cta'),
                  onClick: clearAllFilters,
                  analyticsId: 'partners:clear_filters',
                }
              : undefined
          }
        >
          {hasFilters ? t('common:table.withFilters.emptyState.title') : t('partners:list.myPartners.emptyState')}
        </ListTable.EmptyState>
      }
    />
  );
}

/*
  t('partners:list.myPartners.partnerStatus.connected')
  t('partners:list.myPartners.partnerStatus.pending')
  t('partners:list.myPartners.partnerStatus.not_connected')
*/

const PartnerActions = ({ partner, priceCatalogsEnabled }: { partner: PartnerDTO; priceCatalogsEnabled: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  return (
    <div className="flex w-full justify-end">
      {partner.partnerStatus === 'not_connected' ? (
        <Button
          size="small"
          LeftIcon={PlusIcon}
          onClick={() => setShowPopup(true)}
          analyticsId="partners:new_trade_request"
        >
          {t('partners:request.newTradeRequest.cta')}
        </Button>
      ) : (
        <MenuButton
          text={t('partners:actions.button')}
          variant="secondary"
          analyticsId="partners:trade_request_options"
        >
          <MenuButtonOption
            Icon={PlusIcon}
            onClick={() => setShowPopup(true)}
            analyticsId="partners:add_document_types"
          >
            {t('partners:actions.item.addDocumentTypes')}
          </MenuButtonOption>
          <MenuButtonOption
            newGroup
            Icon={InboxIcon}
            onClick={() => navigate(routeToTransactionsPage({ partner_id: partner.id }))}
            analyticsId="partners:transactions_navigate"
          >
            {t('partners:actions.item.transactions')}
          </MenuButtonOption>
          {!priceCatalogsEnabled && (
            <MenuButtonOption
              Icon={TagIcon}
              onClick={() => navigate(routeToPricingConfigPage({ partner_id: partner.id }))}
              analyticsId="partners:price_lists_navigate"
            >
              {t('partners:actions.item.priceLists')}
            </MenuButtonOption>
          )}
          <MenuButtonOption
            Icon={LinkIcon}
            onClick={() =>
              navigate(routeToPage(ConnectionsRoutes.root, {}, { [ConnectionsFilterKey.partnerName]: partner.name }))
            }
            analyticsId="partners:connections_navigate"
          >
            {t('partners:actions.item.connections')}
          </MenuButtonOption>
        </MenuButton>
      )}
      <NewTradeRequest
        showPopup={showPopup}
        onClose={() => setShowPopup(false)}
        partner={partner}
        connected={partner.partnerStatus !== 'not_connected'}
      />
    </div>
  );
};
