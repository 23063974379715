import { CellContext } from '@tanstack/react-table';
import { classNames } from 'support/helpers/generic/generic';
import { useWebEDIErrors, useWebEDIFieldError, WebEDIError } from 'services/webediErrors/webediErrors';
import { CellProps } from './types';
import { useCellClassNames } from './cellHelpers';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { useValue } from 'components/WebEDI/helpers';
import { cloneElement, useEffect } from 'react';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';

export type Props<TData, TValue> = CellContext<TData, TValue> &
  CellProps & {
    error?: Omit<WebEDIError, 'id'>;
  };

export const URLCell = <TData = unknown, TValue = string>({
  row,
  cell,
  column,
  table,
  isHighlighted,
  fieldId,
}: Props<TData, TValue>) => {
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();
  const value = useValue(row.original, fieldId, column.columnDef.meta?.optionalConfig?.expression);

  const {
    columnDef: { meta: columnMeta },
  } = column;

  const cellClassNames = useCellClassNames({
    row,
    table,
    isFirstColumn: column.getIsFirstColumn(),
    isLastColumn: column.getIsLastColumn(),
  });
  const [truncatedRef, valueIsTruncated] = useIsTruncated();

  useEffect(() => {
    if (error && showFieldErrorModal) {
      setCurrentError(error);
    }
  }, [error, setCurrentError, showFieldErrorModal]);

  return (
    <>
      <div
        data-field={fieldId}
        tabIndex={error ? 0 : undefined}
        onFocus={() => setShowFieldErrorModal(true)}
        onBlur={() => setShowFieldErrorModal(false)}
        className={classNames(
          'border w-full flex justify-between gap-2 items-center px-3 outline-none',
          cellClassNames,
          {
            'text-red-700 underline border-bg-red-50 bg-red-50 py-2.5 cursor-pointer hover:bg-red-100': error,
            'py-3': !error,
            'bg-amber-100': isHighlighted,
            'border-red-500': showFieldErrorModal,
            'border-transparent': !showFieldErrorModal,
          },
        )}
        ref={refs.setReference}
      >
        <Tooltip enabled={valueIsTruncated}>
          <TooltipTrigger>
            <span className="flex w-full items-center">
              <div
                className={classNames('flex w-full gap-x-0.5 overflow-hidden')}
                style={{ width: columnMeta?.optionalConfig?.width }}
              >
                <span ref={truncatedRef} className="truncate">
                  <a href={value} target="_blank" rel="noreferrer" className="underline">
                    {value}
                  </a>
                </span>
              </div>
              {columnMeta?.rightAddon && !error ? (
                <span className="text-gray-500">{cloneElement(columnMeta.rightAddon, { cell })}</span>
              ) : null}
            </span>
          </TooltipTrigger>
          <TooltipContent>{value}</TooltipContent>
        </Tooltip>
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={false}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
        />
      )}
    </>
  );
};
