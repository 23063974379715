import { ViewCell } from './cells/ViewCell';
import { InputCell } from './cells/InputCell';
import { EnumerableCell } from './cells/EnumerableCell';
import { ui } from '@procuros/datachecks';
import { URLCell } from './cells/URLCell';
import { BooleanCell } from './cells/BooleanCell';

/**
 * Map object which connects available column types to actual column cells.
 * Each connection has two components for view and edit modes.
 */
export const cellMapping: Record<keyof typeof ui.FieldTypes, [typeof ViewCell, typeof InputCell] | []> = {
  [ui.FieldTypes.text]: [ViewCell, InputCell],
  [ui.FieldTypes.url]: [URLCell, InputCell],
  [ui.FieldTypes.boolean]: [ViewCell, BooleanCell],
  [ui.FieldTypes.number]: [ViewCell, InputCell],
  [ui.FieldTypes.monetary]: [ViewCell, InputCell],
  [ui.FieldTypes.date]: [ViewCell, InputCell],
  [ui.FieldTypes.date_time]: [ViewCell, InputCell],
  [ui.FieldTypes.select]: [ViewCell, EnumerableCell],
  [ui.FieldTypes.multi_select]: [ViewCell, EnumerableCell],
  [ui.FieldTypes.multi_column]: [],
};
