import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { DisplayModes } from 'components/DataTable/Types';
import { AlertSection } from 'components/Display/Alert';
import { Alert } from 'components/Display/Alert/Alert';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebEDIErrors } from 'services/webediErrors/webediErrors';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { DATA_CHECK_ERROR_CODE } from 'support/helpers/const/const';
import { IssueResolverType, MessageV2DTO } from 'support/types';

export const WebEDIMessageErrorBanner = ({
  mode,
  blockingError,
  issueResolver,
}: {
  mode: DisplayModes;
  blockingError: MessageV2DTO['blocking_error'];
  issueResolver?: IssueResolverType;
  messageId?: string;
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const {
    numberOfErrors,
    moveToNextWebEDIError,
    moveToPreviousWebEDIError,
    focusOnCurrentError,
    nextError,
    previousError,
  } = useWebEDIErrors();

  const moveToNextError = () => {
    track('webedi:next_error', { nextError });
    moveToNextWebEDIError();
  };

  const moveToPreviousError = () => {
    track('webedi:previous_error', { previousError });
    moveToPreviousWebEDIError();
  };

  const bannerSeverity = useMemo(() => {
    if (issueResolver === IssueResolverType.CURRENT_PARTNER) {
      return 'error';
    }
    return 'neutral';
  }, [issueResolver]);

  const renderBannerSections = useMemo(() => {
    if (!blockingError) return null;
    if (issueResolver === IssueResolverType.PROCUROS) {
      return (
        <>
          <AlertSection severity={bannerSeverity} title={blockingError.title}>
            {blockingError.description} {blockingError.howToFix}
          </AlertSection>
        </>
      );
    }

    if (issueResolver === IssueResolverType.OTHER_PARTNER) {
      return (
        <>
          <AlertSection severity={bannerSeverity} title={t('webedi:viewDocument.noActionRequiredBanner.title')}>
            {t('webedi:viewDocument.noActionRequiredBanner.description')}
          </AlertSection>
        </>
      );
    }

    return (
      <>
        <AlertSection severity={bannerSeverity} title={blockingError?.title}>
          {blockingError?.description}
        </AlertSection>
        <AlertSection severity={bannerSeverity} title={t('common:blockingError.howToFix')}>
          {blockingError?.howToFix}
        </AlertSection>
      </>
    );
  }, [bannerSeverity, blockingError, issueResolver, t]);

  // datachecks failed but other partner action required
  if (
    blockingError &&
    blockingError.code === DATA_CHECK_ERROR_CODE &&
    issueResolver === IssueResolverType.OTHER_PARTNER
  ) {
    return (
      <Alert withoutIcon severity={bannerSeverity} fullWidth>
        {renderBannerSections}
      </Alert>
    );
  }

  // action maybe required but not from data checks
  if (blockingError && blockingError.code !== DATA_CHECK_ERROR_CODE) {
    return (
      <Alert withoutIcon severity={bannerSeverity} fullWidth>
        {renderBannerSections}
      </Alert>
    );
  }

  if (numberOfErrors === 0) return null;

  // datachecks failed and action required.
  return (
    <Alert withoutIcon severity="error" fullWidth>
      <div className="flex w-full items-center justify-between">
        <div>
          <p className="font-bold text-red-700">{blockingError?.description}</p>
          <p>
            {mode === DisplayModes.view
              ? t('webedi:viewDocument.errorBanner.fix')
              : t('webedi:editDocument.errorBanner.fix')}
          </p>
        </div>
        <div className="flex gap-1">
          <Badge type="indicator" color={BadgeColor.red}>
            {mode === DisplayModes.view
              ? t('webedi:viewDocument.errorBanner.badge', { count: numberOfErrors, numberOfErrors })
              : t('webedi:editDocument.errorBanner.badge', { count: numberOfErrors, numberOfErrors })}
          </Badge>
          {numberOfErrors > 1 ? (
            <>
              <Button
                variant="secondary-danger"
                size="extra-small"
                onClick={moveToPreviousError}
                iconOnly
                LeftIcon={ChevronUpIcon}
                analyticsId="webedi:previous_error"
              />
              <Button
                variant="secondary-danger"
                size="extra-small"
                onClick={moveToNextError}
                iconOnly
                LeftIcon={ChevronDownIcon}
                analyticsId="webedi:next_error"
              />
            </>
          ) : (
            <>
              <Button
                variant="secondary-danger"
                size="extra-small"
                onClick={focusOnCurrentError}
                analyticsId="webedi:errors_focus_first"
              >
                {t('webedi:viewDocument.errorBanner.focusOnError')}
              </Button>
            </>
          )}
        </div>
      </div>
    </Alert>
  );
};
