import { Page } from 'components/Page/Page';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useCreateProcessSchema,
  useCreateProcessTransformation,
  useIntegrationProcess,
  usePushProcessConfiguration,
  useUpdateProcessSchema,
  useUpdateProcessTransformation,
} from 'services/repositories/integrationProcesses/integrationProcesses';
import { useIntegration } from 'services/repositories/integrations/integrations';
import { useTemplates } from 'services/repositories/templates/templates';
import { useExpandedMessages, useSenderEnvelopes } from 'services/repositories/transactions/transactions';
import {
  LocalSchemaDTO,
  LocalTransformationDTO,
  PayloadObject,
  useProcessTransformationStore,
} from 'stores/transformations/processTransformation';
import { TransformationDTO } from 'support/types';
import { useDebounce } from 'hooks/useDebounce';
import { TransformationExpressionEditor } from './TransformationExpressionEditor';
import { TransformationInputEditor } from './TransformationInputEditor';
import { TransformationOutputEditor } from './TransformationOutputEditor';

import { ArrowRightEndOnRectangleIcon, ArrowRightStartOnRectangleIcon } from '@heroicons/react/16/solid';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { DeployModal } from './DeployModal';

import { useInfiniteRelationships } from 'services/repositories/relationships/relationships';
import { classNames, parseJson } from 'support/helpers/generic/generic';
import { getDataType, processPayloadObject } from './helpers';

import { t } from 'i18next';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useCurrentUser, useUpdateUser } from 'services/repositories/user/user';
import { NotificationType, addNotification } from 'stores/notifications/notifications';

export type UiMode = 'BUILD' | 'TEST';

export const ProcessTransformation = () => {
  const {
    existingInputSchemas,
    existingOutputSchemas,
    existingTransformations,
    selectedInputSchema,
    selectedOutputSchema,
    inputSchemaTemplates,
    outputSchemaTemplates,
    transformationTemplates,
    selectedTransformation,
    activeInputSchema,
    activeOutputSchema,
    activeTransformation,
    addInputSchema,
    addOutputSchema,
    addTransformation,
    updateInputSchema,
    updateOutputSchema,
    updateTransformation,
    selectInputSchema,
    selectOutputSchema,
    selectTransformation,
    existingPayloads,
    selectedPayload,
    selectPayload,
    normalizerConfig,
    existingRelationships,
    selectedRelationship,
    selectRelationship,
    mountRelationships,
    testScenarios,
    selectedTestScenario,
    selectTestScenario,
    addPayload,
    updatePayload,
    mountProcess,
    mountTemplates,
    mountSenderEnvelopes,
    mountMessages,
    replacePayloads,
    needsRetesting,
  } = useProcessTransformationStore();
  const { data: currentPartner } = useCurrentPartner();
  const { data: currentUser } = useCurrentUser();
  const { mutateAsync: updateCurrentUser, isLoading: isMutatingCurrentUser } = useUpdateUser();
  const [searchParams] = useSearchParams();
  const [specificEntityId, setSpecificEntityId] = useState<string | null>(searchParams.get('entity_id'));
  const debouncedSpecificEntityId = useDebounce<string | null>(specificEntityId, 500);
  const { id: integrationId, processId } = useParams<{ processId: string; id: string }>();

  const changePartner = useCallback(
    (partnerId: string | null) => async () => {
      if (!currentUser || !partnerId || isMutatingCurrentUser) {
        return;
      }
      console.log('updating user');
      await updateCurrentUser(
        {
          name: currentUser.name,
          email: currentUser.email,
          language: currentUser.language,
          notifications: currentUser.notifications,
          partner_id: partnerId,
        },
        {
          onSuccess: () => {
            addNotification(t('auth:notifications.updateUser.success'));
          },
          onError: () => {
            addNotification(t('auth:notifications.updateUser.error'), NotificationType.error);
          },
        },
      );
    },
    [currentUser, updateCurrentUser, isMutatingCurrentUser],
  );

  const { data: integration, isInitialLoading: isLoadingIntegration } = useIntegration({
    variables: { id: integrationId },
  });
  const { data: templates, isInitialLoading: isLoadingTemplates } = useTemplates({
    variables: {
      query: {
        perPage: '100',
      },
    },
  });
  const { mutateAsync: createTransformationMutation } = useCreateProcessTransformation();
  const { mutateAsync: updateTransformationMutation } = useUpdateProcessTransformation();
  const { mutateAsync: createSchemaMutation } = useCreateProcessSchema();
  const { mutateAsync: updateSchemaMutation } = useUpdateProcessSchema();
  const { mutateAsync: pushProcessConfigurationMutation, isLoading: configurationPushing } =
    usePushProcessConfiguration();
  const { data: process, isInitialLoading: isLoadingProcess } = useIntegrationProcess({
    variables: { integrationId, processId },
  });
  const { data: relationships, isInitialLoading: isLoadingRelationships } = useInfiniteRelationships({
    variables: {
      query: {
        filterValues: [
          {
            key: process?.type === 'SOURCE' ? 'sender_integration' : 'receiver_integration',
            value: integration?.id ?? '',
          },
          { key: 'message_type', value: process?.messageType || '' },
        ],
      },
    },
    enabled: Boolean(process) && Boolean(integration),
  });
  const { data: senderEnvelopes, isInitialLoading: isLoadingSenderEnvelopes } = useSenderEnvelopes({
    variables: {
      query: {
        filterValues: [
          // We could also use integration + messageType here to filter SEs
          // but if the process is not assigned to the SE, the message type is also
          // not assigned in most cases
          { key: 'process', value: process?.id || '' },
          { key: 'flow', value: 'live,test' },
          ...(selectedRelationship ? [{ key: 'relationship', value: selectedRelationship.id }] : []),
          ...(debouncedSpecificEntityId ? [{ key: 'id', value: debouncedSpecificEntityId }] : []),
        ],
        perPage: selectedRelationship ? '10' : '100',
      },
    },
    enabled: process?.type === 'SOURCE',
  });
  const { data: messages, isInitialLoading: isLoadingMessages } = useExpandedMessages({
    variables: {
      query: {
        filterValues: [
          // We are using receiver_integration + message_type here to filter messages
          // because the process is only assigned to the RE (not the message) which does not
          // exist if message decanonicalization failed
          // and we do not allow two processes with equal direction (source/destination) + messageType
          // on the same integration so the filter is safe
          { key: 'receiver_integration', value: integrationId || '' },
          { key: 'message_type', value: process?.messageType || '' },
          { key: 'flow', value: 'live,test' },
          ...(selectedRelationship ? [{ key: 'relationship', value: selectedRelationship.id }] : []),
          ...(debouncedSpecificEntityId ? [{ key: 'id', value: debouncedSpecificEntityId }] : []),
        ],
        perPage: selectedRelationship ? '10' : '100',
      },
    },
    enabled: process?.type === 'DESTINATION',
  });

  const [uiMode, setUiMode] = useState<UiMode>('BUILD');

  const debouncedSelectedTransformation = useDebounce<LocalTransformationDTO | undefined>(selectedTransformation, 300);
  const debouncedSelectedPayload = useDebounce<PayloadObject | undefined>(selectedPayload, 300);
  const debouncedSelectedInputSchema = useDebounce<LocalSchemaDTO | undefined>(selectedInputSchema, 300);
  const debouncedSelectedOutputSchema = useDebounce<LocalSchemaDTO | undefined>(selectedOutputSchema, 300);

  const persistableSelectedTransformation = useDebounce<LocalTransformationDTO | undefined>(
    selectedTransformation,
    1000,
  );
  const persistableSelectedInputSchema = useDebounce<LocalSchemaDTO | undefined>(selectedInputSchema, 1000);
  const persistableSelectedOutputSchema = useDebounce<LocalSchemaDTO | undefined>(selectedOutputSchema, 1000);

  const [testsExecuting, setTestsExecuting] = useState<boolean>(false);
  const [inputVisible, setInputVisible] = useState<boolean>(true);
  const [transformationVisible, setTransformationVisible] = useState<boolean>(true);
  const [outputVisible, setOutputVisible] = useState<boolean>(true);
  const [deployModalOpen, setDeployModalOpen] = useState<boolean>(false);

  const inputDataType =
    !integration || !process || process.type === 'DESTINATION' ? 'CANONICAL' : getDataType(integration, process);
  const outputDataType =
    !integration || !process || process.type === 'SOURCE' ? 'CANONICAL' : getDataType(integration, process);

  useEffect(() => {
    const shouldImmediatelyImpersonate = searchParams.get('impersonate') === 'true';
    if (
      shouldImmediatelyImpersonate &&
      currentUser &&
      integration?.partner &&
      currentPartner &&
      currentPartner.id !== integration.partner.id
    ) {
      changePartner(integration.partner.id)();
    }
  }, [searchParams, currentPartner, changePartner, currentUser, integration?.partner]);

  const deploy = useCallback(async () => {
    if (!integrationId || !processId || !selectedInputSchema || !selectedOutputSchema || !selectedTransformation) {
      return;
    }
    console.log('deploying');
    await pushProcessConfigurationMutation({
      integrationId,
      processId,
      data: {
        input_schema_id: selectedInputSchema?.id,
        output_schema_id: selectedOutputSchema?.id,
        transformation_id: selectedTransformation?.id,
      },
    });

    setDeployModalOpen(false);
  }, [
    selectedInputSchema,
    selectedOutputSchema,
    selectedTransformation,
    pushProcessConfigurationMutation,
    integrationId,
    processId,
  ]);

  const impersonateButton = useMemo(() => {
    if (!integration?.partner || !currentPartner || !currentUser) {
      return null;
    }

    const isImpersonated = integration.partner.id === currentPartner.id;

    return (
      <Button
        variant="secondary"
        size="small"
        className="mr-2"
        disabled={isImpersonated}
        loading={isMutatingCurrentUser}
        onClick={changePartner(isImpersonated ? null : integration.partner.id)}
        analyticsId="transformation-ui:impersonate"
      >
        {isImpersonated ? `Impersonating ${currentPartner.name}` : `Impersonate ${integration.partner.name}`}
      </Button>
    );
  }, [integration, currentPartner, currentUser, changePartner, isMutatingCurrentUser]);

  const executeTests = () => {
    setTestsExecuting(true);
    setTimeout(() => {
      Promise.all(
        existingPayloads.map((payload) =>
          processPayloadObject({
            payload,
            integrationId,
            processId,
            inputSchema: debouncedSelectedInputSchema,
            outputSchema: debouncedSelectedOutputSchema,
            selectedTransformation: debouncedSelectedTransformation,
            activeTransformation,
            normalizerConfig,
            onBackend: true,
          }),
        ),
      ).then((processedPayloads) => {
        replacePayloads(processedPayloads);
        setTestsExecuting(false);
      });
    });
  };

  useEffect(() => {
    if (templates && process && inputDataType && outputDataType) {
      mountTemplates({
        processType: process.type,
        messageType: process.messageType,
        inputDataType,
        outputDataType,
        templates,
      });
      mountProcess(process);
      mountRelationships(relationships?.pages.flatMap((page) => page.data) ?? []);
    }
  }, [
    templates,
    process,
    relationships,
    mountProcess,
    mountTemplates,
    mountRelationships,
    inputDataType,
    outputDataType,
  ]);

  useEffect(() => {
    if (process && integration && process.type === 'SOURCE') {
      mountSenderEnvelopes({
        sourceIntegration: integration,
        sourceProcess: process,
        senderEnvelopes: senderEnvelopes?.data ?? [],
        autoSelectFirstPayload: !!debouncedSpecificEntityId,
      });
    }
  }, [senderEnvelopes, integration, process, mountSenderEnvelopes, debouncedSpecificEntityId]);

  useEffect(() => {
    if (process && integration && process.type === 'DESTINATION') {
      mountMessages({
        destinationIntegration: integration,
        destinationProcess: process,
        messages: messages?.data ?? [],
        autoSelectFirstPayload: !!debouncedSpecificEntityId,
      });
    }
  }, [messages, integration, process, mountMessages, debouncedSpecificEntityId]);

  useEffect(() => {
    if (relationships && process) {
      mountRelationships(relationships.pages.flatMap((page) => page.data));
    }
  }, [relationships, process, mountRelationships]);

  useEffect(() => {
    if (
      !persistableSelectedTransformation ||
      !persistableSelectedTransformation.is_draft ||
      !persistableSelectedTransformation.is_dirty
    ) {
      console.log('skip updating transformation');
      return;
    }
    updateTransformation(
      persistableSelectedTransformation.id,
      {
        ...persistableSelectedTransformation,
        is_dirty: false,
      },
      false,
    );
    updateTransformationMutation({
      integrationId,
      processId,
      transformationId: persistableSelectedTransformation.id,
      data: {
        name: persistableSelectedTransformation.name,
        expression: persistableSelectedTransformation.expression || '',
      },
    });
  }, [persistableSelectedTransformation, processId, integrationId, updateTransformationMutation, updateTransformation]);

  useEffect(() => {
    if (
      !persistableSelectedInputSchema ||
      !persistableSelectedInputSchema.is_draft ||
      !persistableSelectedInputSchema.is_dirty
    ) {
      console.log('skip updating schema');
      return;
    }
    updateInputSchema(
      persistableSelectedInputSchema.id,
      {
        ...persistableSelectedInputSchema,
        is_dirty: false,
      },
      false,
    );
    const persistableSelectedInputSchemaJson = parseJson(persistableSelectedInputSchema.schema);
    if (!persistableSelectedInputSchemaJson.success) {
      console.error('Invalid JSON schema');
      return;
    }
    updateSchemaMutation({
      integrationId,
      processId,
      schemaId: persistableSelectedInputSchema.id,
      data: {
        name: persistableSelectedInputSchema.name,
        schema: persistableSelectedInputSchemaJson.result,
        type: persistableSelectedInputSchema.type as any,
      },
    });
  }, [persistableSelectedInputSchema, processId, integrationId, updateSchemaMutation, updateInputSchema]);

  useEffect(() => {
    if (
      !persistableSelectedOutputSchema ||
      !persistableSelectedOutputSchema.is_draft ||
      !persistableSelectedOutputSchema.is_dirty
    ) {
      console.log('skip updating schema');
      return;
    }
    updateOutputSchema(
      persistableSelectedOutputSchema.id,
      {
        ...persistableSelectedOutputSchema,
        is_dirty: false,
      },
      false,
    );
    const persistableSelectedOutputSchemaJson = parseJson(persistableSelectedOutputSchema.schema);
    if (!persistableSelectedOutputSchemaJson.success) {
      console.error('Invalid JSON schema');
      return;
    }
    updateSchemaMutation({
      integrationId,
      processId,
      schemaId: persistableSelectedOutputSchema.id,
      data: {
        name: persistableSelectedOutputSchema.name,
        schema: persistableSelectedOutputSchemaJson.result,
        type: persistableSelectedOutputSchema.type as any,
      },
    });
  }, [persistableSelectedOutputSchema, processId, integrationId, updateSchemaMutation, updateOutputSchema]);

  const createTransformation = useCallback(
    async (transformation: TransformationDTO) => {
      // Optimistically adding the transformation to the store
      addTransformation({
        ...transformation,
        is_dirty: false,
      });

      const persistedTransformation = await createTransformationMutation({
        integrationId,
        processId,
        data: {
          name: transformation.name,
          expression: transformation.expression || '',
        },
      });

      // Update the transformation with the persisted one
      // mainly setting the correct id
      updateTransformation(transformation.id, persistedTransformation, false);
      selectTransformation(persistedTransformation.id);
    },
    [
      createTransformationMutation,
      updateTransformation,
      addTransformation,
      selectTransformation,
      integrationId,
      processId,
    ],
  );

  const createInputSchema = useCallback(
    async (schema: LocalSchemaDTO) => {
      // Optimistically adding the schema to the store
      addInputSchema({
        ...schema,
        is_dirty: false,
      });

      const schemaJson = parseJson(schema.schema);
      if (!schemaJson.success) {
        console.error('Invalid JSON schema');
        return;
      }

      const persistedSchema = await createSchemaMutation({
        integrationId,
        processId,
        data: {
          name: schema.name,
          schema: schemaJson.result,
          type: schema.type as any,
        },
      });

      // Update the schema with the persisted one
      // mainly setting the correct id
      updateInputSchema(schema.id, persistedSchema, false);
      selectInputSchema(persistedSchema.id);
    },
    [createSchemaMutation, updateInputSchema, addInputSchema, selectInputSchema, integrationId, processId],
  );

  const createOutputSchema = useCallback(
    async (schema: LocalSchemaDTO) => {
      // Optimistically adding the schema to the store
      addOutputSchema({
        ...schema,
        is_dirty: false,
      });

      const schemaJson = parseJson(schema.schema);
      if (!schemaJson.success) {
        console.error('Invalid JSON schema');
        return;
      }
      const persistedSchema = await createSchemaMutation({
        integrationId,
        processId,
        data: {
          name: schema.name,
          schema: schemaJson.result,
          type: schema.type as any,
        },
      });

      // Update the schema with the persisted one
      // mainly setting the correct id
      updateOutputSchema(schema.id, persistedSchema, false);
      selectOutputSchema(persistedSchema.id);
    },
    [createSchemaMutation, updateOutputSchema, addOutputSchema, selectOutputSchema, integrationId, processId],
  );

  useEffect(() => {
    let isSubscribed = true;

    // Only executing onChange if in BUILD mode (otherwise payloads are batch-processed)
    if (!debouncedSelectedPayload || uiMode === 'TEST') {
      return;
    }
    console.log('running reprocessing');
    processPayloadObject({
      payload: debouncedSelectedPayload,
      inputSchema: debouncedSelectedInputSchema,
      outputSchema: debouncedSelectedOutputSchema,
      selectedTransformation: debouncedSelectedTransformation,
      activeTransformation: activeTransformation,
      normalizerConfig,
      onBackend: false,
    }).then((processedPayload) => {
      if (!isSubscribed || processedPayload === debouncedSelectedPayload) {
        return;
      }

      console.log('updating');
      updatePayload(processedPayload.id, processedPayload);
    });

    return () => {
      isSubscribed = false;
    };
  }, [
    uiMode,
    debouncedSelectedPayload,
    debouncedSelectedTransformation,
    debouncedSelectedInputSchema,
    debouncedSelectedOutputSchema,
    activeTransformation,
    normalizerConfig,
    updatePayload,
  ]);

  if (!integrationId || !processId) return null;

  const gridClass = () => {
    switch ([inputVisible, transformationVisible, outputVisible].filter((visible) => visible).length) {
      case 1:
        return 'w-full';
      case 2:
        return 'w-1/2';
      case 3:
      default:
        return 'w-1/3';
    }
  };

  return (
    <Page
      isInternal
      isScrollable={false}
      isLoading={
        isLoadingProcess ||
        isLoadingIntegration ||
        isLoadingTemplates ||
        isLoadingSenderEnvelopes ||
        isLoadingMessages ||
        isLoadingRelationships
      }
    >
      <div className="flex h-full flex-col divide-y">
        <div className="flex justify-between px-3 py-4">
          <div className="flex items-center">
            <div className="mr-3">
              {integration?.partner.name}: {integration?.name}
            </div>
            <Badge className="mr-1" color={BadgeColor.gray}>
              {process?.messageType}
            </Badge>
            <Badge
              icon={process?.type === 'SOURCE' ? <ArrowRightStartOnRectangleIcon /> : <ArrowRightEndOnRectangleIcon />}
              color={BadgeColor.gray}
            >
              {process?.type}
            </Badge>
          </div>

          <div className="flex">
            <div>{impersonateButton}</div>
            <div>
              <Button
                variant="secondary"
                size="small"
                grouped
                pressed={inputVisible}
                onClick={() => setInputVisible(!inputVisible)}
                analyticsId="transformation-ui:toggle_input"
              >
                Input
              </Button>
              <Button
                variant="secondary"
                size="small"
                grouped
                pressed={transformationVisible}
                onClick={() => setTransformationVisible(!transformationVisible)}
                analyticsId="transformation-ui:toggle_transformation"
              >
                Transformation
              </Button>
              <Button
                variant="secondary"
                size="small"
                grouped
                pressed={outputVisible}
                onClick={() => setOutputVisible(!outputVisible)}
                analyticsId="transformation-ui:toggle_output"
              >
                Output
              </Button>
            </div>
          </div>
        </div>
        <div className="flex min-h-0 w-full flex-1 divide-x">
          <TransformationInputEditor
            className={classNames(gridClass(), inputVisible ? undefined : 'hidden')}
            processType={process?.type}
            uiMode={uiMode}
            inputDataType={inputDataType}
            outputDataType={outputDataType}
            selectedPayload={selectedPayload}
            onPayloadChange={updatePayload}
            onPayloadSelect={selectPayload}
            onPayloadCreate={addPayload}
            normalizerConfig={normalizerConfig}
            existingSchemas={existingInputSchemas}
            schemaTemplates={inputSchemaTemplates}
            existingPayloads={existingPayloads}
            activeSchema={activeInputSchema}
            selectedSchema={selectedInputSchema}
            onSchemaSelect={selectInputSchema}
            onSchemaChange={updateInputSchema}
            onSchemaCreate={createInputSchema}
          />
          <TransformationExpressionEditor
            className={classNames(gridClass(), transformationVisible ? undefined : 'hidden')}
            uiMode={uiMode}
            processType={process?.type}
            setUiMode={setUiMode}
            needsRetesting={needsRetesting}
            existingRelationships={existingRelationships}
            selectedRelationship={selectedRelationship}
            onRelationshipSelect={selectRelationship}
            existingPayloads={existingPayloads}
            selectedPayload={selectedPayload}
            onPayloadSelect={selectPayload}
            existingTransformations={existingTransformations}
            transformationTemplates={transformationTemplates}
            selectedTransformation={selectedTransformation}
            activeTransformation={activeTransformation}
            onTransformationSelect={selectTransformation}
            onTransformationChange={updateTransformation}
            onTransformationCreate={createTransformation}
            selectedTestScenario={selectedTestScenario}
            testScenarios={testScenarios}
            specificEntityId={specificEntityId}
            onSpecificEntityIdChange={setSpecificEntityId}
            onTestScenarioSelect={selectTestScenario}
            testsExecuting={testsExecuting}
            onTestsExecute={executeTests}
            onOpenDeployModal={() => setDeployModalOpen(true)}
          />
          <TransformationOutputEditor
            className={classNames(gridClass(), outputVisible ? undefined : 'hidden')}
            processType={process?.type}
            uiMode={uiMode}
            outputDataType={outputDataType}
            selectedPayload={selectedPayload}
            existingSchemas={existingOutputSchemas}
            schemaTemplates={outputSchemaTemplates}
            activeSchema={activeOutputSchema}
            selectedSchema={selectedOutputSchema}
            onSchemaSelect={selectOutputSchema}
            onSchemaChange={updateOutputSchema}
            onSchemaCreate={createOutputSchema}
          />
        </div>
        <DeployModal
          open={deployModalOpen}
          onClose={() => setDeployModalOpen(false)}
          selectedTransformation={selectedTransformation}
          activeTransformation={activeTransformation}
          selectedInputSchema={selectedInputSchema}
          activeInputSchema={activeInputSchema}
          selectedOutputSchema={selectedOutputSchema}
          activeOutputSchema={activeOutputSchema}
          onDeploy={deploy}
          deploying={configurationPushing}
        />
      </div>
    </Page>
  );
};
