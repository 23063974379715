import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import useTimeout from 'use-timeout';

import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { Dialog } from 'components/Dialog/Dialog';

type NotificationProps = {
  id: string;
  headline: string;
  description?: string;
  type?: NotificationType;
  index?: number;
};

const AUTO_DISMISS_TIMEOUT = 10000;

export function Notification({ id, headline, description, type, index = 0 }: NotificationProps) {
  const { t } = useTranslation();
  const { removeNotification } = useNotificationStore();

  const onDismiss = () => {
    removeNotification(id);
  };

  useTimeout(onDismiss, AUTO_DISMISS_TIMEOUT);

  const topOffset = index * 70;

  return (
    <Dialog as="div" className="relative z-50" onClose={onDismiss} open>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
        style={{ top: `${topOffset}px` }}
        data-testid="notification"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <div className="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="w-0 flex-1 p-4">
              <div className="flex items-start">
                {type && (
                  <div className="flex-shrink-0">
                    {type === NotificationType.success ? (
                      <CheckCircleIcon className="h-6 w-6 text-procuros-green-500" aria-hidden="true" />
                    ) : (
                      <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    )}
                  </div>
                )}

                <div className="ml-3 w-0 flex-1">
                  <p className="text-sm font-medium text-gray-900">{headline}</p>
                  <p className="mt-1 text-sm text-gray-500">{description}</p>
                </div>
              </div>
            </div>

            <div className="ml-4 flex items-center pr-5 flex-shrink">
              <button
                type="button"
                className="flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-procuros-green-400 focus-visible:ring-offset-2"
                onClick={onDismiss}
              >
                <span className="sr-only">{t('close')}</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
