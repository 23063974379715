import { CellContext, ColumnMeta, RowData } from '@tanstack/react-table';
import { FocusEventHandler, KeyboardEventHandler, useCallback, useEffect, useMemo } from 'react';
import { classNames } from 'support/helpers/generic/generic';
import { useCellClassNames, useCellNestedLevel, useOptionalConfigurations, useProcurosAddedValue } from './cellHelpers';
import { MessageFieldErrorModal, useMessageFieldErrorModal } from 'components/WebEDI/MessageFieldErrorModal';
import { Input } from 'components/Form/Input/Input/Input';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { CellProps } from './types';
import { RowStatus } from './components/RowStatus/RowStatus';
import { ui } from '@procuros/datachecks';
import { NestedLevelAddon } from './components/NestedLevelAddon/NestedLevelAddon';
import { useValue } from 'components/WebEDI/helpers';
import { DisplayModes } from '../Types';
import { useDataTableContext } from '../contexts/DataTableContext';
import { useTranslation } from 'react-i18next';
import { TimestampDateInput } from 'components/Form/TimestampDateInput/TimestampDateInput';
import { useDatachecksStore } from 'stores/datachecks/datachecks';

export type Props<TData, TValue> = CellContext<TData, TValue> & CellProps;

const typeToInputType = (type: ColumnMeta<RowData, unknown>['type'] | undefined) => {
  switch (type) {
    case ui.FieldTypes.number:
    case ui.FieldTypes.monetary:
      return 'number';
    case ui.FieldTypes.date:
      return 'date';
    default:
      return 'text';
  }
};

export const InputCell = <TData, TValue>({
  fieldId,
  column,
  row,
  table,
  isHighlighted,
  mode,
}: Props<TData, TValue>) => {
  const { t } = useTranslation();
  const isValidating = useDatachecksStore((state) => state.isValidating);
  const {
    columnDef: { meta: columnMeta, ...columnDef },
    getIsFirstColumn,
    getIsLastColumn,
  } = column;

  const inputValue = useValue(row.original, fieldId, columnMeta?.optionalConfig?.expression);
  const { prefix, suffix, isNumeric, formattedValue } = useOptionalConfigurations({
    meta: columnMeta,
    value: inputValue,
    mode: DisplayModes.create,
    rowData: row.original,
    fieldId,
  });

  const type = useMemo(() => {
    return typeToInputType(columnMeta?.type);
  }, [columnMeta?.type]);

  const { register } = useDataTableContext();
  const { onBlur, ...otherRegister } = register(fieldId, { valueAsNumber: isNumeric });

  const { showFieldErrorModal, setShowFieldErrorModal, floatingStyles, refs } = useMessageFieldErrorModal();

  const isAdded = useProcurosAddedValue(row);
  // Allow users to set values on all fields if customer created the row or is edit mode
  const readOnly = useMemo(() => {
    // if the row is added by the user, the field is always editable
    if (isAdded) return false;
    // do not edit the fields if theres a formatted value different from the input value
    // formattedValue is used for display only
    if (mode === DisplayModes.edit) return formattedValue !== inputValue;

    const isCellReadOnly = Boolean(columnMeta?.rowConfig?.[row.index]?.is_readonly);
    return Boolean(columnMeta?.readOnly || isCellReadOnly);
  }, [mode, formattedValue, inputValue, columnMeta, row.index, isAdded]);

  const { error } = useWebEDIFieldError(fieldId);

  const { numberOfErrors, setCurrentError } = useWebEDIErrors();
  const cellClassNames = useCellClassNames({
    isLastColumn: getIsLastColumn(),
    isFirstColumn: getIsFirstColumn(),
    row,
    table,
  });

  const inputClassNames = classNames(
    error
      ? 'group-hover:bg-red-100 bg-red-50 focus:!bg-transparent disabled:!bg-red-50'
      : 'group-hover:bg-procuros-green-50 focus:!bg-transparent disabled:!bg-transparent',
    cellClassNames,
    isNumeric ? 'text-end' : 'text-start',
    columnMeta?.optionalConfig?.isStatusColumn ? '!pl-10' : undefined,
  );

  useEffect(() => {
    if (showFieldErrorModal && error) {
      setCurrentError(error);
    }
  }, [showFieldErrorModal, error, setCurrentError]);

  const onBlurHandler: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onBlur(e);
      setShowFieldErrorModal(false);
    },
    [onBlur, setShowFieldErrorModal],
  );

  const onFocusHandler = useCallback(() => {
    setShowFieldErrorModal(true);
  }, [setShowFieldErrorModal]);

  const cellNestedLevel = useCellNestedLevel(row);
  const InputComponent = type === 'date' ? TimestampDateInput : Input;

  //We need the readOnly for the formatted value to be shown when rendering the type of the Parties
  //On number inputs we can't render formatted values because the input type is number and the formatted value will contain a currency symbol
  const valueToShow = type === 'date' || (readOnly && !isNumeric) ? formattedValue : inputValue;
  return (
    <>
      <div
        className={classNames(
          'group flex justify-between gap-2 items-center w-full',
          {
            'bg-red-50 focus-within:!bg-transparent focus-within:ring-red-500 focus-within:shadow-red-500 hover:bg-red-100':
              error,
            'hover:bg-procuros-green-50 focus-within:!bg-transparent focus-within:ring-procuros-green-500 focus-within:shadow-procuros-green-500':
              !error,
            'bg-amber-100': isHighlighted,
          },
          cellClassNames,
        )}
        ref={refs.setReference}
      >
        <InputComponent
          wrapperClassName="w-full"
          className={classNames(
            'bg-transparent w-full !border-none p-3 !outline-none text-xs !shadow-none rounded-none min-w-[120px]',
            inputClassNames,
          )}
          placeholder={
            !formattedValue && error?.type === 'required' ? t('common:table.errors.requiredField') : undefined
          }
          title={formattedValue ? String(formattedValue) : undefined}
          value={valueToShow ?? ''}
          readOnly={readOnly}
          type={type}
          {...otherRegister}
          hasError={Boolean(error)}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          leftIcon={
            columnMeta?.optionalConfig?.isStatusColumn ? (
              <RowStatus
                statusColumn={columnMeta?.optionalConfig?.quantityField}
                currentPath={fieldId}
                isProcurosAdded={isAdded}
                currentColumn={columnDef && 'accessorKey' in columnDef ? (columnDef.accessorKey as string) : ''}
                tooltips={{
                  deleted: t('webedi:inputCell.status.deleted'),
                  partial: t('webedi:inputCell.status.partial'),
                  complete: t('webedi:inputCell.status.completed'),
                }}
              />
            ) : null
          }
          leftAddon={
            columnMeta?.showNestLevel && cellNestedLevel > 1 ? <NestedLevelAddon level={cellNestedLevel} /> : prefix
          }
          rightAddon={suffix}
          id={fieldId}
          data-field={fieldId}
          data-testid={fieldId}
          min={isNumeric ? 0 : undefined}
          onKeyDown={disableArrowKeys}
          data-1p-ignore
        />
      </div>
      {showFieldErrorModal && (
        <MessageFieldErrorModal
          error={error}
          viewMode={mode === DisplayModes.view}
          ref={refs.setFloating}
          style={floatingStyles}
          numberOfErrors={numberOfErrors}
          isValidating={isValidating}
        />
      )}
    </>
  );
};

const disableArrowKeys: KeyboardEventHandler<HTMLInputElement> = (e) => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  }
};
