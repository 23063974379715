import { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/Display/Tooltip/Tooltip';
import { CheckCircleIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { InProgresCircle } from 'components/CustomIcons/InProgressCircle';
import { useInitialValue } from 'support/helpers/hooks/webedi/useInitialValue';

type RowStatusProps = {
  statusColumn: string | undefined;
  currentColumn: string;
  currentPath: string;
  tooltips: {
    deleted: string;
    partial: string;
    complete: string;
  };
  isProcurosAdded: boolean;
};

type StatusType = 'deleted' | 'partial' | 'complete';

const iconComponents = {
  deleted: NoSymbolIcon,
  partial: InProgresCircle,
  complete: CheckCircleIcon,
};

const iconClasses: Record<StatusType, string> = {
  deleted: 'size-5 text-gray-400',
  partial: 'size-5 text-yellow-500',
  complete: 'size-5 text-procuros-green-500',
};

const getStatus = (value: number, initialValue: number | undefined, isAdded: boolean): StatusType => {
  if (isAdded || initialValue === undefined) return 'complete';
  if (value === 0 || (!value && value !== initialValue)) return 'deleted';
  if (value < initialValue) return 'partial';
  return 'complete';
};

export const RowStatus: FC<RowStatusProps> = ({
  statusColumn,
  currentColumn,
  currentPath,
  tooltips,
  isProcurosAdded,
}) => {
  const finalPath = currentPath.replace(currentColumn, statusColumn || '');
  const value = useWatch({ name: finalPath });
  const initialValue = useInitialValue(finalPath);

  const { icon, tooltip } = useMemo(() => {
    const status = getStatus(value, initialValue, isProcurosAdded);
    const IconComponent = iconComponents[status];
    return {
      icon: <IconComponent className={iconClasses[status]} />,
      tooltip: tooltips[status],
    };
  }, [value, initialValue, isProcurosAdded, tooltips]);

  return (
    <Tooltip>
      <TooltipTrigger>{icon}</TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};
