import { useFloating, autoUpdate, offset, autoPlacement } from '@floating-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { ModificationGroupsModal } from './ModificationGroupsModal';
import { CellContext } from '@tanstack/react-table';
import { useDocumentCurrency } from 'components/WebEDI/helpers';
import classNames from 'classnames';

type ModificationGroups = Parameters<typeof ModificationGroupsModal>[0]['modificationGroups'];
type RowData = { modification_groups?: ModificationGroups; line_allowance_amount: number; line_charge_amount: number };
type ModificationGroupsModalTriggerProps<TData extends RowData, TValue> = {
  cell?: CellContext<TData, TValue>['cell'];
};

export const ModificationGroupsModalTrigger = <TData extends RowData, TValue>({
  cell,
}: ModificationGroupsModalTriggerProps<TData, TValue>) => {
  const currency = useDocumentCurrency();
  const [hovered, setHovered] = useState(false);
  const { refs, floatingStyles } = useFloating({
    open: hovered,
    onOpenChange: setHovered,
    placement: 'right',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      autoPlacement({
        allowedPlacements: ['right', 'left'],
      }),
    ],
  });

  const lineAllowanceAmount = cell?.row.original.line_allowance_amount;
  const lineChargeAmount = cell?.row.original.line_charge_amount;
  const hasModificationGroups =
    cell?.row.original.modification_groups?.length && (lineAllowanceAmount || lineChargeAmount);

  const showModal = Boolean(hovered && hasModificationGroups);
  return (
    <div>
      <InformationCircleIcon
        ref={refs.setReference}
        aria-disabled={!hasModificationGroups}
        className={classNames('ml-1 size-5 shrink-', {
          'text-current hover:text-procuros-green-500': hasModificationGroups,
          'text-gray-300': !hasModificationGroups,
        })}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      />
      {showModal && (
        <ModificationGroupsModal
          ref={refs.setFloating}
          style={floatingStyles}
          modificationGroups={cell?.row.original.modification_groups || []}
          documentCurrency={currency}
          totalAllowances={cell?.row.original.line_allowance_amount || 0}
          totalCharges={cell?.row.original.line_charge_amount || 0}
        />
      )}
    </div>
  );
};
