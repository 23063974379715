import { useNavigate, useParams } from 'react-router-dom';
import { Page } from 'components/Page/Page';
import { usePricingConfig } from 'services/repositories/pricingConfigs/pricingConfigs';
import { PricingConfigForm } from './PricingConfigForm/PricingConfigForm';
import { usePriceConfigsPartners } from 'hooks/usePriceConfigsPartners/usePriceConfigsPartners';
import { removeUploadedPriceConfig } from 'stores/priceConfigs/priceConfigs';

export function EditPricingConfigsPage() {
  const navigate = useNavigate();
  const { id: pricingConfigId, partnerId } = useParams();
  const { data: pricingConfig, isLoading: pricingConfigLoading } = usePricingConfig({
    variables: {
      pricingConfigId,
      partnerId,
    },
  });
  const { data: partnersWithInvoices, isLoading: connectedPartnersLoading } = usePriceConfigsPartners();

  const onCancelOrSuccess = () => {
    removeUploadedPriceConfig();
    navigate(-1);
  };

  const isPageLoading = partnerId ? connectedPartnersLoading || pricingConfigLoading : connectedPartnersLoading;
  return (
    <Page isLoading={isPageLoading}>
      <PricingConfigForm
        connectedPartners={partnersWithInvoices}
        pricingConfig={pricingConfig}
        onCancel={onCancelOrSuccess}
        onSuccess={onCancelOrSuccess}
      />
    </Page>
  );
}
