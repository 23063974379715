import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useSetNewPassword } from 'services/repositories/user/user';
import { AuthRoutes } from 'support/types';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver/useYupValidationResolver';
import { useNotificationStore } from 'stores/notifications/notifications';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { Button } from 'components/Form/Button/Button';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';

type ResetPasswordFromProps = {
  introText: string;
};

const defaultData = {
  password: '',
  confirmPassword: '',
};

type ResetPasswordFormValues = typeof defaultData;
export const ResetPasswordForm = ({ introText }: ResetPasswordFromProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const [resetPasswordError, setResetPasswordError] = useState<Array<string>>([]);
  const { addNotification } = useNotificationStore();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading } = useSetNewPassword();

  const formSchema = Yup.object({
    password: Yup.string().required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], t('auth:nonMatchingPasswords') ?? '')
      .min(8, t('auth:passwordLength') ?? ''),
  });

  const resolver = useYupValidationResolver(formSchema);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({ resolver });

  const onSubmit = async (submission: ResetPasswordFormValues) => {
    mutate(
      {
        password: submission.password,
        confirm_password: submission.confirmPassword,
        token: token ?? '',
        email: searchParams.get('email') ?? '',
      },
      {
        onSuccess: () => {
          navigate(AuthRoutes.login);
          addNotification(t('auth:successfullyUpdatedPassword'));
        },
        onError: (err) => {
          processSubmissionErrors({
            error: err,
            setInputError: setError,
            setNonInputErrors: setResetPasswordError,
            defaultData,
            t,
          });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
      <p className="mb-4 text-sm text-gray-600">{introText}</p>

      <ValidationErrors errors={resetPasswordError} />
      <div className="h-1">
        {errors.confirmPassword?.message && (
          <div className="text-sm text-red-500">{errors.confirmPassword?.message}</div>
        )}
      </div>

      <Input
        {...register('password', { required: true })}
        placeholder={t('auth:password')}
        label={t('auth:password')}
        type="password"
        required
      />

      <Input
        {...register('confirmPassword', { required: true })}
        placeholder={t('auth:confirmPassword')}
        label={t('auth:confirmPassword')}
        type="password"
        required
      />

      <Button type="submit" fullWidth loading={isLoading} analyticsId="password-reset:reset">
        {t('auth:resetPassword')}
      </Button>
    </form>
  );
};
