import { NavigationLink, NavigationLinkGroup } from 'components/Page/types';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { classNames } from 'support/helpers/generic/generic';

type SecondaryNavigationItemProps = {
  href: string;
  label: ReactNode;
  onClick?: () => void;
};

const getLinkClasses = ({ isActive }: { isActive: boolean }) =>
  classNames(
    isActive ? 'bg-procuros-green-100 text-procuros-green-900' : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900',
    'px-3 py-2 font-medium rounded-md text-sm group relative rounded-md',
  );

const SecondaryNavigationItem = ({ href, label, onClick }: SecondaryNavigationItemProps) => {
  return (
    <NavLink to={href} className={getLinkClasses} onClick={onClick}>
      <div className="flex flex-row w-full justify-between items-center">
        <div className="w-full flex flex-row items-center">
          <span className="w-full text-sm leading-none flex justify-between items-center h-6">{label}</span>
        </div>
      </div>
    </NavLink>
  );
};

export const SecondaryNavigation = ({ linkGroups }: { linkGroups: Array<NavigationLinkGroup> }) => {
  return (
    <div className="h-full flex flex-col pt-4 pl-4 sm:pl-6 md:pl-8">
      <div className="flex flex-col flex-1 flex-shrink-0 w-64">
        <div className="flex-1 flex flex-col overflow-y-auto justify-between">
          <div className="flex-1 flex flex-col gap-6">
            {linkGroups.map((linkGroup: NavigationLinkGroup, linkGroupIndex) => (
              <div key={linkGroupIndex}>
                {!!linkGroup.title && (
                  <div className="mb-3 text-xs font-medium leading-4 text-gray-400 uppercase">{linkGroup.title}</div>
                )}
                <div className="flex flex-col space-y-1">
                  {linkGroup.links.map((link: NavigationLink) => (
                    <SecondaryNavigationItem href={link.href!} label={link.label} key={link.href} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
