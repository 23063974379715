export enum CSVRowKeys {
  TradePartnerName = 'Trade Partner Name',
  ContactPerson = 'Contact Person',
  Language = 'Language',
  EmailAddress = 'Email Address',
  Telephone = 'Telephone',
  GLN = 'GLN',
  OtherIdentifier = 'Other Identifier',
}

export type CSVRow = {
  [CSVRowKeys.TradePartnerName]: string;
  [CSVRowKeys.ContactPerson]: string;
  [CSVRowKeys.Language]: string;
  [CSVRowKeys.EmailAddress]: string;
  [CSVRowKeys.Telephone]: string;
  [CSVRowKeys.GLN]: string;
  [CSVRowKeys.OtherIdentifier]: string;
};

const hasValueOnRow = (row: any, key: CSVRowKeys) => {
  return row.hasOwnProperty(key) && row[key] !== '';
};
const validateCSVRow = (row: unknown) => {
  if (!row) return false;
  return (
    hasValueOnRow(row, CSVRowKeys.TradePartnerName) &&
    hasValueOnRow(row, CSVRowKeys.ContactPerson) &&
    hasValueOnRow(row, CSVRowKeys.Language) &&
    hasValueOnRow(row, CSVRowKeys.EmailAddress) &&
    (hasValueOnRow(row, CSVRowKeys.GLN) || hasValueOnRow(row, CSVRowKeys.OtherIdentifier))
  );
};
export const validateCSV = (results: unknown[] | unknown) => {
  return Array.isArray(results) ? results.length > 0 && results.every(validateCSVRow) : false;
};
