import { PartnerDTO } from 'support/types';
import { deepCopy } from '../generic/generic';
import { PropertyFilter } from 'components/ListTable/types';

export const addPartnersToFiltersConfig = (
  defaultFilterConfig: PropertyFilter[],
  key: string,
  partners?: PartnerDTO[],
): PropertyFilter[] => {
  if (!partners) {
    return defaultFilterConfig;
  }

  const newFilterConfig = deepCopy<PropertyFilter[]>(defaultFilterConfig); // create deep copy
  const filterIndex = newFilterConfig.findIndex((filter: PropertyFilter) => filter.key === key);

  if (filterIndex > -1) {
    const configDropDownOptions = newFilterConfig[filterIndex].dropDownOptions;

    if (configDropDownOptions) {
      configDropDownOptions.dropdownValues = partners.map((partner: PartnerDTO) => {
        return {
          label: partner.name,
          value: partner.id,
        };
      });
    }
  }

  return newFilterConfig;
};
