import { Filters } from 'components/ListTable/Filters/Filters';
import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { Page } from 'components/Page/Page';
import { useDebounce } from 'hooks/useDebounce';
import { useNewFilters } from 'hooks/useFilters';
import isEqual from 'lodash/isEqual';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnectedPartners, useCurrentPartner } from 'services/repositories/partners/partners';
import { useInfiniteTransactions } from 'services/repositories/transactions/transactions';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { addPartnersToFiltersConfig } from 'support/helpers/filters/filters';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { translationForDocumentType } from 'support/helpers/transactions/transactions';
import { IssueResolverType, TransactionDTO, TransactionFlowState, TransactionState } from 'support/types';
import { getBlockedTransactionsFilterConfig } from '../tableConfig';
import { TransactionFilterKey } from '../types';

import { ActionRequiredBadge } from 'components/ActionRequiredBadge/ActionRequiredBadge';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { BlockedTransactionsBanner } from '../BlockedTransactionsBanner/BlockedTransactionsBanner';
import { TransactionsTabs } from '../TransactionsTabs/TransactionsTabs';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { DropTransactionModal } from '../modals/DropTransactionModal';
import { RetriggerTransactionsModal } from '../modals/RetriggerTransactionsModal';
import { DeliverTransactionsModal } from '../modals/DeliverTransactionsModal';

export const ExpiredTransactions = () => {
  return <BlockedTransactions expired={true} />;
};

export function BlockedTransactions({ expired }: { expired?: boolean }) {
  const { t } = useTranslation();
  const { data: currentPartner } = useCurrentPartner();
  const { data: connectedPartnersData } = useConnectedPartners();
  const determineIssueResolver = useTransactionActionRequired();
  const [selectedItems, setSelectedItems] = useState<Array<TransactionDTO>>([]);
  const [showDropTransactionModal, setShowDropTransactionModal] = useState(false);
  const [showRetriggerTransactionsModal, setShowRetriggerTransactionsModal] = useState(false);
  const [showDeliverTransactionsModal, setShowDeliverTransactionsModal] = useState(false);

  const filterConfig = useMemo(
    () =>
      addPartnersToFiltersConfig(
        getBlockedTransactionsFilterConfig(),
        TransactionFilterKey.partnerId,
        connectedPartnersData,
      ),
    [connectedPartnersData],
  );

  const filterKey = useMemo(() => {
    return expired ? 'expired-transactions' : 'blocked-transactions';
  }, [expired]);

  const { filters, filterValues, updateFilters, clearAllFilters, perPage } = useNewFilters(filterConfig, filterKey);

  const hasFilters = filterValues && filterValues.length > 0;
  const debouncedFilterValues = useDebounce(filterValues, 150);
  const differentFilters = !isEqual(debouncedFilterValues, filterValues);

  const {
    data: transactionsData,
    isLoading: transactionsDataLoading,
    isFetchingNextPage,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
  } = useInfiniteTransactions({
    variables: {
      query: {
        filterValues: [
          ...debouncedFilterValues,
          { key: TransactionFilterKey.transactionState, value: TransactionState.failed },
          { key: TransactionFilterKey.transactionFlow, value: TransactionFlowState.live },
          // onlyExpired does not default to false in backend, so we need to explicitly set it
          ...(expired
            ? [{ key: TransactionFilterKey.onlyExpired, value: 'true' }]
            : [{ key: TransactionFilterKey.onlyExpired, value: 'false' }]),
        ],
        perPage,
        include: 'issueResolver',
      },
    },
  });

  const tableHeaders: Array<TableHeader> = [
    { label: t('transactions:list.tableHeaders.externalReference') },
    { label: t('transactions:list.tableHeaders.type') },
    { label: t('transactions:list.tableHeaders.tradePartner') },
    { label: t('transactions:list.tableHeaders.flow') },
    { label: t('transactions:list.tableHeaders.date') },
    {
      label: t('transactions:list.tableHeaders.issueResolver'),
      tooltip: t('transactions:list.tableHeaders.tooltips.issueResolver'),
    },
    { label: '', key: 'action-required' },
  ];

  const buildRow = (transaction: TransactionDTO) => {
    const partner = currentPartner?.id !== transaction.sender?.id ? transaction.sender : transaction.receiver;
    const typeLocale = translationForDocumentType(t, transaction.documentType);
    const [issueResolver, issueResolverLabel]: [IssueResolverType, string] = determineIssueResolver(
      transaction?.issueResolverId,
    );

    return [
      {
        element: transaction.externalMessageRef?.length ? transaction.externalMessageRef : EMPTY_FIELD,
        key: `external-ref ${transaction.id ?? ''}`,
        className: 'text-gray-900 font-medium group-hover:text-procuros-green-600 ',
      },
      {
        element: transaction.documentType?.length ? typeLocale : EMPTY_FIELD,
        key: 'type',
      },
      {
        element: partner?.name ?? EMPTY_FIELD,
        key: 'partner-name',
        className: 'max-w-xs truncate',
      },

      {
        element: transaction.flow ? t(`common:flow.${transaction.flow}`) : t('common:flow.unknown'),
        key: 'flow',
      },
      {
        element: <span className="tabular-nums">{formatDayAndTime(transaction.createdAt)}</span>,
        key: 'created-at',
      },
      {
        element: issueResolverLabel,
        key: 'action-required-actor',
      },
      {
        element: issueResolver === IssueResolverType.CURRENT_PARTNER ? <ActionRequiredBadge /> : null,
        key: 'action-required',
      },
    ];
  };

  const data = transactionsData?.pages?.flatMap((page) => page.items);
  const emptyData = data?.length === 0;

  const selectedTransactionIds = useMemo(() => selectedItems.map((item) => item.id), [selectedItems]);

  const bulkDiscard = useCallback(
    (items: Array<TransactionDTO>) => {
      setSelectedItems(items);
      setShowDropTransactionModal(true);
    },
    [setSelectedItems],
  );

  const bulkDeliver = useCallback(
    (items: Array<TransactionDTO>) => {
      setSelectedItems(items);
      setShowDeliverTransactionsModal(true);
    },
    [setSelectedItems],
  );

  const bulkRetrigger = useCallback(
    (items: Array<TransactionDTO>) => {
      setSelectedItems(items);
      setShowRetriggerTransactionsModal(true);
    },
    [setSelectedItems],
  );

  return (
    <Page>
      <Page.Head title={t('common:mainMenu.transactions')} description={t('transactions:description')}>
        <div className="w-full">
          <TransactionsTabs selectedIndex={expired ? 2 : 1} />

          <Filters
            propertyFilters={filters}
            onFiltersChange={updateFilters}
            filterValues={filterValues}
            clearAllFilters={clearAllFilters}
          />
        </div>
      </Page.Head>

      <Page.Section>
        <BlockedTransactionsBanner expired={expired} />
        <ListTable<TransactionDTO>
          headers={tableHeaders}
          data={data}
          rowBuilder={buildRow}
          rowClickPath={(transaction) => routeToTransactionPage(transaction.internalType, transaction.id)}
          isLoading={transactionsDataLoading || (differentFilters && emptyData)}
          pagination={
            <InfiniteScroll
              hasMore={Boolean(hasNextPage)}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
              noMoreItemsEnabled={Boolean(hasPreviousPage)}
            />
          }
          emptyState={
            <ListTable.EmptyState background="gray" cols={tableHeaders.length}>
              {hasFilters
                ? t('common:table.withFilters.emptyState.title')
                : expired
                  ? t('transactions:list.expiredEmptyState')
                  : t('transactions:list.blockedEmptyState')}
            </ListTable.EmptyState>
          }
          bulkActions={[
            {
              type: 'dropdown',
              label: t('transactions:list.bulk_discard'),
              options: [
                {
                  title: t('transactions:bulk_discard_manual.title'),
                  description: t('transactions:bulk_discard_manual.description'),
                  onClick: bulkDeliver,
                },
                {
                  title: t('transactions:bulk_discard.title'),
                  description: t('transactions:bulk_discard.description'),
                  onClick: bulkDiscard,
                },
              ],
              analyticsId: 'transactions:bulk_discard_options',
            },
            {
              type: 'button',
              label: t('transactions:list.bulk_retrigger'),
              onClick: bulkRetrigger,
              icon: ArrowPathIcon,
              analyticsId: 'transactions:bulk_retrigger',
            },
          ]}
        />
      </Page.Section>

      <DropTransactionModal
        transactionIds={selectedTransactionIds}
        open={showDropTransactionModal}
        onComplete={() => setShowDropTransactionModal(false)}
      />

      <RetriggerTransactionsModal
        transactionIds={selectedTransactionIds}
        open={showRetriggerTransactionsModal}
        onComplete={() => setShowRetriggerTransactionsModal(false)}
      />

      <DeliverTransactionsModal
        transactionIds={selectedTransactionIds}
        open={showDeliverTransactionsModal}
        onComplete={() => setShowDeliverTransactionsModal(false)}
      />
    </Page>
  );
}

/**
 * i18n
 *
 * t('common:flow.LIVE')
 * t('common:flow.TEST')
 * t('common:flow.IDLE')
 * t('common:flow.unknown')
 */
