import { RelationshipStateBadge } from 'components/RelationshipStateBadge/RelationshipStateBadge';
import { ReactNode } from 'react';
import { RelationshipV2 } from 'support/types';

export const RelationshipBadge = ({
  relationship,
  currentPartnerId,
  children,
}: {
  relationship: RelationshipV2;
  currentPartnerId: string;
  children?: ReactNode;
}) => {
  const currentPartnerIsSender = relationship.sender_partner?.id === currentPartnerId;
  const currentPartnerIsRequester = relationship.trade_request?.requested_by === currentPartnerId;
  const otherPartner = currentPartnerIsSender ? relationship.receiver_partner : relationship.sender_partner;
  const status = relationship.status;

  return (
    <RelationshipStateBadge
      status={status}
      requester={currentPartnerIsRequester}
      sender={currentPartnerIsSender}
      otherPartner={otherPartner}
      senderIntegration={relationship.sender_integration?.production || undefined}
      receiverIntegration={relationship.receiver_integration?.production || undefined}
    >
      {children}
    </RelationshipStateBadge>
  );
};
