import { transform, enrich } from '@procuros/transformer';
import { createData } from 'services/http/http';
import portalSchema from 'support/types/schema-portal';
import { ValidationError } from '../errors/errors';
import { DocumentType } from 'support/types';
import { logError } from 'services/logging/logging';

export type TransformationResult = {
  success: boolean;
  output: any;
  errors?: Array<{ message: string; position?: number }>;
};

type TestProcessTransformationResponse =
  portalSchema.paths['/v1/integrations/{integrationId}/processes/{processId}/transformations/{transformationId}/test']['post']['responses']['200']['content']['application/json'];

export const executeTransformationOnBackend = async ({
  integrationId,
  processId,
  transformationId,
  input,
}: {
  integrationId: string;
  processId: string;
  transformationId: string;
  input: any;
}): Promise<TransformationResult> => {
  try {
    const response = await createData<TestProcessTransformationResponse>(
      `/v1/integrations/${integrationId}/processes/${processId}/transformations/${transformationId}/test`,
      { input },
    );

    if (response.data.success === false) {
      return {
        success: false,
        output: { error: 'Unknown Error' },
        errors: [],
      };
    }

    return { output: response.data.result, success: true };
  } catch (e: any) {
    if (e instanceof ValidationError) {
      // Backend is not returning a "real" ValidationError as that would not allow
      // for a positon to be returned. So we need to handle this case separately
      const firstError = e.errors[0] as unknown as { message: string; position: number };
      return {
        success: false,
        output: { error: [e.message, firstError.message].join(': ') },
        errors: [firstError],
      };
    }
    return {
      success: false,
      output: { error: 'Unknown backend transformation error' },
      errors: [{ message: 'Unknown backend transformation error' }],
    };
  }
};

export const executeTransformation = async (
  input: any,
  transformationExpression: string,
): Promise<TransformationResult> => {
  try {
    const transformationResult = await transform({ input: input, expression: transformationExpression });

    if (!transformationResult.success) {
      return {
        success: false,
        output: { error: transformationResult.error_message },
        errors: transformationResult.errors,
      };
    }

    return { output: transformationResult.result, success: true };
  } catch (e: any) {
    console.log(e);
    return {
      success: false,
      output: { error: 'Unknown frontend transformation error' },
      errors: [{ message: 'Unknown frontend transformation error' }],
    };
  }
};

export const enrichDocument = ({ data, messageType }: { data: any; messageType?: DocumentType }) => {
  // Only enrich invoices and credit notes
  if (!messageType || [DocumentType.invoice, DocumentType.creditNote].includes(messageType) === false) {
    return data;
  }

  const enrichmentResult = enrich({ input: data });

  if (!enrichmentResult.success) {
    logError(`Enrichment failed: ${enrichmentResult.error_message}`);
    return data;
  }

  return enrichmentResult.result;
};
