import { TFunction } from 'i18next';

import {
  DocumentType,
  InvoiceItemDTO,
  MessageDTO,
  MessageV2DTO,
  ModificationDTO,
  RawTransactionDTO,
  ReceiverEnvelopeState,
  SenderEnvelopeDTO,
  SenderEnvelopeV2DTO,
  TransactionDTO,
  TransactionInternalType,
  TransactionState,
  ValueOf,
} from '../../types';

const calulateAbsoluteItemDiscount = (
  modification: Pick<ModificationDTO, 'amount'>,
  item: Partial<Pick<InvoiceItemDTO, 'quantity'>>,
) => {
  return (modification.amount ?? 0) * (item.quantity ?? 0);
};

export const calculateLineItemTotal = (
  item: Partial<Pick<InvoiceItemDTO, 'quantity' | 'unitPrice' | 'modificationGroups' | 'isDepositItem'>>,
) => {
  const subTotal = (item.quantity ?? 0) * (item.unitPrice ?? 0);
  const firstModification = item.modificationGroups?.[0]?.modifications?.[0];
  const firstModificationIsRelative = typeof firstModification?.percentage !== 'undefined';

  if (!firstModification || !!item.isDepositItem) {
    return subTotal;
  }

  const modificationAmount = firstModificationIsRelative
    ? subTotal * ((firstModification.percentage ?? 0) / 100)
    : calulateAbsoluteItemDiscount(firstModification, item);

  return firstModification.type === 'ALLOWANCE' ? subTotal - modificationAmount : subTotal + modificationAmount;
};

export const checkMessageDropped = (message?: MessageDTO | MessageV2DTO) => {
  if (!message) {
    return false;
  }

  const receiverEnvelopeState = isMessageV2DTO(message)
    ? message?.receiver_envelope?.state
    : message.receiverEnvelope?.state;
  return message.state === TransactionState.dropped || receiverEnvelopeState === ReceiverEnvelopeState.dropped;
};

export const checkSenderEnvelopeDropped = (senderEnvelope?: SenderEnvelopeDTO | null) => {
  if (!senderEnvelope) {
    return false;
  }

  return senderEnvelope.state === TransactionState.dropped;
};

export const translationForDocumentType = (t: TFunction, type?: ValueOf<DocumentType>) => {
  switch (type) {
    case DocumentType.shippingNotice:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.SHIPPING_NOTICE');
    case DocumentType.invoice:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.INVOICE');
    case DocumentType.orderResponse:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.ORDER_RESPONSE');
    case DocumentType.order:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.ORDER');
    case DocumentType.dispatchResponse:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.DISPATCH_INSTRUCTION_RESPONSE');
    case DocumentType.dispatchInstruction:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.DISPATCH_INSTRUCTION');
    case DocumentType.creditNote:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.CREDIT_NOTE');
    case DocumentType.productCatalog:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.PRODUCT_CATALOG');
    case DocumentType.receivalNotice:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.RECEIVAL_NOTICE');
    case DocumentType.unknown:
    default:
      return t('transactions:show.sidebar.relatedMessages.messageTypes.UNKNOWN');
  }
};

export const isMessageV2DTO = (
  message: MessageDTO | MessageV2DTO | SenderEnvelopeDTO | SenderEnvelopeV2DTO | TransactionDTO,
): message is MessageV2DTO => {
  return (message as MessageV2DTO).canonical !== undefined;
};

export const determineInternalType = (transaction: RawTransactionDTO): TransactionInternalType => {
  if (transaction.messageId) {
    return TransactionInternalType.message;
  }

  return TransactionInternalType.senderEnvelope;
};
