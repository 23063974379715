import { TrashIcon } from '@heroicons/react/20/solid';
import { Input } from 'components/Form/Input/Input/Input';
import { useTranslation } from 'react-i18next';
import { capitaliseFirstLetter } from 'support/helpers/generic/generic';
import { getValidNumberValue } from 'support/helpers/numbers/numbers';
import { ModificationDTO, ModificationReasonCode, ModificationType } from 'support/types';
import { ModificationCalculationType } from '../invoiceCalculations/invoiceCalculations';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';

export type ModificationValueForm = ModificationDTO & { calculationType?: ModificationCalculationType };
type ModificationRowsProps = {
  modifications: Array<ModificationValueForm>;
  calculationLevel: number;
  removeModification: (levelToModify: number, modificationPosition: number) => void;
  updateLevelModification: (
    levelToModify: number,
    modificationIndex: number,
    modification: ModificationValueForm,
  ) => void;
  disabled?: boolean;
};

export function ModificationRows({
  modifications,
  calculationLevel,
  removeModification,
  updateLevelModification,
  disabled,
}: ModificationRowsProps) {
  const { t } = useTranslation();

  return (
    <>
      {modifications.map((modification, index) => {
        const taxDisabled =
          modification.type === 'ALLOWANCE' ||
          modification.calculationType !== ModificationCalculationType.absolute ||
          disabled;
        return (
          <tr key={`${modification.type}-${index}`}>
            <td className="relative hidden pr-2 text-gray-900 sm:table-cell">
              <Dropdown
                label=""
                value={modification.type}
                options={[
                  {
                    value: 'ALLOWANCE' as ModificationType,
                    label: t('webedi:modifications.discount'),
                  },
                  {
                    value: 'CHARGE' as ModificationType,
                    label: t('webedi:modifications.charge'),
                  },
                ]}
                onChange={(value) => {
                  const newValue = {
                    ...modification,
                    type: value,
                    ...(value === 'CHARGE' && modification.calculationType === ModificationCalculationType.absolute
                      ? {
                          tax: {
                            amount: 0,
                            percentage: modification.tax?.percentage ?? 19,
                          },
                        }
                      : {}),
                  };
                  updateLevelModification(calculationLevel, index, newValue);
                }}
                disabled={disabled}
              />
            </td>

            <td className="relative hidden py-1 pr-2 sm:table-cell">
              <Dropdown
                label=""
                value={modification.reasonCode}
                options={
                  [
                    { value: 'SHIPPING', label: capitaliseFirstLetter('SHIPPING') },
                    { value: 'HANDLING', label: capitaliseFirstLetter('HANDLING') },
                    { value: 'DISCOUNT', label: capitaliseFirstLetter('DISCOUNT') },
                    { value: 'PACKAGING', label: capitaliseFirstLetter('PACKAGING') },
                  ] as Array<{ value: ModificationReasonCode; label: string }>
                }
                onChange={(value) => {
                  updateLevelModification(calculationLevel, index, {
                    ...modification,
                    reasonCode: value,
                  });
                }}
                disabled={disabled}
              />
            </td>

            <td className="hidden py-1 pr-2 sm:table-cell">
              <div className="relative flex">
                <Input
                  name="modificationAmount"
                  type="number"
                  placeholder={''}
                  required
                  value={
                    (modification.calculationType === ModificationCalculationType.absolute
                      ? modification.amount
                      : modification.percentage) ?? ''
                  }
                  className={`w-14 rounded-r-none text-gray-900`}
                  onChange={(e) => {
                    const newValue = getValidNumberValue(e.target.value);
                    updateLevelModification(calculationLevel, index, {
                      ...modification,
                      ...(modification.calculationType === ModificationCalculationType.absolute
                        ? {
                            amount: newValue,
                            percentage: undefined,
                            ...(modification.type === 'CHARGE' && {
                              tax: { amount: 0, percentage: modification.tax?.percentage ?? 19 },
                            }),
                            calculationType: ModificationCalculationType.absolute,
                          }
                        : {
                            percentage: newValue,
                            amount: undefined,
                            tax: undefined,
                            calculationType: ModificationCalculationType.relative,
                          }),
                    });
                  }}
                  hasError={Boolean(
                    modification.amount || modification.percentage
                      ? false
                      : modification.calculationType === ModificationCalculationType.absolute
                        ? typeof modification.amount !== 'number'
                        : typeof modification.percentage !== 'number',
                  )}
                  disabled={disabled}
                />
                <div className="w-16">
                  <Dropdown
                    label=""
                    value={modification.calculationType || ModificationCalculationType.relative}
                    options={[
                      { value: ModificationCalculationType.relative, label: '%' },
                      { value: ModificationCalculationType.absolute, label: '€' },
                    ]}
                    onChange={(value) => {
                      const newValue = {
                        ...modification,
                        ...(value === ModificationCalculationType.absolute
                          ? {
                              amount: modification.amount ?? modification.percentage,
                              percentage: undefined,
                              ...(modification.type === 'CHARGE' && {
                                tax: { amount: 0, percentage: modification.tax?.percentage ?? 19 },
                              }),
                              calculationType: value,
                            }
                          : {
                              percentage: modification.percentage ?? modification.amount,
                              amount: undefined,
                              tax: undefined,
                              calculationType: value,
                            }),
                      };
                      updateLevelModification(calculationLevel, index, newValue);
                    }}
                    className="-ml-px text-black"
                    buttonClassName="rounded-l-none rounded-r-md"
                    disabled={disabled}
                  />
                </div>
              </div>
            </td>

            <td className="hidden py-1 pr-2 text-gray-900 sm:table-cell">
              <Input
                type="number"
                min={0}
                max={100}
                required
                className={'text-right text-gray-900'}
                rightAddon="%"
                value={modification.tax?.percentage ?? ''}
                hasError={Boolean(taxDisabled ? false : typeof modification.tax?.percentage !== 'number')}
                onChange={(e) => {
                  const newValue = getValidNumberValue(e.target.value);
                  updateLevelModification(calculationLevel, index, {
                    ...modification,
                    tax: {
                      ...modification.tax,
                      percentage: newValue as number,
                    } as any,
                  });
                }}
                disabled={taxDisabled}
              />
            </td>

            <td className="hidden py-1 pr-2 sm:table-cell">
              <div className="mr-4 cursor-pointer" onClick={() => removeModification(calculationLevel, index)}>
                <TrashIcon className="size-6 text-gray-400" />
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
}
