import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { LocalSchemaDTO, LocalTransformationDTO } from 'stores/transformations/processTransformation';

type DeployModalProps = {
  selectedTransformation?: LocalTransformationDTO;
  activeTransformation?: LocalTransformationDTO;
  selectedInputSchema?: LocalSchemaDTO;
  activeInputSchema?: LocalSchemaDTO;
  selectedOutputSchema?: LocalSchemaDTO;
  activeOutputSchema?: LocalSchemaDTO;
  open: boolean;
  deploying: boolean;
  onDeploy: () => void;
  onClose: () => void;
};

export const DeployModal = ({
  open,
  deploying,
  selectedTransformation,
  activeTransformation,
  selectedInputSchema,
  activeInputSchema,
  selectedOutputSchema,
  activeOutputSchema,
  onClose,
  onDeploy,
}: DeployModalProps) => {
  const { t } = useTranslation();

  const PrimaryButton = () => (
    <Button loading={deploying} onClick={onDeploy} analyticsId="transformation-ui:deploy-confirm">
      {t('common:deploy')}
    </Button>
  );
  const CancelButton = () => (
    <Button variant="secondary" disabled={deploying} onClick={onClose} analyticsId="transformation-ui:deploy_cancel">
      {t('common:cancel')}
    </Button>
  );

  return (
    <Popup
      PrimaryButton={PrimaryButton}
      CancelButton={CancelButton}
      title="Deploy configuration"
      open={open}
      onClose={onClose}
    >
      <div className="space-y-6">
        <div className="text-sm text-gray-900">
          <h3 className="mb-2 font-medium">Input Schema</h3>
          {activeInputSchema?.id === selectedInputSchema?.id ? (
            <Badge color={BadgeColor.gray}>No change</Badge>
          ) : (
            <div className="space-y-1">
              <div className="flex items-center space-x-2 text-gray-700">
                <div className="w-16">Current: </div>
                <Badge color={activeInputSchema ? BadgeColor.red : BadgeColor.gray}>
                  {activeInputSchema ? activeInputSchema.name : 'none'}
                </Badge>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <div className="w-16">New: </div>
                <Badge color={selectedInputSchema ? BadgeColor.green : BadgeColor.gray}>
                  {selectedInputSchema ? selectedInputSchema.name : 'none'}
                </Badge>
              </div>
            </div>
          )}
        </div>
        <div className="text-sm text-gray-900">
          <h3 className="mb-2 font-medium">Transformation</h3>
          {activeTransformation?.id === selectedTransformation?.id ? (
            <Badge color={BadgeColor.gray}>No change</Badge>
          ) : (
            <div className="space-y-1">
              <div className="flex items-center space-x-2 text-gray-700">
                <div className="w-16">Current: </div>
                <Badge color={activeTransformation ? BadgeColor.red : BadgeColor.gray}>
                  {activeTransformation ? activeTransformation.name : 'none'}
                </Badge>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <div className="w-16">New: </div>
                <Badge color={selectedTransformation ? BadgeColor.green : BadgeColor.gray}>
                  {selectedTransformation ? selectedTransformation.name : 'none'}
                </Badge>
              </div>
            </div>
          )}
        </div>
        <div className="text-sm text-gray-900">
          <h3 className="mb-2 font-medium">Output Schema</h3>
          {activeOutputSchema?.id === selectedOutputSchema?.id ? (
            <Badge color={BadgeColor.gray}>No change</Badge>
          ) : (
            <div className="space-y-1">
              <div className="flex items-center space-x-2 text-gray-700">
                <div className="w-16">Current: </div>
                <Badge color={activeOutputSchema ? BadgeColor.red : BadgeColor.gray}>
                  {activeOutputSchema ? activeOutputSchema.name : 'none'}
                </Badge>
              </div>
              <div className="flex items-center space-x-2 text-gray-700">
                <div className="w-16">New: </div>
                <Badge color={selectedOutputSchema ? BadgeColor.green : BadgeColor.gray}>
                  {selectedOutputSchema ? selectedOutputSchema.name : 'none'}
                </Badge>
              </div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};
