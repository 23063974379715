import { Section } from 'components/Display/Section/Section';
import { Trans, useTranslation } from 'react-i18next';
import { SUPPORT_EMAIL } from 'support/helpers/const/const';

export const EdifactSideInformation = () => {
  const { t } = useTranslation();
  return (
    <Section.Vertical
      header={<Section.Title>{t('integrations:processes.new.edifact.sideInformation.title')}</Section.Title>}
    >
      <div className="text-sm font-medium text-gray-700">
        <Trans
          t={t}
          i18nKey={'integrations:processes.new.edifact.sideInformation.content'}
          values={{ supportEmail: SUPPORT_EMAIL }}
          components={{ br: <br /> }}
        />
      </div>
    </Section.Vertical>
  );
};
