import { PageSelectorKey, PaginationSelectorType } from '../types';
import { PageSelector } from './PageSelector';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

type PaginationProps = {
  hasPrevious: boolean;
  hasNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
  isLoading?: boolean;
};

export function Pagination({ hasNext, hasPrevious, onNext, onPrevious, isLoading }: PaginationProps) {
  const buttons: Array<PaginationSelectorType> = [
    {
      pageKey: PageSelectorKey.backwardArrow,
      display: ChevronLeftIcon,
      disabled: !hasPrevious,
    },
    {
      pageKey: PageSelectorKey.forwardArrow,
      display: ChevronRightIcon,
      disabled: !hasNext,
    },
  ];

  if (buttons.filter(({ disabled }) => disabled).length === buttons.length) return null;

  return (
    <nav className="flex items-center justify-between px-3" aria-label="Pagination">
      <div className="flex flex-1 justify-between sm:justify-end">
        {buttons.map((item: PaginationSelectorType, index: number) => (
          <PageSelector
            display={item.display}
            disabled={Boolean(item.disabled) || Boolean(isLoading)}
            key={item.pageKey}
            pageKey={item.pageKey}
            onClick={index === 0 ? onPrevious : onNext}
            className={classNames({
              'rounded-l !rounded-r-none': !index,
              'rounded-r !rounded-l-none border-l-0': index === buttons.length - 1,
            })}
          />
        ))}
      </div>
    </nav>
  );
}
