import { useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ColumnTypesInternal } from 'components/DataTable/Types';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import { TFunction } from 'i18next';
import ImportModal, { DataRow, FieldDefinition } from 'pages/ProductCatalog/ImportModal';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { unflattenObject, withoutNulls } from 'support/helpers/generic/generic';

// TODO: We will later have to replace this with logic
// to dynamically build the import field definitions
// based on the data relevance and modality defined for the receiver
const buildBreuningerPricatFields = (t: TFunction): Array<FieldDefinition> => [
  {
    key: 'item.name',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.name.label'),
    description: t('priceCatalogs:importModal.fields.name.description'),
    isRequired: true,
  },
  {
    key: 'item.identifiers.gtin',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.gtin.label'),
    description: t('priceCatalogs:importModal.fields.gtin.description'),
    isRequired: true,
    isUnique: true,
  },
  {
    key: 'item.identifiers.sender_internal',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.sender_internal.label'),
    description: t('priceCatalogs:importModal.fields.sender_internal.description'),
    isRequired: true,
  },
  {
    key: 'item.identifiers.receiver_internal',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.receiver_internal.label'),
    description: t('priceCatalogs:importModal.fields.receiver_internal.description'),
  },
  {
    key: 'item.unit_price',
    type: 'number',
    label: t('priceCatalogs:importModal.fields.unit_price.label'),
    description: t('priceCatalogs:importModal.fields.unit_price.description'),
    isRequired: true,
  },
  {
    key: 'item.currency',
    type: 'enum',
    label: t('priceCatalogs:importModal.fields.currency.label'),
    description: t('priceCatalogs:importModal.fields.currency.description'),
    options: [
      { value: 'AED', label: 'AED' },
      { value: 'AFN', label: 'AFN' },
      { value: 'ALL', label: 'ALL' },
      { value: 'AMD', label: 'AMD' },
      { value: 'ANG', label: 'ANG' },
      { value: 'AOA', label: 'AOA' },
      { value: 'ARS', label: 'ARS' },
      { value: 'AUD', label: 'AUD' },
      { value: 'AWG', label: 'AWG' },
      { value: 'AZN', label: 'AZN' },
      { value: 'BAM', label: 'BAM' },
      { value: 'BBD', label: 'BBD' },
      { value: 'BDT', label: 'BDT' },
      { value: 'BGN', label: 'BGN' },
      { value: 'BHD', label: 'BHD' },
      { value: 'BIF', label: 'BIF' },
      { value: 'BMD', label: 'BMD' },
      { value: 'BND', label: 'BND' },
      { value: 'BOB', label: 'BOB' },
      { value: 'BRL', label: 'BRL' },
      { value: 'BSD', label: 'BSD' },
      { value: 'BTN', label: 'BTN' },
      { value: 'BWP', label: 'BWP' },
      { value: 'BYR', label: 'BYR' },
      { value: 'BZD', label: 'BZD' },
      { value: 'CAD', label: 'CAD' },
      { value: 'CDF', label: 'CDF' },
      { value: 'CHF', label: 'CHF' },
      { value: 'CKD', label: 'CKD' },
      { value: 'CLP', label: 'CLP' },
      { value: 'CNY', label: 'CNY' },
      { value: 'COP', label: 'COP' },
      { value: 'CRC', label: 'CRC' },
      { value: 'CUP', label: 'CUP' },
      { value: 'CVE', label: 'CVE' },
      { value: 'CZK', label: 'CZK' },
      { value: 'DJF', label: 'DJF' },
      { value: 'DKK', label: 'DKK' },
      { value: 'DOP', label: 'DOP' },
      { value: 'DZD', label: 'DZD' },
      { value: 'EGP', label: 'EGP' },
      { value: 'ERN', label: 'ERN' },
      { value: 'ETB', label: 'ETB' },
      { value: 'EUR', label: 'EUR' },
      { value: 'FJD', label: 'FJD' },
      { value: 'FKP', label: 'FKP' },
      { value: 'FOK', label: 'FOK' },
      { value: 'GBP', label: 'GBP' },
      { value: 'GEL', label: 'GEL' },
      { value: 'GGP', label: 'GGP' },
      { value: 'GHS', label: 'GHS' },
      { value: 'GIP', label: 'GIP' },
      { value: 'GMD', label: 'GMD' },
      { value: 'GNF', label: 'GNF' },
      { value: 'GTQ', label: 'GTQ' },
      { value: 'GYD', label: 'GYD' },
      { value: 'HKD', label: 'HKD' },
      { value: 'HNL', label: 'HNL' },
      { value: 'HRK', label: 'HRK' },
      { value: 'HTG', label: 'HTG' },
      { value: 'HUF', label: 'HUF' },
      { value: 'IDR', label: 'IDR' },
      { value: 'ILS', label: 'ILS' },
      { value: 'IMP', label: 'IMP' },
      { value: 'INR', label: 'INR' },
      { value: 'IQD', label: 'IQD' },
      { value: 'IRR', label: 'IRR' },
      { value: 'ISK', label: 'ISK' },
      { value: 'JEP', label: 'JEP' },
      { value: 'JMD', label: 'JMD' },
      { value: 'JOD', label: 'JOD' },
      { value: 'JPY', label: 'JPY' },
      { value: 'KES', label: 'KES' },
      { value: 'KGS', label: 'KGS' },
      { value: 'KHR', label: 'KHR' },
      { value: 'KID', label: 'KID' },
      { value: 'KMF', label: 'KMF' },
      { value: 'KPW', label: 'KPW' },
      { value: 'KRW', label: 'KRW' },
      { value: 'KWD', label: 'KWD' },
      { value: 'KYD', label: 'KYD' },
      { value: 'KZT', label: 'KZT' },
      { value: 'LAK', label: 'LAK' },
      { value: 'LBP', label: 'LBP' },
      { value: 'LKR', label: 'LKR' },
      { value: 'LRD', label: 'LRD' },
      { value: 'LSL', label: 'LSL' },
      { value: 'LYD', label: 'LYD' },
      { value: 'MAD', label: 'MAD' },
      { value: 'MDL', label: 'MDL' },
      { value: 'MGA', label: 'MGA' },
      { value: 'MKD', label: 'MKD' },
      { value: 'MMK', label: 'MMK' },
      { value: 'MNT', label: 'MNT' },
      { value: 'MOP', label: 'MOP' },
      { value: 'MRO', label: 'MRO' },
      { value: 'MUR', label: 'MUR' },
      { value: 'MVR', label: 'MVR' },
      { value: 'MWK', label: 'MWK' },
      { value: 'MXN', label: 'MXN' },
      { value: 'MYR', label: 'MYR' },
      { value: 'MZN', label: 'MZN' },
      { value: 'NAD', label: 'NAD' },
      { value: 'NGN', label: 'NGN' },
      { value: 'NIO', label: 'NIO' },
      { value: 'NOK', label: 'NOK' },
      { value: 'NPR', label: 'NPR' },
      { value: 'NZD', label: 'NZD' },
      { value: 'OMR', label: 'OMR' },
      { value: 'PAB', label: 'PAB' },
      { value: 'PEN', label: 'PEN' },
      { value: 'PGK', label: 'PGK' },
      { value: 'PHP', label: 'PHP' },
      { value: 'PKR', label: 'PKR' },
      { value: 'PLN', label: 'PLN' },
      { value: 'PYG', label: 'PYG' },
      { value: 'QAR', label: 'QAR' },
      { value: 'RON', label: 'RON' },
      { value: 'RSD', label: 'RSD' },
      { value: 'RUB', label: 'RUB' },
      { value: 'RWF', label: 'RWF' },
      { value: 'SAR', label: 'SAR' },
      { value: 'SBD', label: 'SBD' },
      { value: 'SCR', label: 'SCR' },
      { value: 'SDG', label: 'SDG' },
      { value: 'SEK', label: 'SEK' },
      { value: 'SGD', label: 'SGD' },
      { value: 'SHP', label: 'SHP' },
      { value: 'SLL', label: 'SLL' },
      { value: 'SOS', label: 'SOS' },
      { value: 'SRD', label: 'SRD' },
      { value: 'SSP', label: 'SSP' },
      { value: 'STD', label: 'STD' },
      { value: 'SYP', label: 'SYP' },
      { value: 'SZL', label: 'SZL' },
      { value: 'THB', label: 'THB' },
      { value: 'TJS', label: 'TJS' },
      { value: 'TMT', label: 'TMT' },
      { value: 'TND', label: 'TND' },
      { value: 'TOP', label: 'TOP' },
      { value: 'TRY', label: 'TRY' },
      { value: 'TTD', label: 'TTD' },
      { value: 'TVD', label: 'TVD' },
      { value: 'TWD', label: 'TWD' },
      { value: 'TZS', label: 'TZS' },
      { value: 'UAH', label: 'UAH' },
      { value: 'UGX', label: 'UGX' },
      { value: 'USD', label: 'USD' },
      { value: 'UYU', label: 'UYU' },
      { value: 'UZS', label: 'UZS' },
      { value: 'VEF', label: 'VEF' },
      { value: 'VND', label: 'VND' },
      { value: 'VUV', label: 'VUV' },
      { value: 'WST', label: 'WST' },
      { value: 'XAF', label: 'XAF' },
      { value: 'XCD', label: 'XCD' },
      { value: 'XOF', label: 'XOF' },
      { value: 'XPF', label: 'XPF' },
      { value: 'YER', label: 'YER' },
      { value: 'ZAR', label: 'ZAR' },
      { value: 'ZMW', label: 'ZMW' },
      { value: 'ZWL', label: 'ZWL' },
    ],
    isRequired: true,
  },
  {
    key: 'item.unit_of_measure',
    type: 'enum',
    label: t('priceCatalogs:importModal.fields.unit_of_measure.label'),
    description: t('priceCatalogs:importModal.fields.unit_of_measure.description'),
    options: [
      { value: 'EA', label: t('priceCatalogs:importModal.fields.unit_of_measure.options.EA') },
      { value: 'PCK', label: t('priceCatalogs:importModal.fields.unit_of_measure.options.PCK') },
      { value: 'KGM', label: t('priceCatalogs:importModal.fields.unit_of_measure.options.KGM') },
    ],
    isRequired: true,
  },
  {
    key: 'item.size',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.custom_fields.size.label'),
    description: t('priceCatalogs:importModal.fields.custom_fields.size.description'),
  },
  {
    key: 'item.dimension',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.custom_fields.dimension.label'),
    description: t('priceCatalogs:importModal.fields.custom_fields.dimension.description'),
  },
  {
    key: 'item.color_name',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.custom_fields.colorName.label'),
    description: t('priceCatalogs:importModal.fields.custom_fields.colorName.description'),
  },
  {
    key: 'item.color_code',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.custom_fields.colorNumber.label'),
    description: t('priceCatalogs:importModal.fields.custom_fields.colorNumber.description'),
  },
  {
    key: 'item.origin_country_code',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.origin_country_code.label'),
    description: t('priceCatalogs:importModal.fields.origin_country_code.description'),
  },
  {
    key: 'item.hs_code',
    type: 'string',
    label: t('priceCatalogs:importModal.fields.hs_code.label'),
    description: t('priceCatalogs:importModal.fields.hs_code.description'),
  },
  // TODO: Add these fields when they are supported (See https://linear.app/procuros/issue/CORE-5716/add-support-for-packaging-units-on-product-catalogs)
  // {
  //   key: 'packaging_units[].per_unit_quantity',
  //   type: 'number',
  //   label: t('priceCatalogs:importModal.fields.packaging_units.per_unit_quantity.label'),
  //   description: t('priceCatalogs:importModal.fields.packaging_units.per_unit_quantity.description'),
  // },
  {
    key: 'item.recommended_retail_price',
    type: 'number',
    label: t('priceCatalogs:importModal.fields.custom_fields.recommendedRetailPriceEUR.label'),
    description: t('priceCatalogs:importModal.fields.custom_fields.recommendedRetailPriceEUR.description'),
    isRequired: true,
  },
  {
    key: 'item.unit_price_valid_from_date',
    type: 'date',
    label: t('priceCatalogs:importModal.fields.custom_fields.priceValidFromDate.label'),
    description: t('priceCatalogs:importModal.fields.custom_fields.priceValidFromDate.description'),
    isRequired: true,
  },
];

type LineItemsImporterProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  importMode: ImportMode;
};

export type ImportMode = 'REPLACE' | 'APPEND';

export const LineItemsImporter = ({ isOpen, setOpen, importMode }: LineItemsImporterProps) => {
  const { t } = useTranslation();
  const { fields, append, setValue } = useDataTableContext();
  const emptyLineItem = useEmptyStructure(fields);
  const importFields = useMemo(() => buildBreuningerPricatFields(t), [t]);

  const onImport = (rows: Array<DataRow>) => {
    const skipRowNumber = importMode === 'REPLACE' ? 0 : fields.length;

    const newItems = rows.map((item, itemIndex) => {
      const newValue = unflattenObject(item);
      return {
        ...emptyLineItem,
        ...newValue,
        catalog_line_identifier: `${skipRowNumber + itemIndex + 1}`,
        _internal: {
          [ColumnTypesInternal.procuros_added]: true,
        },
        item: {
          ...withoutNulls(newValue.item),
          type: 'PRODUCT',
        },
      };
    });

    if (importMode === 'REPLACE') {
      //set value is a one time set, no need to remove and then append
      // we are debouncing the fields, so remove and then append can lead to weird situations
      setValue(undefined, newItems);
    } else {
      append(newItems);
    }
  };

  return (
    <ImportModal
      workbookName={t('priceCatalogs:importModal.workbook.name')}
      workbookDescription={t('priceCatalogs:importModal.workbook.description')}
      sheetName={t('priceCatalogs:importModal.sheet.name')}
      sheetDescription={t('priceCatalogs:importModal.sheet.description')}
      importActionLabel={t('priceCatalogs:importModal.importAction.label')}
      importActionDescription={t('priceCatalogs:importModal.importAction.description')}
      fields={importFields}
      open={isOpen}
      setOpen={setOpen}
      onImport={onImport}
    />
  );
};
