import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { Fragment, ReactElement, ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { classNames } from 'support/helpers/generic/generic';
import { SidebarBadge } from './components/SidebarBadge/SidebarBadge';

type SidebarItemProps = {
  href?: string;
  label: ReactNode;
  expanded: boolean;
  sidebarBreak?: boolean;
  externalLink?: boolean;
  icon?: ReactElement;
  notifications?: string | number;
  onClick?: () => void;
};

const getLinkClasses = ({ isActive }: { isActive: boolean }) =>
  classNames(
    isActive
      ? 'bg-procuros-green-400 text-black'
      : 'text-white hover:bg-procuros-green-999 hover:text-procuros-green-300',
    'px-2 py-2 font-medium rounded-md text-sm -mx-2 group relative',
  );

const getButtonClasses = () => classNames(getLinkClasses({ isActive: false }), 'min-w-full');
export function SidebarItem({ href, label, icon, externalLink, onClick, expanded, notifications }: SidebarItemProps) {
  const location = useLocation();
  const isSelected = href && location.pathname?.includes(href);

  const content = (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex w-full flex-row items-center">
        <div className="size-6 shrink-0">{icon}</div>
        {expanded ? (
          <span className="flex w-full items-center justify-between pl-3 text-sm leading-none">{label}</span>
        ) : null}
      </div>

      {notifications ? (
        <SidebarBadge size={expanded ? 'normal' : 'small'} color={isSelected ? 'green' : 'red'} value={notifications} />
      ) : null}
    </div>
  );

  const Wrapper = expanded ? Fragment : createToolTip(label);

  if (!href)
    return (
      <Wrapper>
        <button className={getButtonClasses()} onClick={onClick}>
          {content}
        </button>
      </Wrapper>
    );

  return (
    <Wrapper>
      {externalLink ? (
        <a
          href={href}
          target="_blank"
          className={getLinkClasses({ isActive: false })}
          rel="noreferrer"
          onClick={onClick}
        >
          {content}
        </a>
      ) : (
        <NavLink to={href} className={getLinkClasses} onClick={onClick}>
          {content}
        </NavLink>
      )}
    </Wrapper>
  );
}

const createToolTip =
  (tooltip: ReactNode) =>
  // eslint-disable-next-line react/display-name
  ({
    children,
  }: {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>> & {
      ref?: React.RefObject<HTMLElement>;
    };
  }) => {
    return (
      <Tooltip placement="right" spacing={30}>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    );
  };
