import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';

type TooManyIntegrationsModalProps = {
  connector: string;
  open: boolean;
  onClose: () => void;
};

export const TooManyIntegrationsModal = ({ open, onClose, connector }: TooManyIntegrationsModalProps) => {
  const { t } = useTranslation();

  const PrimaryButton = () => (
    <Button onClick={onClose} analyticsId="integrations:too-many-cancel">
      {t('common:close')}
    </Button>
  );

  return (
    <Popup
      PrimaryButton={PrimaryButton}
      title={t('integrations:connectorSelector.tooManyIntegrationsModal.title', { connector })}
      subtitle={t('integrations:connectorSelector.tooManyIntegrationsModal.description', { connector })}
      open={open}
      onClose={onClose}
      icon={<ExclamationTriangleIcon className="text-red-600" />}
      iconWrapperClassName="bg-red-100"
    ></Popup>
  );
};
