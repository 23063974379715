import { Radio } from 'components/Form/Radio/Radio';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/Form/Input/Input/Input';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { IdentifierDomainOptions } from 'support/types';

export type PartnerIdentifierType = {
  partyIdentifierDomain?: IdentifierDomainOptions | undefined;
  partyIdentifier?: string | undefined;
};

const identifierDomainOptionsSchema = Yup.mixed<IdentifierDomainOptions>().oneOf(
  Object.values(IdentifierDomainOptions),
);

type PartnerIdentifierSchemaReturnType<T> = T extends true
  ? Yup.ObjectSchema<PartnerIdentifierType>
  : Yup.ObjectSchema<Required<PartnerIdentifierType>>;

export const PartnerIdentifierSchema = <T extends boolean>(
  t: TFunction,
  connected: T,
): PartnerIdentifierSchemaReturnType<T> => {
  if (connected) {
    return Yup.object({
      partyIdentifierDomain: identifierDomainOptionsSchema,
      partyIdentifier: Yup.string(),
    }) as PartnerIdentifierSchemaReturnType<T>;
  }

  return Yup.object().shape({
    partyIdentifierDomain: identifierDomainOptionsSchema.required(),
    partyIdentifier: Yup.string().required(t('partners:request.partyIdentifier.required.error')),
  }) as PartnerIdentifierSchemaReturnType<T>;
};

export const PartnerIdentifiers = () => {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<PartnerIdentifierType>();

  const selectedIdentifierDomain = useWatch({
    control,
    name: 'partyIdentifierDomain',
  });
  return (
    <div>
      <div className="text-sm font-medium text-gray-700">{t('partners:request.identifier.title')}</div>
      <div className="pb-3 text-xs font-normal text-gray-500">{t('partners:request.identifier.description')}</div>
      <Radio.Group variant="list-compact">
        <Radio.Item
          variant="list-compact"
          description={t('partners:request.identifier.GS1.description')}
          label={t('partners:request.identifier.GS1.label')}
          value={IdentifierDomainOptions.GS1}
          {...register('partyIdentifierDomain')}
        />
        <Radio.Item
          variant="list-compact"
          label={t('partners:request.identifier.RECEIVER_INTERNAL.label')}
          value={IdentifierDomainOptions.RECEIVER_INTERNAL}
          {...register('partyIdentifierDomain')}
          description={t('partners:request.identifier.RECEIVER_INTERNAL.description')}
        />
      </Radio.Group>

      <Input
        label={t(`partners:request.identifier.${selectedIdentifierDomain}.input.label`)}
        wrapperClassName="mt-4"
        {...register('partyIdentifier')}
        errors={errors.partyIdentifier}
        description={
          selectedIdentifierDomain === IdentifierDomainOptions.RECEIVER_INTERNAL
            ? t('partners:request.identifier.RECEIVER_INTERNAL.input.description')
            : undefined
        }
        required
      />
    </div>
  );
};

/*
 * i18n keys:
 * t('partners:request.identifier.RECEIVER_INTERNAL.input.label')
 * t('partners:request.identifier.GS1.input.label')
 */
