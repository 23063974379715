import { FC, useState } from 'react';
import { SummaryAccordionHeader } from './components/SummaryAccordionHeader';
import { SummaryAccordionBody } from './components/SummaryAccordionBody';
import { SummaryAccordionFooter } from './components/SummaryAccordionFooter';
import { SummaryAccordionItem } from './types';
import classNames from 'classnames';

type SummaryAccordionProps = {
  header: SummaryAccordionItem;
  bodyItems: Array<SummaryAccordionItem>;
  footer: SummaryAccordionItem;
};

export const SummaryAccordion: FC<SummaryAccordionProps> = ({ header, bodyItems, footer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="border-t border-gray-200 bg-white shadow-lg">
      <SummaryAccordionHeader
        label={header.label}
        value={header.value}
        valueLabel={header.valueLabel}
        open={open}
        setOpen={setOpen}
      />
      <div className={classNames('flex flex-col pr-12', { hidden: !open })} data-testid="summary-accordion-content">
        <SummaryAccordionBody items={bodyItems} />
        <SummaryAccordionFooter label={footer.label} value={footer.value} />
      </div>
    </div>
  );
};
