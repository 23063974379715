import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  dialogs: string[];
};

type Actions = {
  addDialog: (id: string) => void;
  removeDialog: (id: string) => void;
};

const initialState: State = {
  dialogs: [],
};

export const useDialogStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,

    addDialog: (id: string) => {
      set((state) => {
        if (state.dialogs.includes(id)) return;
        state.dialogs.push(id);
      });
    },

    removeDialog: (id: string) => {
      set((state) => {
        state.dialogs = state.dialogs.filter((dialogId) => dialogId !== id);
      });
    },
  })),
);
