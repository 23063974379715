import { FileInput } from 'components/Form/FileInput/FileInput';
import { useTranslation } from 'react-i18next';

type FileUploaderProps = Pick<
  Parameters<typeof FileInput>[0],
  'onClear' | 'onError' | 'onLoad' | 'label' | 'accept' | 'loader' | 'multiple'
> & {
  isUploading: boolean;
  hideUploadedFiles?: boolean;
};

export const FileUploader = ({
  onClear,
  onError,
  onLoad,
  label,
  accept,
  loader,
  isUploading,
  multiple,
  hideUploadedFiles,
}: FileUploaderProps) => {
  const { t } = useTranslation();
  return (
    <FileInput
      label={label}
      onLoad={(data, filename) => {
        onLoad(data, filename);
      }}
      onError={onError}
      onClear={onClear}
      loader={loader}
      isProcessing={isUploading}
      processingLabel={t('common:components.fileUploader.uploading')}
      accept={accept}
      hideSelectedFile={hideUploadedFiles}
      multiple={multiple}
    />
  );
};
