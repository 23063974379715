import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCanonical } from 'services/datachecks/datachecks';
import { logError } from 'services/logging/logging';
import { useMessageWithProcessSpecification, useUpdateMessage } from 'services/repositories/messages/messages';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { ConflictError } from 'support/helpers/errors/errors';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import {
  DocumentType,
  MessageV2DTO,
  ProcessSpecificationDTO,
  TransactionInternalType,
  TransactionState,
} from 'support/types';
import { queryClient } from 'services/http/http';
import { updateTransactionState, useInfiniteTransactions } from 'services/repositories/transactions/transactions';
import { useFailedTransactionsCount } from 'services/repositories/stats/stats';
import { enrichDocument } from 'support/helpers/transformation/transformer';

export function useEditMessage({
  message,
  processSpecification,
}: {
  message: MessageV2DTO;
  processSpecification: ProcessSpecificationDTO | undefined;
}) {
  const navigate = useNavigate();
  const { mutate: mutateUpdateMessage, isSuccess, error } = useUpdateMessage();
  const { t } = useTranslation();

  const [isEditingMessage, setIsEditingMessage] = useState(false);
  const editMessage = useCallback(
    async (formValues: any) => {
      setIsEditingMessage(true);
      if (!processSpecification) {
        logError('No process specification found');
        setIsEditingMessage(false);
        return;
      }

      const { canonical } = await getCanonical(processSpecification, formValues);
      const enrichedCanonical = enrichDocument({
        messageType: message.type as DocumentType,
        data: canonical,
      });

      mutateUpdateMessage(
        {
          messageId: message.id,
          data: {
            data: enrichedCanonical,
            message_update_key: message.updated_at,
          },
        },
        {
          onSuccess: () => {
            updateTransactionState(message.id, TransactionState.pending);
            queryClient.invalidateQueries(useInfiniteTransactions.getKey());
            queryClient.resetQueries(useMessageWithProcessSpecification.getKey({ messageId: message.id }));
            queryClient.invalidateQueries(useFailedTransactionsCount.getKey());
            navigate(routeToTransactionPage(TransactionInternalType.message, message.id));
          },
          onError: (err) => {
            if (err instanceof ConflictError) {
              addNotification(
                {
                  title: t('webedi:editDocument.failedToast.title'),
                  subtitle: t('webedi:editDocument.failedToast.subtitle'),
                },
                NotificationType.error,
              );
            } else {
              addNotification(
                {
                  title: t('webedi:editDocument.failedToastGeneric.title'),
                },
                NotificationType.error,
              );
            }
          },
          onSettled: () => {
            setIsEditingMessage(false);
          },
        },
      );
    },
    [processSpecification, mutateUpdateMessage, addNotification, message, t, navigate],
  );

  return { editMessage, isUpdating: isEditingMessage, isSuccess, error };
}
