import { useOnboarding } from 'services/onboarding/onboarding';
import { DashboardWithGraphs } from './DashboardWithGraphs/DashboardWithGraphs';
import { DashboardWithOnboarding } from './DashboardWithOnboarding/DashboardWithOnboarding';
import { Page } from 'components/Page/Page';

export function Dashboard() {
  const { isFinished, isLoading } = useOnboarding();
  if (isLoading) {
    return (
      <Page isLoading>
        <></>
      </Page>
    );
  }
  return isFinished ? <DashboardWithGraphs /> : <DashboardWithOnboarding />;
}
