import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import React, { ReactElement, ReactNode, cloneElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { BasePopup } from '../BasePopup/BasePopup';

type PopupProps = {
  children?: React.ReactNode;
  CancelButton?: React.FC<{ cancelButtonRef: React.RefObject<HTMLButtonElement> }>;
  PrimaryButton?: React.FC;
  testId?: string;
  open: boolean;
  title?: ReactNode;
  subtitle?: ReactNode;
  onClose: () => void;
  fitContent?: boolean;
  icon?: ReactElement;
  iconWrapperClassName?: string;
};

export function Popup({
  children,
  CancelButton,
  PrimaryButton,
  open,
  title,
  subtitle,
  onClose,
  testId,
  fitContent,
  icon,
  iconWrapperClassName,
}: PopupProps) {
  const { t } = useTranslation();
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <BasePopup open={open} setOpen={onClose} initialFocus={cancelButtonRef} testId={testId} fitContent={fitContent}>
      <div className="flex gap-4 rounded-t-lg bg-white px-4 py-5 sm:px-6">
        {icon ? (
          <div
            className={classNames(
              'shrink-0 grow-0 flex items-center justify-center h-10 w-10 rounded-full',
              iconWrapperClassName,
            )}
          >
            {cloneElement(icon, {
              ...icon.props,
              className: classNames(icon.props.className, 'h-6 w-6'),
              'aria-hidden': 'true',
            })}
          </div>
        ) : null}

        {!!title && (
          <div className={'flex flex-col gap-2'}>
            {title ? <h3 className="text-lg font-semibold text-gray-900">{title}</h3> : null}
            {subtitle ? <div className="mt-1 text-sm text-gray-500">{subtitle}</div> : null}
          </div>
        )}
      </div>
      {!PrimaryButton && (
        <div className="absolute right-4 top-5">
          <Button
            size="extra-small"
            variant="minimal"
            LeftIcon={XMarkIcon}
            iconOnly
            onClick={onClose}
            aria-label={t('common:close')}
            analyticsId="popup_close"
          />
        </div>
      )}
      {children ? (
        <div
          className={classNames(
            'bg-white px-6 pb-4',
            title ? '' : 'rounded-t-lg',
            PrimaryButton || CancelButton ? '' : 'rounded-b-lg',
          )}
        >
          {children}
        </div>
      ) : null}
      {PrimaryButton || CancelButton ? (
        <div className="gap-x-3 rounded-b-lg px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          {PrimaryButton && <PrimaryButton />}
          {CancelButton && <CancelButton cancelButtonRef={cancelButtonRef} />}
        </div>
      ) : null}
    </BasePopup>
  );
}
