import { ModificationValueForm } from '../InvoiceModifications/ModificationRows';
import { ModificationCalculationType } from '../invoiceCalculations/invoiceCalculations';

export const validateModifications = (modifications: ModificationValueForm[]) => {
  const missingInvoiceModificationAmount = modifications.some(
    (modification) => modification.amount === undefined && modification.percentage === undefined,
  );

  const missingInvoiceModificationTaxPercent = modifications.some((modification: ModificationValueForm) => {
    const taxDisabled =
      modification.type === 'ALLOWANCE' || modification.calculationType !== ModificationCalculationType.absolute;

    return modification.tax?.percentage === undefined && !taxDisabled;
  });

  if (missingInvoiceModificationAmount || missingInvoiceModificationTaxPercent) {
    const errors = [];
    if (missingInvoiceModificationAmount) {
      errors.push('webedi:modifications.amount');
    }
    if (missingInvoiceModificationTaxPercent) {
      errors.push('webedi:modifications.taxPercentForCharges');
    }
    return errors;
  }
  return;
};
