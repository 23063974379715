import { CheckCircleIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { useIsMutating } from '@tanstack/react-query';
import { Button } from 'components/Form/Button/Button';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const WithoutErrorsContent = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const { t } = useTranslation();
  const isMutating = useIsMutating();

  return (
    <div className="space-y-6 p-4">
      <div className="space-y-2">
        <div className="flex w-full items-center justify-between gap-2">
          <h1 className="text-sm font-bold">{t('webedi:errorModal.withoutErrors.title')}</h1>
          <div className="flex size-5 items-center">
            <CheckCircleIcon className="size-6 shrink-0 text-green-500" />
          </div>
        </div>
        <p className="text-sm text-gray-700">{t('webedi:errorModal.withoutErrors.description')}</p>
      </div>
      <Button
        type="submit"
        size="small"
        LeftIcon={PaperAirplaneIcon}
        form="webedi-document"
        onMouseDown={(e) => e.preventDefault()}
        loading={Boolean(isMutating || isSubmitting)}
        analyticsId="webedi:document_send"
      >
        {t('webedi:errorModal.withoutErrors.cta')}
      </Button>
    </div>
  );
};
