import { queryClient } from 'services/http/http';
import { createInfiniteQuery, createMutation, createQuery } from 'react-query-kit';
import { createData, fetchData, removeData, updateData } from 'services/http/http';
import portalSchema from 'support/types/schema-portal';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import { Filter } from 'components/ListTable/types';

export type ListPricingConfigsResponse =
  portalSchema.paths['/v1/pricing-configs']['get']['responses']['200']['content']['application/json'];

type CreatePricingConfigPayload =
  portalSchema.paths['/v1/partners/{partnerId}/pricing-configs']['post']['requestBody']['content']['application/json'];

type CreatePricingConfigResponse =
  portalSchema.paths['/v1/partners/{partnerId}/pricing-configs']['post']['responses']['201']['content']['application/json'];

type UpdatePricingConfigResponse =
  portalSchema.paths['/v1/partners/{partnerId}/pricing-configs/{pricingConfigId}']['put']['responses']['200']['content']['application/json'];

type UpdatePricingConfigPayload =
  portalSchema.paths['/v1/partners/{partnerId}/pricing-configs/{pricingConfigId}']['put']['requestBody']['content']['application/json'];

type DeletePricingConfigResponse =
  portalSchema.paths['/v1/partners/{partnerId}/pricing-configs/{pricingConfigId}']['delete']['responses']['204']['content']['application/json'];

export const useInfinitePricingConfigs = createInfiniteQuery<ListPricingConfigsResponse, { query?: Filter }, Error>({
  primaryKey: '/pricingConfigs',
  queryFn: async ({ queryKey: [_, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query, cursor: pageParam });
    const response = await fetchData<ListPricingConfigsResponse>(`/v1/pricing-configs${urlParams}`);

    return response.data;
  },
  getNextPageParam: (lastPage) => lastPage.nextCursor,
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.previousCursor,
});

type CreatePricingConfigVariables = {
  partnerId: string;
  data: CreatePricingConfigPayload;
};

export const useCreatePricingConfig = createMutation<CreatePricingConfigResponse, CreatePricingConfigVariables, Error>({
  mutationFn: async ({ partnerId, data }) => {
    const response = await createData<CreatePricingConfigResponse>(`/v1/partners/${partnerId}/pricing-configs`, data);
    return response.data;
  },
  onSuccess: () => queryClient.invalidateQueries(useInfinitePricingConfigs.getKey()),
});

type UpdatePricingConfigVariables = {
  partnerId: string;
  pricingConfigId: string;
  data: UpdatePricingConfigPayload;
};

export const useUpdatePricingConfig = createMutation<void, UpdatePricingConfigVariables, Error>({
  mutationFn: async ({ partnerId, pricingConfigId, data }) => {
    await updateData<UpdatePricingConfigResponse>(`/v1/partners/${partnerId}/pricing-configs/${pricingConfigId}`, data);
  },
  onSuccess: () => queryClient.invalidateQueries(useInfinitePricingConfigs.getKey()),
});

type DeletePricingConfigVariables = {
  partnerId: string;
  pricingConfigId: string;
};

export const useDeletePricingConfig = createMutation<void, DeletePricingConfigVariables, Error>({
  mutationFn: async ({ partnerId, pricingConfigId }) => {
    await removeData<DeletePricingConfigResponse>(`/v1/partners/${partnerId}/pricing-configs/${pricingConfigId}`);
  },
  onSuccess: () => queryClient.invalidateQueries(useInfinitePricingConfigs.getKey()),
});

type GetPricingConfigResponse =
  portalSchema.paths['/v1/partners/{partnerId}/pricing-configs/{pricingConfigId}']['get']['responses']['200']['content']['application/json'];

export const usePricingConfig = createQuery<
  GetPricingConfigResponse,
  { partnerId: string | undefined; pricingConfigId: string | undefined },
  Error
>({
  primaryKey: '/pricingConfigs',
  queryFn: async ({ queryKey: [, variables] }) => {
    const response = await fetchData<GetPricingConfigResponse>(
      `/v1/partners/${variables.partnerId}/pricing-configs/${variables.pricingConfigId}`,
    );

    return response.data;
  },
  enabled: (_, variables) => !!variables.partnerId || !!variables.pricingConfigId,
});
