import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  edifactIdentityQualifierOptions,
  edifactSyntaxIdentifierOptions,
  edifactSyntaxVersionOptions,
} from './helpers';
import { Input } from 'components/Form/Input/Input/Input';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';

export const EDIFACTConfiguration = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<{
    configuration: {
      edifact_identity: string;
      edifact_identity_qualifier: string;
      edifact_syntax_identifier: string;
      edifact_syntax_version: string;
    };
  }>();
  return (
    <>
      <div className="grid grid-cols-3 gap-2">
        <Input
          {...register('configuration.edifact_identity')}
          label={t('integrations:new.as2.form.field.edifactIdentity.label')}
          errors={errors.configuration?.edifact_identity}
          wrapperClassName="col-span-2"
        />

        <Controller
          render={({ field }) => (
            <Dropdown
              {...field}
              label={t('integrations:new.as2.form.field.edifactIdentityQualifier.label')}
              options={edifactIdentityQualifierOptions}
              errors={errors.configuration?.edifact_identity_qualifier}
              className="col-span-1"
            />
          )}
          name="configuration.edifact_identity_qualifier"
        />
      </div>

      <Controller
        render={({ field }) => (
          <Dropdown
            {...field}
            label={t('integrations:new.as2.form.field.edifactSyntaxIdentifier.label')}
            options={edifactSyntaxIdentifierOptions}
            errors={errors.configuration?.edifact_syntax_identifier}
          />
        )}
        name="configuration.edifact_syntax_identifier"
      />

      <Controller
        render={({ field }) => (
          <Dropdown
            {...field}
            label={t('integrations:new.as2.form.field.edifactSyntaxVersion.label')}
            options={edifactSyntaxVersionOptions}
            errors={errors.configuration?.edifact_syntax_version}
          />
        )}
        name="configuration.edifact_syntax_version"
      />
    </>
  );
};
