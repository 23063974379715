import { useMemo } from 'react';

const GLOBAL_MODIFICATION_GROUP_PERCENTAGE_COLUMN_PATH = 'percentage';
const LINE_ITEMS_CHARGES_PERCENTAGE_COLUMN_PATH = 'modification_groups[0].charges[0].percentage';
const LINE_ITEMS_ALLOWANCES_PERCENTAGE_COLUMN_PATH = 'modification_groups[0].allowances[0].percentage';

export const useRemovePercentageColumn = (columns: Array<any>) => {
  return useMemo(
    () =>
      columns.filter(
        (column) =>
          column.path !== GLOBAL_MODIFICATION_GROUP_PERCENTAGE_COLUMN_PATH &&
          column.path !== LINE_ITEMS_CHARGES_PERCENTAGE_COLUMN_PATH &&
          column.path !== LINE_ITEMS_ALLOWANCES_PERCENTAGE_COLUMN_PATH,
      ),
    [columns],
  );
};
