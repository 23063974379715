import { PartnerConnectionDetails } from './components/PartnerConnectionDetails/PartnerConnectionDetails';
import { RelationshipsDetails } from './components/RelationshipDetails/RelationshipsDetails';
import { useMatch, useNavigate } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionsRoutes } from 'support/types';
import { useRelationship } from 'services/repositories/relationships/relationships';
import { ConnectionsTestSlideOver } from 'pages/Connections/components/ConnectionsSlideOvers/components/ConnectionsTestSlideOver/ConnectionsTestSlideOver';
import { ConnectionsTransactionErrorSlideOver } from './components/ConnectionsTransactionErrorSlideOver/ConnectionsTransactionErrorSlideOver';
import { TestMessageDetails } from './components/ConnectionsTestSlideOver/components/TestMessageDetails/TestMessageDetails';

export const ConnectionsSlideOvers = ({
  partnerId,
  relationshipId,
  currentPartnerId,
}: {
  partnerId: string | undefined;
  relationshipId: string | undefined;
  currentPartnerId: string | undefined;
}) => {
  const navigate = useNavigate();
  const isTestRoute = Boolean(useMatch(ConnectionsRoutes.testRelationship));
  const isSeeErrorRoute = Boolean(useMatch(ConnectionsRoutes.testRelationshipTransactionError));
  const isSeeTestMessageDetails = Boolean(useMatch(ConnectionsRoutes.testRelationshipMessageDetails));

  const testSlideOpen = shouldOpenTestSlideOver(isTestRoute, isSeeErrorRoute, isSeeTestMessageDetails);
  const relationshipDetailsSlideOpen = shouldOpenRelationshipDetails(relationshipId, testSlideOpen);
  const partnerDetailsSlideOpen = shouldOpenPartnerDetails(partnerId, relationshipDetailsSlideOpen);

  const { data: relationship } = useRelationship({
    variables: { relationshipId },
    enabled: Boolean(relationshipId),
  });
  const otherPartnerFromCurrentRelationship =
    relationship?.receiver_partner?.id === currentPartnerId
      ? relationship?.sender_partner?.id
      : relationship?.receiver_partner?.id;

  return (
    <>
      <PartnerConnectionDetails
        partnerId={partnerId}
        open={partnerDetailsSlideOpen}
        onClose={() => {
          navigate(routeToPage(ConnectionsRoutes.root));
        }}
      />

      <RelationshipsDetails
        relationshipId={relationshipId}
        open={relationshipDetailsSlideOpen}
        onBack={
          history
            ? () =>
                otherPartnerFromCurrentRelationship
                  ? navigate(
                      routeToPage(ConnectionsRoutes.partnerDetails, {
                        partnerId: otherPartnerFromCurrentRelationship,
                      }),
                    )
                  : navigate(routeToPage(ConnectionsRoutes.root))
            : undefined
        }
        onClose={() => navigate(routeToPage(ConnectionsRoutes.root))}
      />

      <ConnectionsTestSlideOver open={testSlideOpen} />
      <ConnectionsTransactionErrorSlideOver open={isSeeErrorRoute} />
      <TestMessageDetails open={isSeeTestMessageDetails} />
    </>
  );
};

const shouldOpenRelationshipDetails = (relationshipId: string | undefined, isTestRoute: boolean) =>
  Boolean(relationshipId) || isTestRoute;

const shouldOpenPartnerDetails = (partnerId: string | undefined, isRelationshipDetailsOpen: boolean) =>
  Boolean(partnerId) || isRelationshipDetailsOpen;

const shouldOpenTestSlideOver = (isTestRoute: boolean, isSeeErrorRoute: boolean, isSeeTestMessageDetails: boolean) =>
  isTestRoute || isSeeErrorRoute || isSeeTestMessageDetails;
