import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { FileInput } from 'components/Form/FileInput/FileInput';
import { useTranslation } from 'react-i18next';
import { addHostToUrl, downloadFile } from 'services/http/http';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { TemplateData, isValid, normalizeData } from './helpers';
import {
  removeUploadedPriceConfig,
  setUploadedPriceConfig,
  usePriceConfigStore,
} from 'stores/priceConfigs/priceConfigs';
import { useNavigate } from 'react-router-dom';
import { routeToPricingConfigEditPage } from 'support/helpers/navigation/navigation';
import { XSLXLoader } from 'components/Form/FileInput/Loaders/XLSXLoader/XLSXLoader';

const PRICING_CONFIG_TEMPLATE_FILE = (language = 'EN') =>
  addHostToUrl(`/assets/PricingConfigTemplate-${language.toUpperCase()}.xlsx`);

export const UploadPricingConfig = ({ open, setOpen }: { open: boolean; setOpen: (state: boolean) => void }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const { uploadedPriceConfig } = usePriceConfigStore();

  const PrimaryButton = () => (
    <Button
      disabled={!uploadedPriceConfig}
      onClick={() => navigate(routeToPricingConfigEditPage())}
      analyticsId="pricing_config_upload_save"
    >
      <div data-testid="action-button">{t('common:continue')}</div>
    </Button>
  );

  const CancelButton = ({ cancelButtonRef }: { cancelButtonRef: React.RefObject<HTMLButtonElement> }) => (
    <Button onClick={onClose} variant="secondary" ref={cancelButtonRef} analyticsId="pricing_config_upload_cancel">
      <div data-testid="cancel-button">{t('common:cancel')}</div>
    </Button>
  );

  const onClose = () => {
    setOpen(false);
  };

  const onError = () => {
    removeUploadedPriceConfig();
    addNotification(
      {
        title: t('priceList:upload.form.fileInput.notification.invalid.title'),
        subtitle: t('priceList:upload.form.fileInput.notification.invalid.subtitle'),
      },
      NotificationType.error,
    );
  };

  const onClearFileInput = () => {
    removeUploadedPriceConfig();
  };

  const onLoadFile = (data: TemplateData) => {
    addNotification(t('priceList:upload.form.fileInput.notification.valid'), NotificationType.success);
    const normalizedData = normalizeData(data);
    setUploadedPriceConfig(normalizedData);
  };

  return (
    <Popup
      CancelButton={CancelButton}
      PrimaryButton={PrimaryButton}
      title={t('priceList:upload.heading')}
      subtitle={t('priceList:upload.subHeading')}
      open={open}
      onClose={onClose}
    >
      <div className="grid gap-6">
        <Button
          LeftIcon={ArrowDownTrayIcon}
          onClick={() => downloadFile(PRICING_CONFIG_TEMPLATE_FILE(i18n.language))}
          analyticsId="pricing_config_template_download"
        >
          {t('priceList:upload.dowloadTemplate')}
        </Button>
        <FileInput
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          }}
          onClear={onClearFileInput}
          onError={onError}
          onLoad={onLoadFile}
          loader={XSLXLoader}
          validate={isValid}
        />
      </div>
    </Popup>
  );
};
