import { Filters } from 'components/ListTable/Filters/Filters';
import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { ListTable } from 'components/ListTable/ListTable';
import { TableHeader } from 'components/ListTable/types';
import { Page } from 'components/Page/Page';
import { TransactionStatusDisplay } from 'components/TransactionStatusDisplay/TransactionStatusDisplay';
import { useDebounce } from 'hooks/useDebounce';
import { useNewFilters } from 'hooks/useFilters';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnectedPartners, useCurrentPartner } from 'services/repositories/partners/partners';
import { useInfiniteTransactions } from 'services/repositories/transactions/transactions';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { addPartnersToFiltersConfig } from 'support/helpers/filters/filters';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { translationForDocumentType } from 'support/helpers/transactions/transactions';
import { TransactionDTO } from 'support/types';
import { PendingTransactions } from './PendingTransactions/PendingTransactions';
import { getDefaultFilterConfig } from './tableConfig';
import { TransactionFilterKey } from './types';

import { TransactionsTabs } from './TransactionsTabs/TransactionsTabs';

export function Transactions() {
  const { t } = useTranslation();
  const { data: currentPartner } = useCurrentPartner();
  const { data: connectedPartnersData } = useConnectedPartners();

  const filterConfig = useMemo(
    () => addPartnersToFiltersConfig(getDefaultFilterConfig(), TransactionFilterKey.partnerId, connectedPartnersData),
    [connectedPartnersData],
  );

  const { filters, filterValues, updateFilters, clearAllFilters, perPage } = useNewFilters(
    filterConfig,
    'transactions',
  );

  const hasFilters = filterValues && filterValues.length > 0;
  const debouncedFilterValues = useDebounce(filterValues, 150);
  const differentFilters = !isEqual(debouncedFilterValues, filterValues);

  const {
    data: transactionsData,
    isLoading: transactionsDataLoading,
    isFetchingNextPage,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
  } = useInfiniteTransactions({
    variables: {
      query: {
        filterValues: debouncedFilterValues,
        perPage,
      },
    },
  });

  const tableHeaders: Array<TableHeader> = [
    { label: t('transactions:list.tableHeaders.externalReference') },
    { label: t('transactions:list.tableHeaders.type') },
    { label: t('transactions:list.tableHeaders.tradePartner') },
    { label: t('transactions:list.tableHeaders.status') },
    { label: t('transactions:list.tableHeaders.flow') },
    { label: t('transactions:list.tableHeaders.date') },
  ];

  const buildRow = (transaction: TransactionDTO) => {
    const partner = currentPartner?.id !== transaction.sender?.id ? transaction.sender : transaction.receiver;
    const typeLocale = translationForDocumentType(t, transaction.documentType);

    return [
      {
        element: transaction.externalMessageRef?.length ? transaction.externalMessageRef : '–',
        key: `external-ref ${transaction.id ?? ''}`,
        className: 'text-gray-900 font-medium group-hover:text-procuros-green-600 ',
      },
      {
        element: transaction.documentType?.length ? typeLocale : '–',
        key: 'type',
      },
      {
        element: partner?.name ?? '–',
        key: 'partner-name',
        className: 'max-w-xs truncate',
      },
      {
        element: <TransactionStatusDisplay transaction={transaction} currentPartner={currentPartner} />,
        key: 'message-status',
      },
      {
        element: transaction.flow ? t(`common:flow.${transaction.flow}`) : t('common:flow.unknown'),
        key: 'flow',
      },
      {
        element: <span className="tabular-nums">{formatDayAndTime(transaction.createdAt)}</span>,
        key: 'created-at',
      },
    ];
  };

  const data = transactionsData?.pages?.flatMap((page) => page.items);
  const emptyData = data?.length === 0;

  return (
    <Page>
      <Page.Head title={t('common:mainMenu.transactions')} description={t('transactions:description')}>
        <div className="w-full">
          <TransactionsTabs selectedIndex={0} />

          <Filters
            propertyFilters={filters}
            onFiltersChange={updateFilters}
            filterValues={filterValues}
            clearAllFilters={clearAllFilters}
          />
        </div>
      </Page.Head>

      <Page.Section>
        <PendingTransactions />
        <ListTable<TransactionDTO>
          headers={tableHeaders}
          data={data}
          rowBuilder={buildRow}
          rowClickPath={(transaction) => routeToTransactionPage(transaction.internalType, transaction.id)}
          isLoading={transactionsDataLoading || (differentFilters && emptyData)}
          pagination={
            <InfiniteScroll
              hasMore={Boolean(hasNextPage)}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
              noMoreItemsEnabled={Boolean(hasPreviousPage)}
            />
          }
          emptyState={
            <ListTable.EmptyState
              background="gray"
              cols={tableHeaders.length}
              cta={
                hasFilters
                  ? {
                      children: t('common:table.withFilters.emptyState.cta'),
                      onClick: clearAllFilters,
                      analyticsId: 'transactions:clear_filters',
                    }
                  : undefined
              }
            >
              {hasFilters ? t('common:table.withFilters.emptyState.title') : t('transactions:list.emptyState')}
            </ListTable.EmptyState>
          }
        />
      </Page.Section>
    </Page>
  );
}

/**
 * i18n
 *
 * t('common:flow.LIVE')
 * t('common:flow.TEST')
 * t('common:flow.IDLE')
 * t('common:flow.unknown')
 */
