import { ReactNode } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type TimelineProps = {
  activities: Array<{
    id: string;
    date: string;
    content: ReactNode;
    extra?: ReactNode;
  }>;
};
export const Timeline = ({ activities }: TimelineProps) => {
  return (
    <ul className="space-y-6">
      {activities.map((activityItem, activityItemIdx, activities) => {
        const isFirst = activityItemIdx === 0;
        const isLast = activityItemIdx === activities.length - 1;
        const singleElement = activities.length === 1;
        return (
          <li key={activityItem.id} className="relative flex gap-x-4">
            {!singleElement ? (
              <div
                className={classNames(
                  isLast ? 'h-3' : undefined,
                  isFirst ? 'top-3' : undefined,
                  'absolute left-0 top-0 flex w-6 justify-center -bottom-6',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
            ) : null}
            <div className="relative flex size-6 flex-none items-center justify-center">
              <div className="size-[7px] rounded-full bg-gray-300" />
            </div>
            <div className="flex w-full flex-col gap-2">
              <div className="flex gap-x-4">
                <div className="flex-auto py-0.5 text-sm leading-5 text-gray-700">{activityItem.content}</div>
                <span data-testid="time" className="flex-none py-0.5 text-sm tabular-nums leading-5 text-gray-500">
                  {activityItem.date}
                </span>
              </div>
              <div className="flex-auto py-0.5 text-sm leading-5 text-gray-700 empty:hidden">{activityItem.extra}</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
