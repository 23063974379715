import { ui } from '@procuros/datachecks';
import { useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ColumnTypesInternal } from 'components/DataTable/Types';
import jsonata from 'jsonata';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { logError } from 'services/logging/logging';

export function getAvailableColumns(config: object): Map<string, boolean> {
  const availableColumns = new Map<string, boolean>();

  function addKeys(obj: Record<string, unknown>, parentKey = ''): void {
    if (typeof obj !== 'object' || obj === null) return;
    for (const key of Object.keys(obj)) {
      const fullPath = parentKey ? `${parentKey}.${key}` : key;
      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        addKeys(obj[key] as any, fullPath);
      } else {
        availableColumns.set(fullPath, true);
      }
    }
  }

  addKeys(config as Record<string, unknown>);

  return availableColumns;
}

export const usePinnedColumns = ({
  config,
  numberOfPinnedColumns,
  selectableRows,
  deletableRows,
  withIndexColumn,
}: {
  config: Array<ui.Field>;
  numberOfPinnedColumns: number | undefined;
  selectableRows?: boolean;
  deletableRows?: boolean;
  withIndexColumn?: boolean;
}) => {
  const pinnedColumns = useMemo(() => {
    const pinnedIds: Array<string> = [];
    if (numberOfPinnedColumns && numberOfPinnedColumns > 0) {
      if (selectableRows) pinnedIds.push(ColumnTypesInternal.procuros_select);
      if (deletableRows) pinnedIds.push(ColumnTypesInternal.procuros_delete);
      if (withIndexColumn) pinnedIds.push(ColumnTypesInternal.procuros_index);
    }

    if (!numberOfPinnedColumns) return pinnedIds;
    for (let i = 0; i < numberOfPinnedColumns && i < config.length; i++) {
      const path = config[i].path;
      if (path) {
        pinnedIds.push(path);
      }
    }

    return pinnedIds;
  }, [config, deletableRows, numberOfPinnedColumns, selectableRows, withIndexColumn]);

  return pinnedColumns;
};

export const isEnumerableField = (field: ui.Field): field is ui.EnumerableField => {
  return field.type === ui.FieldTypes.select || field.type === ui.FieldTypes.multi_select;
};

export const useEnumerableOptions = (config: ui.EnumerableField['type_config'] | undefined) => {
  const [options, setOptions] = useState(config && 'options' in config ? config.options : []);
  const [section, field] = useMemo(() => {
    const parts = config && 'options_lookup_field' in config ? config.options_lookup_field.split('.') : [''];
    return [parts[0], parts.slice(1).join('.')];
  }, [config]);

  const jsonataExpression = useMemo(() => {
    //the expression is used so we capture undefined values
    return field && jsonata(`$map($$, function ($v) {$exists($v.${field}) ? $v.${field} : ""})`);
  }, [field]);

  const data = useWatch({ name: section, disabled: !section });

  useEffect(() => {
    if (config && 'options' in config) {
      setOptions(config.options);
    }
  }, [config]);

  useEffect(() => {
    if (!config) return;
    if (jsonataExpression && !('options' in config)) {
      jsonataExpression
        .evaluate(data)
        .then((options) => {
          const optionsAsArray = Array.isArray(options) ? options : [options];
          const newOptions = optionsAsArray.map((option: string, index) => {
            const formattedIndex = new Intl.NumberFormat(navigator.language, { minimumIntegerDigits: 3 }).format(
              index + 1,
            );

            return {
              label: option ? option : String(formattedIndex),
              value: `$['${section}'][${index}]`,
            };
          });

          setOptions(newOptions);
        })
        .catch(logError);
    }
  }, [config, data, jsonataExpression, section]);

  return useDeferredValue(options);
};

export const useValue = (data: any, fieldId: string, expression: string | undefined) => {
  const { setValue: setTableValue } = useDataTableContext();
  const [value, setValue] = useState();

  const inputValue = useWatch({ name: fieldId, disabled: Boolean(expression) });
  const jsonataExpression = useMemo(() => {
    return expression && jsonata(expression);
  }, [expression]);

  useEffect(() => {
    if (!jsonataExpression) {
      return;
    }

    jsonataExpression
      .evaluate(data)
      .then((newValue: any) => {
        if (newValue !== value) {
          setValue(newValue);
          setTableValue(fieldId, newValue);
        }
      })
      .catch(logError);
  }, [jsonataExpression, inputValue, data, setTableValue, fieldId, value]);

  //we need to return the inputValue right away if there is no expression otherwise https://stackoverflow.com/questions/76366010/react-input-caret-jumps-to-end-if-value-gets-modified
  return jsonataExpression ? value : inputValue;
};

export const useDocumentCurrency = ({ disabled }: { disabled: boolean } = { disabled: false }) => {
  const currency = useWatch({ name: 'info.0.currency', disabled });
  return currency || 'EUR';
};
