import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { cloneElement, Fragment, ReactElement, useRef, ForwardedRef, useCallback, FocusEventHandler } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useFloating,
  useInteractions,
  useClick,
  offset,
  autoUpdate,
  autoPlacement,
  FloatingPortal,
} from '@floating-ui/react';
import { forwardRefWithGeneric } from 'utils/forwardRefWithGeneric';
import { classNames } from 'support/helpers/generic/generic';
import { useResizeObserver } from 'usehooks-ts';
import { ListboxHandlingOpenState } from './ListboxHandlingOpenState';

export type DropdownOptionType<TValue> = { value: TValue; label: string; testId?: string; disabled?: boolean };
type NativeSelectProps = Pick<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  'className' | 'defaultValue' | 'disabled' | 'id' | 'multiple' | 'name' | 'onBlur' | 'onFocus' | 'required'
>;

type OverrideOfNativeSelectProps<TValue> = {
  onChange?: (value: TValue) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: TValue;
};

type DropdownType<TValue> = {
  label?: string;
  emptySelectionLabel?: string;
  showSelection?: boolean;
  description?: string;
  documentFieldId?: string;
  dropdownDisplayTestId?: string;
  errors?: FieldError;
  hasErrors?: boolean;
  options?: Array<DropdownOptionType<TValue>>;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  wrapperClassName?: string;
  buttonClassName?: string;
  size?: 'base' | 'extra-large' | 'extra-small' | 'large' | 'small';
  onOpen?: () => void;
  onClose?: () => void;
} & NativeSelectProps &
  OverrideOfNativeSelectProps<TValue>;

function DropdownComponent<TValue>(
  {
    label,
    description,
    required,
    emptySelectionLabel,
    options = [],
    name,
    value,
    onChange,
    onFocus,
    onBlur,
    onOpen,
    onClose,
    errors,
    hasErrors,
    disabled,
    className,
    buttonClassName,
    wrapperClassName,
    id,
    showSelection = true,
    leftIcon,
    rightIcon,
    size = 'base',
    dropdownDisplayTestId,
  }: DropdownType<TValue>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { t } = useTranslation();
  const { refs, floatingStyles, context } = useFloating({
    strategy: 'fixed',
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(6),
      autoPlacement({
        allowedPlacements: ['top', 'bottom'],
      }),
    ],
  });

  const click = useClick(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({
    ref: wrapperRef,
    box: 'border-box',
  });

  const selectedOption = options.find((option) => option.value === value);

  const controlledValue = value ? value.toString() : '';

  const handleOnBlur: FocusEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      //Don't call onBlur if the focus is moving to the floating element
      if (e.relatedTarget && e.relatedTarget === refs.floating.current) {
        return;
      }
      onBlur?.();
    },
    [onBlur, refs.floating],
  );
  return (
    <div ref={wrapperRef} className={wrapperClassName} id={id}>
      <input
        ref={ref}
        type="text"
        value={controlledValue}
        name={name}
        aria-label={name}
        className="hidden"
        onChange={(e) => {
          onChange?.(e.target.value as unknown as TValue);
        }}
      />

      <Listbox value={controlledValue as TValue} onChange={onChange} disabled={disabled}>
        {({ open }) => {
          return (
            <ListboxHandlingOpenState open={open} onOpen={onOpen} onClose={onClose}>
              <div className={className ?? ''}>
                {label ? (
                  <Label className="mb-1 block truncate text-sm text-gray-500">
                    {t(label)}
                    {required && <span className="text-procuros-green-600">*</span>}
                  </Label>
                ) : null}

                <div className="relative flex">
                  <ListboxButton
                    className={classNames(
                      {
                        'border-gray-300 active:border-procuros-green-500 focus-visible:border-procuros-green-500':
                          !errors && !hasErrors,
                        'border-red-500': errors || hasErrors,
                        'focus-visible:ring-2 focus-visible:ring-red-500 focus:ring-2 focus:ring-red-500':
                          !open && (errors || hasErrors),
                        'ring-2 ring-red-500': open && (errors || hasErrors),
                        'border-procuros-green-500': open && !errors && !hasErrors,
                        'text-gray-500': !selectedOption,
                      },
                      'disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-300 overflow-hidden flex items-center relative flex-1 min-w-0 bg-white border shadow-sm text-left cursor-default focus-visible:outline-none active:outline-none active:ring-0 focus-visible:ring-0',
                      classes.button.size[size],
                      buttonClassName ?? 'rounded-md',
                    )}
                    data-name={name}
                    data-field={name}
                    ref={refs.setReference}
                    {...getReferenceProps()}
                    onBlur={handleOnBlur}
                    onFocus={onFocus}
                  >
                    {leftIcon &&
                      cloneElement(leftIcon, {
                        ...leftIcon.props,
                        className: classNames(classes.leftIcon.size[size], leftIcon.props.className),
                      })}
                    <span className="block truncate" data-testid={dropdownDisplayTestId}>
                      {!showSelection && emptySelectionLabel
                        ? emptySelectionLabel
                        : selectedOption?.label
                          ? t(selectedOption.label)
                          : emptySelectionLabel ?? t('components.select.pleaseChoose')}
                    </span>

                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      {rightIcon &&
                        cloneElement(rightIcon, {
                          ...rightIcon.props,
                          className: classNames(classes.rightIcon.size[size], rightIcon.props.className),
                        })}
                      <ChevronDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <FloatingPortal>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <ListboxOptions
                        as="ul"
                        static
                        className={classNames(
                          classes.list.size[size],
                          'w-full z-50 bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 overflow-auto focus-visible:outline-none',
                        )}
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, width: `${width}px` }}
                        {...getFloatingProps()}
                      >
                        {options.map((option) => (
                          <ListboxOption
                            as="li"
                            key={String(option.value)}
                            className={({ active, disabled }) =>
                              classNames(
                                active ? 'bg-procuros-green-500' : '',
                                'ml-0 cursor-default select-none relative py-2 px-2',
                                disabled ? 'text-gray-500' : 'text-black',
                                classes.listItem.size[size],
                              )
                            }
                            value={option.value}
                            disabled={option.disabled}
                            data-testid={option.testId}
                          >
                            {({ selected, focus }) => (
                              <span className="flex justify-between gap-2">
                                <span
                                  className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}
                                >
                                  {t(option.label)}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      focus ? 'text-white' : 'text-procuros-green-600',
                                      'flex items-center',
                                    )}
                                  >
                                    <CheckIcon className="size-4" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </span>
                            )}
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </Transition>
                  </FloatingPortal>
                </div>
                {Boolean(errors) && <p className="mt-2 text-sm text-red-600">{errors?.message}</p>}
                {Boolean(description) && <p className="mt-2 text-sm text-gray-500">{description}</p>}
              </div>
            </ListboxHandlingOpenState>
          );
        }}
      </Listbox>
    </div>
  );
}

export const Dropdown = forwardRefWithGeneric(DropdownComponent);

const classes = {
  button: {
    size: {
      'extra-small': 'pl-3 pr-8 py-1 text-xs rounded-3xl',
      small: 'pl-3 pr-8 py-2 text-sm leading-4 rounded-md',
      base: 'pl-4 pr-8 py-2 text-sm rounded-md',
      large: 'px-4 pr-8 py-2 text-base rounded-md',
      'extra-large': 'px-6 pr-8 py-3 text-base rounded-md',
    },
  },
  list: {
    size: {
      'extra-small': 'rounded-lg',
      small: 'rounded-md',
      base: 'rounded-md',
      large: 'rounded-md',
      'extra-large': 'rounded-md',
    },
  },
  listItem: {
    size: {
      'extra-small': 'pl-3 pr-4 py-1.5 text-xs',
      small: 'pl-3 pr-4 py-2 text-sm',
      base: 'pl-3 pr-4 py-2 text-sm',
      large: 'pl-3 pr-4 py-2 text-base',
      'extra-large': 'pl-3 pr-4 py-2 text-base',
    },
  },
  leftIcon: {
    size: {
      'extra-small': '-ml-0.5 mr-2 h-4 w-4',
      small: '-ml-0.5 mr-2 h-4 w-4',
      base: '-ml-1 mr-2 h-5 w-5',
      large: '-ml-1 mr-3 h-5 w-5',
      'extra-large': '-ml-1 mr-3 h-5 w-5',
    },
  },
  rightIcon: {
    size: {
      'extra-small': 'ml-2 h-4 w-4',
      small: 'ml-2 h-4 w-4',
      base: 'ml-2 h-5 w-5',
      large: 'ml-3 h-5 w-5',
      'extra-large': 'ml-3 h-5 w-5',
    },
  },
};
