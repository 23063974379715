import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import { Page } from 'components/Page/Page';

type ContactSoonProps = {
  title: string;
  description: string;
  ctaOptions: { ctaText: string; ctaAction: () => void };
};

export const ContactSoon = ({ title, description, ctaOptions: { ctaText, ctaAction } }: ContactSoonProps) => {
  return (
    <Page>
      <Page.Section>
        <div className="mt-14">
          <div className="mx-auto flex w-[600px] flex-col items-center justify-center gap-6 px-8 text-center">
            <div className="rounded-full bg-procuros-grey p-5">
              <ChatBubbleOvalLeftEllipsisIcon className="size-16" />
            </div>
            <h3 className="text-lg font-bold text-gray-900">{title}</h3>
            <p className="text-sm text-gray-500">{description}</p>
            <Button onClick={ctaAction} analyticsId="contact_soon_cta">
              {ctaText}
            </Button>
          </div>
        </div>
      </Page.Section>
    </Page>
  );
};
