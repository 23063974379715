import { classNames } from 'support/helpers/generic/generic';
import React, { forwardRef, useId } from 'react';
import { FieldError } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

type TextareaType = {
  name: string;
  label?: string;
  rows?: number;
  errors?: FieldError;
  hasErrors?: boolean;
  wrapperClassName?: string;
  required?: boolean;
  value?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLTextAreaElement>;

function TextareaComponent(
  {
    label,
    required,
    rows = 3,
    value,
    name,
    errors,
    hasErrors,
    wrapperClassName,
    className,
    ...otherProps
  }: TextareaType,
  ref: React.Ref<HTMLTextAreaElement>,
) {
  const textAreaId = useId();
  return (
    <div className={classNames(wrapperClassName, otherProps.disabled ? 'opacity-40' : undefined)}>
      {!!label && (
        <label htmlFor={textAreaId} className="block text-sm font-medium text-gray-700">
          {label}
          {!!required && <span className="text-procuros-green-500">*</span>}
        </label>
      )}

      <div className={classNames('relative rounded-md shadow-sm', label ? 'mt-1' : '')}>
        <textarea
          {...otherProps}
          ref={ref}
          name={name}
          id={textAreaId}
          className={classNames(
            'data-hj-allow shadow-sm block w-full sm:text-sm rounded-md focus-visible:outline-none box-border',
            !!errors || hasErrors
              ? 'pr-10 text-red-900 placeholder-red-300 border-red-300 focus-visible:ring-red-500 focus-visible:border-red-500 focus-visible:outline-none'
              : 'focus-visible:ring-procuros-green-500 focus-visible:border-procuros-green-500 border-gray-300',

            className,
          )}
          rows={rows}
          value={value}
        />

        {errors ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="size-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>
      {!!errors && <p className="mt-2 text-sm text-red-600">{errors.message}</p>}
    </div>
  );
}

export const Textarea = forwardRef(TextareaComponent);
