export const NestedLevelAddon = ({ level }: { level: number }) => {
  return (
    <div className="flex gap-4">
      {new Array(level - 1).fill(0).map((_, i) => {
        return <NestedLevelIcon key={i} />;
      })}
    </div>
  );
};

const NestedLevelIcon = () => {
  return <div className="h-4 w-px bg-gray-300" />;
};
