import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'support/helpers/analytics/analytics';

export default function AnalyticsPageView() {
  const location = useLocation();
  const { pageView } = useAnalytics();

  // Track pageviews
  useEffect(() => {
    pageView();
  }, [location, pageView]);

  return null;
}
