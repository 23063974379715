import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FilterValue, PropertyFilter } from 'components/ListTable/types';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import { useSessionStorage } from 'usehooks-ts';

export const getFilterConfig = (
  defaultFilterConfig: Array<PropertyFilter>,
  searchParams: URLSearchParams,
): Array<PropertyFilter> =>
  defaultFilterConfig.map((propertyFilter: PropertyFilter) => {
    const searchParam = searchParams.get(`filter[${propertyFilter.key}]`);

    return {
      ...propertyFilter,
      ...(searchParam && { value: searchParam }),
    };
  });

export const getFilterValues = (filterConfig: Array<PropertyFilter>): Array<FilterValue> =>
  filterConfig.filter((filter) => filter.value.length).map((filter) => ({ key: filter.key, value: filter.value }));

export const useNewFilters = (defaultFilterConfig: Array<PropertyFilter>, key: string) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useSessionStorage<Array<FilterValue>>(
    key,
    getFilterValues(getFilterConfig(defaultFilterConfig, searchParams)),
  );

  const currentCursor = useMemo<string | null>(() => searchParams.get('cursor'), [searchParams]);
  const currentPerPage = useMemo<string | null>(() => searchParams.get('perPage') ?? null, [searchParams]);

  const searchParamsValue = searchParams.toString();

  useEffect(() => {
    setFilterValues(getFilterValues(getFilterConfig(defaultFilterConfig, searchParams)));
  }, [defaultFilterConfig, searchParamsValue, setFilterValues]);

  const updateUrlParams = useCallback(
    (filterValues: Array<FilterValue>, cursor: string | null, perPage: string | null) => {
      const params = buildFilterUrl({ filterValues, cursor, perPage });
      const newUrl = `${window.location.pathname}${params ?? ''}`;

      navigate(newUrl);
    },
    [navigate],
  );

  useEffect(() => {
    if (!searchParams.toString().includes('filter')) {
      updateUrlParams(filterValues, null, currentPerPage);
    }
  }, [filterValues, currentPerPage, updateUrlParams, searchParams]);

  const filters = useMemo(
    () => getFilterConfig(defaultFilterConfig, searchParams),
    [defaultFilterConfig, searchParams],
  );

  const setCursor = useCallback(
    (cursor: string | null) => {
      updateUrlParams(filterValues, cursor, currentPerPage);
    },
    [filterValues, currentPerPage, updateUrlParams],
  );

  const setPerPage = useCallback(
    (perPage: string | null) => {
      // Resetting cursor when perPage is updated
      updateUrlParams(filterValues, null, perPage);
    },
    [filterValues, updateUrlParams],
  );

  const updateFilter = useCallback(
    (filterKey: string, newValue: string) => {
      const updatedFilters = filters.map((filter) =>
        filter.key === filterKey
          ? {
              ...filter,
              value: newValue,
            }
          : filter,
      );

      // Resetting cursor when a filter is updated
      setCursor(null);
      updateUrlParams(getFilterValues(updatedFilters), currentCursor, currentPerPage);
      setFilterValues(getFilterValues(updatedFilters));
    },
    [filters, setCursor, updateUrlParams, currentCursor, currentPerPage, setFilterValues],
  );

  const updateFilters = useCallback(
    (filters: Record<string, string>) => {
      const updatedFilters = Object.entries(filters)
        .map(([filterKey, filterValue]) =>
          filterValue
            ? {
                key: filterKey,
                value: filterValue,
              }
            : undefined,
        )
        .filter(Boolean) as Array<PropertyFilter>;

      // Resetting cursor when filters are updated
      setCursor(null);
      updateUrlParams(getFilterValues(updatedFilters), currentCursor, currentPerPage);
      setFilterValues(getFilterValues(updatedFilters));
    },
    [setCursor, updateUrlParams, currentCursor, currentPerPage, setFilterValues],
  );

  const clearAllFilters = useCallback(() => {
    const updatedFilters = defaultFilterConfig;

    // Resetting cursor when filters are updated
    setCursor(null);
    const filterValues = getFilterValues(updatedFilters);
    setFilterValues(filterValues);
    updateUrlParams(filterValues, currentCursor, currentPerPage);
  }, [filters, setCursor, setFilterValues, updateUrlParams, currentCursor, currentPerPage]);

  return {
    filters,
    filterValues,
    updateFilter,
    updateFilters,
    clearAllFilters,
    cursor: currentCursor,
    setCursor,
    perPage: currentPerPage,
    setPerPage,
  };
};
