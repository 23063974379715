import { Badge } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { ListTable } from 'components/ListTable/ListTable';
import { Page } from 'components/Page/Page';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntegration } from 'services/repositories/integrations/integrations';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { IntegrationsRoutes, IntegrationProcessRoutes, IntegrationConfigurationSteps } from 'support/types';
import { getIntegrationStatusBadgeColor } from '../helpers';
import { useCallback, useEffect, useMemo } from 'react';
import {
  IntegrationProcess,
  generateTableRowPath,
  getProcessStatusBadgeColor,
  getProcessStatusBadgeText,
  useIntegrationWithProcessesData,
} from './helpers';
import { Placeholder } from 'components/Placeholder/Placeholder';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useCurrentUser } from 'services/repositories/user/user';
import { TypeBadge } from './components/DirectionBadge/TypeBadge';

export const ViewIntegration = () => {
  const { id } = useParams();
  const integrationId = id;
  const { data: currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: integration, isLoading: isLoadingIntegration } = useIntegration({ variables: { id: integrationId } });
  const { data, isLoading } = useIntegrationWithProcessesData({ integrationId });
  const processTableHeaders = useMemo(
    () => [
      { label: t('integrations:processes.headers.documentType') },
      { label: t('integrations:processes.headers.standard') },
      { label: t('integrations:processes.headers.type') },
      { label: t('integrations:processes.headers.status') },
    ],
    [t],
  );

  /*
    t('common:messageTypes.plural.QUOTE')
    t('common:messageTypes.plural.QUOTE_REQUEST')
    t('common:messageTypes.plural.ORDER')
    t('common:messageTypes.plural.ORDER_CHANGE')
    t('common:messageTypes.plural.ORDER_RESPONSE')
    t('common:messageTypes.plural.SHIPPING_NOTICE')
    t('common:messageTypes.plural.DISPATCH_INSTRUCTION')
    t('common:messageTypes.plural.DISPATCH_INSTRUCTION_RESPONSE')
    t('common:messageTypes.plural.INVOICE')
    t('common:messageTypes.plural.CREDIT_NOTE')
    t('common:messageTypes.plural.PRODUCT_CATALOG')
    t('common:messageTypes.plural.RECEIVAL_NOTICE')
    t('common:messageTypes.plural.REMITTANCE_ADVICE')
    t('common:messageTypes.plural.UNKNOWN')

   */
  const processTableRowBuilder = useCallback(
    (process: IntegrationProcess) => [
      { key: `${process.id}-documentType`, element: t(`common:messageTypes.plural.${process.messageType}`) },
      { key: `${process.id}-standard`, element: process.standard },
      { key: `${process.id}-direction`, element: <TypeBadge type={process.type} /> },
      {
        key: `${process.id}-status`,
        element: (
          <Badge color={getProcessStatusBadgeColor(process.status)}>
            {t(getProcessStatusBadgeText(process.status))}
          </Badge>
        ),
      },
    ],
    [t],
  );

  useEffect(() => {
    if (integration && !integration?.connectionEstablishedAt) {
      navigate(
        routeToPage(IntegrationsRoutes.edit, {
          id: integration.id,
          step: integration.hasValidConfiguration
            ? IntegrationConfigurationSteps.TEST
            : IntegrationConfigurationSteps.CONFIGURATION,
        }),
        { replace: true },
      );
    }
  }, [integration, navigate]);

  if (!integrationId) {
    return null;
  }

  return (
    <Page isLoading={isLoadingIntegration}>
      <Page.Head>
        <>
          <div>
            <div className="flex items-center gap-2">
              <Page.Title>{integration?.connector}</Page.Title>
              <Badge color={getIntegrationStatusBadgeColor(integration?.state)}>
                {t(`integrations:list.status.${integration?.state}`)}
              </Badge>
            </div>
            <Page.Description>{t('integrations:view.description')}</Page.Description>
          </div>
          <div className="flex items-center gap-2">
            <Button
              onClick={() => navigate(routeToPage(IntegrationProcessRoutes.new, { id: integrationId }))}
              analyticsId="integrations:view-navigate"
            >
              {t('integrations:view.header.actions.newProcess')}
            </Button>
          </div>
        </>
      </Page.Head>
      <Page.Section>
        {data.length || isLoading ? (
          <ListTable<IntegrationProcess>
            data={data}
            headers={processTableHeaders}
            isLoading={isLoading}
            rowBuilder={processTableRowBuilder}
            rowClickPath={generateTableRowPath(integrationId, currentUser)}
          />
        ) : (
          <Placeholder
            title={t('integrations:view.emptyState.title')}
            icon={<ClipboardDocumentCheckIcon />}
            ctaText={t('integrations:view.emptyState.ctaText')}
            ctaOnClick={() => navigate(routeToPage(IntegrationProcessRoutes.new, { id: integrationId }))}
          />
        )}
      </Page.Section>
    </Page>
  );
};
