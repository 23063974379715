import classNames from 'classnames';
import { ReactNode, forwardRef, Ref } from 'react';

type TrailingAddOnProps = {
  trailingAddOn?: ReactNode;
  hasError?: boolean;
  show: boolean;
};

export const TrailingAddOn = forwardRef(
  ({ trailingAddOn, hasError, show }: TrailingAddOnProps, ref: Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={show ? 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3' : 'size-0'}
      >
        {show && trailingAddOn ? (
          <span className={classNames({ 'text-gray-500': !hasError }, 'group-focus-within:text-gray-500 sm:text-sm')}>
            {trailingAddOn}
          </span>
        ) : null}
      </div>
    );
  },
);

TrailingAddOn.displayName = 'TrailingAddOn';
