/*
 * We need this function to parse a string to number .
 * This is needed because we need to parse the value of the input field to a number when not using the register function of react-hook-form.
 * Our types says that a field needs to be a number, but inputs return a string.
 */
export const getValidNumberValue = (value: string): number => {
  const newValue = parseFloat(value);
  const isValidNumber = isNaN(newValue) === false;
  return isValidNumber ? newValue : NaN;
};
