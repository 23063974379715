import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios';
import { QueryClient } from '@tanstack/react-query';
import { ENV } from 'services/environment/environment';
import { setPotentiallyAuthenticated } from 'services/auth/auth';
import { SessionStorageService } from 'services/sessionStorage/sessionStorage';
import { readCookie } from 'support/helpers/cookies/cookies';
import {
  BadCallError,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  BackendError,
  ValidationError,
  ConflictError,
} from 'support/helpers/errors/errors';
import { isLocal } from 'support/helpers/generic/generic';
import posthog from 'posthog-js';

export type ValidationErrors = Record<string, Array<string>>;

type ValidationErrorResponse = {
  message: string;
  errors: ValidationErrors;
};

type GenericErrorResponse = {
  message: string;
};

type ErrorResponseType = ValidationErrorResponse | GenericErrorResponse;

const isValidationErrorResponse = (response: ErrorResponseType): response is ValidationErrorResponse => {
  return (response as ValidationErrorResponse).errors !== undefined;
};

let csrfCookieRefreshPromise: Promise<AxiosResponse> | null = null;
export const BASE_URL = ENV.API_BASE_URL ?? '/';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
});

const fetchNewCsrfToken = async () => {
  // Start new CSRF token request if not already running
  if (!csrfCookieRefreshPromise) {
    csrfCookieRefreshPromise = instance.get('/sanctum/csrf-cookie');
  }

  // Await running CSRF token request
  try {
    await csrfCookieRefreshPromise;
  } finally {
    // Once done, reset promise placeholder
    csrfCookieRefreshPromise = null;
  }

  return readCookie('XSRF-TOKEN');
};

const getCsrfToken = async () => {
  let csrfToken = readCookie('XSRF-TOKEN');

  // Refresh token if not existing or expired
  if (!csrfToken) {
    csrfToken = await fetchNewCsrfToken();
  }

  return csrfToken;
};

// Handle CSRF token
instance.interceptors.request.use(async (config) => {
  if (config.method === 'post' || config.method === 'put' || config.method === 'patch' || config.method === 'delete') {
    const csrfToken = await getCsrfToken();

    if (config.baseURL === BASE_URL) {
      config.headers['X-Xsrf-Token'] = csrfToken;
    }
  }

  return config;
});

// Handle error responses
instance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    // Try fetching new csrf token if the current one is expired
    if (error.response?.status === 419) {
      const newCsrfToken = await fetchNewCsrfToken();
      if (!newCsrfToken) {
        return Promise.reject(error);
      }
      return axios(error.response?.config as AxiosRequestConfig);
    }

    // Logout user if the token is invalid
    if (error.response?.status === 401) {
      setPotentiallyAuthenticated(false);
      SessionStorageService.clear();
    }

    // Handle other errors
    return Promise.reject(error);
  },
);

// Handle API request tracking
instance.interceptors.response.use(
  (response) => {
    // Track all non-get requests
    if (response.config.method !== 'get') {
      posthog.capture('portal_api_call', {
        status: response.status,
        url: response.config.url,
        method: response.config.method,
      });
    }
    return response;
  },
  async (error: AxiosError) => {
    if (!!error.response && !!error.config && error.response?.status !== 401 && error.response?.status !== 419) {
      posthog.capture('portal_api_call', {
        status: error.response.status,
        url: error.config.url,
        method: error.config.method,
      });
    }

    // Handle other errors
    return Promise.reject(error);
  },
);

export const axiosInstance = instance;

export const fetchData = async <ResponseType>(
  url: string,
  options?: { method?: 'get' | 'post' | 'put' | 'patch' | 'delete'; data?: any; config?: AxiosRequestConfig },
) => {
  try {
    const response = await instance.request({
      url,
      method: options?.method || 'get',
      ...(options?.data && ['post', 'put', 'patch'].includes(options?.method || 'get') && { data: options.data }),
      ...(options?.config && options.config),
    });

    return {
      status: response.status,
      data: response.data as ResponseType,
      headers: response.headers,
    };
  } catch (error) {
    if (!isAxiosError<ErrorResponseType>(error)) {
      throw error;
    }

    if (!error.response) {
      throw new Error('No response');
    }

    if (error.response.status === 400) {
      throw new BadCallError((error.response?.data?.message as string) || 'Bad Call');
    }

    if (error.response.status === 401) {
      throw new UnauthorizedError((error.response?.data?.message as string) || 'Unauthorised');
    }

    if (error.response.status === 403) {
      throw new ForbiddenError((error.response?.data?.message as string) || 'Forbidden');
    }

    if (error.response.status === 404) {
      throw new NotFoundError((error.response?.data?.message as string) || 'Not Found');
    }

    if (error.response.status === 409) {
      throw new ConflictError((error.response?.data?.message as string) || 'Conflict');
    }

    if (error.response.status === 422) {
      const errors = isValidationErrorResponse(error.response?.data) ? error.response?.data?.errors : {};
      throw new ValidationError((error.response?.data?.message as string) || 'Validation Error', errors);
    }

    throw new BackendError((error.response?.data?.message as string) || 'Unknown Error', error.response.status);
  }
};

export const download = async (url: string) => fetchData<Blob>(url, { config: { responseType: 'blob' } });

export const addHostToUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  }

  return `${window.location.protocol}//${window.location.host}${url}`;
};

export const downloadFile = async (downloadUrl: string, options?: { contentType?: string; filename?: string }) => {
  const { data } = await download(downloadUrl);
  const downloadFile = data.slice(0, data.size, options?.contentType);
  const url = window.URL.createObjectURL(downloadFile);
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', options?.filename ?? (downloadUrl.split('/').pop() || 'download'));
  link.click();
  window.URL.revokeObjectURL(url);
  link.parentNode?.removeChild(link);
};

export const createData = async <ResponseType, BodyType = any>(url: string, data?: BodyType) =>
  fetchData<ResponseType>(url, {
    method: 'post',
    data,
  });

export const updateData = async <ResponseType>(url: string, data?: any) =>
  fetchData<ResponseType>(url, {
    method: 'put',
    data,
  });

export const patchData = async <ResponseType>(url: string, data?: any) =>
  fetchData<ResponseType>(url, {
    method: 'patch',
    data,
  });

export const removeData = async <ResponseType>(url: string) =>
  fetchData<ResponseType>(url, {
    method: 'delete',
  });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: isLocal() ? 'always' : 'online',
      refetchOnWindowFocus: false, // Not sure how this would play with our forms
      refetchOnReconnect: false, // Not sure how this would play with our forms
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
    mutations: {
      networkMode: isLocal() ? 'always' : 'online',
    },
  },
});
