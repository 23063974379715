import { ArrowPathIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { Section } from 'components/Display/Section/Section';
import {
  SlideOver,
  SlideOverContentSkeleton,
  SlideOverSubtitleSkeleton,
  SlideOverTitleSkeleton,
} from 'components/Display/SlideOver/SlideOver';
import { Divider } from 'components/Divider/Divider';
import { Button } from 'components/Form/Button/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input/Input/Input';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { useTranslation } from 'react-i18next';
import {
  useRecoverTransaction,
  useShareTransactionError,
  useTransaction,
} from 'services/repositories/transactions/transactions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { NotificationType, addNotification } from 'stores/notifications/notifications';
import { ConnectionsRoutes } from 'support/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useRelationship } from 'services/repositories/relationships/relationships';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { TransactionBlockingError } from 'components/TransactionBlockingError/TransactionBlockingError';
import { useTimeout } from 'usehooks-ts';
import { ReactNode, useEffect, useState } from 'react';
import { DEFAULT_REFETCH_INTERVAL } from 'support/helpers/const/const';
import { RequestErrorFallback } from 'components/RequestErrorFallback/RequestErrorFallback';

export const ConnectionsTransactionErrorSlideOver = ({ open, children }: { open: boolean; children?: ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { relationshipId, transactionId } = useParams<{
    relationshipId: string;
    transactionId: string;
  }>();

  const { data: relationship, isLoading: isRelationshipLoading } = useRelationship({
    variables: {
      relationshipId,
    },
    keepPreviousData: true,
    enabled: Boolean(relationshipId),
  });

  const {
    data: transaction,
    isLoading: isLoadingMessage,
    refetch: refetchMessage,
    errorUpdatedAt: isMessageError,
  } = useTransaction({
    variables: { transactionId },
  });
  const [recoverSubmitted, setRecoverSubmitted] = useState(false);

  const { mutate: recoverTransaction, isLoading: isRecoveringTransaction } = useRecoverTransaction();
  const { mutate: shareTransactionError, isLoading: isSharingError } = useShareTransactionError();
  const { register, handleSubmit } = useForm<{ email: string }>({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .label(t('connections:connectionsTest.nonWebEDI.errors.share.email.placeholder'))
          .email()
          .required(
            t('common:form.required', {
              field: t('connections:connectionsTest.nonWebEDI.errors.share.email.placeholder'),
            }),
          ),
      }),
    ),
  });

  useTimeout(
    () => {
      if (!relationshipId || !transaction) return;
      if (transaction?.transaction?.blockingError) {
        addNotification(t('connections:connectionsTest.nonWebEDI.errors.resendDocument.error'), NotificationType.error);
      }
      setRecoverSubmitted(false);
    },
    recoverSubmitted ? DEFAULT_REFETCH_INTERVAL : null,
  );

  useEffect(() => {
    if (transaction && !transaction.transaction?.blockingError && relationshipId) {
      addNotification(t('connections:connectionsTest.nonWebEDI.errors.share.success'), NotificationType.success);
      navigate(routeToPage(ConnectionsRoutes.relationshipDetails, { relationshipId }), { replace: true });
    }
  }, [transaction, recoverSubmitted, relationshipId, t, navigate]);

  if (!relationshipId) {
    return null;
  }

  const retrySendMessage = () => {
    recoverTransaction(
      { id: transaction?.transaction.id as string },
      {
        onSuccess: () => {
          setRecoverSubmitted(true);
        },
      },
    );
  };

  const shareError = async ({ email }: { email: string }) => {
    shareTransactionError(
      { id: transaction?.transaction.id ?? '', email },
      {
        onSuccess: () => {
          addNotification(t('connections:connectionsTest.nonWebEDI.errors.share.success'), NotificationType.success);
          navigate(routeToPage(ConnectionsRoutes.relationshipDetails, { relationshipId }));
        },
        onError: () => {
          addNotification(t('connections:connectionsTest.nonWebEDI.errors.share.error'), NotificationType.error);
        },
      },
    );
  };
  const onClose = () => {
    navigate(routeToPage(ConnectionsRoutes.root));
  };

  const onBack = () => {
    navigate(routeToPage(ConnectionsRoutes.testRelationship, { relationshipId }));
  };

  const otherPartner = relationship?.receiver_partner;
  const isLoading = (isLoadingMessage || isRelationshipLoading || isRecoveringTransaction) && !isMessageError;
  const data = transaction?.transaction;
  const canShareError = data?.blockingError?.type === 'DATA';
  return (
    <SlideOver
      open={open}
      title={isLoading ? <SlideOverTitleSkeleton /> : otherPartner?.name}
      size="large"
      subtitle={
        isLoading ? (
          <SlideOverSubtitleSkeleton />
        ) : (
          `${t('connections:connectionsTest.drawer.subtitle', {
            documentType: t(`common:messageTypes.plural.${relationship?.message_type}`),
          })} - ${data?.externalMessageRef || t('connections:connectionsTest.drawer.unknownDocumentType')}`
        )
      }
      headerImage={<PartnerLogo imageUrl={otherPartner?.logo_url} size="large" isLoading={isLoading} />}
      onBack={onBack}
      setOpen={onClose}
    >
      {isLoading ? (
        <SlideOverContentSkeleton />
      ) : !data ? (
        <RequestErrorFallback onCtaClick={refetchMessage} isLoading={isLoadingMessage} />
      ) : (
        <div className="flex flex-col gap-6">
          <Section.Vertical>
            <div className="flex items-end justify-between gap-4">
              <div className="space-y-2">
                <Section.Title variant="small">{t('connections:connectionsTest.nonWebEDI.errors.title')}</Section.Title>
                <Section.Subtitle>{t('connections:connectionsTest.nonWebEDI.errors.subTitle')}</Section.Subtitle>
              </div>
              {data?.customerCanRetry ? (
                <Button
                  loading={recoverSubmitted}
                  LeftIcon={ArrowPathIcon}
                  variant="secondary"
                  size="small"
                  onClick={() => retrySendMessage()}
                  analyticsId="testing-flow:document_delivery_retry"
                >
                  {t('connections:connectionsTest.nonWebEDI.errors.resendDocument.cta')}
                </Button>
              ) : null}
            </div>
            {data ? <TransactionBlockingError transaction={data} /> : null}
          </Section.Vertical>
          {canShareError ? (
            <>
              <Divider />
              <Form className="flex flex-col gap-3 rounded-md bg-gray-50 p-4" onSubmit={handleSubmit(shareError)}>
                <span className="text-sm font-semibold text-gray-700">
                  {t('connections:connectionsTest.nonWebEDI.errors.share.title')}
                </span>
                <div className="flex w-full gap-3">
                  <Input
                    placeholder={t('connections:connectionsTest.nonWebEDI.errors.share.email.placeholder')}
                    wrapperClassName="w-full"
                    {...register('email')}
                  />
                  <Button
                    LeftIcon={EnvelopeIcon}
                    variant="secondary"
                    size="small"
                    type="submit"
                    loading={isSharingError}
                    analyticsId="testing-flow:error_share"
                  >
                    {t('connections:connectionsTest.nonWebEDI.errors.share.submit')}
                  </Button>
                </div>
              </Form>
            </>
          ) : null}
        </div>
      )}
      {children}
    </SlideOver>
  );
};
