import { BookOpenIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { DOCUMENTATION_LINK } from 'support/helpers/const/const';

export const OnboardingDocumentation = () => {
  const { t } = useTranslation();
  return (
    <article className="flex items-center justify-between rounded-md bg-gray-50 p-4">
      <div className="flex items-start gap-3">
        <BookOpenIcon className="mt-0.25 size-5 text-gray-500" aria-hidden="true" />
        <div className="flex flex-col gap-2">
          <p className="text-sm font-bold text-gray-900">{t('onboarding:integration_steps.documentation.title')}</p>
          <p className="text-sm font-normal text-gray-500">
            {t('onboarding:integration_steps.documentation.description')}
          </p>
        </div>
      </div>
      <a href={DOCUMENTATION_LINK} target="_blank" rel="noreferrer">
        <Button
          variant="secondary"
          RightIcon={ArrowTopRightOnSquareIcon}
          size="small"
          analyticsId="onboarding:external_docs_open"
        >
          {t('onboarding:integration_steps.documentation.cta')}
        </Button>
      </a>
    </article>
  );
};
