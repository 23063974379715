import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { LoadingLogo } from 'components/Loading/LoadingLogo';
import React, { forwardRef, ComponentPropsWithoutRef } from 'react';
import { AnalyticsProps, buildAnalyticsAttributes } from 'support/helpers/analytics/analytics';
import { classNames } from 'support/helpers/generic/generic';

export type ButtonType = {
  type?: 'submit' | 'button' | 'reset';
  variant?: 'primary' | 'secondary' | 'minimal' | 'minimal-emphasized' | 'text' | 'secondary-danger' | 'minimal-danger';
  size?: 'extra-small' | 'small' | 'base' | 'large' | 'extra-large';
  LeftIcon?: React.ComponentType<any>;
  RightIcon?: React.ComponentType<any>;
  iconOnly?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  pressed?: boolean;
  grouped?: boolean;
  testId?: string;
} & ComponentPropsWithoutRef<'button'> &
  AnalyticsProps;

const classes = {
  button: {
    variant: {
      primary:
        'border border-transparent shadow-sm text-white bg-black hover:bg-procuros-green-999 hover:text-procuros-green-300 active:bg-procuros-green-999 active:text-procuros-green-600 disabled:text-gray-300 disabled:border-gray-100 disabled:shadown-none disabled:bg-gray-100',
      secondary:
        'border border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-procuros-green-50 hover:text-procuros-green-500 active:bg-procuros-green-50 active:text-procuros-green-900 disabled:text-gray-300 disabled:border-gray-100 disabled:shadown-none disabled:bg-gray-100',
      minimal:
        'shadown-none text-gray-700 hover:bg-procuros-green-50 hover:text-procuros-green-600 active:bg-procuros-green-50 active:text-procuros-green-900 disabled:bg-transparent disabled:text-gray-300',
      'minimal-emphasized':
        'shadow-none text-procuros-green-600 hover:text-procuros-green-500 hover:bg-procuros-green-50 active:bg-procuros-green-50 active:text-procuros-green-900  disabled:bg-transparent disabled:text-gray-300',
      'minimal-danger':
        'shadow-none text-red-600 hover:text-red-500 hover:bg-red-50 active:bg-red-50 active:text-red-900 disabled:bg-transparent disabled:text-gray-300',
      text: 'text-procuros-green-600 hover:text-procuros-green-400 !p-0',
      'secondary-danger':
        'border border-red-300 shadow-sm text-red-700 bg-white hover:bg-red-50 hover:text-red-600 active:bg-red-50 active:text-red-900 disabled:text-gray-300 disabled:border-gray-100 disabled:shadown-none disabled:bg-gray-100',
    },
    pressed: {
      primary: '!bg-procuros-green-999 text-procuros-green-300',
      secondary: '!bg-procuros-green-50 text-procuros-green-500',
      minimal: '!bg-procuros-green-50 text-procuros-green-600',
      'minimal-emphasized': '!bg-procuros-green-50 text-procuros-green-500',
      'minimal-danger': '!bg-procuros-red-50 text-red-500',
      text: 'text-procuros-green-400',
      'secondary-danger': '!bg-red-50 text-red-600',
    },
    size: {
      'extra-small': 'px-2.5 py-1.5 text-xs rounded',
      small: 'px-3 py-2 text-sm leading-4 rounded-md',
      base: 'px-4 py-2 text-sm rounded-md',
      large: 'px-4 py-2 text-base rounded-md',
      'extra-large': 'px-6 py-3 text-base rounded-md',
    },
    loading: '!text-transparent',
  },
  leftIcon: {
    size: {
      'extra-small': '-ml-0.5 mr-2 h-4 w-4',
      small: '-ml-0.5 mr-2 h-4 w-4',
      base: '-ml-1 mr-2 h-5 w-5',
      large: '-ml-1 mr-3 h-5 w-5',
      'extra-large': '-ml-1 mr-3 h-5 w-5',
    },
  },
  rightIcon: {
    size: {
      'extra-small': 'ml-2 -mr-0.5 h-4 w-4',
      small: 'ml-2 -mr-0.5 h-4 w-4',
      base: 'ml-2 -mr-1 h-5 w-5',
      large: 'ml-3 -mr-1 h-5 w-5',
      'extra-large': 'ml-3 -mr-1 h-5 w-5',
    },
  },
};

const Button = forwardRef(function Button(
  {
    type = 'button',
    variant = 'primary',
    size = 'base',
    iconOnly,
    LeftIcon,
    RightIcon,
    onClick,
    loading,
    fullWidth,
    className,
    disabled,
    children,
    testId,
    pressed,
    grouped,
    analyticsId,
    analyticsProperties,
    ...otherProps
  }: ButtonType,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Tooltip placement="bottom">
      <TooltipTrigger>
        <button
          {...otherProps}
          {...buildAnalyticsAttributes({ analyticsId, analyticsProperties })}
          disabled={disabled || loading}
          type={type}
          onClick={onClick}
          className={classNames(
            fullWidth ? 'w-full flex justify-center' : 'w-fit inline-flex',
            classes.button.variant[variant],
            classes.button.size[size],
            'items-center font-medium focus-visible:outline-none relative min-w-max',
            pressed ? classes.button.pressed[variant] : '',
            grouped
              ? 'rounded-none first:rounded-l-md last:rounded-r-md -ml-px first:ml-0'
              : ' focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-procuros-green-400',
            loading ? classes.button.loading : '',
            className ?? '',
          )}
          data-testid={testId}
          ref={ref}
        >
          {!!LeftIcon && (
            <LeftIcon
              className={classNames(classes.leftIcon.size[size], iconOnly ? '!ml-0 !mr-0' : '')}
              aria-hidden="true"
            />
          )}
          {iconOnly ? null : children}
          {!!RightIcon && (
            <RightIcon
              className={classNames(classes.rightIcon.size[size], iconOnly ? '!ml-0 !mr-0' : '')}
              aria-hidden="true"
            />
          )}
          {loading && (
            <span className="absolute left-1/2 top-1/2 size-5 origin-center -translate-x-1/2 -translate-y-1/2 text-gray-300">
              <LoadingLogo />
            </span>
          )}
        </button>
      </TooltipTrigger>
      {iconOnly && children ? <TooltipContent>{children}</TooltipContent> : null}
    </Tooltip>
  );
});

export { Button };
