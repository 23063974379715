import { useTranslation } from 'react-i18next';
import { formatTimestamp } from 'support/helpers/dateTime/dateTime';
import { DocumentType } from 'support/types';
import { SummaryAccordion } from '../SummaryAccordion/SummaryAccordion';
import { useShippingNoticeSummary } from '../hooks/useShippingNoticeSummary';

export const CreateShippingNoticeSummary = ({ documentType }: { documentType: DocumentType.shippingNotice }) => {
  const { t } = useTranslation();
  const shippingNoticeSummary = useShippingNoticeSummary();
  return (
    <SummaryAccordion
      header={{
        label: t('webedi:documentSummary.title', {
          documentType: t(`common:messageTypes.singular.${documentType}`),
        }),
        value: Math.round(shippingNoticeSummary.totalQuantity),
        valueLabel: t('webedi:documentSummary.shippingNotice.totalQuantity.label'),
      }}
      bodyItems={[
        {
          label: t('webedi:documentSummary.shippingNotice.deliveryDate.label'),
          value: formatTimestamp(shippingNoticeSummary.deliveryDate) ?? '—',
        },
        {
          label: t('webedi:documentSummary.shippingNotice.transportUnitCount.label'),
          value: Math.round(shippingNoticeSummary.transportUnitCount),
        },
        {
          label: t('webedi:documentSummary.shippingNotice.lineItemCount.label'),
          value: Math.round(shippingNoticeSummary.lineItemCount),
        },
      ]}
      footer={{
        label: t('webedi:documentSummary.shippingNotice.totalQuantity.label'),
        value: Math.round(shippingNoticeSummary.totalQuantity),
      }}
    />
  );
};
