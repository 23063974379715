import { Checkbox } from '../../Form/Checkbox/Checkbox';

export type Props = {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SelectionCell = ({ checked, disabled, indeterminate, onChange }: Props) => {
  return (
    <div className="w-full">
      <div className="flex justify-center px-3">
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className="items-center"
        />
      </div>
    </div>
  );
};
