import axios from 'axios';
import { logError } from 'services/logging/logging';

const CHECK_INTERVAL = 15000; // 15 seconds

export let isNewVersionAvailable = false;
let currentVersion: string | null = null;
const checkForUpdate = async () => {
  try {
    const response = await axios.get<string>('/version.txt', {
      headers: { 'Cache-Control': 'no-cache' },
    });

    const latestVersion = response.data;
    if (!currentVersion) {
      currentVersion = latestVersion;
      return;
    }

    if (latestVersion !== currentVersion) {
      isNewVersionAvailable = true;
    }
  } catch (error) {
    logError(error);
  }
};

export const startVersionCheck = () => {
  isNewVersionAvailable = false;
  setInterval(checkForUpdate, CHECK_INTERVAL);
};
