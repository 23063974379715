import { useEffect } from 'react';
import { Page } from 'components/Page/Page';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IntegrationProcessTestStep } from './components/IntegrationProcessTestStep/IntegrationProcessTestStep';
import { useIntegrationProcess } from 'services/repositories/integrationProcesses/integrationProcesses';
import { useIntegration } from 'services/repositories/integrations/integrations';
import { DirectionBadge } from 'pages/Integrations/ViewIntegration/components/DirectionBadge/DirectionBadge';
import { IntegrationProcessConfigurationStep } from './components/IntegrationProcessConfigurationStep/IntegrationProcessConfigurationStep';
import { routeToPage } from 'support/helpers/navigation/navigation';
import {
  IntegrationProcessDTO,
  IntegrationProcessRoutes,
  IntegrationsRoutes,
  ProcessDirection,
  DocumentType,
  IntegrationProcessConfigurationSteps,
} from 'support/types';

export const NewEditIntegrationProcess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    id: integrationId,
    processId,
    step,
  } = useParams<{ processId: string; id: string; step?: IntegrationProcessConfigurationSteps }>();
  const [searchParams] = useSearchParams();
  const { data: integration, isInitialLoading: isLoadingIntegration } = useIntegration({
    variables: { id: integrationId },
  });
  const { data: process, isInitialLoading: isLoadingProcess } = useIntegrationProcess({
    variables: { integrationId, processId },
  });

  useEffect(() => {
    if (process?.status === 'READY') {
      if (integrationId) {
        return navigate(routeToPage(IntegrationsRoutes.view, { id: integrationId }), { replace: true });
      }
      return navigate(routeToPage(IntegrationsRoutes.root), { replace: true });
    }
  }, [integrationId, navigate, process?.status]);

  useEffect(() => {
    if (integrationId && isUnavailableToTest(process) && step === IntegrationProcessConfigurationSteps.TEST) {
      navigate(
        routeToPage(IntegrationProcessRoutes.edit, {
          id: integrationId,
          processId: process.id,
          step: IntegrationProcessConfigurationSteps.CONFIGURATION,
        }),
      );
    }
  }, [integrationId, navigate, process, step]);

  const pageHead = processId ? (
    <Page.Head
      title={
        process ? (
          <div className="flex items-center gap-2">
            {t(`common:messageTypes.plural.${process.messageType}`)}
            <DirectionBadge direction={process.direction}></DirectionBadge>
          </div>
        ) : null
      }
      description={`${integration?.connector} (${t('integrations:list.status.' + integration?.state)})`}
    />
  ) : (
    <Page.Head title={t('integrations:processes.new.title')} />
  );

  if (!integrationId) return null;

  return (
    <Page isLoading={isLoadingProcess || isLoadingIntegration}>
      {pageHead}
      <Page.Section>
        {step === IntegrationProcessConfigurationSteps.CONFIGURATION || !processId ? (
          <IntegrationProcessConfigurationStep
            integrationId={integrationId}
            process={generateDefaultProcess(searchParams.get('direction'), searchParams.get('messageType'), process)}
            onSuccess={(processId) =>
              navigate(
                routeToPage(IntegrationProcessRoutes.edit, {
                  id: integrationId,
                  processId,
                  step: IntegrationProcessConfigurationSteps.TEST,
                }),
              )
            }
          />
        ) : (
          process && (
            <IntegrationProcessTestStep
              onBack={() =>
                navigate(
                  routeToPage(IntegrationProcessRoutes.edit, {
                    id: integrationId,
                    processId: process.id,
                    step: IntegrationProcessConfigurationSteps.CONFIGURATION,
                  }),
                )
              }
              process={process}
            />
          )
        )}
      </Page.Section>
    </Page>
  );
};

const isUnavailableToTest = (process?: IntegrationProcessDTO): process is IntegrationProcessDTO =>
  Boolean(process && !process.hasValidConfiguration);

const generateDefaultProcess = (
  defaultDirection: string | null,
  defaultMessageType: string | null,
  process: IntegrationProcessDTO | undefined,
) => {
  const direction = defaultDirection ? (defaultDirection as ProcessDirection) : undefined;
  const messageType = defaultMessageType ? (defaultMessageType as DocumentType) : undefined;
  return {
    direction,
    messageType,
    ...process,
  };
};
