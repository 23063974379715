import { useParams } from 'react-router-dom';
import { useSenderEnvelope } from 'services/repositories/transactions/transactions';
import { Page } from 'components/Page/Page';
import { DroppedSenderEnvelopeAlert } from '../DroppedSenderEnvelopeAlert/DroppedSenderEnvelopeAlert';
import { SenderEnvelopeHeader } from './SenderEnvelopeHeader/SenderEnvelopeHeader';
import { SenderEnvelopeErrorBanner } from './SenderEnvelopeErrorBanner/SenderEnvelopeErrorBanner';
import { PartnerDTO, SenderEnvelopeDTO } from 'support/types';
import { Placeholder } from 'components/Placeholder/Placeholder';
import { useTranslation } from 'react-i18next';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { WebEDISidebar } from 'components/WebEDI/WebEDISidebar/WebEDISidebar';
import { useCurrentPartner } from 'services/repositories/partners/partners';

export function SenderEnvelopeViewPage() {
  const { id } = useParams<{ id: string }>();
  const { data: currentPartner } = useCurrentPartner();
  const { data: senderEnvelopeData, isLoading: isLoadingSenderEnvelope } = useSenderEnvelope({
    variables: {
      senderEnvelopeId: id,
    },
  });

  const isLoading = isLoadingSenderEnvelope;
  return (
    <SenderEnvelopeUI
      isLoading={isLoading}
      senderEnvelope={senderEnvelopeData?.transaction}
      currentPartner={currentPartner}
    />
  );
}

export const SenderEnvelopeUI = ({
  isLoading,
  senderEnvelope,
  currentPartner,
}: {
  isLoading: boolean;
  senderEnvelope: SenderEnvelopeDTO | undefined;
  currentPartner: PartnerDTO | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Page isLoading={isLoading}>
      {senderEnvelope && <SenderEnvelopeHeader senderEnvelope={senderEnvelope} />}

      {senderEnvelope && (
        <Page.Section
          testId="sender-envelope-view-page-content"
          topBanner={
            <>
              <DroppedSenderEnvelopeAlert senderEnvelope={senderEnvelope} />
              {senderEnvelope ? <SenderEnvelopeErrorBanner senderEnvelope={senderEnvelope} /> : null}
            </>
          }
          rightAside={
            <WebEDISidebar
              document={senderEnvelope}
              relatedTransactions={[senderEnvelope]}
              currentPartner={currentPartner}
              isSenderEnvelope
            />
          }
        >
          <Placeholder title={t('webedi:senderEnvelope.placeholder.title')} icon={<EyeSlashIcon strokeWidth="1" />} />
        </Page.Section>
      )}
    </Page>
  );
};
