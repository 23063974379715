import { Controller } from 'react-hook-form';
import { messageTypeOptions, directionOptions } from '../../helpers';
import { EdifactForm } from '../../ProcessTypes/Edifact/EdifactForm/EdifactForm';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Button } from 'components/Form/Button/Button';

export const NewEditIntegrationProcessForm = ({
  isLoading,
  EditControls,
}: {
  isLoading: boolean;
  EditControls: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Controller
        render={({ field, fieldState }) => (
          <Dropdown
            {...field}
            label={t('integrations:processes.new.form.messageType.label')}
            options={messageTypeOptions}
            errors={fieldState.error}
            disabled={EditControls}
          />
        )}
        name="messageType"
      />
      <Controller
        render={({ field, fieldState }) => (
          <Dropdown
            {...field}
            label={t('integrations:processes.new.form.direction.label')}
            options={directionOptions}
            errors={fieldState.error}
            disabled={EditControls}
          />
        )}
        name="direction"
      />
      <EdifactForm />
      <Button type="submit" loading={isLoading} analyticsId="integrations:process-create">
        {t('integrations:processes.new.form.submit')}
      </Button>
    </>
  );
};
