import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type RequestErrorFallbackProps = {
  onCtaClick: () => void;
  isLoading: boolean;
};
export const RequestErrorFallback: FC<RequestErrorFallbackProps> = ({ onCtaClick, isLoading }) => {
  const { t } = useTranslation();
  return (
    <section className="flex w-full flex-col items-center gap-6 py-6 text-center">
      <div>
        <h1 className="font-bold text-gray-900">{t('common:requestErrorFallback.title')}</h1>
        <span className="text-sm text-gray-500">{t('common:requestErrorFallback.description')}</span>
      </div>
      <Button
        loading={isLoading}
        onClick={onCtaClick}
        LeftIcon={ArrowPathIcon}
        variant="secondary"
        size="small"
        analyticsId="request_error_fallback_cta"
      >
        {t('common:requestErrorFallback.cta')}
      </Button>
    </section>
  );
};
