import { BasePopupV2 } from 'components/Display/BasePopup/BasePopupV2';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { PickLineItemsStage } from './PickLineItemsStage';
import { UploadStage } from './UploadStage';
import { ConfirmationStage } from './ConfirmationStage';
import { MergeStats, OcrResult, UnmatchedLineItem } from './types';
import { ColumnTypesInternal } from 'components/DataTable/Types';
import { ProcessSpecificationDTO, RelationshipV2 } from 'support/types';

enum ExtractionStages {
  UPLOAD,
  PICK_LINE_ITEMS,
  CONFIRMATION,
}

const calculateMergeStats = (mergedData: any, unmatchedLineItems: Array<UnmatchedLineItem>): MergeStats => {
  const extractionStats = mergedData.transport_units?.reduce(
    (acc: any, transportUnit: any) => {
      const lineItems = transportUnit.line_items;
      const matched = lineItems.filter((lineItem: any) => lineItem._ocr_internal.matched === true).length;
      const unmatched = lineItems.filter(
        (lineItem: any) => lineItem._ocr_internal.matched === false && lineItem._ocr_internal.extracted === true,
      ).length;
      const missing = lineItems.filter(
        (lineItem: any) => lineItem._ocr_internal.matched === false && lineItem._ocr_internal.extracted === false,
      ).length;

      return {
        matched: acc.matched + matched,
        unmatched: acc.unmatched + unmatched,
        missing: acc.missing + missing,
      };
    },
    { matched: 0, unmatched: 0, missing: 0 },
  );

  const addedUnmatchedLineItems = unmatchedLineItems.filter((unmatchedLineItem) => unmatchedLineItem.keep === 'true');

  return {
    matched: extractionStats.matched,
    added: addedUnmatchedLineItems.length,
    missing: extractionStats.missing,
  };
};

type DocumentExtractorProps = {
  receiverProcessSpecification: ProcessSpecificationDTO;
  senderProcessSpecification: ProcessSpecificationDTO;
  targetRelationship: RelationshipV2;
  onApplyOcrExtraction: (result: OcrResult) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
};

export const DocumentExtractor = ({
  open,
  setOpen,
  receiverProcessSpecification,
  senderProcessSpecification,
  targetRelationship,
  onApplyOcrExtraction,
}: DocumentExtractorProps) => {
  const { t } = useTranslation();
  const [stage, setStage] = useState<ExtractionStages>(ExtractionStages.UPLOAD);
  const [documentHash, setDocumentHash] = useState<string>();
  const [mergedData, setMergedData] = useState<any>();
  const [mergeStats, setMergeStats] = useState<MergeStats>({
    matched: 0,
    added: 0,
    missing: 0,
  });
  const [unmatchedLineItems, setUnmatchedLineItems] = useState<Array<UnmatchedLineItem>>([]);

  const onBack = useCallback(() => {
    switch (stage) {
      case ExtractionStages.PICK_LINE_ITEMS:
        setStage(ExtractionStages.UPLOAD);
        break;
      case ExtractionStages.CONFIRMATION:
        if (unmatchedLineItems.length > 0) {
          setStage(ExtractionStages.PICK_LINE_ITEMS);
          break;
        }
        setStage(ExtractionStages.UPLOAD);
        break;
    }
  }, [stage, unmatchedLineItems]);

  const onConfirm = useCallback(() => {
    // console.log('onConfirm');
    // console.log('mergedData: ', mergedData);
    const finalMergedData = {
      ...mergedData,
      transport_units: mergedData.transport_units
        .map((unit: any, unitIndex: number) => ({
          ...unit,
          line_items: unit.line_items
            .map((lineItem: any, lineItemIndex: number) => {
              const unmatchedLineItem = unmatchedLineItems.find(
                (unmatchedLineItem) =>
                  unmatchedLineItem.path === `transport_units[${unitIndex}].line_items[${lineItemIndex}]`,
              );

              return {
                ...lineItem,
                _internal: {
                  ...lineItem._internal,
                  matched: !unmatchedLineItem,
                  keepUnmatched: unmatchedLineItem?.keep === 'true',
                  [ColumnTypesInternal.procuros_added]: !!unmatchedLineItem,
                },
              };
            })
            .filter((lineItem: any) => {
              if (lineItem._internal.matched === true) {
                return true;
              }

              return lineItem._internal.keepUnmatched === true;
            }),
        }))
        .filter((unit: any) => unit.line_items.length > 0),
    };
    // console.log('finalMergedData: ', finalMergedData);

    onApplyOcrExtraction({
      documentHash: documentHash ?? '',
      data: finalMergedData,
    });
    setOpen(false);
    addNotification(
      {
        title: t('webedi:documentExtractor.notifications.success.title'),
        subtitle: t('webedi:documentExtractor.notifications.success.subtitle'),
      },
      NotificationType.success,
    );
  }, [mergedData, onApplyOcrExtraction, documentHash, setOpen, t, unmatchedLineItems]);

  const onApply = useCallback(
    (newUnmatchedLineItems: Array<UnmatchedLineItem>) => {
      setMergeStats(calculateMergeStats(mergedData, newUnmatchedLineItems));
      setUnmatchedLineItems(newUnmatchedLineItems);
      setStage(ExtractionStages.CONFIRMATION);
      // console.log('onApply');
      // console.log('unmatchedLineItems: ', newUnmatchedLineItems);
    },
    [setStage, mergedData],
  );

  const onExtracted = useCallback(
    (data: any, documentHash: string) => {
      // console.log('canonical: ', processSpecification?.data);
      // console.log('data: ', data);

      const unmatchedLineItems: Array<UnmatchedLineItem> = data.merged.transport_units.flatMap(
        (unit: any, unitIndex: number) =>
          unit.line_items
            .flatMap((lineItem: any, lineItemIndex: number) => {
              if (lineItem._ocr_internal.matched === false && lineItem._ocr_internal.extracted === true) {
                return {
                  path: `transport_units[${unitIndex}].line_items[${lineItemIndex}]`,
                  item: lineItem,
                  keep: 'false',
                };
              }
            })
            .filter(Boolean),
      );
      // console.log('unmatchedLineItems: ', unmatchedLineItems);

      setMergedData(data.merged);
      setDocumentHash(documentHash);

      if (unmatchedLineItems.length > 0) {
        setUnmatchedLineItems(unmatchedLineItems);
        setStage(ExtractionStages.PICK_LINE_ITEMS);
        return;
      }

      setMergeStats(calculateMergeStats(data.merged, []));
      setStage(ExtractionStages.CONFIRMATION);
    },
    [setStage],
  );

  return (
    <BasePopupV2 setOpen={setOpen} open={open} width="xl">
      {stage === ExtractionStages.UPLOAD && (
        <UploadStage
          senderSpec={{ fields: senderProcessSpecification.fields }}
          receiverSpec={{ fields: receiverProcessSpecification.fields }}
          targetRelationship={targetRelationship}
          documentType={receiverProcessSpecification.process.messageType}
          initialData={receiverProcessSpecification.data}
          onExtracted={onExtracted}
        />
      )}
      {stage === ExtractionStages.PICK_LINE_ITEMS && (
        <PickLineItemsStage onBack={onBack} onApply={onApply} unmatchedLineItems={unmatchedLineItems} />
      )}
      {stage === ExtractionStages.CONFIRMATION && (
        <ConfirmationStage mergeStats={mergeStats} onBack={onBack} onConfirm={onConfirm} />
      )}
    </BasePopupV2>
  );
};
