import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { RangeDatePicker } from 'components/Form/RangeDatePicker/RangeDatePicker';
import { classNames } from 'support/helpers/generic/generic';

const DATE_FORMAT = 'yyyy-MM-dd';

type DateFilterProps = {
  filterKey: string;
  startDate: string;
  endDate?: string;
  placeholder?: string;
  label?: string;
  onChange: (filterKey: string, newStartDate: string, newEndDate: string) => void;
};

export function DateFilter({ filterKey, startDate, endDate, placeholder, label, onChange }: DateFilterProps) {
  const onDateChange = (dates?: DateRange) => {
    if (!dates || !dates.from || !dates.to) {
      return;
    }

    const newStartDate = format(dates.from, DATE_FORMAT);
    const newEndDate = format(dates.to, DATE_FORMAT);

    onChange(filterKey, newStartDate, newEndDate);
  };

  return (
    <RangeDatePicker
      key={startDate + endDate}
      labelText={label}
      placeholderText={placeholder || ''}
      startDate={startDate ? new Date(startDate) : null}
      endDate={endDate ? new Date(endDate) : null}
      onChange={onDateChange}
      className={classNames('min-w-[190px]', label ? 'mt-1' : undefined)}
    />
  );
}
