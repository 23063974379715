import { Button } from 'components/Form/Button/Button';
import { ListTable } from 'components/ListTable/ListTable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToPriceCatalogListPage, routeToStandaloneCreatePage } from 'support/helpers/navigation/navigation';
import { DocumentType } from 'support/types';
import { CatalogPartnerListItem } from '../PriceCatalogsPage';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { TradePartnerNameAndLogo } from 'components/TradePartnerNameAndLogo';

export function CatalogPartnerList({ data, isLoading }: { data: Array<CatalogPartnerListItem>; isLoading: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableHeaders = [
    { label: t('priceCatalogs:partnerList.tableHeaders.tradePartner'), key: 'name' },
    { label: t('priceCatalogs:partnerList.tableHeaders.catalogCount'), key: 'message count' },
    { label: t('priceCatalogs:partnerList.tableHeaders.latestCatalogSentAt'), key: 'last message' },
    { label: '', key: 'actions' },
  ];

  const buildRow = (item: CatalogPartnerListItem) => {
    return [
      {
        element: <TradePartnerNameAndLogo partner={item.partner} />,
        key: `name ${item.partner.id}`,
      },
      {
        element: item.messageCount,
        key: `message count ${item.partner.id}`,
      },
      {
        element: (
          <span className="tabular-nums">
            {item.lastMessageCreatedAt ? formatDayAndTime(item.lastMessageCreatedAt) : '—'}
          </span>
        ),
        key: `last message ${item.partner.id}`,
      },
      {
        element: <CatalogPartnerActions item={item} />,
        key: `actions ${item.partner.id}`,
      },
    ];
  };

  return (
    <ListTable<CatalogPartnerListItem>
      headers={tableHeaders}
      data={data}
      isLoading={isLoading}
      rowBuilder={buildRow}
      onRowClick={(item) => {
        navigate(routeToPriceCatalogListPage(item.catalogRelationshipId));
      }}
    />
  );
}

const CatalogPartnerActions = ({ item }: { item: CatalogPartnerListItem }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="flex w-full justify-end"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {item.partnerIsSender && item.senderUsesWebEDI && (
        <Button
          size="small"
          variant="secondary"
          onClick={() => navigate(routeToStandaloneCreatePage(item.catalogRelationshipId, DocumentType.productCatalog))}
          analyticsId="price-catalogs:create-navigate"
        >
          {t('priceCatalogs:partnerList.actions.newCatalog')}
        </Button>
      )}
    </div>
  );
};
