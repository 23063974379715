import { Alert } from 'components/Display/Alert/Alert';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getScrollableParent } from 'support/helpers/scroll/scroll';

type ValidationErrorsType = {
  errors: string[];
};

export function ValidationErrors({ errors }: ValidationErrorsType) {
  const { t } = useTranslation();
  const hasErrors = errors.length > 0;
  const ref = useRef(null);

  useEffect(() => {
    if (hasErrors) {
      getScrollableParent(ref.current)?.scrollTo(0, 0);
    }
  }, [hasErrors]);

  if (!hasErrors) {
    return null;
  }

  return (
    <Alert severity="error" title={t('auth:errors.heading')} ref={ref}>
      <ul className="list-disc pl-5 space-y-1">
        {errors.map((error: string) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    </Alert>
  );
}
