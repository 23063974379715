import { DocumentType, IntegrationProcessDTO, ProcessDirection } from 'support/types';
import {
  EdifactFormState,
  edifactConfigurationSchema,
  defaultData as edifactDefaulftData,
} from './ProcessTypes/Edifact/EdifactForm/EdifactForm';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DropdownOptionType } from 'components/Form/Dropdown/Dropdown';
import { getDropdownOptionsValues } from 'support/helpers/generic/generic';

const availableMessageTypes = [DocumentType.order, DocumentType.shippingNotice, DocumentType.invoice];
export const messageTypeOptions: DropdownOptionType<DocumentType>[] = Object.values(DocumentType)
  .filter((option) => option !== DocumentType.unknown)
  .sort()
  .map((option) => ({
    value: option,
    label: `common:messageTypes.plural.${option}`,
    disabled: !availableMessageTypes.includes(option),
  }));

/*
  t('integrations:processes.new.form.direction.inbound')
  t('integrations:processes.new.form.direction.outbound')
*/
export const directionOptions = [
  { value: ProcessDirection.INBOUND, label: 'integrations:processes.new.form.direction.inbound' },
  { value: ProcessDirection.OUTBOUND, label: 'integrations:processes.new.form.direction.outbound' },
];

const defaultData = {
  messageType: '',
  direction: '',
  configuration: edifactDefaulftData,
};

export const generateDefaultValues = (data: Partial<IntegrationProcessDTO> | undefined) => {
  return {
    ...defaultData,
    ...data,
    direction: (data?.direction as ProcessDirection) ?? defaultData.direction,
    messageType: (data?.messageType as DocumentType) ?? defaultData.messageType,
    configuration: (data?.configuration as EdifactFormState) ?? defaultData.configuration,
  };
};

export const integrationProcessSchema = (t: TFunction) =>
  Yup.object({
    messageType: Yup.string()
      .oneOf(
        getDropdownOptionsValues(messageTypeOptions),
        t('common:form.required', { field: t('integrations:processes.new.form.messageType.label') }),
      )
      .required(t('common:form.required', { field: t('integrations:processes.new.form.messageType.label') })),
    direction: Yup.string()
      .oneOf(
        getDropdownOptionsValues(directionOptions),
        t('common:form.required', { field: t('integrations:processes.new.form.direction.label') }),
      )
      .required(t('common:form.required', { field: t('integrations:processes.new.form.direction.label') })),
    configuration: Yup.object({}).required().concat(edifactConfigurationSchema),
  });
