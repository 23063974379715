import { Menu, MenuButton, MenuItem, MenuItems, MenuSeparator } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { FC, Fragment, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from 'components/Form/Button';
import { Divider } from 'components/Divider/Divider';
import { AnalyticsProps, buildAnalyticsAttributes } from 'support/helpers/analytics/analytics';

type HeaderMenuProps = {
  actions?: Array<{ label: string; onClick: () => void; className?: string } & AnalyticsProps>;
};
export const HeaderMenu: FC<HeaderMenuProps> = ({ actions }) => {
  if (!actions?.length) return null;
  return (
    <Menu>
      <MenuButton as={Fragment}>
        <Button
          iconOnly
          LeftIcon={EllipsisVerticalIcon}
          variant="minimal"
          size="extra-small"
          analyticsId="table_header_menu_options_toggle"
        >
          Options
        </Button>
      </MenuButton>
      <MenuItems
        transition
        anchor={{ to: 'bottom', gap: 6 }}
        className={classNames(
          'z-50 flex flex-col overflow-auto rounded-md border bg-white text-gray-600 shadow-sm ',
          'origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
        )}
      >
        {actions?.length ? (
          <>
            <MenuSeparator as={Divider} />
            {actions.map(({ label, onClick, className, analyticsId, analyticsProperties }) => (
              <HeaderMenuOption
                key={label}
                onClick={onClick}
                className={className}
                analyticsId={analyticsId}
                analyticsProperties={analyticsProperties}
              >
                {label}
              </HeaderMenuOption>
            ))}
          </>
        ) : null}
      </MenuItems>
    </Menu>
  );
};

const HeaderMenuOption = ({
  onClick,
  children,
  className,
  analyticsId,
  analyticsProperties,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  className?: string;
} & AnalyticsProps) => {
  return (
    <MenuItem>
      <button
        onClick={onClick}
        className={classNames('block px-4 py-2 text-left text-sm hover:bg-gray-100', className)}
        {...buildAnalyticsAttributes({ analyticsId, analyticsProperties })}
      >
        {children}
      </button>
    </MenuItem>
  );
};
