import { useTranslation } from 'react-i18next';

import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';

import { ResetPasswordForm } from '../ResetPasswordForm/ResetPasswordForm';

export function ResetPassword() {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ApplicationLogo variant="dark" className="mx-auto h-11 w-auto" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <ResetPasswordForm introText={t('auth:resetPasswordText')} />
      </div>
    </div>
  );
}
