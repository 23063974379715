import { TrashIcon } from '@heroicons/react/24/outline';
import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { Input } from 'components/Form/Input/Input/Input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropTransaction } from 'services/repositories/transactions/transactions';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { TransactionInternalType } from 'support/types';

export type DropTransactionProps = {
  transactionId: string;
  canDropTransaction: boolean;
  internalType: TransactionInternalType;
};

export function DropTransaction({ transactionId, canDropTransaction, internalType }: DropTransactionProps) {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { addNotification } = useNotificationStore();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [dropTransactionReason, setDropTransactionReason] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const { mutate, isLoading } = useDropTransaction();

  const drop = async (reason: string) => {
    const payload = { id: transactionId, type: internalType, reason };
    mutate(payload, {
      onSuccess: () => {
        track('transaction_dropped', {
          reason,
          type: internalType,
          transaction_id: transactionId,
        });

        addNotification(t('transactions:show.sections.errors.dropTransaction.successful'));
      },
      onError: () => {
        addNotification(t('transactions:show.sections.errors.dropTransaction.failed'), NotificationType.error);
      },
      onSettled: () => {
        onClose();
      },
    });
  };

  const onClose = () => {
    setShowPopup(false);
    setShowWarning(false);
  };

  const PrimaryButton = () => (
    <Button
      onClick={() => {
        if (!dropTransactionReason.length) {
          setShowWarning(true);
          return;
        }
        drop(dropTransactionReason);
      }}
      loading={isLoading}
      analyticsId="transactions:drop_submit"
    >
      <div data-testid="action-button">{t('transactions:show.sections.errors.dropTransaction.actionText')}</div>
    </Button>
  );
  const CancelButton = ({ cancelButtonRef }: { cancelButtonRef: React.RefObject<HTMLButtonElement> }) => (
    <Button
      onClick={onClose}
      disabled={isLoading}
      variant="secondary"
      ref={cancelButtonRef}
      analyticsId="transactions:drop_cancel"
    >
      <div data-testid="cancel-button">{t('transactions:show.sections.errors.dropTransaction.cancelText')}</div>
    </Button>
  );

  return (
    <>
      <Button
        data-testid="drop-transaction-button"
        LeftIcon={TrashIcon}
        variant="secondary"
        onClick={() => setShowPopup(true)}
        disabled={!canDropTransaction}
        className={
          !canDropTransaction ? 'cursor-not-allowed bg-white opacity-30 hover:bg-white' : 'bg-white hover:bg-gray-300'
        }
        analyticsId="transactions:drop"
      >
        {t('transactions:show.sections.errors.progress.dropTransaction')}
      </Button>

      <Popup
        CancelButton={CancelButton}
        PrimaryButton={PrimaryButton}
        title={`${t('transactions:show.sections.errors.dropTransaction.title')} - ${transactionId}`}
        subtitle={t('transactions:show.sections.errors.dropTransaction.description')}
        open={showPopup}
        onClose={onClose}
        testId="drop-transaction-modal"
      >
        <div className="text-left">
          {t('transactions:show.sections.errors.dropTransaction.inputHeading')} <span className="text-red-500">*</span>
        </div>

        <Input
          name="dropTransactionReason"
          value={dropTransactionReason}
          onChange={(event) => {
            setShowWarning(Boolean(event.target.value.length === 0));
            setDropTransactionReason(event.target.value);
          }}
          placeholder={t('transactions:show.sections.errors.dropTransaction.placeholder')}
          className="my-2 w-full"
        />

        <div className={`text-sm text-red-500 ${!showWarning ? 'invisible' : ''}`}>
          {t('transactions:show.sections.errors.dropTransaction.warningText')}
        </div>
      </Popup>
    </>
  );
}
