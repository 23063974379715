import { TFunction } from 'i18next';
import { AS2ConfigurationDTO } from 'support/types';
import * as Yup from 'yup';
import { EDIFACTSchema, edifactDefaultConfiguration } from '../../FileFormats/EDIFACT/helpers';
import { getDropdownOptionsValues } from 'support/helpers/generic/generic';

export const as2DefaultConfiguration: AS2ConfigurationDTO = {
  as2_identity: '',
  as2_url: '',
  settings: {
    send: {
      mdn: {
        signed: true,
        type: 'SYNCHRONOUS',
      },
    },
    receive: {
      mdn: {
        signed: true,
        type: 'SYNCHRONOUS',
      },
    },
  },
  algorithm: {
    signing: 'SHA2256',
    encryption: 'DES3',
  },
  file_format: 'EDIFACT',
  certificate: {
    contents: '',
  },
  ...edifactDefaultConfiguration,
};

/*
  t('integrations:new.as2.form.field.mdnType.options.synchronous')
  t('integrations:new.as2.form.field.mdnType.options.asynchronous')
*/
export const mdnOptions = [
  {
    label: 'integrations:new.as2.form.field.mdnType.options.synchronous',
    value: 'SYNCHRONOUS',
  },
  {
    label: 'integrations:new.as2.form.field.mdnType.options.asynchronous',
    value: 'ASYNCHRONOUS',
  },
];

/*
  t('integrations:new.as2.form.field.signingAlgorithm.options.md5')
  t('integrations:new.as2.form.field.signingAlgorithm.options.sha1')
  t('integrations:new.as2.form.field.signingAlgorithm.options.sha2256')
  t('integrations:new.as2.form.field.signingAlgorithm.options.sha2384')
  t('integrations:new.as2.form.field.signingAlgorithm.options.sha2512')
*/

export const messageSigningOptions = [
  {
    label: 'integrations:new.as2.form.field.signingAlgorithm.options.md5',
    value: 'MD5',
  },
  {
    label: 'integrations:new.as2.form.field.signingAlgorithm.options.sha1',
    value: 'SHA1',
  },
  {
    label: 'integrations:new.as2.form.field.signingAlgorithm.options.sha2256',
    value: 'SHA2256',
  },
  {
    label: 'integrations:new.as2.form.field.signingAlgorithm.options.sha2384',
    value: 'SHA2384',
  },
  {
    label: 'integrations:new.as2.form.field.signingAlgorithm.options.sha2512',
    value: 'SHA2512',
  },
];

/*
t('integrations:new.as2.form.field.encryptionAlgorithm.options.aes128'),
t('integrations:new.as2.form.field.encryptionAlgorithm.options.aes192')
t('integrations:new.as2.form.field.encryptionAlgorithm.options.aes256')
t('integrations:new.as2.form.field.encryptionAlgorithm.options.des3')
t('integrations:new.as2.form.field.encryptionAlgorithm.options.rc2')
*/
export const messageEncryptionOptions = [
  {
    label: 'integrations:new.as2.form.field.encryptionAlgorithm.options.aes128',
    value: 'AES128',
  },
  {
    label: 'integrations:new.as2.form.field.encryptionAlgorithm.options.aes192',
    value: 'AES192',
  },
  {
    label: 'integrations:new.as2.form.field.encryptionAlgorithm.options.aes256',
    value: 'AES256',
  },
  {
    label: 'integrations:new.as2.form.field.encryptionAlgorithm.options.des3',
    value: 'DES3',
  },
  {
    label: 'integrations:new.as2.form.field.encryptionAlgorithm.options.rc2',
    value: 'RC2',
  },
];

// t('integrations:new.as2.form.field.fileFormat.options.edifact')
export const fileFormatOptions = [
  {
    label: 'integrations:new.as2.form.field.fileFormat.options.edifact',
    value: 'EDIFACT',
  },
];

export const aS2ConfigurationSchema = (t: TFunction) =>
  Yup.object({
    as2_identity: Yup.string().required(
      t('common:form.required', { field: t('integrations:new.as2.form.field.as2Identity.label') }),
    ),
    as2_url: Yup.string()
      .url(t('common:form.url', { field: t('integrations:new.as2.form.field.as2Url.label') }))
      .required(t('common:form.required', { field: t('integrations:new.as2.form.field.as2Url.label') })),
    settings: Yup.object().shape({
      send: Yup.object()
        .shape({
          mdn: Yup.object().shape({
            signed: Yup.boolean().required(),
            type: Yup.string().oneOf(['SYNCHRONOUS']).required(),
          }),
        })
        .required(),
      receive: Yup.object()
        .shape({
          mdn: Yup.object().shape({
            signed: Yup.boolean().required(),
            type: Yup.string().oneOf(getDropdownOptionsValues(mdnOptions)).required(),
            asynchronous_mdn_url: Yup.string()
              .nullable()
              .when('type', {
                is: 'ASYNCHRONOUS',
                then: (schema) =>
                  schema
                    .url(t('common:form.url', { field: t('integrations:new.as2.form.field.asynchronousMdnUrl.label') }))
                    .required(
                      t('common:form.required', {
                        field: t('integrations:new.as2.form.field.asynchronousMdnUrl.label'),
                      }),
                    ),
              }),
          }),
        })
        .required(),
    }),
    algorithm: Yup.object()
      .shape({
        signing: Yup.string().oneOf(getDropdownOptionsValues(messageSigningOptions)).required(),
        encryption: Yup.string().oneOf(getDropdownOptionsValues(messageEncryptionOptions)).required(),
      })
      .required(),
    file_format: Yup.string().oneOf(getDropdownOptionsValues(fileFormatOptions)).required(),
    certificate: Yup.object()
      .shape({
        contents: Yup.string().required(
          t('common:form.required', { field: t('integrations:new.as2.form.field.certificate.label') }),
        ),
      })
      .required(),
  }).when((values, schema) => {
    if (values[0].file_format !== 'EDIFACT') return schema;
    return schema.concat(EDIFACTSchema(t));
  });
