import { Section } from 'components/Display/Section/Section';
import { Translate } from 'components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { formatDay } from 'support/helpers/dateTime/dateTime';
import { Connectors, IntegrationDTO, RelationshipV2 } from 'support/types';

const CONNECTORS_WITH_SPECIAL_GUIDELINES: Array<Connectors> = [Connectors.XENTRAL_V2, Connectors.WECLAPP];
type ConnectionTestGuidelinesProps = {
  connector: Connectors | undefined;
  connectorConfig: IntegrationDTO['connectorConfig'];
  relationship: RelationshipV2;
};
export const ConnectionTestGuidelines = ({
  connector,
  connectorConfig,
  relationship,
}: ConnectionTestGuidelinesProps) => {
  const { t } = useTranslation();
  if (!connector) return null;
  const activeFromDate = relationship.active_from ? formatDay(relationship.active_from) : undefined;
  const documentType = relationship.message_type;

  return (
    <div className="flex flex-col gap-2">
      <Section.Title>{t('connections:connectionTest.testGuidelinesSection.title')}</Section.Title>
      <Section.Subtitle>{t('connections:connectionTest.testGuidelinesSection.description')}</Section.Subtitle>
      <div className="space-y-4 rounded-lg bg-gray-50 p-6 text-sm text-gray-700">
        {CONNECTORS_WITH_SPECIAL_GUIDELINES.includes(connector) ? (
          <Translate
            i18nKey={`connections:connectionTest.testGuidelinesSection.guidelines.${connector}.${documentType}`}
            values={{
              ...connectorConfig,
              documentType: t(`common:messageTypes.plural.${documentType}`).toLowerCase(),
              activeFromDate,
            }}
          />
        ) : (
          <Translate
            i18nKey={`connections:connectionTest.testGuidelinesSection.guidelines.other`}
            values={{
              ...connectorConfig,
              documentType: t(`common:messageTypes.plural.${documentType}`).toLowerCase(),
              activeFromDate,
            }}
          />
        )}
      </div>
    </div>
  );
};

/*
 * i18n
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.ORDER')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.ORDER_RESPONSE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.SHIPPING_NOTICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.INVOICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.CREDIT_NOTE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.DISPATCH_INSTRUCTION')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.DISPATCH_INSTRUCTION_RESPONSE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.RECEIVING_NOTICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.REMITTANCE_ADVICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.WECLAPP.PRODUCT_CATALOG')
 *
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.ORDER')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.ORDER_RESPONSE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.SHIPPING_NOTICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.INVOICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.CREDIT_NOTE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.DISPATCH_INSTRUCTION')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.DISPATCH_INSTRUCTION_RESPONSE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.RECEIVING_NOTICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.REMITTANCE_ADVICE')
 * t('connections:connectionTest.testGuidelinesSection.guidelines.XENTRAL_V2.PRODUCT_CATALOG')
 *
 */
