import { Alert } from 'components/Display/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { IntegrationsRoutes, RelationshipV2 } from 'support/types';
import { TestMessagePendingReviewBanner } from './components/TestMessagePendingReviewBanner/TestMessagePendingReviewBanner';
import { TestMessageRequestedBanners } from './components/TestMessageRequestedBanners/TestMessageRequestedBanners';
import { Translate } from 'components/Translate/Translate';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { useNavigate } from 'react-router-dom';
import { useInfiniteIntegrations } from 'services/repositories/integrations/integrations';
import { EMPTY_FIELD } from 'support/helpers/const/const';

export const RelationshipDetailsBanner = ({
  currentPartnerId,
  relationship,
  requester,
}: {
  currentPartnerId: string;
  relationship: RelationshipV2;
  requester: boolean;
}) => {
  const { t } = useTranslation();

  switch (relationship.status) {
    case 'BLOCKED': {
      return (
        <Alert withoutIcon severity="neutral">
          <Translate i18nKey="connections:relationshipDetails.header.alert.blocked.description" />
        </Alert>
      );
    }
    case 'ACTIVE':
      return (
        <Alert withoutIcon severity="info">
          <Translate i18nKey="connections:relationshipDetails.header.alert.active.description" />
        </Alert>
      );

    case 'GO_LIVE_DATE_SCHEDULED': {
      const goLiveDate = relationship.go_live_date ? formatDayAndTime(relationship.go_live_date) : EMPTY_FIELD;

      return (
        <Alert
          withoutIcon
          severity="success"
          title={t('connections:relationshipDetails.header.alert.goLiveDateScheduled.title')}
        >
          <Translate
            i18nKey="connections:relationshipDetails.header.alert.goLiveDateScheduled.description"
            values={{ goLiveDate }}
          />
        </Alert>
      );
    }
    case 'GO_LIVE_DATE_PENDING':
      return (
        <Alert withoutIcon severity="neutral">
          <Translate i18nKey="connections:relationshipDetails.header.alert.goLiveDatePending.description" />
        </Alert>
      );

    case 'LIVE_ORDER_NEEDED':
      return (
        <Alert withoutIcon severity="neutral">
          <Translate i18nKey="connections:relationshipDetails.header.alert.liveOrderNeeded.description" />
        </Alert>
      );

    case 'PARTNER_PROCESS_PENDING':
    case 'ISSUE_RESOLVER_PENDING':
      return (
        <Alert withoutIcon severity="neutral">
          <Translate i18nKey="connections:relationshipDetails.header.alert.partnerProcessPending.description" />
        </Alert>
      );

    case 'PARTNER_INTEGRATION_PENDING':
      return <PartnerIntegrationPendingBanner relationship={relationship} currentPartnerId={currentPartnerId} />;
      break;

    case 'PARTNER_APPROVAL_PENDING':
      if (requester) {
        return (
          <Alert withoutIcon severity="neutral">
            <Translate i18nKey="connections:relationshipDetails.header.alert.partnerApprovalPending.description" />
          </Alert>
        );
      }
      break;

    case 'READY_FOR_TESTING':
    case 'HAS_TEST_MESSAGES':
      return <TestMessageRequestedBanners relationship={relationship} currentPartnerId={currentPartnerId} />;
    case 'IN_REVIEW':
      return <TestMessagePendingReviewBanner relationship={relationship} currentPartnerId={currentPartnerId} />;
    default:
      return null;
  }
};

const PartnerIntegrationPendingBanner = ({
  relationship,
  currentPartnerId,
}: {
  relationship: RelationshipV2;
  currentPartnerId: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    sender_partner: senderPartner,
    sender_integration: senderIntegration,
    receiver_partner: receiverPartner,
    receiver_integration: receiverIntegration,
  } = relationship;

  const isSenderAndDoesntHaveIntegration =
    senderPartner?.id === currentPartnerId && senderIntegration?.production?.state !== 'READY';
  const isReceiverAndDoesntHaveIntegration =
    receiverPartner?.id === currentPartnerId && receiverIntegration?.production?.state !== 'READY';
  const showBanner = isSenderAndDoesntHaveIntegration || isReceiverAndDoesntHaveIntegration;

  const { data: integrations, isLoading: integrationsLoading } = useInfiniteIntegrations({
    variables: {
      query: {
        perPage: '1',
      },
    },
    enabled: showBanner,
  });

  // if no integration is set but the partner has integrations CS needs to go through the integration process.
  // Same label as PARTNER_PROCESS_PENDING.
  if (integrations?.pages[0].count && showBanner) {
    return (
      <Alert withoutIcon severity="neutral">
        <Translate i18nKey="connections:relationshipDetails.header.alert.partnerIntegrationPending.missingIntegrationSelection.description" />
      </Alert>
    );
  }

  return (
    showBanner &&
    !integrationsLoading && (
      <Alert
        withoutIcon
        severity="neutral"
        title={t('connections:relationshipDetails.header.alert.partnerIntegrationPending.missingIntegration.title')}
        actions={{
          primary: {
            variant: 'primary',
            children: t(
              'connections:relationshipDetails.header.alert.partnerIntegrationPending.missingIntegration.action',
            ),
            onClick: () => {
              navigate(routeToPage(IntegrationsRoutes.root, { partnerId: relationship.sender_partner?.id }));
            },
            analyticsId: 'testing-flow:partner_integration_pending_navigate',
          },
        }}
      >
        <Translate i18nKey="connections:relationshipDetails.header.alert.partnerIntegrationPending.missingIntegration.description" />
      </Alert>
    )
  );
};
