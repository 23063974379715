import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentType, IntegrationProcessDTO, IntegrationProcessTestDTO, ProcessDirection } from 'support/types/dtos';
import { Tabs } from 'components/Navigation/Tabs/Tabs';
import { EDIFACTInboundTests } from '../components/EDIFACTInboundTests/EDIFACTInboundTests';
import { EDIFACTOutboundTests } from '../components/EDIFACTOutboundTests/EDIFACTOutboundTests';

export const EDIFACTIntegrationProcessTest = ({
  onShowProcessTestDetails,
  integrationId,
  process,
}: {
  onShowProcessTestDetails: (item: IntegrationProcessTestDTO) => void;
  process: Pick<IntegrationProcessDTO, 'id' | 'messageType' | 'direction'>;
  integrationId: string;
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const tabs = useMemo(
    () => ({
      [ProcessDirection.INBOUND]: [
        {
          label: t('integrations:processes.new.test.edifact.INBOUND.async.title'),
          content: (
            <EDIFACTInboundTests.Async
              documentType={process.messageType as DocumentType}
              processId={process.id}
              integrationId={integrationId}
            />
          ),
        },
        {
          label: t('integrations:processes.new.test.edifact.INBOUND.sync.title'),
          content: (
            <EDIFACTInboundTests.Sync
              onShowItemDetails={onShowProcessTestDetails}
              integrationId={integrationId}
              processId={process.id}
            />
          ),
        },
      ],
      [ProcessDirection.OUTBOUND]: [
        {
          label: t('integrations:processes.new.test.edifact.OUTBOUND.sync.title'),
          content: (
            <EDIFACTOutboundTests.Sync
              integrationId={integrationId}
              processId={process.id}
              onShowItemDetails={onShowProcessTestDetails}
            />
          ),
        },
        {
          label: t('integrations:processes.new.test.edifact.OUTBOUND.async.title'),
          content: (
            <EDIFACTOutboundTests.Async
              integrationId={integrationId}
              processId={process.id}
              onShowItemDetails={onShowProcessTestDetails}
            />
          ),
        },
      ],
    }),
    [integrationId, onShowProcessTestDetails, process.id, process.messageType, t],
  );

  return <Tabs defaultIndex={tab} onChange={(index: number) => setTab(index)} items={tabs[process.direction]} />;
};
