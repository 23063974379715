export const Background = () => {
  return (
    <svg viewBox="0 0 1200 900" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)" stroke="currentColor" strokeWidth="1.42" strokeMiterlimit="10">
          <path d="M1672.24-820.474C904.659 54.873 2732.93-119.632 1810.71 136.458c-381.84 106.036-818.327 111.579-873.082 10.156C824.67-62.601 743.077 136.04 529.165 307.504 349.308 451.667 287.788 128.493 106.136 136.163c-353.686 14.939-370.356 443.402-689.32 608.541-192.611 99.722-534.206-299.979-592.846-130.845-145.48 419.631-333.65 155.658-505.32 150.931-146.53-4.033-191.51 94.086-258.45 173.298-116.83 138.252 96.13 229.712 127.03 353.042 26.56 106.01 334.79 355.08 73.12 448.98-265.68 95.33-217.46 281.94-406.81 363.05-441.39 189.07-705.43 289.06-881.48 565.72-52.24 82.1-525.37 52.98-502.33 179.08 40.89 223.59 236.07 601.83 153.9 733.97-152.26 244.91-324.47 389.22-502.3 349.17-233.24-52.54-426.23 133.09-591 267.29" />
          <path d="M1698.23-783.8C935.947 75.667 2732.44-85.132 1816.67 169.072c-79.51 22.062-161.38 39.59-241.77 52.431-62.29 9.978-123.63 17.347-182.2 22.074-76.34 6.191-147.98 7.894-210.88 5.059-124.36-5.529-214.492-28.896-238.013-70.979-87.279-156.193-154.751-81.248-275.923 38.548-38.759 38.022-82.682 80.272-134.113 121.129-60.83 48.441-108.192 43.921-151.345 17.434-84.064-51.423-151.775-187.781-271.048-181.916-90.779 4.508-159.374 36.139-216.696 83.696-118.789 98.651-184.754 264.353-301.305 395.462-45.523 51.143-98.67 97.482-164.417 132.405-27.766 14.811-58.54 19.032-90.953 16.178-189.31-16.697-443.097-280.899-494.077-136.748-146.51 414.305-333.35 155.977-506.39 152.062-145.76-3.299-191.55 93.516-259.11 172.011-35.44 41.442-40.95 78.622-30.85 113.792 23.23 80.86 130.9 151.4 151.88 237.43 9.76 40.36 59.41 101.06 103.16 166.06 71.89 106.25 129.96 224.58-29.56 283.6-83.13 31.1-135.06 71.25-174.42 113.79-83.25 90.36-101.05 195.13-229.03 250.67-46.75 20.26-91.72 39.4-135.22 57.62-281.8 118.65-482.41 208.43-631.3 358.23-42.73 42.89-81.07 90.77-115.81 145.63-52.34 83-518.08 57.3-495.24 184.25 15.15 84.14 51.5 189.56 86.27 295.23 58.14 176.31 112.1 353.42 59.58 435.68-153.98 241.15-325.65 384.09-504.07 343.93-233.04-52.46-426.33 129.22-591.09 259.79" />
          <path d="M1716.32-752.905c-756.987 843.586 1007.75 696.493 98.4 948.818-79.45 22.031-161.28 39.354-241.69 51.73-62.23 9.635-123.52 16.695-182.06 21.181-76.23 5.881-147.8 7.367-210.73 4.432-123.77-5.647-214.038-28.67-238.122-70.328-88.705-153.45-155.536-79.528-276.967 38.51-38.979 37.889-83.207 79.458-134.641 119.954-60.601 47.944-107.901 43.824-151.087 18.403-84.245-49.245-152.104-182.688-270.642-176.022-90.278 5.159-158.633 37.023-215.896 84.826-118.981 99.518-181.289 265.178-296.644 396.44-45.035 51.095-97.979 97.905-162.97 133.311-27.46 15.103-57.796 19.604-89.573 17.259-184.838-13.637-435.778-271.386-487.648-127.561-147.49 408.992-333.06 156.288-507.45 153.194-144.99-2.573-191.84 92.718-259.79 170.718-35.61 40.88-41.37 77.76-32.11 112.59 21.34 80.11 126.88 150.67 147.14 236.81 9.55 40.59 58.17 101.47 101.24 166.4 70.32 106.04 129.06 223.68-27.15 283.94-81.5 31.44-132.49 72.19-172.33 114.67-84.1 89.65-100.65 195.32-227.77 251.19-46.41 20.37-91.31 39.5-135.14 57.46-282.45 117.03-483.54 205.63-632.25 354.37-42.95 42.74-81.26 90.58-115.82 145.44-52.46 83.91-510.79 61.62-488.18 189.44 15 84.52 50.72 189.9 84.35 295.17 56.3 175.47 107.14 350.79 53.45 432.68-155.65 237.4-326.83 378.95-505.84 338.69-232.82-52.38-426.42 125.36-591.17 252.3" />
          <path d="M1727.52-726.856c-751.698 827.699 981.28 694.325 78.36 944.758-79.4 21.993-161.19 39.125-241.62 51.034-62.18 9.292-123.42 16.051-181.93 20.289-76.11 5.585-147.61 6.854-210.59 3.798-123.18-5.766-213.569-28.423-238.236-69.678C843.399 72.653 776.935 145.298 655.5 261.81c-39.276 37.681-83.725 78.639-135.168 118.78-60.372 47.448-107.561 43.62-150.836 19.364-84.315-47.253-152.42-177.594-270.245-170.122-89.782 5.804-157.884 37.914-215.108 85.956-119.181 100.391-177.812 266.004-291.992 397.425-44.54 51.053-97.379 98.148-161.523 134.231-27.204 15.307-57.053 20.176-88.186 18.347-180.392-10.594-428.462-261.874-481.222-118.375-148.42 403.674-332.74 156.588-508.51 154.326-144.22-1.86-192.01 92.028-260.47 169.428-35.69 40.36-41.8 76.88-33.35 111.38 19.44 79.37 122.77 149.96 142.39 236.18 9.3 40.83 57.03 101.81 99.32 166.72 68.91 105.75 128.27 223.08-24.74 284.3-79.81 31.94-129.86 73.17-170.23 115.55-84.84 89.03-100.25 195.51-226.51 251.72a4312.692 4312.692 0 0 1-135.06 57.3c-283.38 114.62-484.64 202.79-633.17 350.48-43.16 42.59-81.58 90.32-115.82 145.25-52.8 84.7-503.51 65.96-481.13 194.63 14.85 84.92 49.95 190.25 82.42 295.11 54.46 174.65 102.19 348.17 47.33 429.7-157.24 233.65-328 373.8-507.61 333.44-232.6-52.29-426.51 121.49-591.25 244.8" />
          <path d="M1732.88-704.676c-746.4 811.82 954.81 692.151 58.31 940.705-79.33 21.97-161.08 38.89-241.54 50.332-62.14 8.942-123.32 15.406-181.81 19.404-76 5.28-147.43 6.332-210.42 3.164-122.6-5.884-213.103-28.182-238.346-69.027-91.456-147.937-157.602-76.58-279.049 38.427-39.573 37.472-84.332 77.701-135.69 117.613-60.243 46.805-107.253 43.481-150.591 20.33-84.47-45.135-152.743-172.492-269.84-164.228-89.28 6.457-157.142 38.799-214.321 87.087-119.381 101.264-174.347 266.829-287.332 398.403-44.051 51.004-96.725 98.512-160.069 135.144-26.917 15.563-56.309 20.748-86.799 19.435-175.967-7.559-421.123-252.374-474.783-109.195-149.31 398.362-332.44 156.888-509.58 155.458-143.44-1.16-192.17 91.344-261.13 168.144-35.79 39.84-42.23 76.02-34.6 110.17 17.54 78.64 118.64 149.25 137.64 235.57 9.03 41.05 55.86 102.14 97.4 167.05 67.49 105.45 127.46 222.47-22.33 284.65-78.13 32.43-127.23 74.15-168.14 116.42-85.56 88.43-99.85 195.71-225.24 252.24-45.75 20.58-90.52 39.52-134.98 57.16-284.1 112.74-485.37 200.35-634.11 346.6-43.26 42.55-81.81 90.12-115.83 145.07-53 85.53-496.26 70.25-474.09 199.79 14.69 85.29 49.16 190.6 80.48 295.04 52.61 173.82 97.26 345.54 41.21 426.7-158.77 229.92-329.18 368.66-509.37 328.2-232.4-52.22-426.61 117.63-591.35 237.31" />
          <path d="M1733.44-685.377c-741.111 795.939 928.34 689.976 38.28 936.645-79.29 21.938-160.99 38.653-241.47 49.623-62.08 8.592-123.22 14.761-181.68 18.511-75.88 4.97-147.24 5.819-210.27 2.53-122.03-6.004-212.625-27.926-238.472-68.376-92.773-145.173-158.647-75.115-280.085 38.381-39.871 37.265-84.87 76.86-136.218 116.44-60.04 46.278-106.952 43.349-150.339 21.29-84.626-43.018-153.073-167.398-269.45-158.335-88.778 7.109-156.399 39.682-213.526 88.224-119.574 102.131-170.877 267.648-282.68 399.374-43.562 50.955-96.064 98.884-158.621 136.063-26.623 15.827-55.573 21.313-85.419 20.517-171.562-4.532-413.79-242.867-468.36-100.008-150.14 393.048-332.11 157.18-510.64 156.589-142.67-.468-192.32 90.659-261.81 166.849-35.88 39.34-42.65 75.16-35.86 108.97 15.65 77.93 114.53 148.56 132.91 234.94 8.78 41.29 54.71 102.49 95.48 167.39 66.06 105.15 126.65 221.88-19.93 285-76.45 32.93-124.6 75.12-166.04 117.3-86.29 87.81-99.44 195.89-223.98 252.76-45.42 20.68-90.12 39.57-134.9 57-284.81 110.86-486.39 197.62-635.04 342.72-43.46 42.41-82.05 89.91-115.85 144.88-53.14 86.44-488.93 74.61-466.99 205 14.54 85.68 48.39 190.95 78.56 294.98 50.76 173 92.33 342.89 35.08 423.7-160.25 226.2-330.35 363.52-511.14 322.95-232.18-52.14-426.69 113.76-591.43 229.81" />
          <path d="M1730.26-667.996c-735.818 780.052 901.87 687.809 18.23 932.591-79.23 21.907-160.88 38.425-241.4 48.928-62.03 8.249-123.11 14.11-181.54 17.619-75.76 4.673-147.06 5.297-210.13 1.895-121.446-6.122-212.125-27.669-238.581-67.732-94.049-142.406-159.707-73.65-281.13 38.343-40.174 37.049-85.406 76.017-136.745 115.265-59.83 45.745-106.652 43.218-150.088 22.251-84.782-40.9-153.388-162.304-269.045-152.441-88.282 7.754-155.656 40.566-212.732 89.347-119.773 103.005-167.123 268.715-278.019 400.352-42.962 51.005-95.404 99.257-157.167 136.977-26.344 16.089-54.842 21.87-84.032 21.606-167.191-1.515-406.431-233.381-461.931-90.823-150.93 387.748-331.79 157.458-511.71 157.718-141.89.21-192.46 89.97-262.48 165.56-35.96 38.83-43.07 74.31-37.1 107.77 13.76 77.21 110.4 147.85 128.16 234.31 8.53 41.52 53.55 102.82 93.56 167.71 64.64 104.85 125.82 221.29-17.51 285.35-74.79 33.43-121.97 76.1-163.96 118.18-87.01 87.2-99.04 196.08-222.71 253.3-45.08 20.78-89.71 39.6-134.82 56.83-285.52 109.01-487.42 194.89-635.97 338.84-43.65 42.3-82.26 89.7-115.86 144.68-53.34 87.35-481.66 78.97-459.95 210.22 14.39 86.07 47.62 191.29 76.63 294.92 48.92 172.17 87.43 340.25 28.96 420.71-161.66 222.5-331.53 358.38-512.91 317.71-231.96-52.06-426.79 109.89-591.51 222.32" />
          <path d="M1724.37-651.555c-730.514 764.173 875.41 685.635-1.8 928.531-79.19 21.877-160.78 38.19-241.33 48.226-61.97 7.907-123 13.473-181.41 16.728-75.65 4.368-146.86 4.777-209.97 1.268-120.862-6.241-211.63-27.413-238.697-67.075-95.281-139.65-160.772-72.192-282.166 38.298-40.472 36.841-85.95 75.168-137.273 114.091-59.627 45.219-106.359 43.08-149.838 23.226-84.943-38.79-153.71-157.204-268.646-146.541-87.78 8.405-154.915 41.45-211.939 90.484-119.966 103.872-163.606 269.572-273.359 401.331-42.461 50.97-94.744 99.629-155.72 137.896-26.05 16.352-54.112 22.427-82.646 22.694-162.853 1.493-399.076-223.886-455.506-81.636-151.67 382.444-331.47 157.734-512.77 158.844-141.11.87-192.62 89.29-263.15 164.27-36.04 38.32-43.49 73.45-38.36 106.57 11.87 76.51 106.28 147.15 123.41 233.69 8.27 41.75 52.4 103.15 91.65 168.04 63.22 104.54 124.99 220.69-15.11 285.71-73.1 33.93-119.33 77.08-161.85 119.05-87.74 86.59-98.65 196.27-221.46 253.82-44.75 20.89-89.29 39.67-134.75 56.68-286.22 107.16-488.43 192.15-636.9 334.96-43.83 42.18-82.49 89.5-115.87 144.51-53.52 88.17-474.38 83.24-452.88 215.35 14.23 86.46 46.61 191.7 74.7 294.85 46.69 171.45 82.53 337.6 22.84 417.72-163.03 218.8-332.72 353.23-514.68 312.47-231.76-51.99-426.88 106.02-591.59 214.81" />
          <path d="M1716.82-635.07c-725.226 748.292 848.94 683.46-21.84 924.478-79.12 21.846-160.68 37.96-241.26 47.53-61.93 7.556-122.9 12.829-181.28 15.836-75.52 4.065-146.67 4.118-209.82.633-120.269-6.628-211.125-27.141-238.814-66.431-96.474-136.877-161.85-70.75-283.21 38.259-40.776 36.626-86.487 74.327-137.801 112.918-59.416 44.685-106.058 42.948-149.586 24.186-85.105-36.68-154.033-152.116-268.25-140.641-87.277 9.058-154.179 42.341-211.15 91.614C-346.363 518.05-386.279 683.74-494.898 815.627c-41.953 50.944-94.077 100.009-154.266 138.81-25.77 16.614-53.388 22.991-81.266 23.775-158.557 4.498-391.72-214.405-449.07-72.455-152.37 377.153-331.13 158.003-513.84 159.973-140.32 1.52-192.76 88.6-263.82 162.98-36.13 37.81-43.92 72.61-39.61 105.37 9.97 75.83 102.14 146.45 118.67 233.07 8 41.99 51.24 103.47 89.72 168.36 61.8 104.23 124.15 220.11-12.7 286.06-71.45 34.43-116.7 78.06-159.77 119.92-88.45 86-98.24 196.46-220.19 254.36-44.41 20.98-88.88 39.71-134.66 56.52-286.91 105.32-489.47 189.4-637.83 331.09-44.03 42.04-82.71 89.3-115.88 144.31-53.68 89.05-467.07 87.57-445.8 220.54 14.08 86.85 45.8 192.05 72.77 294.79 44.8 170.62 77.67 334.93 16.72 414.72-164.31 215.12-333.89 348.09-516.44 307.23-231.54-51.9-426.98 102.16-591.68 207.32" />
          <path d="M1708.66-617.588c-719.923 732.412 822.47 681.285-41.88 920.417-79.07 21.816-160.59 37.725-241.18 46.829-61.87 7.213-122.79 12.177-181.15 14.943-75.41 3.761-146.48 3.584-209.65.001-119.693-6.782-210.614-26.863-238.932-65.774-97.625-134.115-162.938-69.3-284.248 38.215-41.087 36.416-87.024 73.484-138.329 111.743-59.212 44.159-105.763 42.809-149.334 25.146-85.274-34.563-154.356-147.015-267.85-134.754-86.783 9.703-153.541 43.107-210.35 92.745-120.59 105.36-156.585 271.284-264.054 403.286-41.451 50.909-93.424 100.387-152.811 139.723-25.484 16.884-52.672 23.547-79.879 24.864-154.295 7.494-384.343-204.931-442.643-63.27-153.02 371.864-330.81 158.264-514.9 161.114-139.54 2.15-192.9 87.9-264.5 161.68-36.2 37.31-44.35 71.76-40.86 104.16 8.08 75.16 98.02 145.76 113.92 232.46 7.74 42.22 50.1 103.79 87.81 168.68 60.37 103.91 123.31 219.53-10.3 286.42-69.79 34.94-114.06 79.04-157.67 120.8-89.16 85.38-97.84 196.65-218.93 254.88-44.08 21.1-88.47 39.77-134.58 56.36-287.6 103.48-490.51 186.66-638.76 327.2-44.21 41.92-82.93 89.09-115.89 144.13-53.88 89.96-459.81 91.94-438.77 225.76 13.92 87.23 45 192.41 70.85 294.73 42.9 169.81 72.79 332.27 10.59 411.73-165.55 211.45-335.06 342.95-518.21 301.98-231.33-51.83-427.07 98.29-591.76 199.83" />
          <path d="M1700.94-598.113c-714.64 716.531 796 679.118-61.93 916.364-79.01 21.784-160.48 37.49-241.11 46.126-61.83 6.87-122.69 11.533-181.03 14.044-75.3 3.457-146.28 3.049-209.5-.634-119.108-6.928-210.093-26.59-239.048-65.13-98.733-131.364-164.023-67.865-285.291 38.176-41.405 36.2-87.562 72.643-138.857 110.569-59.002 43.626-105.47 42.67-149.09 26.114C109.64 455.069 40.381 345.364-72.369 358.661c-86.296 10.174-152.804 43.984-209.562 93.875-120.81 106.212-153.068 272.141-259.394 404.265-40.936 50.876-92.757 100.766-151.364 140.642-25.205 17.147-51.962 24.097-78.493 25.957-150.075 10.48-376.968-195.46-436.208-54.088-153.62 366.588-330.48 158.518-515.97 162.238-138.76 2.78-193.04 87.23-265.16 160.4-36.28 36.8-44.78 70.93-42.11 102.96 6.19 74.49 93.89 145.06 109.18 231.83 7.48 42.45 48.95 104.12 85.89 169.01 58.97 103.59 122.45 218.96-7.89 286.77-68.13 35.46-111.43 80.02-155.57 121.68-89.86 84.78-97.44 196.85-217.67 255.4-43.75 21.2-88.06 39.83-134.5 56.2-288.28 101.66-491.55 183.91-639.7 323.32-44.41 41.8-83.15 88.9-115.9 143.95-54.03 90.84-452.52 96.26-431.68 230.94 13.76 87.61 44.18 192.76 68.92 294.66 41.01 168.99 67.96 329.62 4.48 408.74-166.72 207.8-336.24 337.81-519.98 296.74-231.12-51.75-427.15 94.42-591.85 192.33" />
          <path d="M1694.69-575.685c-709.341 700.645 769.53 676.943-81.96 912.303-78.96 21.753-160.4 37.086-241.04 45.425-61.79 6.386-122.59 10.888-180.89 13.158-75.18 3.154-146.11 2.514-209.359-1.261-118.518-7.074-209.546-26.31-239.165-64.48-99.799-128.61-165.135-66.432-286.328 38.131-41.709 35.985-88.092 71.794-139.385 109.395-58.792 43.107-105.177 42.545-148.832 27.082-85.62-30.324-155.032-137.072-267.048-122.961-85.807 10.811-152.075 44.853-208.768 95.012-121.021 107.057-149.551 272.998-254.734 405.243-40.435 50.842-92.099 101.153-149.911 141.558-24.918 17.41-51.25 24.64-77.112 27.03-145.902 13.47-369.578-185.986-429.788-44.9-154.16 361.32-330.13 158.77-517.03 163.38-137.96 3.39-193.16 86.54-265.84 159.11-36.35 36.29-45.2 70.09-43.36 101.75 4.29 73.84 89.75 144.36 104.44 231.21 7.22 42.7 47.79 104.44 83.97 169.34 57.56 103.27 121.58 218.39-5.48 287.13-66.49 35.97-108.79 81.01-153.49 122.56-90.57 84.19-97.04 197.04-216.39 255.93-43.42 21.3-87.65 39.88-134.43 56.04-288.95 99.85-492.59 181.17-640.63 319.45-44.6 41.66-83.37 88.7-115.91 143.76-54.21 91.7-445.23 100.56-424.63 236.1 13.62 88 43.38 193.11 66.99 294.6 39.12 168.17 63.12 326.95-1.65 405.74-167.84 204.17-337.42 332.67-521.75 291.49-230.9-51.66-427.25 90.56-591.94 184.84" />
          <path d="M1690.98-549.33c-704.042 684.765 743.06 674.769-102 908.25-78.91 21.729-160.31 36.844-240.97 44.729-61.74 6.03-122.49 10.243-180.77 12.266-75.06 2.85-145.92 1.98-209.206-1.895-117.932-7.22-209.004-26.029-239.28-63.823-100.81-125.866-166.241-65.004-287.365 38.087-42.026 35.768-88.629 70.952-139.913 108.221-58.581 42.574-104.89 42.413-148.58 28.042-85.803-28.208-155.353-131.999-266.651-117.06-85.303 11.45-151.338 45.729-207.973 96.135-121.24 107.908-146.039 273.847-250.074 406.222-39.933 50.807-91.439 101.536-148.463 142.476-24.632 17.68-50.549 25.2-75.726 28.12-141.779 16.46-362.179-176.531-423.349-35.72-154.67 356.05-329.8 159.01-518.09 164.51-137.19 4-193.3 85.86-266.52 157.81-36.42 35.8-45.64 69.27-44.61 100.55 2.39 73.21 85.6 143.67 99.69 230.59 6.96 42.93 46.65 104.76 82.05 169.67 56.15 102.93 120.72 217.83-3.07 287.47-64.85 36.49-106.15 81.99-151.4 123.43-91.27 83.6-96.64 197.23-215.13 256.46-43.09 21.39-87.24 39.94-134.35 55.89-289.62 98.04-493.63 178.41-641.55 315.56-44.8 41.54-83.58 88.5-115.92 143.57-54.38 92.58-437.95 104.89-417.56 241.29 13.45 88.39 42.57 193.47 65.05 294.54 37.23 167.36 58.32 324.28-7.77 402.76-168.9 200.56-338.6 327.52-523.51 286.25-230.7-51.59-427.34 86.69-592.02 177.34" />
          <path d="M1690.83-518.072c-698.758 668.884 716.59 672.601-122.04 904.19-78.86 21.698-160.21 36.601-240.89 44.027-61.69 5.673-122.39 9.592-180.64 11.374-74.94 2.547-145.72 1.446-209.05-2.522-117.355-7.361-208.444-25.74-239.396-63.179-101.792-123.134-167.366-63.585-288.409 38.048-42.344 35.552-89.16 70.118-140.434 107.054-58.364 42.049-104.596 42.274-148.336 29.009-85.98-26.085-155.68-126.919-266.259-111.168-84.807 12.081-150.603 46.606-207.18 97.273-121.459 108.759-142.528 274.697-245.421 407.193-39.424 50.767-90.779 101.923-147.009 143.383-24.346 17.96-49.859 25.76-74.339 29.22-137.71 19.45-354.777-167.073-416.917-26.53-155.13 350.77-329.47 159.23-519.16 165.63-136.39 4.6-193.42 85.18-267.18 156.53-36.49 35.28-46.07 68.44-45.87 99.34.47 72.59 81.47 142.98 94.94 229.97 6.7 43.16 45.5 105.07 80.14 169.99 54.74 102.6 119.83 217.28-.67 287.83-63.21 37.01-103.5 82.98-149.3 124.31-91.97 83-96.25 197.42-213.87 256.99-42.75 21.5-86.82 40-134.26 55.73-290.29 96.24-494.69 175.65-642.49 311.68-45 41.41-83.8 88.31-115.94 143.39-54.54 93.46-430.66 109.22-410.5 246.48 13.3 88.77 41.77 193.81 63.13 294.47 35.34 166.53 53.53 321.61-13.88 399.76-169.91 196.98-339.77 322.39-525.28 281-230.49-51.51-427.44 82.83-592.11 169.85" />
          <path d="M1695.3-480.916c-693.46 653.004 690.12 670.427-142.09 900.137-78.8 21.66-160.1 36.358-240.82 43.331-61.64 5.33-122.28 8.948-180.51 10.482-74.82 2.243-145.538.911-208.899-3.156-116.768-7.507-207.88-25.452-239.51-62.522-102.725-120.405-168.498-62.174-289.446 38.003-42.669 35.342-89.697 69.276-140.969 105.887-58.154 41.529-104.317 42.148-148.085 29.969-86.17-23.963-156.01-121.839-265.854-105.273-84.318 12.718-149.875 47.489-206.392 98.402-121.672 109.618-139.018 275.546-240.769 408.178-38.922 50.728-90.12 102.328-145.561 144.308-24.06 18.22-49.177 26.3-72.96 30.29-133.705 22.45-347.355-157.611-410.495-17.34-155.52 345.53-329.12 159.45-520.22 166.75-135.6 5.19-193.53 84.51-267.85 155.23-36.56 34.79-46.51 67.63-47.12 98.14-1.44 71.98 77.32 142.29 90.2 229.35 6.42 43.4 44.35 105.39 78.21 170.31 53.34 102.27 118.95 216.74 1.74 288.19-61.58 37.53-100.85 83.96-147.2 125.18-92.66 82.41-95.85 197.62-212.61 257.51-42.41 21.61-86.41 40.06-134.19 55.58-290.95 94.46-495.74 172.9-643.43 307.8-45.18 41.28-84 88.12-115.94 143.21-54.7 94.35-423.38 113.54-403.43 251.65 13.14 89.16 40.96 194.16 61.2 294.42 33.44 165.7 48.74 318.93-20.02 396.75-170.86 193.42-340.95 317.25-527.04 275.77-230.28-51.43-427.53 78.95-592.19 162.35" />
          <path d="M1701.55-449.147c-688.17 637.124 663.65 668.253-162.13 896.083-78.75 21.63-160.01 36.117-240.75 42.623-61.59 4.974-122.19 8.303-180.38 9.59-74.71 1.938-145.348.383-208.736-3.79-116.182-7.652-207.304-25.161-239.624-61.878-103.623-117.695-169.631-60.763-290.491 37.965-42.993 35.118-90.234 68.434-141.497 104.712-57.943 40.997-104.03 42.016-147.833 30.931-86.36-21.841-156.338-116.76-265.457-99.374-83.815 13.357-149.138 48.365-205.598 99.54-121.884 110.462-135.506 276.394-236.108 409.155-38.421 50.7-89.462 102.71-144.108 145.22-23.774 18.5-48.501 26.86-71.573 31.38-129.762 25.45-339.925-148.16-404.055-8.16-155.89 340.28-328.78 159.66-521.29 167.88-134.81 5.76-193.64 83.83-268.53 153.95-36.62 34.28-46.95 66.8-48.37 96.94-3.36 71.38 73.17 141.59 85.46 228.73 6.15 43.63 43.21 105.7 76.29 170.64 51.93 101.93 118.05 216.21 4.15 288.53-59.95 38.08-98.21 84.95-145.11 126.06-93.34 81.82-95.44 197.81-211.34 258.04-42.09 21.71-85.99 40.13-134.11 55.42-291.6 92.68-496.79 170.14-644.35 303.92-45.38 41.15-84.21 87.92-115.95 143.01-54.87 95.24-416.09 117.88-396.37 256.85 12.98 89.54 40.15 194.51 59.27 294.34 31.56 164.87 43.97 316.27-26.14 393.77-171.77 189.87-342.13 312.1-528.81 270.52-230.06-51.35-427.62 75.09-592.28 154.86" />
          <path d="M1705.42-422.828c-682.87 621.237 637.19 666.078-182.16 892.023-78.7 21.606-159.91 35.873-240.69 41.927-61.52 4.617-122.07 7.652-180.23 8.698-74.6 1.635-145.168-.144-208.597-4.424-115.604-7.792-206.721-24.878-239.747-61.228-104.463-114.994-170.775-59.366-291.527 37.92-43.317 34.894-90.758 67.593-142.024 103.538-57.727 40.471-103.751 41.89-147.583 31.905-86.55-19.719-156.666-111.68-265.052-93.48-83.318 13.988-148.402 49.242-204.803 100.663-122.097 111.321-131.995 277.244-231.449 410.136-37.912 50.66-88.803 103.11-142.661 146.14-23.487 18.76-47.833 27.41-70.186 32.47-125.877 28.47-332.491-138.71-397.631 1.02-156.19 335.06-328.43 159.86-522.35 169.02-134.02 6.33-193.75 83.15-269.19 152.65-36.69 33.79-47.4 66-49.63 95.74-5.29 70.79 69.02 140.9 80.72 228.1 5.89 43.88 42.06 106.01 74.37 170.98 50.54 101.57 117.14 215.68 6.56 288.88-58.33 38.61-95.56 85.94-143.01 126.93-94.03 81.25-95.05 198-210.09 258.57-41.75 21.81-85.57 40.19-134.03 55.26-292.24 90.9-497.86 167.37-645.28 300.04-45.58 41.01-84.42 87.72-115.97 142.83-55.03 96.12-408.81 122.2-389.3 262.03 12.83 89.93 39.35 194.86 57.34 294.28 29.68 164.05 39.22 313.6-32.26 390.77-172.61 186.35-343.31 306.96-530.58 265.28-229.84-51.28-427.71 71.22-592.35 147.36" />
          <path d="M1706.28-400.881c-677.58 605.358 610.71 663.911-202.21 887.97-78.65 21.575-159.8 35.625-240.61 41.226-61.48 4.259-121.97 6.889-180.11 7.806-74.48 1.177-144.977-.679-208.441-5.059-115.019-7.925-206.117-24.586-239.855-60.577-105.268-112.312-171.933-57.971-292.571 37.881-43.648 34.677-91.295 66.751-142.552 102.365C142.42 650.69 96.466 652.488 52.6 643.596c-86.748-17.591-156.995-106.6-264.655-87.58-82.822 14.633-147.666 50.119-204.009 101.8-122.309 112.18-128.491 278.087-226.796 411.104-37.41 50.63-88.138 103.52-141.206 147.06-23.209 19.04-47.178 27.97-68.806 33.55-122.069 31.51-325.048-129.27-391.198 10.2-156.45 329.84-328.08 160.07-523.42 170.15-133.22 6.89-193.86 82.48-269.87 151.36-36.73 33.3-47.84 65.2-50.87 94.54-7.23 70.22 64.87 140.22 75.97 227.48 5.62 44.11 40.92 106.32 72.46 171.3 49.13 101.23 116.23 215.18 8.97 289.24-56.72 39.15-92.91 86.93-140.93 127.81-94.71 80.66-94.64 198.19-208.82 259.09-41.41 21.92-85.15 40.25-133.95 55.1-292.9 89.14-498.92 164.61-646.22 296.16-45.78 40.89-84.63 87.54-115.97 142.65-55.19 97.01-401.53 126.52-382.25 267.21 12.67 90.32 38.55 195.21 55.42 294.22 27.79 163.22 34.48 310.94-38.38 387.78-173.4 182.86-344.48 301.83-532.35 260.04-229.63-51.2-427.81 67.35-592.44 139.86" />
          <path d="M1703.46-382.317c-672.28 589.477 584.25 661.736-222.24 883.91-78.59 21.543-159.7 35.382-240.54 40.53-61.43 3.91-121.86 6.238-179.98 6.92-74.364.867-144.792-1.206-208.29-5.686-114.434-8.07-205.514-24.294-239.977-59.926-106.031-109.641-173.098-56.583-293.608 37.835-43.972 34.454-91.826 65.917-143.08 101.191-57.294 39.434-103.186 41.632-147.087 33.832-86.946-15.461-157.323-101.52-264.263-81.686-82.327 15.263-146.925 51.002-203.222 102.93-122.529 113.031-124.986 278.928-222.136 412.087-36.909 50.59-87.473 103.91-139.752 147.96-22.923 19.31-46.525 28.54-67.427 34.65-118.339 34.58-317.578-119.83-384.778 19.39-156.66 324.63-327.74 160.25-524.47 171.29-132.43 7.42-193.97 81.8-270.54 150.07-36.8 32.8-48.31 64.39-52.13 93.33-9.18 69.65 60.71 139.53 71.23 226.86 5.34 44.35 39.77 106.63 70.54 171.63 47.74 100.88 115.31 214.7 11.38 289.6-55.11 39.71-90.27 87.92-138.83 128.69-95.39 80.08-94.25 198.38-207.55 259.62-41.08 22.02-84.73 40.32-133.87 54.94-293.54 87.4-500 161.84-647.15 292.28-45.98 40.76-84.84 87.35-115.99 142.45-55.34 97.9-394.24 130.85-375.18 272.4 12.52 90.7 37.75 195.56 53.49 294.16 25.91 162.4 29.77 308.28-44.5 384.79-174.15 179.4-345.67 296.68-534.12 254.79-229.42-51.12-427.9 63.49-592.53 132.36" />
          <path d="M1696.37-366.147c-667 573.59 557.77 659.562-242.29 879.857-78.54 21.506-159.6 35.138-240.46 39.828-61.38 3.552-121.77 5.586-179.85 6.028-74.247.557-144.603-1.734-208.143-6.32-113.849-8.203-204.897-24.001-240.091-59.283-106.75-106.995-174.269-55.203-294.652 37.797-44.31 34.228-92.349 65.076-143.608 100.017C90.2 670.685 44.362 673.288.44 666.569c-87.15-13.327-157.644-96.433-263.858-75.792-81.83 15.908-146.196 51.885-202.421 104.061C-588.58 808.727-587.32 974.608-683.314 1107.9c-36.406 50.54-86.809 104.32-138.305 148.89-22.63 19.58-45.886 29.1-66.04 35.73-114.681 37.69-310.121-110.39-378.341 28.57-156.83 319.43-327.39 160.43-525.54 172.42-131.64 7.96-194.05 81.14-271.21 148.78-36.85 32.31-48.78 63.6-53.38 92.12-11.15 69.11 56.55 138.84 66.49 226.24 5.07 44.59 38.63 106.93 68.62 171.95 46.35 100.53 114.38 214.23 13.77 289.95-53.5 40.27-87.6 88.91-136.72 129.57-96.07 79.5-93.84 198.57-206.3 260.14-40.75 22.12-84.31 40.39-133.79 54.78-294.17 85.65-501.05 159.07-648.08 288.4-46.18 40.62-85.05 87.16-116 142.27-55.5 98.8-386.96 135.18-368.12 277.58 12.37 91.09 36.95 195.9 51.55 294.09 24.02 161.57 25.07 305.62-50.62 381.8-174.84 175.95-346.84 291.53-535.88 249.54-229.21-51.04-427.99 59.62-592.61 124.87" />
          <path d="M1684.36-351.396c-661.69 557.71 531.31 657.394-262.33 875.796-78.47 21.482-159.5 34.903-240.39 39.126-61.32 3.203-121.67 4.935-179.72 5.135-74.129.247-144.413-2.275-207.981-6.953-113.271-8.343-204.265-23.721-240.207-58.625-107.441-104.36-175.454-53.836-295.689 37.752-44.648 34.004-92.88 64.241-144.135 98.842-56.853 38.39-102.635 41.386-146.578 35.761-87.356-11.192-157.973-91.353-263.461-69.893-81.341 16.547-145.46 52.763-201.633 105.191-122.948 114.756-117.977 280.612-212.824 414.044-35.904 50.51-86.15 104.73-136.85 149.8-22.351 19.87-45.261 29.69-64.661 36.82-111.111 40.84-302.641-100.95-371.911 37.76-156.94 314.24-327.04 160.59-526.61 173.54-130.84 8.5-194.14 80.47-271.88 147.49-36.9 31.82-49.24 62.81-54.62 90.93-13.13 68.55 52.38 138.16 61.73 225.61 4.79 44.84 37.49 107.22 66.7 172.28 44.97 100.16 113.45 213.77 16.19 290.31-51.91 40.84-84.95 89.9-134.63 130.44-96.74 78.93-93.45 198.75-205.03 260.68-40.42 22.23-83.9 40.44-133.71 54.63-294.81 83.91-502.13 156.29-649.02 284.52-46.39 40.49-85.25 86.97-116.01 142.08-55.65 99.69-379.67 139.51-361.06 282.77 12.21 91.47 36.15 196.24 49.63 294.02 22.15 160.74 20.38 302.98-56.74 378.8-175.49 172.55-348.02 286.4-537.65 244.31-229-50.96-428.09 55.75-592.7 117.38" />
          <path d="M1666.83-337.046c-656.4 541.83 504.84 655.22-282.37 871.743-78.43 21.451-159.4 34.66-240.32 38.423-61.27 2.853-121.56 4.291-179.587 4.244-74.014-.058-144.22-2.803-207.829-7.588-112.686-8.475-203.626-23.433-240.321-57.981-108.081-101.756-176.638-52.47-296.733 37.714-44.979 33.772-93.411 63.406-144.657 97.675C18.376 685.062-27.35 688.45-71.32 683.912c-87.563-9.044-158.31-86.267-263.056-63.999-80.839 17.185-144.718 53.647-200.84 106.328-123.153 115.608-114.472 281.449-208.17 415.019-35.403 50.47-85.486 105.14-135.404 150.72-22.058 20.14-44.643 30.28-63.273 37.9-107.607 44.05-295.147-91.5-365.487 46.95-157.01 309.07-326.68 160.75-527.67 174.68-130.05 9.01-194.23 79.8-272.55 146.2-36.95 31.33-49.71 62.02-55.88 89.72-15.13 68.03 48.21 137.47 56.99 224.99 4.52 45.08 36.36 107.53 64.78 172.61 43.59 99.8 112.51 213.32 18.6 290.66-50.31 41.43-82.3 90.9-132.54 131.32-97.41 78.36-92.92 199.18-203.77 261.2-40.04 22.41-83.47 40.52-133.63 54.47-295.45 82.16-503.21 153.49-649.96 280.61-46.58 40.35-85.44 86.78-116.01 141.89-55.81 100.58-372.39 143.83-353.99 287.95 12.06 91.86 35.35 196.59 47.7 293.97 20.26 159.9 15.7 300.32-62.87 375.8-176.09 169.17-349.2 281.26-539.42 239.07-228.79-50.88-428.18 51.88-592.79 109.88" />
          <path d="M1643.26-321.99c-651.108 525.95 478.37 653.045-302.41 867.683-78.38 21.42-159.3 34.417-240.24 37.721-61.22 2.503-121.455 3.64-179.458 3.352-73.895-.368-144.038-3.324-207.674-8.215-112.102-8.607-202.981-23.152-240.437-57.324-108.693-99.164-177.837-51.104-297.77 37.669-45.317 33.548-93.934 62.566-145.185 96.502-56.42 37.353-102.083 41.14-146.081 37.688-87.775-6.902-158.632-81.18-262.665-58.106-80.35 17.822-143.982 54.523-200.045 107.451-123.365 116.467-110.967 282.299-203.51 415.999-34.9 50.42-84.821 105.54-133.949 151.63-21.774 20.42-44.034 30.88-61.887 38.99-104.189 47.31-287.639-82.07-359.059 56.14-157.03 303.9-326.33 160.9-528.74 175.8-129.25 9.52-194.31 79.13-273.23 144.91-36.98 30.83-50.2 61.22-57.12 88.52-17.14 67.48 44.04 136.78 52.24 224.37 4.24 45.32 35.22 107.82 62.86 172.94 42.21 99.43 111.58 212.9 21 291-48.72 42.02-79.64 91.9-130.45 132.2-98.08 77.79-92.52 199.38-202.5 261.74-39.7 22.51-83.06 40.59-133.55 54.31-296.07 80.45-504.29 150.72-650.89 276.75-46.79 40.21-85.64 86.6-116.03 141.71-55.95 101.47-365.1 148.16-346.93 293.14 11.9 92.25 34.56 196.93 45.77 293.89 18.39 159.07 11.04 297.69-68.98 372.81-176.65 165.81-350.37 276.12-541.19 233.81-228.57-50.8-428.27 48.02-592.87 102.4" />
          <path d="M1627.03-310.205c-645.822 510.07 451.9 650.871-322.45 863.629-78.33 21.389-159.2 34.183-240.18 37.027-61.17 2.153-121.35 2.995-179.322 2.46-73.776-.679-143.848-3.852-207.525-8.849-111.517-8.74-202.321-22.871-240.551-56.681-109.261-96.598-179.04-49.761-298.814 37.63-45.66 33.316-94.465 61.731-145.713 95.328-56.196 36.834-101.813 41.034-145.83 38.649-87.981-4.754-158.96-76.1-262.267-52.206-79.854 18.467-143.24 55.407-199.251 108.589-123.572 117.333-107.469 283.129-198.85 416.979-34.406 50.38-84.151 105.96-132.503 152.55-21.481 20.7-43.432 31.49-60.504 40.07-100.84 50.66-280.13-72.63-352.62 65.31-157.01 298.76-325.98 161.04-529.81 176.94-128.46 10.02-194.39 78.48-273.9 143.61-37.03 30.35-50.69 60.44-58.38 87.32-19.16 66.96 39.87 136.1 47.5 223.75 3.97 45.55 34.09 108.1 60.95 173.26 40.83 99.06 110.62 212.49 23.4 291.36-47.14 42.62-76.98 92.89-128.36 133.07-98.74 77.23-92.11 199.57-201.24 262.26-39.37 22.62-82.63 40.66-133.47 54.15-296.69 78.76-505.36 147.95-651.81 272.88-47 40.09-85.84 86.41-116.04 141.52-56.1 102.38-357.81 152.48-339.87 298.33 11.74 92.63 33.76 197.27 43.84 293.84 16.52 158.23 6.4 295.06-75.11 369.81-177.16 162.49-351.55 270.97-542.95 228.58-228.37-50.73-428.35 44.14-592.96 94.88" />
          <path d="M1624.4-299.566c-640.523 494.183 425.44 648.703-342.49 859.569-78.26 21.359-159.09 33.94-240.09 36.325-61.118 1.796-121.246 2.343-179.2 1.56-73.665-.984-143.66-4.379-207.372-9.476-110.932-8.872-201.646-22.601-240.673-56.03-109.793-94.049-180.25-48.412-299.85 37.585-46.005 33.083-94.989 60.89-146.24 94.154-55.973 36.316-101.541 40.914-145.586 39.615-88.195-2.598-159.289-71.006-261.863-46.312-79.358 19.112-142.511 56.291-198.463 109.719-123.785 118.192-103.971 283.967-194.19 417.957-33.897 50.34-83.487 106.38-131.055 153.45-21.196 20.99-42.828 32.14-59.118 41.17-97.56 54.07-272.6-63.2-346.2 74.5-156.93 293.62-325.61 161.16-530.86 178.06-127.67 10.52-194.48 77.82-274.58 142.34-37.07 29.86-51.18 59.64-59.63 86.11-21.2 66.44 35.7 135.41 42.75 223.13 3.68 45.79 32.96 108.4 59.03 173.59 39.46 98.68 109.68 212.12 25.81 291.71-45.55 43.23-74.31 93.9-126.26 133.96-99.4 76.65-91.69 199.76-199.97 262.77-39.03 22.73-82.21 40.73-133.4 54-297.3 77.05-506.44 145.16-652.73 269-47.2 39.95-86.04 86.22-116.05 141.33-56.24 103.28-350.52 156.82-332.8 303.51 11.58 93.02 32.96 197.62 41.9 293.77 14.65 157.4 1.78 292.44-81.23 366.83-177.63 159.2-352.72 265.83-544.72 223.33-228.15-50.63-428.45 40.28-593.04 87.4" />
          <path d="M1627-286.48c-635.225 478.302 398.96 646.528-362.53 855.515-78.21 21.327-159 33.697-240.03 35.629-61.063 1.453-121.134 1.692-179.072.674-73.546-1.294-143.471-4.906-207.223-10.11-110.348-9.004-200.972-22.332-240.781-55.379-110.289-91.533-181.467-47.082-300.895 37.546-46.355 32.844-95.513 60.064-146.768 92.98-55.75 35.812-101.263 40.802-145.328 40.584-88.408-.444-159.617-65.927-261.465-40.412-78.863 19.757-141.769 57.174-197.676 110.849-123.991 119.057-100.473 284.794-189.537 418.924-33.401 50.3-82.815 106.79-129.608 154.38-20.91 21.26-40.827 35.63-57.737 42.25-82.86 73.14-265.05-53.76-339.76 83.68-156.82 288.51-325.27 161.29-531.93 179.2-126.88 11-194.55 77.17-275.25 141.04-37.11 29.37-51.69 58.86-60.87 84.91-23.26 65.92 31.52 134.73 38.01 222.5 3.4 46.04 31.83 108.69 57.11 173.91 38.1 98.3 108.74 211.77 28.23 292.07-43.96 43.85-71.66 94.89-124.16 134.82-100.07 76.11-91.3 199.96-198.71 263.32-38.7 22.82-81.79 40.8-133.32 53.83-297.93 75.36-507.54 142.38-653.68 265.12-47.4 39.81-86.23 86.04-116.06 141.15-56.39 104.17-343.24 161.14-325.74 308.7 11.43 93.4 32.16 197.95 39.98 293.71 12.77 156.56-2.84 289.82-87.35 363.83-178.07 155.93-353.9 260.68-546.49 218.09-227.94-50.57-428.54 36.41-593.13 79.9" />
          <path d="M1634.42-271.123c-629.93 462.422 372.49 644.354-382.56 851.455-78.16 21.297-158.89 33.462-239.96 34.92-61.013 1.096-121.043 1.041-178.943-.217-73.421-1.597-143.281-5.434-207.061-10.744-109.77-9.131-200.282-22.076-240.902-54.729-110.762-89.035-182.691-45.747-301.932 37.501-46.707 32.605-96.044 59.229-147.297 91.806-55.526 35.294-100.997 40.689-145.076 41.544-88.628 1.718-159.946-60.833-261.066-34.525-78.373 20.394-141.027 58.058-196.875 111.979-124.197 119.924-96.974 285.633-184.884 419.913-32.906 50.26-82.145 107.22-128.154 155.29-20.62 21.54-40.09 36.31-56.35 43.33-78.43 76.86-257.51-44.32-333.34 92.87-156.65 283.41-324.91 161.4-533 180.33-126.07 11.48-194.61 76.52-275.91 139.74-37.16 28.9-52.2 58.08-62.13 83.71-25.34 65.4 27.35 134.05 33.26 221.88 3.12 46.28 30.71 108.97 55.19 174.24 36.74 97.92 107.8 211.43 30.64 292.41-42.39 44.5-69 95.89-122.08 135.7-100.71 75.55-90.89 200.16-197.44 263.84-38.37 22.94-81.37 40.88-133.24 53.67-298.55 73.71-508.63 139.62-654.61 261.28-47.61 39.67-86.43 85.86-116.07 140.97-56.52 105.08-335.96 165.46-318.68 313.88 11.28 93.78 31.37 198.29 38.05 293.64 10.9 155.73-7.44 287.22-93.47 360.84-178.48 152.71-355.08 255.55-548.25 212.84-227.73-50.48-428.64 32.56-593.21 72.41" />
          <path d="M1646.26-253.709c-624.64 446.535 346.03 642.179-402.61 847.402-78.11 21.265-158.79 33.226-239.88 34.224-60.964.753-120.938.39-178.814-1.109-73.31-1.902-143.091-5.961-206.912-11.378-109.186-9.263-199.58-21.819-241.011-54.078-111.198-86.57-183.92-44.433-302.976 37.463C27 631.18-22.503 657.21-73.767 689.447c-55.297 34.782-100.727 40.583-144.832 42.511-88.85 3.879-160.268-55.747-260.669-28.626-77.878 21.039-140.291 58.935-196.087 113.109-124.404 120.79-93.477 286.469-180.225 420.889-32.403 50.21-81.467 107.64-126.699 156.2-20.331 21.83-39.351 37-54.971 44.42-73.98 80.57-249.92-34.89-326.9 102.06-156.43 278.31-324.56 161.48-534.06 181.46-125.27 11.96-194.66 75.88-276.57 138.47-37.2 28.42-52.73 57.29-63.39 82.5-27.42 64.88 23.17 133.37 28.52 221.26 2.84 46.53 29.58 109.25 53.28 174.56 35.38 97.54 106.85 211.13 33.04 292.77-40.81 45.14-66.34 96.9-119.98 136.58-101.37 74.99-90.49 200.35-196.18 264.37-38.03 23.03-80.95 40.96-133.16 53.53-299.17 71.99-509.74 136.78-655.55 257.34-47.81 39.54-86.62 85.68-116.08 140.78-56.66 105.98-328.67 169.79-311.61 319.07 11.13 94.17 30.59 198.64 36.13 293.57 9.03 154.89-12.02 284.63-99.6 357.85-178.83 149.5-356.26 250.41-550.02 207.6-227.5-50.4-428.73 28.68-593.29 64.91" />
          <path d="M1662.14-234.424c-619.34 430.655 319.56 640.011-422.65 843.348-78.06 21.241-158.69 32.99-239.813 33.522-60.901.404-120.834-.254-178.679-2.001-73.191-2.212-142.901-6.489-206.763-12.013-108.608-9.388-198.876-21.575-241.133-53.428-111.605-84.129-185.157-43.111-304.013 37.419-47.407 32.125-97.085 57.568-148.359 89.464-55.073 34.264-100.456 40.477-144.58 43.471-89.064 6.049-160.605-50.66-260.271-22.725-77.383 21.684-139.549 59.819-195.294 114.247-124.603 121.649-89.985 287.3-175.572 421.86-31.908 50.17-80.796 108.07-125.251 157.12-20.042 22.12-38.602 37.69-53.582 45.51-69.55 84.29-242.34-25.45-320.47 111.24-156.18 273.25-324.21 161.57-535.13 182.59-124.48 12.41-194.72 75.23-277.26 137.17-37.22 27.94-53.25 56.5-64.64 81.29-29.53 64.36 18.98 132.69 23.79 220.64 2.55 46.76 28.46 109.53 51.35 174.89 34.04 97.14 105.93 210.84 35.45 293.13-39.23 45.8-63.67 97.9-117.89 137.45-102.02 74.44-90.08 200.55-194.92 264.9-37.69 23.14-80.52 41.03-133.08 53.37-299.75 70.32-510.8 133.99-656.45 253.47-48.02 39.4-86.81 85.5-116.09 140.6-56.79 106.89-321.39 174.12-304.55 324.25 10.96 94.56 29.79 198.98 34.19 293.52 7.17 154.04-16.58 282.04-105.71 354.85-179.15 146.34-357.44 245.26-551.79 202.35-227.3-50.33-428.82 24.81-593.38 57.42" />
          <path d="M1681.64-213.471c-614.05 414.774 293.09 637.837-442.69 839.288-77.99 21.204-158.59 32.748-239.737 32.827-60.856.054-120.725-.906-178.547-2.893-73.071-2.523-142.719-7.01-206.608-12.64-108.023-9.521-198.158-21.331-241.255-52.778-111.982-81.716-186.406-41.805-305.057 37.38-47.765 31.879-97.61 56.741-148.887 88.29-54.85 33.76-100.185 40.372-144.329 44.432-89.272 8.225-160.933-45.566-259.866-16.831-76.886 22.329-138.814 60.709-194.499 115.37-124.802 122.523-86.493 288.126-170.911 422.836-31.413 50.13-80.12 108.5-123.798 158.04-19.756 22.4-37.866 38.37-52.196 46.59-65.12 88-234.75-16.01-314.05 120.42-155.88 268.2-323.85 161.65-536.19 183.73-123.7 12.86-194.78 74.59-277.93 135.87-37.26 27.46-53.79 55.71-65.89 80.09-31.67 63.82 14.79 132 19.03 220.02 2.27 47 27.34 109.81 49.44 175.21 32.68 96.75 104.99 210.59 37.85 293.48-37.65 46.47-61.01 98.89-115.8 138.33-102.67 73.89-89.67 200.74-193.66 265.42-37.36 23.24-80.1 41.11-133 53.21-300.34 68.68-511.89 131.21-657.37 249.62-48.23 39.26-87 85.31-116.1 140.41-56.94 107.78-314.1 178.44-297.49 329.42 10.81 94.95 29 199.33 32.26 293.46 5.31 153.22-21.13 279.49-111.83 351.86-179.45 143.2-358.62 240.12-553.56 197.11-227.09-50.25-428.91 20.95-593.46 49.93" />
          <path d="M1704.39-191.044C1095.63 207.852 1971 444.619 1241.65 644.191c-77.94 21.173-158.48 32.513-239.66 32.126-60.797-.296-120.626-1.551-178.416-3.786-72.954-2.819-142.53-7.537-206.46-13.274-107.439-9.639-197.432-21.106-241.362-52.126-112.344-79.329-187.655-40.514-306.094 37.334-48.122 31.632-98.127 55.907-149.409 87.124-54.62 33.249-99.914 40.265-144.078 45.406-89.494 10.4-161.263-40.473-259.475-10.938-76.391 22.973-138.072 61.593-193.705 116.507-125.009 123.386-83.008 288.956-166.252 423.816-30.91 50.08-79.443 108.93-122.351 158.95-19.457 22.69-37.128 39.07-50.818 47.68-60.68 91.73-227.14-6.55-307.61 129.61-155.53 263.16-323.5 161.71-537.25 184.85-122.91 13.3-194.84 73.95-278.6 134.58-37.29 26.99-54.33 54.91-67.14 78.9-33.81 63.28 10.59 131.32 14.29 219.39 1.98 47.25 26.23 110.09 47.52 175.54 31.34 96.36 104.08 210.37 40.26 293.84-36.06 47.17-58.33 99.91-113.7 139.2-103.32 73.34-89.27 200.94-192.39 265.95-37.02 23.35-79.68 41.2-132.92 53.05-300.98 67-513.02 128.38-658.33 245.71-48.44 39.11-87.2 85.12-116.12 140.21-57.06 108.7-306.81 182.77-290.42 334.62 10.66 95.33 28.21 199.65 30.33 293.39 3.45 152.36-25.68 276.92-117.96 348.86-179.71 140.1-359.79 234.98-555.32 191.87-226.87-50.17-429.01 17.07-593.55 42.41" />
          <path d="M1729.96-167.334c-603.46 383.015 240.16 633.496-482.76 831.182-77.89 21.142-158.38 32.284-239.6 31.423-60.745-.639-120.525-2.195-178.284-4.678-72.835-3.13-142.348-8.058-206.305-13.9-106.862-9.766-196.692-20.882-241.484-51.477-112.676-76.967-188.911-39.215-307.139 37.296-48.485 31.378-98.65 55.08-149.936 85.949-54.39 32.739-99.637 40.167-143.826 46.367-89.709 12.583-161.592-35.379-259.07-5.044-75.896 23.618-137.33 62.477-192.918 117.637-125.208 124.259-79.517 289.779-161.592 424.789-30.415 50.04-78.759 109.36-120.896 159.87-19.165 22.98-36.391 39.75-49.43 48.76-56.24 95.44-219.52 2.9-301.19 138.8-155.14 258.14-323.15 161.76-538.32 185.97-122.12 13.75-194.88 73.33-279.28 133.3-37.31 26.52-54.87 54.12-68.39 77.69-35.96 62.74 6.41 130.65 9.55 218.78 1.69 47.49 25.11 110.36 45.6 175.87 30.01 95.95 103.17 210.15 42.67 294.19-34.46 47.87-55.67 100.92-111.6 140.08-103.98 72.81-88.87 201.13-191.13 266.47-36.69 23.45-79.25 41.28-132.85 52.89-301.59 65.35-514.13 125.57-659.26 241.83-48.65 38.97-87.38 84.95-116.13 140.03-57.2 109.61-299.53 187.1-283.37 339.81 10.5 95.72 27.43 200 28.41 293.33 1.59 151.53-30.2 274.39-124.08 345.87-179.94 137.02-360.97 229.84-557.09 186.62-226.66-50.09-429.09 13.22-593.63 34.93" />
          <path d="M1757.99-142.539c-598.17 367.127 213.68 631.32-502.81 827.128-77.83 21.118-158.28 32.042-239.52 30.721-60.7-.989-120.416-2.846-178.159-5.563-72.724-3.434-142.159-8.586-206.15-14.541-106.271-9.891-195.951-20.671-241.592-50.826-112.978-74.631-190.179-37.931-308.175 37.251-48.85 31.124-99.168 54.247-150.464 84.776-54.161 32.227-99.367 40.075-143.575 47.327-89.925 14.766-161.915-30.292-258.673.856-75.394 24.271-136.587 63.361-192.123 118.774-125.415 125.126-76.032 290.606-156.946 425.766-29.927 50-78.083 109.8-119.45 160.79-18.874 23.27-35.649 40.44-48.047 49.85-51.806 99.15-211.866 12.36-294.756 147.98-154.7 253.15-322.79 161.81-539.38 187.11-121.33 14.18-194.93 72.7-279.95 132-37.34 26.05-55.44 53.32-69.65 76.49-38.14 62.18 2.21 129.96 4.81 218.15 1.4 47.74 24 110.64 43.68 176.2 28.67 95.55 102.28 209.98 45.07 294.54-32.87 48.58-53 101.93-109.51 140.95-104.61 72.26-88.47 201.32-189.86 267.01-36.37 23.55-78.83 41.36-132.77 52.73-302.18 63.71-515.22 122.76-660.18 237.95-48.87 38.83-87.58 84.76-116.14 139.84-57.31 110.51-292.25 191.43-276.3 344.99 10.35 96.11 26.64 200.33 26.49 293.26-.27 150.69-34.72 271.86-130.21 342.88-180.15 133.97-362.15 224.69-558.86 181.38-226.45-50.01-429.18 9.34-593.72 27.43" />
          <path d="M1788.05-116.872c-592.87 351.248 187.22 629.146-522.84 823.068-77.78 21.087-158.18 31.813-239.45 30.026-60.644-1.339-120.312-3.484-178.03-6.455-72.598-3.738-141.962-9.106-205.994-15.169-105.688-10.009-195.202-20.479-241.715-50.175-113.263-72.336-191.446-36.661-309.219 37.212C41.59 732.504-8.89 755.055-60.19 785.237c-53.932 31.73-99.096 39.969-143.331 48.294-90.133 16.956-162.243-25.199-258.267 6.75-74.898 24.915-135.845 64.245-191.329 119.897-125.615 126.002-72.546 291.422-152.286 426.752-29.432 49.95-77.399 110.24-117.995 161.7-18.575 23.55-34.914 41.12-46.66 50.94-47.372 102.86-204.202 21.81-288.332 157.16-154.22 248.18-322.45 161.83-540.44 188.24-120.53 14.61-194.96 72.08-280.62 130.72-37.37 25.58-55.99 52.51-70.89 75.27-40.33 61.63-1.99 129.28.05 217.53 1.11 47.98 22.9 110.91 41.77 176.53 27.35 95.15 101.41 209.83 47.47 294.89-31.26 49.32-50.32 102.94-107.42 141.84-105.26 71.72-88.07 201.51-188.6 267.53-36.03 23.66-78.4 41.45-132.68 52.57-302.79 62.08-516.34 119.96-661.12 234.08-49.07 38.67-87.75 84.58-116.14 139.65-57.45 111.42-284.97 195.75-269.24 350.17 10.19 96.49 25.86 200.68 24.55 293.21-2.12 149.85-39.22 269.35-136.32 339.88-180.34 130.98-363.33 219.56-560.63 176.15-226.24-49.93-429.28 5.47-593.81 19.94" />
          <path d="M1819.77-90.501c-587.58 335.367 160.74 626.971-542.89 819.014-77.73 21.056-158.08 31.578-239.37 29.325-60.593-1.682-120.206-4.135-177.9-7.347-72.486-4.042-141.78-9.628-205.845-15.804-105.11-10.135-194.439-20.286-241.829-49.532-113.535-70.052-192.714-35.391-310.256 37.168-49.582 30.607-100.21 52.6-151.52 82.428-51.309 29.827-98.826 39.877-143.072 49.262-90.35 19.152-162.567-20.098-257.877 12.642-74.41 25.567-135.11 65.136-190.535 121.035-125.807 126.87-69.061 292.24-147.626 427.73-28.936 49.89-76.715 110.67-116.548 162.62-18.284 23.84-34.18 41.81-45.281 52.02-42.928 106.59-196.531 31.28-281.891 166.34-153.7 243.22-322.11 161.86-541.51 189.38-119.74 15.02-195 71.46-281.3 129.42-37.38 25.11-56.55 51.7-72.14 74.08-42.54 61.05-6.18 128.59-4.69 216.91.82 48.22 21.81 111.17 39.85 176.85 26.02 94.74 100.55 209.69 49.89 295.24-29.64 50.06-47.66 103.95-105.33 142.71-105.89 71.19-87.66 201.7-187.33 268.06-35.7 23.76-77.98 41.54-132.61 52.42-303.39 60.46-517.46 117.13-662.06 230.19-49.28 38.54-87.93 84.41-116.16 139.48-57.56 112.32-277.68 200.07-262.17 355.35 10.05 96.88 25.08 201.01 22.62 293.13-3.97 149.01-43.71 266.88-142.44 336.89-180.5 128-364.51 214.42-562.41 170.9-226.02-49.85-429.37 1.61-593.89 12.44" />
          <path d="M1852.74-63.64c-582.29 319.481 134.28 624.805-562.92 814.955-77.68 21.019-157.98 31.35-239.31 28.63-60.539-2.026-120.102-4.781-177.765-8.241-72.361-4.344-141.598-10.147-205.691-16.429-104.527-10.254-193.675-20.109-241.944-48.875-113.789-67.81-193.995-34.135-311.301 37.13-49.952 30.345-100.727 51.766-152.047 81.253-53.465 30.716-98.55 39.792-142.828 50.229-90.559 21.342-162.897-15.004-257.48 18.543-73.914 26.211-134.367 66.019-189.747 122.165-126.007 127.74-65.582 293.07-142.966 428.7-28.441 49.86-76.019 111.12-115.094 163.54-17.992 24.13-33.444 42.5-43.894 53.11-38.488 110.3-188.843 40.75-275.463 175.53-153.14 238.27-321.75 161.86-542.58 190.5-118.95 15.44-195.03 70.85-281.96 128.14-37.4 24.66-57.12 50.89-73.39 72.88-44.76 60.45-10.39 127.91-9.44 216.29.52 48.46 20.71 111.44 37.93 177.18 24.71 94.33 99.73 209.58 52.3 295.6-28.01 50.81-44.99 104.96-103.23 143.58-106.54 70.66-87.25 201.89-186.08 268.59-35.36 23.86-77.55 41.62-132.52 52.26-304 58.8-518.58 114.27-662.99 226.28-49.51 38.39-88.13 84.23-116.17 139.29-57.68 113.24-270.4 204.4-255.11 360.54 9.89 97.26 24.3 201.35 20.7 293.07-5.82 148.16-48.19 264.39-148.57 333.9-180.62 125.05-365.68 209.27-564.16 165.65-225.82-49.78-429.47-2.26-593.98 4.95" />
          <path d="M1886.56-36.482c-576.99 303.607 107.81 622.63-582.97 810.901-77.61 20.995-157.87 31.114-239.23 27.919-60.49-2.368-120.002-5.424-177.634-9.131-72.25-4.649-141.402-10.668-205.543-17.064-103.942-10.372-192.902-19.951-242.058-48.231-114.027-65.594-195.289-32.881-312.337 37.084-50.322 30.084-101.245 50.947-152.575 80.079-53.237 30.22-98.28 39.701-142.577 51.19-90.769 23.546-163.233-9.904-257.074 24.437-73.413 26.864-133.619 66.911-188.946 123.298-126.208 128.61-62.098 293.89-138.313 429.67-27.952 49.81-75.336 111.56-113.64 164.45-17.686 24.43-32.701 43.19-42.507 54.2-34.054 114.01-181.126 50.23-269.036 184.71-152.53 233.36-321.41 161.85-543.64 191.64-118.16 15.84-195.05 70.24-282.63 126.85-37.43 24.18-57.7 50.06-74.65 71.67-46.98 59.84-14.58 127.23-14.18 215.66.23 48.71 19.61 111.71 36.01 177.5 23.4 93.92 98.92 209.51 54.71 295.95-26.38 51.59-42.32 105.98-101.14 144.46-107.17 70.12-86.86 202.09-184.81 269.11-35.02 23.97-77.13 41.7-132.44 52.1-304.61 57.25-519.72 111.51-663.94 222.45-49.72 38.25-88.3 84.06-116.17 139.11-57.8 114.15-263.1 208.72-248.05 365.72 9.73 97.65 23.52 201.69 18.76 293.02-7.66 147.32-52.64 261.93-154.68 330.89-180.74 122.15-366.86 204.13-565.93 160.41-225.6-49.69-429.56-6.12-594.05-2.55" />
          <path d="M1920.86-9.217c-571.71 287.72 81.33 620.455-603.01 806.841-77.57 20.964-157.77 30.886-239.16 27.218-60.42-2.717-119.89-6.075-177.504-10.031-72.132-4.945-141.22-11.189-205.381-17.697-103.366-10.484-192.122-19.799-242.173-47.574-114.244-63.397-196.569-31.64-313.382 37.046-50.698 29.815-101.763 50.127-153.103 78.906-53 29.716-98.002 39.602-142.325 52.149-90.979 25.751-163.556-4.802-256.676 30.338-72.925 27.515-132.877 67.791-188.159 124.421-126.407 129.49-58.626 294.71-133.661 430.67-27.463 49.75-74.645 111.99-112.193 165.36-17.395 24.72-31.965 43.87-41.126 55.28-29.615 117.74-173.397 59.73-262.607 193.9-151.9 228.46-321.07 161.84-544.71 192.77-117.38 16.23-195.08 69.64-283.31 125.55-37.45 23.73-58.27 49.25-75.91 70.47-49.22 59.23-18.79 126.55-18.92 215.05-.07 48.95 18.52 111.97 34.09 177.83 22.1 93.5 98.13 209.44 57.12 296.3-24.73 52.37-39.65 107-99.04 145.35-107.81 69.59-86.46 202.27-183.55 269.63-34.68 24.07-76.69 41.8-132.36 51.95-305.19 55.62-520.82 108.66-664.86 218.55-49.93 38.09-88.48 83.88-116.19 138.92-57.91 115.06-255.82 213.05-240.97 370.91 9.57 98.04 22.73 202.03 16.83 292.94-9.51 146.47-57.09 259.5-160.81 327.9-180.84 119.27-368.04 198.99-567.7 155.17-225.39-49.61-429.64-9.99-594.14-10.05" />
          <path d="M1955.21 17.957c-566.4 271.841 54.87 618.289-623.05 802.788-77.51 20.933-157.67 30.656-239.09 26.523-60.37-3.061-119.788-6.721-177.371-10.924-72.014-5.256-141.031-11.703-205.232-18.332-102.782-10.603-191.335-19.667-242.289-46.929-114.457-61.243-197.868-30.407-314.418 37.001C102.686 837.616 51.48 857.39.136 885.822c-52.764 29.212-97.727 39.518-142.082 53.117-91.182 27.962-163.877.298-256.271 36.231-72.422 28.17-132.134 68.68-187.365 125.56-126.593 130.37-55.147 295.52-129.001 431.64-26.974 49.71-73.948 112.44-110.738 166.28-17.089 25.01-31.224 44.56-39.74 56.37-25.181 121.45-165.649 69.23-256.179 203.08-151.21 223.6-320.72 161.8-545.77 193.9-116.59 16.62-195.1 69.03-283.98 124.26-37.45 23.27-58.83 48.42-77.15 69.26-51.47 58.59-23 125.87-23.66 214.43-.37 49.19 17.43 112.24 32.16 178.16 20.81 93.08 97.38 209.39 59.53 296.66-23.06 53.15-36.97 108.02-96.95 146.21-108.44 69.08-86.05 202.47-182.28 270.17-34.36 24.16-76.28 41.89-132.29 51.78-305.8 54.03-521.96 105.84-665.8 214.68-50.16 37.94-88.67 83.7-116.2 138.73-58.03 115.98-248.53 217.38-233.92 376.09 9.41 98.43 21.96 202.37 14.9 292.88-11.36 145.64-61.54 257.1-166.93 324.92-180.92 116.42-369.21 193.85-569.47 149.93-225.17-49.55-429.74-13.86-594.23-17.55" />
          <path d="M1991.56 47.51c-561.12 255.959 28.39 616.113-643.09 798.727-77.47 20.902-157.57 30.421-239.01 25.821-60.32-3.404-119.692-7.365-177.252-11.809-71.895-5.553-140.841-12.231-205.083-18.967-102.198-10.721-190.546-19.535-242.41-46.279-114.655-59.114-199.168-29.174-315.462 36.962-51.45 29.263-102.786 48.488-154.153 76.565-52.535 28.715-97.45 39.433-141.823 54.085-91.386 30.173-164.208 5.391-255.88 42.125-71.92 28.82-131.386 69.57-186.57 126.68-126.793 131.24-51.676 296.34-124.342 432.62-26.485 49.66-73.251 112.88-109.291 167.2-16.791 25.31-30.488 45.24-38.361 57.46-20.734 125.17-157.882 78.75-249.743 212.27-150.49 218.75-320.39 161.76-546.84 195.03-115.81 17.01-195.11 68.45-284.65 122.97-37.47 22.82-59.42 47.58-78.4 68.07-53.73 57.94-27.22 125.19-28.41 213.8-.66 49.43 16.36 112.5 30.26 178.48 19.51 92.67 96.65 209.35 61.92 297.01-21.37 53.95-34.29 109.04-94.85 147.1-109.08 68.55-85.66 202.65-181.02 270.7-34.02 24.27-75.85 41.98-132.21 51.62-306.39 52.43-523.07 102.99-666.72 210.78-50.37 37.8-88.84 83.53-116.2 138.55-58.14 116.9-241.25 221.7-226.86 381.28 9.25 98.8 21.18 202.7 12.97 292.82-13.19 144.79-65.97 254.7-173.05 321.91-180.97 113.61-370.39 188.71-571.23 144.68-224.97-49.47-429.84-17.73-594.32-25.04" />
          <path d="M2030.31 80.199c-555.81 240.073 1.93 613.938-663.12 794.674-77.41 20.863-157.47 30.192-238.95 25.125-60.27-3.754-119.58-8.002-177.115-12.701-71.784-5.857-140.653-12.744-204.928-19.594-101.616-10.826-189.749-19.437-242.519-45.628-114.844-57.006-200.466-27.954-316.498 36.917-51.826 28.981-103.304 47.669-154.68 75.391-52.293 28.22-97.174 39.348-141.572 55.046-91.583 32.381-164.531 10.492-255.483 48.021-71.432 29.47-130.651 70.46-185.776 127.83-126.987 132.11-48.204 297.15-119.688 433.58-25.997 49.61-72.548 113.34-107.837 168.12-16.486 25.6-29.747 45.93-36.981 58.54-16.301 128.88-150.092 88.26-243.323 221.46-149.72 213.91-320.04 161.7-547.9 196.16-115.02 17.39-195.13 67.86-285.33 121.68-37.48 22.36-59.99 46.74-79.64 66.85-55.98 57.28-31.43 124.51-33.16 213.18-.97 49.68 15.28 112.76 28.33 178.81 18.23 92.25 95.96 209.32 64.33 297.36-19.67 54.76-31.62 110.06-92.76 147.98-109.71 68.03-85.25 202.84-179.76 271.22-33.68 24.37-75.42 42.07-132.13 51.47-306.96 50.85-524.18 100.15-667.64 206.9-50.58 37.64-89.03 83.35-116.23 138.35-58.24 117.82-233.96 226.04-219.79 386.47 9.11 99.19 20.41 203.04 11.05 292.75-15.03 143.95-70.39 252.33-179.17 318.93-181.02 110.82-371.57 183.56-573 139.43-224.75-49.38-429.93-21.59-594.4-32.53" />
          <path d="M2067.94 112.191c-550.52 224.193-24.54 611.764-683.17 790.614-77.35 20.84-157.36 29.964-238.87 24.416a9951.194 9951.194 0 0 1-176.986-13.593c-71.658-6.16-140.465-13.258-204.78-20.228-101.032-10.944-188.951-19.34-242.64-44.978-115.032-54.925-201.772-26.743-317.543 36.879-52.215 28.696-103.822 46.848-155.216 74.223-52.063 27.722-96.89 39.257-141.327 56.016-91.779 34.59-164.853 15.59-255.078 53.91-70.929 30.13-129.902 71.36-184.989 128.96-127.179 132.99-44.731 297.96-115.028 434.56-25.507 49.56-71.851 113.78-106.39 169.04-16.187 25.89-29.004 46.62-35.594 59.63-11.868 132.59-142.281 97.8-236.889 230.63-148.93 209.11-319.72 161.63-548.96 197.29-114.24 17.77-195.14 67.29-285.99 120.4-37.5 21.92-60.58 45.9-80.9 65.65-58.25 56.6-35.66 123.82-37.9 212.55-1.27 49.92 14.2 113.03 26.41 179.14 16.95 91.83 95.29 209.31 66.74 297.72-17.94 55.57-28.94 111.09-90.66 148.85-110.34 67.52-84.86 203.03-178.5 271.75-33.36 24.48-75 42.17-132.05 51.31-307.56 49.27-525.31 97.31-668.56 203.02-50.81 37.49-89.21 83.18-116.24 138.17-58.35 118.73-226.68 230.36-212.73 391.65 8.95 99.58 19.65 203.38 9.11 292.69-16.86 143.1-74.79 249.97-185.29 315.93-181.06 108.07-372.76 178.43-574.77 134.19-224.54-49.3-430.02-25.46-594.49-40.02" />
          <path d="M2104.03 143.286c-545.23 208.312-51.01 609.597-703.21 786.56-77.3 20.809-157.27 29.735-238.8 23.721a10805.99 10805.99 0 0 1-176.858-14.485c-71.541-6.457-140.282-13.779-204.625-20.855-100.455-11.057-188.146-19.263-242.748-44.327-115.217-52.872-203.084-25.538-318.58 36.834-52.597 28.406-104.333 46.036-155.743 73.048-51.82 27.228-96.608 39.178-141.076 56.978-91.983 36.8-165.184 20.7-254.68 59.81-70.436 30.79-129.16 72.24-184.195 130.09-127.373 133.87-41.26 298.79-110.369 435.55-25.019 49.51-71.147 114.23-104.935 169.94-15.882 26.19-28.269 47.31-34.208 60.72-7.42 136.32-134.451 107.37-230.457 239.83-148.096 204.33-319.386 161.54-550.026 198.42-113.47 18.14-195.15 66.71-286.67 119.1-37.51 21.48-61.16 45.05-82.15 64.44-60.52 55.91-39.86 123.14-42.65 211.94-1.57 50.16 13.14 113.28 24.49 179.46 15.68 91.41 94.66 209.3 69.15 298.08-16.19 56.38-26.26 112.11-88.57 149.73-110.96 67-84.44 203.21-177.23 272.27-33.02 24.58-74.57 42.27-131.97 51.16-308.17 47.68-526.46 94.45-669.52 199.11-51.02 37.33-89.38 83-116.24 137.98-58.45 119.66-219.4 234.69-205.66 396.84 8.8 99.97 18.87 203.71 7.19 292.62-18.69 142.27-79.2 247.66-191.41 312.94-181.07 105.35-373.92 173.29-576.54 128.95-224.33-49.22-430.1-29.32-594.56-47.52" />
          <path d="M2138.17 173.288c-539.94 192.425-77.48 607.422-723.25 782.506-77.25 20.778-157.17 29.506-238.73 23.019a11821.09 11821.09 0 0 1-176.719-15.377c-71.43-6.761-140.094-14.293-204.47-21.496-99.872-11.161-187.339-19.199-242.87-43.677-115.393-50.853-204.394-24.347-319.616 36.789-52.978 28.116-104.845 45.224-156.271 71.878-51.585 26.73-96.332 39.09-140.818 57.94-92.174 39.03-165.507 25.8-254.282 65.7-69.933 31.44-128.411 73.13-183.4 131.22-127.566 134.74-37.801 299.58-105.716 436.53-24.53 49.46-70.444 114.68-103.488 170.86-15.577 26.48-29.98 47.29-32.828 61.8-26.954 137.44-126.594 116.93-224.029 249.01-147.223 199.58-319.063 161.45-551.093 199.56-112.68 18.5-195.16 66.14-287.34 117.8-37.52 21.03-61.74 44.2-83.4 63.24-62.78 55.19-44.09 122.46-47.39 211.32-1.88 50.4 12.08 113.53 22.57 179.79 14.42 90.99 94.05 209.28 71.56 298.42-14.42 57.19-23.58 113.13-86.47 150.6-111.6 66.48-84.05 203.4-175.97 272.79-32.69 24.68-74.14 42.36-131.88 51-308.76 46.13-527.6 91.63-670.44 195.26-51.25 37.18-89.56 82.83-116.26 137.8-58.55 120.58-212.11 239.02-198.61 402.02 8.64 100.35 18.11 204.05 5.26 292.56-20.52 141.43-83.58 245.35-197.54 309.94-181.08 102.66-375.1 168.15-578.29 123.71-224.12-49.14-430.21-33.2-594.66-55.01" />
          <path d="M2170 202.009c-534.64 176.553-103.95 605.248-743.29 778.447-77.2 20.744-157.06 29.274-238.66 22.324-60.06-5.12-119.16-10.58-176.59-16.27-71.308-7.058-139.902-14.806-204.311-22.122-99.289-11.28-186.524-19.157-242.992-43.027-115.575-48.855-205.718-23.158-320.661 36.75-53.366 27.818-105.356 44.409-156.799 70.699-51.35 26.25-96.04 39.01-140.573 58.91-92.364 41.25-165.83 30.9-253.885 71.6-69.438 32.1-127.662 74.02-182.606 132.36-127.759 135.62-34.343 300.4-101.063 437.5-24.047 49.4-69.733 115.12-102.033 171.77-15.272 26.78-29.158 48.12-31.442 62.9-21.905 142.07-118.717 126.51-217.601 258.19-146.324 194.86-318.734 161.33-552.154 200.69-111.9 18.86-195.16 65.58-288.01 116.52-37.53 20.59-62.33 43.34-84.66 62.04-65.05 54.47-48.3 121.77-52.13 210.7-2.18 50.64 11.02 113.8 20.65 180.12 13.16 90.56 93.48 209.27 73.97 298.77-12.64 58.01-20.91 114.17-84.39 151.48-112.22 65.97-83.65 203.59-174.7 273.33-32.35 24.78-73.71 42.47-131.81 50.84-309.36 44.58-528.73 88.77-671.38 191.39-51.46 37.02-89.73 82.65-116.26 137.61-58.65 121.49-204.83 243.34-191.55 407.2 8.49 100.74 17.34 204.39 3.33 292.51-22.34 140.59-87.96 243.06-203.65 306.95-181.09 100-376.29 163-580.07 118.47-223.9-49.07-430.3-37.06-594.74-62.51" />
          <path d="M2199.09 229.237c-529.35 160.666-130.42 603.081-763.34 774.393-77.13 20.72-156.95 29.05-238.58 21.62-60.01-5.46-119.06-11.22-176.45-17.16-71.197-7.35-139.72-15.319-204.169-22.756-98.706-11.385-185.715-19.134-243.1-42.376-115.755-46.884-207.035-21.987-321.698 36.706-53.761 27.516-105.868 43.596-157.326 69.526-51.107 25.75-95.759 38.93-140.322 59.87-92.555 43.47-166.154 36.02-253.487 77.5-68.937 32.75-126.921 74.91-181.819 133.48-127.939 136.51-30.878 301.22-96.403 438.48-23.559 49.36-69.024 115.59-100.58 172.69-14.967 27.08-28.334 48.93-30.054 63.98-16.747 146.62-110.808 136.13-211.166 267.38-145.386 190.15-318.416 161.2-553.226 201.81-111.12 19.21-195.16 65.03-288.68 115.23-37.54 20.15-62.9 42.48-85.91 60.84-67.32 53.73-52.53 121.08-56.88 210.07-2.48 50.88 9.97 114.06 18.74 180.45 11.91 90.14 92.93 209.25 76.38 299.13-10.84 58.81-18.23 115.19-82.29 152.35-112.86 65.47-83.25 203.78-173.44 273.86-32.02 24.88-73.3 42.56-131.74 50.68-309.95 43.03-529.87 85.92-672.3 187.5-51.69 36.86-89.91 82.48-116.27 137.42-58.75 122.43-197.54 247.67-184.48 412.39 8.33 101.13 16.57 204.73 1.4 292.43-24.17 139.75-92.34 240.82-209.77 303.96-181.09 97.36-377.47 157.85-581.84 113.21-223.7-48.99-430.39-40.92-594.83-70.01" />
          <path d="M2225.06 254.782c-524.05 144.786-156.89 600.906-783.37 770.338-77.08 20.68-156.86 28.82-238.52 20.91-59.96-5.8-118.96-11.86-176.33-18.04-71.084-7.66-139.53-15.84-204.012-23.39-98.13-11.493-184.898-19.115-243.222-41.722-115.926-44.935-208.358-20.812-322.742 36.667-54.149 27.215-106.38 42.785-157.855 68.355-50.871 25.26-95.475 38.85-140.07 60.83-92.731 45.7-166.477 41.11-253.082 83.4-68.436 33.41-126.171 75.79-181.017 134.61-128.133 137.39-27.42 302.02-91.743 439.45-23.077 49.3-68.308 116.04-99.14 173.61-14.662 27.37-27.502 49.72-28.674 65.06-11.487 151.09-102.885 145.74-204.738 276.56-144.415 185.49-318.095 161.07-554.285 202.94-110.35 19.57-195.16 64.48-289.36 113.94-37.54 19.7-63.48 41.61-87.16 59.63-69.6 52.98-56.75 120.4-61.62 209.46-2.8 51.12 8.91 114.31 16.82 180.77 10.66 89.72 92.41 209.23 78.78 299.48-9.02 59.63-15.55 116.22-80.2 153.23-113.48 64.96-82.85 203.97-172.17 274.39-31.69 24.98-72.87 42.67-131.66 50.52-310.54 41.49-531 83.06-673.23 183.62-51.92 36.7-90.08 82.31-116.29 137.24-58.85 123.35-190.26 252-177.42 417.58 8.18 101.51 15.81 205.07-.52 292.37-25.99 138.91-96.71 238.59-215.91 300.96-181.09 94.76-378.63 152.72-583.61 107.98-223.48-48.91-430.48-44.8-594.9-77.51" />
          <path d="M2247.5 278.452c-518.76 128.905-183.35 598.731-803.41 766.278-77.03 20.66-156.76 28.6-238.44 20.22-59.91-6.15-118.86-12.51-176.2-18.94-70.956-7.96-139.346-16.36-203.862-24.02-97.547-11.6-184.086-19.12-243.33-41.074-116.109-43.013-209.68-19.649-323.779 36.624-54.542 26.9-106.884 41.98-158.376 67.18-50.63 24.78-95.185 38.78-139.826 61.8-92.921 47.93-166.793 46.23-252.69 89.29-67.934 34.07-125.431 76.69-180.23 135.74-128.319 138.27-23.969 302.83-87.084 440.44-22.588 49.25-67.59 116.49-97.685 174.52-14.35 27.67-26.67 50.51-27.287 66.15-6.15 155.47-94.93 155.39-198.311 285.74-143.41 180.85-317.78 160.91-555.35 204.08-109.58 19.9-195.15 63.95-290.03 112.65-37.56 19.27-64.06 40.74-88.41 58.42-71.87 52.22-60.97 119.72-66.37 208.83-3.11 51.36 7.88 114.58 14.9 181.1 9.43 89.3 91.93 209.19 81.19 299.83-7.16 60.43-12.87 117.26-78.1 154.11-114.11 64.46-82.45 204.15-170.92 274.91-31.35 25.08-72.44 42.77-131.58 50.37-311.14 39.94-532.15 80.2-674.17 179.74-52.13 36.54-90.25 82.13-116.29 137.05-58.94 124.27-182.97 256.33-170.35 422.76 8.03 101.9 15.05 205.42-2.45 292.31-27.81 138.08-101.07 236.39-222.03 297.97-181.08 92.19-379.81 147.58-585.37 102.74-223.27-48.83-430.58-48.67-594.99-85" />
          <path d="M2266.03 300.045c-513.46 113.018-209.82 596.557-823.44 762.215-76.99 20.63-156.67 28.37-238.38 19.53a22117.89 22117.89 0 0 1-176.07-19.83c-70.834-8.26-139.16-16.87-203.695-24.66-96.965-11.7-183.266-19.15-243.452-40.421-116.298-41.113-211.009-18.495-324.823 36.581-54.93 26.59-107.39 41.18-158.904 66.01-50.387 24.3-94.902 38.7-139.568 62.77-93.098 50.15-167.123 51.33-252.285 95.18-67.44 34.73-124.682 77.59-179.443 136.87-128.506 139.16-20.517 303.64-82.429 441.41-22.114 49.2-66.874 116.94-96.239 175.44-14.031 27.96-25.83 51.29-25.901 67.24-.751 159.76-86.949 165.06-191.882 294.93-142.389 176.22-317.479 160.74-556.419 205.21-108.8 20.24-195.14 63.4-290.7 111.35-37.55 18.85-64.64 39.87-89.66 57.22-74.14 51.44-65.21 119.04-71.12 208.21-3.41 51.6 6.85 114.84 12.99 181.43 8.19 88.87 91.46 209.14 83.59 300.19-5.29 61.23-10.19 118.29-76 154.98-114.73 63.96-82.06 204.34-169.66 275.44-31.02 25.18-72.01 42.88-131.5 50.2-311.73 38.42-533.3 77.34-675.1 175.86-52.36 36.38-90.42 81.96-116.3 136.87-59.02 125.2-175.68 260.64-163.29 427.94 7.87 102.28 14.29 205.75-4.38 292.25-29.63 137.23-105.42 234.21-228.15 294.97-181.06 89.64-380.99 142.43-587.13 97.49-223.06-48.76-430.67-52.53-595.08-92.5" />
          <path d="M2280.25 319.364c-508.18 97.145-236.29 594.39-843.5 758.166-76.92 20.6-156.56 28.15-238.29 18.82-59.81-6.82-118.65-13.79-175.94-20.72-70.72-8.56-138.969-17.37-203.55-25.29-96.382-11.81-182.445-19.19-243.566-39.78-116.492-39.247-212.346-17.347-325.861 36.54-55.323 26.27-107.901 40.36-159.431 64.84-50.139 23.81-94.606 38.62-139.317 63.72-93.275 52.38-167.44 56.44-251.888 101.09-66.938 35.38-123.933 78.47-178.649 138.01-128.693 140.04-17.064 304.43-77.777 442.39-21.631 49.14-66.149 117.38-94.784 176.35-13.719 28.26-24.982 52.05-24.521 68.32 4.694 163.97-78.949 174.76-185.447 304.11-141.319 171.64-317.169 160.55-557.479 206.34-108.03 20.58-195.13 62.88-291.37 110.07-37.56 18.41-65.22 38.99-90.91 56.02-76.39 50.65-69.43 118.35-75.85 207.59-3.73 51.84 5.81 115.09 11.06 181.75 6.97 88.45 91.02 209.07 86 300.55-3.4 62-7.51 119.32-73.91 155.85-115.35 63.46-81.66 204.51-168.38 275.96-30.69 25.28-71.59 42.98-131.42 50.05-312.33 36.88-534.44 74.47-676.03 171.98-52.59 36.21-90.59 81.79-116.32 136.68-59.1 126.12-168.39 264.97-156.23 433.13 7.71 102.66 13.53 206.11-6.32 292.18-31.44 136.41-109.76 232.06-234.26 291.99-181.05 87.13-382.17 137.28-588.91 92.24-222.83-48.67-430.76-56.39-595.16-99.99" />
          <path d="M2284.11 361.256c-502.88 81.257-262.76 592.214-863.53 754.104-76.87 20.56-156.46 27.92-238.22 18.13-59.76-7.17-118.55-14.44-175.81-21.62-70.61-8.86-138.781-17.88-203.403-25.92-95.806-11.91-181.63-19.25-243.682-39.12-116.691-37.39-213.672-16.21-326.904 36.5-55.723 25.94-108.414 39.56-159.959 63.66-49.897 23.33-94.316 38.54-139.072 64.69-93.453 54.61-167.764 61.56-251.491 106.99-66.436 36.04-123.184 79.36-177.854 139.13-128.879 140.91-13.619 305.24-73.117 443.37-21.142 49.1-65.432 117.84-93.337 177.27-13.407 28.56-24.142 52.81-23.135 69.41 10.181 168.09-70.909 184.49-179.019 313.3-140.237 167.08-316.867 160.35-558.547 207.47-107.26 20.91-195.12 62.37-292.04 108.78-37.56 17.98-65.79 38.11-92.17 54.82-78.65 49.84-73.67 117.66-80.59 206.96-4.04 52.07 4.78 115.35 9.14 182.08 5.76 88.04 90.61 208.99 88.41 300.9-1.49 62.77-4.82 120.36-71.83 156.73-115.97 62.97-81.26 204.7-167.12 276.49-30.35 25.38-71.16 43.09-131.33 49.9-312.93 35.36-535.6 71.61-676.97 168.1-52.82 36.04-90.77 81.62-116.33 136.5-59.18 127.05-161.11 269.29-149.16 438.31 7.56 103.05 12.77 206.44-8.24 292.11-33.24 135.57-114.1 229.94-240.39 288.99-181.04 84.65-383.34 132.15-590.67 87.01-222.63-48.59-430.85-60.27-595.25-107.48" />
          <path d="M2280.33 404.336c-497.59 65.37-289.23 590.04-883.58 750.054-76.81 20.53-156.35 27.7-238.14 17.42-59.7-7.5-118.45-15.08-175.683-22.51-70.492-9.16-138.601-18.39-203.249-26.55-95.223-12.01-180.82-19.32-243.796-38.48-116.902-35.56-215.006-15.09-327.94 36.45-56.124 25.62-108.913 38.77-160.488 62.49-49.655 22.85-94.019 38.46-138.82 65.66-93.616 56.83-168.081 66.66-251.092 112.87-65.936 36.7-122.443 80.25-177.061 140.27-129.066 141.8-10.175 306.05-68.457 444.35-20.667 49.04-64.701 118.29-91.882 178.18-13.096 28.86-23.292 53.55-21.749 70.49 15.68 172.13-62.843 194.26-172.592 322.49-139.121 162.56-316.571 160.14-559.601 208.6-106.49 21.23-195.11 61.86-292.72 107.48-37.56 17.56-66.36 37.23-93.41 53.62-80.91 49.04-77.91 116.98-85.35 206.34-4.35 52.33 3.77 115.62 7.23 182.41 4.54 87.61 90.2 208.88 90.81 301.25.43 63.54-2.14 121.4-69.73 157.61-116.6 62.47-80.86 204.89-165.85 277.02-30.04 25.49-70.74 43.2-131.26 49.74-313.53 33.86-536.76 68.74-677.9 164.22-53.05 35.88-90.94 81.44-116.34 136.31-59.26 127.97-153.83 273.62-142.09 443.5 7.39 103.44 12 206.79-10.18 292.06-35.05 134.73-118.43 227.84-246.5 285.99-181.03 82.18-384.52 127.01-592.45 81.76-222.42-48.52-430.94-64.13-595.34-114.98" />
          <path d="M2272.34 439.239c-492.29 49.498-315.7 587.861-903.61 745.991-76.76 20.5-156.26 27.48-238.07 16.72-59.66-7.84-118.35-15.72-175.561-23.4-70.374-9.46-138.412-18.91-203.1-27.19-94.64-12.12-180.003-19.4-243.911-37.82-117.119-33.75-216.347-13.96-328.985 36.42-56.523 25.27-109.418 37.96-161.015 61.31-49.413 22.36-93.737 38.39-138.57 66.63-93.793 59.06-168.397 71.77-250.695 118.77-65.426 37.37-121.693 81.14-176.273 141.4-129.245 142.69-6.737 306.85-63.804 445.32-20.185 48.99-63.976 118.74-90.435 179.1-12.776 29.15-22.443 54.3-20.368 71.58 21.198 176.09-54.746 204.05-166.164 331.67-137.979 158.06-316.269 159.9-560.669 209.73-105.73 21.55-195.09 61.36-293.38 106.2-37.57 17.14-66.93 36.33-94.67 52.41-83.17 48.22-82.13 116.29-90.08 205.72-4.67 52.56 2.75 115.88 5.3 182.73 3.34 87.2 89.82 208.78 93.23 301.61 2.36 64.29.53 122.44-67.64 158.49-117.21 61.99-80.46 205.07-164.59 277.54-29.7 25.59-70.31 43.31-131.18 49.58-314.12 32.34-537.9 65.85-678.83 160.34-53.27 35.71-91.1 81.27-116.35 136.11-59.34 128.91-146.54 277.95-135.04 448.68 7.24 103.83 11.26 207.13-12.1 291.99-36.86 133.91-122.76 225.79-252.63 283-181.03 79.75-385.7 121.86-594.21 76.51-222.2-48.43-431.04-68-595.42-122.48" />
          <path d="M2263.14 469.634c-486.99 33.611-342.16 585.696-923.65 741.936-76.71 20.48-156.16 27.26-238 16.02-59.6-8.18-118.246-16.36-175.429-24.29-70.257-9.75-138.231-19.41-202.946-27.81-94.065-12.22-179.191-19.52-244.025-37.18-117.349-31.96-217.673-12.85-330.022 36.37-56.922 24.94-109.924 37.17-161.543 60.14-49.172 21.9-93.44 38.31-138.318 67.59-93.956 61.29-168.721 76.89-250.29 124.67-64.926 38.03-120.938 82.04-175.472 142.53-129.425 143.56-3.292 307.65-59.144 446.3-19.716 48.93-63.245 119.19-88.981 180.01-12.465 29.45-21.606 55.02-18.981 72.67 26.721 179.98-46.623 213.87-159.729 340.85-136.83 153.6-315.99 159.65-561.74 210.85-104.96 21.87-195.07 60.86-294.06 104.91-37.57 16.72-67.5 35.44-95.91 51.22-85.42 47.39-86.38 115.59-94.84 205.09-4.99 52.8 1.74 116.15 3.38 183.06 2.14 86.78 89.45 208.63 95.64 301.95 4.31 65.02 3.21 123.48-65.54 159.36-117.85 61.5-80.08 205.25-163.33 278.08-29.37 25.68-69.89 43.41-131.1 49.41-314.72 30.84-539.05 62.97-679.76 156.46-53.5 35.54-91.27 81.11-116.37 135.93-59.41 129.85-139.26 282.28-127.97 453.87 7.09 104.21 10.51 207.48-14.03 291.92-38.66 133.08-127.09 223.75-258.76 280.01-181.03 77.36-386.88 116.72-595.98 71.27-221.99-48.36-431.12-71.86-595.5-129.97" />
          <path d="M2252.88 495.933c-481.7 17.73-368.64 583.527-943.69 737.877-76.66 20.45-156.06 27.03-237.93 15.32-59.55-8.52-118.136-17-175.294-25.19-70.146-10.04-138.043-19.91-202.789-28.45-93.49-12.31-178.378-19.64-244.148-36.52-117.59-30.2-219.013-11.74-331.066 36.33-57.321 24.59-110.436 36.37-162.07 58.97-48.931 21.41-93.136 38.22-138.067 68.54-94.127 63.53-169.031 82.01-249.893 130.57-64.417 38.69-120.189 82.93-174.684 143.66-129.612 144.45.139 308.46-54.484 447.28-19.234 48.87-62.515 119.64-87.527 180.92-12.145 29.75-20.762 55.74-17.595 73.76 32.228 183.82-38.461 223.75-153.301 350.04-135.642 149.17-315.712 159.38-562.802 211.98-104.2 22.18-195.05 60.38-294.73 103.61-37.58 16.31-68.07 34.55-97.17 50.02-87.66 46.56-90.62 114.9-99.58 204.47-5.3 53.03.74 116.41 1.48 183.38.94 86.38 89.09 208.46 98.04 302.31 6.27 65.74 5.91 124.52-63.45 160.23-118.46 61.01-79.67 205.44-162.07 278.6-29.03 25.79-69.45 43.54-131.02 49.27-315.32 29.33-540.2 60.08-680.69 152.57-53.73 35.38-91.44 80.93-116.37 135.74-59.49 130.78-131.97 286.61-120.91 459.06 6.93 104.59 9.76 207.82-15.96 291.86-40.47 132.25-131.41 221.75-264.88 277.02-181.02 74.97-388.05 111.59-597.74 66.03-221.78-48.28-431.22-75.74-595.59-137.47" />
          <path d="M2241.67 518.552c-476.41 1.851-395.11 581.348-963.73 733.828-76.61 20.41-155.96 26.81-237.86 14.62-59.501-8.85-118.053-17.53-175.164-26.08-70.044-10.2-137.856-20.42-202.635-29.07-92.907-12.42-177.571-19.79-244.256-35.88-117.845-28.45-220.351-10.64-332.103 36.29-57.726 24.24-110.935 35.57-162.599 57.8-48.682 20.94-92.84 38.15-137.823 69.52-94.289 65.75-169.347 87.11-249.501 136.46-63.916 39.36-119.441 83.82-173.89 144.79-129.786 145.35 3.57 309.26-49.832 448.27-18.759 48.82-61.777 120.09-86.08 181.84-11.82 30.05-19.917 56.44-16.215 74.84 37.732 187.59-30.267 233.65-146.873 359.22-134.439 144.78-315.439 159.11-563.859 213.12-103.44 22.49-195.04 59.9-295.4 102.33-37.58 15.89-68.64 33.65-98.42 48.8-89.9 45.72-94.86 114.21-104.33 203.85-5.62 53.28-.26 116.68-.44 183.71-.25 85.96 88.74 208.26 100.45 302.66 8.24 66.46 8.59 125.56-61.36 161.12-119.08 60.52-79.29 205.61-160.81 279.12-28.7 25.89-69.03 43.65-130.94 49.1-315.93 27.84-541.37 57.2-681.63 148.7-53.96 35.2-91.6 80.76-116.38 135.56-59.56 131.7-124.69 290.92-113.84 464.23 6.79 104.99 9.01 208.18-17.88 291.8-42.27 131.42-135.74 219.77-271 274.02-181.04 72.61-389.24 106.45-599.51 60.79-221.57-48.19-431.31-79.61-595.67-144.97" />
          <path d="M2229.65 537.894c-471.11-14.037-421.57 579.186-983.77 729.776-76.55 20.37-155.86 26.58-237.78 13.91-59.449-9.19-117.946-18.17-175.034-26.96-69.932-10.51-137.667-20.93-202.485-29.71-92.325-12.52-176.77-19.94-244.378-35.23-118.112-26.71-221.69-9.55-333.147 36.25-58.131 23.88-111.435 34.79-163.127 56.63-48.442 20.47-92.543 38.07-137.571 70.48-94.453 67.97-169.665 92.23-249.097 142.35-63.407 40.02-118.691 84.71-173.095 145.92-129.972 146.23 7.002 310.06-45.178 449.23-18.284 48.78-61.046 120.55-84.626 182.76-11.5 30.34-19.072 57.15-14.828 75.93 43.212 191.29-22.035 243.6-140.438 368.4-133.216 140.43-315.166 158.81-564.926 214.25-102.68 22.79-195.02 59.43-296.08 101.04-37.59 15.48-69.2 32.75-99.67 47.59-92.14 44.88-99.1 113.53-109.06 203.24-5.95 53.5-1.26 116.94-2.37 184.03-1.42 85.56 88.41 208.05 102.86 303.02 10.21 67.15 11.27 126.61-59.26 162-119.7 60.04-78.88 205.8-159.53 279.65-28.38 25.98-68.61 43.76-130.86 48.94-316.53 26.36-542.53 54.3-682.56 144.81-54.19 35.03-91.76 80.6-116.4 135.38-59.63 132.64-117.4 295.26-106.78 469.42 6.63 105.37 8.26 208.53-19.82 291.74-44.06 130.6-140.06 217.82-277.12 271.03-181.06 70.29-390.41 101.3-601.28 55.54-221.35-48.12-431.4-83.47-595.75-152.46" />
          <path d="M2216.96 554.343c-465.82-29.91-448.03 576.997-1003.81 725.707-76.49 20.36-155.77 26.37-237.723 13.22a90899.258 90899.258 0 0 0-174.897-27.86c-69.815-10.81-137.486-21.43-202.331-30.34-91.75-12.61-175.975-20.11-244.486-34.57-118.385-25-223.027-8.47-334.184 36.2-58.535 23.53-111.94 34-163.655 55.46-48.193 19.99-92.246 38-137.313 71.44-94.602 70.21-169.982 97.35-248.699 148.26-62.906 40.68-117.826 85.71-172.302 147.05-129.858 147.38 10.426 310.87-40.518 450.21-17.816 48.72-60.301 121-83.179 183.68-11.174 30.64-18.233 57.84-13.442 77.02 48.682 194.97-13.764 253.61-134.01 377.59-131.991 136.1-314.901 158.49-565.991 215.38-101.93 23.09-195 58.96-296.75 99.74-37.59 15.07-69.76 31.86-100.92 46.39-94.36 44.02-103.36 112.84-113.82 202.62-6.26 53.75-2.24 117.22-4.28 184.36-2.6 85.15 88.07 207.82 105.26 303.36 12.2 67.83 13.95 127.67-57.16 162.88-120.33 59.57-78.64 205.83-158.28 280.18-28.09 26.03-68.18 43.87-130.79 48.78-317.13 24.87-543.68 51.41-683.49 140.93-54.42 34.85-91.92 80.42-116.4 135.19-59.69 133.57-110.12 299.58-99.72 474.6 6.47 105.76 7.51 208.88-21.74 291.67-45.86 129.78-144.38 215.91-283.24 268.03-181.1 67.99-391.6 96.16-603.05 50.3-221.15-48.04-431.5-87.34-595.84-159.95" />
          <path d="M2203.76 568.365c-460.53-45.797-474.51 574.825-1023.86 721.655-76.44 20.32-155.66 26.16-237.64 12.52a50697.588 50697.588 0 0 0-174.765-28.75c-69.696-11.12-137.298-21.94-202.182-30.98-91.174-12.7-175.178-20.29-244.608-33.92-118.684-23.3-224.364-7.4-335.228 36.16-58.94 23.17-112.447 33.21-164.182 54.29-47.953 19.52-91.943 37.91-137.069 72.41-94.765 72.43-170.292 102.46-248.294 154.15-62.398 41.34-117.078 86.6-171.515 148.18-130.051 148.25 13.85 311.67-35.859 451.19-17.34 48.67-59.562 121.45-81.724 184.59-10.855 30.93-17.401 58.53-12.061 78.1 54.134 198.59-5.463 263.66-127.582 386.78-130.741 131.81-314.651 158.15-567.051 216.51-101.18 23.39-194.98 58.51-297.42 98.45-37.6 14.66-70.32 30.96-102.17 45.2-96.58 43.16-107.6 112.14-118.55 201.99-6.6 53.98-3.22 117.5-6.21 184.69-3.76 84.76 87.75 207.56 107.68 303.72 14.19 68.5 16.63 128.72-55.07 163.75-120.95 59.09-78.24 206.02-157.01 280.71-27.76 26.13-67.76 43.99-130.7 48.63-317.73 23.4-544.85 48.52-684.43 137.05-54.65 34.67-92.09 80.26-116.4 135.01-59.76 134.5-102.84 303.91-92.66 479.78 6.31 106.15 6.77 209.23-23.67 291.61-47.66 128.97-148.7 214.01-289.36 265.04-181.14 65.71-392.78 91.02-604.82 45.06-220.93-47.96-431.59-91.2-595.93-167.45" />
          <path d="M2190.17 580.329c-455.23-61.684-500.98 572.651-1043.89 717.601-76.39 20.29-155.593 26.09-237.576 11.82-59.313-10.09-117.631-20.12-174.64-29.64-69.578-11.42-137.117-22.44-202.028-31.6-90.592-12.8-174.387-20.5-244.729-33.28-119.004-21.6-225.701-6.32-336.265 36.12-59.35 22.79-112.947 32.43-164.711 53.12-47.697 19.05-91.646 37.83-136.817 73.37-94.914 74.66-170.601 107.57-247.896 160.04-61.891 42.02-116.335 87.49-170.72 149.33-130.252 149.12 17.267 312.46-31.206 452.16-16.872 48.61-58.818 121.9-80.278 185.51-10.529 31.23-16.561 59.21-10.674 79.18 59.554 202.18 2.875 273.78-121.15 395.97-129.49 127.56-314.41 157.8-568.12 217.64-100.43 23.68-194.96 58.06-298.09 97.16-37.6 14.26-70.88 30.05-103.43 43.99-98.8 42.29-111.83 111.45-123.3 201.38-6.9 54.22-4.19 117.77-8.11 185.01-4.93 84.37 87.42 207.28 110.08 304.08 16.18 69.15 19.32 129.77-52.98 164.63-121.57 58.61-77.84 206.21-155.75 281.23-27.43 26.24-67.32 44.11-130.62 48.47-318.34 21.92-546 45.62-685.36 133.18-54.88 34.49-92.25 80.08-116.42 134.82-59.81 135.44-95.55 308.24-85.59 484.97 6.15 106.53 6.02 209.58-25.61 291.55-49.44 128.14-153.01 212.16-295.48 262.05-181.2 63.44-393.95 85.88-606.59 39.81-220.71-47.88-431.68-95.06-596.01-174.94" />
          <path d="M2176.33 590.66c-449.94-77.557-527.44 570.48-1063.94 713.54-76.34 20.26-155.489 25.86-237.493 11.12-59.262-10.43-117.532-20.76-174.51-30.53-69.466-11.72-136.929-22.94-201.871-32.24-90.017-12.9-173.603-20.72-244.838-32.62-119.335-19.95-227.037-5.27-337.309 36.08-59.753 22.41-113.446 31.64-165.238 51.94-47.451 18.59-91.342 37.75-136.566 74.33-95.071 76.9-170.919 112.69-247.505 165.94-61.382 42.67-115.593 88.37-169.926 150.45-130.457 149.98 20.685 313.26-26.546 453.13-16.397 48.57-58.071 122.35-78.822 186.43-10.211 31.52-15.728 59.89-9.288 80.27 64.948 205.73 11.259 283.95-114.718 405.15-128.21 123.35-314.16 157.44-569.19 218.76-99.67 23.98-194.93 57.63-298.77 95.87-37.6 13.85-71.42 29.16-104.68 42.79-101.01 41.43-116.09 110.76-128.04 200.75-7.24 54.45-5.17 118.05-10.04 185.33-6.08 83.98 87.09 206.98 112.48 304.43 18.18 69.78 22.01 130.81-50.89 165.51-122.19 58.14-77.43 206.4-154.49 281.76-27.09 26.34-66.9 44.24-130.54 48.32-318.96 20.45-547.17 42.71-686.29 129.28-55.12 34.31-92.41 79.92-116.43 134.64-59.87 136.37-88.26 312.55-78.53 490.15 6 106.92 5.28 209.95-27.53 291.48-51.23 127.34-157.33 210.33-301.6 259.06-181.26 61.2-395.12 80.73-608.35 34.57-220.51-47.81-431.77-98.94-596.1-182.44" />
          <path d="M2162.37 599.756c-444.64-93.444-553.91 568.314-1083.97 709.484-76.27 20.23-155.399 25.62-237.427 10.42-59.216-10.78-117.43-21.4-174.377-31.42-69.348-12.03-136.749-23.43-201.723-32.87-89.435-12.99-172.824-20.96-244.96-31.98-119.68-18.28-228.365-4.22-338.345 36.04-60.164 22.02-113.954 30.86-165.767 50.76-47.21 18.13-91.045 37.68-136.315 75.31-95.22 79.13-171.229 117.81-247.1 171.83-60.875 43.35-114.85 89.26-169.132 151.59-130.65 150.85 24.102 314.06-21.893 454.12-15.929 48.5-57.328 122.79-77.369 187.34-9.885 31.82-14.895 60.56-7.915 81.36 70.318 209.23 19.68 294.17-108.287 414.33-126.94 119.19-313.92 157.05-570.26 219.9-98.93 24.26-194.91 57.2-299.43 94.58-37.61 13.45-71.97 28.25-105.93 41.58-103.23 40.56-120.34 110.07-132.79 200.12-7.56 54.7-6.12 118.35-11.95 185.67-7.24 83.59 86.77 206.67 114.89 304.79 20.18 70.41 24.68 131.86-48.79 166.38-122.81 57.67-77.04 206.59-153.23 282.29-26.76 26.44-66.48 44.35-130.47 48.16-319.54 18.99-548.33 39.8-687.21 125.41-55.37 34.12-92.58 79.74-116.44 134.44-59.92 137.32-80.98 316.89-71.47 495.35 5.86 107.3 4.54 210.3-29.46 291.42-53.02 126.51-161.66 208.53-307.73 256.06-181.34 58.99-396.3 75.59-610.11 29.32-220.3-47.73-431.86-102.8-596.18-189.93" />
          <path d="M2148.43 608.02c-439.34-109.324-580.38 566.14-1104.01 705.43-76.227 20.2-155.295 25.39-237.359 9.71-59.164-11.12-117.33-22.05-174.245-32.31-69.23-12.33-136.562-23.93-201.569-33.5-88.86-13.07-172.056-21.21-245.067-31.33-120.045-16.64-229.7-3.17-339.39 36-60.573 21.63-114.447 30.09-166.294 49.59-46.963 17.67-90.735 37.6-136.064 76.27-95.369 81.36-171.539 122.93-246.702 177.73-60.367 44.01-114.109 90.14-168.345 152.72-130.845 151.72 27.512 314.87-17.233 455.1-15.46 48.45-56.582 123.24-75.922 188.26-9.552 32.11-14.069 61.24-6.528 82.45 75.661 212.73 28.143 304.48-101.862 423.51-125.66 115.07-313.7 156.65-571.32 221.03-98.19 24.54-194.89 56.78-300.1 93.29-37.61 13.05-72.53 27.34-107.18 40.37-105.43 39.68-124.59 109.37-137.54 199.51-7.88 54.92-7.08 118.63-13.87 185.99-8.39 83.21 86.44 206.32 117.3 305.14 22.18 71.01 27.38 132.92-46.69 167.25-123.44 57.2-76.64 206.79-151.96 282.81-26.43 26.55-66.06 44.48-130.39 48-320.16 17.53-549.5 36.89-688.15 121.53-55.6 33.94-92.74 79.57-116.45 134.26-59.98 138.26-73.7 321.21-64.41 500.53 5.69 107.68 3.8 210.66-31.39 291.35-54.81 125.71-165.98 206.77-313.85 253.07-181.43 56.8-397.48 70.44-611.89 24.07-220.08-47.64-431.96-106.67-596.27-197.42" />
          <path d="M2134.63 615.865c-434.05-125.205-606.84 563.965-1124.05 701.375-76.172 20.16-155.192 25.15-237.285 9.01-59.113-11.46-117.222-22.7-174.113-33.2-69.111-12.64-136.381-24.44-201.42-34.14-88.279-13.16-171.297-21.46-245.189-30.67-120.43-15.02-231.043-2.14-340.428 35.95-60.981 21.23-114.953 29.31-166.821 48.42-46.716 17.2-90.431 37.52-135.812 77.22-95.512 83.58-171.741 128.19-246.298 183.63-59.769 44.79-113.373 91.03-167.543 153.85-131.041 152.59 30.916 315.67-12.58 456.07-14.991 48.39-55.83 123.68-74.467 189.17-9.224 32.4-13.254 61.91-5.142 83.54 80.985 216.2 36.643 314.86-95.432 432.7-124.38 110.98-313.48 156.22-572.39 222.16-97.44 24.83-194.87 56.37-300.78 92-37.62 12.66-73.07 26.43-108.42 39.18-107.62 38.8-128.85 108.67-142.28 198.88-8.21 55.16-8.04 118.92-15.79 186.32-9.53 82.83 86.11 205.96 119.71 305.49 24.17 71.61 30.06 133.98-44.6 168.12-124.05 56.74-76.25 206.98-150.7 283.35-26.1 26.64-65.63 44.59-130.31 47.84-320.77 16.07-550.67 33.97-689.08 117.64-55.83 33.76-92.9 79.41-116.47 134.07-60.01 139.21-66.4 325.54-57.34 505.71 5.54 108.08 3.06 211.02-33.31 291.3-56.6 124.9-170.31 205.03-319.97 250.07-181.54 54.63-398.65 65.31-613.65 18.84-219.88-47.57-432.06-110.54-596.36-204.93" />
          <path d="M2121.16 623.726C1692.4 482.634 1487.84 1185.52 977.064 1321.04c-76.123 20.14-155.095 24.93-237.211 8.32-59.053-11.82-117.114-23.34-173.981-34.1-68.999-12.94-136.217-24.8-201.265-34.76-87.73-13.09-170.536-21.74-245.304-30.03-120.834-13.4-232.362-1.11-341.471 35.91-61.39 20.84-115.454 28.54-167.343 47.25-46.468 16.75-90.135 37.44-135.568 78.19-95.661 85.81-172.064 133.29-245.906 189.53-59.267 45.43-112.631 91.91-166.756 154.97-131.239 153.47 34.319 316.48-7.92 457.05-14.524 48.35-55.069 124.13-73.019 190.09-8.9 32.71-12.42 62.57-3.76 84.62 86.28 219.65 45.184 325.32-89 441.88-123.1 106.95-313.26 155.78-573.45 223.3-96.71 25.09-194.85 55.96-301.45 90.71-37.63 12.27-73.62 25.52-109.68 37.97-109.81 37.92-133.1 107.98-147.02 198.27-8.54 55.39-8.99 119.21-17.71 186.64-10.67 82.46 85.77 205.58 122.11 305.84 26.17 72.2 32.74 135.04-42.5 169-124.68 56.28-75.84 207.17-149.44 283.87-25.76 26.76-65.21 44.73-130.23 47.68-321.38 14.63-551.84 31.06-690.01 113.77-56.07 33.56-93.05 79.24-116.48 133.88-60.07 140.14-59.12 329.87-50.28 510.9 5.39 108.46 2.32 211.38-35.25 291.23-58.37 124.09-174.64 203.31-326.09 247.07-181.68 52.49-399.83 60.17-615.43 13.6-219.66-47.49-432.13-114.41-596.43-212.42" />
          <path d="M2108.12 631.998c-423.47-156.965-659.79 559.622-1164.131 693.252-76.066 20.12-154.994 24.69-237.141 7.63-59.008-12.17-117.014-23.99-173.856-35-68.881-13.24-136.027-25.33-201.109-35.41-87.153-13.21-169.788-22.02-245.42-29.37-121.259-11.78-233.695-.09-342.507 35.87-61.799 20.43-115.956 27.77-167.871 46.08-46.222 16.29-89.747 37.44-135.316 79.15-95.656 88.2-172.387 138.39-245.502 195.41-58.765 46.09-111.889 92.8-165.962 156.12-131.445 154.34 37.723 317.27-3.26 458.03-14.056 48.29-54.315 124.57-71.565 191-8.57 33-11.6 63.23-2.38 85.71 91.551 223.08 53.784 335.86-82.57 451.07-121.83 102.95-313.06 155.3-574.51 224.42-95.97 25.38-194.83 55.56-302.12 89.42-37.63 11.88-74.16 24.61-110.93 36.77-112 37.03-137.35 107.28-151.76 197.64-8.87 55.62-9.94 119.51-19.64 186.97-11.8 82.1 85.44 205.19 124.53 306.2 28.16 72.77 35.43 136.09-40.41 169.88-125.29 55.81-75.43 207.35-148.17 284.4-25.42 26.85-64.78 44.84-130.14 47.53-322.01 13.18-553.01 28.13-690.95 109.88-56.3 33.38-93.21 79.07-116.49 133.7-60.11 141.09-51.83 334.2-43.21 516.08 5.22 108.85 1.58 211.75-37.18 291.17-60.16 123.29-178.97 201.64-332.21 244.09-181.82 50.35-401.01 55.02-617.18 8.35-219.45-47.41-432.24-118.28-596.53-219.93" />
          <path d="M2095.64 641.058C1677.46 468.206 1409.38 1198.5 911.461 1330.26c-76.008 20.08-154.895 24.46-237.064 6.92-58.956-12.51-116.906-24.63-173.731-35.88-68.763-13.55-135.839-25.85-200.955-36.03-86.568-13.33-169.038-22.33-245.533-28.73-121.704-10.19-235.022.93-343.552 35.83-62.213 20.01-116.456 27-168.399 44.9-45.975 15.84-89.462 37.34-135.058 80.12-95.83 90.39-172.709 143.47-245.104 201.32-58.264 46.74-111.147 93.68-165.165 157.23-131.64 155.21 40.872 318.01 1.39 459.02-13.68 48.21-53.57 125-70.12 191.92-8.23 33.29-10.77 63.89-.99 86.8 96.794 226.49 62.41 346.49-76.14 460.25-120.57 99.01-312.87 154.83-575.58 225.56-95.23 25.64-194.81 55.18-302.79 88.13-37.64 11.49-74.71 23.71-112.19 35.57-114.18 36.14-141.6 106.58-156.51 197.02-9.19 55.86-10.88 119.82-21.55 187.3-12.93 81.73 85.09 204.77 126.93 306.55 30.15 73.35 38.18 137.3-38.32 170.76-125.81 55.58-75.03 207.55-146.91 284.93-25.09 26.95-64.36 45.09-130.06 47.36-322.6 12.35-554.18 25.21-691.88 106.01-56.54 33.18-93.27 78.94-116.49 133.51-59.9 142.13-44.56 338.52-36.15 521.27 5.07 109.23.96 212.16-39.11 291.1-61.75 122.6-183.31 200-338.33 241.09-181.98 48.24-402.19 49.88-618.95 3.11-219.24-47.33-432.33-122.15-596.61-227.42" />
          <path d="M2083.92 651.398C1671.05 462.666 1371.2 1206.67 879.705 1336.54c-75.957 20.04-154.796 24.23-236.994 6.21-58.904-12.85-116.805-25.28-173.599-36.77-68.645-13.85-135.657-26.37-200.806-36.67-85.985-13.45-168.303-22.63-245.649-28.07-122.162-8.6-236.34 1.94-344.589 35.79-62.62 19.58-116.95 26.23-168.926 43.72-45.729 15.39-89.171 37.24-134.815 81.09-96.01 92.57-173.032 148.57-244.706 207.22-57.768 47.38-110.404 94.56-164.371 158.37-131.84 156.08 44.343 318.83 6.04 459.99-13.18 48.16-52.68 125.48-68.67 192.82-7.84 33.6-9.96 64.55.39 87.88 102.014 229.89 71.09 357.22-69.71 469.44-119.33 95.12-312.68 154.33-576.64 226.69-94.51 25.91-194.79 54.8-303.46 86.84-37.65 11.11-75.25 22.8-113.45 34.36-116.37 35.26-145.99 105.86-161.25 196.39-9.62 56.09-11.81 120.13-23.47 187.63-14.06 81.38 84.74 204.35 129.33 306.9 32.14 73.91 40.85 138.31-36.23 171.64-126.46 55.04-74.63 207.74-145.64 285.45-24.76 27.07-63.95 45.19-129.99 47.21-323.23 10.72-555.49 22.05-692.81 102.13-56.84 32.89-93.46 78.75-116.51 133.32-60.01 143.04-37.26 342.85-29.09 526.45 4.91 109.62.19 212.52-41.04 291.03-63.6 121.77-187.65 198.37-344.45 238.1-182.17 46.15-403.37 44.75-620.72-2.13-219.03-47.25-432.42-126-596.69-234.91" />
          <path d="M2073.38 663.77c-407.59-204.612-739.2 553.1-1224.259 681.09-75.906 20.01-154.696 23.99-236.924 5.51-58.852-13.19-116.704-25.92-173.468-37.66-68.533-14.15-135.468-26.88-200.651-37.3-85.407-13.57-167.573-22.95-245.763-27.42-122.64-7.02-237.665 2.93-345.633 35.75-63.027 19.16-117.435 25.53-169.462 42.55-45.464 15-88.886 37.14-134.563 82.05-96.19 94.75-173.347 153.67-244.307 213.11-57.266 48.03-109.66 95.44-163.59 159.5-132.04 156.94 47.82 319.66 10.71 460.96-12.7 48.12-51.99 125.9-67.23 193.75-7.54 33.88-9.13 65.2 1.78 88.97 107.22 233.26 79.81 368.05-63.28 478.62-118.1 91.26-312.64 153.3-577.71 227.81-93.82 26-194.77 54.44-304.13 85.55-37.66 10.71-75.8 21.81-114.69 33.16-118.6 34.17-150.19 105.17-166 195.77-9.91 56.31-12.75 120.44-25.4 187.96-15.18 81.02 84.39 203.9 131.75 307.25 34.12 74.46 43.5 139.33-34.14 172.51-127.11 54.51-74.23 207.93-144.38 285.99-24.43 27.16-63.52 45.27-129.91 47.05-323.84 9.08-556.58 19.22-693.74 98.24-57.06 32.75-93.64 78.58-116.52 133.15-60.13 143.95-29.98 347.17-22.03 531.63 4.76 110.01-.59 212.87-42.96 290.97-65.44 120.95-192.01 196.78-350.58 235.11-182.38 44.07-404.55 39.6-622.5-7.38-218.81-47.17-432.51-129.88-596.77-242.41" />
          <path d="M2065.28 679.864c-466.04-255.44-879.86 820.346-1481.146 681.846-58.807-13.54-116.604-26.57-173.336-38.56-68.415-14.46-135.278-27.4-200.502-37.92-217.013-35.03-415.765-43.25-592.549 8.93-118.583 34.99-205.999 25.53-304.301 124.39-174.266 175.28-286.18 235.8-406.696 379.64-132.23 157.81 51.29 320.48 15.37 461.94-18.31 72.11-97.08 212.12-62.61 284.72 245.076 516.02-157.52 583.69-1056.37 832.97-195.99 54.35-162.54 207.64-198.05 383.43-28.04 138.79 288.96 402.07 102.11 481-172.21 72.74-14.33 327.46-272.95 333.4-324.48 7.44-557.69 16.4-694.67 94.36-299.86 170.66-32.26 703.41-176.38 960.69-220.57 393.73-1105.16 303.83-1577.82-30.41" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h1200v900H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" transform="rotate(-40.99 2941.791 10272.886)" d="M0 0h9260.57v2608.57H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
