import { ENV } from 'services/environment/environment';
import { useCurrentPartner } from 'services/repositories/partners/partners';

export const useOnboarding = () => {
  const { data: currentPartner, isLoading: isLoadingCurrentPartner } = useCurrentPartner();

  const finishedOnboarding =
    Boolean(currentPartner?.onboardingSteps?.some((onboarginStep) => onboarginStep.integration_is_active)) ||
    Boolean(currentPartner?.onboardingSteps?.length === 0) ||
    Boolean(ENV.APP_ENV === 'staging');

  return {
    steps: currentPartner?.onboardingSteps,
    isFinished: finishedOnboarding,
    isLoading: isLoadingCurrentPartner,
  };
};
