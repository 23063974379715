import { RowData } from '@tanstack/table-core';
import { ColumnTypesInternal } from '../Types';
import { createColumnHelper } from '@tanstack/react-table';
import { SelectionCell } from '../cells/SelectionCell';

export const selectionPartial = <TData extends RowData>(columnHelper: ReturnType<typeof createColumnHelper<TData>>) =>
  columnHelper.display({
    id: ColumnTypesInternal.procuros_select,
    meta: {
      type: ColumnTypesInternal.procuros_select,
    },
    header: ({ table }) => (
      <SelectionCell
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        indeterminate={table.getIsSomeRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <SelectionCell
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  });
