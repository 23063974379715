import { useTranslation } from 'react-i18next';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { TableHeader } from 'components/ListTable/types';
import { Page } from 'components/Page/Page';
import { IntegrationConfigurationSteps, IntegrationDTO, IntegrationsRoutes, UserDTO } from 'support/types';
import { useInfiniteIntegrations } from 'services/repositories/integrations/integrations';
import { Placeholder } from 'components/Placeholder/Placeholder';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Form/Button/Button';
import { PlugIcon } from 'components/CustomIcons/PlugIcon';
import { getIntegrationStatusBadgeColor } from './helpers';
import { useCurrentUser } from 'services/repositories/user/user';
import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { ListTable } from 'components/ListTable/ListTable';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToPage } from 'support/helpers/navigation/navigation';

export function Integrations() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();

  const {
    data: integrationsData,
    isLoading: integrationsLoading,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteIntegrations({
    variables: { query: {} },
  });

  const tableHeaders: Array<TableHeader> = [
    { label: t('integrations:list.tableHeaders.name') },
    { label: t('integrations:list.tableHeaders.type') },
    { label: t('integrations:list.tableHeaders.environment') },
    { label: t('integrations:list.tableHeaders.status') },
    { label: t('integrations:list.tableHeaders.lastUpdate') },
  ];

  const buildRow = (integration: IntegrationDTO) => {
    return [
      {
        element: <div className="text-sm text-gray-900">{integration.name}</div>,
        key: `name-${integration.id}`,
      },
      {
        element: (
          <div className="text-sm text-gray-900">{t(`integrations:connector.${integration.connector}.name`)}</div>
        ),
        key: `connector-${integration.id}`,
      },
      {
        element: (
          <Badge color={getIntegrationEnvironmentBadgeColor(integration.environment)}>
            {t(`integrations:environments.${integration.environment}`)}
          </Badge>
        ),
        key: `environment-${integration.id}`,
      },
      {
        element: (
          <Badge color={getIntegrationStatusBadgeColor(integration.state)}>
            {t(`integrations:list.status.${integration.state}`)}
          </Badge>
        ),
        key: `status-${integration.id}`,
      },
      {
        element: <div className="text-sm tabular-nums text-gray-900">{formatDayAndTime(integration.updatedAt)}</div>,
        key: `updatedAt-${integration.id}`,
      },
    ];
  };

  return (
    <Page>
      <Page.Head
        title={t('common:mainMenu.integrations')}
        description={t('integrations:description')}
        rightContent={
          <div>
            <Button onClick={() => navigate(IntegrationsRoutes.new)} analyticsId="integrations:create-navigate">
              {t('integrations:list.new')}
            </Button>
          </div>
        }
      />

      <Page.Section>
        {integrationsData?.pages?.flatMap(({ items }) => items).length || integrationsLoading ? (
          <ListTable<IntegrationDTO>
            headers={tableHeaders}
            data={integrationsData?.pages?.flatMap(({ items }) => items)}
            isLoading={integrationsLoading}
            rowBuilder={buildRow}
            rowClickPath={integrationClickPath(currentUser)}
            pagination={
              <InfiniteScroll
                hasMore={Boolean(hasNextPage)}
                loadMore={fetchNextPage}
                loading={isFetchingNextPage}
                noMoreItemsEnabled={Boolean(hasPreviousPage)}
              />
            }
          />
        ) : (
          <Placeholder
            title={t('integrations:list.emptyState.title')}
            description={t('integrations:list.emptyState.description')}
            ctaText={t('integrations:list.new')}
            ctaOnClick={() => navigate(IntegrationsRoutes.new)}
            icon={<PlugIcon />}
          />
        )}
      </Page.Section>
    </Page>
  );
}

const integrationClickPath = (currentUser?: UserDTO) => (integration: IntegrationDTO) => {
  if (!currentUser || !currentUser.isAdmin) {
    return undefined;
  }

  if (integration.state === 'READY') {
    return routeToPage(IntegrationsRoutes.view, { id: integration.id });
  }

  const step = integration.hasValidConfiguration
    ? IntegrationConfigurationSteps.TEST
    : IntegrationConfigurationSteps.CONFIGURATION;
  return routeToPage(IntegrationsRoutes.edit, { id: integration.id, step });
};

const getIntegrationEnvironmentBadgeColor = (status?: IntegrationDTO['environment'] | null): BadgeColor => {
  switch (status) {
    case 'TESTING':
      return BadgeColor.gray;
    case 'PRODUCTION':
    default:
      return BadgeColor.blue;
  }
};

/**
 * i18n
 * t("integrations:list.status.REQUESTED")
 * t("integrations:list.status.IN_TESTING")
 * t("integrations:list.status.READY")
 * t("integrations:list.status.DEACTIVATED")
 * t("integrations:list.status.UNSUPPORTED")
 *
 *
 * t("integrations:environments.TESTING")
 * t("integrations:environments.PRODUCTION")
 */
