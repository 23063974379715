import * as Sentry from '@sentry/react';

const getExtraProperties = (error: Error) =>
  Object.fromEntries(Object.entries(error).filter(([key]) => !['name', 'message', 'stack'].includes(key)));

export const logError = (error: unknown) => {
  Sentry.withScope((scope) => {
    scope.setExtras(getExtraProperties(error as Error));
    Sentry.captureException(error);
  });
};
