import { useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { isValid } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import 'react-day-picker/dist/style.css';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { Input } from '../Input/Input/Input';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { formatDay } from 'support/helpers/dateTime/dateTime';
import classNames from 'classnames';

type RangeDatePickerProps = {
  placeholderText: string;
  labelText?: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates?: DateRange) => void;
  className?: string;
};

export function RangeDatePicker({
  placeholderText,
  labelText,
  startDate,
  endDate,
  onChange,
  className,
}: RangeDatePickerProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isVisible,
    onOpenChange: setIsVisible,
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip(), shift()],
  });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const [lastToDate, setLastToDate] = useState<Date | undefined>(undefined);
  const [month, setMonth] = useState<Date>(startDate ?? new Date());
  const defaultSelected: DateRange = {
    from: startDate ?? undefined,
    to: endDate ?? undefined,
  };

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const showMe = () => {
    if (range?.from) {
      setMonth(range.from);
    }

    setIsVisible(true);
  };

  const onSelect = (newDates?: DateRange) => {
    setRange(newDates);
    onChange(newDates);

    if (newDates?.to && newDates?.to !== lastToDate) {
      setIsVisible(false);
      setLastToDate(newDates?.to);
    }

    if (newDates?.from) {
      setMonth(newDates?.from);
    }
  };

  const displayValue = () => {
    if (!range || !range.from || !range.to || !isValid(range.from) || !isValid(range.to)) {
      return '';
    }

    return `${formatDay(range.from.toISOString())} - ${formatDay(range.to.toISOString())}`;
  };

  return (
    <>
      <Input
        leftIcon={<CalendarIcon className="size-5 text-black" />}
        label={labelText}
        readOnly
        onClick={showMe}
        type="text"
        value={displayValue()}
        className={classNames(className, '!cursor-default')}
        placeholder={placeholderText}
        ref={refs.setReference}
        {...getReferenceProps()}
      />

      {isVisible && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: () => setIsVisible(false),
          }}
        >
          <FloatingPortal>
            <div
              className="z-20 bg-white shadow-md"
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <DayPicker
                mode="range"
                disabled={{ after: new Date() }}
                selected={range}
                onSelect={onSelect}
                month={month}
                onMonthChange={setMonth}
                modifiersClassNames={{
                  selected: 'rdp-selected',
                  today: 'rdp-today',
                }}
              />
            </div>
          </FloatingPortal>
        </FocusTrap>
      )}
    </>
  );
}
