import { useMemo } from 'react';
import { Badge } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { PropertyFilter, FilterValue } from 'components/ListTable/types';
import { formatDay } from 'support/helpers/dateTime/dateTime';

const isDefaultValue = (defaultValues: Array<PropertyFilter>, filter: FilterValue) => {
  return defaultValues.some(
    (defaultValue) =>
      defaultValue.key === filter.key &&
      defaultValue.dropDownOptions?.defaultToFirstValue &&
      filter.value === defaultValue.dropDownOptions.dropdownValues[0].label,
  );
};

const formatDate = (date: string): string => {
  const [fromDate, toDate] = date.split(',');
  return `${formatDay(fromDate)} - ${formatDay(toDate)}`;
};
export const FilterBadges = ({
  reset,
  filters,
  remove,
  filtersConfig,
}: {
  reset: () => void;
  filters: Array<FilterValue>;
  remove: (key: string) => void;
  filtersConfig: Array<PropertyFilter>;
}) => {
  const { t } = useTranslation();
  const filteredFilters = useMemo(() => filters.filter((filter) => filter.value), [filters]);

  if (!filteredFilters.length) {
    return null;
  }

  if (!filteredFilters.length) {
    return null;
  }
  return (
    <div className="flex flex-wrap items-center gap-2">
      <span className="text-xs text-gray-500">{t('common:components.filters.filteredBy')}</span>
      {filteredFilters.map((filter) => (
        <Badge
          key={filter.key}
          type="filters"
          className="max-w-xs"
          onRemove={
            isDefaultValue(filtersConfig, filter)
              ? undefined
              : () => {
                  remove(filter.key);
                }
          }
        >
          {getFilterLabel(filtersConfig, filter)}:{' '}
          {isDate(filtersConfig, filter) ? formatDate(filter.value) : filter.value}
        </Badge>
      ))}
      <Button variant="minimal" size="extra-small" onClick={reset} analyticsId="filters_clear_all">
        {t('common:components.filters.clearAllFilters')}
      </Button>
    </div>
  );
};

const getFilterLabel = (filtersConfig: Array<PropertyFilter>, filter: FilterValue) => {
  const filterConfig = filtersConfig.find((filterConfig) => filterConfig.key === filter.key);
  return filterConfig?.label || filterConfig?.placeholder || filter.key;
};

const isDate = (filtersConfig: Array<PropertyFilter>, filter: FilterValue) => {
  return filtersConfig.some((filterConfig) => filterConfig.key === filter.key && filterConfig.type === 'date');
};
