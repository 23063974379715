import { DocumentType } from 'support/types';
import { ShippingNoticeSummary } from './components/ShippingNoticeSummary';
import { OrderSummary } from './components/OrderSummary';
import { OrderResponseSummary } from './components/OrderResponseSummary';
import { DispatchInstructionSummary } from './components/DispatchInstructionSummary';
import { PriceCatalogSummary } from './components/PriceCatalogSummary';
import { CreditNoteSummary } from './components/CreditNoteSummary';
import { InvoiceSummary } from './components/InvoiceSummary';

export const getDocumentSummary = (documentType: DocumentType | undefined) => {
  switch (documentType) {
    case DocumentType.order:
      return <OrderSummary />;
    case DocumentType.orderResponse:
      return <OrderResponseSummary />;
    case DocumentType.dispatchInstruction:
      return <DispatchInstructionSummary />;
    case DocumentType.shippingNotice:
    case DocumentType.dispatchResponse:
      return <ShippingNoticeSummary />;
    case DocumentType.invoice:
      return <InvoiceSummary />;
    case DocumentType.creditNote:
      return <CreditNoteSummary />;
    case DocumentType.productCatalog:
      return <PriceCatalogSummary />;
    default:
      return null;
  }
};

export const hasDocumentSummary = (documentType: DocumentType | undefined) => {
  return Boolean(getDocumentSummary(documentType));
};
