import classNames from 'classnames';
import { ReactNode, forwardRef, Ref } from 'react';

type LeadingAddOnProps = {
  leadingAddOn?: ReactNode | string;
  hasError?: boolean;
  show: boolean;
};

export const LeadingAddOn = forwardRef(
  ({ leadingAddOn, hasError, show }: LeadingAddOnProps, ref: Ref<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={classNames({
          'absolute inset-y-0 left-0 flex items-center pl-3': show,
          'pointer-events-none': typeof leadingAddOn === 'string' && show,
          'size-0': !show,
        })}
      >
        {show && leadingAddOn ? (
          <span className={classNames({ 'text-gray-500': !hasError }, 'group-focus-within:text-gray-500 sm:text-sm')}>
            {leadingAddOn}
          </span>
        ) : null}
      </div>
    );
  },
);

LeadingAddOn.displayName = 'LeadingAddOn';
