import { Connectors, IntegrationConfigurationSteps, IntegrationDTO } from 'support/types';
import { IntegrationConfigurationForm } from '../IntegrationConfigurationForm/IntegrationConfigurationForm';
import { IntegrationTestStep } from '../IntegrationTestStep/IntegrationTestStep';
import { AS2ServerInformation } from './AS2/AS2ServerInformation';
import { WebEDIInitialInformation } from './WebEDI/WebEDIInitialInformation';
import { AS2ConfigurationForm } from './AS2/AS2ConfigurationForm';
import { WebEDIConfigurationForm } from './WebEDI/WebEDIConfigurationForm';
import { Page } from 'components/Page/Page';
import { useTranslation } from 'react-i18next';
import { as2DefaultConfiguration } from './AS2/helpers';
import { useCurrentPartner } from 'services/repositories/partners/partners';

type ConnectorStepConfiguration = {
  key: IntegrationConfigurationSteps;
  content: React.ReactNode;
};

export const ConnectorSteps = ({
  connector,
  integration,
  isLoadingIntegration,
  stepKey,
}: {
  connector: Connectors;
  isLoadingIntegration: boolean;
  integration: IntegrationDTO | undefined;
  stepKey: string | undefined;
}) => {
  const { t } = useTranslation();
  const { data: currentPartner } = useCurrentPartner();

  let steps: Array<ConnectorStepConfiguration> = [];
  switch (connector) {
    case Connectors.AS2:
      steps = [
        {
          key: IntegrationConfigurationSteps.CONFIGURATION,
          content: currentPartner ? (
            <IntegrationConfigurationForm
              partner={currentPartner}
              integration={
                integration ?? {
                  handle: '',
                  connector: Connectors.AS2,
                  name: '',
                  environment: 'PRODUCTION',
                }
              }
              mainForm={<AS2ConfigurationForm />}
              header={{ environment: true, name: true }}
              defaultConfiguration={as2DefaultConfiguration}
            />
          ) : null,
        },
        {
          key: IntegrationConfigurationSteps.TEST,
          content: integration ? (
            <IntegrationTestStep
              integration={integration}
              rightColumn={<AS2ServerInformation integration={integration} />}
            />
          ) : null,
        },
      ];
      break;
    case Connectors.WEB_EDI:
      steps = [
        {
          key: IntegrationConfigurationSteps.INITIAL_INFORMATION,
          content: <WebEDIInitialInformation />,
        },
        {
          key: IntegrationConfigurationSteps.CONFIGURATION,
          content: currentPartner ? (
            <IntegrationConfigurationForm
              partner={currentPartner}
              integration={
                integration ?? {
                  handle: '',
                  connector,
                  name: '',
                  environment: 'PRODUCTION',
                }
              }
              mainForm={<WebEDIConfigurationForm />}
              header={{ environment: false, name: false }}
              defaultConfiguration={{
                billFromAddressIdentifier: currentPartner?.settings?.billFromAddressIdentifier,
                taxId: currentPartner?.settings?.billFromFederalTaxIdentifier,
                vatId: currentPartner?.settings?.billFromVatIdentifier,
                email: currentPartner?.settings?.invoiceToEmailAddress,
              }}
            />
          ) : null,
        },
      ];
      break;
  }

  const step = steps.find(({ key }) => stepKey === key) || steps[0];

  return (
    <Page isLoading={isLoadingIntegration}>
      {step ? (
        <>
          <Page.Head title={t(`integrations:new.title.${step.key}.${connector}`)} />
          <Page.Section>{step.content}</Page.Section>
        </>
      ) : null}
    </Page>
  );
};

// Translations

// t('integrations:new.title.CONFIGURATION.AS2')
// t('integrations:new.title.TEST.AS2')

// t('integrations:new.title.INITIAL_INFORMATION.WEB_EDI')
// t('integrations:new.title.CONFIGURATION.WEB_EDI')
