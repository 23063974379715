import { useState, useEffect } from 'react';

export const useEmptyStructure = <T extends object>(objects: Array<T> | undefined): EmptyValue<T> => {
  const [emptyStructure, setEmptyStructure] = useState<EmptyValue<T>>({} as EmptyValue<T>);

  useEffect(() => {
    if (objects && objects.length > 0) {
      setEmptyStructure(createEmptyObject(objects[0]));
    }
    // We don't update emptyStructure if objects is empty
  }, [objects]);

  return emptyStructure;
};

type EmptyValue<T> = T extends string
  ? ''
  : T extends number
    ? 0
    : T extends Array<infer U>
      ? Array<EmptyValue<U>>
      : T extends object
        ? { [K in keyof T]: EmptyValue<T[K]> }
        : T;

function createEmptyObject<T extends object>(obj: T): EmptyValue<T> {
  if (Array.isArray(obj)) {
    return [] as EmptyValue<T>;
  }

  const result: any = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (typeof value === 'string' || typeof value === 'number') {
        result[key] = '';
      } else if (Array.isArray(value)) {
        result[key] = [];
      } else if (typeof value === 'object' && value !== null) {
        result[key] = createEmptyObject(value);
      } else {
        result[key] = value;
      }
    }
  }

  return result as EmptyValue<T>;
}
