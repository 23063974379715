import { useMemo } from 'react';
import { useInfinitePartners } from 'services/repositories/partners/partners';
import { RelationshipPartnerStatus, DocumentType } from 'support/types/dtos';

export const usePriceConfigsPartners = () => {
  const { data: connectedPartnersData, ...others } = useInfinitePartners({
    variables: {
      query: {
        filterValues: [
          { key: 'status', value: `${RelationshipPartnerStatus.connected},${RelationshipPartnerStatus.pending}` },
        ],
        cursor: null,
        perPage: null,
      },
    },
  });

  const partnersWithInvoices = useMemo(() => {
    return (
      connectedPartnersData?.pages[0]?.items.filter((partner) =>
        partner.documentTypes?.receives.some(({ type, relationshipStatus }) => {
          return (
            type === DocumentType.invoice &&
            [RelationshipPartnerStatus.connected, RelationshipPartnerStatus.pending].includes(
              relationshipStatus as RelationshipPartnerStatus,
            )
          );
        }),
      ) || []
    );
  }, [connectedPartnersData]);

  return { ...others, data: partnersWithInvoices };
};
