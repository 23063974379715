import { useMemo } from 'react';
import { useDatachecksStore } from 'stores/datachecks/datachecks';

type PriceCatalogSummaryValues = {
  lineCount: number;
  creationDate?: number;
};

export const usePriceCatalogSummary = (): PriceCatalogSummaryValues => {
  const { canonical } = useDatachecksStore();

  return useMemo(
    () => ({
      lineCount: canonical?.line_items?.length ?? 0,
      creationDate: canonical?.catalog_date,
    }),
    [canonical?.line_items, canonical?.catalog_date],
  );
};
