import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { t } from 'i18next';
import { logError } from 'services/logging/logging';
import { GenericError } from 'support/helpers/errors/errors';
import { InternalTransactionState, InternalTransactionStateType } from 'support/types';

const getColourAndText = (
  state: TestingFlowMessageStateDisplayProps['state'],
  receiver: TestingFlowMessageStateDisplayProps['receiver'],
): { colour: BadgeColor; text: string } => {
  if (!state) return { colour: BadgeColor.gray, text: '' };

  if (receiver) {
    switch (state) {
      case InternalTransactionState.delivered:
        return { colour: BadgeColor.green, text: t('connections:testingFlowStates.delivered') };
      case InternalTransactionState.initial:
      case InternalTransactionState.denormalized:
      case InternalTransactionState.decanonicalized:
      case InternalTransactionState.pendingAutoRecovery:
      case InternalTransactionState.deliveryPending:
      case InternalTransactionState.validated_for_destination:
        return { colour: BadgeColor.yellow, text: t('connections:testingFlowStates.transmitting') };
      case InternalTransactionState.pendingPickup:
        return { colour: BadgeColor.yellow, text: t('connections:testingFlowStates.pendingPickup') };
      case InternalTransactionState.deliverySimulated:
        return { colour: BadgeColor.blue, text: t('connections:testingFlowStates.readyForDelivery') };
      case InternalTransactionState.pickupFailed:
      case InternalTransactionState.deliverySimulationFailed:
      case InternalTransactionState.deliveryFailed:
      case InternalTransactionState.decanonicalizedFailed:
      case InternalTransactionState.denormalizationFailed:
      case InternalTransactionState.validation_for_destination_failed:
        return { colour: BadgeColor.red, text: t('connections:testingFlowStates.failed') };
      case InternalTransactionState.dropped:
        return { colour: BadgeColor.gray, text: t('connections:testingFlowStates.dropped') };
    }

    logError(new GenericError('Internal state of transaction was not translated for the receiver', state));

    return { colour: BadgeColor.gray, text: String(state) };
  } else {
    switch (state) {
      case InternalTransactionState.initial:
      case InternalTransactionState.received:
      case InternalTransactionState.normalized:
      case InternalTransactionState.canonicalized:
      case InternalTransactionState.enriched:
      case InternalTransactionState.pendingAutoRecovery:
      case InternalTransactionState.routed:
      case InternalTransactionState.validated_for_destination:
        return { colour: BadgeColor.yellow, text: t('connections:testingFlowStates.processing') };

      case InternalTransactionState.receivalFailed:
      case InternalTransactionState.normalizationFailed:
      case InternalTransactionState.canonicalizationFailed:
      case InternalTransactionState.enrichedFailed:
      case InternalTransactionState.routingFailed:
      case InternalTransactionState.validation_for_destination_failed:
        return { colour: BadgeColor.red, text: t('connections:testingFlowStates.invalidForTesting') };
      case InternalTransactionState.dropped:
        return { colour: BadgeColor.gray, text: t('connections:testingFlowStates.dropped') };
      case InternalTransactionState.pendingRelease:
        return { colour: BadgeColor.green, text: t('connections:testingFlowStates.validForTesting') };
    }

    logError(new GenericError('Internal state of transaction was not translated for the sender', state));

    return { colour: BadgeColor.gray, text: String(state) };
  }
};

type TestingFlowMessageStateDisplayProps = {
  state: InternalTransactionStateType | undefined;
  receiver: boolean;
};

export function TestingFlowMessageStateDisplay({ state, receiver }: TestingFlowMessageStateDisplayProps) {
  const { colour, text } = getColourAndText(state, receiver);

  return <Badge color={colour}>{text}</Badge>;
}
