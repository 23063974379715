import { useCallback, useState } from 'react';
import { ColumnDef, Row } from '@tanstack/react-table';
import { ColumnTypesInternal } from '../Types';

export type Props<TData, TValue> = {
  columns: Array<ColumnDef<TData, TValue>>;
  rowSelectionFn?: (row: Row<TData>) => boolean;
};

export const useRowSelection = <TData, TValue>({ columns, rowSelectionFn }: Props<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState({});
  const hasSelectionColumn = columns.some(({ id }) => id === ColumnTypesInternal.procuros_select);
  const displayWidget = hasSelectionColumn && Object.keys(rowSelection).length > 0;
  const enableRowSelection = hasSelectionColumn ? rowSelectionFn || true : false;
  const clearSelection = useCallback(() => {
    setRowSelection({});
  }, []);
  return { rowSelection, enableRowSelection, setRowSelection, displayWidget, clearSelection };
};
