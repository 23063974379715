import { FloatingPortal } from '@floating-ui/react';
import { CSSProperties, forwardRef, ReactNode } from 'react';

type MessagePopoverProps = {
  style?: CSSProperties;
  children: ReactNode;
};
export const MessagePopover = forwardRef<HTMLDivElement, MessagePopoverProps>((props, ref) => {
  const { style, children } = props;
  return (
    <FloatingPortal>
      <div
        className="z-10 w-80 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5"
        ref={ref}
        style={style}
      >
        {children}
      </div>
    </FloatingPortal>
  );
});

export const MessagePopoverBody = ({ children }: { children: ReactNode }) => {
  return <div className="space-y-2 p-4">{children}</div>;
};
export const MessagePopoverFooter = ({ children }: { children: ReactNode }) => {
  return <div className="flex w-full justify-between border-t border-gray-200 bg-gray-50 p-2">{children}</div>;
};
MessagePopover.displayName = 'MessagePopover';
