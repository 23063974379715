import { Pagination } from 'components/ListTable/Pagination/Pagination';
import { useState } from 'react';
import { Connectors, DocumentType, RelationshipV2, TransactionDTO, TransactionState } from 'support/types/dtos';
import { useRelationshipTestDocuments } from 'services/repositories/relationships/relationships';
import { SlideOverContentSkeleton } from 'components/Display/SlideOver/SlideOver';
import { ReviewAction } from './components/ReviewAction/ReviewAction';
import { ReviewTestMessageInEmailConnector } from './components/ReviewTestMessageEmailConnector/ReviewTestMessageEmailConnector';
import { ReviewTestMessage } from './components/ReviewTestMessage/ReviewTestMessage';
import { DEFAULT_REFETCH_INTERVAL } from 'support/helpers/const/const';
import { Alert } from 'components/Display/Alert';
import { useTranslation } from 'react-i18next';

export const TestMessagePendingReview = ({
  relationship,
}: {
  relationship: RelationshipV2;
  currentPartnerId: string | undefined;
}) => {
  const [currentCursor, setCurrentCursor] = useState<string | undefined>();
  const { data: transactionsData, isLoading: isLoadingRelationshipDocuments } = useRelationshipTestDocuments({
    variables: {
      query: {
        perPage: '25',
        cursor: currentCursor,
      },
      relationshipId: relationship.id,
    },
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
  });

  const isOrderRelationship = relationship.message_type === DocumentType.order;

  if (isLoadingRelationshipDocuments && !currentCursor) {
    return <SlideOverContentSkeleton />;
  }

  const receiverConnector =
    relationship.receiver_integration?.testing?.connector || relationship.receiver_integration?.production?.connector;

  const isEmailConnector = receiverConnector === Connectors.EMAIL;

  const pagination = (Boolean(transactionsData?.previousCursor) || Boolean(transactionsData?.hasMore)) && (
    <Pagination
      hasPrevious={Boolean(transactionsData?.previousCursor)}
      hasNext={Boolean(transactionsData?.hasMore)}
      onPrevious={() => setCurrentCursor(transactionsData?.previousCursor)}
      onNext={() => setCurrentCursor(transactionsData?.nextCursor)}
    />
  );

  const table = isEmailConnector ? (
    <ReviewTestMessageInEmailConnector
      transactions={transactionsData?.items}
      isLoading={isLoadingRelationshipDocuments}
      pagination={pagination}
      isOrder={isOrderRelationship}
      relationshipId={relationship.id}
    />
  ) : (
    <ReviewTestMessage
      relationshipId={relationship.id}
      transactions={transactionsData?.items}
      isLoading={isLoadingRelationshipDocuments}
      pagination={pagination}
      isOrder={isOrderRelationship}
    />
  );

  return (
    <div className="space-y-6">
      {table}
      <FailedTransactionsBanner transactions={transactionsData?.items || []} />
      <ReviewAction relationshipId={relationship.id} />
    </div>
  );
};

const FailedTransactionsBanner = ({ transactions }: { transactions: Array<TransactionDTO> }) => {
  const { t } = useTranslation();
  const hasFailedTransactions = transactions.some((transaction) => transaction.state === TransactionState.failed);

  if (!hasFailedTransactions) {
    return null;
  }

  return (
    <Alert
      severity="error"
      withoutIcon
      title={t('connections:testMessagePendingReview.alerts.failed_transactions.title')}
    >
      {t('connections:testMessagePendingReview.alerts.failed_transactions.description')}
    </Alert>
  );
};
