import {
  PricingConfigDTO,
  PricingConfigModificationDTO,
  PricingConfigPayloadLineItemDTO,
  PricingConfigPaymentTermsDTO,
} from 'support/types';

type NewLineItem = {
  item: {
    name: string;
    identifiers: {
      gtin: string;
      sender_internal: string;
      receiver_internal: string;
    };
    unit_price: number;
    tax_percentage: number;
    type: any;
  };
  modification_groups: Array<NewModificationGroup>;
};

type NewPaymentTerm = {
  pay_in_number_of_days: number;
  discount_percentage: number;
};

type NewChargeOrAllowance = {
  type: 'RELATIVE' | 'ABSOLUTE';
  description: string;
  reason_code: string;
  percentage: number;
  amount: number;
};

type NewModificationGroup = {
  basis: number;
  charges: Array<NewChargeOrAllowance>;
  allowances: Array<NewChargeOrAllowance>;
};
const mapLookupValuesToLineItems = (
  items: Array<NewLineItem> | undefined,
  lookupLineItems: Array<PricingConfigPayloadLineItemDTO>,
) => {
  if (!lookupLineItems) {
    return items;
  }

  return items?.map((lineItem) => {
    const gtin = lineItem.item.identifiers.gtin;
    if (!gtin) {
      return lineItem;
    }

    const lookupItem = lookupLineItems.find((lookup: PricingConfigPayloadLineItemDTO) => {
      return lookup.gtin === gtin;
    });

    if (!lookupItem) {
      return lineItem;
    }
    const {
      name,
      modifiable,
      salesTax,
      unitPrice,
      discountOrChargeType,
      discountOrChargeValue,
      discountOrChargeIsPercent,
    } = lookupItem;

    const modification_groups = [
      {
        [discountOrChargeType === 'ALLOWANCE' ? 'allowances' : 'charges']: [
          {
            type: discountOrChargeIsPercent ? 'RELATIVE' : 'ABSOLUTE',
            percentage: discountOrChargeIsPercent ? discountOrChargeValue : undefined,
            amount: discountOrChargeIsPercent ? undefined : discountOrChargeValue,
            reason_code: 'DISCOUNT',
          },
        ],
      },
    ];

    return {
      ...lineItem,

      item: {
        ...lineItem.item,
        name: name ?? lineItem.item.name,
        type: modifiable ? lineItem.item.type : 'RETURNABLE_CONTAINER',
        unit_price: unitPrice ?? lineItem.item.unit_price,
        tax_percentage: salesTax ?? lineItem.item.tax_percentage,
      },
      ...(!!discountOrChargeValue &&
        typeof discountOrChargeIsPercent !== 'undefined' &&
        modifiable && {
          modification_groups,
        }),
    };
  });
};

const mapLookupValuesToModificationGroups = (
  modificationGroups: Array<NewModificationGroup> | undefined,
  lookupModificationGroups: Array<PricingConfigModificationDTO>,
) => {
  if (!lookupModificationGroups || !lookupModificationGroups.length) {
    return modificationGroups;
  }

  return lookupModificationGroups?.map((lookupModificationGroup) => {
    const { isPercentage, amount, code, type } = lookupModificationGroup;

    if (type === 'CHARGE') {
      return {
        charges: [
          {
            percentage: isPercentage ? amount : undefined,
            amount: isPercentage ? undefined : amount,
            type: isPercentage ? 'RELATIVE' : 'ABSOLUTE',
            reason_code: code,
          },
        ],
        allowances: [],
      };
    }
    return {
      charges: [],
      allowances: [
        {
          percentage: isPercentage ? amount : undefined,
          amount: isPercentage ? undefined : amount,
          type: isPercentage ? 'RELATIVE' : 'ABSOLUTE',
          reason_code: code,
        },
      ],
    };
  });
};

const mapLookupValuesToPaymentTerms = (
  payment_terms: Array<NewPaymentTerm> | undefined,
  lookupPaymentTerms: Array<PricingConfigPaymentTermsDTO>,
) => {
  if (!lookupPaymentTerms || !lookupPaymentTerms.length) {
    return payment_terms;
  }

  return lookupPaymentTerms.map((paymentTerm) => {
    return {
      pay_in_number_of_days: paymentTerm.payInNumberOfDays,
      discount_percentage: paymentTerm.percentage,
    };
  });
};

export const applyPricingConfigToInvoiceOrCreditNote = (formData: any, currentPricingConfig: PricingConfigDTO) => {
  return {
    ...formData,
    line_items: mapLookupValuesToLineItems(formData.line_items, currentPricingConfig.payload.lineItems),
    payment_terms: mapLookupValuesToPaymentTerms(formData.payment_terms, currentPricingConfig.payload.paymentTerms),
    modification_groups: mapLookupValuesToModificationGroups(
      formData.modification_groups,
      currentPricingConfig.payload.modifications,
    ),
  };
};
