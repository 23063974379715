import { ClipboardDocumentListIcon, ListBulletIcon, UsersIcon } from '@heroicons/react/24/outline';
import { PlugIcon } from 'components/CustomIcons/PlugIcon';
import {
  routeToPricingConfigEditPage,
  routeToNewIntegrationPage,
  routeToPage,
} from 'support/helpers/navigation/navigation';
import { IOnboardingStepKeys } from 'support/types';
import { SettingsRoutes } from 'support/types/routes';

interface IStepConfig {
  icon: JSX.Element;
  title: string;
  description: string;
  cta?: {
    label: string;
    href: string;
  };
}
export const stepsConfig: Record<IOnboardingStepKeys | string, IStepConfig> = {
  [IOnboardingStepKeys.CREATE_COMPANY]: {
    icon: <ClipboardDocumentListIcon className="h-6 w-6 text-white" aria-hidden="true" />,
    title: 'onboarding:integration_steps.create_company.title',
    description: 'onboarding:integration_steps.create_company.description',
  },
  [IOnboardingStepKeys.CREATE_OR_UPLOAD_PRICE_LIST]: {
    icon: <ListBulletIcon className="h-6 w-6 text-white" aria-hidden="true" />,
    title: 'onboarding:integration_steps.create_or_upload_price_list.title',
    description: 'onboarding:integration_steps.create_or_upload_price_list.description',
    cta: {
      label: 'onboarding:integration_steps.create_or_upload_price_list.cta',
      href: routeToPricingConfigEditPage(),
    },
  },
  [IOnboardingStepKeys.SETUP_INTEGRATION]: {
    icon: <PlugIcon className="h-6 w-6 text-white" aria-hidden="true" />,
    title: 'onboarding:integration_steps.setup_integration.title',
    description: 'onboarding:integration_steps.setup_integration.description',
    cta: {
      label: 'onboarding:integration_steps.setup_integration.cta',
      href: routeToNewIntegrationPage(),
    },
  },
  [IOnboardingStepKeys.INVITE_YOUR_TEAM_MEMBERS]: {
    icon: <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />,
    title: 'onboarding:integration_steps.invite_your_team_members.title',
    description: 'onboarding:integration_steps.invite_your_team_members.description',
    cta: {
      label: 'onboarding:integration_steps.invite_your_team_members.cta',
      href: routeToPage(SettingsRoutes.teamMembers),
    },
  },
};

/*
 * t('onboarding:integration_steps.create_company.title')
 * t('onboarding:integration_steps.create_company.description')
 * t('onboarding:integration_steps.create_or_upload_price_list.title')
 * t('onboarding:integration_steps.create_or_upload_price_list.description')
 * t('onboarding:integration_steps.create_or_upload_price_list.cta')
 * t('onboarding:integration_steps.setup_integration.title')
 * t('onboarding:integration_steps.setup_integration.description')
 * t('onboarding:integration_steps.setup_integration.cta')
 * t('onboarding:integration_steps.invite_your_team_members.title')
 * t('onboarding:integration_steps.invite_your_team_members.description')
 * t('onboarding:integration_steps.invite_your_team_members.cta')
 */
