import { Description, DialogTitle, useClose } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Alert, AlertSection } from 'components/Display/Alert';
import { Button } from 'components/Form/Button';
import { Translate } from 'components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { MergeStats } from './types';

type ConfirmationStageProps = {
  mergeStats: MergeStats;
  onBack: () => void;
  onConfirm: () => void;
};

export const ConfirmationStage = ({ mergeStats, onBack, onConfirm }: ConfirmationStageProps) => {
  const { t } = useTranslation();
  const close = useClose();

  return (
    <div className="p-6">
      <div className="flex items-start justify-between">
        <div>
          <DialogTitle as="h3" className="text-lg font-medium text-gray-900">
            {t('webedi:documentExtractor.confirm.title')}
          </DialogTitle>
          <Description className="mt-2 text-sm leading-tight text-gray-500">
            <Translate i18nKey="webedi:documentExtractor.confirm.subTitle" />
          </Description>
        </div>
        <Button
          variant="minimal"
          iconOnly
          size="base"
          LeftIcon={XMarkIcon}
          onClick={close}
          analyticsId="document-extractor:close"
        />
      </div>
      <div className="space-y-2 py-4">
        {mergeStats.matched > 0 && (
          <Alert severity="success" fullWidth className="rounded-md">
            <AlertSection
              severity="success"
              title={t('webedi:documentExtractor.confirm.linesMatched.title', { count: mergeStats.matched })}
            >
              <Translate
                i18nKey="webedi:documentExtractor.confirm.linesMatched.description"
                values={{ count: mergeStats.matched }}
              />
            </AlertSection>
          </Alert>
        )}
        {mergeStats.added > 0 && (
          <Alert severity="info" fullWidth className="rounded-md">
            <AlertSection
              severity="info"
              title={t('webedi:documentExtractor.confirm.linesAdded.title', { count: mergeStats.added })}
            >
              <Translate
                i18nKey="webedi:documentExtractor.confirm.linesAdded.description"
                values={{ count: mergeStats.added }}
              />
            </AlertSection>
          </Alert>
        )}
        {mergeStats.missing > 0 && (
          <Alert severity="warning" fullWidth className="rounded-md">
            <AlertSection
              severity="warning"
              title={t('webedi:documentExtractor.confirm.linesMissing.title', { count: mergeStats.missing })}
            >
              <Translate
                i18nKey="webedi:documentExtractor.confirm.linesMissing.description"
                values={{ count: mergeStats.missing }}
              />
            </AlertSection>
          </Alert>
        )}
      </div>
      <p className="text-sm text-gray-900">{t('webedi:documentExtractor.confirm.description')}</p>
      <div className="mt-5 flex justify-end gap-2 sm:mt-6">
        <Button type="button" variant="secondary" onClick={onBack} analyticsId="document-extractor:back">
          {t('common:back')}
        </Button>
        <Button type="button" onClick={onConfirm} analyticsId="document-extractor:apply">
          {t('common:apply')}
        </Button>
      </div>
    </div>
  );
};
