import { useDispatchInstructionSummary } from 'components/WebEDI/hooks/useDispatchInstructionSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestamp } from 'support/helpers/dateTime/dateTime';
import { DocumentSummaryContent } from './DocumentSummaryContent';

export const DispatchInstructionSummary = () => {
  const { t } = useTranslation();
  const dispatchInstructionSummary = useDispatchInstructionSummary();
  return (
    <DocumentSummaryContent
      items={[
        {
          label: t('webedi:documentSummary.dispatchInstruction.totalQuantity.label'),
          value: Math.round(dispatchInstructionSummary.totalQuantity),
        },
        {
          label: t('webedi:documentSummary.dispatchInstruction.lineCount.label'),
          value: Math.round(dispatchInstructionSummary.lineCount),
        },
        {
          label: t('webedi:documentSummary.dispatchInstruction.deliveryDate.label'),
          value: formatTimestamp(dispatchInstructionSummary.deliveryDate) ?? '—',
        },
      ]}
    />
  );
};
