import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY, formatCurrency } from 'support/helpers/currency/currency';
import { SummaryAccordion } from '../SummaryAccordion/SummaryAccordion';
import { useDocumentCurrency } from '../helpers';
import { useInvoiceAndCreditNoteSummary } from '../hooks/useInvoiceAndCreditNoteSummary';
import { DocumentType } from 'support/types';
import NumberFlow from '@number-flow/react';

export const CreateInvoiceAndCreditNoteSummary = ({
  documentType,
}: {
  documentType: DocumentType.invoice | DocumentType.creditNote;
}) => {
  const { t } = useTranslation();
  const currency = useDocumentCurrency();
  const invoiceAndCreditNoteSummary = useInvoiceAndCreditNoteSummary();
  const isInvoice = documentType === DocumentType.invoice;
  return (
    <SummaryAccordion
      header={{
        label: t('webedi:documentSummary.title', {
          documentType: t(`common:messageTypes.singular.${documentType}`),
        }),
        value: (
          <NumberFlow
            value={invoiceAndCreditNoteSummary.due || 0}
            format={{ style: 'currency', currency: currency || DEFAULT_CURRENCY }}
          />
        ),
      }}
      bodyItems={[
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.grossAmount.label')
            : t('webedi:documentSummary.creditNote.grossAmount.label'),
          value: formatCurrency(invoiceAndCreditNoteSummary.subtotal, currency),
        },
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.allowances.label')
            : t('webedi:documentSummary.creditNote.allowances.label'),
          value: formatCurrency(invoiceAndCreditNoteSummary.allowances, currency),
        },
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.charges.label')
            : t('webedi:documentSummary.creditNote.charges.label'),
          value: formatCurrency(invoiceAndCreditNoteSummary.charges, currency),
        },
        {
          label: isInvoice
            ? t('webedi:documentSummary.invoice.taxes.label')
            : t('webedi:documentSummary.creditNote.taxes.label'),
          value: formatCurrency(invoiceAndCreditNoteSummary.taxes, currency),
        },
      ]}
      footer={{
        label: isInvoice ? t('webedi:documentSummary.invoice.footer') : t('webedi:documentSummary.creditNote.footer'),
        value: formatCurrency(invoiceAndCreditNoteSummary.due, currency),
      }}
    />
  );
};
