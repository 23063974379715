import { Tab } from '@headlessui/react';
import { classNames } from 'support/helpers/generic/generic';

type TabProps = {
  items: Array<{ label: string; content: JSX.Element }>;
  defaultIndex?: number;
  onChange?: (index: number) => void;
  scrollingContainer?: boolean;
};

const PADDING_HEIGHT = 6 * 4; //pt-6 padding-on-top
const TABS_HEIGHT = 54;

const STYLE_WITH_SCROLL = {
  height: `calc(var(--content-height) - ${TABS_HEIGHT}px - ${PADDING_HEIGHT}px)`,
};

export function Tabs({ items, defaultIndex, onChange, scrollingContainer }: TabProps) {
  return (
    <Tab.Group key={defaultIndex || 0} defaultIndex={defaultIndex} onChange={onChange}>
      <Tab.List>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {items.map((tab) => (
                <Tab
                  key={tab.label}
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-procuros-green-500 text-procuros-green-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                    )
                  }
                >
                  {tab.label}
                </Tab>
              ))}
            </nav>
          </div>
        </div>
      </Tab.List>

      <Tab.Panels
        className={scrollingContainer ? '-mx-2 overflow-y-scroll px-2' : undefined}
        style={scrollingContainer ? STYLE_WITH_SCROLL : undefined}
      >
        {items.map((tab) => (
          <Tab.Panel key={tab.label}>{tab.content}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
