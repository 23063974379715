import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type SegmentedControlProps = {
  label?: string;
  items: {
    label: string;
    iconOnly?: boolean;
    LeftIcon?: React.ComponentType<any>;
    RightIcon?: React.ComponentType<any>;
  }[];
  defaultIndex?: number;
  selectedIndex?: number;
  onChange?: (index: number) => void;
};

export function SegmentedControl({ label, items, defaultIndex, selectedIndex, onChange }: SegmentedControlProps) {
  return (
    <Tab.Group
      as={label ? 'div' : Fragment}
      key={defaultIndex || 0}
      defaultIndex={defaultIndex}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      {label && <div className="text-sm font-medium text-gray-700 truncate">{label}</div>}
      <Tab.List className={classNames('flex', label ? 'mt-1' : undefined)}>
        <nav className="flex bg-gray-100 space-x-1 p-1 rounded-lg" aria-label="Tabs">
          {items.map(({ label, LeftIcon, RightIcon, iconOnly }) => (
            <Tab
              key={label}
              className={({ selected }) =>
                classNames(
                  'flex items-center px-2.5 py-1.5 text-xs font-medium rounded-md outline-none',
                  selected
                    ? 'border border-gray-200 shadow-sm text-procuros-green-700 bg-white'
                    : 'border border-transparent text-gray-500 hover:text-gray-700',
                )
              }
            >
              {!!LeftIcon && (
                <LeftIcon
                  className={classNames('-ml-0.5 mr-2 h-4 w-4', iconOnly ? '!ml-0 !mr-0' : '')}
                  aria-hidden="true"
                />
              )}
              {iconOnly ? null : label}
              {!!RightIcon && (
                <RightIcon
                  className={classNames('ml-2 -mr-0.5 h-4 w-4', iconOnly ? '!ml-0 !mr-0' : '')}
                  aria-hidden="true"
                />
              )}
            </Tab>
          ))}
        </nav>
      </Tab.List>
    </Tab.Group>
  );
}
