import { useTranslation } from 'react-i18next';
import { useFailedTransactionsCount } from 'services/repositories/stats/stats';
import { Alert } from 'components/Display/Alert/Alert';

export function BlockedTransactionsBanner({ expired }: { expired?: boolean }) {
  const { t } = useTranslation();
  const { data: failedTransactionCountData } = useFailedTransactionsCount();

  if (expired) {
    return (
      <div className="mb-6">
        <Alert severity="neutral" withoutIcon>
          {t('transactions:list.expiredTransactions.banner')}
        </Alert>
      </div>
    );
  }

  if (failedTransactionCountData?.actionRequiredTransactions) {
    return (
      <div className="mb-6">
        <Alert severity="error" withoutIcon>
          {t('transactions:list.blockedTransactions.actionRequiredBanner')}
        </Alert>
      </div>
    );
  }

  if (failedTransactionCountData?.failedTransactions) {
    return (
      <div className="mb-6">
        <Alert severity="neutral" withoutIcon>
          {t('transactions:list.blockedTransactions.noActionBanner', {
            count: failedTransactionCountData.failedTransactions,
          })}
        </Alert>
      </div>
    );
  }

  return null;
}
