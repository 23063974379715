import { createColumnHelper, RowData } from '@tanstack/react-table';
import { DeleteCell } from '../cells/DeleteCell';
import { ColumnTypesInternal } from '../Types';

export const deletionPartial = <TData extends RowData>(
  quantityField: string | undefined,
  columnHelper: ReturnType<typeof createColumnHelper<TData>>,
) =>
  columnHelper.display({
    id: ColumnTypesInternal.procuros_delete,
    meta: {
      type: ColumnTypesInternal.procuros_delete,
      optionalConfig: {
        quantityField,
      },
    },
    cell: DeleteCell,
  });
