import { Button } from 'components/Form/Button/Button';
import { ComponentType } from 'react';

type PageSelectorProps = {
  display: ComponentType<any>;
  pageKey: string;
  disabled: boolean;
  className?: string;
  onClick: (newPage: string) => void;
};

export function PageSelector({ display, disabled, className, pageKey, onClick }: PageSelectorProps) {
  return (
    <Button
      testId={pageKey}
      iconOnly
      LeftIcon={display}
      disabled={disabled}
      className={className}
      size="small"
      variant="secondary"
      onClick={() => onClick(pageKey)}
      analyticsId="pagination_page_selector"
    />
  );
}
