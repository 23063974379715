import React, { forwardRef, useId, useRef, WheelEventHandler } from 'react';
import { FieldError } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { classNames } from 'support/helpers/generic/generic';
import { InputLabel } from '../InputLabel/InputLabel';
import { LeadingAddOn } from '../LeadingAddOn/LeadingAddOn';
import { TrailingAddOn } from '../TrailingAddOn/TrailingAddOn';
import './Input.css';
import { useResizeObserver } from 'usehooks-ts';

type NativeInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | 'autoComplete'
  | 'className'
  | 'defaultValue'
  | 'disabled'
  | 'id'
  | 'max'
  | 'min'
  | 'name'
  | 'onClick'
  | 'onChange'
  | 'onBlur'
  | 'onFocus'
  | 'placeholder'
  | 'readOnly'
  | 'required'
  | 'step'
  | 'type'
  | 'minLength'
  | 'maxLength'
  | 'onKeyDown'
  | 'title'
>;

type InputProps = {
  description?: string;
  errors?: FieldError;
  hasError?: boolean;
  label?: string;
  leftAddon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightAddon?: string;
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'] | null;
  wrapperClassName?: string;
  wrapperRef?: React.Ref<HTMLDivElement>;
} & NativeInputProps;

function InputComponent(
  {
    label,
    leftIcon,
    leftAddon,
    rightAddon,
    className,
    wrapperClassName,
    errors,
    hasError,
    disabled,
    required,
    description,
    type = 'text',
    id,
    wrapperRef,
    ...otherProps
  }: InputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const inputId = useId();
  let inputValue: InputProps['value'] = '';

  if (typeof otherProps.value === 'number') {
    if (!isNaN(otherProps.value)) {
      inputValue = otherProps.value;
    }
  } else if (otherProps.value !== null) {
    inputValue = otherProps.value;
  }

  const showErrorState = Boolean(errors || hasError);

  const leftAddonRef = useRef<HTMLDivElement>(null);
  const { width: leftAddonWidth } = useResizeObserver({
    ref: leftAddonRef,
    box: 'border-box',
  });

  const rightAddonRef = useRef<HTMLDivElement>(null);
  const { width: rightAddonWidth } = useResizeObserver({
    ref: rightAddonRef,
    box: 'border-box',
  });

  return (
    <div
      className={classNames(wrapperClassName, {
        'text-red-700 focus-within:text-inherit': showErrorState,
      })}
      ref={wrapperRef}
    >
      <InputLabel label={label} required={required} htmlFor={id ?? inputId} />

      <div className={classNames('group relative', label ? 'mt-1' : undefined)}>
        <LeadingAddOn show={!!leftIcon} leadingAddOn={leftIcon} hasError={showErrorState} />
        <LeadingAddOn show={!!leftAddon} leadingAddOn={leftAddon} hasError={showErrorState} ref={leftAddonRef} />
        <input
          ref={ref}
          type={type}
          className={classNames(
            'text-sm block w-full rounded-md focus-visible:outline-none box-border shadow-sm read-only:cursor-not-allowed disabled:cursor-not-allowed',
            'border-gray-300',
            {
              'pl-9': !leftAddon && leftIcon,
              'bg-red-50 placeholder:text-red-700 focus-visible:ring-red-500 focus-visible:border-red-500 focus-visible:outline-none focus:bg-transparent':
                showErrorState,
              'focus-visible:ring-procuros-green-400 focus-visible:border-procuros-green-400': !errors && !hasError,
              'input--date': type === 'date',
              'data-hj-allow': type !== 'password',
              'disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-300 overflow-hidden flex items-center relative flex-1 min-w-0 bg-white border shadow-sm text-left focus-visible:outline-none active:outline-none active:ring-0 focus-visible:ring-0':
                disabled,
            },
            className,
          )}
          style={{
            paddingLeft: leftAddonWidth ? `calc(${leftAddonWidth}px + 0.5rem)` : undefined,
            paddingRight: rightAddonWidth ? `calc(${rightAddonWidth}px + 0.5rem)` : undefined,
          }}
          aria-label={label ? undefined : otherProps.name}
          disabled={disabled}
          data-testid={id}
          id={id ?? inputId}
          {...otherProps}
          value={inputValue}
          onWheel={numberInputOnWheelPreventChange}
        />
        <TrailingAddOn
          hasError={showErrorState}
          ref={rightAddonRef}
          show={Boolean(showErrorState || rightAddon)}
          trailingAddOn={
            <div className="flex items-center gap-2">
              {rightAddon}
              {showErrorState ? <ExclamationCircleIcon className="size-5 text-red-500" aria-hidden="true" /> : null}
            </div>
          }
        />
      </div>

      {/* error texts */}
      {!!errors && <span className="mt-2 text-sm text-red-600">{errors.message}</span>}

      {!!description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  );
}

export const Input = forwardRef(InputComponent);

const numberInputOnWheelPreventChange: WheelEventHandler<HTMLInputElement> = (e) => {
  const target = e.target as HTMLInputElement;
  // Prevent the input value change
  target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();
};
