import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import { ReactNode } from 'react';

export const SummaryAccordionHeader = ({
  label,
  open,
  setOpen,
  value,
  valueLabel,
}: {
  label: string | undefined;
  value: string | number | undefined | ReactNode;
  valueLabel?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <div className="grid grid-cols-[1fr_32px] gap-4 bg-gray-50 px-8 py-3">
      <div className="flex items-center justify-between text-sm font-bold text-gray-900">
        <span>{label}</span>
        <div className="flex">
          {valueLabel && <span className="mr-2 font-normal">{valueLabel}:</span>}
          <span>{value}</span>
        </div>
      </div>
      <Button
        variant="minimal"
        iconOnly
        onClick={() => setOpen(!open)}
        className="shrink-0 self-end"
        LeftIcon={open ? ChevronDownIcon : ChevronUpIcon}
        size="extra-small"
        analyticsId="webedi:summary_accordion_toggle"
      />
    </div>
  );
};
