import { createQuery } from 'react-query-kit';
import portalSchema from 'support/types/schema-portal';
import { fetchData } from '../../http/http';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import { Filter } from 'components/ListTable/types';

type GetDashboardStatsResponse =
  portalSchema.paths['/v1/stats/dashboard']['get']['responses']['200']['content']['application/json'];

type GetFailedTransactionsCountResponse =
  portalSchema.paths['/v1/stats/failed']['get']['responses']['200']['content']['application/json'];

type GetConnectionsCountResponse =
  portalSchema.paths['/v1/stats/connections']['get']['responses']['200']['content']['application/json'];

type GetInboxStatsResponse =
  portalSchema.paths['/v1/stats/inbox']['get']['responses']['200']['content']['application/json'];

export const useDashboardStats = createQuery<GetDashboardStatsResponse, { relatedPartnerId?: string }, Error>({
  primaryKey: '/stats/dashboard',
  queryFn: async ({ queryKey: [, variables] }) => {
    const queryParams = variables.relatedPartnerId ? `?relatedPartnerId=${variables.relatedPartnerId}` : '';
    const { data } = await fetchData<GetDashboardStatsResponse>('/v1/stats/dashboard' + queryParams);
    return data;
  },
});

export const useFailedTransactionsCount = createQuery<GetFailedTransactionsCountResponse, any, Error>({
  primaryKey: '/stats/failed',
  queryFn: async () => {
    const { data } = await fetchData<GetFailedTransactionsCountResponse>('/v1/stats/failed');
    return data;
  },
});

export const useInboxStats = createQuery<GetInboxStatsResponse, any, Error>({
  primaryKey: '/stats/inbox',
  queryFn: async () => {
    const { data } = await fetchData<GetInboxStatsResponse>('/v1/stats/inbox');
    return data;
  },
});

export const useConnectionsStats = createQuery<GetConnectionsCountResponse, { query?: Filter }, Error>({
  primaryKey: '/stats/connections',
  queryFn: async ({ queryKey: [, variables] }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query });
    const { data } = await fetchData<GetConnectionsCountResponse>('/v1/stats/connections' + urlParams);
    return data;
  },
});
