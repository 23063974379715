import { buildDocumentTypeFilter } from 'components/ListTable/Filters/helpers';
import { PropertyFilter, PropertyFilterType } from 'components/ListTable/types';
import { t } from 'i18next';
import { DocumentType } from 'support/types';

export enum ConnectionsFilterKey {
  messageType = 'message_type',
  actionRequired = 'action_required',
  partnerName = 'partner_name',
  partnerId = 'partner_id',
  deactivated_partners = 'deactivated_partners',
}

export const getDefaultFilterConfig = (): Array<PropertyFilter> => [
  {
    key: ConnectionsFilterKey.partnerName,
    type: PropertyFilterType.search,
    placeholder: t('connections:list.filter.search.label'),
    value: '',
  },
  buildDocumentTypeFilter(ConnectionsFilterKey.messageType, [DocumentType.unknown]),
  {
    key: ConnectionsFilterKey.actionRequired,
    type: PropertyFilterType.dropdown,
    label: t('connections:list.filter.actionRequired.label'),
    value: '',
    dropDownOptions: {
      dropdownValues: [
        { label: t('connections:list.filter.actionRequired.required.label'), value: 'true' },
        { label: t('connections:list.filter.actionRequired.notRequired.label'), value: 'false' },
      ],
    },
  },
  {
    key: ConnectionsFilterKey.deactivated_partners,
    type: PropertyFilterType.checkbox,
    label: t('connections:list.filter.deactivated_partners.label'),
    value: 'false',
  },
];
