import { useDocumentCurrency } from 'components/WebEDI/helpers';
import { useInvoiceAndCreditNoteSummary } from 'components/WebEDI/hooks/useInvoiceAndCreditNoteSummary';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'support/helpers/currency/currency';
import { DocumentSummaryContent } from './DocumentSummaryContent';

export const CreditNoteSummary = () => {
  const { t } = useTranslation();
  const creditNoteSummary = useInvoiceAndCreditNoteSummary();
  const currency = useDocumentCurrency();
  return (
    <DocumentSummaryContent
      items={[
        {
          label: t('webedi:documentSummary.creditNote.grossAmount.label'),
          value: formatCurrency(creditNoteSummary.subtotal, currency),
        },
        {
          label: t('webedi:documentSummary.creditNote.allowances.label'),
          value: formatCurrency(creditNoteSummary.allowances, currency),
        },
        {
          label: t('webedi:documentSummary.creditNote.charges.label'),
          value: formatCurrency(creditNoteSummary.charges, currency),
        },
        {
          label: t('webedi:documentSummary.creditNote.taxes.label'),
          value: formatCurrency(creditNoteSummary.taxes, currency),
        },
      ]}
      footer={{
        label: t('webedi:documentSummary.creditNote.footer'),
        value: formatCurrency(creditNoteSummary.due, currency),
      }}
    />
  );
};
