import Ajv2020, { DefinedError } from 'ajv/dist/2020';
import * as jsonMap from 'json-source-map';
import { monaco } from 'react-monaco-editor';
import isEqual from 'lodash/isEqual';

export type ValidationResult = {
  valid: boolean;
  errors: Array<monaco.editor.IMarkerData>;
};

const knownSchemas: Record<string, unknown> = {};

const ajv = new Ajv2020({
  allowUnionTypes: true,
  discriminator: true,
  allErrors: true,
  verbose: true,
  strictSchema: false,
});

export const wrapSchema = (
  schema: Record<string, unknown> | Array<Record<string, unknown>>,
): Record<string, unknown> | Array<Record<string, unknown>> => {
  // @ts-expect-error test
  const { $defs, $schema, ...otherProperties } = schema;
  return {
    $schema: 'https://json-schema.org/draft/2020-12/schema',
    type: 'object',
    $defs,
    properties: {
      payloads: {
        type: 'array',
        items: {
          ...otherProperties,
        },
      },
      context: {
        type: ['object', 'null', 'array'],
      },
    },
    required: ['payloads'],
  };
};

export const validateSchema = (
  jsonData: Record<string, unknown> | Array<Record<string, unknown>>,
  schema: Record<string, unknown> | Array<Record<string, unknown>>,
  schemaName: string,
): ValidationResult | undefined => {
  if (!knownSchemas[schemaName] || !isEqual(knownSchemas[schemaName], schema)) {
    knownSchemas[schemaName] = schema;

    // Remove schema if one exists
    ajv.removeSchema(schemaName);

    ajv.addSchema(schema, schemaName);
  }

  const validate = ajv.getSchema(schemaName);

  if (!validate) {
    return;
  }

  const valid = validate(jsonData) as boolean;
  if (valid) {
    return {
      valid,
      errors: [],
    };
  }

  return {
    valid,
    errors: transformAjvErrorsToMonacoMarkers(jsonData, (validate.errors ?? []) as Array<DefinedError>),
  };
};

const transformAjvErrorsToMonacoMarkers = (
  jsonData: Record<string, unknown> | Array<Record<string, unknown>>,
  errors: Array<DefinedError>,
): Array<monaco.editor.IMarkerData> => {
  const jsonSourceMap = jsonMap.stringify(jsonData, null, 2);
  if (!jsonSourceMap || !jsonSourceMap.pointers) {
    return [];
  }

  return errors.map((error) => {
    const pointer = jsonSourceMap.pointers[error.instancePath];
    if (!pointer) {
      return {
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: 1,
        endColumn: 1,
        message: error.message ?? 'Unknown schema validation error',
        severity: monaco.MarkerSeverity.Error,
      };
    }

    return {
      startLineNumber: pointer.value.line + 1,
      startColumn: pointer.value.column + 1,
      endLineNumber: pointer.valueEnd.line + 1,
      endColumn: pointer.valueEnd.column + 1,
      message: error.message ?? 'Unknown schema validation error',
      severity: monaco.MarkerSeverity.Error,
    };
  });
};
