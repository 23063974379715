import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { isDateInPast } from 'support/helpers/dateTime/dateTime';
import { IntegrationProcessTestDTO } from 'support/types/dtos';

type IntegrationProcessTestStepDetailsPopupProps = {
  item: IntegrationProcessTestDTO | undefined;
  open: boolean;
  onClose: () => void;
};

export const IntegrationProcessTestStepDetailsPopup = ({
  item,
  open,
  onClose,
}: IntegrationProcessTestStepDetailsPopupProps) => {
  if (!item) return null;

  if (item.context?.hasOwnProperty('truugo_report_result')) {
    return <TruugoErrorPopup item={item} open={open} onClose={onClose} />;
  }

  if (item.errors || item.context?.errors) {
    return (
      <Popup open={open} onClose={onClose} title={item.message}>
        {item?.errors
          ? Object.entries(item.errors as Record<string, string>).map(([key, error]) => (
              <p key={key} className="text-sm text-gray-700">
                {error}
              </p>
            ))
          : null}

        {item?.context?.errors
          ? Object.entries(item.context.errors as Record<string, { message: string }>).map(([key, error]) => (
              <p key={key} className="text-sm text-gray-700">
                {error.message}
              </p>
            ))
          : null}
      </Popup>
    );
  }

  return null;
};

const TruugoErrorPopup = ({
  item,
  open,
  onClose,
}: {
  item: IntegrationProcessTestDTO;
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { report_url: reportUrl, file_expiry: fileExpiry } = item.context?.truugo_report_result as {
    report_url: string;
    file_expiry: string;
  };

  const isFileInPast = isDateInPast(new Date(fileExpiry));

  return (
    <Popup
      open={open}
      onClose={onClose}
      PrimaryButton={() => (
        <Button onClick={onClose} analyticsId="integrations:process-test-truugo-popup-close">
          {t('common:close')}
        </Button>
      )}
      fitContent={!isFileInPast}
    >
      {isFileInPast ? (
        <div className="flex gap-4">
          <div className="flex size-10 shrink-0 grow-0 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon className="size-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              {t('integrations:processes.new.test.errorDetails.truugoExpired.title')}
            </h3>
            <p className="text-sm leading-5 text-gray-500">
              {t('integrations:processes.new.test.errorDetails.truugoExpired.description')}
            </p>
          </div>
        </div>
      ) : (
        <iframe
          src={reportUrl}
          title={t('integrations:processes.new.test.errorDetails.truugoValid.title')}
          className="h-[80vh] w-[900px]"
        />
      )}
    </Popup>
  );
};
