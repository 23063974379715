type DividerProps = {
  children?: React.ReactNode;
};

export const Divider = ({ children }: DividerProps) => {
  return (
    <div className="relative">
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-200" />
      </div>
      {!!children && (
        <div className="relative flex pl-8">
          <div className="bg-white">{children}</div>
        </div>
      )}
    </div>
  );
};
