import { useCallback } from 'react';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { IssueResolverType } from '../support/types';
import { t } from 'i18next';

export function useTransactionActionRequired(): (resolverID: string | null | undefined) => [IssueResolverType, string] {
  const { data: currentPartner } = useCurrentPartner();

  return useCallback(
    (resolverID: string | null | undefined): [IssueResolverType, string] => {
      if (!resolverID || !currentPartner) {
        return [IssueResolverType.PROCUROS, t('transactions:list.actionRequiredActor.procuros')];
      }

      return currentPartner.id === resolverID
        ? [IssueResolverType.CURRENT_PARTNER, t('transactions:list.actionRequiredActor.you')]
        : [IssueResolverType.OTHER_PARTNER, t('transactions:list.actionRequiredActor.partner')];
    },
    [currentPartner],
  );
}
