import { TagIcon } from '@heroicons/react/24/outline';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { PricingConfigDTO } from 'support/types/dtos';
import { PricingConfigRoutes } from 'support/types/routes';

export type PriceListSelectorProps = {
  selectedPriceListId: string | undefined;
  setSelectedPricingConfigId: (id: string) => void;
  priceLists: Array<Pick<PricingConfigDTO, 'id' | 'name'>> | undefined;
};
export const PriceListSelector: FC<PriceListSelectorProps> = ({
  selectedPriceListId,
  setSelectedPricingConfigId,
  priceLists,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const truncateClassNames = 'max-w-xs truncate !min-w-0';
  const textButton =
    priceLists?.find(({ id }) => id === selectedPriceListId)?.name || t('webedi:priceList.selector.empty');
  const textNode = <span className={truncateClassNames}>{textButton}</span>;

  return (
    <MenuButton Icon={TagIcon} variant="secondary" text={textNode} size="base" analyticsId="webedi:price_list_selector">
      {priceLists?.map((priceList) => {
        return (
          <MenuButtonOption
            key={priceList.id}
            onClick={() => {
              setSelectedPricingConfigId(priceList.id);
            }}
            analyticsId="webedi:price_list_selector_select"
          >
            <span className={truncateClassNames}>{priceList.name}</span>
          </MenuButtonOption>
        );
      })}

      <MenuButtonOption
        onClick={() => navigate(routeToPage(PricingConfigRoutes.new))}
        newGroup
        analyticsId="webedi:price_list_selector_new_price_list"
      >
        {t('webedi:priceList.selector.new')}
      </MenuButtonOption>
    </MenuButton>
  );
};
