import { DataTable, useGenerateColumns } from 'components/DataTable';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ui } from '@procuros/datachecks';
import { useTranslation } from 'react-i18next';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import { ColumnTypesInternal, DisplayModes } from 'components/DataTable/Types';
import { memo, useMemo } from 'react';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';
import { Button } from 'components/Form/Button';
import { PlusIcon } from '@heroicons/react/24/outline';

export const DocumentPaymentTerms = memo(({ config, mode }: DocumentPaymentTermsProps) => {
  const { label: title, description, fields } = config;
  if (!fields?.length) return null;
  return (
    <WebEDISection header={{ title, spacing: 'medium', description }}>
      <div className="space-y-4">
        <DocumentSectionError path={DATA_PREFIX} />
        <DataTableProvider name={DATA_PREFIX}>
          <PaymentTermsTable config={fields} mode={mode} />
        </DataTableProvider>
      </div>
    </WebEDISection>
  );
});

DocumentPaymentTerms.displayName = 'DocumentPaymentTerms';

const PaymentTermsTable = ({ mode, config }: { mode: DisplayModes; config: ui.PaymentTermsSection['fields'] }) => {
  const { t } = useTranslation();
  const { fields, append } = useDataTableContext();
  const updatedConfig = useMemo(() => {
    return config.map((field, index) => {
      if (mode === DisplayModes.view) {
        return {
          ...field,
          extraFields: index === 0 ? { textAlign: 'left' } : { textAlign: 'right' },
        };
      }
      return field;
    });
  }, [config, mode]);

  const columns = useGenerateColumns({
    config: updatedConfig,
    ...TABLE_STATIC_CONFIG[mode],
  });

  const emptyPaymentTerm = useEmptyStructure(fields);
  return (
    <DataTable
      key={mode}
      meta={{ dataPrefix: DATA_PREFIX, mode }}
      columns={columns}
      data={fields}
      enableGlobalSearch={false}
      showCellBorders={mode === DisplayModes.create || mode === DisplayModes.edit}
      emptyState={
        mode === DisplayModes.create || mode === DisplayModes.edit
          ? t('webedi:payment_terms.empty_state.create.text')
          : t('webedi:payment_terms.empty_state.view.text')
      }
      footerMenu={
        (mode === DisplayModes.create || mode === DisplayModes.edit) && (
          <Button
            variant="secondary"
            size="extra-small"
            LeftIcon={PlusIcon}
            onClick={() => append({ ...emptyPaymentTerm, _internal: { [ColumnTypesInternal.procuros_added]: true } })}
            analyticsId="webedi:payment_terms_add"
          >
            {t('webedi:payment_terms.add_button.text')}
          </Button>
        )
      }
    />
  );
};

const DATA_PREFIX = 'payment_terms';
const TABLE_STATIC_CONFIG = {
  view: {},
  create: {
    selectableRows: false,
    deletableRows: true,
  },
  edit: {
    selectableRows: false,
    deletableRows: true,
  },
};

type DocumentPaymentTermsProps = {
  config: ui.PaymentTermsSection;
  mode: DisplayModes;
};
