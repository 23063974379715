import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';

type PartnerFeatureFlagGuardProps = {
  featureFlags: FEATURE_FLAGS[];
  to: string;
  children: React.ReactNode;
};
export const PartnerFeatureFlagGuard = ({ featureFlags, to, children }: PartnerFeatureFlagGuardProps) => {
  const navigate = useNavigate();
  const { enabled, isLoading } = usePartnerFeatureFlag(featureFlags);

  useEffect(() => {
    if (!isLoading && !enabled) {
      navigate(to, { replace: true });
    }
  }, [enabled, isLoading, navigate, to]);

  if (enabled) {
    return <>{children}</>;
  }
  return null;
};
