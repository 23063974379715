import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useCurrentPartner, useUpdateCurrentPartner } from 'services/repositories/partners/partners';
import { logError } from 'services/logging/logging';
import { PartnerDTO } from 'support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { useIsMutating } from '@tanstack/react-query';
import { Translate } from 'components/Translate/Translate';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input/Input/Input';
import { Button } from 'components/Form/Button/Button';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';

const AccountWrapper = ({
  setIsLoading,
  onComplete,
}: {
  setIsLoading: (isLoading: boolean) => void;
  onComplete: () => void;
}) => {
  const { t } = useTranslation();
  const { data: currentPartner, isLoading: isLoadingCurrentPartner } = useCurrentPartner();
  const { mutate: updateCurrentPartner } = useUpdateCurrentPartner();
  const defaultValues = useMemo(() => generateDefaultValues(currentPartner), [currentPartner]);
  const [submissionErrors, setSubmissionErrors] = useState<Array<string>>([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AccountForm>({
    values: defaultValues,
    resolver: yupResolver<AccountForm>(questionnaireSchema(t)),
  });

  useEffect(() => {
    setIsLoading(isLoadingCurrentPartner);
  }, [isLoadingCurrentPartner, setIsLoading]);

  const submit = (data: AccountForm) => {
    if (!currentPartner) {
      return logError(new Error('No current partner found'));
    }
    const updatedPartner = formatPartnerData(data, currentPartner);

    updateCurrentPartner(
      { currentPartnerId: currentPartner.id, updatedPartner },
      {
        onError: (error) => {
          processSubmissionErrors({
            error,
            t,
            setInputError: setError,
            setNonInputErrors: setSubmissionErrors,
            defaultData: defaultValues,
          });
        },
        onSuccess: onComplete,
      },
    );
  };

  return (
    <div className="space-y-10">
      <ValidationErrors errors={submissionErrors} />
      <Form
        onSubmit={handleSubmit(submit)}
        className="space-y-10 divide-y divide-gray-200 *:pt-10 first:*:pt-0"
        id="onboarding-account"
      >
        <div className="space-y-6">
          <div className="flex gap-4">
            <Input
              wrapperClassName="w-1/2"
              label={t('onboarding:steps.questionnaire.name.label')}
              placeholder={t('onboarding:steps.questionnaire.name.label')}
              {...register('name')}
              required
              errors={errors.name}
            />
            <Input
              wrapperClassName="w-1/2"
              label={t('onboarding:steps.questionnaire.vat.label')}
              placeholder={t('onboarding:steps.questionnaire.vat.label')}
              {...register('settings.billFromVatIdentifier')}
              required
              errors={errors.settings?.billFromVatIdentifier}
            />
          </div>
        </div>
        <div className="space-y-6">
          <Input
            label={t('onboarding:steps.questionnaire.street.label')}
            placeholder={t('onboarding:steps.questionnaire.street.label')}
            {...register('settings.billFromAddress.street')}
            required
            errors={errors.settings?.billFromAddress?.street}
          />

          <Input
            label={t('onboarding:steps.questionnaire.street2.label')}
            placeholder={t('onboarding:steps.questionnaire.street2.label')}
            {...register('settings.billFromAddress.extra')}
            errors={errors.settings?.billFromAddress?.extra}
          />

          <div className="grid grid-cols-2 gap-4">
            <Input
              label={t('onboarding:steps.questionnaire.city.label')}
              placeholder={t('onboarding:steps.questionnaire.city.label')}
              {...register('settings.billFromAddress.city')}
              required
              errors={errors.settings?.billFromAddress?.city}
            />
            <Input
              label={t('onboarding:steps.questionnaire.postalCode.label')}
              placeholder={t('onboarding:steps.questionnaire.postalCode.label')}
              {...register('settings.billFromAddress.postalCode')}
              required
              errors={errors.settings?.billFromAddress?.postalCode}
            />
            <Input
              label={t('onboarding:steps.questionnaire.state.label')}
              placeholder={t('onboarding:steps.questionnaire.state.label')}
              {...register('settings.billFromAddress.state')}
              errors={errors.settings?.billFromAddress?.state}
            />
            <Input
              label={t('onboarding:steps.questionnaire.country.label')}
              placeholder={t('onboarding:steps.questionnaire.country.label')}
              {...register('settings.billFromAddress.countryCode')}
              required
              errors={errors.settings?.billFromAddress?.countryCode}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

const BackCTA = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const isMutating = useIsMutating();
  return (
    <Button variant="secondary" onClick={onClick} disabled={Boolean(isMutating)} analyticsId="onboarding_account_back">
      {t('common:back')}
    </Button>
  );
};

const NextCTA = () => {
  const { t } = useTranslation();
  const isMutating = useIsMutating();
  return (
    <Button
      variant="primary"
      type="submit"
      form="onboarding-account"
      loading={Boolean(isMutating)}
      analyticsId="onboarding_account_continue"
    >
      {t('common:continue')}
    </Button>
  );
};

const Title = () => <Translate i18nKey="onboarding:steps.account.title" />;

export const Account = Object.assign(AccountWrapper, { BackCTA, NextCTA, Title });

type UpdatePartnerPayload = Parameters<ReturnType<typeof useUpdateCurrentPartner>['mutate']>['0']['updatedPartner'];
type PartialSettings = {
  settings: {
    billFromVatIdentifier: string | null;
    billFromAddress: {
      street: string | null;
      extra?: string | null;
      city: string | null;
      postalCode: string | null;
      state?: string | null;
      countryCode: string | null;
    };
  };
};

type Picking = Pick<UpdatePartnerPayload, 'name'>;
type AccountForm = Picking & PartialSettings;

const formatPartnerData = (data: AccountForm, current: PartnerDTO): UpdatePartnerPayload => {
  return {
    ...current,
    ...data,
    settings: {
      ...current.settings,
      ...data.settings,
      billFromAddress: {
        ...current.settings?.billFromAddress,
        ...data.settings.billFromAddress,
        name: data.name,
      },
    },
  };
};

const generateDefaultValues = (currentPartner: PartnerDTO | undefined): AccountForm => {
  const defaultPartner = {
    name: '',
    settings: {
      id: '',
      billFromVatIdentifier: '',
      billFromAddress: {
        street: '',
        extra: '',
        city: '',
        postalCode: '',
        state: '',
        countryCode: '',
      },
    },
    onboardingSteps: [],
  };

  return {
    ...defaultPartner,
    ...currentPartner,
    settings: {
      ...defaultPartner.settings,
      ...currentPartner?.settings,
      billFromAddress: { ...defaultPartner.settings.billFromAddress, ...currentPartner?.settings?.billFromAddress },
    },
  };
};

const questionnaireSchema = (t: TFunction) =>
  Yup.object({
    name: Yup.string().required(t('common:form.required', { field: t('onboarding:steps.questionnaire.name.label') })),
    settings: Yup.object({
      billFromVatIdentifier: Yup.string().required(
        t('common:form.required', { field: t('onboarding:steps.questionnaire.vat.label') }),
      ),
      billFromAddress: Yup.object({
        street: Yup.string().required(
          t('common:form.required', { field: t('onboarding:steps.questionnaire.street.label') }),
        ),
        extra: Yup.string().nullable(),
        city: Yup.string().required(
          t('common:form.required', { field: t('onboarding:steps.questionnaire.city.label') }),
        ),
        postalCode: Yup.string().required(
          t('common:form.required', { field: t('onboarding:steps.questionnaire.postalCode.label') }),
        ),
        state: Yup.string().nullable(),
        countryCode: Yup.string().required(
          t('common:form.required', { field: t('onboarding:steps.questionnaire.country.label') }),
        ),
      }),
    }),
  });
