import { TFunction } from 'i18next';
import { useCallback } from 'react';
import { useUpdateCurrentPartner, useInviteTeamMember, useTeamMembers } from 'services/repositories/partners/partners';
import * as Yup from 'yup';
import { Connectors, PartnerDTO } from 'support/types';
import { IntegrationConfigurationFormState } from '../../IntegrationConfigurationForm/types';

export type WebEDIConfiguration = {
  email: string;
  billFromAddressIdentifier: string | null;
  taxId: string | undefined | null;
  vatId: string;
};
export const webEDIConfigurationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string()
      .label(t('integrations:new.webedi.form.field.email.label'))
      .email()
      .required(
        t('common:form.required', {
          field: t('integrations:new.webedi.form.field.email.label'),
        }) ?? '',
      ),
    billFromAddressIdentifier: Yup.string().nullable(),
    taxId: Yup.string().nullable(),
    vatId: Yup.string().required(
      t('common:form.required', {
        field: t('integrations:new.webedi.form.field.vatId.label'),
      }) ?? '',
    ),
  });

export const useUpdateWebEDIPartner = ({ partner }: { partner: PartnerDTO | undefined }) => {
  const { mutateAsync: updatePartner, isLoading: isUpdatingPartner } = useUpdateCurrentPartner();
  const { mutateAsync: inviteTeamMember, isLoading: isInvitingTeamMember } = useInviteTeamMember();
  const { data: teamMembers } = useTeamMembers({
    variables: {
      partnerId: partner?.id,
    },
  });

  const updateWebEDIPartner = useCallback(
    async (data: IntegrationConfigurationFormState) => {
      if (partner && teamMembers && isWebEDIGuard(data)) {
        const teamMember = teamMembers.find(
          ({ email }) => email.toLowerCase() === data.configuration.email.toLowerCase(),
        );
        const isResponsibleUser = partner.responsibleUsers.some(
          ({ email }) => email.toLowerCase() === data.configuration.email.toLowerCase(),
        );
        let responsibleUsers = partner.responsibleUsers;
        let newTeamMember;
        if (!teamMember) {
          newTeamMember = await inviteTeamMember({
            partnerId: partner.id,
            newMember: {
              name: partner.name,
              email: data.configuration.email,
            },
          });
        }

        if (newTeamMember) {
          responsibleUsers = [
            ...responsibleUsers,
            { id: newTeamMember.id, email: newTeamMember.email },
          ] as PartnerDTO['responsibleUsers'];
        } else if (!isResponsibleUser && teamMember) {
          responsibleUsers = [
            ...responsibleUsers,
            { id: teamMember.id, email: teamMember.email },
          ] as PartnerDTO['responsibleUsers'];
        }

        await updatePartner({
          currentPartnerId: partner?.id || '',
          updatedPartner: {
            ...partner,
            responsibleUsers,
            settings: {
              ...partner.settings,
              ...data.configuration,
            },
          },
        });
      }
    },
    [inviteTeamMember, partner, teamMembers, updatePartner],
  );

  return { mutateAsync: updateWebEDIPartner, isLoading: isUpdatingPartner || isInvitingTeamMember };
};

const isWebEDIGuard = <T extends { type: Connectors }>(
  integration: T,
): integration is T & {
  type: typeof Connectors.WEB_EDI;
  configuration: WebEDIConfiguration;
} => {
  return integration.type === Connectors.WEB_EDI;
};
