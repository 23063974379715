import { ProcessSpecificationDTO } from 'support/types/dtos';
import datachecksWorker from './datachecksWorker?worker&url';
import { logError } from 'services/logging/logging';
import { ui } from '@procuros/datachecks';

class WorkerManager {
  private worker: Worker | null = null;
  private taskQueue: Array<{
    action: string;
    isProcessing: boolean;
    params: any;
    promise: Promise<any>;
    resolve: (value: any) => void;
    reject: (reason?: any) => void;
  }> = [];
  private isProcessing = false;

  private createWorker() {
    if (!this.worker) {
      this.worker = new Worker(datachecksWorker, { type: 'module' });

      this.worker.onmessage = this.handleMessage.bind(this);
      this.worker.onerror = this.handleError.bind(this);
    }
  }

  private handleMessage(event: MessageEvent) {
    const currentTask = this.taskQueue.shift();

    if (currentTask) {
      if (event.data.error) {
        currentTask.reject(new Error(event.data.error));
      } else {
        currentTask.resolve(event.data.result);
      }
    }
    this.processNextTask();
  }

  private handleError(error: ErrorEvent) {
    logError(error);
    const currentTask = this.taskQueue.shift();
    if (currentTask) {
      currentTask.reject(error);
    }
    this.terminateWorker();
    this.processNextTask();
  }

  private processNextTask() {
    if (this.taskQueue.length > 0 && !this.isProcessing) {
      this.isProcessing = true;
      const nextTask = this.taskQueue[0];
      nextTask.isProcessing = true;
      this.createWorker();
      this.worker!.postMessage({
        action: nextTask.action,
        ...nextTask.params,
      });
    } else {
      this.isProcessing = false;
    }
  }

  public runTask(action: string, params: any): Promise<any> {
    // Check if an unstarted task with the same action is already in the queue
    const existingTask = this.taskQueue.find((task) => task.action === action && !task.isProcessing);

    // If so, update the params and return the promise of the existing task
    if (existingTask) {
      existingTask.params = params;
      return existingTask.promise;
    }

    // Otherwise, create a new task and return its promise
    let resolveFunction: (value: any) => void;
    let rejectFunction: (reason?: any) => void;

    const promise = new Promise((res, rej) => {
      resolveFunction = res;
      rejectFunction = rej;
    });

    this.taskQueue.push({
      action,
      isProcessing: false,
      params,
      promise,
      resolve: resolveFunction!,
      reject: rejectFunction!,
    });

    this.processNextTask();
    return promise;
  }

  public terminateWorker() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    }
  }
}

// Create a single instance of the worker manager
const dataChecksWorkerManager = new WorkerManager();

export const getCanonical = (
  processSpecification: ProcessSpecificationDTO,
  data: any,
): Promise<{
  canonical: any;
}> => {
  if ((window as any).procuros_debug) {
    console.log('getCanonical', processSpecification, data);
  }
  return dataChecksWorkerManager.runTask('getCanonical', { processSpecification, data });
};

export const getCanonicalAndErrors = (
  processSpecification: ProcessSpecificationDTO,
  data: any,
): Promise<{
  canonical: any;
  errors: { datachecks: Array<any>; modality: Array<any> };
}> => {
  if ((window as any).procuros_debug) {
    console.log('getCanonicalAndErrors', processSpecification, data);
  }
  return dataChecksWorkerManager.runTask('getCanonicalAndErrors', { processSpecification, data });
};

export const getUiDetails = (processSpecification: ProcessSpecificationDTO): Promise<any> => {
  if ((window as any).procuros_debug) {
    console.log('getUidetails', processSpecification);
  }

  return dataChecksWorkerManager.runTask('getUiDetails', { processSpecification });
};

export const terminateDataChecksWorker = () => {
  dataChecksWorkerManager.terminateWorker();
};

export const applyRowMasksToData = (data: Record<string, any>, uiConfig: Array<ui.Section>) => {
  if (!data) {
    return data;
  }

  const filteredData = {
    ...data,
  };
  uiConfig.forEach((section) => {
    if ('row_mask' in section && data[section.type]) {
      const rowMaskFields = applyRowMask(data[section.type], section);
      filteredData[section.type] = rowMaskFields;
    }
  });

  return filteredData;
};

const applyRowMask = (
  fields: Array<Record<string, string>>,
  config: ui.Section & { row_mask: Record<string, Array<string>> },
): Array<Record<string, string>> => {
  const result: Array<Record<string, string>> = [];

  // Get the first key of the row_mask object, we don't support multiple keys
  const rowMaskFirstKey = Object.keys(config.row_mask)[0];

  config.row_mask[rowMaskFirstKey].forEach((value) => {
    // Find a field that matches the current row_mask key's values
    const matchedField = fields.find((field) => field[rowMaskFirstKey] === value);
    if (matchedField) {
      // If a matching field is found, add it to the result
      result.push(matchedField);
    } else {
      // If no matching field, create a new field with the first row_mask value
      result.push({ [rowMaskFirstKey]: value });
    }
  });

  return result;
};
