import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, useId, forwardRef, Fragment } from 'react';
import { classNames } from 'support/helpers/generic/generic';

type VARIANTS = 'normal' | 'list-compact' | 'list';

const RadioGroup = ({
  children,
  label,
  variant = 'normal',
}: {
  children: ReactNode;
  label?: string;
  variant?: VARIANTS;
}) => {
  let content: ReactNode = null;

  switch (variant) {
    case 'list-compact':
      content = children;
      break;
    case 'list':
    case 'normal':
    default:
      content = <div className="space-y-3">{children}</div>;
  }

  return (
    <fieldset className="mt-4">
      {label ? <legend className="mb-4 block truncate text-sm font-medium text-gray-700">{label}</legend> : null}
      {content}
    </fieldset>
  );
};

const checkedClasses =
  'peer-has-[:checked]:border-t-0 has-[:checked]:border-b has-[:checked]:border-procuros-green-200 has-[:checked]:bg-procuros-green-50 has-[:checked]:text-procuros-green-700';
const bordersAndColorsClasses = 'text-gray-500 border border-gray-200 first:border-b-0';
const otherClasses = 'peer flex justify-between items-center p-4 first:rounded-t-lg last:rounded-b-lg';

const RadioCompactListItemWrapper = ({ children }: { children: ReactNode }) => {
  return <div className={classNames(checkedClasses, bordersAndColorsClasses, otherClasses)}>{children}</div>;
};

const RadioListItemWrapper = ({ children }: { children: ReactNode }) => {
  return <div className="flex rounded-lg border border-gray-200 bg-white p-3 text-gray-500">{children}</div>;
};

type RadioItemProps = {
  variant?: VARIANTS;
  description?: string;
  label: ReactNode;
  hasErrors?: boolean;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type' | 'id' | 'name' | 'className'
>;

const RadioItem = forwardRef<HTMLInputElement, RadioItemProps>(
  ({ description, variant = 'normal', label, children, hasErrors, ...inputProps }, ref) => {
    const id = useId();
    let Wrapper: React.FC<{ children: ReactNode }> = Fragment;
    switch (variant) {
      case 'list':
        Wrapper = RadioListItemWrapper;
        break;
      case 'list-compact':
        Wrapper = RadioCompactListItemWrapper;
        break;
      case 'normal':
      default:
        Wrapper = Fragment;
    }

    return (
      <Wrapper>
        <div className="flex items-center gap-3">
          <input
            {...inputProps}
            ref={ref}
            id={id}
            type="radio"
            className={classNames(
              'my-1 h-4 w-4 border-gray-300 text-procuros-green-400 focus-visible:ring-procuros-green-400 disabled:border-gray-200 disabled:bg-gray-200',
              hasErrors ? 'border-red-300 ' : undefined,
            )}
          />
          <div>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-700">
              {label}
              {Boolean(inputProps.required) && <span className="text-procuros-green-500"> *</span>}
            </label>
            {description && variant === 'list' && <span className="text-sm font-normal leading-5">{description}</span>}
          </div>
          {children}
        </div>
        {description && variant === 'list-compact' && (
          <span className="text-sm font-normal leading-5">{description}</span>
        )}
      </Wrapper>
    );
  },
);

RadioItem.displayName = 'RadioItem';

export const Radio = Object.assign({}, { Group: RadioGroup, Item: RadioItem });
