import { useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { Page } from 'components/Page/Page';
import { MessageHeader } from './MessageHeader/MessageHeader';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { WebEDISidebar } from 'components/WebEDI/WebEDISidebar/WebEDISidebar';
import { DroppedMessageAlert } from '../DroppedMessageAlert/DroppedMessageAlert';
import { DisplayModes } from 'components/DataTable/Types';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'components/Form/Form';
import { ui } from '@procuros/datachecks';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { DocumentType, IssueResolverType, MessageV2DTO, PartnerDTO, ProcessSpecificationDTO } from 'support/types';
import { DocumentSections } from 'components/WebEDI/DocumentSections/DocumentSections';
import { UnkownTypePlaceholder } from './UnkownTypePlaceholder';
import { DocumentSummary } from 'components/WebEDI/DocumentSummary/DocumentSummary';
import { hasDocumentSummary } from 'components/WebEDI/DocumentSummary/helpers';
import { DATA_CHECK_ERROR_CODE, EMPTY_FUNCTION } from 'support/helpers/const/const';
import { useFlattenData } from 'hooks/useFlattenData';
import { datachecksResolver } from 'support/helpers/resolvers/datachecksResolver';
import { cleanStore, setIsValidating, setProcessSpecification, useDatachecksStore } from 'stores/datachecks/datachecks';
import { terminateDataChecksWorker } from 'services/datachecks/datachecks';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';
import { WebEDIMessageErrorBanner } from 'components/WebEDI/WebEDIMessageErrorBanner/WebEDIMessageErrorBanner';

const mode = DisplayModes.view;
export function MessageViewPage() {
  const { id } = useParams<{ id: string }>();
  const { data: currentPartner } = useCurrentPartner();
  const { data: message, isError } = useMessageWithProcessSpecification({
    variables: {
      messageId: id,
    },
    refetchInterval: 1000 * 10,
  });

  useLayoutEffect(() => {
    cleanStore();
    return () => {
      cleanStore();
      terminateDataChecksWorker();
    };
  }, []);

  const processSpec: ProcessSpecificationDTO | undefined = useMemo(() => {
    return message
      ? {
          fields: message.fields,
          data: message.data?.canonical,
          process: message.process,
        }
      : undefined;
  }, [message]);

  useEffect(() => {
    setProcessSpecification(processSpec);
  }, [processSpec]);

  const { data, uiConfig, isLoading: isLoadingDataChecks } = useFlattenData(processSpec);

  if (!id) return;

  if (isError) {
    return <UnkownTypePlaceholder messageId={id} />;
  }

  if ((window as any).procuros_debug) {
    console.log({ flattenData: data, canonical: processSpec?.data });
  }

  return (
    <MessageViewPageUI
      isLoading={isLoadingDataChecks}
      message={message?.data}
      data={data}
      relatedTransactions={message?.related_messages || []}
      currentPartner={currentPartner}
      uiConfig={uiConfig}
      processSpec={processSpec}
    />
  );
}

type MessageViewPageV2UIProps = {
  isLoading: boolean;
  message: MessageV2DTO | undefined;
  data: any;
  relatedTransactions: Array<MessageV2DTO>;
  currentPartner: PartnerDTO | undefined;
  uiConfig: Array<ui.Section>;
  processSpec: ProcessSpecificationDTO | undefined;
};
export const MessageViewPageUI = ({
  isLoading,
  message,
  data,
  uiConfig,
  relatedTransactions,
  currentPartner,
  processSpec,
}: MessageViewPageV2UIProps) => {
  const isValidating = useDatachecksStore((state) => state.isValidating);
  const setCanonical = useDatachecksStore((state) => state.setCanonical);
  const hasDatachecksError = message?.blocking_error?.code === DATA_CHECK_ERROR_CODE;
  const [issueResolver, issueResolverLabel]: [IssueResolverType, string] = useTransactionActionRequired()(
    message?.issue_resolver_id,
  );

  const resolver = useMemo(
    () => (hasDatachecksError ? datachecksResolver(processSpec) : undefined),
    [processSpec, hasDatachecksError],
  );

  const formMethods = useForm<typeof data>({
    values: data,
    resolver,
  });

  useEffect(() => {
    if (message?.canonical) {
      setCanonical(message?.canonical);
    }
  }, [message, setCanonical]);

  const { handleSubmit } = formMethods;
  useEffect(() => {
    if (hasDatachecksError && data) {
      setIsValidating(true);
      handleSubmit(EMPTY_FUNCTION)();
    }
  }, [data, handleSubmit, hasDatachecksError, setIsValidating]);

  const isValidatingDataChecks = hasDatachecksError && isValidating;
  return (
    <Page isLoading={isLoading || isValidatingDataChecks}>
      <Form id="webedi-document">
        <FormProvider {...formMethods}>
          <Page.Head>
            {!!message && (
              <MessageHeader
                mode={mode}
                message={message}
                relatedTransactions={relatedTransactions}
                currentPartner={currentPartner}
                webediV2
                issueResolverLabel={issueResolverLabel}
              />
            )}
          </Page.Head>
          <Page.Section
            topBanner={
              <>
                <DroppedMessageAlert message={message} webediV2Enabled />
                {message?.blocking_error ? (
                  <WebEDIMessageErrorBanner
                    messageId={message.id}
                    mode={mode}
                    blockingError={message.blocking_error}
                    issueResolver={issueResolver}
                  />
                ) : null}
              </>
            }
            rightAside={
              mode == DisplayModes.view ? (
                <WebEDISidebar
                  document={message}
                  relatedTransactions={relatedTransactions}
                  currentPartner={currentPartner}
                >
                  {hasDocumentSummary(message?.type as DocumentType) ? (
                    <DocumentSummary documentType={message?.type as DocumentType} />
                  ) : null}
                </WebEDISidebar>
              ) : null
            }
          >
            {message ? (
              <DocumentSections
                mode={mode}
                uiConfig={uiConfig}
                documentType={message.type as DocumentType}
                variables={message.receiver_partner ? { receiverPartnerName: message.receiver_partner?.name } : {}}
              />
            ) : null}
          </Page.Section>
        </FormProvider>
      </Form>
    </Page>
  );
};
