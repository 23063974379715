import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { BadgeColor, Badge } from 'components/Display/Badge/Badge';
import { Section } from 'components/Display/Section/Section';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'support/helpers/generic/generic';
import { Connectors, IOnboardingStep, IOnboardingStepState } from 'support/types';
import { stepsConfig } from '../../stepsConfig';
import { Button } from 'components/Form/Button/Button';

export const IntegrationOnboardingSteps = ({
  steps,
  variant,
  integrationType,
}: {
  steps: Array<IOnboardingStep>;
  variant: 'OPTIONAL' | 'REQUIRED';
  integrationType?: string;
}) => {
  const { t } = useTranslation();
  if (!steps || steps?.length === 0) return null;

  let title: string = t('onboarding:integration_steps.optional.title');
  if (variant === 'REQUIRED') {
    title = steps.every((step) => step.state === IOnboardingStepState.COMPLETED)
      ? t('onboarding:integration_steps.required.completed')
      : t('onboarding:integration_steps.required.incomplete');
  }

  if (integrationType) {
    if (Connectors.WEB_EDI === integrationType) {
      // t('onboarding:integration_steps.integration_type.WEB_EDI')
      title = `${t(`onboarding:integration_steps.integration_type.${integrationType}`)} ${title}`;
    } else {
      title = `${t(`onboarding:integration_steps.integration_type.allIntegrations`, { connector: t(`integrations:connector.${integrationType}.name`) })} ${title}`;
    }
  }

  return (
    <Section.Vertical header={<Section.Title>{title}</Section.Title>}>
      <ul className="divide-y divide-gray-200">
        {steps.map((step) => (
          <OnboardingStep step={step} key={step.key} />
        ))}
      </ul>
    </Section.Vertical>
  );
};

const classes = {
  COMPLETED: 'p-4 first:pt-0',
  WAITING_FOR_PROCUROS: 'p-4 first:pt-0',
  NOT_STARTED: 'py-8 first:pt-4',
};
const OnboardingStep = ({ step }: { step: IOnboardingStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key, state } = step;
  if (!key || !state) return null;

  const stepConfig = stepsConfig[key];
  return (
    <li key={key} className={classNames('w-full flex items-center justify-between gap-3 px-3', classes[state])}>
      <div className="flex items-center gap-3">
        <OnboardingStepIcon step={step} />
        <OnboardingStepInfo step={step} />
      </div>
      {state === IOnboardingStepState.NOT_STARTED && stepConfig?.cta ? (
        <Button
          variant="primary"
          onClick={() => stepConfig.cta?.href && navigate(stepConfig.cta.href)}
          analyticsId="onboarding:next_step"
        >
          {t(stepConfig.cta.label)}
        </Button>
      ) : (
        <OnboardingStepBadgeStatus status={step.state as IOnboardingStepState} />
      )}
    </li>
  );
};

const OnboardingStepInfo = ({ step }: { step: IOnboardingStep }) => {
  const { t } = useTranslation();
  if (!step.key) return null;
  return (
    <div className="flex flex-col">
      <span
        className={
          step.state !== IOnboardingStepState.COMPLETED ? 'text-sm font-bold text-gray-900' : 'text-sm text-gray-400'
        }
      >
        {t(stepsConfig[step.key]?.title)}
      </span>
      {step.state === IOnboardingStepState.NOT_STARTED ? (
        <span className="text-sm text-gray-500">{t(stepsConfig[step.key]?.description)}</span>
      ) : null}
    </div>
  );
};

const badgeColor = {
  COMPLETED: BadgeColor.green,
  WAITING_FOR_PROCUROS: BadgeColor.yellow,
  NOT_STARTED: undefined,
};
const OnboardingStepBadgeStatus = ({ status }: { status: IOnboardingStepState | undefined }) => {
  const { t } = useTranslation();
  if (!status) return null;

  /*
   * t('onboarding:integration_steps.status.COMPLETED')
   * t('onboarding:integration_steps.status.WAITING_FOR_PROCUROS')
   */
  return (
    <Badge color={badgeColor[status]} hasDot>
      {t(`onboarding:integration_steps.status.${status}`)}
    </Badge>
  );
};

const baseIconClasses = 'h-10 w-10 flex-shrink-0 flex items-center justify-center  rounded-lg';
const icon = {
  COMPLETED: (
    <div className={classNames(baseIconClasses, 'bg-procuros-green-50')}>
      <CheckCircleIcon className="size-6 text-procuros-green-500" aria-hidden="true" />
    </div>
  ),
  WAITING_FOR_PROCUROS: (
    <div className={classNames(baseIconClasses, 'bg-yellow-50')}>
      <ExclamationCircleIcon className="size-6 text-yellow-500 " aria-hidden="true" />
    </div>
  ),
  NOT_STARTED: undefined,
};
const OnboardingStepIcon = ({ step: { state, key } }: { step: IOnboardingStep }) => {
  if (!key || !state) return null;

  return (
    icon[state] ?? <div className={classNames(baseIconClasses, 'bg-procuros-green-500')}>{stepsConfig[key].icon}</div>
  );
};
