import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OfflineNotification = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const showMe = () => setIsVisible(true);
  const hideMe = () => setIsVisible(false);

  useEffect(() => {
    if (!window.navigator.onLine) {
      showMe();
    }

    window.addEventListener('offline', showMe);
    window.addEventListener('online', hideMe);

    return () => {
      window.removeEventListener('offline', showMe);
      window.removeEventListener('online', hideMe);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      data-testid="offline-notification"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <div className="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="w-0 flex-1 p-4">
            <div className="flex items-start">
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-medium text-gray-900">{t('globalOffline.title')}</p>
                <p className="mt-1 text-sm text-gray-500">{t('globalOffline.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
