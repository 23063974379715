import { Dropdown, DropdownOptionType } from 'components/Form/Dropdown/Dropdown';
import { DropdownFilter } from 'components/ListTable/Filters/DropdownFilter';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardCard } from '../components/DashboardCard/DashboardCard';
import { DashboardSection } from '../components/DashboardSection';
import { TRANSACTION_TYPE_OPTIONS, getVolumeStats } from '../helpers';
import { DateRange, TransactionGraphData, TransactionVolumeStats, VolumeStats } from '../types';

type TransactionVolumeProps = {
  dateRangeOptions: Array<DateRange>;
  transactions: TransactionGraphData | undefined;
  volumeDateRange: number;
  previousDateRange: number;
  onUpdateDateRange: (dateRange: number) => void;
  selectedTransactionType: TransactionVolumeStats['type'];
  onSelectTransactionType: (transactionType: TransactionVolumeStats['type']) => void;
  partnerOptions: Array<DropdownOptionType<string>> | undefined;
  selectedPartner: string | undefined;
  onSelectPartner: (partner: string) => void;
};

const getVolumeStatsStatus = (
  itemType: TransactionVolumeStats['type'],
  selectedTransactionType: TransactionVolumeStats['type'],
) => {
  if (!selectedTransactionType) return 'normal';
  return itemType === selectedTransactionType ? 'normal' : 'disabled';
};

export function TransactionVolume({
  dateRangeOptions,
  transactions,
  volumeDateRange,
  previousDateRange,
  onUpdateDateRange,
  selectedTransactionType,
  onSelectTransactionType,
  partnerOptions,
  selectedPartner,
  onSelectPartner,
}: TransactionVolumeProps) {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const initialVolumeStats = getVolumeStats(transactions, volumeDateRange, dateRangeOptions[0].interval, t);
  const [volumeStats, setVolumeStats] = useState<VolumeStats>(initialVolumeStats);

  useEffect(() => {
    setVolumeStats(getVolumeStats(transactions, volumeDateRange, previousDateRange, t));
  }, [transactions, volumeDateRange, currentLanguage, previousDateRange]);

  return (
    <DashboardSection
      title={t('dashboard:transactionVolume.title')}
      actions={
        <div className="relative flex flex-wrap gap-2">
          <Dropdown
            className="w-40"
            dropdownDisplayTestId="TransactionVolumeDropdownDataRangeDisplay"
            value={volumeDateRange}
            options={dateRangeOptions}
            onChange={(newDateRange) => {
              onUpdateDateRange(newDateRange);
            }}
          />
          <DropdownFilter
            filterKey="transactionType"
            className="w-52"
            currentValue={selectedTransactionType || ''}
            testId="TransactionVolumeDropdownTransactionTypeDisplay"
            displayValueLabel={t('transactions:list.filter.transactionTypeOptions.showAll')}
            dropdownValues={TRANSACTION_TYPE_OPTIONS}
            onChange={(_, transactionType) => {
              onSelectTransactionType(transactionType as TransactionVolumeStats['type']);
            }}
          />
          {partnerOptions && (
            <DropdownFilter
              filterKey="partner"
              className="w-52"
              currentValue={selectedPartner ?? ''}
              dropdownValues={partnerOptions}
              displayValueLabel={t('transactions:list.filter.partnerOptions.showAll')}
              testId="TransactionVolumeDropdowSelectedPartnerDisplay"
              onChange={(_, value) => {
                onSelectPartner(value);
              }}
            />
          )}
        </div>
      }
    >
      <div className="grid grid-cols-4">
        {Object.values(volumeStats).map((item) => (
          <DashboardCard
            key={item.name}
            title={item.name}
            value={item.count}
            status={getVolumeStatsStatus(item.type, selectedTransactionType)}
            onClick={() => onSelectTransactionType(item.type)}
          />
        ))}
      </div>
    </DashboardSection>
  );
}
