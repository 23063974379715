import { useFloating, autoUpdate, offset, autoPlacement } from '@floating-ui/react';
import { useState } from 'react';

export const useMessageFieldErrorModal = () => {
  const [showFieldErrorModal, setShowFieldErrorModal] = useState(false);
  const { refs, floatingStyles } = useFloating({
    open: showFieldErrorModal,
    onOpenChange: setShowFieldErrorModal,
    placement: 'right',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      autoPlacement({
        allowedPlacements: ['right', 'left'],
      }),
    ],
  });

  return { setShowFieldErrorModal, showFieldErrorModal, refs, floatingStyles };
};
