import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { LoadingSpinner } from 'components/Loading/LoadingSpinner';
import { MessagePopoverBody, MessagePopoverFooter } from 'components/WebEDI/MessagePopover/MessagePopover';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useWebEDIErrors, WebEDIError } from 'services/webediErrors/webediErrors';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { classNames } from 'support/helpers/generic/generic';
import { TransactionsRoutes } from 'support/types';

export const WithErrorsContent = ({
  isErrorFixed,
  error,
  numberOfErrors,
  isValidating,
  viewMode,
}: {
  isErrorFixed: boolean;
  error: WebEDIError;
  numberOfErrors: number;
  isValidating?: boolean;
  viewMode: boolean;
}) => {
  const { id: messageId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { moveToNextWebEDIError, focusOnCurrentError, nextError } = useWebEDIErrors();
  const showNextErrorButton = numberOfErrors > 1 || (isErrorFixed && numberOfErrors > 0);

  const moveToNextError = () => {
    track('webedi:next_error', { nextError });
    moveToNextWebEDIError();
  };

  const focusOnNextNonFixedError = () => {
    track('webedi:next_error', { nextError });
    focusOnCurrentError();
  };

  return (
    <>
      <MessagePopoverBody>
        <div className="flex justify-between gap-2">
          {isErrorFixed ? (
            <h1 className="text-sm font-bold">{t('webedi:errorModal.withErrors.title.errorFixed')}</h1>
          ) : (
            <h1 className="text-sm font-bold text-red-700">{error.title}</h1>
          )}
          <div className="flex size-5 items-center">
            {isValidating && error ? <LoadingSpinner sizeClassName="size-5" /> : null}
            {isErrorFixed && !isValidating ? <CheckCircleIcon className="size-6 shrink-0 text-green-500" /> : null}
          </div>
        </div>

        {Boolean(isErrorFixed || error.description) && (
          <p className="text-sm text-gray-700">
            {isErrorFixed ? t('webedi:errorModal.withErrors.description.errorFixed') : error.description}
          </p>
        )}
      </MessagePopoverBody>
      <MessagePopoverFooter>
        {viewMode && messageId ? (
          <Button
            type="button"
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              // we need to navigate to the message edit page and focus on the error field
              navigate(generatePath(TransactionsRoutes.edit, { id: messageId }), {
                state: {
                  focusOnField: error,
                },
              });
            }}
            variant="secondary-danger"
            RightIcon={PencilSquareIcon}
            size="extra-small"
            analyticsId="webedi:fix_issue"
          >
            {t('webedi:viewDocument.errorPopover.fixIssueCTA')}
          </Button>
        ) : (
          <Badge type="indicator" color={BadgeColor.red}>
            {viewMode
              ? t('webedi:errorModal.totalErrorsInDocument', { count: numberOfErrors, numberOfErrors })
              : t('webedi:errorModal.errorsRemaining', { count: numberOfErrors, numberOfErrors })}
          </Badge>
        )}

        <Button
          type="button"
          onMouseDown={(e) => e.preventDefault()}
          onClick={isErrorFixed ? focusOnNextNonFixedError : moveToNextError}
          variant="minimal-danger"
          RightIcon={ChevronRightIcon}
          size="extra-small"
          className={classNames(showNextErrorButton ? 'visible' : 'invisible')}
          analyticsId="webedi:next_error"
        >
          {t('webedi:errorModal.nextError')}
        </Button>
      </MessagePopoverFooter>
    </>
  );
};
