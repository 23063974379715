import { Alert } from 'components/Display/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { ConnectionsRoutes } from 'support/types';
import { isReceiver } from '../../helpers';
import { BannersProps } from '../../types';

export const TestMessageRequestedBanners = ({ relationship, currentPartnerId }: BannersProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  if (isReceiver({ relationship, currentPartnerId })) {
    return (
      <div className="space-y-2">
        <Alert severity="neutral" withoutIcon>
          {t('connections:relationshipDetails.header.alert.readyForTesting.receiver.description')}
        </Alert>
      </div>
    );
  }

  return (
    <Alert
      withoutIcon
      severity="info"
      title={t('connections:relationshipDetails.header.alert.readyForTesting.sender.title')}
      actions={{
        primary: {
          variant: 'primary',
          children: t('connections:relationshipDetails.header.alert.readyForTesting.sender.action'),
          onClick: () => navigate(routeToPage(ConnectionsRoutes.testRelationship, { relationshipId: relationship.id })),
          analyticsId: 'testing-flow:send_test_messages_navigate',
        },
      }}
    >
      {t('connections:relationshipDetails.header.alert.readyForTesting.sender.description')}
    </Alert>
  );
};
