import { Ranking, rankings } from '@tanstack/match-sorter-utils';
import {
  ColumnDef,
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  Header,
  Row,
  RowData,
  TableMeta,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { Button } from 'components/Form/Button/Button';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { HeaderCell } from './cells/HeaderCell';
import { BulkEditDialog } from './components/BulkEditDialog/BulkEditDialog';
import { Search } from './components/Search';
import { SelectionWidget } from './components/SelectionWidget/SelectionWidget';
import { Th } from './components/Th';
import { createFuzzyFilter } from './fuzzyFilter';
import { useBulkActions } from './hooks/useBulkActions';
import { useDataRenderMode } from './hooks/useDataRenderMode';
import { useGlobalFilter } from './hooks/useGlobalFilter';
import { useRowSelection } from './hooks/useRowSelection';
import { useVirtualizer } from './hooks/useVirtualizer';
import { setColumnSizes } from './layoutHelpers/setColumnSizes';
import { DataRenderModes } from './Types';
import { useTranslation } from 'react-i18next';
import { TableBody } from './components/TableBody';
import { isActionColumn } from './hooks/useIsActionColumn';
import { useColumnWidth } from './hooks/useColumnWidth';
import { useDataTableSetValue } from './contexts/DataTableContext';

export type DataTableProps<TData, TValue> = {
  /**
   * Component to be displayed in the footer
   */
  footerMenu?: ReactNode;
  /** Provide array of pinned columns ids. Pins to the left. */
  pinnedColumns?: Array<string>;
  /** Enable to show cell borders. */
  showCellBorders?: boolean;
  /**
   * Provide '@tanstack/react-table' configuration
   * @see https://tanstack.com/table/latest/docs/guide/column-defs
   */
  columns: Array<ColumnDef<TData, TValue>>;
  /** Provide table data */
  data: Array<TData>;
  /** Empty state component */
  emptyState?: ReactNode;
  /**
   * Provide '@tanstack/react-table' meta information
   * @see https://tanstack.com/table/latest/docs/api/core/table#meta
   */
  meta: TableMeta<TData>;
  /** Set render mode for the data. Allows to override default 'auto' setting */
  dataRenderMode?: keyof typeof DataRenderModes;
  /** Change a number of non-visible items rendered in virtualized mode. Needed for debugging */
  overscan?: number;
  // TODO: improve type
  /**
   * Provide a custom filtering function for global search
   * @see https://tanstack.com/table/latest/docs/framework/react/examples/filters-fuzzy
   */
  globalFilterFn?: FilterFnOption<TData>;
  searchPositionStrategy?: 'relative' | 'absolute';
  /** Enable to display search input on the top of the table */
  enableGlobalSearch?: boolean;
  /**/
  globalSearchThreshold?: Ranking;
  /**
   * Provide a function to conditionally enable or disable specific row for selection
   * @example
   * // enable selection conditionally per row
   * <DataTable rowSelectionFn={row => row.original.age > 18} />
   */
  rowSelectionFn?: (row: Row<TData>) => boolean;

  /**
   * Component to be displayed in the header
   */
  headerMenu?: ReactNode;
  /**
   * Enable equal width columns
   */
  equalWidthColumns?: boolean;

  /**
   * Table id
   */
  id?: string;
};

const TABLE_HEIGHT = 'calc(100svh - 180px)';
const TABLE_MAX_HEIGHT = '940px'; // 20 rows
const Table = <TData extends RowData, TValue>({
  columns,
  data,
  showCellBorders = true,
  emptyState,
  meta,
  pinnedColumns = [],
  headerMenu,
  footerMenu,
  dataRenderMode: dataRenderModeProp = DataRenderModes.auto,
  overscan = 5,
  globalFilterFn: globalFilterFnProp,
  enableGlobalSearch = true,
  searchPositionStrategy = 'relative',
  globalSearchThreshold = rankings.CONTAINS,
  rowSelectionFn,
  equalWidthColumns,
  id,
}: DataTableProps<TData, TValue>) => {
  const { t } = useTranslation();
  const globalFilterFn = useMemo(() => {
    if (!globalFilterFnProp) {
      return createFuzzyFilter<TData>(globalSearchThreshold);
    }
    return globalFilterFnProp;
  }, [globalFilterFnProp, globalSearchThreshold]);

  const { globalFilter, setGlobalFilter, handleResetFilter } = useGlobalFilter();
  const { rowSelection, enableRowSelection, setRowSelection, displayWidget, clearSelection } = useRowSelection({
    columns,
    rowSelectionFn,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      columnPinning: {
        left: pinnedColumns,
      },
      globalFilter,
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    meta,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const { rows } = table.getRowModel();
  const dataRenderMode = useDataRenderMode({ mode: dataRenderModeProp, rows });
  const { parentRef, before, after, virtualRows } = useVirtualizer({ rows, overscan, dataRenderMode });
  const hasScroll = dataRenderMode === DataRenderModes.scrollable || dataRenderMode === DataRenderModes.virtualized;
  const hasRows = rows.length > 0;
  const scrollStyle: CSSProperties =
    dataRenderMode !== DataRenderModes.plain
      ? { height: TABLE_HEIGHT, maxHeight: TABLE_MAX_HEIGHT }
      : { maxHeight: TABLE_HEIGHT };

  const setValue = useDataTableSetValue();
  const { isBulkEditModalOpen, handleBulkEditModalClose, bulkColumns, handleBulkEdit, handleBulkEditButtonClick } =
    useBulkActions({
      columns,
      rowSelection,
      setTableData: (value) => {
        setValue(undefined, value);
      },
      tableData: data,
    });

  const columnsWidth = useColumnWidth(columns, Boolean(equalWidthColumns));
  const bodyVirtualizationConfig = useMemo(() => ({ before, after, virtualRows }), [after, before, virtualRows]);
  return (
    <>
      <BulkEditDialog
        isOpen={isBulkEditModalOpen}
        closeSelf={handleBulkEditModalClose}
        inputsConfig={bulkColumns}
        onApply={handleBulkEdit}
      />
      <div className="w-full">
        <div
          className={classNames('inset-x-0 top-0', searchPositionStrategy, {
            'mt-3': searchPositionStrategy === 'absolute',
          })}
        >
          {enableGlobalSearch || headerMenu ? (
            <div className="mb-2 flex justify-end gap-2">
              {enableGlobalSearch ? <Search filter={globalFilter} onFilterChange={setGlobalFilter} /> : null}
              {headerMenu ? headerMenu : null}
            </div>
          ) : null}
        </div>
        <div className="relative">
          {displayWidget && (
            <SelectionWidget
              rowSelection={rowSelection}
              handleBulkEditButtonClick={handleBulkEditButtonClick}
              clearSelection={clearSelection}
            />
          )}
        </div>
        <div
          ref={parentRef}
          style={scrollStyle}
          id={id}
          className={classNames('relative overflow-auto rounded-md', {
            'border border-gray-200': hasRows,
          })}
        >
          {hasRows ? (
            <table
              className={classNames('w-full border-separate border-spacing-0 text-xs', {
                'table-fixed': equalWidthColumns,
                'table-auto': true,
              })}
            >
              <thead className={classNames({ 'sticky top-0 left-0 z-10': hasScroll })}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => {
                      const isAction = isActionColumn(header.column.columnDef);
                      const fixedWidth = header.column.columnDef.meta?.style?.width;

                      return (
                        <Th
                          isAction={isAction}
                          key={header.id}
                          // TODO: improve header type
                          header={header as Header<unknown, unknown>}
                          betweenCellBorders={showCellBorders}
                          isFirstColumn={!index}
                          isLastColumn={index === headerGroup.headers.length - 1}
                          ref={(thElem: HTMLTableCellElement | null) => setColumnSizes(thElem, table, header.column)}
                          style={
                            !isAction
                              ? { width: fixedWidth ?? columnsWidth, minWidth: fixedWidth, maxWidth: fixedWidth }
                              : undefined
                          }
                        />
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <TableBody<TData, TValue>
                dataRenderMode={dataRenderMode}
                virtualizationConfig={bodyVirtualizationConfig}
                rows={rows}
                columns={columns}
                showCellBorders={showCellBorders}
                columnsWidth={columnsWidth}
              />
            </table>
          ) : (
            <div className="flex w-full flex-col items-center gap-3 rounded-md bg-gray-50 p-4 text-sm text-gray-500">
              <div>{!globalFilter ? emptyState : t('common:dataTable.search.emptyState')}</div>
              {globalFilter && (
                <Button variant="secondary" onClick={handleResetFilter} analyticsId="table_search_clear">
                  {t('common:dataTable.search.clearSearch')}
                </Button>
              )}
            </div>
          )}
        </div>
        {footerMenu ? <div className="mt-3">{footerMenu}</div> : null}
      </div>
    </>
  );
};

export const DataTable = Object.assign(Table, { HeaderCellBuilder: HeaderCell });
