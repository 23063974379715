import { DocumentEnvironmentBadge } from 'pages/Transactions/components/DocumentEnvironmentBadge/DocumentEnvironmentBadge';
import { useTranslation } from 'react-i18next';
import { DocumentType, RelationshipV2 } from 'support/types';

export const DocumentTitle = ({ relationship }: { relationship: RelationshipV2 | undefined }) => {
  const { t } = useTranslation();
  let title = t('webedi:createDocument.titles.default');
  switch (relationship?.message_type) {
    case DocumentType.productCatalog:
      return t('webedi:createDocument.titles.productCatalog');
    case DocumentType.orderResponse:
      title = t('webedi:createDocument.titles.orderResponse');
      break;
    case DocumentType.shippingNotice:
      title = t('webedi:createDocument.titles.shippingNotice');
      break;
    case DocumentType.invoice:
      title = t('webedi:createDocument.titles.invoice');
      break;
    case DocumentType.creditNote:
      title = t('webedi:createDocument.titles.creditNote');
      break;
  }

  return (
    <div className="flex items-center gap-3">
      <h1>{title}</h1>
      <DocumentEnvironmentBadge relationshipStatus={relationship?.status} />
    </div>
  );
};
