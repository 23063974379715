import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useCreatePartner, useCurrentPartner } from 'services/repositories/partners/partners';
import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';
import { DocumentTypeSetting, createTradePartnerRequests, defaultDocumentSettings } from '../helpers';
import { useForm, useWatch } from 'react-hook-form';
import { useCreateTradeRequest } from 'services/repositories/relationships/relationships';
import { FileInput } from 'components/Form/FileInput/FileInput';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { addHostToUrl, downloadFile } from 'services/http/http';
import { CSVRow, CSVRowKeys, validateCSV } from './helpers';
import { CSVLoader } from 'components/Form/FileInput/Loaders/CSVLoader/CSVLoader';
import { SlideOver } from 'components/Display/SlideOver/SlideOver';
import { Form } from 'components/Form/Form';
import { IdentifierDomainOptions } from 'support/types';
import { AllowedLanguages } from 'i18n';
import { Button } from 'components/Form/Button/Button';
import { CheckboxInABox } from 'components/Form/Checkbox/Checkbox';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { DocumentSettingsCheckboxes } from '../DocumentSettingsCheckboxes/DocumentSettingsCheckboxes';

type NewBulkTradePartnerProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};

type NewTradePartnerForm = {
  tradePartners: Array<{
    name: string;
    contactPersons: Array<{
      name: string;
      email: string;
      phone?: string;
      language: AllowedLanguages;
    }>;
    partyIdentifierDomain: IdentifierDomainOptions;
    partyIdentifier: string;
  }>;
  documentTypeSettings: Array<any>; //having issues with required and nullable fiels on schemas so using any for now. ChaGPT + co-pilot didn't help
  includeAsCCRecipient?: boolean;
};

const documentTypeSettingSchema = Yup.object();

const contactPersonSchema = Yup.object()
  .shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string(),
    language: Yup.string().oneOf(['en', 'de']).required(),
  })
  .required();

const tradePartnerSchema = Yup.object().shape({
  name: Yup.string().required(),
  contactPersons: Yup.array().of(contactPersonSchema).required().min(1),
  partyIdentifierDomain: Yup.mixed<IdentifierDomainOptions>().oneOf(Object.values(IdentifierDomainOptions)).required(),
  partyIdentifier: Yup.string().required(),
});

const newTradePartnerFormSchema = Yup.object().shape({
  tradePartners: Yup.array().of(tradePartnerSchema).required().min(1),
  documentTypeSettings: Yup.array()
    .of(documentTypeSettingSchema)
    .required()
    .min(1)
    .test('', function (array) {
      return array.some((item) => (item as DocumentTypeSetting).incoming || (item as DocumentTypeSetting).outgoing);
    }),
  includeAsCCRecipient: Yup.boolean(),
});

const TRADE_PARTNER_TEMPLATE_FILE = addHostToUrl('/assets/TradePartnersTemplate.csv');

export function NewBulkTradePartner({ showPopup, setShowPopup }: NewBulkTradePartnerProps) {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const {
    handleSubmit,
    setValue,
    register,
    reset,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<NewTradePartnerForm>({
    defaultValues: {
      tradePartners: [],
      documentTypeSettings: defaultDocumentSettings,
      includeAsCCRecipient: true,
    },
    mode: 'all',
    resolver: yupResolver(newTradePartnerFormSchema),
  });

  const documentTypeSettings = useWatch({ name: 'documentTypeSettings', control });
  const { data: currentPartner } = useCurrentPartner();
  const createPartnerMutation = useCreatePartner();
  const createTradeRequestMutation = useCreateTradeRequest();
  const PrimaryButton = () => (
    <Button
      type="submit"
      form="newBulkTradePartnerForm"
      disabled={!isValid || isSubmitting || !!Object.keys(errors).length}
      analyticsId="partners:bulk_trade_partner_submit"
    >
      <div data-testid="action-button">{t('partners:request.newBulkTradePartner.primaryButton')}</div>
    </Button>
  );

  const CancelButton = () => (
    <Button onClick={onClose} variant="secondary" analyticsId="partners:bulk_trade_partner_cancel">
      <div data-testid="cancel-button">{t('common:cancel')}</div>
    </Button>
  );

  const onSubmit = async (submission: NewTradePartnerForm) => {
    if (!currentPartner) {
      return;
    }

    setNonInputErrors([]);
    const requestedDocumentTypes = submission.documentTypeSettings.filter(
      (setting) => setting.outgoing || setting.incoming,
    );
    if (!requestedDocumentTypes.length) {
      setNonInputErrors([t('partners:request.noDocumentTypesSelected')]);
      return;
    }

    try {
      await createTradePartnerRequests({
        createPartnerMutation: createPartnerMutation,
        createTradeRequestMutation: createTradeRequestMutation,
        currentPartner,
        submission: {
          requestedDocumentTypes,
          tradePartners: submission.tradePartners,
          includeAsCCRecipient: submission.includeAsCCRecipient,
        },
      });

      onClose();
      addNotification(t('partners:request.newTradePartner.success'));
    } catch (err) {
      processSubmissionErrors({
        error: err as Error,
        setInputError: undefined,
        setNonInputErrors,
        defaultData: undefined,
        t,
      });
    }
  };

  const onClose = () => {
    setShowPopup(false);
    setNonInputErrors([]);
    reset();
  };

  const onError = () => {
    addNotification(
      {
        title: t('partners:request.newBulkTradePartner.form.upload.invalid.title'),
        subtitle: t('partners:request.newBulkTradePartner.form.upload.invalid.subtitle'),
      },
      NotificationType.error,
    );
  };

  const onClearFileInput = () => {
    setValue('tradePartners', [], { shouldValidate: true });
  };

  const onLoadFile = (data: Array<CSVRow>) => {
    addNotification(t('partners:request.newBulkTradePartner.form.upload.valid'), NotificationType.success);
    setValue(
      'tradePartners',
      data.map((row: CSVRow) => {
        const language = row[CSVRowKeys.Language].toLocaleLowerCase().trim() as AllowedLanguages;
        return {
          name: row[CSVRowKeys.TradePartnerName],
          partyIdentifierDomain: row[CSVRowKeys.GLN]
            ? IdentifierDomainOptions.GS1
            : IdentifierDomainOptions.RECEIVER_INTERNAL,
          partyIdentifier: row[CSVRowKeys.GLN] || row[CSVRowKeys.OtherIdentifier],
          contactPersons: [
            {
              name: row[CSVRowKeys.ContactPerson],
              email: row[CSVRowKeys.EmailAddress],
              phone: getContactPersonLanguage(language),
              language,
            },
          ],
        };
      }),
      { shouldValidate: true },
    );
  };

  const onDocumentSettingsChange = (documentTypeSettings: Array<DocumentTypeSetting>) => {
    setValue('documentTypeSettings', documentTypeSettings, { shouldValidate: true });
  };

  return (
    <SlideOver
      size="large"
      footer={
        <>
          <CancelButton />
          <PrimaryButton />{' '}
        </>
      }
      title={t('partners:request.newBulkTradePartner.heading')}
      subtitle={t('partners:request.newBulkTradePartner.subHeading')}
      open={showPopup}
      setOpen={onClose}
    >
      <Form id="newBulkTradePartnerForm" onSubmit={handleSubmit(onSubmit)} className="grid gap-6">
        <ValidationErrors errors={nonInputErrors} />

        <Button
          LeftIcon={ArrowDownTrayIcon}
          onClick={() => downloadFile(TRADE_PARTNER_TEMPLATE_FILE)}
          analyticsId="partners:bulk_trade_partner_template_download"
        >
          {t('partners:request.newBulkTradePartner.dowloadTemplate')}
        </Button>
        <FileInput
          label={t('partners:request.newBulkTradePartner.form.upload.label')}
          accept={{
            'text/csv': ['.csv'],
          }}
          validate={validateCSV}
          onClear={onClearFileInput}
          onError={onError}
          onLoad={onLoadFile}
          loader={CSVLoader}
        />

        <DocumentSettingsCheckboxes
          documentTypeSettings={documentTypeSettings}
          setDocumentTypeSettings={onDocumentSettingsChange}
        />

        <CheckboxInABox
          label={t('partners:request.includeAsCCRecipient.label')}
          description={t('partners:request.includeAsCCRecipient.description')}
          {...register('includeAsCCRecipient')}
        />
      </Form>
    </SlideOver>
  );
}

const getContactPersonLanguage = (language: string): AllowedLanguages => {
  const possibleSupportedLanguageCode = language?.substring(0, 2).toLowerCase();
  return ['de', 'en'].includes(possibleSupportedLanguageCode)
    ? (possibleSupportedLanguageCode as AllowedLanguages)
    : 'de';
};
