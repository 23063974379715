import { ENV } from 'services/environment/environment';
import { UpdateExtractionResult } from './types';
import { logError } from 'services/logging/logging';

export const recordExtractionQuality = async (documentHash: string, extractionResultUpdate: UpdateExtractionResult) => {
  try {
    await fetch(`${ENV.DOCUPARSE_API_URL}api/v1/extraction_quality/${documentHash}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ENV.DOCUPARSE_API_KEY}`,
      },
      body: JSON.stringify(extractionResultUpdate),
    });
  } catch (e: unknown) {
    logError(e);
  }
};
