import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button/Button';
import { ReactNode, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'support/helpers/generic/generic';
import { SidebarKeys } from 'support/types/localStorage';
import { useLocalStorage } from 'usehooks-ts';

type CollapsibleSidebarProps = {
  side?: 'left' | 'right';
  children: ReactNode | ((collapsed: boolean) => ReactNode);
  bottomSlot?: ReactNode;
  sidebarKey: SidebarKeys;
};
const renderChildren = (children: CollapsibleSidebarProps['children'], args: any) =>
  typeof children === 'function' ? children(args) : children;

export const CollapsibleSidebar: FC<CollapsibleSidebarProps> = ({
  children,
  bottomSlot,
  side = 'right',
  sidebarKey,
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useLocalStorage(sidebarKey, false);
  const toggleCollapsed = () => setCollapsed((prev) => !prev);

  const collapsedIcon = side === 'left' ? ArrowLongRightIcon : ArrowLongLeftIcon;
  const openIcon = side === 'left' ? ArrowLongLeftIcon : ArrowLongRightIcon;

  return (
    <aside
      className={classNames(
        'flex flex-col justify-between border-gray-200 divide-y',
        collapsed ? 'w-[72px]' : 'w-[282px]',
        side === 'left' ? 'border-r' : 'border-l',
      )}
      style={{ height: `var(--content-height)` }}
    >
      <div className={classNames(collapsed ? 'px-4' : 'px-6', 'py-6 overflow-y-auto')}>
        {renderChildren(children, collapsed)}
      </div>
      <div className="divide-y">
        {bottomSlot && !collapsed ? <div className="p-6 pb-4">{bottomSlot}</div> : null}
        <div className="hover:bg-procuros-green-50">
          <Button
            analyticsId="toggle_right_sidebar"
            fullWidth
            variant="minimal"
            size={'extra-small'}
            onClick={toggleCollapsed}
            RightIcon={collapsed ? collapsedIcon : openIcon}
            iconOnly={collapsed}
            className={classNames(collapsed ? 'px-6 py-5' : 'px-6 py-5 justify-between')}
          >
            {collapsed ? t('common:rightSidebar.open') : t('common:rightSidebar.close')}
          </Button>
        </div>
      </div>
    </aside>
  );
};
