const indexOf = (array: Array<any>, value: any) => {
  return array.indexOf(value);
};
export const formatBasedOnSortArray =
  <T, Y = T>(sortArray: Array<T>, indexOfFunction: (array: Array<T>, value: Y) => number = indexOf) =>
  (a: Y, b: Y) => {
    const indexA = indexOfFunction(sortArray, a);
    const indexB = indexOfFunction(sortArray, b);

    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  };

export const getLoopArrayPositions = (currentIndex: number, array: Array<unknown>) => {
  const current = currentIndex < 0 ? 0 : currentIndex;
  const arrayLength = array.length;
  const previous = (current - 1 + arrayLength) % arrayLength;
  const next = (current + 1) % arrayLength;

  return { previous, current, next };
};
