import { Trans, useTranslation } from 'react-i18next';
import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button/Button';

type Props = {
  onAction: () => void;
  onClose: () => void;
  isProcessing?: boolean;
  isOpen: boolean;
};

export const AlreadyExistingMessageModal = ({ isOpen, onAction, onClose, isProcessing }: Props) => {
  const { t } = useTranslation();

  const PrimaryButton = () => (
    <Button onClick={onAction} loading={isProcessing} analyticsId="webedi:already_existing_modal_confirm">
      <div data-testid="action-button">{t('transactions:show.alreadyExistingMessageModal.actionText')}</div>
    </Button>
  );
  const CancelButton = ({ cancelButtonRef }: { cancelButtonRef: React.RefObject<HTMLButtonElement> }) => (
    <Button
      onClick={onClose}
      disabled={isProcessing}
      variant="secondary"
      ref={cancelButtonRef}
      analyticsId="webedi:already_existing_modal_cancel"
    >
      <div data-testid="cancel-button">{t('transactions:show.alreadyExistingMessageModal.cancelText')}</div>
    </Button>
  );

  return (
    <Popup
      CancelButton={CancelButton}
      PrimaryButton={PrimaryButton}
      title={<Trans i18nKey="transactions:show.alreadyExistingMessageModal.title" />}
      open={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-col items-center">
        <p className="text-base text-gray-500">
          <Trans i18nKey="transactions:show.alreadyExistingMessageModal.message" />
        </p>
      </div>
    </Popup>
  );
};
