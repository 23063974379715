import { useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreatePartner, useCurrentPartner } from 'services/repositories/partners/partners';
import { useCreateTradeRequest } from 'services/repositories/relationships/relationships';
import { useNotificationStore } from 'stores/notifications/notifications';
import {
  createTradePartnerRequests,
  defaultDocumentSettings,
  DocumentTypeSetting,
  createDocumentTypesPayload,
} from '../helpers';
import { SlideOver } from 'components/Display/SlideOver/SlideOver';
import { TradeRequestMessage } from '../TradeRequestMessage/TradeRequestMessage';
import { TradePartnerSuggestion } from '../TradePartnerSuggestion/TradePartnerSuggestion';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewTradePartnerForm, NewTradePartnerFormSchema, defaultData, isFormWithPartnerId } from './formHelpers';
import { Form } from 'components/Form/Form';
import { PartnerIdentifiers } from '../PartnerIdentifiers/PartnerIdentifiers';
import { AllowedLanguages } from 'i18n';
import { CheckboxInABox } from 'components/Form/Checkbox/Checkbox';
import { Input } from 'components/Form/Input/Input/Input';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { classNames } from 'support/helpers/generic/generic';
import { DocumentSettingsCheckboxes } from '../DocumentSettingsCheckboxes/DocumentSettingsCheckboxes';

type NewTradePartnerProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};

export function NewTradePartner({ showPopup, setShowPopup }: NewTradePartnerProps) {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  const [nonInputErrors, setNonInputErrors] = useState<Array<string>>([]);
  const formMethods = useForm<NewTradePartnerForm>({
    defaultValues: defaultData,
    resolver: yupResolver(NewTradePartnerFormSchema(t)),
  });

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
  } = formMethods;

  const [tradePartnerName, tradePartnerId] = useWatch({
    name: ['name', 'partnerId'],
    control,
  });
  const [documentTypeSettings, setDocumentTypeSettings] = useState<Array<DocumentTypeSetting>>(defaultDocumentSettings);
  const { data: currentPartner } = useCurrentPartner();
  const createPartnerMutation = useCreatePartner();
  const createTradeRequestMutation = useCreateTradeRequest();
  const isLoading = createPartnerMutation.isLoading || createTradeRequestMutation.isLoading;

  const onSubmit = async (submission: NewTradePartnerForm) => {
    if (!currentPartner) {
      return;
    }

    setNonInputErrors([]);
    const requestedDocumentTypes = documentTypeSettings.filter((setting) => setting.outgoing || setting.incoming);
    if (!requestedDocumentTypes.length) {
      setNonInputErrors([t('partners:request.noDocumentTypesSelected')]);
      return;
    }

    if (isFormWithPartnerId(submission)) {
      const relationships = createDocumentTypesPayload(currentPartner.id, submission.partnerId, requestedDocumentTypes);
      createTradeRequestMutation.mutate(
        {
          tradeRequests: [
            {
              partnerId: submission.partnerId,
              relationships,
              message: submission.message,
              partyIdentifierDomain: submission.partyIdentifierDomain,
              partyIdentifier: submission.partyIdentifier,
              includeAsCCRecipient: submission.includeAsCCRecipient,
            },
          ],
        },
        {
          onSuccess: () => {
            onClose();
            addNotification(t('partners:request.newTradePartner.success'));
          },
          onError: (err) => {
            processSubmissionErrors({
              error: err as Error,
              setInputError: setError,
              setNonInputErrors,
              defaultData,
              t,
            });
          },
        },
      );
      return;
    }

    try {
      await createTradePartnerRequests({
        createPartnerMutation: createPartnerMutation,
        createTradeRequestMutation: createTradeRequestMutation,
        currentPartner,
        submission: {
          requestedDocumentTypes,
          message: submission.message,
          includeAsCCRecipient: submission.includeAsCCRecipient,
          tradePartners: [
            {
              name: submission.name,
              contactPersons: submission.contactPersons,
              partyIdentifierDomain: submission.partyIdentifierDomain,
              partyIdentifier: submission.partyIdentifier,
            },
          ],
        },
      });
      onClose();
      addNotification(t('partners:request.newTradePartner.success'));
    } catch (err) {
      processSubmissionErrors({
        error: err as Error,
        setInputError: setError,
        setNonInputErrors,
        defaultData,
        t,
      });
    }
  };

  const onClose = () => {
    setShowPopup(false);
    setNonInputErrors([]);
    reset();
    setDocumentTypeSettings(defaultDocumentSettings);
  };

  const PrimaryButton = () => (
    <Button
      loading={isLoading}
      type="submit"
      form="newTradePartnerForm"
      analyticsId="partners:new_trade_partner_request"
    >
      <div data-testid="action-button">{t('partners:request.newTradePartner.primaryButton')}</div>
    </Button>
  );

  const CancelButton = () => (
    <Button disabled={isLoading} onClick={onClose} variant="secondary" analyticsId="partners:new_trade_partner_cancel">
      <div data-testid="cancel-button">{t('common:cancel')}</div>
    </Button>
  );

  return (
    <SlideOver
      size="large"
      open={showPopup}
      setOpen={onClose}
      title={t('partners:request.newTradePartner.heading')}
      subtitle={t('partners:request.newTradePartner.subHeading')}
      footer={
        <>
          <CancelButton />
          <PrimaryButton />
        </>
      }
    >
      <div className={classNames(nonInputErrors.length ? 'mb-6' : undefined)}>
        <ValidationErrors errors={nonInputErrors} />
      </div>

      <FormProvider {...formMethods}>
        <Form
          id="newTradePartnerForm"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-6 divide-y divide-gray-200 [&>*:first-child]:pt-0 [&>*]:pt-6"
        >
          <div className="mb-5 flex flex-col gap-2">
            <div className="mb-5 grid grid-cols-2 gap-2">
              <Input
                label={t('partners:request.tradePartnerName')}
                {...register('name')}
                required
                errors={errors.name}
              />

              <Input
                label={t('partners:request.contactPerson')}
                {...register('contactPersons.0.name')}
                required
                disabled={Boolean(tradePartnerId)}
                errors={errors.contactPersons?.[0]?.name}
              />

              <Input
                label={t('partners:request.email')}
                type="email"
                {...register('contactPersons.0.email')}
                required
                errors={errors.contactPersons?.[0]?.email}
                disabled={Boolean(tradePartnerId)}
              />

              <Input
                label={t('partners:request.phone')}
                {...register('contactPersons.0.phone')}
                errors={errors.contactPersons?.[0]?.phone}
                disabled={Boolean(tradePartnerId)}
              />

              <Controller
                render={({ field, fieldState }) => (
                  <Dropdown<AllowedLanguages>
                    {...field}
                    required
                    wrapperClassName="col-span-full"
                    label={t('partners:request.language')}
                    options={[
                      { value: 'de', label: t('common:language.de') },
                      { value: 'en', label: t('common:language.en') },
                    ]}
                    errors={fieldState.error}
                  />
                )}
                name="contactPersons.0.language"
              />
            </div>
            <TradePartnerSuggestion tradePartnerName={tradePartnerName} />
          </div>

          <PartnerIdentifiers />

          <DocumentSettingsCheckboxes
            documentTypeSettings={documentTypeSettings}
            setDocumentTypeSettings={setDocumentTypeSettings}
          />

          <div className="space-y-6">
            <TradeRequestMessage />

            <CheckboxInABox
              label={t('partners:request.includeAsCCRecipient.label')}
              description={t('partners:request.includeAsCCRecipient.description')}
              {...register('includeAsCCRecipient')}
            />
          </div>
        </Form>
      </FormProvider>
    </SlideOver>
  );
}
