import { Popup } from 'components/Display/Popup/Popup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useCallback, useId, useState } from 'react';
import { useCreateIntegrationRequest } from 'services/repositories/integrations/integrations';
import { Form } from 'components/Form/Form';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { Button } from 'components/Form/Button/Button';
import { Textarea } from 'components/Form/Input/Textarea/Textarea';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { Connectors, ConnectorsType } from 'support/types';

type ContactModalProps = {
  connector: Connectors | undefined;
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
};

type ContactModalFormData = {
  description: string;
  connector: Connectors;
};

export const ContactModal = ({ open, onCancel, onSuccess, connector }: ContactModalProps) => {
  const { t } = useTranslation();
  const formId = useId();
  const { mutate, isLoading } = useCreateIntegrationRequest();
  const [submissionErrors, setSubmissionErrors] = useState<Array<string>>([]);

  const contactSchema = Yup.object().shape({
    connector: Yup.string().oneOf(Object.values(Connectors)).required(),
    description: Yup.string().required(
      t('common:form.required', {
        field: t('integrations:connectorSelector.contactPopup.message.label'),
      }),
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactModalFormData>({
    resolver: yupResolver(contactSchema),
    values: connector
      ? {
          connector,
          description: '',
        }
      : undefined,
  });

  const submit = (data: ContactModalFormData) => {
    const { connector, description } = data;
    mutate(
      {
        connector: connector as ConnectorsType,
        description,
      },
      {
        onSuccess,
        onError: (error) => {
          processSubmissionErrors({
            error,
            setInputError: undefined,
            setNonInputErrors: setSubmissionErrors,
            defaultData: undefined,
            t,
          });
        },
      },
    );
  };

  const PrimaryButton = useCallback(
    () => (
      <Button form={formId} type="submit" loading={isLoading} analyticsId="integrations:contact-send">
        {t('common:send')}
      </Button>
    ),
    [t, formId, isLoading],
  );

  const CancelButton = useCallback(
    () => (
      <Button variant="secondary" onClick={onCancel} analyticsId="integrations:contact-cancel">
        {t('common:cancel')}
      </Button>
    ),
    [t, onCancel],
  );

  return (
    <Popup
      PrimaryButton={PrimaryButton}
      CancelButton={CancelButton}
      title={t('integrations:connectorSelector.contactPopup.title')}
      subtitle={t('integrations:connectorSelector.contactPopup.description')}
      open={open}
      onClose={onCancel}
    >
      <ValidationErrors errors={submissionErrors} />
      <Form onSubmit={handleSubmit(submit)} id={formId}>
        <Textarea
          label={t('integrations:connectorSelector.contactPopup.message.label')}
          {...register('description')}
          errors={errors.description}
        />
      </Form>
    </Popup>
  );
};
