import { ui } from '@procuros/datachecks';
import { useFlattenData } from 'hooks/useFlattenData';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { UseFormReset, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { applyRowMasksToData, getCanonical } from 'services/datachecks/datachecks';
import { logError } from 'services/logging/logging';
import { setFlattenData, setInitialData, setIsLoading, setUiConfig } from 'stores/datachecks/datachecks';
import useDebouncePromise, { CANCELLED_ERROR } from 'support/helpers/hooks/useDebouncePromise';
import { enrichDocument } from 'support/helpers/transformation/transformer';
import { DocumentType, ProcessSpecificationDTO } from 'support/types';
import { useDebounceValue } from 'usehooks-ts';

const DEBOUNCE_TIME = 1000;
type UseDatachecksDataArgs = {
  targetRelationshipId: string | undefined;
  documentType: DocumentType | undefined;
  reset: UseFormReset<any>;
  isSubmitted: boolean;
  trigger: UseFormTrigger<any>;
  watch: UseFormWatch<any>;
  processSpecification: ProcessSpecificationDTO | undefined;
};

export const useDatachecksData = ({
  targetRelationshipId,
  documentType,
  isSubmitted,
  reset,
  trigger,
  watch,
  processSpecification,
}: UseDatachecksDataArgs) => {
  const [formData, setFormData] = useDebounceValue(undefined, DEBOUNCE_TIME);
  useEffect(() => {
    const { unsubscribe } = watch((value: any) => {
      setFormData(value);
    });
    return () => unsubscribe();
  }, [setFormData, watch]);

  const { data, uiConfig, isLoading } = useFlattenData(processSpecification);

  const debouncedProcessFormData = useDebouncePromise(processFormData, DEBOUNCE_TIME);

  useEffect(() => {
    let isCancelled = false;
    const executeProcessFormData = async () => {
      try {
        const { flattenData, uiConfig } = await debouncedProcessFormData({
          formData,
          receiverProcessSpecification: processSpecification,
          documentType,
        });

        if ((window as any).procuros_debug) {
          console.log({ flattenData, uiConfig });
        }

        if (!isCancelled) {
          if ((window as any).procuros_debug) {
            console.warn('merging flattened data and uiConfig');
          }

          let filteredFlattenData = flattenData;
          if (uiConfig && flattenData) {
            filteredFlattenData = applyRowMasksToData(flattenData, uiConfig);
          }
          // Just updating the flattenData on the store
          // does not work as there is a race condition
          // that causes trigger() to execute the form validation
          // using stale data.
          reset(filteredFlattenData, {
            keepIsSubmitted: true,
          });
          if (isSubmitted) {
            trigger();
          }
          setUiConfig(uiConfig);
        }
      } catch (error) {
        if ((error as Error).message !== CANCELLED_ERROR) logError(error);
      }
    };
    executeProcessFormData();
    return () => {
      isCancelled = true;
      debouncedProcessFormData.cancel();
    };
  }, [documentType, processSpecification, targetRelationshipId, formData, reset, trigger, debouncedProcessFormData]);

  useEffect(() => {
    if (uiConfig) {
      setUiConfig(uiConfig);
    }

    if (data) {
      let filteredData = data;
      if (uiConfig) {
        filteredData = applyRowMasksToData(data, uiConfig);
      }

      setInitialData(filteredData);
      setFlattenData(filteredData);
    }
  }, [data, uiConfig]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);
};

type ProcessFormDataArgs = {
  formData: any;
  receiverProcessSpecification: ProcessSpecificationDTO | undefined;
  documentType: DocumentType | undefined;
};

type ProcessFormReturn = Promise<{ flattenData: any; uiConfig: any }>;

let previousEnrichedCanonical: any = null;
const processFormData = ({
  formData,
  receiverProcessSpecification,
  documentType,
}: ProcessFormDataArgs): ProcessFormReturn => {
  return new Promise((resolve, reject) => {
    try {
      const hasData = formData && Object.keys(formData).length > 0;
      if (receiverProcessSpecification && hasData) {
        getCanonical(receiverProcessSpecification, formData)
          .then((wrappedCanonical) => {
            const canonical = wrappedCanonical.canonical;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).procuros_debug) {
              console.log('Result canonical', canonical);
            }

            const enrichedDocument = enrichDocument({ data: canonical, messageType: documentType });
            if (!isEqual(enrichedDocument, previousEnrichedCanonical)) {
              previousEnrichedCanonical = enrichedDocument;
              ui.view({ fields: receiverProcessSpecification?.fields as any }, enrichedDocument)
                .then((out) => {
                  // We need to use previous formData and merge it with the new out.values
                  // to keep the state of the form

                  const hasLineItems = 'line_items' in out.values;
                  const newFlattenData = {
                    ...out.values,
                    ...(hasLineItems
                      ? {
                          line_items: out.values.line_items.map((lineItem: any, index: number) => {
                            return {
                              ...lineItem,
                              _internal: {
                                ...formData['line_items']?.[index]?._internal,
                                ...lineItem._internal,
                              },
                            };
                          }),
                        }
                      : {}),
                  };
                  resolve({ flattenData: newFlattenData, uiConfig: out.view });
                })
                .catch(logError);
            }
          })
          .catch((error) => {
            logError(error);
            reject(error);
          });
      }
    } catch (error) {
      logError(error);
      reject(error);
    }
  });
};
