import { PlusIcon } from '@heroicons/react/20/solid';
import { Card } from 'components/Display/Card/Card';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';
import { ModificationDTO, ModificationGroupDTO, ModificationReasonCode, ModificationType } from 'support/types';
import { ModificationLevels } from './ModificationLevels';
import { ModificationCalculationType } from '../invoiceCalculations/invoiceCalculations';

type InvoiceModificationsProps = {
  modificationGroups?: Array<ModificationGroupDTO>;
  hideTotal?: boolean;
  onChange: (modificationGroups: Array<ModificationGroupDTO>) => void;
  disabled?: boolean;
};

export function InvoiceModifications({ modificationGroups, onChange, disabled }: InvoiceModificationsProps) {
  const { t } = useTranslation();

  const addModification = (levelToModify: number) => {
    const newModificationGroups = (modificationGroups ?? []).map((modificationGroup) => {
      if (modificationGroup.level === levelToModify) {
        return {
          ...modificationGroup,
          modifications: [
            ...modificationGroup.modifications,
            {
              type: 'ALLOWANCE' as ModificationType,
              reasonCode: 'DISCOUNT' as ModificationReasonCode,
              calculationType: ModificationCalculationType.relative,
            },
          ],
        };
      }
      return modificationGroup;
    });

    onChange(newModificationGroups);
  };

  const removeModification = (levelToModify: number, modificationPosition: number) => {
    const newModificationGroups = (modificationGroups ?? []).map((modificationGroup) => {
      if (modificationGroup.level === levelToModify) {
        const newModifications = modificationGroup.modifications.filter(
          (modification, index) => index !== modificationPosition,
        );
        return { ...modificationGroup, modifications: newModifications };
      }
      return modificationGroup;
    });

    onChange(newModificationGroups);
  };

  const addFirstModificationGroup = () => {
    const newLevel: ModificationGroupDTO = {
      level: 1,
      basis: 0,
      modifications: [{ type: 'ALLOWANCE', reasonCode: 'DISCOUNT' }],
    };
    onChange([newLevel]);
  };

  const updateLevelModification = (levelToModify: number, modificationIndex: number, modification: ModificationDTO) => {
    const newModificationGroups = (modificationGroups ?? []).map((modificationGroup) => {
      if (modificationGroup.level === levelToModify) {
        const newModifications = modificationGroup.modifications.map((modificationGroupModification, index) => {
          if (index === modificationIndex) {
            return modification;
          }
          return modificationGroupModification;
        });
        return { ...modificationGroup, modifications: newModifications };
      }
      return modificationGroup;
    });

    onChange(newModificationGroups);
  };

  return (
    <Card>
      <Card.Body className="border-t-0">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t('webedi:modifications.modificationsTitle')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">{t('webedi:modifications.modificationSubTitle')}</p>
        </div>

        <ModificationLevels
          modificationGroups={modificationGroups}
          removeModification={removeModification}
          updateLevelModification={updateLevelModification}
          disabled={disabled}
        />

        <Button
          variant="secondary"
          className={'mt-5 p-2'}
          onClick={() => (modificationGroups?.length ? addModification(1) : addFirstModificationGroup())}
          disabled={disabled}
          LeftIcon={PlusIcon}
          analyticsId="price-configs:modifications-add"
        >
          {t('webedi:modifications.add')}
        </Button>
      </Card.Body>
    </Card>
  );
}
