import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useTranslation } from 'react-i18next';
import { MessageV2DTO, RelationshipStatus, RelationshipV2 } from 'support/types';

export const DocumentEnvironmentBadge = ({
  flow,
  relationshipStatus,
}: {
  flow?: MessageV2DTO['flow'];
  relationshipStatus?: RelationshipV2['status'];
}) => {
  const { t } = useTranslation();
  const isTestDocument =
    (flow && flow === 'TEST') ||
    (relationshipStatus &&
      ![RelationshipStatus.LIVE, RelationshipStatus.ACTIVE].includes(RelationshipStatus[relationshipStatus]));
  return (
    isTestDocument && (
      <Badge color={BadgeColor.blue} tooltip={t('common:test-document.badge.tooltip')}>
        {t('common:test-document.badge.content')}
      </Badge>
    )
  );
};
