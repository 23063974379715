import { Link } from 'react-router-dom';
import { classNames } from 'support/helpers/generic/generic';

type DashboardCardProps = {
  value: number;
  onClick?: () => void;
  title: string;
  status?: 'error' | 'disabled' | 'normal';
  testId?: string;
  to?: string;
};
export const DashboardCard = ({ value, onClick, title, status = 'normal', testId, to }: DashboardCardProps) => {
  let statusClassesValue = '';
  let statusClassesTitle = '';

  switch (status) {
    case 'error':
      statusClassesValue = 'text-red-500';
      statusClassesTitle = 'text-red-500';
      break;
    case 'disabled':
      statusClassesValue = 'text-gray-400';
      statusClassesTitle = 'text-gray-400';
      break;
    default:
      statusClassesValue = 'text-gray-900';
      statusClassesTitle = 'text-gray-700';
      break;
  }

  const Component = to ? Link : 'div';

  return (
    <Component
      to={to!}
      onClick={onClick}
      className={'flex flex-col gap-2 w-max group hover:cursor-pointer'}
      data-testid={testId}
    >
      <span className={classNames('text-3xl font-extrabold group-hover:text-procuros-green-500', statusClassesValue)}>
        {value}
      </span>
      <span
        className={classNames(
          'text-base group-hover:text-procuros-green-500 group-hover:underline',
          statusClassesTitle,
        )}
      >
        {title}
      </span>
    </Component>
  );
};
