import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Popup } from 'components/Display/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { IntegrationConnectivityTestDTO } from 'support/types/dtos';

type IntegrationTestStepDetailsPopupProps = {
  item: IntegrationConnectivityTestDTO | undefined;
  open: boolean;
  onClose: () => void;
};

export const IntegrationTestStepDetailsPopup = ({ item, open, onClose }: IntegrationTestStepDetailsPopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={
        <div className="flex gap-2">
          {t(`integrations:new.test.${item?.type.toLowerCase()}.title`)}
          {/*
            t('integrations:connectivityTest.status.SUCCESSFUL')
            t('integrations:connectivityTest.status.IN_PROGRESS')
            t('integrations:connectivityTest.status.FAILED') 
          */}
          <Badge color={getBadgeColor(item?.status)}>{t(`integrations:connectivityTest.status.${item?.status}`)}</Badge>
        </div>
      }
      subtitle={item ? formatDayAndTime(item.createdAt) : undefined}
    >
      {item?.errors
        ? Object.entries(item.errors as Record<string, string[]>).map(([key, error]) => (
            <p key={key} className="text-sm text-gray-700">
              {error.join(' ')}
            </p>
          ))
        : null}

      {item?.context?.errors
        ? item.context.errors.map(({ code, message }) => (
            <p key={code} className="text-sm text-gray-700">
              {message}
            </p>
          ))
        : null}
    </Popup>
  );
};

const getBadgeColor = (status: IntegrationConnectivityTestDTO['status'] | undefined) => {
  switch (status) {
    case 'SUCCESSFUL':
      return BadgeColor.green;
    case 'IN_PROGRESS':
      return BadgeColor.yellow;
    case 'FAILED':
      return BadgeColor.red;
    default:
      return BadgeColor.gray;
  }
};
