import { Filter } from 'components/ListTable/types';
import { queryClient } from 'services/http/http';
import { createInfiniteQuery, createMutation, createQuery } from 'react-query-kit';
import { buildFilterUrl } from 'support/helpers/urls/urls';
import { PaginationSettings } from 'support/helpers/pagination/pagination';
import { DocumentType, InviteMemberDTO, PaginationDTO, PartnerDTO, UpdatePartnerDTO } from 'support/types';
import portalSchema from 'support/types/schema-portal';
import { createData, fetchData, updateData } from '../../http/http';

export interface PartnersListDTO {
  pagination: PaginationSettings;
  partners: PartnerDTO[];
}

export type PartnersResponseDTO = PaginationDTO<PartnerDTO>;

export interface IncomingOutgoingResponseDTO {
  items: PartnerDTO[];
}

export type GetCurrentPartnerResponse =
  portalSchema.paths['/v1/partner/current']['get']['responses']['200']['content']['application/json'];

type GetPartnerResponse =
  portalSchema.paths['/v1/partner/{id}']['get']['responses']['200']['content']['application/json'];

type UpdatePartnerResponse =
  portalSchema.paths['/v1/partner/{id}']['put']['responses']['204']['content']['application/json'];

type GetOutgoingPartnersResponse =
  portalSchema.paths['/v1/partners/outgoing']['get']['responses']['200']['content']['application/json'];

export type OutgoingPartners = GetOutgoingPartnersResponse['items'];

type GetTeamMembersResponse =
  portalSchema.paths['/v1/partners/{partnerId}/members']['get']['responses']['200']['content']['application/json'];

type InviteTeamMembersResponse =
  portalSchema.paths['/v1/partners/{partnerId}/members']['post']['responses']['200']['content']['application/json'];

export type CreatePartnerRequest =
  portalSchema.paths['/v1/partners']['post']['requestBody']['content']['application/json'];

type CreatePartnerResponse =
  portalSchema.paths['/v1/partners']['post']['responses']['201']['content']['application/json'];

type GetTradeRequestLastMessageResponse =
  portalSchema.paths['/v1/partners/trade-requests/last-message']['get']['responses']['200']['content']['application/json'];

export const useCurrentPartner = createQuery<GetCurrentPartnerResponse, any, Error>({
  primaryKey: '/currentPartner',
  queryFn: async () => {
    const { data } = await fetchData<GetCurrentPartnerResponse>('/v1/partner/current');
    return data;
  },
  staleTime: Infinity,
});

export const usePartner = createQuery<GetPartnerResponse, { id?: string }, Error>({
  primaryKey: '/partners',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data } = await fetchData<GetPartnerResponse>(`/v1/partner/${variables.id}`);

    return data;
  },
  enabled: (data, variables) => !!variables.id,
});

export const usePartnersSuggestion = createQuery<PartnersResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/partners/suggestion',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query, perPage: '5' });
    const { data } = await fetchData<PartnersResponseDTO>('/v1/partners/search' + urlParams);

    return data;
  },
});
export const useInfinitePartners = createInfiniteQuery<PartnersResponseDTO, { query?: Filter }, Error>({
  primaryKey: '/partners',
  queryFn: async ({ queryKey: [_, variables], pageParam }) => {
    const urlParams = buildFilterUrl({ filterValues: [], ...variables.query, cursor: pageParam });
    const { data } = await fetchData<PartnersResponseDTO>('/v1/partners/search' + urlParams);

    return data;
  },
  getNextPageParam: (lastPage) => lastPage.nextCursor,
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.previousCursor,
});

const fetchConnectedPartnersPage = async (cursor: string | null): Promise<PartnersResponseDTO> => {
  const urlParams = buildFilterUrl({
    filterValues: [],
    cursor,
    perPage: '100',
  });
  const { data } = await fetchData<PartnersResponseDTO>('/v1/partners' + urlParams);

  return data;
};

export const useConnectedPartners = createQuery<PartnerDTO[], any, Error>({
  primaryKey: '/partners/connected',
  queryFn: async () => {
    let hasMorePages = true;
    let nextPageCursor = null;
    let currentPage = 1;
    let data: PartnerDTO[] = [];

    // 50 pages limit because I'm just super scared to accidentally DDOS ourselves
    while (hasMorePages && currentPage <= 50) {
      const { items, hasMore, ...rest } = await fetchConnectedPartnersPage(nextPageCursor);

      hasMorePages = hasMore;
      nextPageCursor = rest.nextCursor;
      currentPage++;

      data = [...data, ...items];
    }

    return data;
  },
});

export const useOutgoingPartners = createQuery<OutgoingPartners, { transactionType?: DocumentType }, Error>({
  primaryKey: '/partners/outgoing',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data } = await fetchData<GetOutgoingPartnersResponse>(
      `/v1/partners/outgoing${variables.transactionType ? `?type=${variables.transactionType}` : ''}`,
    );

    return data.items;
  },
});

export const useTeamMembers = createQuery<GetTeamMembersResponse['members'], { partnerId?: string }, Error>({
  primaryKey: '/partners/teamMembers',
  queryFn: async ({ queryKey: [_, variables] }) => {
    const { data } = await fetchData<GetTeamMembersResponse>(`/v1/partners/${variables.partnerId}/members`);

    return data.members;
  },
  enabled: (data, variables) => !!variables.partnerId,
});

type InviteTeamMemberVariables = {
  partnerId: string;
  newMember: InviteMemberDTO;
};

export const useInviteTeamMember = createMutation<InviteTeamMembersResponse, InviteTeamMemberVariables, Error>({
  mutationFn: async ({ partnerId, newMember }) => {
    const { data } = await createData<InviteTeamMembersResponse>(`/v1/partners/${partnerId}/members`, newMember);

    return data;
  },
  onSuccess: () => queryClient.invalidateQueries(useTeamMembers.getKey()),
});

type UpdatePartnerPayload = {
  currentPartnerId: string;
  updatedPartner: UpdatePartnerDTO;
};

export const useUpdateCurrentPartner = createMutation<void, UpdatePartnerPayload, Error>({
  mutationFn: async ({ currentPartnerId, updatedPartner }) => {
    await updateData<UpdatePartnerResponse>(`/v1/partners/${currentPartnerId}`, { ...updatedPartner });
  },
  onSuccess: () => queryClient.invalidateQueries(useCurrentPartner.getKey()),
});

export const useCreatePartner = createMutation<CreatePartnerResponse, CreatePartnerRequest, Error>({
  mutationFn: async (payload) => {
    const { data } = await createData<CreatePartnerResponse>('/v1/partners', payload);
    return data;
  },
  onSuccess: () => queryClient.invalidateQueries(useInfinitePartners.getKey()),
});

export const useTradeRequestLastMessage = createQuery<
  GetTradeRequestLastMessageResponse['data']['message'],
  { tradeRequestId: string },
  Error
>({
  primaryKey: '/partners/trade-requests/last-message',
  queryFn: async () => {
    const { data } = await fetchData<GetTradeRequestLastMessageResponse>('/v1/partners/trade-requests/last-message');
    return data?.data?.message;
  },
});
