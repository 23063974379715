import { ApplicationLogoSmall } from 'components/Logo/ApplicationLogoSmall/ApplicationLogoSmall';

export function NotFoundPage() {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-gray-100">
      <ApplicationLogoSmall className="w-40 h-40 rounded-full bg-black shadow-2xl" />
      <div className="text-4xl mt-5">404 | Not Found</div>
    </div>
  );
}
