import { CellContext, createColumnHelper, RowData } from '@tanstack/react-table';
import { ColumnTypesInternal } from '../Types';
import { HeaderCell } from '../cells/HeaderCell';
import { Translate } from 'components/Translate/Translate';

export const indexColumn = <TData extends RowData>(columnHelper: ReturnType<typeof createColumnHelper<TData>>) =>
  columnHelper.display({
    id: ColumnTypesInternal.procuros_index,
    header: (props) => (
      <HeaderCell
        {...props}
        tooltip={<Translate i18nKey="webedi:transport_units.table.header.index.description" />}
        mode={props.table.options.meta?.mode}
      >
        <Translate i18nKey="webedi:transport_units.table.header.index.label" />
      </HeaderCell>
    ),
    meta: {
      type: ColumnTypesInternal.procuros_index,
      readOnly: true,
    },
    cell: IndexCell,
  });

const IndexCell = <TData, TValue>(props: CellContext<TData, TValue>) => {
  return (
    <div className="w-full px-3 text-end">
      {new Intl.NumberFormat(navigator.language, { minimumIntegerDigits: 3 }).format(props.row.index + 1)}
    </div>
  );
};
