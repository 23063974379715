import { WebEDISection } from 'components/WebEDI/WebEDISection/WebEDISection';
import { useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { MessageInput } from 'components/Form/MessageInput/MessageInput';
import { useWebEDIErrors, useWebEDIFieldError } from 'services/webediErrors/webediErrors';
import { DataDisplay } from 'components/Form/DataDisplay/DataDisplay';
import { useOptionalConfigurations } from 'components/DataTable/cells/cellHelpers';
import { MessageDropdown } from 'components/Form/MessageDropdown/MessageDropdown';
import { ui } from '@procuros/datachecks';
import { isEnumerableField } from '../helpers';
import { DisplayModes } from 'components/DataTable/Types';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';
import { memo, useMemo, useState } from 'react';

export const DocumentHeaderInfo = memo(({ config, mode }: { config: ui.InfoSection; mode: DisplayModes }) => {
  if (!config.fields?.length) return null;

  return (
    <WebEDISection header={{ title: config.label, description: config.description, spacing: 'medium' }}>
      <div className="space-y-4">
        <DocumentSectionError path="info" />
        <DataTableProvider name="info.0">
          <div className={classNames('grid gap-4 grid-cols-4')}>
            {config.fields.map((field) => (
              <Field key={field.path} mode={mode} field={field} />
            ))}
          </div>
        </DataTableProvider>
      </div>
    </WebEDISection>
  );
});

DocumentHeaderInfo.displayName = 'DocumentHeaderInfo';

const Field = ({ mode, field }: { mode: DisplayModes; field: ui.Field }) => {
  const fieldId = `info.0.${field.path}`;
  const value = useWatch({ name: fieldId });
  const [initialValue] = useState(value);
  const { register, setValue } = useDataTableContext();
  const { error } = useWebEDIFieldError(fieldId);
  const { numberOfErrors, setCurrentError } = useWebEDIErrors();

  const { prefix, suffix, formattedValue, isNumeric } = useOptionalConfigurations({
    meta: {
      optionalConfig: 'type_config' in field ? field.type_config : undefined,
      type: field.type,
    },
    value,
    mode,
  });

  const readOnly = useMemo(() => {
    return Boolean(field.is_readonly) && initialValue !== '' && mode !== DisplayModes.edit;
  }, [field.is_readonly, initialValue, mode]);

  if (mode === DisplayModes.view) {
    return (
      <DataDisplay
        value={formattedValue}
        label={field.label}
        totalNumberOfErrors={numberOfErrors}
        error={error}
        fieldId={fieldId}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
      />
    );
  }
  if (isEnumerableField(field) && field.type === ui.FieldTypes.select) {
    return (
      <MessageDropdown
        onChange={(value) => setValue(fieldId, value)}
        value={value}
        field={field}
        error={error}
        totalNumberOfErrors={numberOfErrors}
        onShowFieldErrorModal={() => error && setCurrentError(error)}
        required={field.modality === 'MANDATORY'}
        disabled={readOnly}
      />
    );
  }

  const type = typeToInputType(field.type);
  return (
    <MessageInput
      type={type}
      label={field.label}
      error={error}
      leftAddon={prefix}
      rightAddon={suffix}
      totalNumberOfErrors={numberOfErrors}
      {...register(fieldId, { valueAsNumber: isNumeric })}
      value={type === 'date' ? formattedValue : value}
      onShowFieldErrorModal={() => error && setCurrentError(error)}
      required={field.modality === 'MANDATORY'}
      disabled={readOnly}
    />
  );
};

const typeToInputType = (type: ui.FieldTypes): React.HTMLInputTypeAttribute => {
  switch (type) {
    case ui.FieldTypes.number:
    case ui.FieldTypes.monetary:
      return 'number';
    case ui.FieldTypes.date:
      return 'date';
    case ui.FieldTypes.date_time:
      return 'datetime-local';
    case ui.FieldTypes.boolean:
      return 'checkbox';
    default:
      return 'text';
  }
};
