import { Table as TanstackTable } from '@tanstack/table-core/build/lib/types';
import { Column } from '@tanstack/react-table';
import debounce from 'lodash/debounce';

export const setColumnSizes = <TData>(
  thElem: HTMLTableCellElement | null,
  table: TanstackTable<TData>,
  column: Column<TData>,
) => {
  if (!thElem || table.getState().columnSizing[column.id] === thElem.getBoundingClientRect().width) return;

  const updateSizes = debounce(
    () => {
      table.setColumnSizing((prevSizes) => ({
        ...prevSizes,
        [column.id]: thElem.getBoundingClientRect().width,
      }));
    },
    1000,
    { trailing: true, leading: true },
  );

  updateSizes();

  window.onresize = () => {
    updateSizes();
  };
};
