import { Button } from 'components/Form/Button/Button';
import { Page } from 'components/Page/Page';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationsRoutes, IntegrationDTO, Connectors } from 'support/types';
import { ConnectorSelectorActions, connectorsConfig, ConnectorConfigGroup, ConnectorConfigItem } from './config';
import { Section } from 'components/Display/Section/Section';
import { ConnectorSelectorItem } from './components/ConnectorSelectorItem/ConnectorSelectorItem';
import { ContactModal } from './components/ConnectorModal/ContactModal';
import { useInfiniteIntegrations } from 'services/repositories/integrations/integrations';
import { SearchFilter } from 'components/ListTable/Filters/SearchFilter';
import { TooManyIntegrationsModal } from './components/TooManyIntegrationsModal/TooManyIntegrationsModal';
import { useDebounce } from 'hooks/useDebounce';
import { nonNullable } from 'support/helpers/generic/generic';
import { routeToNewIntegrationPage } from 'support/helpers/navigation/navigation';

export const ConnectorSelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showTooManyIntegrationsModal, setShowTooManyIntegrationsModal] = useState({
    show: false,
    connector: '',
  });
  const [showContactModal, setShowContactModal] = useState<{ show: boolean; connector: Connectors | undefined }>({
    show: false,
    connector: undefined,
  });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);

  const { data: integrations, isLoading: integrationsLoading } = useInfiniteIntegrations({
    variables: {
      query: {
        perPage: '100',
      },
    },
  });

  const generateConnectorItem = (item: ConnectorConfigItem) => {
    let action;
    if (item.action === ConnectorSelectorActions.SHOW_CONTACT_MODAL) {
      action = () => setShowContactModal({ show: true, connector: item.key });
    } else if (item.action === ConnectorSelectorActions.SHOW_CONTACT_PAGE) {
      action = () => navigate(routeToNewIntegrationPage(item.key));
    }

    let canCreateMoreIntegrations = true;
    if (item.limit) {
      const numberOfConnectorIntegrations = getNumberOfIntegrationsOfConnector(item, integrations?.pages);
      canCreateMoreIntegrations = integrationsLoading ? false : numberOfConnectorIntegrations < item.limit;
    }

    return (
      <ConnectorSelectorItem
        key={item.key}
        name={item.name}
        description={item.description}
        badge={item.badge}
        path={canCreateMoreIntegrations ? item.path : undefined}
        onClick={
          canCreateMoreIntegrations
            ? action
            : () => setShowTooManyIntegrationsModal({ connector: item.name, show: true })
        }
        icon={item.icon}
      />
    );
  };

  return (
    <>
      <Page>
        <Page.Head
          title={t('integrations:connectorSelector.title')}
          description={t('integrations:connectorSelector.description')}
          rightContent={
            <div>
              <CancelButton />
            </div>
          }
        >
          <SearchFilter
            currentValue={search}
            onChange={setSearch}
            filterKey={''}
            placeholder={t('integrations:connectorSelector.searchPlaceholder')}
          />
        </Page.Head>

        <Page.Section>
          {connectorsConfig(t)
            .map(filterBySearch(debouncedSearch))
            .filter(nonNullable)
            .map((group: ConnectorConfigGroup) => {
              return (
                <Section.Vertical key={group.name} header={<Section.Title>{group.name}</Section.Title>} cols={3}>
                  {group.items.map((item) => {
                    return generateConnectorItem(item);
                  })}
                </Section.Vertical>
              );
            })}
          <Section.Vertical header={<Section.Title>{t('integrations:group.other')}</Section.Title>}>
            <Button
              variant="secondary"
              size="extra-large"
              fullWidth
              onClick={() => setShowContactModal({ show: true, connector: Connectors.NONE })}
              analyticsId="integrations:contact"
            >
              {t('integrations:item.contact')}
            </Button>
          </Section.Vertical>
          <div className="!mt-16">
            <CancelButton />
          </div>
        </Page.Section>
      </Page>
      <ContactModal
        connector={showContactModal.connector}
        open={showContactModal.show}
        onCancel={() => setShowContactModal((state) => ({ show: false, connector: state.connector }))}
        onSuccess={() => {
          navigate(routeToNewIntegrationPage(showContactModal.connector));
        }}
      />
      <TooManyIntegrationsModal
        connector={showTooManyIntegrationsModal.connector}
        open={showTooManyIntegrationsModal.show}
        onClose={() => setShowTooManyIntegrationsModal((state) => ({ show: false, connector: state.connector }))}
      />
    </>
  );
};

const CancelButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Button
      variant="secondary"
      onClick={() => navigate(IntegrationsRoutes.root)}
      analyticsId="integrations:choose-connector-cancel"
    >
      {t('common:cancel')}
    </Button>
  );
};

const filterBySearch =
  (searchTerm: string) =>
  (group: ConnectorConfigGroup): ConnectorConfigGroup | undefined => {
    const nonEmptyGroup = group.items.some((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    if (nonEmptyGroup) {
      return {
        ...group,
        items: group.items.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase())),
      };
    }
  };

const getNumberOfIntegrationsOfConnector = (
  item: { key: string },
  infiniteIntegrations: Array<{ items: Array<IntegrationDTO> }> | undefined,
) => {
  return (
    infiniteIntegrations
      ?.reduce((acc, page) => {
        return acc.concat(page.items);
      }, [] as Array<IntegrationDTO>)
      ?.filter((integration) => {
        return item.key.toLocaleLowerCase() === integration.connector.toLowerCase();
      })?.length || 0
  );
};
