import { PartnerV2 } from 'support/types';
import { useTranslation } from 'react-i18next';
import { useResendEmailInvitation } from 'services/repositories/relationships/relationships';
import { NotificationType, addNotification } from 'stores/notifications/notifications';
import { useState } from 'react';
import { Button } from 'components/Form/Button/Button';
import { Translate } from 'components/Translate/Translate';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DifferentEmailForm } from './components/DifferentEmailForm';
import { ResendEmailFormType } from '../types';
import { AllowedLanguages } from 'i18n';
import { formatDayAndTime, isDateInLastMinute } from 'support/helpers/dateTime/dateTime';

export const ResendInvitationEmailAlert = ({ partner }: { partner: PartnerV2 }) => {
  const { t } = useTranslation();
  const { mutate } = useResendEmailInvitation();
  const [wasSentRecently, setSentRecently] = useState(false);
  const [showDifferentEmailOptions, setShowDifferentEmailOptions] = useState(false);
  const lastSentEmail = getLastEmailSent(partner);

  const formMethods = useForm<ResendEmailFormType>({
    defaultValues: {
      email: '',
      language: 'de',
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email()
          .label(t('connections:resendInvitationEmailAlert.email.label'))
          .required(t('common:form.required', { field: t('connections:resendInvitationEmailAlert.email.label') })),
        name: yup
          .string()
          .required(
            t('common:form.required', { field: t('connections:resendInvitationEmailAlert.contactName.label') }),
          ),
        language: yup.string().oneOf(['en', 'de']).required(),
      }),
    ),
  });

  const toggleDifferentEmailOptions = () => {
    setShowDifferentEmailOptions((showDifferentEmailOptions) => !showDifferentEmailOptions);
    formMethods.reset();
  };

  const resendEmail = (args?: { email: string; name: string; language: AllowedLanguages }) => {
    const { email, name, language } = args || {};
    mutate(
      { partnerId: partner.id, email, name, language },
      {
        onSuccess: () => {
          setSentRecently(true);
          setShowDifferentEmailOptions(false);
          addNotification(t('connections:resendInvitationEmailAlert.success'));
        },
        onError: () => {
          addNotification(t('connections:resendInvitationEmailAlert.error'), NotificationType.error);
        },
      },
    );
  };

  const SelectEmailCtas = () => {
    return (
      <>
        <Button
          variant="secondary"
          onClick={() => resendEmail()}
          disabled={(lastSentEmail?.created_at && isDateInLastMinute(lastSentEmail.created_at)) || wasSentRecently}
          analyticsId="testing-flow:invite_resend"
        >
          {t('connections:resendInvitationEmailAlert.sameEmailCta')}
        </Button>

        <Button
          variant="secondary"
          onClick={toggleDifferentEmailOptions}
          analyticsId="testing-flow:invite_email_change"
        >
          {t('connections:resendInvitationEmailAlert.differentEmailCta')}
        </Button>
      </>
    );
  };

  return lastSentEmail ? (
    <div className="space-y-4 divide-y divide-gray-200 bg-gray-50 p-4">
      <div className="flex flex-col gap-2">
        <h3 className="text-base font-bold text-gray-700">{t('connections:resendInvitationEmailAlert.title')}</h3>
        <p className="text-sm text-gray-700">
          {wasSentRecently ? (
            <Translate
              i18nKey="connections:resendInvitationEmailAlert.sentRecently"
              values={{ email: lastSentEmail.destination_contact }}
            />
          ) : (
            <Translate
              i18nKey="connections:resendInvitationEmailAlert.content"
              values={{ date: formatDayAndTime(lastSentEmail.created_at), email: lastSentEmail.destination_contact }}
            />
          )}
        </p>
      </div>
      <div className="flex gap-2 pt-4">
        {showDifferentEmailOptions ? (
          <FormProvider {...formMethods}>
            <DifferentEmailForm onSubmit={resendEmail} onCancel={toggleDifferentEmailOptions} />
          </FormProvider>
        ) : (
          <SelectEmailCtas />
        )}
      </div>
    </div>
  ) : null;
};

const getLastEmailSent = (partner: PartnerV2) =>
  [...(partner.receiver_relationships || []), ...(partner.sender_relationships || [])].sort(
    (relationshipA, relationshipB) => {
      if (
        relationshipA?.trade_request?.last_email_sent?.created_at &&
        relationshipB?.trade_request?.last_email_sent?.created_at
      ) {
        return (
          new Date(relationshipA.trade_request.last_email_sent?.created_at).getTime() -
          new Date(relationshipB.trade_request.last_email_sent?.created_at).getTime()
        );
      } else if (relationshipA?.trade_request?.last_email_sent?.created_at) {
        return -1;
      }
      return 1;
    },
  )[0]?.trade_request?.last_email_sent;
