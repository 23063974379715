import { Fragment, ReactNode } from 'react';
import { Transition, TransitionChild } from '@headlessui/react';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { classNames } from 'support/helpers/generic/generic';
import { Dialog } from 'components/Dialog/Dialog';
import { Button } from 'components/Form/Button/Button';
import { useTranslation } from 'react-i18next';

export function SlideOver({
  open,
  setOpen,
  headerImage,
  title,
  subtitle,
  children,
  footer,
  actionConfig,
  size,
  onBack,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  headerImage?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  actionConfig?: Parameters<typeof Button>[0];
  size?: 'base' | 'large';
  onBack?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className={classNames('w-screen', size === 'large' ? 'max-w-3xl' : 'max-w-md')}>
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div
                      className={classNames(
                        'py-4 px-4 sm:px-6 bg-gray-50 flex items-center w-full',
                        onBack ? 'justify-between' : 'justify-end',
                      )}
                    >
                      <Button
                        className={onBack ? undefined : 'invisible'}
                        variant="minimal"
                        size="extra-small"
                        LeftIcon={ArrowLeftIcon}
                        onClick={() => onBack?.()}
                        analyticsId="slide_over_back_button"
                      >
                        {t('common:back')}
                      </Button>

                      <div className="flex items-center">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-400 focus-within:ring-2 focus-within:ring-procuros-green-400 hover:text-gray-500 focus-visible:ring-2 focus-visible:ring-procuros-green-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="size-6 focus:outline-0" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll pb-6">
                      <div className="relative flex-1">
                        {title || subtitle ? (
                          <div className="flex items-center justify-between gap-2 border-b border-gray-200 px-4 py-6 sm:px-6">
                            <div className="flex items-center gap-3">
                              {headerImage}
                              <div>
                                {title ? (
                                  <Dialog.Title className="shrink-0 text-base font-semibold leading-6 text-gray-900">
                                    {title}
                                  </Dialog.Title>
                                ) : null}
                                {subtitle ? (
                                  <Dialog.Description className="text-sm text-gray-500">{subtitle}</Dialog.Description>
                                ) : null}
                              </div>
                            </div>
                            {actionConfig ? <Button variant="secondary" {...actionConfig} /> : null}
                          </div>
                        ) : null}
                        <div className="px-4 py-6 sm:px-6">{children}</div>
                      </div>
                    </div>
                    {footer && <div className="flex shrink-0 justify-start gap-x-4 p-4">{footer}</div>}
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export const SlideOverTitleSkeleton = () => {
  return <div className="block h-6 w-60 animate-pulse bg-gray-200 leading-relaxed"></div>;
};

export const SlideOverSubtitleSkeleton = () => {
  return <span className="mt-2 block h-5 w-64 animate-pulse bg-gray-100 leading-relaxed"></span>;
};

export const SlideOverContentSkeleton = () => {
  return (
    <div className="flex w-full flex-wrap">
      <div className="mb-3 block h-10 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
      <div className="mb-3 block h-10 w-[95%] animate-pulse bg-gray-100 leading-relaxed"></div>
      <div className="mb-3 block h-10 w-[95%] animate-pulse bg-gray-300 leading-relaxed"></div>
      <div className="mb-3 block h-10 w-[95%] animate-pulse bg-gray-200 leading-relaxed"></div>
    </div>
  );
};
