import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { Section } from 'components/Display/Section/Section';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntegrationProcessDeploy } from 'services/repositories/integrationProcesses/integrationProcesses';
import { useNavigate, useParams } from 'react-router-dom';
import { IntegrationProcessDTO, IntegrationProcessTestDTO, IntegrationsRoutes } from 'support/types';
import { IntegrationProcessTestStepDetailsPopup } from './components/IntegrationProcessTestDetailsPopup/IntegrationProcessTestStepDetailsPopup';
import { EDIFACTIntegrationProcessTestSideInformation } from './FileFormats/EDIFACT/EDIFACTIntegrationProcessTestSideInformation/EDIFACTIntegrationProcessTestSideInformation';
import { EDIFACTIntegrationProcessTest } from './FileFormats/EDIFACT/EDIFACTIntegrationProcessTest/EDIFACTIntegrationProcessTest';
import { useNotificationStore } from 'stores/notifications/notifications';
import { ValidationErrors } from 'components/Form/ValidationErrors/ValidationErrors';
import { processSubmissionErrors } from 'support/helpers/errors/errors';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { Button } from 'components/Form/Button/Button';

type IntegrationProcessTestStepProps = {
  onBack: () => void;
  process: IntegrationProcessDTO;
};

export const IntegrationProcessTestStep = ({ onBack, process }: IntegrationProcessTestStepProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const { id: integrationId, processId } = useParams();
  const [showItemDetails, setShowItemDetails] = useState<{
    open: boolean;
    item: IntegrationProcessTestDTO | undefined;
  }>({
    open: false,
    item: undefined,
  });
  const [deployErrors, setDeployErrors] = useState<Array<string>>([]);
  const { mutateAsync, isLoading } = useIntegrationProcessDeploy();

  const deployIntegrationProcess = async () => {
    if (!integrationId || !processId) return;
    setDeployErrors([]);
    try {
      await mutateAsync({ integrationId, processId });
      navigate(routeToPage(IntegrationsRoutes.view, { id: integrationId }));
      addNotification(t('integrations:processes.new.test.notifications.deploy.success'));
    } catch (error) {
      processSubmissionErrors({
        error: error as Error,
        setNonInputErrors: setDeployErrors,
        setInputError: undefined,
        t,
        defaultData: undefined,
      });
    }
  };

  if (!processId || !integrationId) return null;

  /*
    t('integrations:processes.new.test.INBOUND.title')
    t('integrations:processes.new.test.OUTBOUND.title')
    t('integrations:processes.new.test.INBOUND.description')
    t('integrations:processes.new.test.OUTBOUND.description')
  */
  return (
    <>
      <Button onClick={onBack} LeftIcon={ChevronLeftIcon} analyticsId="integrations:process-test-back">
        {t(`integrations:new.test.actions.back`)}
      </Button>
      <ValidationErrors errors={deployErrors} />
      <div className="grid grid-cols-2 gap-12">
        <Section.Vertical
          cols={1}
          header={<Section.Title>{t(`integrations:processes.new.test.${process.direction}.title`)}</Section.Title>}
        >
          <p className="text-sm font-medium text-gray-700">
            {t(`integrations:processes.new.test.${process.direction}.description`)}
          </p>
          <EDIFACTIntegrationProcessTest
            onShowProcessTestDetails={(item) => setShowItemDetails({ open: true, item })}
            process={process}
            integrationId={integrationId}
          />
          <Button onClick={deployIntegrationProcess} loading={isLoading} analyticsId="integrations:process-deploy">
            {t(`integrations:processes.new.test.actions.deploy`)}
          </Button>
        </Section.Vertical>
        <EDIFACTIntegrationProcessTestSideInformation process={process} />
      </div>
      <IntegrationProcessTestStepDetailsPopup
        open={showItemDetails.open}
        onClose={() => setShowItemDetails((currentState) => ({ ...currentState, open: false }))}
        item={showItemDetails.item}
      />
    </>
  );
};
