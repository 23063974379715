import { useCallback, useEffect, useRef, useState } from 'react';
import MonacoEditor, { monaco } from 'react-monaco-editor';
import { UiMode } from './ProcessTransformation';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { ValidationResult } from 'support/helpers/schemas/schemaValidator';
import { ArrowDownIcon, ArrowUpIcon, Bars3BottomLeftIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { CreateSchemaModal } from './CreateSchemaModal';
import { IntegrationProcessDTO, TemplateDTO } from 'support/types';
import { LocalSchemaDTO, PayloadObject } from 'stores/transformations/processTransformation';
import { SegmentedControl } from 'components/SegmentedControl/SegmentedControl';
import { denormalizeNormalizedPayload, normalizeRawPayload } from 'services/normalization/normalization';
import { DataType, formatRawPayload } from './helpers';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { parseJson, classNames } from 'support/helpers/generic/generic';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Button } from 'components/Form/Button/Button';

type EditorType = 'SCHEMA' | 'PAYLOAD';
type DisplayType = 'VISUAL_CANONICAL' | 'JSON' | 'XML' | 'RAW';

type TransformationInputEditorProps = {
  className?: string;
  processType?: IntegrationProcessDTO['type'];
  uiMode: UiMode;
  inputDataType: DataType;
  outputDataType: DataType;
  normalizerConfig?: Record<string, unknown>;
  hidden?: boolean;
  selectedPayload?: PayloadObject;
  existingPayloads: Array<PayloadObject>;
  onPayloadSelect: (inputId: string) => void;
  onPayloadChange: (inputId: string, payload: PayloadObject) => void;
  onPayloadCreate: (payload: PayloadObject) => void;
  existingSchemas: Array<LocalSchemaDTO>;
  schemaTemplates: Array<TemplateDTO>;
  activeSchema?: LocalSchemaDTO;
  selectedSchema?: LocalSchemaDTO;
  onSchemaSelect: (schemaId: string) => void;
  onSchemaChange: (schemaId: string, schema: LocalSchemaDTO) => void;
  onSchemaCreate: (schema: LocalSchemaDTO) => void;
};

type EditorConfig = {
  displayTypeOptions: Array<DisplayType>;
  initialDisplayType: DisplayType;
  hasRawForm: boolean;
};

const editorConfigs: { [k in DataType]: EditorConfig } = {
  CANONICAL: {
    displayTypeOptions: ['JSON', 'VISUAL_CANONICAL'],
    initialDisplayType: 'JSON',
    hasRawForm: false,
  },
  JSON: {
    displayTypeOptions: ['JSON'],
    initialDisplayType: 'JSON',
    hasRawForm: false,
  },
  TRADACOMS: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
  FIXED: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
  PHBEST: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
  EDIFACT: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
  EDIFACT_V2: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
  XML: {
    displayTypeOptions: ['XML', 'JSON'],
    initialDisplayType: 'XML',
    hasRawForm: true,
  },
  CSV: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
  NONE: {
    displayTypeOptions: ['RAW', 'JSON'],
    initialDisplayType: 'RAW',
    hasRawForm: true,
  },
};

export const TransformationInputEditor = ({
  className,
  processType,
  uiMode,
  inputDataType,
  outputDataType,
  normalizerConfig,
  hidden,
  existingPayloads,
  selectedPayload,
  onPayloadSelect,
  onPayloadChange,
  onPayloadCreate,
  existingSchemas,
  schemaTemplates,
  activeSchema,
  selectedSchema,
  onSchemaSelect,
  onSchemaChange,
  onSchemaCreate,
}: TransformationInputEditorProps) => {
  const [currentSchemaErrorIndex, setCurrentSchemaErrorIndex] = useState<number>();
  const [createSchemaModalOpen, setCreateSchemaModalOpen] = useState<boolean>(false);
  const [displayType, setDisplayType] = useState<DisplayType>(editorConfigs[inputDataType].initialDisplayType);
  const [editorType, setEditorType] = useState<EditorType>('PAYLOAD');
  const dataEditorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
  const rawEditorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  // Change to payload and Json when uiMode switches to TEST
  useEffect(() => {
    setEditorType('PAYLOAD');
    setDisplayType('JSON');
  }, [uiMode]);

  const selectNextSchemaError = useCallback(() => {
    if (!selectedPayload?.outputSchemaValidationResult?.errors) {
      return;
    }
    if (typeof currentSchemaErrorIndex === 'undefined') {
      setCurrentSchemaErrorIndex(0);
      return;
    }
    if (currentSchemaErrorIndex >= selectedPayload.outputSchemaValidationResult.errors.length - 1) {
      setCurrentSchemaErrorIndex(0);
      return;
    }
    setCurrentSchemaErrorIndex(currentSchemaErrorIndex + 1);
  }, [selectedPayload?.outputSchemaValidationResult?.errors, currentSchemaErrorIndex]);

  const selectPreviousSchemaError = useCallback(() => {
    if (!selectedPayload?.outputSchemaValidationResult?.errors) {
      return;
    }
    if (typeof currentSchemaErrorIndex === 'undefined') {
      setCurrentSchemaErrorIndex(selectedPayload.outputSchemaValidationResult.errors.length - 1);
      return;
    }
    if (currentSchemaErrorIndex <= 0) {
      setCurrentSchemaErrorIndex(selectedPayload.outputSchemaValidationResult.errors.length - 1);
      return;
    }
    setCurrentSchemaErrorIndex(currentSchemaErrorIndex - 1);
  }, [selectedPayload?.outputSchemaValidationResult?.errors, currentSchemaErrorIndex]);

  const rawDataUpdated = useCallback(
    (raw: string) => {
      if (!selectedPayload) {
        return;
      }

      const updatedPayload = {
        ...selectedPayload,
        rawInputData: raw,
      };

      if (selectedPayload.inputType !== 'CANONICAL') {
        const transformationInputJson = parseJson(updatedPayload.transformationInput ?? '{}');
        if (transformationInputJson.success) {
          const inputNormalizationResult = normalizeRawPayload({
            rawPayload: raw,
            rawType: selectedPayload.inputType,
            normalizerConfig,
          });
          updatedPayload.transformationInput = JSON.stringify(
            {
              ...transformationInputJson.result,
              payloads: [
                inputNormalizationResult.success
                  ? inputNormalizationResult.normalizedPayload
                  : {
                      errorMessage: inputNormalizationResult.errorMessage,
                      errors: inputNormalizationResult.errors,
                    },
              ],
            },
            null,
            2,
          );
        }
      }
      onPayloadChange(selectedPayload.id, updatedPayload);
    },
    [onPayloadChange, selectedPayload, normalizerConfig],
  );

  useEffect(() => {
    if (!selectedPayload?.transformationInput && !!selectedPayload?.rawInputData) {
      rawDataUpdated(selectedPayload.rawInputData);
    }
  }, [selectedPayload, rawDataUpdated]);

  const markErrors = useCallback(
    (data: string, validationResult: ValidationResult) => {
      const model = dataEditorRef.current?.getModel();
      if (!dataEditorRef.current || !model || !validationResult) {
        return;
      }
      const jsonData = parseJson(data);
      if (!jsonData.success) {
        return;
      }
      // console.log(transformAjvErrorsToMonacoMarkers(model, validationResult.errors));
      // console.log('lineCount', model.getLineCount());
      // console.log('inputerrors', validationResult.errors);
      validationResult.valid
        ? monaco.editor.setModelMarkers(model, 'owner', [])
        : monaco.editor.setModelMarkers(model, 'owner', validationResult.errors);

      setCurrentSchemaErrorIndex(undefined);
    },
    [dataEditorRef],
  );

  const dataUpdated = useCallback(
    (data: string) => {
      if (!selectedPayload) {
        return;
      }

      const updatedPayload: PayloadObject = {
        ...selectedPayload,
        transformationInput: data,
      };

      if (selectedPayload.inputType !== 'CANONICAL') {
        const transformationInputJson = parseJson(data);
        if (transformationInputJson.success && transformationInputJson.result.payloads?.length > 0) {
          const inputDataDenormalizationResult = denormalizeNormalizedPayload({
            normalizedPayload: transformationInputJson.result.payloads[0],
            rawType: selectedPayload.inputType,
            normalizerConfig,
          });
          updatedPayload.rawInputData = inputDataDenormalizationResult.success
            ? formatRawPayload(inputDataDenormalizationResult.rawPayload ?? '')
            : inputDataDenormalizationResult.errorMessage;
        }
      }
      onPayloadChange(selectedPayload.id, updatedPayload);
    },
    [onPayloadChange, selectedPayload, normalizerConfig],
  );

  // validateDataAndMarkErrors when either selectedPayload or selectedSchema changes (debounced)
  useEffect(() => {
    if (!selectedPayload?.transformationInput || !selectedPayload?.inputSchemaValidationResult) {
      return;
    }

    markErrors(selectedPayload.transformationInput, selectedPayload.inputSchemaValidationResult);
  }, [selectedPayload?.transformationInput, selectedPayload?.inputSchemaValidationResult, markErrors]);

  const schemaUpdated = useCallback(
    (schema: string) => {
      if (!selectedSchema) {
        return;
      }
      onSchemaChange(selectedSchema.id, {
        ...selectedSchema,
        schema,
      });
    },
    [onSchemaChange, selectedSchema],
  );

  // reveal current schema error in editor on currentSchemaErrorIndex change
  useEffect(() => {
    if (
      typeof currentSchemaErrorIndex === 'undefined' ||
      !selectedPayload?.inputSchemaValidationResult ||
      !dataEditorRef.current
    ) {
      return;
    }
    const error = selectedPayload?.inputSchemaValidationResult.errors[currentSchemaErrorIndex];
    const model = dataEditorRef.current.getModel();
    if (!model || !error) {
      return;
    }
    const errorRange = new monaco.Range(error.startLineNumber, error.startColumn, error.endLineNumber, error.endColumn);
    dataEditorRef.current.revealRangeInCenter(errorRange);
    dataEditorRef.current.setSelection(errorRange);
  }, [currentSchemaErrorIndex, selectedPayload?.inputSchemaValidationResult]);

  return (
    <div className={classNames('flex-1 flex flex-col divide-y', hidden ? 'hidden' : undefined, className)}>
      <div className="flex items-center justify-between px-3 py-4">
        <div className="flex space-x-3 text-sm">
          <dl>
            <dt className="font-medium text-gray-700">Type</dt>
            <dd>{inputDataType}</dd>
          </dl>
          <dl>
            <dt className="font-medium text-gray-700">{uiMode === 'BUILD' ? 'Active' : 'Selected'} Schema</dt>
            <dd>{uiMode === 'BUILD' ? activeSchema?.name ?? '–' : selectedSchema?.name ?? '–'}</dd>
          </dl>
        </div>

        <Badge type="basic" color={uiMode === 'BUILD' ? BadgeColor.blue : BadgeColor.yellow}>
          {uiMode === 'BUILD' ? 'BUILD' : 'TEST'}
        </Badge>
      </div>
      {uiMode === 'BUILD' ? (
        <div className="flex justify-between space-x-3 px-3 py-4 text-sm">
          {editorType === 'PAYLOAD' ? (
            <div className="flex space-x-3 text-sm">
              <div>
                <Dropdown
                  label="Select existing payload"
                  value={selectedPayload?.id ?? 'choose...'}
                  options={existingPayloads.map((payload) => ({
                    label: payload.name,
                    value: payload.id,
                  }))}
                  onChange={onPayloadSelect}
                />
              </div>
              <div className="flex items-end space-x-2 text-sm">
                <SegmentedControl
                  label="Display"
                  selectedIndex={editorConfigs[inputDataType].displayTypeOptions.indexOf(displayType)}
                  items={editorConfigs[inputDataType].displayTypeOptions.map((displayTypeOption) => ({
                    label: displayTypeOption,
                    LeftIcon:
                      displayTypeOption === 'JSON' && selectedPayload?.inputSchemaValidationResult?.valid === false
                        ? ExclamationTriangleIcon
                        : undefined,
                  }))}
                  onChange={(index) => setDisplayType(editorConfigs[inputDataType].displayTypeOptions[index])}
                />
              </div>
            </div>
          ) : (
            <div className="flex space-x-3 text-sm">
              <div>
                <Dropdown
                  label="Selected schema"
                  value={selectedSchema?.id ?? 'choose...'}
                  options={existingSchemas.map((schema) => ({
                    label: schema.name,
                    value: schema.id,
                  }))}
                  onChange={onSchemaSelect}
                />
              </div>
              <div className="flex items-end space-x-2 text-sm">
                <Button
                  variant="secondary"
                  onClick={() => setCreateSchemaModalOpen(true)}
                  analyticsId="transformation-ui:new-input-schema"
                >
                  New schema
                </Button>
              </div>
            </div>
          )}
          <div className="flex items-end space-x-2 text-sm">
            <SegmentedControl
              label="Editor"
              selectedIndex={editorType === 'PAYLOAD' ? 0 : 1}
              items={[{ label: 'PAYLOAD' }, { label: 'SCHEMA' }]}
              onChange={(index) => setEditorType(index === 0 ? 'PAYLOAD' : 'SCHEMA')}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-between space-x-3 px-3 py-4 text-sm">
          {selectedPayload && (
            <div className="flex space-x-3">
              <dl>
                <dt className="font-medium text-gray-700">Name</dt>
                <dd>{selectedPayload.name}</dd>
              </dl>
              <dl>
                <dt className="font-medium text-gray-700">Processed</dt>
                <dd>{formatDayAndTime(selectedPayload.date)}</dd>
              </dl>
            </div>
          )}
          <div className="flex items-end space-x-2">
            <SegmentedControl
              label="Display type"
              selectedIndex={editorConfigs[inputDataType].displayTypeOptions.indexOf(displayType)}
              items={editorConfigs[inputDataType].displayTypeOptions.map((displayTypeOption) => ({
                label: displayTypeOption,
                LeftIcon:
                  displayTypeOption === 'JSON' && selectedPayload?.inputSchemaValidationResult?.valid === false
                    ? ExclamationTriangleIcon
                    : undefined,
              }))}
              onChange={(index) => setDisplayType(editorConfigs[inputDataType].displayTypeOptions[index])}
            />
          </div>
        </div>
      )}
      {selectedPayload && (
        <>
          <div
            className={classNames('flex-1 min-h-0', editorType === 'PAYLOAD' && displayType === 'JSON' ? '' : 'hidden')}
          >
            <MonacoEditor
              width="100%"
              height="100%"
              language="json"
              value={selectedPayload.transformationInput ?? ''}
              options={{
                automaticLayout: true,
                bracketPairColorization: {
                  enabled: true,
                },
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
                stickyScroll: {
                  enabled: true,
                },
              }}
              editorDidMount={(editor) => (dataEditorRef.current = editor)}
              onChange={dataUpdated}
            />
          </div>
          <div
            className={classNames(
              'flex-1 min-h-0',
              editorType === 'PAYLOAD' && ['RAW', 'XML'].includes(displayType) ? '' : 'hidden',
            )}
          >
            <MonacoEditor
              width="100%"
              height="100%"
              language={displayType === 'XML' ? 'xml' : 'plain'}
              value={selectedPayload.rawInputData}
              options={{
                maxTokenizationLineLength: 100000,
                automaticLayout: true,
                codeLens: false,
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
              }}
              onChange={rawDataUpdated}
              editorDidMount={(editor) => (rawEditorRef.current = editor)}
            />
          </div>
        </>
      )}
      {selectedSchema && (
        <div className={classNames('flex-1 min-h-0', uiMode === 'BUILD' && editorType === 'SCHEMA' ? '' : 'hidden')}>
          <MonacoEditor
            width="100%"
            height="100%"
            language="json"
            value={selectedSchema.schema}
            options={{
              readOnly: selectedSchema.is_draft === false,
              automaticLayout: true,
              bracketPairColorization: {
                enabled: true,
              },
              codeLens: false,
              scrollBeyondLastLine: false,
              minimap: {
                enabled: false,
              },
              stickyScroll: {
                enabled: true,
              },
            }}
            onChange={schemaUpdated}
          />
        </div>
      )}
      {editorType === 'PAYLOAD' && !selectedPayload && (
        <div className="flex flex-1 items-center justify-center">
          <Button
            variant="secondary"
            onClick={() =>
              onPayloadCreate({
                id: `Payload-${Object.keys(existingPayloads).length}`,
                name: 'New payload',
                inputType: inputDataType,
                outputType: outputDataType,
                date: new Date().toISOString(),
                inputEditable: true,
              })
            }
            analyticsId="transformation-ui:new-payload"
          >
            Use manual payload
          </Button>
        </div>
      )}
      {uiMode === 'BUILD' && editorType === 'SCHEMA' && !selectedSchema && (
        <div className="flex flex-1 items-center justify-center">
          <Button
            variant="secondary"
            onClick={() => setCreateSchemaModalOpen(true)}
            analyticsId="transformation-ui:new-input-schema"
          >
            Create new schema
          </Button>
        </div>
      )}

      <div className="flex items-center justify-between px-3 py-4">
        <div className="flex items-center">
          {selectedPayload?.inputSchemaValidationResult?.valid === false && (
            <>
              <Badge color={BadgeColor.red} className="mr-3">
                {selectedPayload?.inputSchemaValidationResult.errors.length} Errors
              </Badge>
              <Button
                iconOnly
                LeftIcon={ArrowUpIcon}
                variant="minimal"
                size="extra-small"
                onClick={selectPreviousSchemaError}
                analyticsId="transformation-ui:select-previous-input-schema-error"
              />
              <Button
                iconOnly
                LeftIcon={ArrowDownIcon}
                variant="minimal"
                size="extra-small"
                onClick={selectNextSchemaError}
                analyticsId="transformation-ui:select-next-input-schema-error"
              />
            </>
          )}
        </div>
        <div className="flex items-center">
          <Button
            iconOnly
            LeftIcon={Bars3BottomLeftIcon}
            variant="minimal"
            size="extra-small"
            onClick={() => {
              rawEditorRef.current?.getAction('editor.action.formatDocument')?.run();
            }}
            analyticsId="transformation-ui:document-format"
          />
        </div>
      </div>

      {!!processType && (
        <CreateSchemaModal
          open={createSchemaModalOpen}
          onClose={() => setCreateSchemaModalOpen(false)}
          existingSchemas={existingSchemas}
          schemaTemplates={schemaTemplates}
          onSchemaCreate={onSchemaCreate}
          type={processType === 'SOURCE' ? 'SOURCE' : 'EXPECTED'}
        />
      )}
    </div>
  );
};
