import { AlertSection } from 'components/Display/Alert';
import { Alert } from 'components/Display/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { isMessageV2DTO } from 'support/helpers/transactions/transactions';
import { MessageDTO, MessageV2DTO, SenderEnvelopeDTO } from 'support/types';

export const TransactionBlockingError = ({
  transaction,
}: {
  transaction: MessageDTO | MessageV2DTO | SenderEnvelopeDTO;
}) => {
  const { t } = useTranslation();
  const blockingError = isMessageV2DTO(transaction)
    ? transaction.blocking_error
      ? {
          howToFix: transaction.blocking_error?.howToFix,
          title: transaction.blocking_error?.title,
          description: transaction.blocking_error?.description,
        }
      : undefined
    : transaction.blockingError;

  return blockingError ? (
    <Alert severity="error" withoutIcon title={blockingError.title}>
      {blockingError.description ? (
        <AlertSection severity="error" title={t('common:blockingError.description')}>
          {blockingError.description}
        </AlertSection>
      ) : null}
      {blockingError.howToFix ? (
        <AlertSection severity="error" title={t('common:blockingError.howToFix')}>
          {blockingError.howToFix}
        </AlertSection>
      ) : null}
    </Alert>
  ) : null;
};
