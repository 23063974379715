import { Page } from 'components/Page/Page';
import { useTranslation } from 'react-i18next';
import { useOnboarding } from 'services/onboarding/onboarding';
import { useCurrentUser } from 'services/repositories/user/user';
import { IOnboardingStep } from 'support/types/dtos';
import { OnboardingDocumentation } from './components/OnboardingDocumentation/OnboardingDocumentation';
import { IntegrationOnboardingSteps } from './components/IntegrationOnboardingSteps/IntegrationOnboardingSteps';

export const DashboardWithOnboarding = () => {
  const { t } = useTranslation();
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const { steps: integrations, isLoading: isLoadingOnboarding } = useOnboarding();

  const multipleIntegrations = Boolean(integrations && integrations.length > 1);

  const optionalSteps = integrations?.reduce((acc, integration) => {
    if (!integration?.optional) return acc;
    const nonPresentOptionalSteps = integration.optional.filter(
      (optionalStep) => !acc.some((accStep) => accStep.key === optionalStep.key),
    );

    if (nonPresentOptionalSteps.length > 0) {
      return [...acc, ...nonPresentOptionalSteps];
    }
    return acc;
  }, [] as Array<IOnboardingStep>);

  return (
    <Page isLoading={isLoadingCurrentUser || isLoadingOnboarding}>
      <Page.Head title={t('dashboard:title', { name: currentUser?.name })} />

      {integrations && (
        <Page.Section>
          {integrations.map((integration) => (
            <IntegrationOnboardingSteps
              key={`${integration.integration_id}.${integration.connector}`}
              steps={integration.required || []}
              variant="REQUIRED"
              integrationType={multipleIntegrations ? integration.connector : undefined}
            />
          ))}

          {optionalSteps && optionalSteps.length > 0 && (
            <IntegrationOnboardingSteps steps={optionalSteps} variant="OPTIONAL" />
          )}

          <OnboardingDocumentation />
        </Page.Section>
      )}
    </Page>
  );
};
