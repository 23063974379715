import { CellContext } from '@tanstack/react-table';
import { CellProps } from './types';
import { useEnumerableOptions } from 'components/WebEDI/helpers';
import { DropdownCell } from './DropdownCell';

export type Props<TData, TValue> = CellContext<TData, TValue> & CellProps;

export const EnumerableCell = <TData = unknown, TValue = string>(props: Props<TData, TValue>) => {
  const {
    column: {
      columnDef: { meta },
    },
  } = props;
  const options = useEnumerableOptions(meta?.optionalConfig);

  return <DropdownCell {...props} options={options} />;
};
