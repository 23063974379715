import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Input } from 'components/Form/Input/Input/Input';

type SearchFilterProps = {
  filterKey: string;
  currentValue: string;
  placeholder: string;
  onChange: (filterKey: string, newValue: string) => void;
};

export function SearchFilter({ placeholder, filterKey, currentValue, onChange }: SearchFilterProps) {
  return (
    <Input
      placeholder={placeholder}
      value={currentValue}
      type="search"
      wrapperClassName="w-80"
      leftIcon={<MagnifyingGlassIcon className="size-5" />}
      onChange={(event) => {
        onChange(filterKey, event.target.value);
      }}
      id={`filter-search-input-${filterKey}`}
    />
  );
}
