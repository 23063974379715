import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/Form/Button/Button';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { Input } from 'components/Form/Input/Input/Input';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'support/helpers/currency/currency';
import { getValidNumberValue } from 'support/helpers/numbers/numbers';
import { ModificationType, PricingConfigPayloadLineItemDTO } from 'support/types';

type PricingConfigLineItemsProps = {
  lineItems: Array<PricingConfigPayloadLineItemDTO>;
  errors: FieldErrors<any> | undefined;
  currency: string | undefined | null;
  onChange: (lineItems: Array<PricingConfigPayloadLineItemDTO>) => void;
};

const emptyLineItem: PricingConfigPayloadLineItemDTO = {
  gtin: '',
  name: '',
  unitPrice: null,
  salesTax: null,
  modifiable: true,
  discountOrChargeType: 'ALLOWANCE',
  discountOrChargeIsPercent: false,
  discountOrChargeValue: undefined,
};

export function PricingConfigLineItems({ lineItems, currency, onChange, errors }: PricingConfigLineItemsProps) {
  const { t } = useTranslation();
  const defaultLineItems = lineItems.length > 0 ? lineItems : [emptyLineItem];
  const lineItemUpdated = (data: PricingConfigPayloadLineItemDTO, index: number) => {
    const newLineItems = [...defaultLineItems];
    newLineItems[index] = data;
    onChange(newLineItems);
  };

  const addLineItem = () => {
    const newLineItems = [...defaultLineItems];
    newLineItems.push(emptyLineItem);
    onChange(newLineItems);
  };

  const removeLineitem = (index: number) => {
    const newLineItems = [...defaultLineItems];
    newLineItems.splice(index, 1);
    onChange(newLineItems);
  };

  return (
    <div className="rounded-lg bg-white p-5 shadow">
      <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">{t('priceList:form.lineItems.title')}</h3>
      <table className="w-full">
        <thead>
          <tr>
            <th className="py-2 pr-3 text-left text-sm font-medium">
              {t('priceList:form.lineItems.fields.productId')}
            </th>
            <th className="px-3 py-2 text-left text-sm font-medium">
              {t('priceList:form.lineItems.fields.productName')}
            </th>
            <th className=" px-3 py-2 text-left text-sm font-medium">
              {t('priceList:form.lineItems.fields.unitPrice')}
            </th>
            <th className="px-3 py-2 text-left text-sm font-medium">{t('priceList:form.lineItems.fields.salesTax')}</th>
            <th className=" py-2 pl-3 text-left text-sm font-medium">
              {t('priceList:form.lineItems.fields.discountOrCharge')}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {defaultLineItems.map((item, index) => {
            return (
              <tr key={`${index}`}>
                <td className="py-2 pr-3">
                  <Input
                    value={item.gtin}
                    hasError={Boolean(errors?.[`lineItems[${index}].gtin}`])}
                    onChange={(e) => {
                      lineItemUpdated({ ...item, gtin: e.target.value }, index);
                    }}
                  />
                </td>

                <td className="px-3 py-2">
                  <Input
                    value={item.name}
                    hasError={Boolean(errors?.[`lineItems[${index}].name`])}
                    onChange={(e) => {
                      lineItemUpdated({ ...item, name: e.target.value }, index);
                    }}
                  />
                </td>

                <td className="px-3 py-2">
                  <Input
                    value={item.unitPrice}
                    type="number"
                    step="0.01"
                    rightAddon={getCurrencySymbol(currency)}
                    hasError={Boolean(errors?.[`lineItems[${index}].unitPrice`])}
                    onChange={(e) => {
                      const newValue = getValidNumberValue(e.target.value);
                      lineItemUpdated({ ...item, unitPrice: newValue }, index);
                    }}
                  />
                </td>

                <td className="px-3 py-2">
                  <Input
                    value={item.salesTax}
                    type="number"
                    step="0.01"
                    rightAddon="%"
                    hasError={Boolean(errors?.[`lineItems[${index}].salesTax`])}
                    onChange={(e) => {
                      const newValue = getValidNumberValue(e.target.value);
                      lineItemUpdated({ ...item, salesTax: newValue }, index);
                    }}
                  />
                </td>

                <td className="relative px-3 py-2">
                  <div className="flex">
                    <div className="w-20">
                      <Dropdown
                        label=""
                        value={item.discountOrChargeType}
                        options={[
                          {
                            value: 'ALLOWANCE' as ModificationType,
                            label: t('priceList:form.lineItems.discountAbbreviation'),
                          },
                          {
                            value: 'CHARGE' as ModificationType,
                            label: t('priceList:form.lineItems.chargeAbbreviation'),
                          },
                        ]}
                        className="-mr-px"
                        buttonClassName="rounded-l-md rounded-r-none"
                        onChange={(value) => {
                          lineItemUpdated(
                            {
                              ...item,
                              discountOrChargeType: value,
                            },
                            index,
                          );
                        }}
                      />
                    </div>

                    <Input
                      value={item.discountOrChargeValue}
                      type="number"
                      step="0.01"
                      className="w-20 rounded-none"
                      onChange={(e) => {
                        const newValue = getValidNumberValue(e.target.value);
                        lineItemUpdated(
                          {
                            ...item,
                            discountOrChargeValue: newValue,
                            modifiable: Boolean(newValue),
                          },
                          index,
                        );
                      }}
                    />

                    <div className="w-16">
                      <Dropdown
                        label=""
                        value={item.discountOrChargeIsPercent ? '%' : currency}
                        options={[
                          {
                            value: currency,
                            label: getCurrencySymbol(currency),
                          },
                          {
                            value: '%',
                            label: '%',
                          },
                        ]}
                        className="-ml-px"
                        buttonClassName="rounded-r-md rounded-l-none"
                        onChange={(value) => {
                          const newValue = value !== currency;

                          lineItemUpdated({ ...item, discountOrChargeIsPercent: newValue }, index);
                        }}
                      />
                    </div>
                  </div>
                </td>

                <td className="py-2 pl-3">
                  <div className="flex w-full items-center pl-3">
                    <button onClick={() => removeLineitem(index)}>
                      <TrashIcon className="size-6 text-gray-400" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-5">
        <Button
          onClick={addLineItem}
          variant="secondary"
          className="mt-1 p-2"
          LeftIcon={PlusIcon}
          analyticsId="pricing-configs:line_item_add"
        >
          {t('priceList:form.lineItems.add')}
        </Button>
      </div>
    </div>
  );
}
