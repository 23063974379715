import { useTranslation } from 'react-i18next';
import { Dropdown } from 'components/Form/Dropdown/Dropdown';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { classNames } from 'support/helpers/generic/generic';

type DropdownValue = {
  label: string;
  value: string;
};

type DropdownFilterProps = {
  label?: string;
  filterKey: string;
  dropdownValues: Array<DropdownValue>;
  displayValueLabel?: string;
  defaultToFirstValue?: boolean;
  className?: string;
  testId?: string;
  currentValue: string;
  onChange: (filterKey: string, newValue: string) => void;
  hideValueFromButton?: boolean;
};

export function DropdownFilter({
  label,
  filterKey,
  currentValue,
  dropdownValues,
  displayValueLabel,
  defaultToFirstValue,
  onChange,
  className,
  testId,
  hideValueFromButton,
}: DropdownFilterProps) {
  const { t } = useTranslation();
  const defaultValueLabel = displayValueLabel ?? t('common:allValues');

  const defaultValue = {
    value: '',
    label: defaultValueLabel,
  };

  const translatedDropdownValues = dropdownValues.map((dropdownValues: DropdownValue) => {
    return {
      label: dropdownValues.label,
      value: dropdownValues.value,
    };
  });

  let options = [];
  let value: string;
  if (defaultToFirstValue) {
    options = translatedDropdownValues;
    value = currentValue || options[0].value;
  } else {
    options = [defaultValue, ...translatedDropdownValues];
    value = currentValue || defaultValue.value;
  }

  return (
    <Dropdown
      className={className}
      dropdownDisplayTestId={testId}
      value={value}
      options={options}
      onChange={(value) => onChange(filterKey, value)}
      emptySelectionLabel={label}
      showSelection={!hideValueFromButton}
      leftIcon={<DropdownFilterIcon currentValue={currentValue} />}
    />
  );
}

const DropdownFilterIcon = ({ currentValue, className }: { currentValue: string; className?: string }) => {
  const icon = currentValue ? (
    <span
      className={classNames(
        'flex items-center justify-center h-5 w-5 border border-procuros-green-500 bg-procuros-green-50 text-procuros-green-500 rounded-full',
        className,
      )}
    >
      1
    </span>
  ) : (
    <FunnelIcon className={className} />
  );

  return icon;
};
